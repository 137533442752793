import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

import { classNames } from '../helpers';

const ButtonRed: ForwardRefRenderFunction<HTMLElement, ButtonProps> = ({ children, className, ...rest }, ref) => (
    <Button {...rest} ref={ref} className={classNames('btn-red', className)}>
        {children}
    </Button>
);

export default forwardRef(ButtonRed);
