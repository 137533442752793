import React, { FC, useCallback, useEffect } from 'react';
import { message } from 'antd';
import { useSelector } from 'react-redux';

import { Preparation } from '../../store/api/apiTypes';
import { getPreparationUpdateState, update as preparationUpdate } from '../../store/actions/preparations';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';

const shortcutScope = 'PreparationUnblockModal';

interface PreparationUnblockModalProps extends CustomModalProps {
    preparationId?: Preparation['id'];
    onSuccess: () => void;
}

const PreparationUnblockModal: FC<PreparationUnblockModalProps> = ({ visible, onCancel, preparationId, onSuccess }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [updatePreparation] = useActions([preparationUpdate.trigger]);
    const preparationUpdateState = useSelector(getPreparationUpdateState);
    const previous = usePrevious({ preparationUpdateState });
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const onClickYes = () => {
        updatePreparation({
            id: preparationId,
            operatorId: null,
        });
    };

    useEffect(() => {
        if (visible && previous?.preparationUpdateState.loading && !preparationUpdateState.loading) {
            if (preparationUpdateState.error) {
                message.error('Une erreur est survenue pendant le déblocage');
            } else {
                onClose();
                onSuccess();
            }
        }
    }, [
        previous?.preparationUpdateState.loading,
        preparationUpdateState.loading,
        preparationUpdateState.error,
        onClose,
        visible,
        onSuccess,
    ]);

    return (
        <CustomModal
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            loading={preparationUpdateState.loading}
                            onClick={onClickYes}
                        >
                            Oui
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClose}
                            ghost
                        >
                            Non
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            visible={visible}
            onCancel={onCancel}
            title="Êtes-vous sûr de vouloir débloquer cette préparation maintenant ?"
            width={368}
            keyboard={false}
        />
    );
};

export default PreparationUnblockModal;
