import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Reception } from '../../store/api/apiTypes';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import CustomModal from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import React, { useEffect } from 'react';
import { message, Typography } from 'antd';
import { IconWarning } from '../../components/icons';
import { del as receptionDel, details as receptionDetails, getReceptionDelState } from '../../store/actions/receptions';
import { getPalletDelState } from '../../store/actions/pallets';
import { useSelector } from 'react-redux';
import { getRoute, RoutePathName } from '../../routes';
import { useHistory } from 'react-router-dom';

const shortcutScope = 'ReceptionDeleteModal';

interface ReceptionDeleteModalProps {
    receptionId: Reception['id'];
}

const ReceptionDeleteModal = NiceModal.create<ReceptionDeleteModalProps>(({ receptionId }) => {
    const modal = useModal();
    const { visible, hide } = modal;
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [deleteReception] = useActions([receptionDel.trigger, receptionDetails.trigger]);
    const receptionDeleteState = useSelector(getReceptionDelState);
    const palletDeleteState = useSelector(getPalletDelState);
    const previous = usePrevious({ receptionDeleteState, palletDeleteState });
    const history = useHistory();
    const onValid = () => {
        deleteReception({
            id: receptionId,
        });
    };

    useEffect(() => {
        if (previous?.receptionDeleteState.loading && !receptionDeleteState.loading) {
            if (receptionDeleteState.error) {
                message.error('Erreur lors de la suppression de la réception');
            } else if (receptionDeleteState.success) {
                hide().then(() => history.push(getRoute(RoutePathName.receptionsList)));
            }
        }
    }, [
        previous?.receptionDeleteState.loading,
        receptionDeleteState.loading,
        receptionDeleteState.success,
        history,
        receptionDeleteState.error,
        hide,
    ]);

    return (
        <CustomModal
            {...antdModal(modal)}
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onValid}
                        >
                            Valider
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={hide}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            title={
                <Typography.Text>
                    <Typography.Text type="warning">
                        <IconWarning style={{ fontSize: '2.25rem', marginBottom: '1rem' }} />
                    </Typography.Text>
                    <>
                        <br />
                        En cliquant sur valider <br />
                        vous supprimez la réception
                        <br />
                        en cours
                    </>
                </Typography.Text>
            }
            width={368}
        />
    );
});

export default ReceptionDeleteModal;
