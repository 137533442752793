import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 22">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11 14v3a1 1 0 11-2 0v-3a1 1 0 112 0zm7 6H2l-.001-9H18v9zM6 6c0-2.206 1.794-4 4-4s4 1.794 4 4v3H6V6zm12 3h-2V6c0-3.309-2.691-6-6-6S4 2.691 4 6v3H2c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2z"
        />
    </svg>
);

const IconLock: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-lock${props.className ? ` ${props.className}` : ''}`} />
);

export default IconLock;
