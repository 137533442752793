import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { OrderFurniture } from '../api/apiTypes';

// Controlers
import {
    OrderFurnitureUpdatePayload,
    update as updateApiCall,
    OrderFurnitureDeletePayload,
    del as delApiCall,
} from '../api/orderFurnitures';

// States
export interface OrderFurnituresState {
    update: RequestState<OrderFurniture>;
    del: RequestState;
}

const initialState: OrderFurnituresState = {
    update: { ...requestInitialState },
    del: { ...requestInitialState },
};

export const update = new EzeeAsyncAction<OrderFurnituresState['update'], OrderFurnitureUpdatePayload, OrderFurniture>(
    'orderFurnitures/update',
    initialState.update,
    requestReducer<OrderFurnituresState['update'], OrderFurniture>(initialState.update)
);

export const del = new EzeeAsyncAction<OrderFurnituresState['del'], OrderFurnitureDeletePayload>(
    'orderFurnitures/del',
    initialState.del,
    requestReducer<OrderFurnituresState['del'], OrderFurniture>(initialState.del)
);

// Reducer
export const orderFurnituresReducer = combineReducers<OrderFurnituresState>({
    update: update.reducer,
    del: del.reducer,
});

// Saga
export function* orderFurnituresSaga() {
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
}

// Store helpers
export const getOrderFurnitureUpdateState = (state: MainReducerState) => state.orderFurnitures.update;
export const getOrderFurnitureDeleteState = (state: MainReducerState) => state.orderFurnitures.del;
