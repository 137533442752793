import { Button, Divider } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    PalletPlace,
    PlaceZoneType,
    TransferOrderStatus,
    TransferOrderType,
    TransferType,
    WMSMode,
} from '../../../store/api/apiTypes';

import { getPalletPlaceListState, list as listPalletsAction } from '../../../store/actions/palletPlaces';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonGrey from '../../../components/ButtonGrey';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import FixedFooter from '../../../components/FixedFooter';
import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import PageHeader from '../../../components/PageHeader';

import Seo from '../../../components/Seo';

import { useActions, useIsMobile, usePrevious } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';

import {
    create,
    detailsTransferOrder,
    getOTStorePackagesMultiPalletState,
    getTransferOrdersDetails,
    getTransferOrderStorePackagesState,
    updateAndStorePackages,
    del as deleteOT,
    getTransferOrdersDeleteState,
} from '../../../store/actions/transferOrders';
import QuantityCard from '../../../components/QuantityCard';
import TransferQuantityModal from './TransferQuantityModal';
import PrintModal from '../../../components/PrintModal';
import StorePackagesByPalletModal from './StorePackagesByPalletModal';
import { formatNumber, formatZoneType } from '../../../helpers/i18n';
import WarningModal from '../../../components/WarningModal';
import { isZebra } from '../../../helpers/enterprise-browser';
import StorePackageByPlaceModal from './StorePackageByPlaceModal';
import { formatPlace } from '../../../helpers';

const TransferOrdersPalletsList: FC = () => {
    const isMobile = useIsMobile();
    const location =
        useLocation<{
            packageQuantity: number;
            canCreatePallet: boolean;
            finishZoneType: PlaceZoneType;
            backRoute?: string;
        }>();
    const history = useHistory();

    const [selectedPalletPlace, setSelectedPalletPlace] = useState<PalletPlace | undefined>();
    const [isQuantityModalVisible, setIsQuantityModalVisible] = useState<boolean>(false);
    const [isReferenceModalVisible, setIsReferenceModalVisible] = useState<boolean>(false);
    const [isPrintModalVisible, setIsPrintModalVisible] = useState<boolean>(false);
    const [isNoDataModalVisible, setIsNoDataModalVisible] = useState<boolean>(false);
    const [isWaitingForBackButton, setIsWaitingForBackButton] = useState(false);

    const [listPallets, resetStorePackagesState, resetCreationState, fecthTransferOrder, deleteTransferOrder] =
        useActions([
            listPalletsAction.trigger,
            updateAndStorePackages.reset,
            create.reset,
            detailsTransferOrder.trigger,
            deleteOT.trigger,
        ]);

    const palletListState = useSelector(getPalletPlaceListState);
    const transferOrderStorePackagesState = useSelector(getTransferOrderStorePackagesState);
    const transferOrderDeleteState = useSelector(getTransferOrdersDeleteState);
    const OTStorePackagesMultiPalletState = useSelector(getOTStorePackagesMultiPalletState);
    const transferOrder = useSelector(getTransferOrdersDetails);

    const [totalPackages, setTotalPackages] = useState<number>(location.state?.packageQuantity ?? 0);
    const [transferQuantity, setTransferQuantity] = useState<number>(totalPackages);
    const [missedPackages, setMissedPackages] = useState<number>(0);

    const finishZoneType =
        transferOrder.data?.startPlaceZoneType === PlaceZoneType.picking
            ? PlaceZoneType.mass
            : location.state?.finishZoneType ?? transferOrder.data?.finishPlaceZoneType;

    const onListRowEnter = (record: PalletPlace) => {
        setSelectedPalletPlace(record);
    };

    const backRoute = location.state?.backRoute ?? getRoute(RoutePathName.movementsTransferOrderList);

    const columns: Array<ListColumn<PalletPlace>> = [
        {
            key: 'reference',
            title: 'Réference de palette',
            flex: '1 1 60%',
            render: (record) => record.pallet?.reference ?? '-',
        },
        {
            title: 'Emplacement',
            flex: '1 1 60%',
            render: ({ place }) => formatPlace(place),
        },
        {
            title: 'Qté en stock',
            flex: '1 1 40%',
            render: (palletPlace) => palletPlace.pallet?.quantity ?? 0,
        },
    ];

    useEffect(() => {
        const parcelId = transferOrder.data?.parcel?.id;
        if (parcelId) {
            listPallets({
                parcelId,
                sort: 'quantity',
                sortOrder: 'asc',
                pageSize: 10,
                zoneType: finishZoneType ?? PlaceZoneType.mass,
            });
        }
    }, [listPallets, transferOrder.data?.parcel?.id, finishZoneType]);

    const transferPackages = (qt: number) => {
        setTransferQuantity(qt);
        setIsQuantityModalVisible(false);
        setIsReferenceModalVisible(true);
    };

    const handlePrintSucess = () => {
        handlePackageTransfered();
    };

    const previous = usePrevious({
        transferOrderStorePackagesState,
        OTStorePackagesMultiPalletState,
        transferOrder,
        palletListState,
        transferOrderDeleteState,
    });
    useEffect(() => {
        if (
            !!(previous?.OTStorePackagesMultiPalletState.loading && !OTStorePackagesMultiPalletState.loading) ||
            (previous?.transferOrderStorePackagesState.loading && !transferOrderStorePackagesState.loading)
        ) {
            fecthTransferOrder({ id: transferOrder.data?.id });
        }
    }, [
        OTStorePackagesMultiPalletState.loading,
        fecthTransferOrder,
        previous?.OTStorePackagesMultiPalletState.loading,
        previous?.transferOrderStorePackagesState.loading,
        transferOrder.data?.id,
        transferOrderStorePackagesState.loading,
    ]);

    useEffect(() => {
        setTotalPackages(transferOrder.data?.packageQuantity ?? location.state?.packageQuantity);
    }, [location.state?.packageQuantity, transferOrder.data?.packageQuantity, transferOrder.loading]);

    const back = () => {
        history.push(getRoute(RoutePathName.movementsTransferOrderList), {});
    };
    const handlePackageTransfered = () => {
        setIsPrintModalVisible(false);
        const isCompleted = transferOrder.data?.status === TransferOrderStatus.completed;
        if (isCompleted) {
            resetStorePackagesState();
            resetCreationState();
            back();
        }
    };
    const handleSubmit = () => {
        setIsQuantityModalVisible(true);
    };

    const handlePackageTransfer = () => {
        setIsReferenceModalVisible(false);
        if (transferOrder.data?.finishPlaceZoneType === PlaceZoneType.picking) {
            handlePackageTransfered();
        } else {
            setIsPrintModalVisible(true);
        }
    };
    const onLoadMore = () => {
        listPallets(
            {
                parcelId: transferOrder.data?.startZone.pallet?.parcel?.id,
                sort: 'quantity',
                sortOrder: 'asc',
                page: (palletListState.data?.page ?? 0) + 1,
                pageSize: 10,
                zoneType: finishZoneType ?? PlaceZoneType.mass,
            },
            {
                loadMore: true,
            }
        );
    };

    useEffect(() => {
        const packagesNumber = totalPackages - (transferOrder.data?.transferedPackages ?? 0);

        setMissedPackages(packagesNumber);
    }, [totalPackages, transferOrder.data?.transferedPackages]);

    const findPlace = () => {
        history.push(getRoute(RoutePathName.movementsTransferOrdersPackagePlaces), {
            ...location.state,
            totalPackages: missedPackages,
            step: 'confirmation',
            zoneType: finishZoneType,
            transferType: TransferType.package,
        });
    };

    // return to home page when there is no OT in store (reload page, direct access to page by url...)
    useEffect(() => {
        if (!transferOrder.loading && !transferOrder.data) {
            history.push(getRoute(RoutePathName.movementsTransferOrderList), {});
        }
    }, [history, transferOrder.data, transferOrder.loading]);

    useEffect(() => {
        if (previous?.palletListState.loading && !palletListState.loading) {
            if (!palletListState.data?.totalCount && finishZoneType === PlaceZoneType.picking) {
                setIsNoDataModalVisible(true);
            }
        }
    }, [finishZoneType, palletListState.data?.totalCount, palletListState.loading, previous?.palletListState.loading]);

    const placeZoneFormatted = formatZoneType(
        finishZoneType === PlaceZoneType.picking ? PlaceZoneType.picking : PlaceZoneType.accumulation
    );

    const handleNoData = () => {
        if (transferOrder.data?.type === TransferOrderType.manual) {
            deleteTransferOrder({ id: transferOrder.data?.id });
            resetCreationState();
        }
        back();
    };

    useEffect(() => {
        if (!isZebra) {
            history.push(getRoute(RoutePathName.notFound));
        }
    }, [history]);

    // delete transferOrder when clicking back button
    useEffect(() => {
        if (isWaitingForBackButton) {
            if (transferOrder.data?.type === TransferOrderType.manual) {
                deleteTransferOrder({
                    id: transferOrder.data?.id,
                });
            } else {
                history.push(backRoute);
            }
        }
    }, [
        deleteTransferOrder,
        transferOrder.data?.id,
        isWaitingForBackButton,
        transferOrder.data?.type,
        backRoute,
        history,
    ]);

    useEffect(() => {
        if (previous?.transferOrderDeleteState.loading && !transferOrderDeleteState.loading) {
            if (!transferOrderDeleteState.error && isWaitingForBackButton) {
                setIsWaitingForBackButton(false);
                history.push(backRoute);
            }
        }
    }, [
        previous?.transferOrderDeleteState.loading,
        transferOrderDeleteState.loading,
        transferOrderDeleteState.error,
        isWaitingForBackButton,
        history,
        backRoute,
    ]);

    return (
        <FixedFooter.Wrapper>
            <Seo title={`arrivée ${placeZoneFormatted}`} />
            <Header
                title={`arrivée ${placeZoneFormatted}`}
                onClickBack={() => setIsWaitingForBackButton(true)}
                backButtonLoading={transferOrderDeleteState.loading}
            />
            <PageHeader>
                <QuantityCard
                    loading={transferOrder.loading}
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    value={`${transferOrder.data?.transferedPackages?.toString()?.concat(' / ') ?? ''}${formatNumber(
                        totalPackages ?? transferOrder.data?.packageQuantity
                    )}`}
                    label={transferOrder.data?.transferedPackages ? 'colis transférés' : 'colis à transférer'}
                />
            </PageHeader>
            <Divider />
            <List<PalletPlace>
                columns={columns}
                data={palletListState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowFocus={setSelectedPalletPlace}
                onRowClick={setSelectedPalletPlace}
                onRowEnterPress={onListRowEnter}
                isLoading={palletListState.loading && palletListState.data?.page === undefined}
                title="Palettes avec cette réference"
                hideWhenEmpty
                style={{ marginBottom: 60 }}
            />

            {palletListState.data && palletListState.data.page < palletListState.data.pageCount - 1 && (
                <Button
                    onClick={onLoadMore}
                    loading={palletListState.loading}
                    size="small"
                    style={{ marginBottom: 60, fontSize: 14 }}
                    block
                >
                    Plus de résultats
                </Button>
            )}

            {isNoDataModalVisible && (
                <WarningModal
                    visible={isNoDataModalVisible}
                    message={`Il n'y a aucune palette picking disponible`}
                    onCancel={handleNoData}
                />
            )}

            {isQuantityModalVisible && (
                <TransferQuantityModal
                    visible={isQuantityModalVisible}
                    onReturn={setIsQuantityModalVisible.bind(null, false)}
                    altTitle={true}
                    onSubmit={transferPackages}
                    title="quantité colis"
                    text={`sur ${missedPackages} à transférer`}
                    max={missedPackages}
                    transferOrderType={transferOrder.data?.type}
                    subTitle={
                        transferOrder.data?.type === TransferOrderType.manual ? 'Quantité à tranférer' : undefined
                    }
                />
            )}
            {isPrintModalVisible && (
                <PrintModal
                    visible={isPrintModalVisible}
                    onCancel={handlePrintSucess}
                    palletId={selectedPalletPlace?.pallet?.id}
                    mode={WMSMode.movement}
                />
            )}
            {isReferenceModalVisible ? (
                finishZoneType === PlaceZoneType.picking ? (
                    <StorePackageByPlaceModal
                        onCancel={setIsReferenceModalVisible.bind(null, false)}
                        visible={isReferenceModalVisible}
                        packagesNumber={transferQuantity}
                        totalPackages={missedPackages}
                        selectedPlace={selectedPalletPlace?.place}
                        onSuccess={handlePackageTransfer}
                        palletId={selectedPalletPlace?.pallet?.id}
                    />
                ) : (
                    <StorePackagesByPalletModal
                        onCancel={setIsReferenceModalVisible.bind(null, false)}
                        visible={isReferenceModalVisible}
                        packagesNumber={transferQuantity}
                        totalPackages={missedPackages}
                        selectedPallet={selectedPalletPlace?.pallet}
                        onSucess={handlePackageTransfer}
                        finishZoneType={finishZoneType}
                    />
                )
            ) : null}
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        onClick={handleSubmit}
                        shortcut="enter"
                        size={isMobile ? 'middle' : 'large'}
                        as={ButtonGrey}
                        block
                        disabled={!selectedPalletPlace}
                    >
                        Ranger les colis
                    </ButtonWithShortcut>
                </ArrowNavItem>
                {finishZoneType !== PlaceZoneType.picking && transferOrder.data?.type !== TransferOrderType.manual && (
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            onClick={findPlace}
                            shortcut="f1"
                            type="primary"
                            ghost
                            size={isMobile ? 'middle' : 'large'}
                            block
                        >
                            Autre emplacement
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                )}
            </FixedFooter>
        </FixedFooter.Wrapper>
    );
};

export default TransferOrdersPalletsList;
