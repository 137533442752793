import { takeLatest } from 'redux-saga/effects';
import { combineReducers } from 'redux';
// Helpers
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { requestInitialState, requestReducer } from '../helpers';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { MainReducerState, RequestState } from '../reducers';

// Types
import { Incident } from '../api/apiTypes';
import { ListResponse } from '../api';
// Controllers
import {
    IncidentsCreatePayload,
    IncidentsListPayload,
    list as listApiCall,
    create as createApiCall,
    del as delApiCall,
    IncidentsDelPayload,
} from '../api/incidents';

// State
export interface IncidentsState {
    create: RequestState<Incident>;
    list: RequestState<ListResponse<Incident>>;
    del: RequestState;
}
const initialState: IncidentsState = {
    create: { ...requestInitialState },
    list: { ...requestInitialState },
    del: { ...requestInitialState },
};
export const create = new EzeeAsyncAction<IncidentsState['create'], IncidentsCreatePayload, Incident>(
    'incidents/create',
    initialState.create,
    requestReducer<IncidentsState['create'], Incident>(initialState.create)
);

export const list = new EzeeAsyncAction<IncidentsState['list'], IncidentsListPayload, ListResponse<Incident>>(
    'incidents/list',
    initialState.list,
    requestReducer<IncidentsState['list'], ListResponse<Incident>>(initialState.list)
);
export const del = new EzeeAsyncAction<IncidentsState['del'], IncidentsDelPayload>(
    'incidents/del',
    initialState.del,
    requestReducer<IncidentsState['del']>(initialState.list)
);

export const incidentsReducer = combineReducers<IncidentsState>({
    create: create.reducer,
    list: list.reducer,
    del: del.reducer,
});

export function* incidentsSaga() {
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
}

export const getIncidentsCreateState = (state: MainReducerState) => state.incidents.create;
export const getIncidentsListState = (state: MainReducerState) => state.incidents.list;
export const getIncidentsDelState = (state: MainReducerState) => state.incidents.del;
