import React, { forwardRef, ForwardRefRenderFunction, useRef } from 'react';
import { Link, LinkProps, NavLink } from 'react-router-dom';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

export type ButtonLinkProps = LinkProps &
    ButtonProps & {
        isNavLink?: boolean;
        onLinkClick?: LinkProps['onClick'];
    };

const ButtonLink: ForwardRefRenderFunction<HTMLAnchorElement, ButtonLinkProps> = (
    { children, download, href, isNavLink, onLinkClick, target, to, ...rest },
    ref
) => {
    const buttonRef = useRef<HTMLElement | null>(null);
    const onFocus = () => {
        buttonRef.current?.focus();
    };
    const hrefStyle = {
        display: 'inline-block',
        width: rest.block ? '100%' : 'auto',
    };

    if (href) {
        return (
            <a
                href={href}
                download={download}
                rel="noopener noreferrer"
                target={target}
                ref={ref}
                onFocus={onFocus}
                style={hrefStyle}
            >
                <Button {...rest} tabIndex={-1} ref={buttonRef}>
                    {children}
                </Button>
            </a>
        );
    }

    if (isNavLink) {
        return (
            <NavLink to={to} onClick={onLinkClick} ref={ref as any} onFocus={onFocus} style={hrefStyle}>
                <Button {...rest} tabIndex={-1} ref={buttonRef}>
                    {children}
                </Button>
            </NavLink>
        );
    }

    return (
        <Link to={to} ref={ref as any} onFocus={onFocus} style={hrefStyle}>
            <Button {...rest} tabIndex={-1} ref={buttonRef}>
                {children}
            </Button>
        </Link>
    );
};

export default forwardRef<HTMLAnchorElement, ButtonLinkProps>(ButtonLink);
