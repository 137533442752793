import { OrderParcel } from './apiTypes';
import api from './_client';

export type OrderParcelUpdatePayload = OrderParcel & {
    orderParcelId: OrderParcel['id'];
};
export const update = async ({ orderParcelId, ...payload }: OrderParcelUpdatePayload) =>
    await api.put(`/orderParcels/${orderParcelId}`, payload);

export interface OrderParcelDeletePayload {
    orderParcelId: OrderParcel['id'];
}
export const del = async ({ orderParcelId }: OrderParcelDeletePayload) =>
    await api.delete(`/orderParcels/${orderParcelId}`);
