import { call, delay, put } from 'redux-saga/effects';

import { ActionMeta, DataAction } from './EzeeAction';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

export type CallableAsyncFunc<Payload> = (payload: Payload, meta?: ActionMeta) => Promise<any>;

export function simpleAsyncSaga<Payload>(func: CallableAsyncFunc<Payload>, action: EzeeAsyncAction<any, Payload>) {
    return function* asyncSaga(actionData: DataAction<Payload>) {
        try {
            if (actionData.meta?.throttling) {
                yield delay(actionData.meta?.throttling);
            }

            const response = yield call(func, actionData.payload, actionData.meta);
            return yield put(action.success(response, actionData.meta));
        } catch (error) {
            return yield put(action.failure(error, actionData.meta));
        }
    };
}
