import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { clientRegularizationList, getClientRegularizationListState } from '../../store/actions/controlRegularization';
import { ClientRegularization, ClientRegularizationStatus } from '../../store/api/apiTypes';

import { getRoute, RoutePathName } from '../../routes';
import { useActions } from '../../hooks';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import List, { ListColumn } from '../../components/list/List';
import ControlPalletActionsModal from './ControlPalletActionsModal';

const ControlRegularize: FC = () => {
    const history = useHistory();
    const { loadingOrderId, customerOrderId } = useParams<{ loadingOrderId: string; customerOrderId: string }>();
    const [isActionModalVisible, setIsActionModalVisible] = useState(false);
    const [fetchClientRegularizations] = useActions([clientRegularizationList.trigger]);
    const clientRegularizationListState = useSelector(getClientRegularizationListState);
    const columns: Array<ListColumn<ClientRegularization>> = [
        {
            key: 'reference',
            title: 'Référence palette',
            flex: '1 1 65%',
            render: (record) => (record.pallet ? record.pallet?.reference : 'Palette supprimée'),
        },
        {
            key: 'quantity',
            title: 'Quantité retirée',
            flex: '1 1 35%',
            render: (record) => `${record.quantityRemoved ?? 0}/${record.quantity ?? 0}`,
        },
    ];
    const onRowClick = useCallback(
        (record) => {
            history.push(
                getRoute(RoutePathName.preparationControlRegularizePallet, {
                    loadingOrderId,
                    customerOrderId,
                    clientRegularizationId: record.id,
                })
            );
        },
        [customerOrderId, history, loadingOrderId]
    );
    const onRefresh = useCallback(() => {
        fetchClientRegularizations({ customerOrderId });
    }, [fetchClientRegularizations, customerOrderId]);

    useEffect(() => {
        fetchClientRegularizations({ customerOrderId });
    }, [fetchClientRegularizations, customerOrderId]);

    return (
        <>
            <Seo title="Retrait des colis" />
            <Header
                title="Retrait des colis"
                backRoute={getRoute(RoutePathName.preparationControl, {
                    loadingOrderId,
                    customerOrderId,
                })}
                enableHomeButton
            />
            <List<ClientRegularization>
                columns={columns}
                data={clientRegularizationListState.data?.items}
                rowKey={(record) => `${record.id}`}
                isLoading={clientRegularizationListState.loading}
                isRowHiglighted={(record) => record.status === ClientRegularizationStatus.completed}
                onRowClick={onRowClick}
                onRowEnterPress={onRowClick}
                loadingRowsCount={6}
                title={`${clientRegularizationListState.data?.totalCount ?? ''} palette${
                    (clientRegularizationListState.data?.totalCount ?? 0) === 1 ? '' : 's'
                } avec des colis à retirer`}
                style={{ marginTop: '1.5rem' }}
            />
            <ControlPalletActionsModal
                visible={isActionModalVisible}
                onCancel={setIsActionModalVisible.bind(null, false)}
                setIsVisible={setIsActionModalVisible}
                onRefresh={onRefresh}
            />
        </>
    );
};

export default ControlRegularize;
