import React, { FC, useEffect } from 'react';
import { useActions } from '../hooks';
import { infos } from '../store/actions/api';

const ApiInfo: FC = () => {
    const getApiInfos = useActions(infos.trigger);

    useEffect(() => {
        getApiInfos();
    }, [getApiInfos]);

    return <>{null}</>;
};

export default ApiInfo;
