import { Card, Col, Divider, message, Row, Spin, Typography } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import '../../../assets/styles/Rdv.less';

import { getCustomerOrderStateById } from '../../../store/actions/customerOrders';
import {
    getCustomerOrderPlatformReasonCodeSendMailState,
    sendMail,
} from '../../../store/actions/customerOrderPlatformReasonCodes';
import { CustomerOrderPlatformReasonCode, MailStatus } from '../../../store/api/apiTypes';

import { useActions, usePrevious } from '../../../hooks';
import TitleBlack from '../../../components/TitleBlack';
import BasicList from '../../../components/BasicList';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import { formatDate, formatTimeFromSeconds } from '../../../helpers/i18n';
import { IconCheckCircle } from '../../../components/icons';
import ButtonRed from '../../../components/ButtonRed';

interface RdvCustomerOrderHistoryProps {
    onSendMailSuccess: () => void;
}

const RdvCustomerOrderHistory: FC<RdvCustomerOrderHistoryProps> = ({ onSendMailSuccess }) => {
    const { customerOrderId: customerOrderIdParam } = useParams<{ customerOrderId: string }>();
    const customerOrderId = parseInt(customerOrderIdParam, 10);
    const [currentCustomerOrderPlatformReasonCodeId, setCurrentCustomerOrderPlatformReasonCodeId] =
        useState<CustomerOrderPlatformReasonCode['id']>();
    const [sendMailCustomerOrderPlatformReasonCode] = useActions([sendMail.trigger]);
    const customerOrderState = useSelector(getCustomerOrderStateById(customerOrderId));
    const sendMailState = useSelector(getCustomerOrderPlatformReasonCodeSendMailState);
    const previous = usePrevious({ sendMailState });
    const onClickSendEmail = (id: CustomerOrderPlatformReasonCode['id']) => {
        setCurrentCustomerOrderPlatformReasonCodeId(id);
        sendMailCustomerOrderPlatformReasonCode({
            id,
        });
    };

    useEffect(() => {
        if (previous?.sendMailState.loading && !sendMailState.loading) {
            if (sendMailState.error) {
                message.error(`Une erreur est survenue pendant l'envoi du mail ${sendMailState.error.status}`);
            } else {
                onSendMailSuccess();
            }

            setCurrentCustomerOrderPlatformReasonCodeId(undefined);
        }
    }, [previous?.sendMailState.loading, sendMailState.loading, sendMailState.error, onSendMailSuccess]);

    return customerOrderState.data?.customerOrderPlatformReasonCodes?.length ? (
        <Card className="mt-24">
            <TitleBlack>Historique des modifications</TitleBlack>
            <Divider />
            <BasicList>
                {customerOrderState.data?.customerOrderPlatformReasonCodes?.map(
                    (customerOrderPlatformReasonCode, index) => {
                        const {
                            id,
                            createdAt,
                            platformReasonCode,
                            previousDeliveryDateToClient,
                            deliveryDateToClient,
                            tour,
                            previousTour,
                            time,
                            previousTime,
                            mailStatus,
                            archived,
                        } = customerOrderPlatformReasonCode;

                        return (
                            <li key={id}>
                                {index > 0 && <Divider />}
                                <Row gutter={56}>
                                    <Col flex="none">
                                        {formatDate(createdAt, {
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}
                                    </Col>
                                    <Col flex="auto">
                                        <Typography.Title level={3} className="text-primary">{`${
                                            platformReasonCode?.reference ?? ''
                                        } - ${platformReasonCode?.label ?? ''}`}</Typography.Title>
                                        {previousDeliveryDateToClient !== deliveryDateToClient && (
                                            <p className="mb-8 text-uppercase">
                                                Changement date de livraison {formatDate(deliveryDateToClient)} au lieu
                                                du {formatDate(previousDeliveryDateToClient)}
                                            </p>
                                        )}
                                        {previousTour !== tour && (
                                            <p className="mb-8 text-uppercase">
                                                Changement tour {tour} au lieu de tour {previousTour}
                                            </p>
                                        )}
                                        {previousTime !== time && (
                                            <p className="mb-8 text-uppercase">
                                                Changement heure {formatTimeFromSeconds(time)} au lieu de{' '}
                                                {formatTimeFromSeconds(previousTime)}
                                            </p>
                                        )}
                                        {!archived && (
                                            <ButtonWithShortcut
                                                shortcut={`${index + 1}`}
                                                as={mailStatus === MailStatus.error ? ButtonRed : undefined}
                                                type="primary"
                                                onClick={onClickSendEmail.bind(null, id)}
                                                loading={
                                                    currentCustomerOrderPlatformReasonCodeId === id &&
                                                    sendMailState.loading
                                                }
                                                size="small"
                                                className="mt-8"
                                                disabled={
                                                    ![MailStatus.toBeProcessed, MailStatus.error].includes(mailStatus)
                                                }
                                                ghost
                                            >
                                                {mailStatus === MailStatus.toBeProcessed &&
                                                    'Communiquer par email au client'}
                                                {mailStatus === MailStatus.completed && (
                                                    <>
                                                        <IconCheckCircle /> Email envoyé avec succès
                                                    </>
                                                )}
                                                {mailStatus === MailStatus.inProgress && (
                                                    <>
                                                        <Spin spinning /> {"Email en cours d'envoi"}
                                                    </>
                                                )}
                                                {mailStatus === MailStatus.error &&
                                                    "(Erreur) Renvoyer l'email au client"}
                                            </ButtonWithShortcut>
                                        )}
                                    </Col>
                                </Row>
                            </li>
                        );
                    }
                )}
            </BasicList>
        </Card>
    ) : null;
};

export default RdvCustomerOrderHistory;
