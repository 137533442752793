import React, { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router-dom';

const elementQuery = '.arrow-nav-item:not([disabled]), #password';

// get current arrow nav items based on elementQuery, filter them with current arrow nav scope if different than default scope
const getCurrentArrowNavItems = () =>
    [...document.querySelectorAll(elementQuery)].filter((element) =>
        arrowNav.scope !== DEFAULT_ARROW_NAV_SCOPE
            ? (element as HTMLElement).dataset.arrowNavScope === arrowNav.scope
            : !(element as HTMLElement).dataset.arrowNavScope
    );

const ArrowNav: FC = () => {
    const location = useLocation();
    const [index, setIndex] = useState<number | undefined>();
    const [items, setItems] = useState<Element[]>([]);
    const onArrow = useCallback(
        (type: 'down' | 'up', event) => {
            // prevent scrolling the page
            event.preventDefault();

            if (
                [...(document.activeElement?.classList ?? [])].some(
                    (className) => className === 'ant-select-open' || className === 'ant-select-selection-search-input'
                )
            ) {
                return;
            }

            const currentItems = getCurrentArrowNavItems();
            const currentItemsCount = currentItems.length;
            let newIndex;

            // if page items have changed
            if (currentItemsCount !== items.length || currentItems.some((i, ind) => i !== items[ind])) {
                setItems(currentItems);
                newIndex = type === 'down' ? 0 : currentItemsCount - 1;
            } else {
                if (type === 'down') {
                    newIndex = ((index ?? -1) + 1) % currentItemsCount;
                } else {
                    newIndex = ((index ?? 0) - 1 + currentItemsCount) % currentItemsCount;
                }
            }
            setIndex(newIndex);

            if (currentItemsCount) {
                const itemToFocus = currentItems[newIndex] as HTMLElement;

                if (itemToFocus.classList.contains('ant-select')) {
                    itemToFocus.querySelector('input')?.focus();
                } else {
                    itemToFocus.focus();
                }
            }
        },
        [items, index]
    );
    useHotkeys('down', onArrow.bind(null, 'down'), { enableOnTags: ['INPUT'] }, [items, index]);
    useHotkeys('up', onArrow.bind(null, 'up'), { enableOnTags: ['INPUT'] }, [items, index]);

    useLayoutEffect(() => {
        setItems(getCurrentArrowNavItems());
    }, [location.pathname]);

    useEffect(() => {
        const interval = window.setInterval(() => {
            setItems(getCurrentArrowNavItems());
        }, 2000);

        return () => {
            window.clearInterval(interval);
        };
    }, []);

    return <>{null}</>;
};

export default ArrowNav;

export const DEFAULT_ARROW_NAV_SCOPE = 'all';
// Used in ButtonWithShortcut to scope events
class ArrowNavScope {
    public _scope = DEFAULT_ARROW_NAV_SCOPE;

    public set scope(scope: string) {
        this._scope = scope;
    }

    public get scope() {
        return this._scope;
    }

    public resetScope() {
        this._scope = DEFAULT_ARROW_NAV_SCOPE;
    }
}

export const arrowNav = new ArrowNavScope();

// @ts-expect-error
window.arrowNav = arrowNav;
