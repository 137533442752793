import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { setDefaultBreakpoints, BreakpointProvider } from 'react-socks';
import { ConfigProvider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import frFR from 'antd/lib/locale/fr_FR';
import dayjs from 'dayjs';
import dayjsFr from 'dayjs/locale/fr.js';
import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';

import './assets/styles/AntOverride.less';

import reportWebVitals from './reportWebVitals';

import configureStore from './store/configureStore';

import App from './App';
import ArrowNav from './components/ArrowNav';
import ApiInfo from './components/ApiInfo';
import NiceModal from '@ebay/nice-modal-react';

dayjs.locale(dayjsFr);

Spin.setDefaultIndicator(<LoadingOutlined spin />);

setDefaultBreakpoints([{ xs: 0 }, { sm: 480 }, { md: 576 }, { lg: 768 }, { xl: 992 }, { xxl: 1200 }, { xxxl: 1600 }]);

const store = configureStore();

const render = (Component: React.ComponentType) => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <BreakpointProvider>
                    <ConfigProvider locale={frFR}>
                        <NiceModal.Provider>
                            <ApiInfo />
                            <ArrowNav />
                            <Component />
                        </NiceModal.Provider>
                    </ConfigProvider>
                </BreakpointProvider>
            </BrowserRouter>
        </Provider>,
        document.getElementById('root') as HTMLElement
    );
};

const init = async () => {
    const checkPolyfills = async () => {
        if (!window.IntersectionObserver) {
            await import('intersection-observer');
        }
        if (!window.URLSearchParams) {
            await import('url-search-params-polyfill');
        }
        if (shouldPolyfillLocale()) {
            await import('@formatjs/intl-locale/polyfill');
        }
        if (shouldPolyfillGetCanonicalLocales()) {
            await import('@formatjs/intl-getcanonicallocales/polyfill');
        }
        if (shouldPolyfillPluralRules('fr')) {
            await import('@formatjs/intl-pluralrules/polyfill');
            await import('@formatjs/intl-pluralrules/locale-data/fr');
        }
        if (shouldPolyfillNumberFormat('fr')) {
            await import('@formatjs/intl-numberformat/polyfill');
            await import('@formatjs/intl-numberformat/locale-data/fr');
        }
    };

    await checkPolyfills();

    render(App);
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
