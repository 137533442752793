import { isZebra } from '../helpers/enterprise-browser';
import { Device } from '../store/api/apiTypes';

export default Object.freeze({
    PAGE_SIZE: 50,
    API: Object.freeze({
        BASE_URL: process.env.REACT_APP_API_BASE_URL ?? '',
        HEADERS: Object.freeze({
            // send the device type to receive permissions for this device only
            'x-device': isZebra ? Device.zebra : Device.desktop,
        }),
    }),
    SEO: Object.freeze({
        BASE_URL: '',
        SITE_NAME: 'Parisot WMS',
        DEFAULT_TITLE: 'Parisot WMS',
        DEFAULT_DESCRIPTION: 'Parisot warehouse management system',
    }),
    GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS ?? '',
    ENABLE_DEBUG_LOGS: false, // process.env.NODE_ENV === 'production',
    DEBUG_LOGS_APP: '', // <app>_<service>
    PALLET_BARCODE_REGEX: /([ˆ0-9].[0-9]*)/,
});
