import { Input, InputProps } from 'antd';
import React, { FC, ReactNode, useEffect, useState } from 'react';

import '../assets/styles/QuantityInput.less';
import { classNames } from '../helpers';

import ArrowNavItem from './ArrowNavItem';
import ButtonWithShortcut from './ButtonWithShortcut';
import { IconMinus, IconPlus } from './icons';

interface QuantityInputProps {
    hasArrowNav?: boolean;
    value?: number | undefined;
    onChange?: (value: number) => void;
    min?: number;
    max?: number;
    total?: number;
    text?: ReactNode;
    smallText?: boolean;
    small?: boolean;
    disableEnterShortCut?: boolean;
}

const QuantityInput: FC<QuantityInputProps> = ({
    hasArrowNav,
    onChange,
    value,
    min = 0,
    max,
    total,
    text,
    smallText,
    small,
    disableEnterShortCut,
}) => {
    const [internalValue, setInternalValue] = useState<number | undefined>(value);
    const onIncrement = () => {
        if (internalValue === max) {
            return;
        }
        setInternalValue((internalValue ?? 0) + 1);
        onChange?.((internalValue ?? 0) + 1);
    };
    const onDecrement = () => {
        if (internalValue !== undefined && internalValue <= min) {
            return;
        }
        setInternalValue((internalValue ?? 0) - 1);
        onChange?.((internalValue ?? 0) - 1);
    };
    const onChangeInput: InputProps['onChange'] = (e) => {
        const val = parseInt(e.target.value.replace(/^0+/, ''), 10);

        if (isNaN(val)) {
            setInternalValue(0);
            onChange?.(0);
        } else {
            setInternalValue(val);
            onChange?.(val);
        }
    };
    const minusButton = (
        <ButtonWithShortcut
            shortcut="-"
            shortcutOptions={{ enableOnTags: ['INPUT'], enabled: internalValue !== min }}
            type="primary"
            onClick={onDecrement}
            disabled={internalValue !== undefined && internalValue <= min}
            hideShortcut
            disableEnterShortCut={disableEnterShortCut}
        >
            <IconMinus />
        </ButtonWithShortcut>
    );
    const plusButton = (
        <ButtonWithShortcut
            shortcut="+"
            shortcutOptions={{ enableOnTags: ['INPUT'] }}
            type="primary"
            onClick={onIncrement}
            disabled={internalValue !== undefined && max !== undefined && internalValue >= max}
            hideShortcut
            disableEnterShortCut={disableEnterShortCut}
        >
            <IconPlus />
        </ButtonWithShortcut>
    );

    useEffect(() => {
        if (value !== internalValue) {
            setInternalValue(value);
        }
    }, [value]); // eslint-disable-line

    return (
        <div className="quantity-input-wrapper">
            <div
                className={classNames(
                    'quantity-input',
                    !!value && !!max && (value > max || value < min) && 'has-error',
                    small && 'quantity-input-small'
                )}
            >
                {hasArrowNav ? (
                    <>
                        <ArrowNavItem>{minusButton}</ArrowNavItem>
                        <ArrowNavItem>
                            <Input value={internalValue} min={min} max={max} onChange={onChangeInput} />
                        </ArrowNavItem>
                        <ArrowNavItem>{plusButton}</ArrowNavItem>
                    </>
                ) : (
                    <>
                        {minusButton}
                        <Input value={internalValue} min={min} max={max} onChange={onChangeInput} />
                        {plusButton}
                    </>
                )}
            </div>
            {!!total && total > 0 && (
                <p className="quantity-input-total">
                    {total} RESTANT{total > 1 && 'S'}
                </p>
            )}
            {text && (
                <p className="quantity-input-total" style={smallText ? { textTransform: 'none' } : undefined}>
                    {text}
                </p>
            )}
        </div>
    );
};

export default QuantityInput;
