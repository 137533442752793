import { OrderFurniture } from './apiTypes';
import api from './_client';

export type OrderFurnitureUpdatePayload = OrderFurniture & {
    orderFurnitureId: OrderFurniture['id'];
};
export const update = async ({ orderFurnitureId, ...payload }: OrderFurnitureUpdatePayload) =>
    await api.put(`/orderFurnitures/${orderFurnitureId}`, payload);

export interface OrderFurnitureDeletePayload {
    orderFurnitureId: OrderFurniture['id'];
}
export const del = async ({ orderFurnitureId }: OrderFurnitureDeletePayload) =>
    await api.delete(`/orderFurnitures/${orderFurnitureId}`);
