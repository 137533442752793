import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Col, Descriptions, Divider, Row, Space, Spin, Tag } from 'antd';

import { details, getPalletDetailsStateById } from '../../store/actions/pallets';
import { Pallet, PalletQuality, PlaceZoneType, RoleName, WMSMode } from '../../store/api/apiTypes';
import { getUser } from '../../store/actions/auth';

import { useActions, useIsMobile } from '../../hooks';
import PageHeader from '../../components/PageHeader';
import QuantityCard from '../../components/QuantityCard';
import VerticalDescriptions from '../../components/VerticalDescriptions';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import FixedFooter from '../../components/FixedFooter';
import ArrowNavItem from '../../components/ArrowNavItem';
import PrintModal from '../../components/PrintModal';
import TitleBlack from '../../components/TitleBlack';
import useQueryParams from '../../hooks/queryParams';
import { formatNumber } from '../../helpers/i18n';
import SearchPalletActionsModal from './SearchPalletActionsModal';
import { hasRole } from '../../helpers/security';

interface SearchDetailsPalletProps {
    id: Pallet['id'];
}

const SearchDetailsPallet: FC<SearchDetailsPalletProps> = ({ id }) => {
    const isMobile = useIsMobile();
    const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
    const [isActionModalVisible, setIsActionModalVisible] = useState(false);
    const fetchPallet = useActions(details.trigger);
    const palletState = useSelector(getPalletDetailsStateById(id));
    const user = useSelector(getUser);
    const [urlSearchParams] = useQueryParams('searchDetailsPallet');
    const placeStoreLabel = urlSearchParams.get('placeStoreLabel');
    const { sector, locker, spot } = palletState.data?.currentPlace ?? {};
    const isGestionnaire = hasRole(user, [RoleName.gsriruRole]);
    const isPicking = palletState.data?.currentPlace?.zoneType === PlaceZoneType.picking;
    const isMass = palletState.data?.currentPlace?.zoneType === PlaceZoneType.mass;

    useEffect(() => {
        fetchPallet({ palletId: id });
    }, [fetchPallet, id]);

    return (
        <FixedFooter.Wrapper>
            <PageHeader>
                <Spin spinning={palletState.loading}>
                    <Row wrap={false} gutter={24}>
                        <Col flex="auto">
                            <Descriptions column={{ xs: 2 }} size="small" colon={false} layout="vertical">
                                {placeStoreLabel && (
                                    <Descriptions.Item label="Site">{placeStoreLabel}</Descriptions.Item>
                                )}
                                <Descriptions.Item label="Référence palette">
                                    {palletState.data?.reference}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        {palletState.data?.quality === PalletQuality.noConform && (
                            <Col flex="none">
                                <Tag color="warning">Bloquée en défaut qualité</Tag>
                            </Col>
                        )}
                    </Row>
                </Spin>
            </PageHeader>
            <Space direction="vertical" size="large">
                <Card>
                    <TitleBlack>Information colis</TitleBlack>
                    <Divider />
                    <VerticalDescriptions
                        items={[
                            { label: 'Référence', value: palletState.data?.parcel?.reference ?? '—' },
                            {
                                label: 'Désignation',
                                value: palletState.data?.parcel?.label ?? '—',
                            },
                        ]}
                        inCard
                    />
                </Card>
                <QuantityCard value={formatNumber(palletState.data?.quantity)} label="Colis en stock" />
                <Card>
                    <VerticalDescriptions
                        items={[
                            {
                                label: 'Emplacement',
                                value: `${sector ?? '—'} ${locker ?? '—'} ${spot ?? '—'}`,
                            },
                        ]}
                        inCard
                    />
                </Card>
            </Space>
            <FixedFooter>
                {isPicking && !isGestionnaire && (
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            shortcut="f1"
                            type="primary"
                            onClick={setIsPrintModalOpen.bind(null, true)}
                            ghost
                            block
                        >
                            Impression de l&rsquo;étiquette
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                )}
                {((isPicking && isGestionnaire) || isMass) && (
                    <>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="enter"
                                onClick={setIsActionModalVisible.bind(null, true)}
                                size={isMobile ? 'middle' : 'large'}
                                block
                            >
                                Actions
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <SearchPalletActionsModal
                            visible={isActionModalVisible}
                            onCancel={setIsActionModalVisible.bind(null, false)}
                            setIsVisible={setIsActionModalVisible}
                            palletId={id}
                        />
                    </>
                )}
            </FixedFooter>
            <PrintModal
                visible={isPrintModalOpen}
                onCancel={setIsPrintModalOpen.bind(null, false)}
                palletId={palletState.data?.id}
                mode={WMSMode.stockResearch}
            />
        </FixedFooter.Wrapper>
    );
};

export default SearchDetailsPallet;
