import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { Parcel, Reception } from '../api/apiTypes';
import { ListResponse } from '../api/';

// Controlers
import {
    ParcelListPayload,
    list as listApiCall,
    ParcelDetailsByScanPayload,
    details as detailsApiCall,
    detailsByScan as detailsByScanApiCall,
    ParcelDetailsPayload,
} from '../api/parcels';

// States
export interface ParcelsState {
    list: RequestState<ListResponse<Reception>>;
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: Parcel | undefined;
        };
    };
    detailsByScan: RequestState<Parcel>;
}

const initialState: ParcelsState = {
    list: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
    detailsByScan: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<ParcelsState['list'], ParcelListPayload, ListResponse<Reception>>(
    'parcels/list',
    initialState.list,
    requestReducer<ParcelsState['list'], ListResponse<Reception>>(initialState.list)
);

export const details = new EzeeAsyncAction<ParcelsState['details'], ParcelDetailsPayload, Parcel>(
    'parcels/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

export const detailsByScan = new EzeeAsyncAction<ParcelsState['detailsByScan'], ParcelDetailsByScanPayload, Parcel>(
    'parcels/detailsByScan',
    initialState.detailsByScan,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            data: undefined, // reset when fetching
            loading: true,
        }),
        success: (state, payload) => ({
            data: payload,
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.detailsByScan,
        }),
    }
);

// Reducer
export const parcelsReducer = combineReducers<ParcelsState>({
    list: list.reducer,
    details: details.reducer,
    detailsByScan: detailsByScan.reducer,
});

// Saga
export function* parcelsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(detailsByScan.type.trigger, simpleAsyncSaga(detailsByScanApiCall, detailsByScan));
}

// Store helpers
export const getParcelsListState = (state: MainReducerState) => state.parcels.list;
export const getParcelDetailsKey = ({ parcelId }: { parcelId: Parcel['id'] }) => {
    return parcelId;
};
export const getParcelStateById = (id: Parcel['id']) => (state: MainReducerState) => ({
    loading: state.parcels.details.loading,
    error: state.parcels.details.error,
    data: state.parcels.details.data[id],
});
export const getParcelScanState = (state: MainReducerState) => state.parcels.detailsByScan;
