window.Rho = window.Rho || (function(Q) {
    var Y = "rhoapi.js";
    var W = "__rhoID";
    var am = "__rhoClass";
    var G = "__rhoCallback";
    var B = "/system/js_api_entrypoint";
    var w = "";
    var v = "0";
    var af = {
        isFunction: function(i) {
            return "function" === typeof i
        },
        isPlainObject: function(i) {
            return i && "object" === typeof i
        },
        isArray: function(i) {
            return (i && "Array" == (i).constructor.name)
        },
        extend: function() {
            var an, au, ao, ap, ax, av, at = arguments[0] || {},
                ar = 1,
                aq = arguments.length,
                aw = false;
            if (typeof at === "boolean") {
                aw = at;
                at = arguments[ar] || {};
                ar++
            }
            if (typeof at !== "object" && !af.isFunction(at)) {
                at = {}
            }
            if (ar === aq) {
                at = this;
                ar--
            }
            for (;ar < aq;ar++) {
                if ((ax = arguments[ar]) != null) {
                    for (ap in ax) {
                        an = at[ap];
                        ao = ax[ap];
                        if (at === ao) {
                            continue
                        }
                        if (aw && ao && (af.isPlainObject(ao) || (au = af.isArray(ao)))) {
                            if (au) {
                                au = false;
                                av = an && af.isArray(an) ? an : []
                            } else {
                                av = an && af.isPlainObject(an) ? an : {}
                            }
                            at[ap] = af.extend(aw, av, ao)
                        } else {
                            if (ao !== undefined) {
                                at[ap] = ao
                            }
                        }
                    }
                }
            }
            return at
        },
        ajax: function(aq) {
            if (!aq) {
                return
            }

            function ao() {
                if (typeof XMLHttpRequest !== "undefined") {
                    return new XMLHttpRequest()
                } else {
                    var au = ["MSXML2.XmlHttp.5.0", "MSXML2.XmlHttp.4.0", "MSXML2.XmlHttp.3.0", "MSXML2.XmlHttp.2.0", "Microsoft.XmlHttp"];
                    for (var aw = 0, at = au.length;aw < at;aw++) {
                        try {
                            return new ActiveXObject(au[aw])
                        } catch (av) { }
                    }
                }
                return null
            }
            var ar = ao();

            function i(at) {
                return function() {
                    if (at.readyState < 4) {
                        return
                    }
                    if (at.status !== 200 && at.status !== 0) {
                        (aq.error || function() { })(at, "error", at.statusText);
                        return
                    }
                    if (at.readyState === 4) {
                        var av = null;
                        try {
                            av = JSON.parse(at.responseText)
                        } catch (au) { } (aq.success || function() { })(av, "success", at)
                    }
                }
            }
            if (aq.async === true) {
                ar.onreadystatechange = i(ar)
            }
            ar.open(aq.type || "get", aq.url || "", aq.async === true);
            var ap = aq.headers || {};
            for (var an in ap) {
                if (!ap.hasOwnProperty(an)) {
                    continue
                }
                ar.setRequestHeader(an, ap[an])
            }
            ar.send(aq.data);
            if (aq.async !== true) {
                i(ar)()
            }
        },
        each: function(an, ao) {
            if (this.isArray(an)) {
                an.forEach(function(aq, ap) {
                    ao(ap, aq)
                })
            } else {
                for (var i in an) {
                    if (an.hasOwnProperty(i)) {
                        ao(i, an[i])
                    }
                }
            }
        }
    };
    var M = 0;
    var y = {};

    function ab() {
        return window.__rhoJsVmID || null
    }

    function t() { }

    function b(i) {
        if ("undefined" == typeof i || !i) {
            i = W
        }
        return (i + "#" + M++)
    }

    function F(i) {
        if (i && "string" == typeof i) {
            return (i.toLowerCase() == "true")
        }
        return false
    }

    function h(ap, an, ao) {
        if ("string" == typeof ap) {
            return ap
        }
        if ("function" != typeof ap) {
            ap = t
        }
        if ("undefined" == typeof ao || !ao) {
            ao = b()
        }
        var i = {
            id: ao,
            callback: ap,
            isPersistent: ("undefined" != typeof an) && an
        };
        y[ao] = i;
        return w + ao
    }

    function R(i) {
        for (var an in i) {
            if (!i.hasOwnProperty(an)) {
                continue
            }
            if ("object" == typeof i[an]) {
                i[an] = s(i[an])
            }
        }
        return i
    }

    function s(i) {
        if ((i != null) && ("object" == typeof i)) {
            if (i[W] && i[am]) {
                return N(i[am], i[W])
            } else {
                return R(i)
            }
        }
        return i
    }

    function l(i) {
        if ("undefined" == typeof i) {
            throw "Invalid API JSON response"
        }
        if (null == i || "object" != typeof i) {
            return i
        }
        var an = af.extend(i instanceof Array ? [] : {}, i);
        return s(an)
    }

    function e(ap) {
        var ao = {};
        if ("string" == typeof ap) {
            ap = ap.split(/[\s\,]/)
        }
        if (ap instanceof Array) {
            for (var an = 0;an < ap.length;an++) {
                if (0 < ap[an].length) {
                    ao[ap[an]] = null
                }
            }
        } else {
            if (ap instanceof Object) {
                ao = ap
            }
        }
        return ao
    }

    function U(aq) {
        var ap = [];
        if ("string" == typeof aq) {
            aq = aq.split(/[\s\,]/)
        }
        if (aq instanceof Array) {
            for (var ao = 0;ao < aq.length;ao++) {
                if (0 < aq[ao].length) {
                    ap.push(aq[ao])
                }
            }
        } else {
            if (aq instanceof Object) {
                for (var an in aq) {
                    if (aq.hasOwnProperty(an) && 0 < an.length) {
                        ap.push(an)
                    }
                }
            }
        }
        return ap
    }
    var ai = 0;

    function p(i, an) {
        if ("function" != typeof an) {
            return an
        }
        return G + ":" + h(an, true)
    }

    function O(ap) {
        var aq = null;
        if ("number" == typeof ap.valueCallbackIndex) {
            if (ap.valueCallbackIndex < ap.args.length - 1) {
                throw "Generated API method error: wrong position for value callback argument!"
            }
            if (ap.valueCallbackIndex == ap.args.length - 1) {
                aq = ap.args.pop()
            }
            if (aq && "function" != typeof aq) {
                throw "Value callback should be a function!"
            }
        }
        var au = null;
        var ao = null;
        if ("number" == typeof ap.persistentCallbackIndex) {
            if (ap.persistentCallbackIndex < ap.args.length - 2) {
                throw "Generated API method error: wrong position for persistent callback argument!"
            }
            if (ap.persistentCallbackIndex == ap.args.length - 2) {
                ao = ap.args.pop();
                au = ap.args.pop()
            } else {
                if (ap.persistentCallbackIndex == ap.args.length - 1) {
                    au = ap.args.pop()
                }
            }
            if (au && "function" != typeof au) {
                throw "Persistent callback should be a function!"
            }
            if (ao && "string" != typeof ao) {
                throw "Persistent callback optional parameters should be a string!"
            }
            var ao = ao || null;
            if (au) {
                au = h(au, true)
            }
        }
        var an = {
            method: ap.method,
            params: ap.args
        };
        an[am] = ap.module;
        an[W] = ap.instanceId || null;
        an.jsonrpc = "2.0";
        an.id = ai++;
        if (au) {
            an[G] = {
                id: au,
                vmID: ab(),
                optParams: ao
            }
        }
        var ar = JSON.stringify(an, p);
        var av = null;

        function at(aw) {
            av = l(aw);
            if (aq) {
                aq(av)
            }
        }

        function i(aw) {
            throw aw.message
        }
        window.Rho.platform.nativeApiCall(ar, null != aq, function(aw) {
            if (aw.error) {
                i(aw.error)
            } else {
                at(aw.result)
            }
        });
        return (null != aq) ? null : av
    }

    function ag(i) {
        return function(an) {
            an.args = Array.prototype.slice.call(an.args);
            if ("getProperties" == an.method && 0 < an.args.length) {
                an.args[0] = U(an.args[0])
            }
            an.module = i;
            an.method = an.method;
            return O(an)
        }
    }

    function Z(i, aq, ap) {
        var ar = aq;
        var au = i;
        var ao = false;
        if ("function" == typeof ar) {
            if ("function" == typeof au && !ap) {
                throw "Namespace definition conflict!"
            }
            ao = true;
            ar = i;
            au = aq
        }
        for (var at in ar) {
            if (!ar.hasOwnProperty(at)) {
                continue
            }
            if (au.hasOwnProperty(at) && !ap) {
                continue
            }
            if ("prototype" == at) {
                if (ao) {
                    continue
                }
                if ("object" != typeof au[at]) {
                    au[at] = {}
                }
                for (var an in ar[at]) {
                    if (!ar[at].hasOwnProperty(an)) {
                        continue
                    }
                    au[at][an] = ar[at][an]
                }
                continue
            }
            au[at] = ar[at]
        }
        return au
    }

    function aa(au, at, an) {
        at = at || {};
        var av = window;
        var ap = au.split(/[\:\.]/);
        var aw = "";
        for (var aq = 0;aq < ap.length;aq++) {
            var ao = ap[aq];
            aw = aw + (aq == 0 ? "" : ".") + ao;
            var ar = av[ao];
            if (!(ar instanceof Object || "undefined" == typeof ar)) {
                throw "Namespace " + aw + " is already defined and it isn't an object!"
            }
            if (aq == ap.length - 1) {
                if (av[ao]) {
                    av[ao] = Z(av[ao], at, an)
                } else {
                    av[ao] = at
                }
            }
            av[ao] = av[ao] || {};
            av = av[ao]
        }
        return av
    }
    var P = {
        ffHackKeywords: false,
        ffHackMethod: false,
        js185: false
    };
    (function z() {
        P.ffHackKeywords = (function an() {
            var aq = {};
            var ap = false;
            var at = false;
            try {
                aq = {
                    get propGet() {
                        ap = true;
                        return ap
                    },
                    set propSet(au) {
                        at = au
                    }
                };
                aq.propSet = aq.propGet
            } catch (ar) { }
            return ap && at
        })();
        P.ffHackMethod = (function ao() {
            var aq = {};
            var ap = false;
            var at = false;
            try {
                aq.__defineGetter__("propGet", function() {
                    ap = true;
                    return ap
                });
                aq.__defineSetter__("propSet", function(au) {
                    at = au
                });
                aq.propSet = aq.propGet
            } catch (ar) { }
            return ap && at
        })();
        P.js185 = (function i() {
            var aq = {};
            var ap = false;
            var at = false;
            try {
                Object.defineProperty(aq, "propGet", {
                    get: function() {
                        ap = true;
                        return ap
                    }
                });
                Object.defineProperty(aq, "propSet", {
                    set: function(au) {
                        at = au
                    }
                });
                aq.propSet = aq.propGet
            } catch (ar) { }
            return ap && at
        })()
    })();
    var n = function(ap, ar, i, aq) {
        var ao = ar.split(":")[0];

        function an(at, ay) {
            var ax = ay.split(":");
            var aw = ax[0];
            var av = ax[1];
            var au = ax[2];
            if (("get" == at) && av) {
                return av
            }
            if (("set" == at) && au) {
                return au
            }
            return at + aw.charAt(0).toUpperCase() + aw.slice(1)
        }
        if (null != i && "function" == typeof i) {
            ap[an("get", ar)] = i
        }
        if (null != aq && "function" == typeof aq) {
            ap[an("set", ar)] = aq
        }
    };
    var a = n;
    if (P.js185) {
        a = function(ap, ar, i, aq) {
            var ao = ar.split(":")[0];
            var an = {
                configurable: true,
                enumerable: false
            };
            if (null != i && "function" == typeof i) {
                an.get = i
            }
            if (null != aq && "function" == typeof aq) {
                an.set = aq
            }
            Object.defineProperty(ap, ao, an)
        }
    } else {
        if (P.ffHackMethod) {
            a = function(ao, aq, i, ap) {
                var an = aq.split(":")[0];
                ao.__defineGetter__(an, i);
                ao.__defineSetter__(an, ap)
            }
        } else { }
    }

    function m(aq, ap, an, i, at) {
        var ar = ("w" == an);
        var av = ap.split(":");
        ap = av[0];
        var ao = ap;
        var au = ap + "=";
        if (2 < av.length) {
            au = av[2]
        }
        if (1 < av.length) {
            ao = av[1]
        }
        return function() {
            try {
                if ("function" == typeof at) {
                    return at.apply(this, arguments)
                }
            } catch (aw) {
                throw "Custom accessor function exception: " + aw
            }
            return aq({
                instanceId: ("function" == typeof i) ? i.apply(this, []) : v,
                args: arguments,
                method: ar ? au : ao,
                valueCallbackIndex: (ar ? 1 : 0)
            })
        }
    }
    var u = [];

    function r(ap, aw, ar, an) {
        if (!(aw instanceof Array)) {
            throw "Property definitions list should be Array instance"
        }
        for (var aq = 0;aq < aw.length;aq++) {
            var ax = aw[aq];
            var au = ax.propAccess;
            var av = (0 <= au.indexOf("r")) ? m(ar, ax.propName, "r", an, ax.customGet) : null;
            var ao = (0 <= au.indexOf("w")) ? m(ar, ax.propName, "w", an, ax.customSet) : null;
            try {
                a(ap, ax.propName, av, ao)
            } catch (at) {
                u.push(name)
            }
            n(ap, ax.propName, av, ao)
        }
    }

    function D(ar, ap, aq) {
        if (!(ap instanceof Array)) {
            throw "Property definitions list should be Array instance"
        }
        if (a != n || true == aq) {
            for (var ao = 0;ao < ap.length;ao++) {
                var at = ap[ao];
                try {
                    a(ar, at.propName, at.propGetter, at.propSetter)
                } catch (an) {
                    u.push(name)
                }
            }
        }
    }

    function d(aq, an, ap, i, ao) {
        return function() {
            return i({
                instanceId: ("function" == typeof ao) ? ao.apply(this, []) : v,
                args: arguments,
                method: aq,
                persistentCallbackIndex: an,
                valueCallbackIndex: ap
            })
        }
    }

    function aj(ar, au, an, aq) {
        if (!(au instanceof Array)) {
            throw "Property definitions list should be Array instance"
        }
        for (var ap = 0;ap < au.length;ap++) {
            var at = au[ap];
            try {
                ar[at.methodName] = d(at.nativeName, at.persistentCallbackIndex, at.valueCallbackIndex, an, aq)
            } catch (ao) {
                u.push(at.methodName)
            }
        }
    }

    function k(ao, an, i) {
        if (!an) {
            throw "No parent namespace for alias!"
        }
        if (an[i]) {
            throw "Alias definition conflict!"
        }
        an[i] = ao
    }

    function N(an, ao) {
        var i = {};
        i[am] = an;
        i[W] = ao;
        return new (aa(an))(i)
    }

    function x(ap, ao, i) {
        var aq = document.createElement(ao);
        for (var an in i) {
            if (!i.hasOwnProperty(an)) {
                continue
            }
            aq.setAttribute(an, i[an])
        }
        ap.appendChild(aq)
    }

    function E(i) {
        x(document.getElementsByTagName("head")[0], "link", {
            rel: "stylesheet",
            href: i
        })
    }

    function j(i) {
        x(document.getElementsByTagName("head")[0], "script", {
            type: "text/javascript",
            src: i
        })
    }

    function c(aq) {
        var ar = null;
        var an = document.getElementsByTagName("script");
        for (var ap = 0;ap < an.length;ap++) {
            var ao = (an[ap].getAttribute("src") || "").replace(/\?.*$/gi, "");
            if (ao.indexOf(aq, ao.length - aq.length) !== -1) {
                ar = an[ap];
                break
            }
        }
        return ar
    }
    var L = c(Y);

    function ak(i) {
        if (null == L || null == i || !i) {
            return
        }
        var an = L.getAttribute("src");
        j(an.replace(Y, i + ".js"))
    }

    function I(an) {
        if (null == an) {
            return
        }
        var ao = [];
        if ("object" == typeof an && "Array" == an.constructor.name) {
            ao = an
        }
        if ("string" == typeof an) {
            ao = an.trim().split(/[,\s]+/)
        }
        for (var aq = 0;aq < ao.length;aq++) {
            var ap = ao[aq].trim();
            if (0 < ap.length) {
                ap = (0 == ap.indexOf("Rho.")) ? ap : "rho." + ap;
                ak(ap)
            }
        }
    }
    if (null != L) {
        I(L.getAttribute("data-api-modules"))
    }

    function q(ar, an) {
        var ao = decodeURIComponent(ar);
        var aq = y[ao];
        if ("object" == typeof aq && aq) {
            if ("function" == typeof aq.callback) {
                var i = null;
                var ap = null;
                if (an) {
                    ap = an.error;
                    if (!ap) {
                        i = l(an.result)
                    }
                }
                aq.callback(i, ap)
            }
            if (!aq.isPersistent) {
                delete y[ao]
            }
        }
    }
    var ae = "RHO_AJAX-->12345<--PORT_NUMBER";
    var g = "http://127.0.0.1";
    var C = null;

    function K() {
        if (C) {
            return C
        }
        var an = window[Rho.util.flag.API_AJAX_URL];
        if (an) {
            return (C = an)
        }
        if (0 != window.location.protocol.indexOf("file")) {
            return (C = B)
        }
        var ao = ae.replace(/[\-<>A-Z_]*/g, "");
        var i = Number(ao);
        if (0 < ao.length && !isNaN(i)) {
            return (C = (g.replace(/\/$/, "") + ":" + i + B))
        }
        throw "Unknown API AJAX URL for application loaded with file:// protocol"
    }

    function X(ao, an, i) {
        af.ajax({
            async: an,
            type: "post",
            url: K(),
            data: ao,
            dataType: "json",
            headers: {
                Accept: "text/plain"
            },
            success: function(ap) {
                i(ap)
            },
            error: function(ar, ap, aq) {
                i({
                    error: {
                        message: aq,
                        code: ar.status
                    }
                })
            }
        })
    }

    function J(i) {
        if ("undefined" != typeof window.RhoOld) {
            if ("object" == typeof window.RhoOld) {
                for (var an in window.RhoOld) {
                    if (window.RhoOld.hasOwnProperty(an) && "undefined" != typeof window.RhoOld[an] && "undefined" == typeof i[an]) {
                        i[an] = window.RhoOld[an]
                    }
                }
            }
            window.RhoOld = undefined
        }
        return i
    }
    var T = {
        flag: {
            API_AJAX_URL: "__rho_apiAjaxURL",
            USE_AJAX_BRIDGE: "__rho_useAjaxBridge",
            NATIVE_BRIDGE_TYPE: "__rho_nativeBridgeType"
        },
        loadApiModules: I,
        namespace: aa,
        namespaceAlias: k,
        apiReqFor: ag,
        namesToProps: e,
        namesToArray: U,
        createPropsProxy: r,
        createRawPropsProxy: D,
        createMethodsProxy: aj,
        methodAccessReqFunc: d,
        incompatibleProps: u,
        rhoIdParam: function() {
            return W
        },
        rhoClassParam: function() {
            return am
        },
        nextId: b
    };
    var o = {
        id: {
            AJAX: "ajax",
            AUTO: "auto",
            RHOSIMULATOR: "rhosimulator",
            ANDROID: "android",
            IPHONE: "iphone",
            WP8: "wp8",
            WM: "wm",
            WIN32: "win32"
        },
        nativeApiCall: X,
        nativeApiResult: function() { }
    };
    var V = J({
        jQuery: af,
        util: T,
        platform: o,
        callbackHandler: q
    });
    var ac = "__rhoNativeApiCall";
    var S = "__rhoNativeApi";
    var A = {};
    var f = function(an, i) {
        A[an] = function() {
            var ao = i();
            ao.apiCall.platformId = an;
            return ao
        }
    };
    f(o.id.ANDROID, function() {
        return {
            apiCall: function(ap, ao, an) {
                var i = {};
                if (window[S] && "function" == typeof window[S]["apiCall"]) {
                    i = window[S].apiCall(ap, ao)
                } else {
                    i = prompt(ap, ac + ":prompt")
                }
                an(JSON.parse(i))
            }
        }
    });
    f(o.id.IPHONE, function() {
        return window[S] || {
            apiCall: function(ap, an, i) {
                var ao = {};
                ao[ac] = ap;
                af.ajax({
                    async: an,
                    type: "head",
                    url: "/!" + S + "?" + (+new Date()),
                    dataType: "json",
                    headers: ao,
                    success: function(at, aq, ar) {
                        i(JSON.parse(ar.responseText))
                    },
                    error: function(at, aq, ar) {
                        i({
                            error: {
                                message: ar,
                                code: at.status
                            }
                        })
                    }
                })
            }
        }
    });
    f(o.id.WP8, function() {
        var i = undefined;
        o.nativeApiResult = function(an) {
            i = an
        };
        window.__rhoNativeApiResult = o.nativeApiResult;
        return {
            apiCall: function(ap, ao, an) {
                window.external.notify(ap);
                an(JSON.parse(i))
            }
        }
    });
    f(o.id.WM, function() {
        var i = new WebkitBridge();
        return {
            apiCall: function(ap, ao, an) {
                var aq = i.framework(ap);
                an(JSON.parse(aq))
            }
        }
    });
    f(o.id.RHOSIMULATOR, function() {
        return {
            apiCall: function(ap, ao, an) {
                var i = {};
                if (window[S] && "function" == typeof window[S]["apiCall"]) {
                    i = window[S].apiCall(ap, ao)
                }
                an(JSON.parse(i))
            }
        }
    });
    f(o.id.WIN32, function() {
        return {
            apiCall: function(ap, ao, an) {
                var i = {};
                if (window[S] && "function" == typeof window[S]["apiCall"]) {
                    i = window[S].apiCall(ap, ao)
                }
                an(JSON.parse(i))
            }
        }
    });
    var H = o.nativeApiCall;
    f(o.id.AJAX, function() {
        return {
            apiCall: H
        }
    });
    var al = [
        [/RhoSimulator/, A[o.id.RHOSIMULATOR]],
        [/Android/, A[o.id.ANDROID]],
        [/iPhone|iPod|iPad/, A[o.id.IPHONE]],
        [/Windows\s+Phone/, A[o.id.WP8]],
        [/Windows\s+(?:Mobile|CE)|WM [0-9]/, A[o.id.WM]],
        [/Windows/, A[o.id.WIN32]]
    ];
    var ad = null;
    for (var ah = 0;ah < al.length;++ah) {
        if (al[ah][0].test(navigator.userAgent)) {
            ad = al[ah][1];
            break
        }
    }
    if (!ad) {
        if (console && console.warn) {
            console.warn("User-Agent [" + navigator.userAgent + "] does not match any known platform. Falling back to use AJAX bridge by default.")
        }
        ad = A[o.id.AJAX]
    }
    o.nativeApiCall = function() {
        var i = A[window[T.flag.NATIVE_BRIDGE_TYPE]] || ad;
        i().apiCall.apply(this, arguments)
    };
    return V
})("undefined" == typeof jQuery ? undefined : jQuery);
window.EB = window.Rho;