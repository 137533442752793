import React, { FC } from 'react';
import { Select } from 'antd';
import { PalletSize } from '../../../../store/api/apiTypes';
import { translatePalletSize } from '../../../../helpers/i18n';
import ArrowNavItem from '../../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../../components/ButtonWithShortcut';

interface PalletSizeModalContentProps {
    shortcutScope: string;
    size: PalletSize | undefined;
    setSize: React.Dispatch<React.SetStateAction<PalletSize | undefined>>;
}

interface PalletSizeModalFooterProps {
    shortcutScope: string;
    size: PalletSize | undefined;
    onConfirm: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const PalletSizeModalContent: FC<PalletSizeModalContentProps> = ({ shortcutScope, size, setSize }) => {
    return (
        <ArrowNavItem scope={shortcutScope}>
            <Select style={{ width: '100%' }} value={size} onChange={setSize} placeholder="Choisir une taille">
                {Object.values(PalletSize).map((size) => (
                    <Select.Option value={size} key={size}>
                        {translatePalletSize(size)}
                    </Select.Option>
                ))}
            </Select>
        </ArrowNavItem>
    );
};

const PalletSizeModalFooter: FC<PalletSizeModalFooterProps> = ({ shortcutScope, size, onConfirm, onClose }) => {
    return (
        <>
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut
                    type="primary"
                    shortcut="enter"
                    shortcutScope={shortcutScope}
                    onClick={onConfirm}
                    disabled={!size}
                >
                    Suivant
                </ButtonWithShortcut>
            </ArrowNavItem>
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut type="primary" shortcut="esc" shortcutScope={shortcutScope} onClick={onClose} ghost>
                    Annuler
                </ButtonWithShortcut>
            </ArrowNavItem>
        </>
    );
};

export { PalletSizeModalContent, PalletSizeModalFooter };
