import { SearchPaginationQuery } from '.';
import { PlatformReasonCode } from './apiTypes';
import api from './_client';

export type PlatformReasonCodeListPayload = SearchPaginationQuery;
export const list = async (payload: PlatformReasonCodeListPayload = {}) =>
    await api.get('/platformReasonCodes', { params: payload });

export type PlatformReasonCodeCreatePayload = Omit<PlatformReasonCode, 'id'>;
export const create = async (payload: PlatformReasonCodeCreatePayload) =>
    await api.post('/platformReasonCodes', payload);

export type PlatformReasonCodeUpdatePayload = PlatformReasonCode & {
    platformReasonCodeId: PlatformReasonCode['id'];
};
export const update = async ({ platformReasonCodeId, ...payload }: PlatformReasonCodeUpdatePayload) =>
    await api.put(`/platformReasonCodes/${platformReasonCodeId}`, payload);

export interface PlatformReasonCodeDeletePayload {
    platformReasonCodeId: PlatformReasonCode['id'];
}
export const del = async ({ platformReasonCodeId }: PlatformReasonCodeDeletePayload) =>
    await api.delete(`/platformReasonCodes/${platformReasonCodeId}`);
