import React, { ElementType, forwardRef, ForwardRefRenderFunction, useCallback } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { useHotkeys, Options } from 'react-hotkeys-hook';

import '../assets/styles/ButtonWithShortcut.less';

import { DEFAULT_SHORCUT_SCOPE, formatShortcut, getKey, shortcut as shortcutHelper } from '../helpers/shortcut';
import { useShareForwardedRef } from '../hooks';
import { debug } from '../helpers';
import { LinkProps, useHistory } from 'react-router-dom';

interface ButtonWithShortcutProps extends ButtonProps {
    shortcut: string;
    shortcutOptions?: Options;
    disableEnterShortCut?: boolean;
    shortcutScope?: string;
    hideShortcut?: boolean;
    as?: ElementType;
    to?: LinkProps['to'];
}

const filter = () => {
    // prevent shortcut triggering if currently focused element has secific class names
    // useful for preventing "enter" shortcut to trigger when an antd select element is focused
    if ([...(document.activeElement?.classList ?? [])].some((className) => ['ant-select'].includes(className))) {
        debug.log('[filter] prevented because activeElement is ant-select');
        return false;
    }

    return true;
};

// Example :
// <ButtonWithShortcut
//     type="primary"
//     shortcut="enter"
//     onClick={onClickButton}
//     as={ButtonGrey}
//     disableShortcut={isModalVisible}
// >
//     test
// </ButtonWithShortcut>
const ButtonWithShortcut: ForwardRefRenderFunction<HTMLElement, ButtonWithShortcutProps> = (
    {
        as: Component = Button,
        children,
        onClick,
        onKeyDown,
        shortcut,
        shortcutOptions,
        disableEnterShortCut,
        shortcutScope,
        hideShortcut,
        to,
        disabled,
        ...rest
    },
    ref
) => {
    const history = useHistory();
    const actualShortcut = getKey(shortcut);
    const buttonRef = useShareForwardedRef<HTMLElement>(ref);
    const getFilter = useCallback(() => {
        if (disabled) {
            return () => false;
        }

        if (typeof shortcutOptions?.filter === 'function') {
            return (event: KeyboardEvent) => filter() && shortcutOptions?.filter!(event);
        } else {
            return filter;
        }
    }, [shortcutOptions?.filter, disabled]);
    const handler = useCallback(
        (event: KeyboardEvent) => {
            debug.group('[ButtonWithShortcut] shortcut trigger', actualShortcut, 'on', buttonRef.current?.textContent);
            const button = buttonRef.current;
            const target = event.target;
            const activeElement = document.activeElement;
            debug.log('target', target);
            debug.log('activeElement', activeElement);

            // don't trigger shortcut if the event target is a button and shortcut is enter/space
            if (
                (target as HTMLElement)?.tagName === 'BUTTON' &&
                ['enter', 'return', 'space'].includes(actualShortcut)
            ) {
                debug.log('event target is a button && shortcut is enter/space');
                debug.log('>>>> do nothing');
                debug.groupEnd();
                return;
            }

            if (target === button && onClick) {
                debug.log('target is current button and button has onClick');
                debug.log('>>>> do nothing');
                debug.groupEnd();
                return;
            }

            // if button has a shortcut scope
            if (shortcutScope) {
                debug.log('shortcutScope', shortcutScope);
                debug.log('shortcutHelper.scope', shortcutHelper.scope);
                // and current global scope is different, prevent shortcut
                if (shortcutScope !== shortcutHelper.scope) {
                    debug.log('shortcut scope', shortcutScope, 'is different than global scope', shortcutHelper.scope);
                    debug.log('>>>> do nothing');
                    debug.groupEnd();
                    return;
                } else {
                    debug.log('scope matches');
                    debug.log('>>>> trigger click');
                    debug.groupEnd();
                    button?.click();
                    return;
                }
            } else if (shortcutHelper.scope !== DEFAULT_SHORCUT_SCOPE) {
                // if button doesn't have a shortcut scope and a global scope is set, prevent shortcut
                debug.log('global scope is', shortcutHelper.scope, 'and shortcut scope is not provided');
                debug.log('>>>> do nothing');
                debug.groupEnd();
                return;
            }

            if (to) {
                event.preventDefault();
                if (typeof to === 'string') {
                    history.push(to);
                } else if (typeof to === 'object' && to.pathname) {
                    history.push(to.pathname, to.state);
                }
                return;
            }

            // if there is no onClick prop provided, simply trigger a click (eg MainMenu buttons)
            if (!onClick) {
                debug.log('no onClick provided');
                debug.log('>>>> trigger click');
                debug.groupEnd();
                button?.click();
            }

            debug.log('everything passes');
            debug.log('>>>> trigger click');
            button?.click();
            debug.groupEnd();
        },
        [buttonRef, actualShortcut, shortcutScope, onClick, to, history]
    );
    useHotkeys(actualShortcut, handler, { ...shortcutOptions, filter: getFilter() }, [
        actualShortcut,
        handler,
        shortcutScope,
        onClick,
        buttonRef,
        to,
    ]);
    const innerOnClick: ButtonProps['onClick'] = (e) => {
        debug.group('[ButtonWithShortcut] onClick', buttonRef.current);
        debug.log(e.target);

        if (typeof onClick === 'function') {
            onClick(e);
        }
        debug.groupEnd();
    };

    const innerOnKeyDown: ButtonProps['onKeyDown'] = (e) => {
        if (disableEnterShortCut && e.key.toLowerCase() === 'enter') {
            e.preventDefault();
        }

        if (typeof onKeyDown === 'function') {
            onKeyDown(e);
        }
    };

    return (
        <Component
            onClick={innerOnClick}
            onKeyDown={innerOnKeyDown}
            to={to}
            disabled={disabled}
            {...rest}
            ref={buttonRef}
        >
            {!hideShortcut && <span className="btn-shortcut">{formatShortcut(shortcut)}</span>}
            {children}
        </Component>
    );
};

export default forwardRef<HTMLElement, ButtonWithShortcutProps>(ButtonWithShortcut);
