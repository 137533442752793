import { SearchPaginationQuery } from '.';
import { Pallet, PalletSize, Place } from './apiTypes';
import api from './_client';

export interface PlaceListPayload extends SearchPaginationQuery, PlaceFiltersListPayload {
    palletId: Pallet['id'];
    size: PalletSize;
}
export const list = async (payload: PlaceListPayload) => await api.get('/places', { params: payload });

export type PlaceFiltersListPayload = Pick<Place, 'sector' | 'lane' | 'alveole'>;
export const listFilters = async (payload: PlaceFiltersListPayload) =>
    await api.get('/placesFilters', { params: payload });

export type PlaceCreatePayload = Omit<Place, 'id'>;
export const create = async (payload: PlaceCreatePayload) => await api.post('/places', payload);

export type PlaceUpdatePayload = Place & {
    placeId: Place['id'];
};
export const update = async ({ placeId, ...payload }: PlaceUpdatePayload) =>
    await api.put(`/places/${placeId}`, payload);

export interface PlaceDeletePayload {
    placeId: Place['id'];
}
export const del = async ({ placeId }: PlaceDeletePayload) => await api.delete(`/places/${placeId}`);

export interface PlaceDetailsByScanPayload {
    barcode?: Place['barcode'];
    reference?: Place['reference'];
}
export const detailsPlaceByScan = async ({ barcode, reference, ...payload }: PlaceDetailsByScanPayload) =>
    await api.get(`/places/${reference ? 'reference' : 'barcode'}/${reference ?? barcode ?? ''}`, { params: payload });
