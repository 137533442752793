import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { ClientRegularization, ParcelRegularization } from '../api/apiTypes';
import { ListResponse } from '../api';

// Controlers
import {
    ClientRegularizationListPayload,
    ParcelRegularizationListPayload,
    clientRegularizationList as clientRegularizationListApiCall,
    parcelRegularizationList as parcelRegularizationListApiCall,
    clientRegularizationDetails as clientRegularizationDetailsApiCall,
    ClientRegularizationDetailsPayload,
} from '../api/controlRegularization';

// States
export interface ControlRegularizationState {
    clientRegularizationList: RequestState<ListResponse<ClientRegularization>>;
    clientRegularizationDetails: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: ClientRegularization | undefined;
        };
    };
    parcelRegularizationList: RequestState<ListResponse<ParcelRegularization>>;
}

const initialState: ControlRegularizationState = {
    clientRegularizationList: { ...requestInitialState },
    clientRegularizationDetails: { ...requestInitialState, data: {} },
    parcelRegularizationList: { ...requestInitialState },
};

export const clientRegularizationList = new EzeeAsyncAction<
    ControlRegularizationState['clientRegularizationList'],
    ClientRegularizationListPayload,
    ListResponse<ClientRegularization>
>(
    'controlRegularization/clientRegularizationList',
    initialState.clientRegularizationList,
    requestReducer<ControlRegularizationState['clientRegularizationList'], ListResponse<ClientRegularization>>(
        initialState.clientRegularizationList
    )
);

export const clientRegularizationDetails = new EzeeAsyncAction<
    ControlRegularizationState['clientRegularizationDetails'],
    ClientRegularizationDetailsPayload,
    ClientRegularization
>('controlRegularization/clientRegularizationDetails', initialState.clientRegularizationDetails, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...state.data,
            [payload.id]: payload,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.clientRegularizationDetails,
    }),
});

export const parcelRegularizationList = new EzeeAsyncAction<
    ControlRegularizationState['parcelRegularizationList'],
    ParcelRegularizationListPayload,
    ListResponse<ParcelRegularization>
>(
    'controlRegularization/parcelRegularizationList',
    initialState.parcelRegularizationList,
    requestReducer<ControlRegularizationState['parcelRegularizationList'], ListResponse<ParcelRegularization>>(
        initialState.parcelRegularizationList
    )
);

// Reducer
export const controlRegularizationReducer = combineReducers<ControlRegularizationState>({
    clientRegularizationList: clientRegularizationList.reducer,
    clientRegularizationDetails: clientRegularizationDetails.reducer,
    parcelRegularizationList: parcelRegularizationList.reducer,
});

// Saga
export function* controlRegularizationSaga() {
    yield takeLatest(
        clientRegularizationList.type.trigger,
        simpleAsyncSaga(clientRegularizationListApiCall, clientRegularizationList)
    );
    yield takeLatest(
        clientRegularizationDetails.type.trigger,
        simpleAsyncSaga(clientRegularizationDetailsApiCall, clientRegularizationDetails)
    );
    yield takeLatest(
        parcelRegularizationList.type.trigger,
        simpleAsyncSaga(parcelRegularizationListApiCall, parcelRegularizationList)
    );
}

// Store helpers
export const getClientRegularizationListState = (state: MainReducerState) =>
    state.controlRegularization.clientRegularizationList;
export const getClientRegularizationStateById = (id?: ClientRegularization['id']) => (state: MainReducerState) => ({
    loading: state.controlRegularization.clientRegularizationDetails.loading,
    error: state.controlRegularization.clientRegularizationDetails.error,
    data: id ? state.controlRegularization.clientRegularizationDetails.data[id] : undefined,
});
export const getParcelRegularizationListState = (state: MainReducerState) =>
    state.controlRegularization.parcelRegularizationList;
