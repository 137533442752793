import { combineReducers } from 'redux';
import { call, delay, put, race, take, takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestInitialState } from '../helpers';

// Types
import { PalletPlace } from '../api/apiTypes';

// Controlers
import {
    PalletPlaceDetailsPayload,
    details as detailsApiCall,
    list as listApiCall,
    PalletPlaceListPayload,
} from '../api/palletPlaces';
import { ListResponse } from '../api';
import { DataAction, EzeeAction } from '../helpers/EzeeAction';

// States
export interface PalletPlacesState {
    details: RequestState<{
        [id: number]: PalletPlace;
    }>;
    list: RequestState<ListResponse<PalletPlace>>;
}

const initialState: PalletPlacesState = {
    details: { ...requestInitialState, data: {} },
    list: { ...requestInitialState },
};

export const details = new EzeeAsyncAction<PalletPlacesState['details'], PalletPlaceDetailsPayload, PalletPlace>(
    'palletPlaces/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

export const list = new EzeeAsyncAction<PalletPlacesState['list'], PalletPlaceListPayload, ListResponse<PalletPlace>>(
    'palletPlaces/list',
    initialState.list,
    {
        trigger: (state, _, meta) => ({
            ...state,
            loading: true,
            data: meta?.loadMore ? state.data : undefined,
        }),
        success: (state, payload, meta) => {
            return {
                data: {
                    ...payload,
                    items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
                },
                loading: false,
            };
        },
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.list,
        }),
    }
);

export const listPolling = new EzeeAction(
    'palletPlaces/listPolling',
    {},
    {
        stopPolling: () => ({}),
        startPolling: () => ({}),
    }
);

// Reducer
export const palletPlacesReducer = combineReducers<PalletPlacesState>({
    details: details.reducer,
    list: list.reducer,
});

// Saga
export function* palletPlacesSaga() {
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(list.type.trigger, listSaga);
}

function* listSaga(actionData: DataAction<PalletPlaceListPayload>) {
    if (actionData.meta?.poll) {
        yield put(listPolling.actions.startPolling());
        yield race([call(listPollTask, actionData), take(listPolling.actions.stopPolling().type)]);
    } else {
        try {
            const response = yield call(listApiCall, actionData.payload);

            return yield put(list.success(response, actionData.meta));
        } catch (error) {
            return yield put(list.failure(error, actionData.meta));
        }
    }
}

/* Worker Function */
function* listPollTask(action: DataAction<PalletPlaceListPayload>) {
    while (true) {
        try {
            const response = yield call(listApiCall, action.payload);

            yield put(list.success(response));
            yield delay(10000);
        } catch (error) {
            yield put(list.failure(error));
            yield delay(10000);
        }
    }
}

// Store helpers
export const getPalletPlaceDetailsState = (id: PalletPlace['id']) => (state: MainReducerState) => ({
    loading: state.palletPlaces.details.loading,
    error: state.palletPlaces.details.error,
    data: state.palletPlaces.details.data?.[id],
});
export const getPalletPlaceListState = (state: MainReducerState) => state.palletPlaces.list;
