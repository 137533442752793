import React, { FC, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge, Button, Col, Descriptions, Divider, message, Row, Spin } from 'antd';

import {
    details as detailsPalletAction,
    update as updatePalletAction,
    getPalletUpdateState,
    getPalletDetailsStateById,
} from '../../store/actions/pallets';
import { list as listPlacesAction, getPlacesListState } from '../../store/actions/places';
import { Place, PlaceZoneType } from '../../store/api/apiTypes';

import FixedFooter from '../../components/FixedFooter';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import List, { ListColumn } from '../../components/list/List';
import { translatePalletSize } from '../../helpers/i18n';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonGrey from '../../components/ButtonGrey';
import { getRawRoute, getRoute, RoutePathName } from '../../routes';
import { useActions, useIsMobile, usePrevious } from '../../hooks';
import useQueryParams from '../../hooks/queryParams';
import { urlSearchParamsToObject } from '../../helpers';
import { useModal } from '@ebay/nice-modal-react';
import SizePalletModal from './SizePalletModal';
import ReceptionPalletPlaceEditFilter from './ReceptionPalletPlaceEditFilter';
import ReceptionPalletPlaceEditSearch from './ReceptionPalletPlaceEditSearch';
import ButtonLink from '../../components/ButtonLink';

const ReceptionPalletPlaceEdit: FC = () => {
    const isMobile = useIsMobile();
    const { receptionId, palletId } = useParams<{ receptionId: string; palletId: string }>();
    const { from } = useLocation<{ from: string }>().state || {};
    const [fetchPallet, updatePallet, fetchPlaces] = useActions([
        detailsPalletAction.trigger,
        updatePalletAction.trigger,
        listPlacesAction.trigger,
    ]);
    const [queryParams] = useQueryParams('receptionPlaceEdit');
    const palletState = useSelector(getPalletDetailsStateById(parseInt(palletId, 10)));
    const placesListState = useSelector(getPlacesListState);
    const palletUpdateState = useSelector(getPalletUpdateState);
    const previous = usePrevious({ palletUpdateState });
    const [selectedPlace, setSelectedPlace] = useState<Place | undefined>();
    const history = useHistory();
    const sizePalletModal = useModal(SizePalletModal);

    const onLoadMore = () => {
        fetchPlaces(
            {
                ...urlSearchParamsToObject(queryParams),
                zoneType: PlaceZoneType.mass,
                excludePlace: palletState.data?.reservedPlace?.id,
                size: palletState.data?.size,
                page: (placesListState.data?.page ?? 0) + 1,
            },
            { loadMore: true }
        );
    };

    const onValidatePlace = () => {
        if (selectedPlace) {
            updatePallet({
                id: palletState.data?.id,
                reservedPlaceId: selectedPlace?.id,
                storageIndex: selectedPlace?.index,
            });
        } else {
            message.error('Veuillez choisir un emplacement');
        }
    };

    const onClickSize = async () => await sizePalletModal.show({ palletId: parseInt(palletId, 10) });

    const onListRowEnter = (record: Place) => {
        setSelectedPlace(record);
    };

    const columns: Array<ListColumn<Place>> = [
        {
            dataIndex: 'sector',
            title: 'Secteur',
            flex: '1 1 33.33333%',
        },
        {
            key: 'locker',
            title: 'Casier',
            flex: '1 1 33.33333%',
            render: ({ locker }) => locker ?? '—',
        },
        {
            dataIndex: 'spot',
            title: 'Emplacement',
            flex: '1 1 33.33333%',
        },
    ];

    useEffect(() => {
        if (palletId) {
            fetchPallet({ palletId });
        }
    }, [fetchPallet, palletId]);

    useEffect(() => {
        fetchPlaces({
            ...urlSearchParamsToObject(queryParams),
            zoneType: PlaceZoneType.mass,
            excludePlace: palletState.data?.reservedPlace?.id,
            size: palletState.data?.size,
        });
    }, [fetchPlaces, queryParams, palletState.data?.reservedPlace?.id, palletState.data?.size]);

    useEffect(() => {
        if (!sizePalletModal.visible && previous?.palletUpdateState.loading && !palletUpdateState.loading) {
            if (palletUpdateState.error) {
                message.error(`Erreur lors de la mise à jour de l'emplacement`);
            } else if (palletUpdateState.data) {
                history.push({
                    pathname: from,
                    state: {
                        pallet: palletUpdateState.data,
                    },
                });
            }
        }
    }, [
        fetchPallet,
        from,
        history,
        palletId,
        palletUpdateState.data,
        palletUpdateState.error,
        palletUpdateState.loading,
        previous?.palletUpdateState.loading,
        sizePalletModal,
    ]);

    const onClickBack = () => {
        if (palletState.data) {
            history.push({
                pathname: from,
                state: {
                    pallet: palletState.data,
                },
            });
        }
    };

    return (
        <FixedFooter.Wrapper>
            <Switch>
                <Route path={getRawRoute(RoutePathName.receptionPalletPlaceEditFilter)} exact>
                    <ReceptionPalletPlaceEditFilter />
                </Route>
                <Route path={getRawRoute(RoutePathName.receptionPalletPlaceEditSearch)} exact>
                    <ReceptionPalletPlaceEditSearch reservedPlaceId={palletState.data?.reservedPlace?.id} />
                </Route>
                <Route path={getRawRoute(RoutePathName.receptionPalletPlaceEdit)} exact>
                    <Seo title="Modifier emplacement" />
                    <Header title="Modifier emplacement" onClickBack={onClickBack} />
                    <PageHeader>
                        <Spin spinning={palletState.loading}>
                            <Row gutter={16}>
                                <Col xs={12}>
                                    <Descriptions column={1} size="small" colon={false} layout="vertical">
                                        <Descriptions.Item label="Taille de la palette">
                                            {translatePalletSize(palletState.data?.size)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col xs={12}>
                                    <ArrowNavItem>
                                        <ButtonWithShortcut
                                            shortcut="F1"
                                            type="primary"
                                            size="small"
                                            onClick={onClickSize}
                                            loading={palletState.loading}
                                            ghost
                                            block
                                        >
                                            Modifier la taille
                                        </ButtonWithShortcut>
                                    </ArrowNavItem>
                                </Col>
                            </Row>
                            <Divider style={{ margin: '1rem 0' }} />
                            <Row gutter={16}>
                                <Col xs={12}>
                                    <ArrowNavItem>
                                        <ButtonWithShortcut
                                            shortcut="F2"
                                            type="primary"
                                            size="small"
                                            as={ButtonLink}
                                            to={{
                                                pathname: getRoute(RoutePathName.receptionPalletPlaceEditFilter, {
                                                    receptionId,
                                                    palletId,
                                                }),
                                                state: { from },
                                                search: queryParams.toString(),
                                            }}
                                            ghost
                                            block
                                        >
                                            Filtrer&nbsp;
                                            <Badge count={[...queryParams].length} />
                                        </ButtonWithShortcut>
                                    </ArrowNavItem>
                                </Col>
                                <Col xs={12}>
                                    <ArrowNavItem>
                                        <ButtonWithShortcut
                                            shortcut="F3"
                                            type="primary"
                                            size="small"
                                            as={ButtonLink}
                                            to={{
                                                pathname: getRoute(RoutePathName.receptionPalletPlaceEditSearch, {
                                                    receptionId,
                                                    palletId,
                                                }),
                                                state: {
                                                    from,
                                                },
                                            }}
                                            ghost
                                            block
                                        >
                                            Rechercher
                                        </ButtonWithShortcut>
                                    </ArrowNavItem>
                                </Col>
                            </Row>
                        </Spin>
                    </PageHeader>
                    <List<Place>
                        columns={columns}
                        data={placesListState.data?.items}
                        isLoading={placesListState.loading && placesListState.data?.page === undefined}
                        rowKey={(record) => `${record.id}`}
                        title="Emplacements disponibles"
                        onRowFocus={setSelectedPlace}
                        onRowClick={setSelectedPlace}
                        onRowEnterPress={onListRowEnter}
                        isRowSelected={(record) =>
                            selectedPlace
                                ? record.id === selectedPlace?.id
                                : record.id === palletState.data?.reservedPlace?.id
                        }
                        style={{ marginBottom: 48 }}
                    />
                    {placesListState.data && placesListState.data.page < placesListState.data.pageCount - 1 && (
                        <Button
                            onClick={onLoadMore}
                            loading={placesListState.loading}
                            size="small"
                            style={{ marginBottom: 16, fontSize: 14 }}
                            block
                        >
                            Plus de résultats
                        </Button>
                    )}
                    <FixedFooter>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="enter"
                                onClick={onValidatePlace}
                                loading={palletUpdateState.loading}
                                as={ButtonGrey}
                                size={isMobile ? 'middle' : 'large'}
                                block
                            >
                                Valider l&rsquo;emplacement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </FixedFooter>
                </Route>
            </Switch>
        </FixedFooter.Wrapper>
    );
};

export default ReceptionPalletPlaceEdit;
