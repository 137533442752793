import React, { FC, useCallback, useEffect, useState } from 'react';
import { PaginationProps } from 'antd';
import { useSelector } from 'react-redux';

import { PlatformAnomalyCode } from '../../../store/api/apiTypes';
import { getPlatformAnomalyCodesListState, list } from '../../../store/actions/platformAnomalyCodes';

import { useActions } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import useQueryParams from '../../../hooks/queryParams';
import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import Seo from '../../../components/Seo';
import FixedFooter from '../../../components/FixedFooter';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CodeFormModal from './CodeFormModal';

const AnomalyCodes: FC = () => {
    const [queryParams, setQueryParams] = useQueryParams('anomalyCodes');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const [isFormModalVisible, setIsFormModalVisible] = useState(false);
    const [anomalyToEdit, setAnomalyToEdit] = useState<PlatformAnomalyCode>();
    const fetchAnomalyCodes = useActions(list.trigger);
    const anomalyCodesListState = useSelector(getPlatformAnomalyCodesListState);
    const onClickPlatformAnomalyCode = (record: PlatformAnomalyCode) => {
        setAnomalyToEdit(record);
        setIsFormModalVisible(true);
    };
    const onClickAdd = () => {
        setAnomalyToEdit(undefined);
        setIsFormModalVisible(true);
    };
    const onCloseFormModal = useCallback(() => {
        setIsFormModalVisible(false);
    }, []);
    const onFormSuccess = useCallback(() => {
        fetchAnomalyCodes({
            sort: 'updateAt',
            sortOrder: 'desc',
            page,
        });
    }, [fetchAnomalyCodes, page]);
    const onPaginationChange: PaginationProps['onChange'] = (p) => {
        setQueryParams({
            page: p ? p - 1 : 0,
        });
    };
    const columns: Array<ListColumn<PlatformAnomalyCode>> = [
        {
            dataIndex: 'reference',
            title: 'Code anomalie',
            flex: '1 1 20%',
        },
        {
            dataIndex: 'label',
            title: 'Libellé',
            flex: '1 1 80%',
        },
    ];

    useEffect(() => {
        fetchAnomalyCodes({
            sort: 'updateAt',
            sortOrder: 'desc',
            page,
        });
    }, [fetchAnomalyCodes, page]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Codes anomalies" />
            <Header title="Codes anomalies" backRoute={getRoute(RoutePathName.platformSettings)} />
            <List<PlatformAnomalyCode>
                columns={columns}
                data={anomalyCodesListState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowEnterPress={onClickPlatformAnomalyCode}
                onRowClick={onClickPlatformAnomalyCode}
                isLoading={anomalyCodesListState.loading}
                style={{ marginTop: '1.5rem' }}
                pagination={{
                    total: anomalyCodesListState.data?.totalCount,
                    pageSize: anomalyCodesListState.data?.pageSize,
                    hideOnSinglePage: true,
                    current: page + 1,
                    onChange: onPaginationChange,
                }}
            />
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut onClick={onClickAdd} shortcut="1" type="primary" ghost block>
                        Ajouter un nouveau code
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
            <CodeFormModal
                visible={isFormModalVisible}
                onCancel={onCloseFormModal}
                onSuccess={onFormSuccess}
                anomalyCode={anomalyToEdit}
                isAnomaly
            />
        </FixedFooter.Wrapper>
    );
};

export default AnomalyCodes;
