import { takeLatest } from 'redux-saga/effects';
import { combineReducers } from 'redux';
// Helpers
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { requestInitialState, requestReducer } from '../helpers';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { MainReducerState, RequestState } from '../reducers';

// Types
import { MachineIncident } from '../api/apiTypes';
// Controllers
import { MachinesIncidentsCreatePayload, create as createApiCall } from '../api/machinesIncidents';

// State
export interface MachinesIncidentsState {
    create: RequestState<MachineIncident>;
}
const initialState: MachinesIncidentsState = {
    create: { ...requestInitialState },
};
export const create = new EzeeAsyncAction<
    MachinesIncidentsState['create'],
    MachinesIncidentsCreatePayload,
    MachineIncident
>(
    'machinesIncidents/create',
    initialState.create,
    requestReducer<MachinesIncidentsState['create'], MachineIncident>(initialState.create)
);

export const machinesIncidentsReducer = combineReducers<MachinesIncidentsState>({
    create: create.reducer,
});

export function* machinesIncidentsSaga() {
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
}

export const getMachineIncidentsIncidentsCreateState = (state: MainReducerState) => state.machinesIncidents.create;
