import { SearchPaginationQuery } from '.';
import { Inventory, InventoryPlace } from './apiTypes';
import api from './_client';

export type InventoryListPayload = SearchPaginationQuery;
export const list = async (payload: InventoryListPayload = {}) => await api.get('/inventories', { params: payload });

export interface InventoryDetailsPayload {
    inventoryId: Inventory['id'];
}
export const details = async ({ inventoryId, ...payload }: InventoryDetailsPayload) =>
    await api.get(`/inventories/${inventoryId}`, { params: payload });

export type InventoryUpdatePayload = Pick<Inventory, 'id'> & Partial<Inventory>;
export const update = async (payload: InventoryUpdatePayload) => await api.put(`/inventories/${payload.id}`, payload);

export type InventoryCreatePayload = Partial<Inventory>;
export const create = async (payload: InventoryCreatePayload) => await api.post('/inventories', payload);

export interface InventoryClosePayload {
    inventoryId: Inventory['id'];
}
export const close = async ({ inventoryId }: InventoryClosePayload) =>
    await api.post(`/inventories/${inventoryId}/close`);

export interface InventoryPlacesListPayload extends SearchPaginationQuery {
    inventoryId: Inventory['id'];
}
export const placeList = async (payload: InventoryPlacesListPayload) =>
    await api.get('/inventoriesPlaces', { params: payload });

export interface InventoryPlaceDetailsPayload {
    inventoryPlaceId: InventoryPlace['id'];
}
export const placeDetails = async ({ inventoryPlaceId }: InventoryPlaceDetailsPayload) =>
    await api.get(`/inventoriesPlaces/${inventoryPlaceId}`);

export type InventoryPlaceUpdatePayload = InventoryPlace;
export const placeUpdate = async (payload: InventoryPlaceUpdatePayload) =>
    await api.put(`/inventoriesPlaces/${payload.id}`, payload);

export interface InventoryOperatorListPayload {
    inventoryId: Inventory['id'];
}
export const operatorList = async (payload: InventoryOperatorListPayload) =>
    await api.get('/users', { params: payload });

export interface InventoryPlaceValidateGapPayload {
    inventoryPlaceId: InventoryPlace['id'];
    quantity?: number;
}
export const placeValidateGap = async ({ inventoryPlaceId, ...payload }: InventoryPlaceValidateGapPayload) =>
    await api.post(`/inventoriesPlaces/${inventoryPlaceId}/validateGap`, payload);
export interface InventoryDeletePayload {
    inventoryId: Inventory['id'];
}
export const del = async ({ inventoryId }: InventoryDeletePayload) => await api.delete(`/inventories/${inventoryId}`);
