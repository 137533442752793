import React, { FC } from 'react';

import BasicList from '../../components/BasicList';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ButtonLink from '../../components/ButtonLink';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import { getRoute, RoutePathName } from '../../routes';
import { useModal } from '@ebay/nice-modal-react';
import ReturnCreateModal from './customerReturn/ReturnCreateModal';

const MouvementsStockMenu: FC = () => {
    const customerReturnModal = useModal(ReturnCreateModal);
    const openCustomerReturnModal = () => {
        customerReturnModal.show();
    };

    return (
        <>
            <Seo title="Mouvements de stock" />
            <Header title="Mouvements de stock" backRoute={getRoute(RoutePathName.movementsMenu)} enableHomeButton />
            <nav className="menu">
                <BasicList>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut shortcut="1" onClick={openCustomerReturnModal} block>
                                Retour client
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="2"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.movementsRepairs)}
                                block
                            >
                                Réparation
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="3"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.movementsVariousOutputsMenu)}
                                block
                            >
                                Sorties diverses
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                </BasicList>
            </nav>
        </>
    );
};

export default MouvementsStockMenu;
