import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
    list as receptionsList,
    getReceptionsListState,
    getReceptionCreateState,
} from '../../store/actions/receptions';
import { Reception, ReceptionStatus } from '../../store/api/apiTypes';

import ArrowNavItem from '../../components/ArrowNavItem';
import BasicList from '../../components/BasicList';
import ButtonGrey from '../../components/ButtonGrey';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import Header from '../../components/Header';
import List, { ListColumn } from '../../components/list/List';
import PageHeader from '../../components/PageHeader';
import Seo from '../../components/Seo';
import { useActions, useIsDesktop } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import { formatNumber } from '../../helpers/i18n';
import { getParcelScanState } from '../../store/actions/parcels';
import AddPackageModal from './AddPackageModal';
import { useModal } from '@ebay/nice-modal-react';
import { useHistory } from 'react-router-dom';
import ReceptionPalletScanModal from './ReceptionPalletScanModal';

const ReceptionList: FC = () => {
    const history = useHistory();
    const listReceptions = useActions(receptionsList.trigger);
    const receptionsListState = useSelector(getReceptionsListState);
    const parcelScanState = useSelector(getParcelScanState);
    const receptionCreateState = useSelector(getReceptionCreateState);
    const isDesktop = useIsDesktop();
    const addPackageModal = useModal(AddPackageModal);
    const receptionPalletScanModal = useModal(ReceptionPalletScanModal);
    const columns: Array<ListColumn<Reception>> = [
        {
            dataIndex: 'parcel.reference',
            title: 'Référence colis',
            flex: '1 1 70%',
        },
        {
            title: 'Qté',
            flex: '1 1 30%',
            render: (record) => (
                <span style={{ fontSize: '2rem', lineHeight: '2rem', fontWeight: 300 }}>
                    {formatNumber(record.quantity)}
                </span>
            ),
        },
    ];

    useEffect(() => {
        listReceptions({
            status: [ReceptionStatus.inProgress, ReceptionStatus.pending],
        });
    }, [listReceptions]);

    const onClickAddPackage = async () => await addPackageModal.show();
    const onClickStorePallets = async () => await receptionPalletScanModal.show();

    const onClickReception = (record: Reception) => {
        if (record.parcel?.id) {
            history.push(
                getRoute(RoutePathName.receptionParcel, {
                    receptionId: record.id,
                    parcelId: record.parcel.id,
                })
            );
        }
    };

    return (
        <>
            <Seo title="Réception" />
            <Header title="Réception" backRoute={getRoute(RoutePathName.home)} enableHomeButton />
            <PageHeader>
                <BasicList inline={isDesktop} inlineStretch={isDesktop}>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="F1"
                                as={ButtonGrey}
                                onClick={onClickAddPackage}
                                loading={parcelScanState.loading || receptionCreateState.loading}
                                block
                            >
                                Ajouter un colis
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    {!!receptionsListState.data?.totalCount && (
                        <li>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    shortcut="F2"
                                    type="primary"
                                    onClick={onClickStorePallets}
                                    ghost
                                    block
                                >
                                    Rangement des palettes
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </li>
                    )}
                </BasicList>
            </PageHeader>
            <List<Reception>
                columns={columns}
                data={receptionsListState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowEnterPress={onClickReception}
                onRowClick={onClickReception}
                isRowHiglighted={(record) => record.status === ReceptionStatus.pending}
                isLoading={receptionsListState.loading}
                title="Colis reçus"
                hideWhenEmpty
            />
        </>
    );
};

export default ReceptionList;
