import { Form, Input, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Customer } from '../../../store/api/apiTypes';
import { getCustomersListState, list } from '../../../store/actions/customers';

import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';

const shortcutScope = 'SearchPlatformRdvModal';

const SearchPlatformRdvModal: FC<CustomModalProps> = ({ visible, onCancel }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const history = useHistory();
    const [customerReference, setCustomerReference] = useState<Customer['reference']>();
    const [listCustomers] = useActions([list.trigger]);
    const customersListState = useSelector(getCustomersListState);
    const previous = usePrevious({ customersListState });
    const onSubmit = () => {
        listCustomers({
            reference: customerReference,
        });
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    useEffect(() => {
        if (previous?.customersListState.loading && !customersListState.loading) {
            if (customersListState.error) {
                message.error(
                    `Une erreur est survenue pendant la recherche de rendez-vous (${customersListState.error.status})`
                );
            } else {
                if (customersListState.data?.items.length) {
                    history.push({
                        pathname: getRoute(RoutePathName.platformRdv),
                        search: new URLSearchParams({
                            customerId: `${customersListState.data?.items?.[0].id ?? ''}`,
                        }).toString(),
                    });
                } else {
                    message.error('Aucun résultat trouvé pour ce code client');
                }
            }
        }
    }, [
        previous?.customersListState.loading,
        customersListState.loading,
        customersListState.error,
        history,
        customersListState.data?.items.length,
        customerReference,
        customersListState.data?.items,
    ]);

    useEffect(() => {
        if (!visible) {
            setCustomerReference(undefined);
        }
    }, [visible]);

    return (
        <CustomModal
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onSubmit}
                            disabled={!customerReference}
                            loading={customersListState.loading}
                        >
                            Rechercher le client
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClose}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            visible={visible}
            onCancel={onCancel}
            title="Modification rendez-vous"
            width={368}
            keyboard={false}
            altTitle
        >
            <Form.Item style={{ margin: 0 }} labelCol={{ span: 24 }}>
                <Input
                    placeholder="Saisissez un code client"
                    onChange={(e) => setCustomerReference(e.target.value)}
                    value={customerReference}
                />
            </Form.Item>
        </CustomModal>
    );
};

export default SearchPlatformRdvModal;
