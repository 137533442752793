import api from './_client';
import { Incident } from './apiTypes';
import { SearchPaginationQuery } from './index';

export interface IncidentsCreatePayload {
    label: Incident['label'];
}
export const create = async (payload: IncidentsCreatePayload) => await api.post(`/incident`, payload);

export type IncidentsListPayload = SearchPaginationQuery;
export const list = async (payload: IncidentsListPayload = {}) => await api.get(`/incidents`, { params: payload });
export interface IncidentsDelPayload {
    incidentId: Incident['id'];
}

export const del = async ({ incidentId }: IncidentsDelPayload) => await api.delete(`/incident/${incidentId}`);
