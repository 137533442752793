import { SearchPaginationQuery } from '.';
import { ClientRegularization } from './apiTypes';
import api from './_client';

export type ClientRegularizationListPayload = SearchPaginationQuery;
export const clientRegularizationList = async (payload: ClientRegularizationListPayload) =>
    await api.get('/clientRegularizations', { params: payload });

export interface ClientRegularizationDetailsPayload {
    clientRegularizationId: ClientRegularization['id'];
}
export const clientRegularizationDetails = async ({
    clientRegularizationId,
    ...payload
}: ClientRegularizationDetailsPayload) =>
    await api.get(`/clientRegularizations/${clientRegularizationId}`, { params: payload });

export type ParcelRegularizationListPayload = SearchPaginationQuery;
export const parcelRegularizationList = async (payload: ParcelRegularizationListPayload) =>
    await api.get('/parcelRegularizations', { params: payload });
