import { Descriptions, Divider, message, Spin } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ControlOrderPalletStatus, LoadingOrderType, Pallet, PalletStatus, WMSMode } from '../../store/api/apiTypes';
import {
    details as controlOrderPalletDetails,
    getControlOrderPalletPalletListState,
    getControlOrderPalletStateById,
    palletList as controlOrderPalletPalletList,
} from '../../store/actions/controlOrderPallets';
import {
    details as controlLoadingOrderDetails,
    detailsPolling as controlLoadingOrderDetailsPolling,
    getControlLoadingOrderStateById,
} from '../../store/actions/controlLoadingOrders';
import { getUser } from '../../store/actions/auth';

import { formatDate, formatNumber, translateControlOrderPalletStatus } from '../../helpers/i18n';
import { isZebra } from '../../helpers/enterprise-browser';
import { useActions, usePrevious } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import FixedFooter from '../../components/FixedFooter';
import Header from '../../components/Header';
import List, { ListColumn } from '../../components/list/List';
import PageHeader from '../../components/PageHeader';
import Seo from '../../components/Seo';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonGrey from '../../components/ButtonGrey';
import QuantityCard from '../../components/QuantityCard';
import ControlScanModal from './ControlScanModal';
import PrintModal from '../../components/PrintModal';

const ControlOrderPalletDetails: FC = () => {
    const history = useHistory();
    const [isHeaderExpanded, setIsHeaderExpanded] = useState(false);
    const [isPalletControlModalVisible, setIsPalletControlModalVisible] = useState(false);
    const [selectedPallet, setSelectedPallet] = useState<Pallet>();
    const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
    const { controlLoadingOrderId, controlOrderPalletId } =
        useParams<{ controlLoadingOrderId: string; controlOrderPalletId: string }>();
    const [
        fetchControlOrderPallet,
        fetchControlOrderPalletPallets,
        fetchControlLoadingOrder,
        stopControlLoadingOrderPolling,
    ] = useActions([
        controlOrderPalletDetails.trigger,
        controlOrderPalletPalletList.trigger,
        controlLoadingOrderDetails.trigger,
        controlLoadingOrderDetailsPolling.actions.stopPolling,
    ]);
    const controlLoadingOrderDetailsState = useSelector(
        getControlLoadingOrderStateById(parseInt(controlLoadingOrderId, 10))
    );
    const controlOrderPalletDetailsState = useSelector(
        getControlOrderPalletStateById(parseInt(controlOrderPalletId, 10))
    );
    const user = useSelector(getUser);
    const controlOrderPalletPalletListState = useSelector(getControlOrderPalletPalletListState);
    const previous = usePrevious({ controlOrderPalletDetailsState, controlLoadingOrderDetailsState });
    const checkedQuantity = controlOrderPalletDetailsState.data?.palletQuantityChecked ?? 0;
    const quantityToCheck = controlOrderPalletDetailsState.data?.palletQuantityToCheck ?? 0;
    const onControlScanFinish = useCallback(() => {
        fetchControlOrderPallet({ controlOrderPalletId });
        fetchControlOrderPalletPallets({
            controlOrderPalletId,
        });
    }, [controlOrderPalletId, fetchControlOrderPallet, fetchControlOrderPalletPallets]);
    const onCloseControlScanModal = useCallback(() => {
        setIsPalletControlModalVisible(false);
    }, []);
    const columns: Array<ListColumn<Pallet>> = [
        {
            dataIndex: 'reference',
            title: 'Référence palette',
            flex: '1 1 50%',
        },
        {
            dataIndex: 'quantity',
            title: 'Quantité colis',
            flex: '1 1 20%',
        },
    ];

    const onClickPrint = () => {
        setIsPrintModalVisible(true);
    };

    const onClosePrintModal = () => {
        setIsPrintModalVisible(false);
    };

    useEffect(() => {
        if (controlLoadingOrderId) {
            fetchControlLoadingOrder({ controlLoadingOrderId }, { poll: isZebra });
        }
    }, [fetchControlLoadingOrder, controlLoadingOrderId]);

    useEffect(() => {
        if (controlOrderPalletId) {
            fetchControlOrderPallet({ controlOrderPalletId });
        }
    }, [fetchControlOrderPallet, controlOrderPalletId]);

    useEffect(() => {
        if (previous?.controlOrderPalletDetailsState.loading && !controlOrderPalletDetailsState.loading) {
            if (!controlOrderPalletDetailsState.error) {
                fetchControlOrderPalletPallets({
                    controlOrderPalletId,
                });
            }
        }
    }, [
        previous?.controlOrderPalletDetailsState,
        controlOrderPalletDetailsState,
        controlOrderPalletId,
        fetchControlOrderPalletPallets,
    ]);

    // check if user has been unassigned of this controlLoadingOrder, if true redirect to home page
    useEffect(() => {
        if (
            isZebra &&
            user &&
            previous?.controlLoadingOrderDetailsState.data?.operator &&
            previous?.controlLoadingOrderDetailsState.data.operator.id === user.id &&
            controlLoadingOrderDetailsState.data?.operator?.id !== user.id
        ) {
            message.warn('Vous avez été désassigné de ce chargement', 5);
            history.push(getRoute(RoutePathName.home));
        }
    }, [previous?.controlLoadingOrderDetailsState, controlLoadingOrderDetailsState.data, user, history]);

    useEffect(
        () => () => {
            stopControlLoadingOrderPolling();
        },
        [stopControlLoadingOrderPolling]
    );

    return (
        <FixedFooter.Wrapper>
            <Seo title="Détail client" />
            <Header
                title="Détail client"
                backRoute={getRoute(RoutePathName.controlLoadingOrderDetails, { controlLoadingOrderId })}
            />
            <PageHeader>
                <Spin spinning={controlOrderPalletDetailsState.loading}>
                    <Descriptions column={{ xs: 4 }} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="Code client">
                            {controlOrderPalletDetailsState.data?.customerOrder?.customer?.reference ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Rang">
                            {controlOrderPalletDetailsState.data?.customerOrder?.rank ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Colis">
                            {controlOrderPalletDetailsState.data?.customerOrder?.nbOfPackageEstimated ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Statut">
                            {translateControlOrderPalletStatus(controlOrderPalletDetailsState.data?.status)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Client" span={4}>
                            {controlOrderPalletDetailsState.data?.customerOrder?.customer?.label ?? '—'}
                        </Descriptions.Item>
                        {isHeaderExpanded && (
                            <>
                                <Descriptions.Item label="N° de tournée">
                                    {controlOrderPalletDetailsState.data?.customerOrder?.loadingOrder?.tourNumber ??
                                        '—'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Date de chargement" span={3}>
                                    {formatDate(controlOrderPalletDetailsState.data?.customerOrder?.loadingOrder?.date)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Code plateforme">
                                    {controlOrderPalletDetailsState.data?.customerOrder?.loadingOrder?.plateformCode ??
                                        '—'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Transporteur" span={3}>
                                    {controlOrderPalletDetailsState.data?.customerOrder?.loadingOrder?.carrier?.label ??
                                        '—'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Poids">
                                    {controlOrderPalletDetailsState.data?.customerOrder?.loadingOrder?.weightEstimated
                                        ? new Intl.NumberFormat('fr-FR', {
                                              style: 'unit',
                                              unit: 'kilogram',
                                          }).format(
                                              controlOrderPalletDetailsState.data?.customerOrder?.loadingOrder
                                                  ?.weightEstimated ?? 0
                                          )
                                        : '—'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Volume">
                                    {controlOrderPalletDetailsState.data?.customerOrder?.loadingOrder
                                        ?.volumeEstimated ? (
                                        <span>
                                            {
                                                controlOrderPalletDetailsState.data.customerOrder?.loadingOrder
                                                    ?.volumeEstimated
                                            }{' '}
                                            m<sup>3</sup>
                                        </span>
                                    ) : (
                                        '—'
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label="Palette" span={2}>
                                    {controlOrderPalletDetailsState.data?.customerOrder?.loadingOrder
                                        ?.nbOfPalletEstimated ?? '—'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Instructions livraison" span={4}>
                                    {controlOrderPalletDetailsState.data?.customerOrder?.deliveryInstruction ?? '—'}
                                </Descriptions.Item>
                            </>
                        )}
                    </Descriptions>
                    <ButtonWithShortcut
                        onClick={setIsHeaderExpanded.bind(null, !isHeaderExpanded)}
                        shortcut="f1"
                        size="small"
                        block
                    >
                        {isHeaderExpanded ? 'Moins' : 'Plus'} de détail
                    </ButtonWithShortcut>
                </Spin>
            </PageHeader>
            <QuantityCard
                value={
                    checkedQuantity > 0
                        ? `${formatNumber(checkedQuantity)}/${formatNumber(quantityToCheck)}`
                        : formatNumber(quantityToCheck)
                }
                isComplete={checkedQuantity > 0 ? quantityToCheck === checkedQuantity : false}
                label={
                    checkedQuantity > 0
                        ? `Palette${checkedQuantity !== 1 ? 's' : ''} contrôlée${checkedQuantity !== 1 ? 's' : ''}`
                        : 'Palettes à charger'
                }
            />
            <Divider />
            <List<Pallet>
                columns={columns}
                data={controlOrderPalletPalletListState.data?.items}
                rowKey={(record) => `${record.id}`}
                isRowHiglighted={(record) => record.status === PalletStatus.loaded}
                isLoading={controlOrderPalletPalletListState.loading}
                title="Détail des palettes"
                onRowClick={(pallet) => setSelectedPallet(pallet)}
                isRowSelected={(record) => record && record.id === selectedPallet?.id}
            />
            {isZebra &&
                controlOrderPalletDetailsState.data?.status !== ControlOrderPalletStatus.completed &&
                controlOrderPalletDetailsState.data?.customerOrder?.loadingOrder?.type ===
                    LoadingOrderType.directDelevery && (
                    <FixedFooter>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="enter"
                                as={ButtonGrey}
                                onClick={setIsPalletControlModalVisible.bind(null, true)}
                                block
                            >
                                Contrôle des palettes
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </FixedFooter>
                )}
            <ControlScanModal
                visible={isPalletControlModalVisible}
                onCancel={onCloseControlScanModal}
                controlOrderPalletId={parseInt(controlOrderPalletId, 10)}
                controlLoadingOrderId={parseInt(controlLoadingOrderId, 10)}
                checkedQuantity={checkedQuantity}
                quantityToCheck={quantityToCheck}
                onFinish={onControlScanFinish}
            />
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut shortcut="enter" onClick={onClickPrint} block disabled={!selectedPallet}>
                        {"Imprimer l'étiquette"}
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
            {isPrintModalVisible && (
                <PrintModal
                    visible={isPrintModalVisible}
                    onCancel={onClosePrintModal.bind(null, false)}
                    palletId={Number(selectedPallet?.id)}
                    mode={WMSMode.preparation}
                />
            )}
        </FixedFooter.Wrapper>
    );
};

export default ControlOrderPalletDetails;
