import { Layout } from 'antd';
import React, { FC } from 'react';

import { LayoutContextProvider } from '../context/LayoutContext';

const MainLayout: FC = ({ children }) => (
    <LayoutContextProvider>
        <Layout id="main-layout">
            <Layout.Content id="main-content">{children}</Layout.Content>
        </Layout>
    </LayoutContextProvider>
);

export default MainLayout;
