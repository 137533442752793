import React, { useState, forwardRef, useEffect, ForwardRefRenderFunction } from 'react';
import { Select, SelectProps } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { useSelector } from 'react-redux';

import { PlatformReasonCode } from '../store/api/apiTypes';
import { getPlatformReasonCodesListState, list as listReasonCodesAction } from '../store/actions/platformReasonCodes';

import { useActions } from '../hooks';
import ArrowNavItem from './ArrowNavItem';

interface ReasonCodeSelectProps {
    size?: SizeType;
    placeholder?: string;
    onChange?: (value: any) => void;
    withArrowNav?: boolean;
    arrowNavScope?: string;
}

const ReasonCodeSelect: ForwardRefRenderFunction<typeof Select, ReasonCodeSelectProps> = (props, ref: any) => {
    const listReasonCodes = useActions(listReasonCodesAction.trigger);
    const platformReasonCodesListState = useSelector(getPlatformReasonCodesListState);
    const { onChange, size, placeholder, withArrowNav, arrowNavScope } = props;
    const [value, setValue] = useState<PlatformReasonCode['id'] | undefined>();
    const onSearch: SelectProps<string>['onSearch'] = (value) => {
        listReasonCodes(
            {
                ...(value ? { search: value } : {}),
            },
            { throttling: 300 }
        );
    };

    useEffect(() => {
        setValue(undefined);
        listReasonCodes();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeSelect = (newValue: any) => {
        if (newValue) {
            setValue(newValue);
        } else {
            setValue(undefined);
        }

        if (onChange) {
            onChange(platformReasonCodesListState.data?.items.find((p) => p.id === parseInt(newValue, 10)));
        }
    };

    const select = (
        <Select
            size={size}
            ref={ref}
            value={value}
            placeholder={placeholder ?? 'Sélectionner un code'}
            loading={platformReasonCodesListState.loading}
            filterOption={false}
            onChange={onChangeSelect}
            onSearch={onSearch}
            style={{ width: '100%' }}
            allowClear
            showSearch
            showArrow
        >
            {platformReasonCodesListState.data?.items?.map((reason) => (
                <Select.Option value={reason.id} key={reason.id}>
                    {reason.reference} - {reason.label}
                </Select.Option>
            ))}
        </Select>
    );

    return withArrowNav ? <ArrowNavItem scope={arrowNavScope}>{select}</ArrowNavItem> : select;
};

export default forwardRef(ReasonCodeSelect);
