import { Form, FormProps, Input, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';

import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import QuantityCard from '../../../components/QuantityCard';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../../hooks';
import {
    formatNumber,
    getPlatformReturnsCheckPalletError,
    getPlatformReturnsSendPalletsError,
} from '../../../helpers/i18n';
import { useSelector } from 'react-redux';
import {
    getPlatformReturnsCheckPalletState,
    getPlatformReturnsSendPalletsState,
    checkReturnsPallet,
    sendReturnsPallets,
    getPlatformReturnsReceptionState,
    returnsReception,
} from '../../../store/actions/platformReturn';
import AnomalyCodeSelect from '../../../components/AnomalyCodeSelect';
import { PlatformAnomalyCode } from '../../../store/api/apiTypes';
import SuccessModal from '../../../components/SuccessModal';

const shortcutScope = 'PlatformReturnModal';

const PlatformReturnModal: FC<CustomModalProps> = ({ visible, onCancel }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const [isManual, setIsManual] = useState(false);
    const [tempReference, setTempReference] = useState<string>(); // temp data when waiting for API response
    const [tempBarcode, setTempBarcode] = useState<string>(); // temp data when waiting for API response
    const [isAnomaly, setIsAnomaly] = useState(false);
    const [step, setStep] = useState<'reference' | 'code' | 'success'>('reference');
    const [anomalyCode, setAnomalyCode] = useState<PlatformAnomalyCode>();
    const checkPalletState = useSelector(getPlatformReturnsCheckPalletState);
    const sendPalletsState = useSelector(getPlatformReturnsSendPalletsState);
    const { palletRefs, palletBarcodes } = useSelector(getPlatformReturnsReceptionState).receive;
    const [checkPallet, sendPallets, setReceiveState, resetReceiveState] = useActions([
        checkReturnsPallet.trigger,
        sendReturnsPallets.trigger,
        returnsReception.actions.setReceiveData,
        returnsReception.actions.resetReceive,
    ]);

    const [form] = Form.useForm();
    const previous = usePrevious({ visible, checkPalletState, sendPalletsState });
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (palletBarcodes.some((palletBarcode) => palletBarcode.barcode === barCode.data)) {
                    message.error('Vous avez déjà scanné ce lot');
                } else {
                    setTempBarcode(barCode.data);
                    checkPallet({
                        barcode: barCode.data,
                    });
                }
            } else {
                console.error(barCode);
            }
        },
        {
            deps: [checkPallet, palletBarcodes, visible],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );
    const onSubmit = () => {
        sendPallets({
            barcodes: palletBarcodes.length ? palletBarcodes : undefined,
            references: palletRefs.length ? palletRefs : undefined,
        });
    };
    const onSubmitManual: FormProps['onFinish'] = (values) => {
        if (palletRefs.some((palletRef) => palletRef.reference === values.reference)) {
            message.error('Vous avez déjà entré ce lot');
        } else {
            setTempReference(values.reference);
            checkPallet({
                reference: values.reference,
            });
        }
    };
    const onClickCancel = () => {
        onClose();
    };

    const receivedCount = palletRefs.length + palletBarcodes.length;
    const anomalyCount =
        palletRefs.reduce((count, ref) => (count += ref.anomalyCode ? 1 : 0), 0) +
        palletBarcodes.reduce((count, barcode) => (count += barcode.anomalyCode ? 1 : 0), 0);

    const resetLocalStates = () => {
        setAnomalyCode(undefined);
        setStep('reference');
        setTempBarcode(undefined);
        setTempReference(undefined);
        setIsAnomaly(false);
        setIsManual(false);
    };
    const onClose = useCallback(() => {
        form.resetFields();

        resetLocalStates();
        resetReceiveState();
        onCancel?.({} as React.MouseEvent<HTMLElement, MouseEvent>);
    }, [form, onCancel, resetReceiveState]);

    const onAnomaly = () => {
        setIsAnomaly(true);
    };
    const onAnomalyCancel = () => {
        resetLocalStates();
        form.resetFields();
    };
    const onSubmitAnomaly = () => {
        setReceiveState({
            palletBarcodes: tempBarcode
                ? [...palletBarcodes, { barcode: tempBarcode, anomalyCode: anomalyCode?.id }]
                : palletBarcodes,
            palletRefs: tempReference
                ? [...palletRefs, { reference: tempReference, anomalyCode: anomalyCode?.id }]
                : palletRefs,
        });
        form.resetFields();
        setTempBarcode(undefined);
        setTempReference(undefined);
        setStep('success');
    };

    const onAnomalySuccess = () => {
        setAnomalyCode(undefined);
        setStep('reference');
        setIsAnomaly(false);
    };
    useEffect(() => {
        if (visible && previous?.checkPalletState.loading && !checkPalletState.loading) {
            if (checkPalletState.error) {
                const requestError = getPlatformReturnsCheckPalletError(checkPalletState);

                if (requestError.message) {
                    message.error(requestError.message);
                }
            } else if (checkPalletState.data) {
                if (isAnomaly) {
                    setStep('code');
                } else {
                    setReceiveState({
                        palletBarcodes: tempBarcode ? [...palletBarcodes, { barcode: tempBarcode }] : palletBarcodes,
                        palletRefs: tempReference ? [...palletRefs, { reference: tempReference }] : palletRefs,
                    });
                    form.resetFields();
                    setIsManual(false);
                    setTempBarcode(undefined);
                    setTempReference(undefined);
                }
            }
        }
    }, [
        previous?.checkPalletState.loading,
        checkPalletState,
        tempBarcode,
        tempReference,
        form,
        palletBarcodes,
        palletRefs,
        visible,
        setReceiveState,
        isAnomaly,
        anomalyCode?.id,
    ]);

    useEffect(() => {
        if (visible && previous?.sendPalletsState.loading && !sendPalletsState.loading) {
            if (sendPalletsState.error) {
                const requestError = getPlatformReturnsSendPalletsError(sendPalletsState, receivedCount > 1);
                if (requestError.message) {
                    message.error(requestError.message);
                }
            } else {
                resetReceiveState();
                setAnomalyCode(undefined);
                setIsAnomaly(false);
                setStep('success');
            }
        }
    }, [
        previous?.sendPalletsState.loading,
        sendPalletsState.loading,
        sendPalletsState.error,
        sendPalletsState.data,
        visible,
        sendPalletsState,
        resetReceiveState,
        onClose,
        receivedCount,
    ]);

    return step === 'success' ? (
        <SuccessModal
            message={isAnomaly ? 'Lot déclaré en avarie avec succès' : 'Lots retournés avec succès'}
            visible={visible}
            onCancel={isAnomaly ? onAnomalySuccess : onClose}
            noFooter
            autoClose
        />
    ) : (
        <CustomModal
            footer={
                isManual ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={() => form.submit()}
                                loading={checkPalletState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={setIsManual.bind(null, false)}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : isAnomaly ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                disabled={step === 'code' && !anomalyCode}
                                onClick={() => (step === 'code' ? onSubmitAnomaly() : form.submit())}
                                loading={checkPalletState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onAnomalyCancel}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={onSubmit}
                                loading={sendPalletsState.loading}
                                disabled={false}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="f1"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={setIsManual.bind(null, true)}
                                ghost
                            >
                                Saisir manuellement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="f2"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={onAnomaly}
                                ghost
                            >
                                Déclarer avarie
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClickCancel}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onClickCancel}
            title={
                isManual
                    ? 'Saisir manuellement'
                    : isAnomaly
                    ? 'Déclarer avarie lot'
                    : 'Veuillez scanner les lots en retour :'
            }
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            maskClosable={false}
            altTitle={isManual}
        >
            {isManual ? (
                <Form form={form} onFinish={onSubmitManual}>
                    <Form.Item name="reference" rules={[{ required: true, message: 'Veuillez saisir une référence' }]}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir référence lot" autoFocus />
                        </ArrowNavItem>
                    </Form.Item>
                </Form>
            ) : isAnomaly ? (
                step === 'reference' ? (
                    <Form form={form} onFinish={onSubmitManual}>
                        <Form.Item
                            name="reference"
                            rules={[{ required: true, message: 'Veuillez saisir une référence' }]}
                        >
                            <ArrowNavItem scope={shortcutScope}>
                                <Input placeholder="Flasher le lot" autoFocus />
                            </ArrowNavItem>
                        </Form.Item>
                    </Form>
                ) : (
                    <AnomalyCodeSelect withArrowNav onChange={setAnomalyCode} />
                )
            ) : (
                <QuantityCard
                    value={formatNumber(receivedCount)}
                    label={`lot${receivedCount > 1 ? 's' : ''} retourné${receivedCount > 1 ? 's' : ''}${
                        anomalyCount ? ` dont ${anomalyCount} en avarie` : ''
                    }`}
                    outline
                />
            )}
        </CustomModal>
    );
};

export default PlatformReturnModal;
