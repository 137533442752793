import React, { FC } from 'react';

import BasicList from '../../components/BasicList';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ButtonLink from '../../components/ButtonLink';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import { getRoute, RoutePathName } from '../../routes';

const PlaceManagementSectionsMenu: FC = () => {
    return (
        <>
            <Seo title="Gestion des sections" />
            <Header title="Gestion des sections" backRoute={getRoute(RoutePathName.placeManagementMenu)} />
            <nav className="menu">
                <BasicList>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="1"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.placeManagementSites)}
                                block
                            >
                                Sites / Puits
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="2"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.placeManagementSectors)}
                                block
                            >
                                Secteurs
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="3"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.placeManagementLanes)}
                                block
                            >
                                Allées
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="4"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.placeManagementAlveoles)}
                                block
                            >
                                Alvéoles
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                </BasicList>
            </nav>
        </>
    );
};

export default PlaceManagementSectionsMenu;
