import React, { FC, useEffect, useState } from 'react';
import List, { ListColumn } from '../../../components/list/List';
import { PalletSize, Parcel, Place, ReturnZoneType } from '../../../store/api/apiTypes';
import FixedFooter from '../../../components/FixedFooter';
import Seo from '../../../components/Seo';
import Header from '../../../components/Header';
import { getRoute, RoutePathName } from '../../../routes';
import { Button, Divider, PageHeader } from 'antd';
import QuantityCard from '../../../components/QuantityCard';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPlacesListState, list } from '../../../store/actions/places';
import { useActions } from '../../../hooks';
import { useModal } from '@ebay/nice-modal-react';
import ReturnScanModal from './ReturnScanModal';
import ReturnNewPalletModal from './ReturnNewPalletModal';
import { urlSearchParamsToObject } from '../../../helpers';
import useQueryParams from '../../../hooks/queryParams';
import ReturnCancelModal from './ReturnCancelModal';

type createReturnPackagePlacesStep = 'confirmation' | 'selectPlace' | 'scanPlace' | undefined;

const PackagePlaces: FC = () => {
    const fetchPlaces = useActions(list.trigger);

    const location =
        useLocation<{
            returnZoneType: ReturnZoneType;
            quantity: number;
            parcelId: Parcel['id'] | undefined;
            afterFilter: boolean;
            step?: createReturnPackagePlacesStep;
            size: PalletSize;
        }>();
    const { returnZoneType, quantity, parcelId } = location.state;
    const placesListState = useSelector(getPlacesListState);
    const customerReturnScanModal = useModal(ReturnScanModal);
    const newPalletModal = useModal(ReturnNewPalletModal);
    const history = useHistory();
    const [selectedPlace, setSelectedPlace] = useState<Place>();
    const [size, setSize] = useState<PalletSize>(location.state.size);
    const [step, setStep] = useState<createReturnPackagePlacesStep>(location.state.step);
    const [queryParams] = useQueryParams('customerReturnPlacesFilter');
    const cancelCustomerReturnModal = useModal(ReturnCancelModal);
    const { visible } = newPalletModal;

    useEffect(() => {
        fetchPlaces({
            ...urlSearchParamsToObject(queryParams),
            zoneType: ReturnZoneType.mass,
            size,
            pageSize: 10,
        });
    }, [fetchPlaces, queryParams, size]);

    useEffect(() => {
        if (step === 'confirmation') {
            newPalletModal.show({ ...location.state, callBackSetSize: setSize });
            setStep('selectPlace');
        } else if (step === 'selectPlace' && !size && !visible) {
            history.push(getRoute(RoutePathName.movementCustomerReturnPalletList), {
                returnZoneType,
                quantity,
                parcelId,
            });
        }
    }, [step, size, visible, newPalletModal, location.state, history, returnZoneType, quantity, parcelId]);

    useEffect(() => {
        setStep(location.state.step);
    }, [location.state.step]);

    const handlePlaceSelect = (place?: Place) => {
        setSelectedPlace(place);
    };

    const columns: Array<ListColumn<Place>> = [
        {
            key: 'sector',
            title: 'Secteur',
            flex: '1 1 25%',
            render: (record) => record.sector,
        },
        {
            key: 'locker',
            title: 'Casier',
            flex: '1 1 25%',
            render: (record) => record.locker,
        },
        {
            key: 'spot',
            title: 'Emplacement',
            flex: '1 1 25%',
            render: (record) => record.spot,
        },
    ];
    const onLoadMore = () => {
        fetchPlaces(
            {
                ...urlSearchParamsToObject(queryParams),
                zoneType: ReturnZoneType.mass,
                size,
                page: (placesListState.data?.page ?? 0) + 1,
                pageSize: 10,
            },
            { loadMore: true }
        );
    };

    const handleFilterClick = () => {
        history.push(getRoute(RoutePathName.movementCustomerReturnPlacesFilter), {
            ...location.state,
            size,
            backRoute: RoutePathName.movementCustomerReturnPackagePlaces,
        });
    };

    const onClickBack = () => {
        if (visible) {
            newPalletModal.hide();
        }
        cancelCustomerReturnModal.show({
            isNewPallet: true,
            returnZoneType,
            quantity,
            parcelId,
        });
    };

    return (
        <FixedFooter.Wrapper>
            <Seo title="autre emplacement" />
            <Header
                title="autre emplacement"
                backRoute={{
                    pathname: getRoute(RoutePathName.movementCustomerReturnPalletList),
                    state: location.state,
                }}
                onClickBack={onClickBack}
            />
            <PageHeader>
                <QuantityCard value={location.state.quantity} label={'colis à ranger'} />
            </PageHeader>
            <Divider />
            <List<Place>
                columns={columns}
                data={placesListState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowClick={handlePlaceSelect}
                onRowEnterPress={handlePlaceSelect}
                onRowFocus={handlePlaceSelect}
                onRowBlur={handlePlaceSelect}
                isLoading={placesListState.loading && placesListState.data?.page === undefined}
                title="Emplacements disponibles"
                style={{ marginTop: 24 }}
                columnWidthGrow={true}
            />
            {placesListState.data && placesListState.data.page < placesListState.data.pageCount - 1 && (
                <Button
                    onClick={onLoadMore}
                    loading={placesListState.loading}
                    size="small"
                    style={{ marginBottom: 60, marginTop: 16, fontSize: 14 }}
                    block
                >
                    Plus de résultats
                </Button>
            )}

            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        shortcut="enter"
                        block
                        className="btn-grey"
                        onClick={async () => {
                            if (parcelId && selectedPlace)
                                await customerReturnScanModal.show({
                                    parcelId,
                                    quantity,
                                    returnZoneType,
                                    isNewPallet: true,
                                    selectedPlace,
                                    palletSize: size,
                                    setSelectedPlace,
                                });
                        }}
                        disabled={!selectedPlace}
                    >
                        Ranger la palette
                    </ButtonWithShortcut>
                </ArrowNavItem>
                <ArrowNavItem>
                    <ButtonWithShortcut onClick={handleFilterClick} shortcut="f1" type="ghost" block>
                        Filtrer
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </FixedFooter.Wrapper>
    );
};

export default PackagePlaces;
