import { Form, FormProps, Input, message } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    control,
    controlPackage,
    controlPackages,
    getControlOrderControlPackagesState,
    getControlOrderControlPackageState,
    getControlOrderControlState,
    PackageRef,
} from '../../store/actions/preparationControl';
import { Pallet } from '../../store/api/apiTypes';

import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import QuantityCard from '../../components/QuantityCard';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { formatNumber, getControlPackageGetError } from '../../helpers/i18n';
import { isControlPackageAlreadyStoredLocally } from '../../helpers';
import { getCustomerOrderStateById } from '../../store/actions/customerOrders';
import { getPreparationRemainingQuantityToControlByCustomerOrderIdAndParcelIdState } from '../../store/actions/preparations';
import { errorSound, warningSound } from '../../helpers/sound';

const shortcutScope = 'ControlScanModals';

interface ControlScanModalProps extends CustomModalProps {
    onFinish: () => void;
    palletId?: Pallet['id'];
    onTransfer: () => void;
}

const ControlScanModal: FC<ControlScanModalProps> = ({ visible, onCancel, onFinish, onTransfer, palletId }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const { customerOrderId: customerOrderIdParam } = useParams<{ customerOrderId: string }>();
    const customerOrderId = parseInt(customerOrderIdParam, 10);
    const [form] = Form.useForm();
    const [isManual, setIsManual] = useState(false);
    const [refToStore, setRefToStore] = useState<PackageRef>(); // temp data when waiting for API response
    const [barcodeToStore, setBarcodeToStore] = useState<string>(); // temp data when waiting for API response
    const [
        fetchControlPackage,
        sendControlPackages,
        setControlCreatePalletState,
        setControlLocalParcelCountByParcelId,
        setControlTransferState,
        setControlPrintState,
        resetControlState,
    ] = useActions([
        controlPackage.trigger,
        controlPackages.trigger,
        control.actions.setCreatePalletData,
        control.actions.setLocalParcelCountByParcelId,
        control.actions.setTransferData,
        control.actions.setPrintData,
        control.actions.resetCreatePallet,
    ]);
    const controlPackageState = useSelector(getControlOrderControlPackageState);
    const controlPackagesState = useSelector(getControlOrderControlPackagesState);
    const customerOrderState = useSelector(getCustomerOrderStateById(customerOrderId));
    const { packagesRefs, packagesBarcodes, isWaitingForTransfer, transferredCount } =
        useSelector(getControlOrderControlState).createPallet;
    const localParcelCountByParcelId = useSelector(getControlOrderControlState).localParcelCountByParcelId;
    const remainingQuantityToControlForCurrentParcel =
        useSelector(
            getPreparationRemainingQuantityToControlByCustomerOrderIdAndParcelIdState(
                customerOrderId,
                controlPackageState.data?.id
            )
        ) - (localParcelCountByParcelId[controlPackageState.data?.id ?? -1] ?? 0);
    const totalRemainingQuantityToControl =
        ((customerOrderState.data?.preparedQuantity ?? 0) > (customerOrderState.data?.quantity ?? 0)
            ? customerOrderState.data?.quantity ?? 0
            : customerOrderState.data?.preparedQuantity ?? 0) -
        (customerOrderState.data?.controlledQuantity ?? 0) -
        packagesRefs.length -
        packagesBarcodes.length;
    const previous = usePrevious({ visible, isWaitingForTransfer, controlPackageState, controlPackagesState });
    const onClickCancel = () => {
        setControlPrintState({ controlPalletId: undefined, transferPalletIds: [] });
        onCancel?.({} as React.MouseEvent<HTMLElement, MouseEvent>);
    };
    const onSubmitManual: FormProps['onFinish'] = (values) => {
        if (isControlPackageAlreadyStoredLocally(packagesRefs, values)) {
            message.error('Ce colis est déjà présent dans la palette');
        } else {
            setRefToStore({
                reference: values.reference,
                ...(values.cont ? { cont: values.cont } : {}),
            });
            fetchControlPackage({
                customerOrderId,
                reference: values.reference,
                ...(values.cont ? { cont: values.cont } : {}),
            });
        }
    };
    const onSubmit = () => {
        if (transferredCount && packagesRefs.length + packagesBarcodes.length === 0) {
            // we only have transferred packages, so we just close the scan modal and open the print modal
            onFinish();
        } else {
            sendControlPackages({
                customerOrderId,
                barcodes: packagesBarcodes.length ? packagesBarcodes : undefined,
                references: packagesRefs.length ? packagesRefs : undefined,
                palletId,
            });
        }
    };
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (packagesBarcodes.includes(barCode.data)) {
                    message.error('Ce colis est déjà présent dans la palette');
                    errorSound();
                } else {
                    setBarcodeToStore(barCode.data);
                    fetchControlPackage({
                        customerOrderId,
                        barcode: barCode.data,
                    });
                }
            } else {
                console.error(barCode);
            }
        },
        {
            deps: [fetchControlPackage, packagesBarcodes, customerOrderId, visible],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    // parcel control response, if request fails because parcel is already on another pallet, send to transfer modal;
    // otherwise store parcel in redux store
    useEffect(() => {
        if (visible && previous?.controlPackageState.loading && !controlPackageState.loading) {
            if (controlPackageState.error) {
                const requestError = getControlPackageGetError(controlPackageState);

                // already controlled in another pallet, send to transfer modal
                if (requestError.alreadyControlled) {
                    setControlCreatePalletState({ isWaitingForTransfer: true });
                    form.resetFields();
                    setControlTransferState({
                        packagesBarcodes: barcodeToStore ? [barcodeToStore] : [],
                        packagesRefs: refToStore ? [refToStore] : [],
                    });
                    setControlPrintState({
                        transferPalletIds: [controlPackageState.error.data?.pallet?.id],
                    });
                    warningSound();
                } else if (requestError.message) {
                    message.error(requestError.message, requestError.messageDuration);
                    errorSound();
                }
            } else if (controlPackageState.data) {
                if (totalRemainingQuantityToControl === 0) {
                    message.error(
                        'Vous avez contrôlé tous les colis préparés, vous pouvez transférer/supprimer des colis, mais pas en ajouter',
                        5
                    );
                    errorSound();
                } else if (remainingQuantityToControlForCurrentParcel === 0) {
                    message.error(
                        'Vous avez déjà contrôlé tous les colis préparés de cette référence, veuillez contrôler une autre référence',
                        5
                    );
                    errorSound();
                } else {
                    setControlCreatePalletState({
                        packagesBarcodes: barcodeToStore ? [...packagesBarcodes, barcodeToStore] : packagesBarcodes,
                        packagesRefs: refToStore ? [...packagesRefs, refToStore] : packagesRefs,
                        parcelCountByParcelId: {
                            ...localParcelCountByParcelId,
                            [controlPackageState.data.id]:
                                (localParcelCountByParcelId[controlPackageState.data.id] ?? 0) + 1,
                        },
                    });
                    setControlLocalParcelCountByParcelId({
                        ...localParcelCountByParcelId,
                        [controlPackageState.data.id]:
                            (localParcelCountByParcelId[controlPackageState.data.id] ?? 0) + 1,
                    });
                }

                form.resetFields();
                setIsManual(false);
            }
            setBarcodeToStore(undefined);
            setRefToStore(undefined);
        }
    }, [
        previous?.controlPackageState.loading,
        controlPackageState,
        barcodeToStore,
        refToStore,
        form,
        remainingQuantityToControlForCurrentParcel,
        packagesBarcodes,
        packagesRefs,
        localParcelCountByParcelId,
        setControlCreatePalletState,
        setControlLocalParcelCountByParcelId,
        setControlTransferState,
        setControlPrintState,
        visible,
        totalRemainingQuantityToControl,
    ]);

    useEffect(() => {
        if (visible && previous?.controlPackagesState.loading && !controlPackagesState.loading) {
            if (controlPackagesState.error) {
                message.error('Une erreur est survenue pendant la création de la palette');
            } else {
                if (controlPackagesState.data) {
                    setControlPrintState({ controlPalletId: controlPackagesState.data?.id });
                    onFinish();
                } else {
                    message.error('Une erreur est survenue pendant la création de la palette');
                }
            }
        }
    }, [
        previous?.controlPackagesState.loading,
        controlPackagesState.loading,
        controlPackagesState.error,
        controlPackagesState.data,
        setControlPrintState,
        onFinish,
        visible,
    ]);

    useEffect(() => {
        if (!previous?.isWaitingForTransfer && isWaitingForTransfer) {
            onTransfer();
        }
    }, [previous?.isWaitingForTransfer, isWaitingForTransfer, onTransfer]);

    useEffect(() => {
        if (!visible && !isWaitingForTransfer) {
            setBarcodeToStore(undefined);
            setRefToStore(undefined);
            resetControlState([]);
            form.resetFields();
            setIsManual(false);
        }
    }, [visible, isWaitingForTransfer, setBarcodeToStore, setRefToStore, resetControlState, form, setIsManual]);

    useEffect(() => {
        if (!previous?.visible && visible && isWaitingForTransfer) {
            setControlCreatePalletState({ isWaitingForTransfer: false });
            setIsManual(false);
        }
    }, [previous?.visible, visible, isWaitingForTransfer, setControlCreatePalletState]);

    return (
        <CustomModal
            footer={
                isManual ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={() => form.submit()}
                                loading={controlPackageState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={setIsManual.bind(null, false)}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={onSubmit}
                                loading={controlPackagesState.loading}
                                disabled={packagesRefs.length + packagesBarcodes.length + transferredCount === 0}
                            >
                                Terminer cette palette
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="f1"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={setIsManual.bind(null, true)}
                                ghost
                            >
                                Saisir manuellement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClickCancel}
                                disabled={controlPackagesState.loading}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={
                isManual ? 'Saisir manuellement' : 'Veuillez scanner individuellement les colis pour les contrôler :'
            }
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            altTitle={isManual}
        >
            {isManual ? (
                <Form form={form} onFinish={onSubmitManual}>
                    <Form.Item name="reference" rules={[{ required: true, message: 'Veuillez saisir une référence' }]}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir référence colis" autoFocus />
                        </ArrowNavItem>
                    </Form.Item>
                    <Form.Item name="cont" style={{ marginBottom: 0 }}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir CONT (optionnel)" />
                        </ArrowNavItem>
                    </Form.Item>
                </Form>
            ) : (
                <QuantityCard
                    value={formatNumber(packagesRefs.length + packagesBarcodes.length + transferredCount)}
                    label="colis contrôlés/transférés"
                    outline
                />
            )}
        </CustomModal>
    );
};

export default ControlScanModal;
