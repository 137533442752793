import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useScanner, useShortcutScope } from '../../../hooks';

import { Input, InputProps, message, Spin } from 'antd';
import { isZebra } from '../../../helpers/enterprise-browser';

import { useHistory } from 'react-router-dom';
import {
    updateAndStorePackagesMultiPallet,
    getOTStorePackagesMultiPalletState,
    getTransferOrdersDetails,
    updateAndStorePackages,
    getTransferOrderStorePackagesState,
} from '../../../store/actions/transferOrders';
import SuccessMessage from '../../../components/SuccessMessage';

import {
    MovementHistoryType,
    Pallet,
    Place,
    PlaceZoneType,
    TransferOrderStatus,
    TransferType,
} from '../../../store/api/apiTypes';
import { getPlaceDetailsByScanState, detailsByScan } from '../../../store/actions/places';
import { StorePackagesMultiPalletPayload } from '../../../store/api/TransferOrders';
import { translateTransferOrderError } from '../../../helpers/errors';
import { formatZoneType } from '../../../helpers/i18n';

const shortcutScope = 'StorePackageByPlaceModal';
interface StorePackageByPlaceProps extends CustomModalProps {
    onCancel: () => void;
    visible: boolean;
    packagesNumber: number;
    totalPackages: number;
    selectedPlace?: Place;
    onSuccess: (palletId?: Pallet['id'], transferOrderStatus?: TransferOrderStatus) => void;
    palletSize?: Pallet['size'];
    palletId?: Pallet['id'];
}

const StorePackageByPlaceModal: FC<StorePackageByPlaceProps> = ({
    visible,
    onCancel,
    packagesNumber,
    totalPackages,
    selectedPlace,
    onSuccess,
    palletSize,
    palletId,
}) => {
    const [reference, setReference] = useState<string>();
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [success, setSuccess] = useState<boolean>();

    const [fetchPlaceByScan, storePackagesMultiPallet, storePackages] = useActions([
        detailsByScan.trigger,
        updateAndStorePackagesMultiPallet.trigger,
        updateAndStorePackages.trigger,
    ]);

    const placeByScanState = useSelector(getPlaceDetailsByScanState);
    const transferOrder = useSelector(getTransferOrdersDetails);
    const storePackgesMultiPalletsState = useSelector(getOTStorePackagesMultiPalletState);
    const storePackagesState = useSelector(getTransferOrderStorePackagesState);
    const transferOrderState = useSelector(getTransferOrdersDetails);

    const isMounted = useIsMounted();
    const history = useHistory();

    const handlePlaceChange: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };

    const handleSubmit = () => {
        fetchPlaceByScan({ reference });
    };
    const previous = usePrevious({
        placeByScanState,
        storePackgesMultiPalletsState,
        storePackagesState,
    });
    useEffect(() => {
        if (previous?.placeByScanState.loading && !placeByScanState.loading) {
            if (placeByScanState.error) {
                message.error('Emplacement non reconnue');
            } else {
                if (selectedPlace?.id !== placeByScanState.data?.id) {
                    message.error("L'emplacement sélectionnée est different de palette flashée");
                } else {
                    if (transferOrderState.data?.id && placeByScanState.data?.id) {
                        const payload: StorePackagesMultiPalletPayload = {
                            id: transferOrderState.data?.id,
                            transferType: TransferType.package,
                            finishPlaceZoneType: placeByScanState.data?.zoneType,
                            transferedPackages: packagesNumber,

                            type: MovementHistoryType.transfer,
                            ...(transferOrder.data?.packageQuantity ? {} : { packageQuantity: totalPackages }),
                            placeId: placeByScanState.data?.id,
                            index: selectedPlace?.index,
                        };
                        if (placeByScanState.data.zoneType === PlaceZoneType.picking) {
                            storePackages(payload);
                        } else {
                            storePackagesMultiPallet({
                                ...payload,
                                ...(palletSize ? { size: palletSize } : {}),
                                ...(palletId ? { palletId } : {}),
                            });
                        }
                    }
                }
            }
        }
    }, [
        packagesNumber,
        palletSize,
        placeByScanState.data?.id,
        placeByScanState.data?.zoneType,
        placeByScanState.error,
        placeByScanState.loading,
        previous?.placeByScanState.loading,
        selectedPlace?.id,
        selectedPlace?.index,
        storePackages,
        storePackagesMultiPallet,
        totalPackages,
        transferOrder.data?.packageQuantity,
        transferOrderState.data?.id,
        palletId,
    ]);

    useEffect(() => {
        if (previous?.storePackagesState.loading && !storePackagesState.loading) {
            if (storePackagesState.success) {
                setSuccess(true);
            } else {
                message.error(translateTransferOrderError(storePackagesState.error?.data));
            }
        }
    }, [
        previous?.storePackagesState.loading,
        storePackagesState.error?.data,
        storePackagesState.loading,
        storePackagesState.success,
    ]);

    useEffect(() => {
        if (previous?.storePackgesMultiPalletsState.loading && !storePackgesMultiPalletsState.loading) {
            if (storePackgesMultiPalletsState.success) {
                setSuccess(true);
            } else {
                message.error(translateTransferOrderError(storePackgesMultiPalletsState.error?.data));
            }
        }
    }, [
        previous?.storePackgesMultiPalletsState.loading,
        storePackgesMultiPalletsState.error?.data,
        storePackgesMultiPalletsState.loading,
        storePackgesMultiPalletsState.success,
    ]);

    useEffect(() => {
        let timeout: number;

        if (success) {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    const data = storePackgesMultiPalletsState.data ?? storePackagesState.data;
                    onSuccess(data?.pallet?.id, data?.status);
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [success, isMounted, onSuccess, history, storePackgesMultiPalletsState.data, storePackagesState.data]);

    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                fetchPlaceByScan({ barcode: barCode.data });
            }
        },
        {
            deps: [],
            disable: !visible,
        }
    );

    const isLoading = storePackgesMultiPalletsState.loading || storePackagesState?.loading;
    return (
        <CustomModal
            visible={visible}
            title={success ?? isLoading ? '' : `rangement - ${formatZoneType(selectedPlace?.zoneType)}`}
            width={368}
            keyboard={false}
            altTitle
            transitionName=""
            maskTransitionName=""
            footer={
                !isLoading &&
                !success && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={handleSubmit}
                            >
                                Valider le rangement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onCancel}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
        >
            {isLoading ? (
                <div style={{ textAlign: 'center' }}>
                    <p>Transfert en cours..</p>
                    <Spin size="large" />
                </div>
            ) : success ? (
                <SuccessMessage
                    message={
                        [storePackgesMultiPalletsState?.data?.status, storePackagesState?.data?.status].includes(
                            TransferOrderStatus.completed
                        )
                            ? 'Toutes les colis ont été transférés'
                            : 'Colis transférés avec succès'
                    }
                />
            ) : (
                <ArrowNavItem scope={shortcutScope}>
                    <Input
                        placeholder={isZebra ? `Flasher l'emplacement` : `Entrer la reference de l'emplacement`}
                        value={reference}
                        onChange={handlePlaceChange}
                    />
                </ArrowNavItem>
            )}
        </CustomModal>
    );
};

export default StorePackageByPlaceModal;
