import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 45 56">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M26.563 48.471c-2.186 2.166-5.749 2.169-7.936 0a5.533 5.533 0 010-7.862 5.627 5.627 0 013.968-1.625c1.437 0 2.873.543 3.968 1.625a5.533 5.533 0 010 7.862zm2.578.801a7.993 7.993 0 00-.809-10.46c-3.201-3.123-8.365-3.123-11.521.003-3.15 3.167-3.15 8.288.005 11.414 2.876 2.806 7.35 3.12 10.554.796l4.647 4.606c.248.245.566.369.884.369.315 0 .629-.121.876-.36.238-.21.375-.505.386-.831a1.27 1.27 0 00-.376-.933l-4.646-4.604zM22.5 19.702l-7.656-3.794 17.299-8.572 7.653 3.795L22.5 19.7zm-11.25-5.574L5.2 11.13l14.971-7.418a5.318 5.318 0 014.655 0l3.72 1.844-17.296 8.57zM26.262.865a8.621 8.621 0 00-7.526 0L0 10.148v27.445l12.483 6.185a9.846 9.846 0 01.67-3.23l-.296-.146-3.214-1.592-6.429-3.185v-21.92l6.429 3.186v4.69c0 .426.244.816.628 1.009l.937.463c.757.374 1.65-.17 1.65-1.01v-3.56l8.035 3.981v11.883c.524-.085 1.057-.14 1.607-.14.548 0 1.083.055 1.607.14V22.464l17.679-8.758v21.92l-9.94 4.921a9.815 9.815 0 01.67 3.231L45 37.593V10.147L26.262.865z"
        />
    </svg>
);

const IconStockSearch: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-stock-search${props.className ? ` ${props.className}` : ''}`} />
);

export default IconStockSearch;
