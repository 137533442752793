import React from 'react';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useArrowNavScope, useShortcutScope } from '../../../hooks';
import SuccessModal from '../../../components/SuccessModal';
import { ReturnZoneType } from '../../../store/api/apiTypes';
import ReturnPrintModal from './ReturnPrintModal';
import { useHistory } from 'react-router-dom';

const shortcutScope = 'ReturnSuccessModal';

interface CustomerReturnSuccessModalProps {
    returnZoneType: ReturnZoneType;
}

const ReturnSuccessModal = NiceModal.create<CustomerReturnSuccessModalProps>(({ returnZoneType }) => {
    const modal = useModal();
    const customerReturnPrintModal = useModal(ReturnPrintModal);
    useHistory();
    useShortcutScope(shortcutScope);
    useArrowNavScope(shortcutScope);
    const { hide } = modal;
    const message =
        returnZoneType === ReturnZoneType.picking || returnZoneType === ReturnZoneType.mass ? (
            'les colis ont été rangé avec succès'
        ) : (
            <>
                Les colis ont été declarés
                <br /> en {returnZoneType === ReturnZoneType.soldeur ? 'Envoi soldeur' : 'Destruction'} avec succès.
                <br />
                <br /> Merci de les déplacer dans
                <br /> le puit correspondant.
            </>
        );
    return (
        <SuccessModal
            {...antdModal(modal)}
            message={message}
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={() => {
                                if (returnZoneType === ReturnZoneType.mass) {
                                    hide().then(async () => await customerReturnPrintModal.show());
                                } else {
                                    hide();
                                }
                            }}
                            ghost
                        >
                            Fermer
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            width={368}
        />
    );
});

export default ReturnSuccessModal;
