import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Input, InputProps, message } from 'antd';
import { useSelector } from 'react-redux';

import {
    collectPallet as collectPalletAction,
    getResupplyOrderPickPalletState,
    details,
} from '../../store/actions/resupplyOrders';
import { Pallet, ResupplyOrder } from '../../store/api/apiTypes';

import { isZebra } from '../../helpers/enterprise-browser';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import QuantityCard from '../../components/QuantityCard';
import SuccessMessage from '../../components/SuccessMessage';
import { formatNumber } from '../../helpers/i18n';
import constants from '../../config/constants';

const shortcutScope = 'ResupplyPalletScanModal';

interface ResupplyPalletScanModalProps extends CustomModalProps {
    pallet?: Pallet;
    resupplyOrder?: ResupplyOrder;
    onSuccess: () => void;
}

const ResupplyPalletScanModal: FC<ResupplyPalletScanModalProps> = ({
    visible,
    onCancel,
    onSuccess,
    pallet,
    resupplyOrder,
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const isMounted = useIsMounted();
    const [step, setStep] = useState<'scan' | 'validate' | 'success'>('scan');
    const [reference, setReference] = useState<string>();
    const [getDetails, collectPallet] = useActions([details.trigger, collectPalletAction.trigger]);
    const collectPalletState = useSelector(getResupplyOrderPickPalletState);
    const previous = usePrevious({ collectPalletState });
    const inputRef = useRef<Input | null>(null);
    const onSubmitReference = () => {
        if (reference === pallet?.reference) {
            setStep('validate');
        } else {
            message.error("La référence palette entrée ne correspond pas à celle de la palette choisie pour l'OR");
        }
    };
    const onValidate = () => {
        collectPallet({ resupplyOrderId: resupplyOrder?.id, palletId: pallet?.id });
    };
    const onChangeField: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (barCode.data?.match(constants.PALLET_BARCODE_REGEX)?.[1] === pallet?.barcode) {
                    setStep('validate');
                } else {
                    message.error("Le code-barre scanné ne correspond pas à celui de la palette choisie pour l'OR");
                }
            }
        },
        {
            deps: [setStep, pallet?.barcode],
            disable: !visible,
        }
    );

    useEffect(() => {
        if (!visible) {
            setReference(undefined);
            setStep('scan');
            if (inputRef.current) {
                inputRef.current.blur();
            }
        } else {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [visible, setReference]);

    useEffect(() => {
        if (previous?.collectPalletState.loading && !collectPalletState.loading) {
            if (collectPalletState.error) {
                message.error('Une erreur est survenue lors du prélèvement de la palette');
            } else {
                setStep('success');
            }
        }
    }, [previous?.collectPalletState, collectPalletState.loading, collectPalletState.error, setStep]);

    // refresh resupply order after success
    useEffect(() => {
        let timeout: number;

        if (step === 'success') {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onClose();
                    onSuccess();
                    getDetails({ resupplyOrderId: resupplyOrder?.id });
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [step, onClose, onSuccess, isMounted, getDetails, resupplyOrder?.id]);

    return (
        <CustomModal
            footer={
                step !== 'success' ? (
                    <>
                        {step === 'scan' && (
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="enter"
                                    type="primary"
                                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                    shortcutScope={shortcutScope}
                                    onClick={onSubmitReference}
                                >
                                    Valider
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        )}
                        {step === 'validate' && (
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="enter"
                                    type="primary"
                                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                    shortcutScope={shortcutScope}
                                    onClick={onValidate}
                                    loading={collectPalletState.loading}
                                >
                                    Prélever
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        )}
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : null
            }
            visible={visible}
            onCancel={onCancel}
            title={step !== 'success' ? (step === 'scan' ? 'Palette prélevée' : 'Quantité prélevée') : undefined}
            width={step === 'success' ? 266 : 368}
            keyboard={false}
            maskClosable={step !== 'success'}
            altTitle
        >
            {step === 'scan' && (
                <ArrowNavItem scope={shortcutScope}>
                    <Input
                        placeholder={
                            isZebra ? 'Flasher la palette ou saisir la référence' : 'Entrer la référence de la palette'
                        }
                        value={reference}
                        onChange={onChangeField}
                        ref={inputRef}
                        autoFocus
                    />
                </ArrowNavItem>
            )}
            {step === 'validate' && (
                <QuantityCard label="Colis à prélever" value={formatNumber(pallet?.quantity)} onWhite />
            )}
            {step === 'success' && (
                <SuccessMessage
                    message={
                        (pallet?.quantity ?? 0) + (resupplyOrder?.quantity ?? 0) ===
                        (resupplyOrder?.quantityToBeTransferred ?? 0)
                            ? 'Tous les colis on été prélevés'
                            : 'Les colis ont été prélevés'
                    }
                />
            )}
        </CustomModal>
    );
};

export default ResupplyPalletScanModal;
