import { SearchPaginationQuery } from '.';
import { Customer } from './apiTypes';
import api from './_client';

export type CustomerListPayload = SearchPaginationQuery & {
    reference: Customer['reference'];
};
export const list = async (payload: CustomerListPayload) => await api.get('/customers', { params: payload });

export interface CustomerDetailsPayload {
    customerId: Customer['id'];
}
export const details = async ({ customerId }: CustomerDetailsPayload) => {
    return await api.get(`/customers/${customerId}`);
};
