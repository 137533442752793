import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Descriptions, Divider, PaginationProps, Spin } from 'antd';

import { getOrderListState, list as orderList } from '../../store/actions/orders';
import { getCustomerOrderStateById, details as customerOrderDetails } from '../../store/actions/customerOrders';
import { Order } from '../../store/api/apiTypes';

import { formatNumber, translateOrderStatus } from '../../helpers/i18n';
import { useActions, useIsMobile } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import Header from '../../components/Header';
import List, { ListColumn } from '../../components/list/List';
import Seo from '../../components/Seo';
import PageHeader from '../../components/PageHeader';
import QuantityCard from '../../components/QuantityCard';
import useQueryParams from '../../hooks/queryParams';

const OrderList: FC = () => {
    const history = useHistory();
    const isMobile = useIsMobile();
    const { loadingOrderId, customerOrderId } = useParams<{ loadingOrderId: string; customerOrderId: string }>();
    const [queryParams, setQueryParams] = useQueryParams('OrderList');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const [fetchCustomerOrder, listOrders] = useActions([customerOrderDetails.trigger, orderList.trigger]);
    const orderListState = useSelector(getOrderListState);
    const customerOrderState = useSelector(getCustomerOrderStateById(parseInt(customerOrderId, 10)));
    const onClickOrder = (record: Order) => {
        history.push(
            getRoute(RoutePathName.orderDetails, {
                orderId: record.id,
                loadingOrderId,
                customerOrderId,
            })
        );
    };
    const onPaginationChange: PaginationProps['onChange'] = (p) => {
        setQueryParams({
            page: p ? p - 1 : 0,
        });
    };
    const columns: Array<ListColumn<Order>> = [
        {
            dataIndex: 'reference',
            title: 'N° de commande',
            flex: '1 1 45%',
        },
        {
            key: 'packages',
            title: 'Colis',
            flex: '1 1 10%',
            render: (record) => formatNumber(record.nbOfPackageEstimated),
        },
        {
            key: 'status',
            title: 'Statut',
            flex: '1 1 45%',
            render: (record) => translateOrderStatus(record.status),
        },
    ];

    useEffect(() => {
        if (customerOrderId) {
            fetchCustomerOrder({ customerOrderId });
        }
    }, [fetchCustomerOrder, customerOrderId]);

    useEffect(() => {
        listOrders({
            customerOrderId,
            page,
        });
    }, [listOrders, customerOrderId, page]);

    return (
        <>
            <Seo title="Commandes" />
            <Header
                title="Commandes"
                backRoute={getRoute(RoutePathName.customerOrderDetails, { loadingOrderId, customerOrderId })}
            />
            <PageHeader>
                <Spin spinning={customerOrderState.loading}>
                    <Descriptions column={{ xs: 3, md: 4 }} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="Code client">
                            {customerOrderState.data?.customer?.reference ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Client" span={4}>
                            {customerOrderState.data?.customer?.label ?? '—'}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>
            <QuantityCard
                value={formatNumber(orderListState.data?.totalCount)}
                label={`Commande${orderListState.data?.totalCount !== 1 ? 's' : ''} à préparer`}
            />
            <Divider />
            <List<Order>
                columns={columns}
                data={orderListState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowEnterPress={onClickOrder}
                onRowClick={onClickOrder}
                isLoading={orderListState.loading}
                loadingRowsCount={11}
                title="Liste des commandes"
                style={isMobile ? { marginTop: 24 } : undefined}
                pagination={
                    (orderListState.data?.pageCount ?? 0) > 1
                        ? {
                              total: orderListState.data?.totalCount,
                              pageSize: orderListState.data?.pageSize,
                              hideOnSinglePage: true,
                              current: page + 1,
                              onChange: onPaginationChange,
                          }
                        : undefined
                }
            />
        </>
    );
};

export default OrderList;
