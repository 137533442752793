import { SearchPaginationQuery } from '.';
import { Pallet, Place, ResupplyOrder, ResupplyOrderType } from './apiTypes';
import api from './_client';

export type ResupplyOrderListPayload = SearchPaginationQuery;
export const list = async (payload: ResupplyOrderListPayload = {}) =>
    await api.get('/resupplyOrders', { params: payload });

export interface ResupplyOrderDetailsPayload {
    resupplyOrderId: number;
}
export const details = async ({ resupplyOrderId, ...payload }: ResupplyOrderDetailsPayload) =>
    await api.get(`/resupplyOrders/${resupplyOrderId}`, payload);

export interface ResupplyOrderPickPalletPayload {
    resupplyOrderId: ResupplyOrder['id'];
    palletId: Pallet['id'];
}
export const collectPallet = async ({ resupplyOrderId, palletId, ...payload }: ResupplyOrderPickPalletPayload) =>
    await api.post(`/resupplyOrders/${resupplyOrderId}/collectPallet/${palletId}`, payload);

export type ResupplyOrderUpdatePayload = ResupplyOrder;
export const update = async (payload: ResupplyOrderUpdatePayload) =>
    await api.put(`/resupplyOrders/${payload.id}`, payload);

export interface ResupplyOrderCreatePayload {
    placeId: Place['id'];
    type: ResupplyOrderType;
    quantityToBeTransferred: number;
}
export const create = async (payload: ResupplyOrderCreatePayload) => await api.post('/resupplyOrders', payload);
