type ObjectId = string;

export enum SupportedLanguage {
    fr = 'fr',
    en = 'en',
    // es = 'es',
    // du = 'du',
    // it = 'it',
    // de = 'de',
    // ca = 'ca',
    // po = 'po',
    // ru = 'ru',
    // pt = 'pt',
    // tr = 'tr',
    // us = 'us',
    // da = 'da',
    // hu = 'hu',
    // zh = 'zh',
    // ko = 'ko',
    // sv = 'sv',
    // cs = 'cs',
    // no = 'no',
    // br = 'br',
    // ja = 'ja',
}

export enum SortOrder {
    asc = 'asc',
    desc = 'desc',
}

export enum PermissionRefType {
    users = 'users',
    organization = 'organizations',
    role = 'roles',
}

export enum PermissionRight {
    read = 'r',
    write = 'w',
    disabled = 'disabled',
}

export enum Permission {
    // Front permission
    reception = 'reception',
    preparation = 'preparation',
    loading = 'loading',
    resupply = 'resupply',
    transferOrder = 'transferOrder',
    customerReturn = 'customerReturn',
    repair = 'repair',
    backToWorkshop = 'backToWorkshop',
    break = 'break',
    opac = 'opac',
    inventory = 'inventory',
    stockControl = 'stockControl',
    searchStock = 'searchStock',
    placeManagement = 'placeManagement',
    platformReception = 'platformReception',
    platformLotVisualization = 'platformLotVisualization',
    platformRDV = 'platformRDV',
    platformShipping = 'platformShipping',
    platformLoading = 'platformLoading',
    platformReturn = 'platformReturn',
    platformSetting = 'platformSetting',

    // API Permission
    allFunction = 'allFunction',
    allFunctionDesktop = 'allFunctionDesktop',

    superAdmin = 'superAdmin',
    admin = 'admin',
    users = 'users',
    roles = 'roles',
    organizations = 'organizations',
    staticPages = 'staticPages',
    questions = 'questions',
    applications = 'applications',
    purchaseOrders = 'purchaseOrders',
    productions = 'productions',
    afterSaleServices = 'afterSaleServices',
    pallets = 'pallets',
    parcels = 'parcels',
    controlOrder = 'controlOrder',
    controlOrderParcel = 'controlOrderParcel',
    controlLoadingOrders = 'controlLoadingOrders',
    movementHistories = 'movementHistories',
    places = 'places',
    placeStores = 'placsStores',
    placeSectors = 'placeSectors',
    placeLanes = 'placeLanes',
    placeAlveoles = 'placeAlveoles',
    placeLevels = 'placeLevels',
    printers = 'printers',
    resupplyOrder = 'resupplyOrder',
    inventories = 'inventories',
    stockControls = 'stockControls',
    stockResearch = 'stockResearch',
    loadingOrders = 'loadingOrders',
    orders = 'orders',
    ordersParcels = 'ordersParcels',
    stockDifferences = 'stockDifferences',
    sites = 'sites',
    settings = 'settings',
    incidents = 'incidents',
}

export enum RoleScope {
    organizations = 'organizations',
    users = 'users',
}

export enum RoleName {
    superAdmin = 'SuperAdmin',
    admin = 'Administrateur',
    cpc = 'Cariste/Préparateur/Chargeur',
    ss = 'Service support',
    administrative = 'Administratif',
    gsriruRole = 'Gestionnaire de stock/RI/RU',
    pfAdmin = 'Administrateur Plateforme',
    pfUser = 'Utilisateur Plateforme',
    embpmkRole = 'Emballage PMK',
}

export enum SignedAction {
    passwordReset = 'passwordReset',
}

export enum EmailTemplate {
    welcome = 'welcome',
    passwordReset = 'passwordReset',
}

export enum DeviceType {
    ios = 'ios',
    android = 'android',
    web = 'web',
    koala = 'koala',
    api = 'api',
}

export enum OrganizationType {
    platform = 'platform',
    default = 'default',
}

export enum OrganizationSlug {
    ekipa = 'PMAT',
    parisot = 'PSL',
    PF12G = '12G',
    PF13N = '13N',
    PF14A = '14A',
    PF15O = '15O',
    PF22M = '22M',
    unknown = 'unknown',
}

export enum WMSMode {
    none = 'none',
    reception = 'reception',
    preparation = 'preparation',
    movement = 'movement',
    inventory = 'inventory',
    stockControl = 'stockControl',
    stockResearch = 'stockResearch',
    transferOrder = 'transferOrder',
    customerReturn = 'customerReturn',
}

export enum PrinterStatus {
    working = 'working',
    broken = 'broken',
}

export enum SupplierType {
    none = 'none',
}

export enum OrderType {
    auto = 'Auto',
    manuel = 'Manuel',
}
export enum TransferType {
    pallet = 'pallet',
    package = 'package',
}
export enum TransferOrderStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
}

export interface TransferOrder {
    id: number;
    startZone: PalletPlace;
    finishZones?: PalletPlace[];
    parcel?: Parcel;
    organization?: Organization;
    customerOrder?: CustomerOrder;
    type: TransferOrderType;
    transferType: TransferType;
    packageQuantity?: number;
    transferedPackages?: number;
    startPlaceZoneType: PlaceZoneType;
    finishPlaceZoneType?: PlaceZoneType;
    status: TransferOrderStatus;
    operator?: User;
    createdAt: string;
    updatedAt: string;
    pallet?: Pallet;
}

export enum TransferOrderType {
    auto = 'auto',
    manual = 'manual',
}

export enum ReturnZoneType {
    picking = 'picking',
    mass = 'mass',
    soldeur = 'soldeur',
    destruction = 'destruction',
}

export enum PurchaseOrderStatus {
    toProceed = 'toProceed',
    inProgress = 'inProgress',
    partial = 'partial',
    finished = 'finished',
}

export enum ProductionStatus {
    toProceed = 'toProceed',
    inProgress = 'inProgress',
    finished = 'finished',
}

export enum ParcelType {
    production = 'PRODUCTION',
    assimilatedProduction = 'SAV',
    negoce = 'NEGOCE',
}

export enum ReceptionStatus {
    inProgress = 'inProgress',
    pending = 'pending',
}

// TODO: Remove
export enum PurchaseOrderQualityControl {
    conform = 'conform',
    nonConforming = 'nonConforming',
    notStart = 'notStart',
}

export enum PalletQuality {
    conform = 'conform',
    noConform = 'noConform',
}

export enum PalletStatus {
    stored = 'stored',
    closed = 'closed',
    opened = 'opened',
    toBeLoaded = 'toBeLoaded',
    loaded = 'loaded',
    receivedInPlatform = 'receivedInPlatform',
    receivedInPlatformDamaged = 'receivedInPlatformDamaged',
    receivedInPlatformDerogated = 'receivedInPlatformDerogated',
    toBeLoadedSendToClient = 'toBeLoadedSendToClient',
    toBeLoadedSendToClientDerogated = 'toBeLoadedSendToClientDerogated',
    loadedSendToClient = 'loadedSendToClient',
    loadedSendToClientDerogated = 'loadedSendToClientDerogated',
}

export enum PalletSize {
    small = 'small',
    medium = 'medium',
    large = 'large',
}

export enum PalletSpace {
    small = 3,
    medium = 4,
    large = 6,
}

// TODO: à supprimer
export enum PlacePosition {
    bottom = 'bottom',
    top = 'top',
}

// TODO: à supprimer
export enum PlaceCapacity {
    small = 'small',
    medium = 'medium',
    large = 'large',
}

// TODO: à supprimer
export enum PlaceLoad {
    light = 'light',
    heavy = 'heavy',
}

export enum PlaceStoreType {
    site = 'site',
    well = 'well',
}

export enum PlaceZoneType {
    mass = 'mass',
    picking = 'picking',
    preparation = 'preparation',
    accumulation = 'accumulation',
}

export enum ResupplyOrderType {
    resupply = 'resupply',
    direct = 'direct',
}

export enum ResupplyOrderStatus {
    creating = 'creating',
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
}

export enum InventoryStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    toValidGap = 'toValidGap',
    completed = 'completed',
}

export enum InventoryType {
    place = 'place',
    parcel = 'parcel',
}

export enum StockControlStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    toValidGap = 'toValidGap',
    completed = 'completed',
}

export enum StockControlType {
    place = 'place',
    parcel = 'parcel',
}

export enum SearchType {
    parcels = 'parcels',
    pallets = 'pallets',
    places = 'places',
    quantity = 'quantity',
}

export enum OperatorSymbol {
    greaterThan = '>',
    lessThan = '<',
    equalTo = '=',
}

export enum LoadingOrderType {
    directDelevery = 'directDelivery',
    logisticsPlateform = 'logisticsPlateform',
}

export enum LoadingOrderStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
    toBeLoaded = 'toBeLoaded',
    loaded = 'loaded',
}

export enum CarrierType {}

export enum OrderStatus {
    toBeProcessed = 'toBeProcessed',
    started = 'started',
    inProgress = 'inProgress',
    completed = 'completed',
}

export enum PreparationOrderType {
    detail = 'detail',
    sav = 'sav',
    none = 'none',
}

export enum PreparationStatus {
    toBeProcessed = 'toBeProcessed',
    started = 'started',
    inProgress = 'inProgress',
    completed = 'completed',
    partial = 'partial',
    toRegularize = 'toRegularize',
    canceled = 'canceled',
}

export enum PickingStatus {
    toProceed = 'toBeProceed',
    inProgress = 'inProgress',
    waiting = 'waiting',
    finished = 'finished',
}

export enum PreparationType {
    picking = 'picking',
    directOrPicking = 'directOrPicking',
}

export enum ControlLoadingOrderStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
}

export enum ControlOrderPalletStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
}

export enum SiteReference {
    ekipa = 'PMAT',
    parisot = 'PSL',
}

export enum MovementHistoryType {
    reception = 'reception',
    picking = 'picking',
    resupply = 'resupply',
    customerReturn = 'customerReturn',
    direct = 'direct',
    transfer = 'transfer',
    broken = 'broken',
    repair = 'repair',
    destruction = 'destruction',
    discountShipping = 'discountShipping',
    backToWorkshop = 'backToWorkshop',
}

export enum Device {
    desktop = 'desktop',
    zebra = 'zebra',
}

export enum DayOfWeek {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
}

export enum ExpeditionStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
}

export enum MailStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    error = 'error',
    completed = 'completed',
}

export enum PlatformLoadingStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
}

export enum StickerType {
    trackingSheet = 'trackingSheet',
    customerAddressing = 'customerAddressing',
    clientStickers = 'clientStickers',
}

export interface Meta {
    [key: string]: string;
}

export type Translation = {
    [key in SupportedLanguage]?: string;
};

export interface ApiInfos {
    name: string;
    version: string;
    env: string;
    instance: string;
    request: string;
}

export interface Coordinates {
    id?: ObjectId;
    latitude?: number;
    longitude?: number;
}

export interface Address {
    id?: number;
    number?: string;
    street?: string;
    zipCode?: string;
    city?: string;
    region?: string;
    country?: string;
    locality?: string;
    sup1?: string;
    sup2?: string;
    directives?: string;
    floor?: string;
    elevator?: boolean;
    intercom?: boolean;
    digiCode?: string;
    codeIris?: string;
    codeInsee?: string;
    distanceToParkingSpot?: number;
    coordinates?: Coordinates;
    coordinatesRadius?: number;
}
export interface Organization {
    id: number;
    name: string;
    slug: string;
    logoUrl?: string;
    type: OrganizationType;
    createdAt: string;
    updatedAt: string;
}

export interface User {
    id: number;
    createdAt?: string;
    updatedAt?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    acceptedTerms?: boolean;
    acceptedTermsAt?: string;
    avatarUrl?: string;
    role?: Role;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    mergedPermissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        // [key in RoleScope]?: ObjectId[];
        [RoleScope.organizations]?: ObjectId[] | Organization[];
        [RoleScope.users]?: ObjectId[] | User[];
    };
    organization?: Organization;
}

export interface Scope {
    agencies: ObjectId[];
}

export interface Role {
    id: number;
    organization?: Organization;
    name: RoleName;
    removable?: boolean;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        [key in RoleScope]?: boolean;
    };
}

export interface QuestionSection {
    id: ObjectId;
    title: Translation | string;
    description?: Translation | string;
    questionCount?: number;
    questions?: Question[];
}

export interface Question {
    id: ObjectId;
    section?: QuestionSection;
    title: Translation | string;
    description?: Translation | string;
    answer: Translation | string;
}

export interface Application {
    id: ObjectId;
    name: string;
    organization?: Organization;
    applicationClients?: ApplicationClient[];
}

export interface ApplicationClient {
    id: ObjectId;
    name: string;
    apiKey: string;
    isPrivate: boolean;
    reference: string;
    type: DeviceType;
    options: {
        isBackOffice?: boolean;
        [key: string]: unknown;
    };
    iosOptions?: {
        authentificationPush: string;
        keyId: string;
        teamId: string;
        bundleId: string;
        stagingServer: boolean;
    };
    androidOptions?: {
        certificatPush: string;
        bundleId: string;
    };
    application?: Application;
    organization?: Organization;
}

export interface Printer {
    id: number;
    reference?: string;
    name: string;
    status: PrinterStatus;
}

export interface Site {
    id: number;
    reference?: string;
    label?: string;
}

export interface Supplier {
    id: number;
    reference: string;
    name?: string;
    type?: SupplierType; // To define !
}

export interface PurchaseOrder {
    id: number;
    reference: string;
    expectedDeliveryDate: string;
    deliveryDate?: string;
    // site: Site;
    organization?: Organization;
    supplier: Supplier;

    // Qui ne sont pas dans l'objet PurchaseOrder
    receivedQuantity?: number;
    storedQuantity?: number;
    quantity?: number;
    status?: PurchaseOrderStatus; // To define !
    qualityControl?: PurchaseOrderQualityControl;
    parcels?: Parcel[];
}

export interface Product {
    // TO DEFINE !!
    id: number;
    reference?: string;
    name: string;
}

export interface Furniture {
    id: number;
    barcode?: string;
    reference: string;
    label?: string;

    parcels?: Parcel[];
}

export interface FurnitureParcel {
    id: number;
    furniture: Furniture;
    parcel: Parcel;
    quantity: number;
}

export interface Parcel {
    id: number;
    barcode: string;
    reference: string;
    label?: string;
    weight?: number;
    size?: {
        height?: number;
        width?: number;
        depth?: number;
    };
    type: ParcelType;
    quantityPerFurniture?: number;

    // Qui ne sont pas dans l'objet Parcel
    quantity?: number;
    reception?: Reception;
    receptions?: Reception[];
    pallet?: Pallet;
    pallets?: Pallet[];
}

export interface Reception {
    id: number;
    parcel?: Parcel;
    pallets?: Pallet[];
    organization?: Organization;
    quantity?: number;
    status: ReceptionStatus;
    type: ParcelType;
}

export interface Production {
    id: number;
    organization?: Organization;
    parcel: Parcel;
    receivedQuantity?: number;
    storedQuantity?: number;
    status: ProductionStatus;
}

export interface AfterSaleService {
    id: number;
    site: Site;
    package: Package;
    deliveryDate: string;
}

export interface Package {
    id: number;
    parcel: Parcel;
    pallet?: Pallet;
    resupply?: ResupplyOrder;
    order?: Order;
    cont?: string;
}

export interface Pallet {
    id: number;
    reference: string;
    barcode: string;
    quantity: number;
    size: PalletSize;
    status: PalletStatus;
    isArchived?: boolean;
    quality: PalletQuality;
    anomaly?: boolean;
    vrac?: boolean;
    weight?: number;

    resupply?: ResupplyOrder;
    customerOrder?: CustomerOrder;
    order?: Order;

    platformReceptionDate?: string;

    // Qui ne sont pas dans l'objet Pallet
    reception?: Reception;

    parcel?: Parcel;
    packages?: Package[];

    lastOperator?: User;
    historyOperators?: User[];

    currentPlace?: Place;
    reservedPlace?: Place;
    historyPlaces?: Place[];
}

export interface OperatorPallet {
    id: number;
    operator: User;
    pallet: Pallet;
}

export interface PalletPlace {
    id: number;
    place: Place;
    pallet?: Pallet;
    index?: number;
    space: number;
    isFulfilled: boolean;
    isArchived?: boolean;
    fullfilledAt?: string;
}

export interface PlaceStore {
    id: number;
    organization?: Organization;
    number: string;
    name: string;
    type: PlaceStoreType;
    createdAt: string;
    updatedAt: string;
}

export interface PlaceSector {
    id: number;
    placeStore?: PlaceStore;
    sector: string;
    zoneType: PlaceZoneType;
    storageByAccumulation: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface PlaceLane {
    id: number;
    placeSector?: PlaceSector;
    lane: string;
    createdAt: string;
    updatedAt: string;
}

export interface PlaceAlveole {
    id: number;
    placeLane?: PlaceLane;
    alveole: string;
    createdAt: string;
    updatedAt: string;
}

export interface PlaceLevel {
    id: number;
    placeAlveole?: PlaceAlveole;
    level: string;
    createdAt: string;
    updatedAt: string;
}

export interface Place {
    id: number;

    store?: PlaceStore;

    zoneType?: PlaceZoneType;

    barcode?: string;
    reference?: string;
    locker?: string;

    sector?: string;
    lane?: string;
    alveole?: string;
    level?: string;

    rank?: number;

    space?: number;

    minLength?: number;
    maxLength?: number;
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;

    minWeight?: number;
    maxWeight?: number;

    isStorageAuthorization?: boolean;
    isWithdrawalAuthorization?: boolean;
    isResupplyAuthorization?: boolean;
    isMixingParcel?: boolean;

    parcel?: Parcel;
    pallets?: Pallet[];
    isArchived?: boolean;

    createdAt?: string;
    updatedAt?: string;

    storageByAccumulation?: boolean;

    // Qui ne sont pas dans l'objet Place
    spot?: string;
    index?: number;
    available?: boolean;
    compatible?: boolean;
    resupply?: ResupplyOrder;
}

export interface PlaceFilter {
    sectors?: string[];
    lanes?: string[];
    alveoles?: string[];
    levels?: string[];
    lockers?: string[];
}

export interface Space {
    id: number;
    index: number;
    space: number;
    place: Place;
    pallet: Pallet;
}

export interface ResupplyOrder {
    id: number;
    type: ResupplyOrderType;
    status: ResupplyOrderStatus;
    parcel: Parcel;
    place: Place;
    pallets?: Pallet[];
    inProgressOperationPalletPlace?: PalletPlace;
    preparation?: Preparation;
    quantity?: number;
    quantityToBeTransferred: number;
    index: number;
    date: string;
    operator?: User;
}

export interface Inventory {
    id: number;
    placeStore?: PlaceStore;
    sector?: string;
    lane?: string;
    alveole?: string;
    level?: string;
    position?: string;
    spot?: string;
    parcel?: Parcel;
    operator: User;
    date: string;
    type: InventoryType;
    status: InventoryStatus;
}

export interface InventoryPlace {
    id: number;
    inventory: Inventory;
    place: Place;
    pallet?: Pallet;
    parcel?: Parcel;
    foundParcel?: Parcel;
    theoreticalQuantity: number;
    quantity?: number;
    status: InventoryStatus;
}

export interface StockControl {
    id: number;
    placeStore?: PlaceStore;
    sector?: string;
    lane?: string;
    alveole?: string;
    level?: string;
    position?: string;
    spot?: string;
    parcel?: Parcel;
    operator: User;
    date: string;
    type: StockControlType;
    status: StockControlStatus;
}

export interface StockControlPlace {
    id: number;
    stockControl: StockControl;
    place: Place;
    pallet?: Pallet;
    parcel?: Parcel;
    foundParcel?: Parcel;
    theoreticalQuantity: number;
    quantity?: number;
    status: StockControlStatus;
}

export interface ResearchStock {
    parcels: Parcel[];
    palletPlaces: PalletPlace[];
    pallets: Pallet[];
}

export interface Carrier {
    id: number;
    reference: string;
    label: string;
    type?: CarrierType;

    historyLoadingOrders?: LoadingOrder[];
}

export interface Customer {
    id: number;
    reference: string;
    label: string;

    platformOrganization?: Organization;
    parisotOrganization?: Organization;
    parisotTour?: number;
    parisotTime?: number;
    parisotDay?: DayOfWeek;

    // To compute
    numberOfPackage?: number;
}

export enum CustomerOrderStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
    cancelled = 'cancelled',
    toBeLoaded = 'toBeLoaded',
    loaded = 'loaded',
    toRegularize = 'toRegularize',
    partial = 'partial',
    toBeLoadedSendToClient = 'toBeLoadedSendToClient',
    loadingSendToClient = 'loadingSendToClient',
    loadedSendToClient = 'loadedSendToClient',
}
export enum PreparationMethod {
    byOrder = 'byOrder',
    byClient = 'byClient',
}

export enum ControlPreparationStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
    toRegularize = 'toRegularize',
}

export enum ControlCustomerOrderStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
}

export interface LoadingOrder {
    id: number;
    organization?: Organization;
    tourNumber: number;
    editionDate: string;
    date: string;
    deliveryDate: string;
    carrier: Carrier;
    type: LoadingOrderType;
    status: LoadingOrderStatus;
    plateformCode?: string;
    weightEstimated?: number;
    volumeEstimated?: number;
    nbOfPackageEstimated?: number;
    nbOfPalletEstimated?: number;
    // Not in a base
    customerOrders?: CustomerOrder[];
    // To compute
    quantity?: number;
    quantityToControl?: number;
    preparedQuantity?: number;
    controlledQuantity?: number;
    // numberOfPallets?: number;
}

export interface CustomerOrder {
    id: number;
    loadingOrder?: LoadingOrder;
    rank: number;
    customer?: Customer;
    deliveryAddress?: Address;
    preparationMethod: PreparationMethod;
    status: CustomerOrderStatus;
    weightEstimated?: number;
    volumeEstimated?: number;
    nbOfPackageEstimated?: number;
    nbOfPalletEstimated?: number;
    deliveryInstruction?: string;
    controlStatus?: ControlCustomerOrderStatus;
    platformReasonCode?: PlatformReasonCode;
    platformReasonCodeHistory?: PlatformReasonCode[];
    orderDifference?: number;
    orderDifferenceSAV?: string;
    fileNameOfClientMask?: string;
    quantityToLoadToSendToClient?: number;
    quantityLoadedToSendToClient?: number;
    isBlocked: boolean;

    // Plateforme
    deliveryDateToClient?: string;
    tour?: number;
    time?: number;
    day?: DayOfWeek;
    loadedWeight?: number;
    customerOrderPlatformReasonCodes?: CustomerOrderPlatformReasonCode[];

    // Not in a base
    orders?: Order[];

    // To compute
    quantity?: number;
    quantityToControl?: number;
    preparedQuantity?: number;
    controlledQuantity?: number;
    pallets?: Pallet[];
}
export interface Order {
    id: number;
    reference: string;
    customerOrder?: CustomerOrder;
    partial?: boolean;
    status: OrderStatus;
    weightEstimated?: number;
    volumeEstimated?: number;
    nbOfPackageEstimated?: number;
    nbOfPalletEstimated?: number;
    removedPackagesPallet?: Pallet;
    // Not in a base
    detailOrderParcels?: OrderParcel[];
    orderFurnitures?: OrderFurniture[];
    orderParcels?: OrderParcel[];
    // pallets?: Pallet[];
    // numberOfPallets?: number;
}

export interface Preparation {
    id: number;
    customerOrder?: CustomerOrder;
    order?: Order;
    parcel?: Parcel;

    picking?: boolean;
    direct?: boolean;

    pickingQtyToPrep?: number;
    pickingPreparedQty?: number;
    pickingPrepPallet?: Pallet;
    pickingPrepStatus?: PickingStatus;

    directQtyToPrep?: number;

    quantity: number;
    initialQuantity: number;
    preparedQuantity?: number;
    type: PreparationOrderType;
    status: PreparationStatus;

    operator?: User;

    historyPickingPlace?: HistoryPreparationPicking[];
    resupplies?: ResupplyOrder[];
    resupplyDirectPrep?: ResupplyOrder;

    controlStatus?: ControlPreparationStatus;
    controlledQuantity?: number;
    removedPackagesPallet?: Pallet;

    quantityUpdatedFlag?: boolean;
}

export interface HistoryPreparationPicking {
    id: number;
    preparation?: Preparation;
    sector: string;
    lane: string;
    alveole: string;
    level: string;
    quantity: number;
}

export interface StockDifference {
    id: number;
    place: Place;
    parcel: Parcel;
    stockDifferenceQuantity: number;
    packageAnomaliesQuantity: number;
    message?: string;
    createdAt: string;
    updatedAt: string;
}

export interface ControlLoadingOrder {
    id: number;
    loadingOrder: LoadingOrder;
    operator?: User;
    palletQuantityChecked?: number;
    palletQuantityToCheck: number;
    status: ControlLoadingOrderStatus;
    controlOrderPallets?: ControlOrderPallet[];
    createdAt: string;
    updatedAt: string;
}

export interface ControlOrderPallet {
    id: number;
    controlLoadingOrder: ControlLoadingOrder;
    customerOrder?: CustomerOrder;
    order?: Order;
    status: ControlOrderPalletStatus;
    palletQuantityChecked?: number;
    palletQuantityToCheck: number;
    createdAt: string;
    updatedAt: string;
}

export const itemTypeFromCode: { [code: string]: ParcelType } = {
    M: ParcelType.production,
    P: ParcelType.negoce,
};

export interface MovementHistory {
    id: number;
    organization?: Organization;
    type: MovementHistoryType;
    parcel?: Parcel;
    sourceSector?: string;
    sourceLane?: string;
    sourceAlveole?: string;
    sourceLevel?: string;
    sourceLocker?: string;
    destinationSector?: string;
    destinationLane?: string;
    destinationAlveole?: string;
    destinationLevel?: string;
    destinationLocker?: string;
    quantity: number;
    date: string;
    operator?: User;
    createdAt: string;
    updatedAt: string;
}

export interface Repair {
    id: number;
    quantity: number;
    status: RepairStatus;
    parcel: Parcel;
    createdAt: string;
    updatedAt: string;
    packagesQuantity?: number;
    transferedPackages?: number;
    operator?: User;
    organization: Organization;
}

export enum RepairStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
}

export enum MovementBrokenStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
    created = 'created',
}

export interface MovementBroken {
    id: number;
    organization?: Organization;
    parcel?: Parcel;
    pallet?: Pallet;
    quantity: number;
    status: MovementBrokenStatus;
    operator?: User;
    createdAt: string;
    updatedAt: string;
    destroyedQuantities?: number;
    repairedQuantities?: number;
    discountQuantities?: number;
}
export interface PlatformAnomalyCode {
    id: number;
    reference: string;
    label: string;
    createdAt: string;
    updatedAt: string;
}
export interface PlatformReasonCode {
    id: number;
    reference: string;
    label: string;
    createdAt: string;
    updatedAt: string;
}
export interface OrderFurniture {
    id: number;
    order?: Order;
    furniture?: Furniture;
    quantity: number;
    initialQuantity: number;
    orderParcels?: OrderParcel[];
    createdAt: string;
    updatedAt: string;
}
export interface OrderParcel {
    id: number;
    order?: Order;
    orderFurniture?: OrderFurniture;
    parcel?: Parcel;
    preparation?: Preparation;
    quantity: number;
    initialQuantity: number;
    quantityPerFurniture?: number;
    createdAt: string;
    updatedAt: string;
}

export enum ClientRegularizationStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
}
export enum ParcelRegularisationStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
}
export interface ClientRegularization {
    id: number;
    customerOrder?: CustomerOrder;
    pallet?: Pallet;
    quantity: number;
    quantityRemoved?: number;
    status: ClientRegularizationStatus;
    createdAt: string;
    updatedAt: string;
}
export interface ParcelRegularization {
    id: number;
    clientRegularization?: ClientRegularization;
    parcel?: Parcel;
    quantity: number;
    quantityRemoved?: number;
    status: ParcelRegularisationStatus;
}

export enum PlatformReceptionStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    partial = 'partial',
    completed = 'completed',
}
export interface PlatformReception {
    id: number;
    loadingOrder?: LoadingOrder;
    receivedQuantity?: number;
    quantity: number;
    status: PlatformReceptionStatus;
    receivedDate?: string;
    createdAt: string;
    updatedAt: string;
}

export interface Expedition {
    id: number;
    organization?: Organization;
    deliveryDate: string;
    tour: number;
    lotQuantity: number;
    weight: number;
    platformLoadings?: PlatformLoading[];
    customerOrders?: CustomerOrder[];
    createdAt: string;
    updatedAt: string;
}

export interface PlatformLoading {
    id: number;
    expedition?: Expedition;
    reference: string;
    referenceNumber: number;
    quantityToLoad: number;
    quantityLoaded?: number;
    weight: number;
    status: PlatformLoadingStatus;
    customerOrders?: CustomerOrder[];
    createdAt: string;
    updatedAt: string;
}

export interface CustomerOrderPlatformReasonCode {
    id: number;
    platformReasonCode?: PlatformReasonCode;
    customerOrder?: CustomerOrder;
    previousDeliveryDateToClient?: string;
    deliveryDateToClient?: string;
    previousTour?: number;
    tour?: number;
    previousTime?: number;
    time?: number;
    archived?: boolean;
    mailStatus: MailStatus;
    errorMessage?: string;
    createdAt: string;
    updatedAt: string;
}

export interface MovementBackToWorkshop {
    id: number;
    organization?: Organization;
    parcel?: Parcel;
    pallet?: Pallet;
    quantity: number;
    status: MovementBackToWorkshopStatus;
    operator?: User;
    createdAt: string;
    updatedAt: string;
}
export enum MovementBackToWorkshopStatus {
    toBeProcessed = 'toBeProcessed',
    inProgress = 'inProgress',
    completed = 'completed',
    created = 'created',
}

export interface Machine {
    id: number;
    label: string;
}

export interface Incident {
    id: number;
    label: string;
}

export interface MachineIncident {
    id: number;
    machine: Machine;
    incident: Incident;
    operator: User;
    date: Date;
}
