import React, { FC } from 'react';

import { getRoute, RoutePathName } from '../../../routes';
import BasicList from '../../../components/BasicList';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import ButtonLink from '../../../components/ButtonLink';
import Seo from '../../../components/Seo';
import Header from '../../../components/Header';

const SettingsMenu: FC = () => (
    <>
        <Seo title="Paramètres" />
        <Header title="Paramètres" backRoute={getRoute(RoutePathName.home)} />
        <nav className="menu">
            <BasicList>
                <li>
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            shortcut="1"
                            as={ButtonLink}
                            to={getRoute(RoutePathName.platformAnomalyCodes)}
                            block
                        >
                            Codes anomalies
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </li>
                <li>
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            shortcut="2"
                            as={ButtonLink}
                            to={getRoute(RoutePathName.platformReasonCodes)}
                            block
                        >
                            Codes motifs (Modifications de livraison)
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </li>
            </BasicList>
        </nav>
    </>
);

export default SettingsMenu;
