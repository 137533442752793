import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { FormProps } from 'antd/lib/form/Form';
import { Alert, Badge, Card, Form, Input } from 'antd';
import { Breakpoint } from 'react-socks';

import { getAuthState, login as loginAction } from '../../store/actions/auth';
import { getApiInfosState } from '../../store/actions/api';
import { RoleName } from '../../store/api/apiTypes';

import { defaultErrorMessage } from '../../helpers/i18n';
import { hasRole } from '../../helpers/security';
import { useActions, useIsMobile } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import Seo from '../../components/Seo';
import LoginLayout from '../../components/LoginLayout';
import Logo from '../../components/Logo';
import ButtonGrey from '../../components/ButtonGrey';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useModal } from '@ebay/nice-modal-react';
import SelectPrinterModal from '../settings/SelectPrinterModal';

const Login: FC = () => {
    const isMobile = useIsMobile();
    const login = useActions(loginAction.trigger);
    const { from } = useLocation<{ from?: Location }>().state ?? {};
    const authState = useSelector(getAuthState);
    const apiInfosState = useSelector(getApiInfosState);
    const selectPrinterModal = useModal(SelectPrinterModal);
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        login(values);
    };

    if (authState.isConnected) {
        const homePage = hasRole(authState.user, [RoleName.pfAdmin, RoleName.pfUser])
            ? getRoute(RoutePathName.platformHome)
            : getRoute(RoutePathName.home);
        if (homePage === getRoute(RoutePathName.home)) {
            selectPrinterModal.show({ isConnexionPrinter: true });
        }
        return <Redirect to={from ?? homePage} />;
    }

    let error = authState.error ? defaultErrorMessage : null;

    if (authState.error?.status) {
        switch (authState.error.status) {
            case 401:
                error = 'Non autorisé';
                break;

            case 403:
                error = 'Mot de passe incorrect';
                break;

            case 404:
                error = "Nom d'utilisateur introuvable";
                break;

            default:
                error = defaultErrorMessage;
                break;
        }
    }

    const formItems = (
        <>
            <Form.Item
                name="username"
                label="Identifiant"
                rules={[{ required: true, message: 'Veuillez renseigner votre e-mail' }]}
            >
                <ArrowNavItem>
                    <Input placeholder="Nom d'utilisateur" size={isMobile ? 'middle' : 'large'} />
                </ArrowNavItem>
            </Form.Item>

            <Form.Item
                name="password"
                label="Mot de passe"
                rules={[{ required: true, message: 'Veuillez renseigner votre mot de passe' }]}
            >
                <Input.Password placeholder="Mot de passe" id="password" size={isMobile ? 'middle' : 'large'} />
            </Form.Item>

            {error ? (
                <div className="login-error-message">
                    <Alert type="error" message={error} showIcon />
                </div>
            ) : null}

            <ArrowNavItem>
                <ButtonGrey block htmlType="submit" loading={authState.loading} size={isMobile ? 'middle' : 'large'}>
                    Se connecter
                </ButtonGrey>
            </ArrowNavItem>
        </>
    );

    return (
        <LoginLayout>
            <Seo title="Connexion" />
            <Form className="login-form" onFinish={onFormValidSubmit} layout="vertical" requiredMark={false}>
                {apiInfosState.data?.env === 'preprod' ? (
                    <Badge count="UAT">
                        <Logo />
                    </Badge>
                ) : (
                    <Logo />
                )}
                <Breakpoint md up>
                    <Card>{formItems}</Card>
                </Breakpoint>

                <Breakpoint sm down>
                    {formItems}
                </Breakpoint>
            </Form>
        </LoginLayout>
    );
};

export default Login;
