import { PaginationQuery } from './index';
import { Place, Pallet, MovementBackToWorkshop, MovementBackToWorkshopStatus } from './apiTypes';
import api from './_client';

export interface MovementsBackToWorkshopCreatePayload {
    placeId?: Place['id']; // si picking
    palletId?: Pallet['id']; // si mass/accumulation
    quantity: number;
    status: MovementBackToWorkshopStatus;
}

export const create = async (payload: MovementsBackToWorkshopCreatePayload) =>
    await api.post(`/movementBackToWorkshop`, payload);

export interface MovementsBackToWorkshopListPayload {
    status?: MovementBackToWorkshopStatus;
}

export const list = async (payload: MovementsBackToWorkshopListPayload & PaginationQuery) =>
    await api.get(`/movementBackToWorkshop`, { params: payload });

export interface MovementsBackToWorkshopIdPayload {
    backToWorkshopId: MovementBackToWorkshop['id'];
}
export const details = async ({ backToWorkshopId }: MovementsBackToWorkshopIdPayload) =>
    await api.get(`/movementBackToWorkshop/${backToWorkshopId}`);

export const del = async (payload: MovementsBackToWorkshopIdPayload) =>
    await api.delete(`/movementBackToWorkshop/${payload.backToWorkshopId}`);

export interface MovementsBackToWorkshopUpdatePayload {
    status: MovementBackToWorkshopStatus;
    quantity: number;
    backToWorkshopId: MovementBackToWorkshop['id'];
}
export const update = async ({ backToWorkshopId, ...payload }: MovementsBackToWorkshopUpdatePayload) =>
    await api.put(`/movementBackToWorkshop/${backToWorkshopId}`, payload);
