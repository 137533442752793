import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { message, Pagination, PaginationProps, Spin } from 'antd';

import { Permission, PermissionRight, TransferOrder, TransferOrderStatus } from '../../../store/api/apiTypes';
import {
    detailsTransferOrder,
    getTransferOrdersListState,
    getTransferOrdersUpdateState,
    list,
    listPolling as transferOrderListPolling,
    update,
} from '../../../store/actions/transferOrders';
import { getUser } from '../../../store/actions/auth';

import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import Seo from '../../../components/Seo';
import { useActions, useIsMobile, usePrevious } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import { formatDate, formatNumber, translateTransferOrderStatus } from '../../../helpers/i18n';
import { getOperatorName } from '../../../helpers';
import FixedFooter from '../../../components/FixedFooter';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import { hasPermission } from '../../../helpers/security';
import { isZebra } from '../../../helpers/enterprise-browser';
import TransferOrderCreateModals, { TransferOrderModalsType } from './TransferOrderCreateModals';
import useQueryParams from '../../../hooks/queryParams';

const TransferOrderList: FC = () => {
    const isMobile = useIsMobile();
    const history = useHistory();
    const location = useLocation<{ activeModal: TransferOrderModalsType }>();
    const [queryParams, setQueryParams] = useQueryParams('transferOrderList');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(!!location?.state?.activeModal);
    const [fetchTransferOrders, resetTransferOrderDetails, stopTransferOrderListPolling, updateTransferOrder] =
        useActions([
            list.trigger,
            detailsTransferOrder.reset,
            transferOrderListPolling.actions.stopPolling,
            update.trigger,
        ]);
    const transferOrdersList = useSelector(getTransferOrdersListState);
    const transferOrderUpdateState = useSelector(getTransferOrdersUpdateState);
    const previous = usePrevious({ transferOrderUpdateState });
    const user = useSelector(getUser);
    const onPaginationChange: PaginationProps['onChange'] = (p) => {
        setQueryParams({
            page: p ? p - 1 : 0,
        });
    };
    const onClickItem = (record: TransferOrder) => {
        if (!isZebra) {
            history.push(getRoute(RoutePathName.movementsTransferOrderDetails, { transferOrderId: record.id }));
        } else {
            // this happens when the transfertOrder is in progress and has been unblocked by an admin
            // so we have to reassign it to the current user that clicked on it
            const isInProgressWithNoOperator = record.status === TransferOrderStatus.inProgress && !record.operator;

            if (user?.id === record.operator?.id) {
                history.push(getRoute(RoutePathName.movementsTransferOrderDetails, { transferOrderId: record.id }));
            } else if (
                record.status === TransferOrderStatus.toBeProcessed ||
                isInProgressWithNoOperator ||
                !record.operator
            ) {
                updateTransferOrder({
                    id: record.id,
                    operatorId: user?.id,
                    status: TransferOrderStatus.inProgress,
                });
            }
        }
    };
    const canRead = hasPermission(user, Permission.transferOrder);
    const canEdit = hasPermission(user, Permission.transferOrder, PermissionRight.write);
    const columnWith = isZebra ? '33%' : '25%';

    const columns = (
        [
            {
                key: 'reference',
                title: 'Réf. Colis',
                flex: `1 1 ${columnWith}`,
                render: (record) => record.parcel?.reference,
            },
            {
                key: 'date',
                title: 'Date',
                flex: `1 1 ${columnWith}`,
                render: (record) => formatDate(record.createdAt),
            },
            {
                key: 'time',
                title: 'Heure',
                flex: `1 1 ${columnWith}`,
                render: (record) => formatDate(record.createdAt, { minute: '2-digit', hour: '2-digit' }),
            },
            {
                key: 'status',
                title: 'Statut',
                flex: `1 1 ${columnWith}`,
                render: (record) => translateTransferOrderStatus(record.status),
            },
            canRead &&
                !isZebra && {
                    key: 'operator',
                    title: 'Cariste',
                    flex: `1 1 ${columnWith}`,
                    render: (record) => getOperatorName(record.operator),
                },
        ] as Array<ListColumn<TransferOrder>>
    ).filter(Boolean);

    const triggerCreation = () => {
        resetTransferOrderDetails();
        setIsCreateModalVisible(true);
    };

    useEffect(() => {
        fetchTransferOrders({ page }, { poll: true });
    }, [fetchTransferOrders, page]);

    useEffect(
        () => () => {
            stopTransferOrderListPolling();
        },
        [stopTransferOrderListPolling]
    );

    useEffect(() => {
        if (previous?.transferOrderUpdateState.loading && !transferOrderUpdateState.loading) {
            if (transferOrderUpdateState.error) {
                if (transferOrderUpdateState.error?.data?.operatorAssigned) {
                    message.error('Un cariste est déjà assigné à cet OT, veuillez en choisir un autre.');
                    fetchTransferOrders({ page }, { poll: true });
                } else {
                    message.error("Une erreur est survenue lors de la mise à jour de l'OT");
                }
            } else {
                history.push(
                    getRoute(RoutePathName.movementsTransferOrderDetails, {
                        transferOrderId: transferOrderUpdateState.data?.id ?? '',
                    })
                );
            }
        }
    }, [
        previous?.transferOrderUpdateState.loading,
        transferOrderUpdateState.loading,
        transferOrderUpdateState.error,
        transferOrderUpdateState.data?.id,
        history,
        fetchTransferOrders,
        page,
    ]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Ordres de transfert" />
            <Header title="Ordres de transfert" backRoute={getRoute(RoutePathName.movementsMenu)} enableHomeButton />
            <Spin spinning={transferOrderUpdateState.loading}>
                <List<TransferOrder>
                    columns={columns}
                    data={transferOrdersList.data?.items}
                    rowKey={(record) => String(record.id)}
                    onRowEnterPress={onClickItem}
                    onRowClick={onClickItem}
                    isLoading={transferOrdersList.loading}
                    isRowLocked={(transferOrder) =>
                        isZebra &&
                        (transferOrder.status === TransferOrderStatus.toBeProcessed ||
                            transferOrder.status === TransferOrderStatus.inProgress) &&
                        !!transferOrder.operator &&
                        user?.id !== transferOrder.operator?.id
                    }
                    title={
                        transferOrdersList.data
                            ? `${formatNumber(transferOrdersList.data?.totalCount)} ordre${
                                  transferOrdersList.data?.totalCount > 1 ? 's' : ''
                              } à traiter`
                            : 'Ordres à traiter'
                    }
                    style={isMobile ? { marginTop: 24 } : undefined}
                    columnWidthGrow={false}
                />
                {(transferOrdersList.data?.pageCount ?? 0) > 1 && (
                    <div className="flex-center">
                        <Pagination
                            style={{ marginBottom: 32, marginTop: 16 }}
                            total={transferOrdersList.data?.totalCount}
                            pageSize={transferOrdersList.data?.pageSize}
                            current={page + 1}
                            onChange={onPaginationChange}
                            showSizeChanger={false}
                            hideOnSinglePage
                        />
                    </div>
                )}
            </Spin>
            {canEdit && isZebra && (
                <FixedFooter>
                    <ArrowNavItem>
                        <ButtonWithShortcut shortcut="f1" onClick={triggerCreation} block>
                            Déclencher un OT
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <TransferOrderCreateModals
                        visible={isCreateModalVisible}
                        onCancel={setIsCreateModalVisible.bind(null, false)}
                    />
                </FixedFooter>
            )}
        </FixedFooter.Wrapper>
    );
};

export default TransferOrderList;
