import React, { useState, forwardRef, useEffect, ForwardRefRenderFunction } from 'react';
import { Select, SelectProps } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { useSelector } from 'react-redux';

import { PlaceStore, PlaceStoreType } from '../store/api/apiTypes';
import { getPlaceStoresListState, list as listPlaceStoresAction } from '../store/actions/placeStores';

import { useActions, usePrevious } from '../hooks';
import ArrowNavItem from './ArrowNavItem';

interface PlaceStoreSelectProps extends SelectProps<any> {
    multi?: boolean;
    size?: SizeType;
    placeholder?: string;
    onChange?: (value: any) => void;
    withArrowNav?: boolean;
    arrowNavScope?: string;
    placeStoreType?: PlaceStoreType;
}

const PlaceStoreSelect: ForwardRefRenderFunction<typeof Select, PlaceStoreSelectProps> = (props, ref: any) => {
    const listPlaceStores = useActions(listPlaceStoresAction.trigger);
    const placeStoresListState = useSelector(getPlaceStoresListState);
    const previous = usePrevious({ placeStoresListState });
    const { onChange, multi, size, placeholder, withArrowNav, arrowNavScope, placeStoreType, ...selectProps } = props;
    const [value, setValue] = useState<PlaceStore['id'] | undefined>();

    useEffect(() => {
        setValue(undefined);
        listPlaceStores(placeStoreType ? { type: placeStoreType } : undefined);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (previous?.placeStoresListState.loading && !placeStoresListState.loading) {
            if (!value) {
                setValue(placeStoresListState.data?.items?.[0]?.id);

                if (onChange) {
                    onChange(placeStoresListState.data?.items?.[0]);
                }
            }
        }
    }, [
        onChange,
        previous?.placeStoresListState.loading,
        placeStoresListState.data?.items,
        placeStoresListState.loading,
        value,
    ]);

    const onChangeSelect = (newValue: any) => {
        if (newValue) {
            setValue(newValue);
        } else {
            setValue(undefined);
        }

        if (onChange) {
            onChange(placeStoresListState.data?.items.find((p) => p.id === parseInt(newValue, 10)));
        }
    };

    const select = (
        <Select
            mode={multi ? 'multiple' : undefined}
            size={size}
            ref={ref}
            placeholder={placeholder ?? 'Sélectionner un site'}
            loading={placeStoresListState.loading}
            filterOption={false}
            onChange={onChangeSelect}
            style={{ width: '100%' }}
            allowClear
            showArrow
            {...selectProps}
            value={value}
        >
            {placeStoresListState.data?.items?.map((option) => (
                <Select.Option value={option.id} key={option.id}>
                    {option.number} - {option.name}
                </Select.Option>
            ))}
        </Select>
    );

    return withArrowNav ? <ArrowNavItem scope={arrowNavScope}>{select}</ArrowNavItem> : select;
};

export default forwardRef(PlaceStoreSelect);
