import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 36 36">
        <path
            fill="currentColor"
            fillRule="nonzero"
            d="M17.966 4.281a.874.874 0 0 0-.66.29l-.112.156L2.37 30.383a.892.892 0 0 0 .606 1.32l.166.016h29.648c.628 0 1.04-.622.84-1.184l-.07-.152L18.736 4.727a.876.876 0 0 0-.77-.446zm.216 3.898 12.108 20.96-.216.374H5.856l-.216-.374 12.108-20.96h.434zm-.216 15.772a1.46 1.46 0 1 0 0 2.92 1.46 1.46 0 0 0 0-2.92zm.854-9.408h-1.71l-.024.012-.012.024v6.556l.012.026 .024.01h1.71l.026-.01.01-.026v-6.556l-.01-.024-.026-.012z"
        />
    </svg>
);

const IconIncidentMenu: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-incident-menu${props.className ? ` ${props.className}` : ''}`} />
);

export default IconIncidentMenu;
