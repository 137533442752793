import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { message } from 'antd';

import { create, del, getPalletCreateState } from '../../store/actions/pallets';
import { ControlPreparationStatus, CustomerOrderStatus, Pallet, PalletSize } from '../../store/api/apiTypes';
import { getCustomerOrderStateById } from '../../store/actions/customerOrders';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import ControlVracScanModal from './ControlVracScanModal';
import ControlVracPrintAgainModal from './ControlVracPrintAgainModal';
import { getControlOrderControlPackagesState } from '../../store/actions/preparationControl';
import {
    getPreparationControlListByCustomerOrderIdState,
    listControlByCustomerOrderId,
} from '../../store/actions/preparations';

const shortcutScope = 'ControlVracModal';

interface ControlVracScanModalProps extends CustomModalProps {
    onFinish: () => void;
    onPrint: () => void;
}

const ControlVracModal: FC<ControlVracScanModalProps> = ({ visible, onCancel, onFinish, onPrint }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const { customerOrderId } = useParams<{ customerOrderId: string }>();
    const [palletToControl, setPalletToControl] = useState<Pallet>();
    const [isVracScanModalVisible, setIsVracScanModalVisible] = useState(false);
    const [isVracPrintModalVisible, setIsVracPrintModalVisible] = useState(false);
    const [createPallet, deletePallet, listPreparationControls] = useActions([
        create.trigger,
        del.trigger,
        listControlByCustomerOrderId.trigger,
    ]);
    const customerOrderState = useSelector(getCustomerOrderStateById(parseInt(customerOrderId, 10)));
    const createPalletState = useSelector(getPalletCreateState);
    const controlPackagesState = useSelector(getControlOrderControlPackagesState);
    const preparationControlListState = useSelector(
        getPreparationControlListByCustomerOrderIdState(customerOrderId ? parseInt(customerOrderId, 10) : -1)
    );
    const previous = usePrevious({
        createPalletState,
        isVracScanModalVisible,
        controlPackagesState,
        preparationControlListState,
    });
    const onCreatePallet = () => {
        createPallet({
            customerOrderId,
            size: PalletSize.small,
            vrac: true,
        });
    };
    const onVracScanModalCancel = useCallback(() => {
        setIsVracScanModalVisible(false);
        listPreparationControls({
            customerOrderId,
            controlStatus: [
                ControlPreparationStatus.toBeProcessed,
                ControlPreparationStatus.inProgress,
                ControlPreparationStatus.completed,
                ControlPreparationStatus.toRegularize,
            ],
            pageSize: 1337,
        });
    }, [customerOrderId, listPreparationControls]);
    const onCloseVracPrintAgainModal = useCallback(() => {
        setIsVracPrintModalVisible(false);
    }, []);
    const onPrintAgain = () => {
        onClose();
        window.setTimeout(() => {
            setIsVracPrintModalVisible(true);
        }, 300);
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const actions = [
        customerOrderState.data?.status &&
            ![CustomerOrderStatus.toBeLoaded, CustomerOrderStatus.loaded, CustomerOrderStatus.toRegularize].includes(
                customerOrderState.data?.status
            ) && {
                onClick: onCreatePallet,
                loading: createPalletState.loading,
                label: 'Créer vrac',
            },
        {
            onClick: onPrintAgain,
            label: 'Ré-imprimer étiquette',
        },
    ].filter(Boolean) as Array<{ onClick: () => void; label: string; loading?: boolean }>;

    useEffect(() => {
        if (visible && previous?.createPalletState.loading && !createPalletState.loading) {
            if (createPalletState.error) {
                message.error('Une erreur est survenue lors de la création de la palette');
            } else {
                setPalletToControl(createPalletState.data);
                onClose();
                window.setTimeout(() => {
                    setIsVracScanModalVisible(true);
                }, 300);
            }
        }
    }, [
        previous?.createPalletState.loading,
        createPalletState.loading,
        createPalletState.error,
        createPalletState.data,
        onClose,
        visible,
    ]);

    // When scan modal closes, delete pallet if it is empty
    useEffect(() => {
        if (previous?.isVracScanModalVisible && !isVracScanModalVisible && palletToControl?.quantity === 0) {
            deletePallet({ palletId: palletToControl?.id });
        }
    }, [previous?.isVracScanModalVisible, deletePallet, isVracScanModalVisible, palletToControl]);

    // update palletToControl when controlPackages request succeeds (needed to have the latest state
    // of the pallet in order to delete it if the user cancels and there are no parcels in it ^^')
    useEffect(() => {
        if (previous?.controlPackagesState.loading && !controlPackagesState.loading) {
            if (controlPackagesState.data) {
                setPalletToControl(controlPackagesState.data);
            }
        }
    }, [controlPackagesState.data, controlPackagesState.loading, previous?.controlPackagesState.loading]);

    return (
        <>
            <CustomModal
                footer={
                    <>
                        {actions.map(({ onClick, label, loading }, index) => (
                            <ArrowNavItem scope={shortcutScope} key={index}>
                                <ButtonWithShortcut
                                    shortcut={`${index + 1}`}
                                    type="primary"
                                    shortcutScope={shortcutScope}
                                    loading={loading}
                                    onClick={onClick}
                                >
                                    {label}
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        ))}
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                }
                visible={visible}
                onCancel={onCancel}
                title="Colis vrac"
                width={368}
                keyboard={false}
                transitionName=""
                maskTransitionName=""
                altTitle
            />
            <ControlVracScanModal
                visible={isVracScanModalVisible}
                onCancel={onVracScanModalCancel}
                onFinish={onFinish}
                palletId={palletToControl?.id}
            />
            <ControlVracPrintAgainModal
                visible={isVracPrintModalVisible}
                onCancel={onCloseVracPrintAgainModal}
                onStartControl={onCreatePallet}
                onPrint={onPrint}
            />
        </>
    );
};

export default ControlVracModal;
