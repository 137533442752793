import { EzeeAsyncAction } from './../helpers/EzeeAsyncAction';
import { requestInitialState, requestReducer } from './../helpers/index';
import { ListResponse } from './../api/index';
import { MainReducerState, RequestState } from '../reducers';
import { Repair } from './../api/apiTypes';
import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';

import * as api from './../api/repairs';

export interface RepairsState {
    list: RequestState<ListResponse<Repair>>;
    create: RequestState<Repair>;
    update: RequestState<Repair>;
    del: RequestState;
    details: RequestState<Repair>;
    transferPackages: RequestState<Repair>;
    storePackages: RequestState<Repair>;
}

const initialState: RepairsState = {
    list: { ...requestInitialState },
    create: { ...requestInitialState },
    update: { ...requestInitialState },
    del: { ...requestInitialState },
    details: { ...requestInitialState },
    transferPackages: { ...requestInitialState },
    storePackages: { ...requestInitialState },
};

export const listRepairs = new EzeeAsyncAction<RepairsState['list'], api.RepairsListPayload, ListResponse<Repair>>(
    'repairs/list',
    initialState.list,
    {
        trigger: (state, _, meta) => ({
            ...state,
            loading: true,
            data: meta?.loadMore ? state.data : undefined,
        }),
        success: (state, payload, meta) => ({
            data: {
                ...payload,
                items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.list,
        }),
    }
);

export const createRepair = new EzeeAsyncAction<RepairsState['create'], api.RepairCreatePayload, Repair>(
    'repairs/create',
    initialState.create,
    requestReducer<RepairsState['create'], Repair>(initialState.create)
);

export const updateRepair = new EzeeAsyncAction<RepairsState['update'], api.RepairUpdatePayload, Repair>(
    'repairs/update',
    initialState.update,
    requestReducer<RepairsState['update'], Repair>(initialState.update)
);

export const delRepair = new EzeeAsyncAction<RepairsState['del'], api.RepairIdPayload>(
    'repairs/del',
    initialState.del,
    requestReducer<RepairsState['del']>(initialState.del)
);

export const detailsRepair = new EzeeAsyncAction<RepairsState['details'], api.RepairIdPayload, Repair>(
    'repairs/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            data: undefined, // reset when fetching
            loading: true,
        }),
        success: (state, payload) => ({
            data: payload,
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);
export const transferRepairPackages = new EzeeAsyncAction<
    RepairsState['transferPackages'],
    api.TransferRepairPackagesPayload,
    Repair
>(
    'repairs/transferPackages',
    initialState.transferPackages,
    requestReducer<RepairsState['transferPackages'], Repair>(initialState.transferPackages)
);

export const storePackages = new EzeeAsyncAction<RepairsState['storePackages'], api.StoreRepairPackagesPayload, Repair>(
    'repairs/storePackages',
    initialState.storePackages,
    requestReducer<RepairsState['storePackages'], Repair>(initialState.storePackages)
);

// Reducer
export const repairsReducer = combineReducers<RepairsState>({
    list: listRepairs.reducer,
    create: createRepair.reducer,
    update: updateRepair.reducer,
    del: delRepair.reducer,
    details: detailsRepair.reducer,
    transferPackages: transferRepairPackages.reducer,
    storePackages: storePackages.reducer,
});

// Saga
export function* repairsSaga() {
    yield takeLatest(listRepairs.type.trigger, simpleAsyncSaga(api.list, listRepairs));
    yield takeLatest(createRepair.type.trigger, simpleAsyncSaga(api.create, createRepair));
    yield takeLatest(updateRepair.type.trigger, simpleAsyncSaga(api.update, updateRepair));
    yield takeLatest(delRepair.type.trigger, simpleAsyncSaga(api.del, delRepair));
    yield takeLatest(detailsRepair.type.trigger, simpleAsyncSaga(api.details, detailsRepair));
    yield takeLatest(
        transferRepairPackages.type.trigger,
        simpleAsyncSaga(api.TransferRepairPackages, transferRepairPackages)
    );
    yield takeLatest(storePackages.type.trigger, simpleAsyncSaga(api.StoreRepairPackages, storePackages));
}

// Store helpers
export const getRepairsListState = (state: MainReducerState) => state.repairs.list;
export const getRepairsCreateState = (state: MainReducerState) => state.repairs.create;
export const getRepairsUpdateState = (state: MainReducerState) => state.repairs.update;
export const getRepairsDeleteState = (state: MainReducerState) => state.repairs.del;
export const getRepairsDetails = (state: MainReducerState) => state.repairs.details;
export const getRepairStorePackagesState = (state: MainReducerState) => state.repairs.storePackages;
export const getRepairTransferPackagesState = (state: MainReducerState) => state.repairs.transferPackages;
