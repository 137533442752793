import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { Pallet, PlatformAnomalyCode, PlatformReception } from '../api/apiTypes';
import { ListResponse } from '../api';

// Controlers
import {
    PlatformReceptionListPayload,
    list as listApiCall,
    PlatformReceptionDetailsPayload,
    details as detailsApiCall,
    PlatformReceptionPalletListPayload,
    palletList as palletListApiCall,
    PlatformReceptionCheckPalletPayload,
    checkPallet as checkPalletApiCall,
    PlatformReceptionSendPalletsPayload,
    sendPallets as sendPalletsApiCall,
} from '../api/platformReceptions';
import { EzeeAction } from '../helpers/EzeeAction';

export interface PlatformReceptionPalletRef {
    reference: string;
    anomalyCode?: PlatformAnomalyCode['id'];
}
export interface PlatformReceptionPalletBarcode {
    barcode: string;
    anomalyCode?: PlatformAnomalyCode['id'];
}

// States
export interface PlatformReceptionsState {
    list: RequestState<ListResponse<PlatformReception>>;
    details: RequestState<{
        [id: number]: PlatformReception;
    }>;
    palletList: RequestState<ListResponse<Pallet>>;
    checkPallet: RequestState<Pallet>;
    sendPallets: RequestState<PlatformReception>;
    reception: {
        receive: {
            palletRefs: PlatformReceptionPalletRef[];
            palletBarcodes: PlatformReceptionPalletBarcode[];
            isWaitingForAnomaly: boolean;
        };
    };
}

const initialState: PlatformReceptionsState = {
    list: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
    palletList: { ...requestInitialState },
    checkPallet: { ...requestInitialState },
    sendPallets: { ...requestInitialState },
    reception: {
        receive: {
            palletRefs: [],
            palletBarcodes: [],
            isWaitingForAnomaly: false,
        },
    },
};

export const list = new EzeeAsyncAction<
    PlatformReceptionsState['list'],
    PlatformReceptionListPayload,
    ListResponse<PlatformReception>
>(
    'platformReceptions/list',
    initialState.list,
    requestReducer<PlatformReceptionsState['list'], ListResponse<PlatformReception>>(initialState.list)
);

export const details = new EzeeAsyncAction<
    PlatformReceptionsState['details'],
    PlatformReceptionDetailsPayload,
    PlatformReception
>('platformReceptions/details', initialState.details, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...state.data,
            [payload.id]: payload,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.details,
    }),
});

export const palletList = new EzeeAsyncAction<
    PlatformReceptionsState['palletList'],
    PlatformReceptionPalletListPayload,
    ListResponse<Pallet>
>('platformReceptions/palletList', initialState.palletList, {
    trigger: (state, _, meta) => ({
        ...state,
        loading: true,
        data: meta?.loadMore ? state.data : undefined,
    }),
    success: (state, payload, meta) => ({
        data: {
            ...payload,
            items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.palletList,
    }),
});

export const checkPallet = new EzeeAsyncAction<
    PlatformReceptionsState['checkPallet'],
    PlatformReceptionCheckPalletPayload,
    Pallet
>(
    'platformReceptions/checkPallet',
    initialState.checkPallet,
    requestReducer<PlatformReceptionsState['checkPallet'], Pallet>(initialState.checkPallet)
);

export const sendPallets = new EzeeAsyncAction<
    PlatformReceptionsState['sendPallets'],
    PlatformReceptionSendPalletsPayload,
    PlatformReception
>(
    'platformReceptions/sendPallets',
    initialState.sendPallets,
    requestReducer<PlatformReceptionsState['sendPallets'], PlatformReception>(initialState.sendPallets)
);

export const reception = new EzeeAction<PlatformReceptionsState['reception']>(
    'platformReceptions/reception',
    initialState.reception,
    {
        setReceiveData: (state, payload) => ({
            ...state,
            receive: {
                ...state.receive,
                ...payload,
            },
        }),
        resetReceive: (state) => ({
            ...state,
            receive: {
                ...initialState.reception.receive,
            },
        }),
    }
);

// Reducer
export const platformReceptionsReducer = combineReducers<PlatformReceptionsState>({
    list: list.reducer,
    details: details.reducer,
    palletList: palletList.reducer,
    checkPallet: checkPallet.reducer,
    sendPallets: sendPallets.reducer,
    reception: reception.reducer,
});

// Saga
export function* platformReceptionsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(palletList.type.trigger, simpleAsyncSaga(palletListApiCall, palletList));
    yield takeLatest(checkPallet.type.trigger, simpleAsyncSaga(checkPalletApiCall, checkPallet));
    yield takeLatest(sendPallets.type.trigger, simpleAsyncSaga(sendPalletsApiCall, sendPallets));
}

// Store helpers
export const getPlatformReceptionsListState = (state: MainReducerState) => state.platformReceptions.list;
export const getPlatformReceptionDetailsStateById = (id: PlatformReception['id']) => (state: MainReducerState) => ({
    loading: state.platformReceptions.details.loading,
    error: state.platformReceptions.details.error,
    data: state.platformReceptions.details.data?.[id],
});
export const getPlatformReceptionPalletListState = (state: MainReducerState) => state.platformReceptions.palletList;
export const getPlatformReceptionCheckPalletState = (state: MainReducerState) => state.platformReceptions.checkPallet;
export const getPlatformReceptionSendPalletsState = (state: MainReducerState) => state.platformReceptions.sendPallets;
export const getPlatformReceptionReceptionState = (state: MainReducerState) => state.platformReceptions.reception;
