import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Descriptions, Divider, Spin } from 'antd';

import {
    getPalletDetailsStateById,
    details as detailsPalletAction,
    getPalletPackageListState,
    packageList,
} from '../../store/actions/pallets';
import { Package } from '../../store/api/apiTypes';

import { getRoute, RoutePathName } from '../../routes';
import { useActions, useIsMobile } from '../../hooks';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import FixedFooter from '../../components/FixedFooter';
import List, { ListColumn } from '../../components/list/List';
import QuantityCard from '../../components/QuantityCard';
import ButtonGrey from '../../components/ButtonGrey';
import ControlPalletActionsModal from './ControlPalletActionsModal';
import { formatNumber } from '../../helpers/i18n';

const ControlPalletDetails: FC = () => {
    const isMobile = useIsMobile();
    const { loadingOrderId, customerOrderId, palletId } =
        useParams<{ loadingOrderId: string; customerOrderId: string; palletId: string }>();
    const [isActionModalVisible, setIsActionModalVisible] = useState(false);
    const [fetchPallet, fetchPackages] = useActions([detailsPalletAction.trigger, packageList.trigger]);
    const palletState = useSelector(getPalletDetailsStateById(parseInt(palletId, 10)));
    const palletPackageListState = useSelector(getPalletPackageListState);
    const columns: Array<ListColumn<Package>> = [
        {
            dataIndex: 'parcel.reference',
            title: 'Référence colis',
            flex: '1 1 50%',
        },
        {
            dataIndex: 'cont',
            title: 'CONT',
            flex: '1 1 50%',
        },
    ];
    const onRefresh = useCallback(() => {
        fetchPackages({ palletId });
        fetchPallet({ palletId });
    }, [palletId, fetchPackages, fetchPallet]);

    useEffect(() => {
        if (palletId) {
            fetchPallet({ palletId });
            fetchPackages({ palletId });
        }
    }, [fetchPallet, fetchPackages, palletId]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Détail palette" />
            <Header
                title="Détail palette"
                backRoute={getRoute(RoutePathName.preparationControlPalletList, {
                    loadingOrderId,
                    customerOrderId,
                })}
                enableHomeButton
            />
            <PageHeader>
                <Spin spinning={palletState.loading}>
                    <Descriptions column={5} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="Référence palette" span={2}>
                            {palletState.data?.reference}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>
            <QuantityCard value={formatNumber(palletState.data?.quantity)} label="Colis ajoutés" />
            <Divider />
            <List<Package>
                columns={columns}
                data={palletPackageListState.data?.items}
                rowKey={(record) => `${record.id}`}
                isLoading={palletPackageListState.loading}
                loadingRowsCount={6}
                title="Détail des colis"
            />
            <ControlPalletActionsModal
                visible={isActionModalVisible}
                onCancel={setIsActionModalVisible.bind(null, false)}
                setIsVisible={setIsActionModalVisible}
                onRefresh={onRefresh}
            />
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        shortcut="enter"
                        onClick={setIsActionModalVisible.bind(null, true)}
                        size={isMobile ? 'middle' : 'large'}
                        as={ButtonGrey}
                        block
                    >
                        Actions
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </FixedFooter.Wrapper>
    );
};

export default ControlPalletDetails;
