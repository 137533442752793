import Seo from '../../components/Seo';
import Header from '../../components/Header';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Incident, Machine } from '../../store/api/apiTypes';
import {
    getIncidentsDelState,
    getIncidentsListState,
    list as incidentsListAction,
} from '../../store/actions/incidents';
import { getMachinesDelState, getMachinesListState, list as machinesListAction } from '../../store/actions/machines';
import { useActions } from '../../hooks';
import { useSelector } from 'react-redux';
import FixedFooter from '../../components/FixedFooter';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import List, { ListColumn } from '../../components/list/List';
import { useModal } from '@ebay/nice-modal-react';
import AddMachineIncidentModal from './AddMachineIncidentModal';
import BasicList from '../../components/BasicList';
import ButtonRed from '../../components/ButtonRed';
import ConfirmRemoveMachineIncidentModal from './ConfirmRemoveMachineIncidentModal';
import { getRawRoute, RoutePathName } from '../../routes';

const IncidentsMachinesList = () => {
    const history = useHistory();
    const [fetchIncidents, fetchMachines] = useActions([incidentsListAction.trigger, machinesListAction.trigger]);
    const [selectedIncident, setSelectedIncident] = useState<Incident>();
    const [selectedMachine, setSelectedMachine] = useState<Machine>();
    const addMachineIncidentModal = useModal(AddMachineIncidentModal);
    const confirmRemoveMachineIncidentModal = useModal(ConfirmRemoveMachineIncidentModal);
    const incidentsListState = useSelector(getIncidentsListState);
    const machinesListState = useSelector(getMachinesListState);
    const incidentDelState = useSelector(getIncidentsDelState);
    const machineDelState = useSelector(getMachinesDelState);
    const isMachinesListRoute = !!useRouteMatch(getRawRoute(RoutePathName.machinesList));
    const incidentsColumns: Array<ListColumn<Incident>> = [
        {
            key: 'label',
            title: 'Nom',
            flex: '',
            render: (record) => record.label,
        },
    ];
    const machinesColumns: Array<ListColumn<Machine>> = [
        {
            key: 'label',
            title: 'Nom',
            flex: '',
            render: (record) => record.label,
        },
    ];

    useEffect(() => {
        if (isMachinesListRoute) {
            fetchMachines();
        } else {
            fetchIncidents({
                exclude: ['RAS'],
            });
        }
    }, [fetchIncidents, fetchMachines, isMachinesListRoute]);

    useEffect(() => {
        if (isMachinesListRoute && machineDelState.success) {
            setSelectedMachine(undefined);
        } else if (incidentDelState.success) {
            setSelectedIncident(undefined);
        }
    }, [incidentDelState.success, isMachinesListRoute, machineDelState.success]);

    const onClickAdd = () => {
        addMachineIncidentModal.show();
    };

    const onClickDelete = () => {
        if (selectedMachine) {
            confirmRemoveMachineIncidentModal.show({ machineId: selectedMachine.id });
        } else if (selectedIncident) {
            confirmRemoveMachineIncidentModal.show({ incidentId: selectedIncident.id });
        }
    };
    return (
        <>
            <FixedFooter.Wrapper>
                <Seo title={isMachinesListRoute ? 'Engins' : 'Incidents'} />
                <Header
                    title={isMachinesListRoute ? 'Engins' : 'Incidents'}
                    onClickBack={() => {
                        history.goBack();
                    }}
                />
                {isMachinesListRoute ? (
                    <List<Machine>
                        title={`${machinesListState.data?.items.length ?? 0} engins répertoriés`}
                        columns={machinesColumns}
                        data={machinesListState.data?.items}
                        onRowClick={setSelectedMachine}
                        onRowEnterPress={setSelectedMachine}
                        isRowSelected={(record) => record.id === selectedMachine?.id}
                        rowKey={(record) => `${record.id}`}
                        isLoading={machinesListState.loading}
                        loadingRowsCount={11}
                    />
                ) : (
                    <List<Incident>
                        title={`${incidentsListState.data?.items.length ?? 0} incidents répertoriés`}
                        columns={incidentsColumns}
                        data={incidentsListState.data?.items}
                        onRowClick={setSelectedIncident}
                        onRowEnterPress={setSelectedIncident}
                        isRowSelected={(record) => record.id === selectedIncident?.id}
                        rowKey={(record) => `${record.id}`}
                        isLoading={incidentsListState.loading}
                        loadingRowsCount={11}
                    />
                )}
                <FixedFooter>
                    <BasicList inline inlineStretch>
                        <li>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    shortcut="f1"
                                    onClick={onClickAdd}
                                    size={'large'}
                                    type="primary"
                                    ghost
                                    block
                                >
                                    Ajouter un {isMachinesListRoute ? 'engin' : 'incident'}
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </li>
                        {(selectedIncident ?? selectedMachine) && (
                            <li>
                                <ArrowNavItem>
                                    <ButtonWithShortcut
                                        shortcut="f2"
                                        onClick={onClickDelete}
                                        size={'large'}
                                        as={ButtonRed}
                                        block
                                    >
                                        Supprimer l&lsquo;{isMachinesListRoute ? 'engin' : 'incident'}
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </li>
                        )}
                    </BasicList>
                </FixedFooter>
            </FixedFooter.Wrapper>
        </>
    );
};

export default IncidentsMachinesList;
