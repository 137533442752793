import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 24">
        <path
            fill="currentColor"
            fillRule="nonzero"
            d="M31.4.426c-.618-.53-1.557-.565-2.232-.107l-.131.098-.107.094L9.712 19.596l-6.518-7.39-.099-.106c-.668-.67-1.762-.7-2.492-.062a1.765 1.765 0 00-.323 2.283l.088.126.093.113 7.814 8.804.1.104c.272.262.611.442.924.505l.132.02.138.006.065.001.153-.004c.34-.02.638-.126.886-.31l.12-.1 20.671-20.53.097-.1c.549-.614.582-1.551.105-2.23L31.57.6l-.075-.087L31.4.426z"
        />
    </svg>
);

const IconSingleCheck: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-single-check${props.className ? ` ${props.className}` : ''}`} />
);

export default IconSingleCheck;
