import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
        <path
            d="M16 0a3 3 0 013 3v10h10a3 3 0 010 6H19v10a3 3 0 01-6 0V19H3a3 3 0 010-6h10V3a3 3 0 013-3z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

const IconPlus: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-plus${props.className ? ` ${props.className}` : ''}`} />
);

export default IconPlus;
