import { Form } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Organization, OrganizationType } from '../store/api/apiTypes';

import { useArrowNavScope, useShortcutScope } from '../hooks';
import { getRoute, RoutePathName } from '../routes';
import ArrowNavItem from './ArrowNavItem';
import ButtonWithShortcut from './ButtonWithShortcut';
import CustomModal, { CustomModalProps } from './CustomModal';
import OrganizationSelect from './OrganizationSelect';

const shortcutScope = 'SearchPlatformLotVisualizationModal';

interface SearchPlatformSlugModalProps extends CustomModalProps {
    redirectLink: RoutePathName;
    title: string;
}

const SearchPlatformSlugModal: FC<SearchPlatformSlugModalProps> = ({ visible, onCancel, title, redirectLink }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const history = useHistory();
    const [organizationSlug, setOrganizationSlug] = useState<Organization['slug']>();
    const onSubmit = () => {
        onClose();
        history.push({
            pathname: getRoute(redirectLink),
            search: organizationSlug ? `plateformCode=${organizationSlug}` : undefined,
        });
    };
    const onChangeOrganization = (organizationSlug: Organization['slug']) => {
        setOrganizationSlug(organizationSlug);
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    useEffect(() => {
        if (!visible) {
            setOrganizationSlug(undefined);
        }
    }, [visible]);

    return (
        <CustomModal
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onSubmit}
                            disabled={!organizationSlug}
                        >
                            Valider
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClose}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            visible={visible}
            onCancel={onCancel}
            title={title}
            width={368}
            keyboard={false}
            altTitle
        >
            <Form.Item label="Plateforme" style={{ margin: 0 }} labelCol={{ span: 24 }}>
                <OrganizationSelect
                    onChange={onChangeOrganization}
                    payload={{ type: OrganizationType.platform }}
                    valueProp="slug"
                    withArrowNav
                />
            </Form.Item>
        </CustomModal>
    );
};

export default SearchPlatformSlugModal;
