import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { PlaceAlveole } from '../api/apiTypes';
import { ListResponse } from '../api';

// Controlers
import {
    PlaceAlveoleListPayload,
    list as listApiCall,
    PlaceAlveoleCreatePayload,
    create as createApiCall,
    PlaceAlveoleUpdatePayload,
    update as updateApiCall,
    PlaceAlveoleDeletePayload,
    del as delApiCall,
} from '../api/placeAlveoles';

// States
export interface PlaceAlveolesState {
    list: RequestState<ListResponse<PlaceAlveole>>;
    create: RequestState<PlaceAlveole>;
    update: RequestState<PlaceAlveole>;
    del: RequestState;
}

const initialState: PlaceAlveolesState = {
    list: { ...requestInitialState },
    create: { ...requestInitialState },
    update: { ...requestInitialState },
    del: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<
    PlaceAlveolesState['list'],
    PlaceAlveoleListPayload,
    ListResponse<PlaceAlveole>
>(
    'placeAlveoles/list',
    initialState.list,
    requestReducer<PlaceAlveolesState['list'], ListResponse<PlaceAlveole>>(initialState.list)
);

export const create = new EzeeAsyncAction<PlaceAlveolesState['create'], PlaceAlveoleCreatePayload, PlaceAlveole>(
    'placeAlveoles/create',
    initialState.create,
    requestReducer<PlaceAlveolesState['create'], PlaceAlveole>(initialState.create)
);

export const update = new EzeeAsyncAction<PlaceAlveolesState['update'], PlaceAlveoleUpdatePayload, PlaceAlveole>(
    'placeAlveoles/update',
    initialState.update,
    requestReducer<PlaceAlveolesState['update'], PlaceAlveole>(initialState.update)
);

export const del = new EzeeAsyncAction<PlaceAlveolesState['del'], PlaceAlveoleDeletePayload>(
    'placeAlveoles/del',
    initialState.del,
    requestReducer<PlaceAlveolesState['del'], PlaceAlveole>(initialState.del)
);

// Reducer
export const placeAlveolesReducer = combineReducers<PlaceAlveolesState>({
    list: list.reducer,
    create: create.reducer,
    update: update.reducer,
    del: del.reducer,
});

// Saga
export function* placeAlveolesSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
}

// Store helpers
export const getPlaceAlveolesListState = (state: MainReducerState) => state.placeAlveoles.list;
export const getPlaceAlveoleCreateState = (state: MainReducerState) => state.placeAlveoles.create;
export const getPlaceAlveoleUpdateState = (state: MainReducerState) => state.placeAlveoles.update;
export const getPlaceAlveoleDeleteState = (state: MainReducerState) => state.placeAlveoles.del;
