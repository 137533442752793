import { PlaceZoneType } from './apiTypes';
import api from './_client';

export interface PalletPlaceDetailsPayload {
    palletPlaceId: number;
}
export const details = async ({ palletPlaceId, ...payload }: PalletPlaceDetailsPayload) =>
    await api.get(`/palletsPlaces/${palletPlaceId}`, { params: payload });
export interface PalletPlaceListPayload {
    parcelId: number;
    siteId: number;
    zoneType: PlaceZoneType;
}
export const list = async (payload: PalletPlaceListPayload) => await api.get('/palletsPlaces', { params: payload });
