import { Card, CardProps } from 'antd';
import React, { FC } from 'react';

import '../assets/styles/PageHeader.less';
import { classNames } from '../helpers';

const PageHeader: FC<CardProps> = ({ children, ...props }) => (
    <Card bordered={false} {...props} className={classNames('page-header', props.className)}>
        {children}
    </Card>
);

export default PageHeader;
