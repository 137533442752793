import { SearchPaginationQuery } from '.';
import { Site } from './apiTypes';
import api from './_client';

export type SiteListPayload = SearchPaginationQuery;
export const list = async (payload: SiteListPayload = {}) => await api.get('/sites', { params: payload });

export type SiteCreatePayload = Omit<Site, 'id'>;
export const create = async (payload: SiteCreatePayload) => await api.post('/sites', payload);

export type SiteUpdatePayload = Site & {
    siteId: Site['id'];
};
export const update = async ({ siteId, ...payload }: SiteUpdatePayload) => await api.put(`/sites/${siteId}`, payload);

export interface SiteDeletePayload {
    siteId: Site['id'];
}
export const del = async ({ siteId }: SiteDeletePayload) => await api.delete(`/sites/${siteId}`);
