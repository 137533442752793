import React, { FC } from 'react';
import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';

import '../assets/styles/TitleBlack.less';

const TitleBlack: FC<TitleProps> = ({ children, level = 2, ...props }) => (
    <Typography.Title level={level} className="black-title" {...props}>
        {children}
    </Typography.Title>
);

export default TitleBlack;
