import React, { FC, useEffect } from 'react';
import FixedFooter from '../../components/FixedFooter';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import { getRoute, RoutePathName } from '../../routes';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { details as customerOrderDetails, getCustomerOrderStateById } from '../../store/actions/customerOrders';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ControlSearchPalletModal from './ControlSearchPalletModal';
import { show } from '@ebay/nice-modal-react';
import { useActions } from '../../hooks';
import List, { ListColumn } from '../../components/list/List';
import { Pallet } from '../../store/api/apiTypes';

const ControlPalletList: FC = () => {
    const { loadingOrderId, customerOrderId: customerOrderIdParam } =
        useParams<{ loadingOrderId: string; customerOrderId: string }>();
    const customerOrderId = parseInt(customerOrderIdParam, 10);
    const fetchCustomerOrder = useActions(customerOrderDetails.trigger);
    const customerOrderState = useSelector(getCustomerOrderStateById(customerOrderId));
    const history = useHistory();

    const columns: Array<ListColumn<Pallet>> = [
        {
            key: 'reference',
            title: 'Référence palette',
            flex: '1 1 50%',
            render: (record) => record.reference,
        },
        {
            key: 'quantity',
            title: 'Quantité colis',
            flex: '1 1 50%',
            render: (record) => record.quantity,
        },
    ];

    useEffect(() => {
        if (customerOrderId) {
            fetchCustomerOrder({ customerOrderId });
        }
    }, [customerOrderId, fetchCustomerOrder]);

    const onClickSearchPallet = () => {
        show('controlSearchPalletModal');
    };

    const onClickPalletListRow = (record: Pallet) => {
        history.push(
            getRoute(RoutePathName.preparationControlPalletDetails, {
                loadingOrderId,
                customerOrderId,
                palletId: record.id,
            })
        );
    };

    return (
        <FixedFooter.Wrapper>
            <Seo title="Liste des palettes" />
            <Header
                title="Liste des palettes"
                backRoute={getRoute(RoutePathName.preparationControl, {
                    loadingOrderId,
                    customerOrderId: customerOrderIdParam,
                })}
            />
            <List<Pallet>
                columns={columns}
                data={customerOrderState.data?.pallets}
                rowKey={(record) => `${record.id}`}
                onRowEnterPress={onClickPalletListRow}
                onRowClick={onClickPalletListRow}
                isLoading={customerOrderState.loading}
                title={`${customerOrderState.data?.pallets?.length ?? 0} palettes crées`}
                emptyDescription="Aucune palette existante pour ce client"
            />
            <ControlSearchPalletModal id={'controlSearchPalletModal'} />
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut shortcut="f1" onClick={onClickSearchPallet} block>
                        Rechercher
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </FixedFooter.Wrapper>
    );
};

export default ControlPalletList;
