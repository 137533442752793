import { Descriptions, Spin, Divider, Card, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    CustomerOrderStatus,
    Pallet,
    PalletStatus,
    Permission,
    PermissionRight,
    PlatformLoadingStatus,
} from '../../../store/api/apiTypes';

import {
    checkPlatformLoadingsPallets,
    details as loadingDetails,
    getChekPlatformLoadingsPalletsState,
    getPlatformLoadingsStateById,
} from '../../../store/actions/platformLoadings';
import { getUser } from '../../../store/actions/auth';

import {
    formatNumber,
    getPlatformLoadingCheckPalletError,
    translateCustomerOrderStatus,
    translatePalletStatus,
} from '../../../helpers/i18n';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonGrey from '../../../components/ButtonGrey';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import FixedFooter from '../../../components/FixedFooter';
import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import PageHeader from '../../../components/PageHeader';
import QuantityCard from '../../../components/QuantityCard';
import Seo from '../../../components/Seo';
import { useActions, usePrevious } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import { IconWarning } from '../../../components/icons';
import { isZebra } from '../../../helpers/enterprise-browser';
import { hasPermission } from '../../../helpers/security';
import { getCustomerOrderStateById, details as detailsCustomerOrder } from '../../../store/actions/customerOrders';

import PalletScanModal from './PalletScanModal';
import SuccessModal from '../../../components/SuccessModal';
const shortcutScope = 'SuccessModalScope';

const PlatformLoadingCODetails: FC = () => {
    const { customerOrderId, loadingId } = useParams<{ customerOrderId: string; loadingId: string }>();
    const [isPalletScanModalVisible, setIsPalletScanModalVisible] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [isLoadingCompleteModalvisible, setIsLoadingCompleteModdalVisible] = useState(false);
    const [selectedPallet, setSelectedPallet] = useState<Pallet>();

    const [fetchLoadingDetails, sendPallets, fetchCustomerOrder] = useActions([
        loadingDetails.trigger,
        checkPlatformLoadingsPallets.trigger,
        detailsCustomerOrder.trigger,
    ]);
    const loadingDetailsState = useSelector(getPlatformLoadingsStateById(Number(loadingId)));
    const sendPalletsState = useSelector(getChekPlatformLoadingsPalletsState);
    const customerOrderDetailsState = useSelector(getCustomerOrderStateById(Number(customerOrderId)));
    const user = useSelector(getUser);

    const canEditDesktop = !isZebra && hasPermission(user, Permission.platformLoading, PermissionRight.write);

    const previous = usePrevious({ loadingDetailsState, customerOrderDetailsState, sendPalletsState });

    const onMarkAsLoaded = () => {
        sendPallets({ customerOrderId, references: [selectedPallet?.reference] });
    };
    const onClickReceive = () => {
        setIsPalletScanModalVisible(true);
    };
    const onClosePalletScanModal = useCallback(() => {
        setIsPalletScanModalVisible(false);
    }, []);

    const onCloseSuccessModal = useCallback(() => {
        setIsSuccessModalVisible(false);
        if (loadingDetailsState.data?.status === PlatformLoadingStatus.completed) {
            setIsLoadingCompleteModdalVisible(true);
        }
    }, [loadingDetailsState.data?.status]);

    const onCloseLoadingCompleteModal = useCallback(() => {
        setIsLoadingCompleteModdalVisible(false);
    }, []);

    const onPalletScanModalFinish = useCallback(() => {
        setIsPalletScanModalVisible(false);
    }, []);

    const columns: Array<ListColumn<Pallet>> = [
        {
            key: 'lot',
            title: 'N° de lot',
            flex: '1 1 60%',
            render: (record) => record.reference ?? '-',
        },
        {
            key: 'status',
            title: 'Statut',
            flex: '1 1 40%',
            render: (record) => translatePalletStatus(record.status, true),
        },
    ];

    const loadedPalletsCount = customerOrderDetailsState.data?.quantityLoadedToSendToClient ?? 0;
    const palletsNumber = customerOrderDetailsState.data?.quantityToLoadToSendToClient ?? 0;

    const isPalletLoaded = (status: PalletStatus) =>
        [PalletStatus.loadedSendToClient, PalletStatus.loadedSendToClientDerogated].includes(status);

    const isCustomerOrderLoaded = (status?: CustomerOrderStatus) => status === CustomerOrderStatus.loadedSendToClient;

    const onPalletRowClick = (pallet: Pallet) => {
        return canEditDesktop ? setSelectedPallet(pallet) : undefined;
    };

    useEffect(() => {
        fetchLoadingDetails({ id: loadingId });
        fetchCustomerOrder({ customerOrderId });
    }, [customerOrderId, fetchCustomerOrder, fetchLoadingDetails, loadingId]);

    useEffect(() => {
        if (previous?.customerOrderDetailsState.loading && !customerOrderDetailsState.loading) {
            if (!customerOrderDetailsState.error) {
                if (
                    customerOrderDetailsState.data?.id === previous?.customerOrderDetailsState.data?.id && // prevent display modal when customOrder Id change
                    !isCustomerOrderLoaded(previous?.customerOrderDetailsState.data?.status) &&
                    isCustomerOrderLoaded(customerOrderDetailsState.data?.status)
                ) {
                    setIsSuccessModalVisible(true);
                }
            }
        }
    }, [
        customerOrderDetailsState.data?.status,
        loadingDetailsState.error,
        loadingDetailsState.loading,
        previous?.customerOrderDetailsState.data?.status,
        previous?.customerOrderDetailsState.data?.id,
        customerOrderDetailsState.data?.id,
        previous?.customerOrderDetailsState.loading,
        customerOrderDetailsState.loading,
        customerOrderDetailsState.error,
    ]);

    useEffect(() => {
        if (previous?.sendPalletsState.loading && !sendPalletsState.loading) {
            if (sendPalletsState.error) {
                if (canEditDesktop) {
                    const requestError = getPlatformLoadingCheckPalletError(sendPalletsState, true);
                    message.error(requestError.message);
                }
            } else {
                fetchLoadingDetails({ id: loadingId });
                fetchCustomerOrder({ customerOrderId });
                setSelectedPallet(undefined);
            }
        }
    }, [
        canEditDesktop,
        customerOrderId,
        fetchCustomerOrder,
        fetchLoadingDetails,
        loadingId,
        previous?.sendPalletsState.loading,
        sendPalletsState,
        sendPalletsState.error,
        sendPalletsState.loading,
    ]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Détail du client" />
            <Header
                title="Détail du client"
                backRoute={
                    (loadingDetailsState.data?.customerOrders?.length ?? 0) > 1
                        ? getRoute(RoutePathName.platformLoadingDetails, { loadingId })
                        : getRoute(RoutePathName.platformHome)
                }
            />
            <PageHeader>
                <Spin spinning={loadingDetailsState.loading}>
                    <Descriptions column={isZebra ? 3 : 4} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="Code client">
                            {customerOrderDetailsState.data?.customer?.reference ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Rang">
                            {' '}
                            {customerOrderDetailsState.data?.rank ?? '-'}{' '}
                        </Descriptions.Item>
                        <Descriptions.Item label="Statut">
                            {translateCustomerOrderStatus(customerOrderDetailsState.data?.status)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Client">
                            {customerOrderDetailsState.data?.customer?.label ?? '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>

            <QuantityCard
                isComplete={isCustomerOrderLoaded(customerOrderDetailsState.data?.status)}
                label={
                    customerOrderDetailsState.data?.status !== CustomerOrderStatus.toBeLoadedSendToClient
                        ? 'Lots contrôlés'
                        : 'Lots à charger'
                }
                value={
                    loadedPalletsCount
                        ? `${formatNumber(loadedPalletsCount)}/${formatNumber(palletsNumber)}`
                        : formatNumber(palletsNumber)
                }
            />
            <Divider />
            <List<Pallet>
                focusable={!isZebra}
                columns={columns}
                data={customerOrderDetailsState.data?.pallets}
                rowKey={(record) => `${record.id}`}
                onRowFocus={onPalletRowClick}
                onRowClick={onPalletRowClick}
                isRowHiglighted={(record) => isPalletLoaded(record.status)}
                isRowWarning={(record) => record.status === PalletStatus.receivedInPlatformDamaged}
                isLoading={loadingDetailsState.loading}
            />

            {!customerOrderDetailsState.data?.pallets?.length && (
                <Card>
                    <p style={{ margin: 0, textAlign: 'center', color: '#EA9741' }}>
                        <IconWarning style={{ fontSize: '1.5rem' }} />
                        <br />
                        Il n&rsquo;y a pas de lots à charger
                    </p>
                </Card>
            )}
            {isZebra && (
                <PalletScanModal
                    visible={isPalletScanModalVisible}
                    onCancel={onClosePalletScanModal}
                    onFinish={onPalletScanModalFinish}
                    palletToLoadCount={palletsNumber - loadedPalletsCount}
                />
            )}
            <SuccessModal
                visible={isSuccessModalVisible}
                onCancel={onCloseSuccessModal}
                message="Tous les lots du client ont été contrôlés"
                footer={null}
                autoClose
            />
            <SuccessModal
                visible={isLoadingCompleteModalvisible}
                message="Toutes les lots du chargement ont été contrôlés"
                footer={
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            ghost
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onCloseLoadingCompleteModal}
                        >
                            Fermer
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                }
            />

            {isZebra && (
                <FixedFooter>
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            shortcut="enter"
                            size="large"
                            as={ButtonGrey}
                            onClick={onClickReceive}
                            block
                            disabled={isCustomerOrderLoaded(customerOrderDetailsState.data?.status)}
                        >
                            Contrôler les lots
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </FixedFooter>
            )}
            {canEditDesktop && selectedPallet && (
                <FixedFooter>
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            shortcut="enter"
                            size="large"
                            as={ButtonGrey}
                            onClick={onMarkAsLoaded}
                            disabled={isPalletLoaded(selectedPallet?.status)}
                            loading={sendPalletsState.loading || loadingDetailsState.loading}
                            block
                        >
                            Marquer comme chargé
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </FixedFooter>
            )}
        </FixedFooter.Wrapper>
    );
};

export default PlatformLoadingCODetails;
