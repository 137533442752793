import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { PlaceSector } from '../api/apiTypes';
import { ListResponse } from '../api';

// Controlers
import {
    PlaceSectorListPayload,
    list as listApiCall,
    PlaceSectorCreatePayload,
    create as createApiCall,
    PlaceSectorUpdatePayload,
    update as updateApiCall,
    PlaceSectorDeletePayload,
    del as delApiCall,
} from '../api/placeSectors';

// States
export interface PlaceSectorsState {
    list: RequestState<ListResponse<PlaceSector>>;
    create: RequestState<PlaceSector>;
    update: RequestState<PlaceSector>;
    del: RequestState;
}

const initialState: PlaceSectorsState = {
    list: { ...requestInitialState },
    create: { ...requestInitialState },
    update: { ...requestInitialState },
    del: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<PlaceSectorsState['list'], PlaceSectorListPayload, ListResponse<PlaceSector>>(
    'placeSectors/list',
    initialState.list,
    requestReducer<PlaceSectorsState['list'], ListResponse<PlaceSector>>(initialState.list)
);

export const create = new EzeeAsyncAction<PlaceSectorsState['create'], PlaceSectorCreatePayload, PlaceSector>(
    'placeSectors/create',
    initialState.create,
    requestReducer<PlaceSectorsState['create'], PlaceSector>(initialState.create)
);

export const update = new EzeeAsyncAction<PlaceSectorsState['update'], PlaceSectorUpdatePayload, PlaceSector>(
    'placeSectors/update',
    initialState.update,
    requestReducer<PlaceSectorsState['update'], PlaceSector>(initialState.update)
);

export const del = new EzeeAsyncAction<PlaceSectorsState['del'], PlaceSectorDeletePayload>(
    'placeSectors/del',
    initialState.del,
    requestReducer<PlaceSectorsState['del'], PlaceSector>(initialState.del)
);

// Reducer
export const placeSectorsReducer = combineReducers<PlaceSectorsState>({
    list: list.reducer,
    create: create.reducer,
    update: update.reducer,
    del: del.reducer,
});

// Saga
export function* placeSectorsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
}

// Store helpers
export const getPlaceSectorsListState = (state: MainReducerState) => state.placeSectors.list;
export const getPlaceSectorCreateState = (state: MainReducerState) => state.placeSectors.create;
export const getPlaceSectorUpdateState = (state: MainReducerState) => state.placeSectors.update;
export const getPlaceSectorDeleteState = (state: MainReducerState) => state.placeSectors.del;
