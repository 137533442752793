import { SearchPaginationQuery } from '.';
import { ControlLoadingOrder } from './apiTypes';
import api from './_client';

export interface ControlLoadingOrderCreatePayload {
    tourNumber: string;
}
export const create = async (payload: ControlLoadingOrderCreatePayload) =>
    await api.post('/controlLoadingOrders', payload);

export type ControlLoadingOrderUpdatePayload = Pick<ControlLoadingOrder, 'id'> & Partial<ControlLoadingOrder>;
export const update = async ({ id, ...payload }: ControlLoadingOrderUpdatePayload) =>
    await api.put(`/controlLoadingOrders/${id}`, payload);

export interface ControlLoadingOrderDetailsPayload {
    controlLoadingOrderId: ControlLoadingOrder['id'];
}
export const details = async ({ controlLoadingOrderId, ...payload }: ControlLoadingOrderDetailsPayload) =>
    await api.get(`/controlLoadingOrders/${controlLoadingOrderId}`, { params: payload });

export interface ControlLoadingOrderPalletListPayload extends SearchPaginationQuery {
    controlLoadingOrderId: ControlLoadingOrder['id'];
}
export const palletList = async (payload: ControlLoadingOrderDetailsPayload) =>
    await api.get('/controlLoadingOrderPallets', { params: payload });

export interface ControlLoadingOrderClosePayload {
    controlLoadingOrderId: ControlLoadingOrder['id'];
}
export const close = async ({ controlLoadingOrderId, ...payload }: ControlLoadingOrderClosePayload) =>
    await api.post(`/controlLoadingOrders/${controlLoadingOrderId}/close`, payload);
