import React, { FC } from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './assets/styles/App.less';

import { Permission, RoleName } from './store/api/apiTypes';
import { getUser } from './store/actions/auth';

import { hasPermission, hasRole } from './helpers/security';
import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/MainLayout';
import ErrorBoundary from './components/ErrorBoundary';
import { getRawRoute, RoutePathName } from './routes';
import ErrorPage from './pages/error';
import Login from './pages/login';
import Home from './pages/home';
import { ReceptionDetails, ReceptionList, ReceptionPalletPlaceEdit } from './pages/reception';
import { ResupplyOrderDetails, ResupplyOrderList } from './pages/resupplyOrders';
import {
    InventoryCreate,
    InventoryDetails,
    InventoryList,
    InventoryMenu,
    InventoryPlaceDetails,
    InventoryStockGapList,
} from './pages/inventory';
import { SearchDetails, SearchMenu, SearchResults, SearchType } from './pages/search';
import {
    ControlDetails,
    ControlPalletDetails,
    ControlRegularize,
    ControlRegularizePallet,
    CustomerOrderDetails,
    ControlPalletList,
    LoadingOrderDetails,
    LoadingOrderList,
    OrderDetails,
    OrderList,
    PreparationDetails,
    PreparationPicking,
} from './pages/preparation';
import { ControlLoadingOrderDetails, ControlOrderPalletDetails } from './pages/controlLoadingOrders';
import {
    CustomerReturnPalletList,
    CustomerReturnPackagePlaces,
    MovemenntRepairs,
    MovementRepairDetails,
    MovementRepairPackagesPlaces,
    MovementRepairPalletPlaces,
    MovementsBrokenDetails,
    MovementsBrokenList,
    MovementsMenu,
    MovementsPlacesFilter,
    MovementsStockMenu,
    MovementsVariousOutputsMenu,
    TransferOrderDetails,
    TransferOrderList,
    TransferOrderPackagePlaces,
    TransferOrderPalletPlacees,
    TransferOrdersPalletsList,
    TransferOrdersPlacesFilter,
    CustomerReturnPlacesFilter,
} from './pages/movements';
import {
    PlaceManagementCreate,
    PlaceManagementMenu,
    PlaceManagementPlaceStores,
    PlaceManagementSearch,
    PlaceManagementSearchResults,
    PlaceManagementSection,
    PlaceManagementSectionsMenu,
} from './pages/placeManagement';
import { AnomalyCodes, ReasonCodes, SettingsMenu } from './pages/platform/settings';
import { PlatformHome, PlatformLoadingDetails } from './pages/platform';

import { LotVisualization } from './pages/platform/lotVisualization';
import { PlatformReception } from './pages/platform/reception';
import { Rdv, RdvCustomerOrder } from './pages/platform/rdv';
import { PlatformExpedition } from './pages/platform/expedition';
import PlatformLoadingCODetails from './pages/platform/loading/PlatformLoadingCODetails';
import ParcelCheckingDetails from './pages/parcelChecking/parcelCheckingDetails';
import { Settings, IncidentsMachinesList } from './pages/settings';

interface RouteComponent extends RouteProps {
    permissions?: Permission[];
}

const routeComponents: RouteComponent[] = [
    // -----------------------------------------------------------------------------
    // ------------------------------- WMS -----------------------------------------
    // ReceptionList -------------------------------------------------------------------
    {
        path: getRawRoute(RoutePathName.receptionsList),
        children: <ReceptionList />,
        exact: true,
        permissions: [Permission.reception],
    },
    {
        path: getRawRoute(RoutePathName.receptionParcel),
        children: <ReceptionDetails />,
        permissions: [Permission.reception],
    },
    {
        path: getRawRoute(RoutePathName.receptionPalletPlaceEdit),
        children: <ReceptionPalletPlaceEdit />,
        permissions: [Permission.reception],
    },
    // Resupply --------------------------------------------------------------------
    {
        path: getRawRoute(RoutePathName.resupplyOrderDetails),
        children: <ResupplyOrderDetails />,
        exact: true,
        permissions: [Permission.resupply],
    },
    {
        path: getRawRoute(RoutePathName.resupplyOrderList),
        children: <ResupplyOrderList />,
        exact: true,
        permissions: [Permission.resupply],
    },
    // Inventory -------------------------------------------------------------------
    {
        path: getRawRoute(RoutePathName.inventoryMenu),
        children: <InventoryMenu />,
        exact: true,
        permissions: [Permission.inventory],
    },
    {
        path: getRawRoute(RoutePathName.inventoryPlaceDetails),
        children: <InventoryPlaceDetails />,
        exact: true,
        permissions: [Permission.inventory],
    },
    {
        path: getRawRoute(RoutePathName.inventoryStockGap),
        children: <InventoryStockGapList />,
        exact: true,
        permissions: [Permission.inventory],
    },
    {
        path: getRawRoute(RoutePathName.inventoryCreate),
        children: <InventoryCreate />,
        exact: true,
        permissions: [Permission.inventory],
    },
    {
        path: [getRawRoute(RoutePathName.inventoryCurrent), getRawRoute(RoutePathName.inventoryHistory)],
        children: <InventoryList />,
        exact: true,
        permissions: [Permission.inventory],
    },
    {
        path: getRawRoute(RoutePathName.inventoryDetails),
        children: <InventoryDetails />,
        exact: true,
        permissions: [Permission.inventory],
    },
    // Search ---------------------------------------------------------------------
    {
        path: getRawRoute(RoutePathName.search),
        children: <SearchMenu />,
        exact: true,
        permissions: [Permission.searchStock],
    },
    {
        path: getRawRoute(RoutePathName.searchType),
        children: <SearchType />,
        exact: true,
        permissions: [Permission.searchStock],
    },
    {
        path: getRawRoute(RoutePathName.searchResults),
        children: <SearchResults />,
        exact: true,
        permissions: [Permission.searchStock],
    },
    {
        path: [getRawRoute(RoutePathName.searchDetails), getRawRoute(RoutePathName.searchDetailsHistory)],
        children: <SearchDetails />,
        exact: true,
        permissions: [Permission.searchStock],
    },
    // Preparation -------------------------------------------------------------------
    {
        path: getRawRoute(RoutePathName.customerOrderDetails),
        children: <CustomerOrderDetails />,
        exact: true,
        permissions: [Permission.preparation],
    },
    {
        path: getRawRoute(RoutePathName.loadingOrderDetails),
        children: <LoadingOrderDetails />,
        exact: true,
        permissions: [Permission.preparation],
    },
    {
        path: getRawRoute(RoutePathName.preparation),
        children: <LoadingOrderList />,
        exact: true,
        permissions: [Permission.preparation],
    },
    {
        path: getRawRoute(RoutePathName.preparationDetails),
        children: <PreparationDetails />,
        exact: true,
        permissions: [Permission.preparation],
    },
    {
        path: [getRawRoute(RoutePathName.preparationPicking), getRawRoute(RoutePathName.preparationDetailType)],
        children: <PreparationPicking />,
        exact: true,
        permissions: [Permission.preparation],
    },
    {
        path: getRawRoute(RoutePathName.preparationControl),
        children: <ControlDetails />,
        exact: true,
        permissions: [Permission.preparation],
    },
    {
        path: getRawRoute(RoutePathName.preparationControlPalletList),
        children: <ControlPalletList />,
        exact: true,
        permissions: [Permission.preparation],
    },
    {
        path: getRawRoute(RoutePathName.preparationControlPalletDetails),
        children: <ControlPalletDetails />,
        exact: true,
        permissions: [Permission.preparation],
    },
    {
        path: getRawRoute(RoutePathName.preparationControlRegularize),
        children: <ControlRegularize />,
        exact: true,
        permissions: [Permission.preparation],
    },
    {
        path: getRawRoute(RoutePathName.preparationControlRegularizePallet),
        children: <ControlRegularizePallet />,
        exact: true,
        permissions: [Permission.preparation],
    },
    {
        path: getRawRoute(RoutePathName.orderList),
        children: <OrderList />,
        exact: true,
        permissions: [Permission.preparation],
    },
    {
        path: getRawRoute(RoutePathName.orderDetails),
        children: <OrderDetails />,
        exact: true,
        permissions: [Permission.preparation],
    },
    // Loading orders ----------------------------------------------------------------
    {
        path: getRawRoute(RoutePathName.controlLoadingOrderDetails),
        children: <ControlLoadingOrderDetails />,
        exact: true,
        permissions: [Permission.loading],
    },
    {
        path: getRawRoute(RoutePathName.controlOrderPalletDetails),
        children: <ControlOrderPalletDetails />,
        exact: true,
        permissions: [Permission.loading],
    },
    // Movements  ---------------------------------------------------------------------
    {
        path: getRawRoute(RoutePathName.movementsMenu),
        children: <MovementsMenu />,
        exact: true,
        permissions: [
            Permission.transferOrder,
            Permission.customerReturn,
            Permission.repair,
            Permission.backToWorkshop,
            Permission.break,
            Permission.opac,
        ],
    },
    {
        path: getRawRoute(RoutePathName.movementsPlacesFilter),
        children: <MovementsPlacesFilter />,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.movementsTransferOrderList),
        children: <TransferOrderList />,
        exact: true,
        permissions: [Permission.transferOrder],
    },
    {
        path: getRawRoute(RoutePathName.movementsTransferOrderDetails),
        children: <TransferOrderDetails />,
        exact: true,
        permissions: [Permission.transferOrder],
    },
    {
        path: getRawRoute(RoutePathName.movementTransferOrderPalletPlaces),
        children: <TransferOrderPalletPlacees />,
        exact: true,
        permissions: [Permission.transferOrder],
    },
    {
        path: getRawRoute(RoutePathName.movementsTransferOrdersPlacesFilter),
        children: <TransferOrdersPlacesFilter />,
        exact: true,
        permissions: [Permission.transferOrder],
    },
    {
        path: getRawRoute(RoutePathName.movementsTransferOrdersPalletsList),
        children: <TransferOrdersPalletsList />,
        exact: true,
        permissions: [Permission.transferOrder],
    },
    {
        path: getRawRoute(RoutePathName.movementsTransferOrdersPackagePlaces),
        children: <TransferOrderPackagePlaces />,
        exact: true,
        permissions: [Permission.transferOrder],
    },
    {
        path: getRawRoute(RoutePathName.movementsRepairs),
        children: <MovemenntRepairs />,
        exact: true,
        permissions: [Permission.repair],
    },
    {
        path: getRawRoute(RoutePathName.movementsRepairDetails),
        children: <MovementRepairDetails />,
        exact: true,
        permissions: [Permission.repair],
    },
    {
        path: getRawRoute(RoutePathName.movementRepairPalletPlaces),
        children: <MovementRepairPalletPlaces />,
        exact: true,
        permissions: [Permission.repair],
    },
    {
        path: getRawRoute(RoutePathName.movementRepairPackagesPlaces),
        children: <MovementRepairPackagesPlaces />,
        exact: true,
        permissions: [Permission.repair],
    },
    {
        path: getRawRoute(RoutePathName.movementsStockMenu),
        children: <MovementsStockMenu />,
        exact: true,
        permissions: [
            Permission.customerReturn,
            Permission.repair,
            Permission.backToWorkshop,
            Permission.break,
            Permission.stockControls,
        ],
    },
    {
        path: getRawRoute(RoutePathName.movementsVariousOutputsMenu),
        children: <MovementsVariousOutputsMenu />,
        exact: true,
        permissions: [
            Permission.customerReturn,
            Permission.repair,
            Permission.backToWorkshop,
            Permission.break,
            Permission.stockControls,
        ],
    },
    {
        path: getRawRoute(RoutePathName.movementsBrokenList),
        children: <MovementsBrokenList />,
        exact: true,
        permissions: [
            Permission.customerReturn,
            Permission.repair,
            Permission.backToWorkshop,
            Permission.break,
            Permission.stockControls,
        ],
    },
    {
        path: getRawRoute(RoutePathName.movementsBrokenDetails),
        children: <MovementsBrokenDetails />,
        exact: true,
        permissions: [
            Permission.customerReturn,
            Permission.repair,
            Permission.backToWorkshop,
            Permission.break,
            Permission.stockControls,
        ],
    },
    {
        path: getRawRoute(RoutePathName.movementCustomerReturnPalletList),
        children: <CustomerReturnPalletList />,
        exact: true,
        permissions: [Permission.customerReturn],
    },
    {
        path: getRawRoute(RoutePathName.movementCustomerReturnPackagePlaces),
        children: <CustomerReturnPackagePlaces />,
        exact: true,
        permissions: [Permission.customerReturn],
    },
    {
        path: getRawRoute(RoutePathName.movementCustomerReturnPlacesFilter),
        children: <CustomerReturnPlacesFilter />,
        exact: true,
        permissions: [Permission.customerReturn],
    },
    // Place management  ----------------------------------------------------------------
    {
        path: getRawRoute(RoutePathName.placeManagementMenu),
        children: <PlaceManagementMenu />,
        exact: true,
        permissions: [Permission.placeManagement],
    },
    {
        path: getRawRoute(RoutePathName.placeManagementSearch),
        children: <PlaceManagementSearch />,
        exact: true,
        permissions: [Permission.placeManagement],
    },
    {
        path: getRawRoute(RoutePathName.placeManagementSearchResults),
        children: <PlaceManagementSearchResults />,
        exact: true,
        permissions: [Permission.placeManagement],
    },
    {
        path: getRawRoute(RoutePathName.placeManagementCreate),
        children: <PlaceManagementCreate />,
        exact: true,
        permissions: [Permission.placeManagement],
    },
    {
        path: getRawRoute(RoutePathName.placeManagementSectionsMenu),
        children: <PlaceManagementSectionsMenu />,
        exact: true,
        permissions: [Permission.placeManagement],
    },
    {
        path: getRawRoute(RoutePathName.placeManagementSites),
        children: <PlaceManagementPlaceStores />,
        exact: true,
        permissions: [Permission.placeManagement],
    },
    {
        path: [
            getRawRoute(RoutePathName.placeManagementSectors),
            getRawRoute(RoutePathName.placeManagementLanes),
            getRawRoute(RoutePathName.placeManagementAlveoles),
        ],
        children: <PlaceManagementSection />,
        exact: true,
        permissions: [Permission.placeManagement],
    },

    // -----------------------------------------------------------------------------
    // ----------------------------- Platform --------------------------------------
    // Settings  -------------------------------------------------------------------
    {
        path: getRawRoute(RoutePathName.platformSettings),
        children: <SettingsMenu />,
        exact: true,
        permissions: [Permission.platformSetting],
    },
    {
        path: getRawRoute(RoutePathName.platformAnomalyCodes),
        children: <AnomalyCodes />,
        exact: true,
        permissions: [Permission.platformSetting],
    },
    {
        path: getRawRoute(RoutePathName.platformReasonCodes),
        children: <ReasonCodes />,
        exact: true,
        permissions: [Permission.platformSetting],
    },
    // Lot visualization  -------------------------------------------------------
    {
        path: getRawRoute(RoutePathName.platformLotVisualization),
        children: <LotVisualization />,
        exact: true,
        permissions: [Permission.platformLotVisualization],
    },
    // ReceptionList -------------------------------------------------------------------
    {
        path: getRawRoute(RoutePathName.platformReception),
        children: <PlatformReception />,
        exact: true,
        permissions: [Permission.platformReception],
    },
    // Loading -------------------------------------------------------------------
    {
        path: getRawRoute(RoutePathName.platformLoadingCustomerOrderDetails),
        children: <PlatformLoadingCODetails />,
        exact: true,
        permissions: [Permission.platformLoading],
    },
    {
        path: getRawRoute(RoutePathName.platformLoadingDetails),
        children: <PlatformLoadingDetails />,
        exact: true,
        permissions: [Permission.platformLoading],
    },
    // Rendez-vous -----------------------------------------------------------------
    {
        path: getRawRoute(RoutePathName.platformRdv),
        children: <Rdv />,
        exact: true,
        permissions: [Permission.platformRDV],
    },
    {
        path: getRawRoute(RoutePathName.platformRdvCustomerOrder),
        children: <RdvCustomerOrder />,
        exact: true,
        permissions: [Permission.platformRDV],
    },
    // Expedition ------------------------------------------
    {
        path: getRawRoute(RoutePathName.platformExpedition),
        children: <PlatformExpedition />,
        exact: true,
        permissions: [Permission.platformShipping],
    },
    // Contrôle codes-barres ------------------------------------------
    {
        path: getRawRoute(RoutePathName.parcelCheckDetails),
        children: <ParcelCheckingDetails />,
        exact: true,
        permissions: [Permission.parcels],
    },
    // Paramétrage ------------------------------------------
    {
        path: getRawRoute(RoutePathName.settings),
        children: <Settings />,
        exact: true,
        permissions: [Permission.settings],
    },
    {
        path: getRawRoute(RoutePathName.incidentsList),
        children: <IncidentsMachinesList />,
        exact: true,
        permissions: [Permission.incidents, Permission.settings],
    },
    {
        path: getRawRoute(RoutePathName.machinesList),
        children: <IncidentsMachinesList />,
        exact: true,
        permissions: [Permission.incidents, Permission.settings],
    },
];

const App: FC = () => {
    const user = useSelector(getUser);

    return (
        <ErrorBoundary>
            <Switch>
                <Route path={getRawRoute(RoutePathName.login)} component={Login} exact />
                <ProtectedRoute>
                    <MainLayout>
                        <Switch>
                            {/* WMS homepage */}
                            <Route path={getRawRoute(RoutePathName.home)} exact>
                                {hasRole(user, [RoleName.pfAdmin, RoleName.pfUser]) ? <PlatformHome /> : <Home />}
                            </Route>

                            {/* PLATFORM homepage */}
                            <Route path={getRawRoute(RoutePathName.platformHome)} exact>
                                <PlatformHome />
                            </Route>

                            {routeComponents.map(({ permissions, children, ...routeProps }, index) => {
                                const result = <Route key={index} children={children} {...routeProps} />;

                                if (permissions?.length) {
                                    return permissions.some((permission) => hasPermission(user, permission)) ? (
                                        result
                                    ) : (
                                        <Route key={index} {...routeProps}>
                                            <ErrorPage status={403} />
                                        </Route>
                                    );
                                } else {
                                    return result;
                                }
                            })}

                            <Route path="*">
                                <ErrorPage />
                            </Route>
                        </Switch>
                    </MainLayout>
                </ProtectedRoute>
            </Switch>
        </ErrorBoundary>
    );
};

export default App;
