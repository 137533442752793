import React, { FC, useState } from 'react';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useArrowNavScope, useShortcutScope } from '../../../hooks';
import QuantityInput from '../../../components/QuantityInput';
import TitleBlack from '../../../components/TitleBlack';
import QuantityCard from '../../../components/QuantityCard';
import { TransferOrder, TransferOrderType } from '../../../store/api/apiTypes';

const shortcutScope = 'TransferQuantityModal';

interface TransferQuantityModalProps extends CustomModalProps {
    onSubmit: (packagesQuantity: number) => void;
    onReturn: () => void;
    subTitle?: string;
    text?: string;
    max: number;
    transferOrderType?: TransferOrder['type'];
}

const TransferQuantityModal: FC<TransferQuantityModalProps> = ({
    visible,
    onReturn,
    cancelText,
    onSubmit,
    title,
    subTitle,
    text,
    max,
    transferOrderType,
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const [quantity, setQuantity] = useState<number>(max);

    const handleSubmit = () => {
        onSubmit(quantity);
    };

    return (
        <CustomModal
            visible={visible}
            title={title}
            width={368}
            keyboard={false}
            altTitle
            transitionName=""
            maskTransitionName=""
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            onClick={handleSubmit}
                            shortcut="enter"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            disabled={quantity <= 0 || quantity > max}
                        >
                            Valider
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onReturn}
                            ghost
                        >
                            {cancelText ?? 'Annuler'}
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
        >
            {subTitle && transferOrderType !== TransferOrderType.manual && <TitleBlack>{subTitle}</TitleBlack>}
            {transferOrderType === TransferOrderType.manual ? (
                <QuantityCard value={quantity} label={subTitle} onWhite />
            ) : (
                <ArrowNavItem scope={shortcutScope}>
                    <QuantityInput max={max} value={quantity} onChange={setQuantity} text={text} />
                </ArrowNavItem>
            )}
        </CustomModal>
    );
};

export default TransferQuantityModal;
