import { debug } from './helpers';

export enum RoutePathName {
    home = 'home',
    login = 'login',
    notFound = 'notFound',

    // receptions
    receptionsList = 'receptionsList',
    receptionParcel = 'receptionParcel',
    receptionParcelPallet = 'receptionParcelPallet',
    receptionPalletList = 'receptionPalletList',
    receptionPallet = 'receptionPallet',
    receptionPalletPlaceEdit = 'receptionPalletPlaceEdit',
    receptionPalletPlaceEditFilter = 'receptionPalletPlaceEditFilter',
    receptionPalletPlaceEditSearch = 'receptionPalletPlaceEditSearch',

    resupplyOrderList = 'resupplyOrderList',
    resupplyOrderDetails = 'resupplyOrderDetails',

    inventoryMenu = 'inventoryMenu',
    inventoryCurrent = 'inventoryCurrent',
    inventoryHistory = 'inventoryHistory',
    inventoryDetails = 'inventoryDetails',
    inventoryPlaceDetails = 'inventoryPlaceDetails',
    inventoryStockGap = 'inventoryStockGap',
    inventoryCreate = 'inventoryCreate',

    stockControlMenu = 'stockControlMenu',
    stockControlList = 'stockControlList',
    stockControlCurrent = 'stockControlCurrent',
    stockControlHistory = 'stockControlHistory',
    stockControlStockGap = 'stockControlStockGap',
    stockControlDetails = 'stockControlDetails',
    stockControlPlaceDetails = 'stockControlPlaceDetails',
    stockControlCreate = 'stockControlCreate',

    search = 'search',
    searchType = 'searchType',
    searchResults = 'searchResults',
    searchDetails = 'searchDetails',
    searchDetailsHistory = 'searchDetailsHistory',

    preparation = 'preparation',
    loadingOrderDetails = 'loadingOrderDetails',
    customerOrderDetails = 'customerOrderDetails',
    orderList = 'orderList',
    orderDetails = 'orderDetails',
    preparationDetails = 'preparationDetails',
    preparationPicking = 'preparationPicking',
    preparationDetailType = 'preparationDetailType',
    preparationControl = 'preparationControl',
    preparationControlPalletList = 'preparationControlPalletList',
    preparationControlPalletDetails = 'preparationControlPalletDetails',
    preparationControlRegularize = 'preparationControlRegularize',
    preparationControlRegularizePallet = 'preparationControlRegularizePallet',

    controlLoadingOrderDetails = 'controlLoadingOrderDetails',
    controlOrderPalletDetails = 'controlOrderPalletDetails',

    // movement
    movementsMenu = 'movements',
    movementsStockMenu = 'movementsStockMenu',
    movementsVariousOutputsMenu = 'movementsVariousOutputsMenu',
    movementsPlacesFilter = 'movementsPlacesFilter',

    // movement transferOrder
    movementsTransferOrderList = 'movementsTransferOrderList',
    movementsTransferOrderDetails = 'movementsTransferOrderDetails',
    movementsTransferOrdersPlacesFilter = 'movementsTransferOrdersPlacesFilter',
    movementsTransferOrdersPalletsList = 'movementsTransferOrdersPalletsList',
    movementsTransferOrdersPackagePlaces = 'movementsTransferOrdersPackagePlaces',
    movementTransferOrderPalletPlaces = 'movementTransferOrderPalletPlaces',

    // movement customerReturn
    movementCustomerReturnPalletList = 'movementCustomerReturnPalletList',
    movementCustomerReturnPackagePlaces = 'movementCustomerReturnPackagePlaces',
    movementCustomerReturnPlacesFilter = 'movementCustomerReturnPlacesFilter',

    // movements repairs
    movementsRepairs = 'movementsRepairs',
    movementsRepairDetails = 'movementRepairDetails',
    movementRepairPalletPlaces = 'movementRepairPalletPlaces',
    movementRepairPackagesPlaces = 'movementRepairPackagesPlaces',

    // movement brokens
    movementsBrokenList = 'movementsBrokenList',
    movementsBrokenDetails = 'MovementsBrokenDetails',

    // places
    placeManagementMenu = 'placeManagementMenu',
    placeManagementSearch = 'placeManagementSearch',
    placeManagementSearchResults = 'placeManagementSearchResults',
    placeManagementCreate = 'placeManagementCreate',
    placeManagementSectionsMenu = 'placeManagementSectionsMenu',
    placeManagementSites = 'placeManagementSites',
    placeManagementSectors = 'placeManagementSectors',
    placeManagementLanes = 'placeManagementLanes',
    placeManagementAlveoles = 'placeManagementAlveoles',

    // parcel check
    parcelCheckDetails = 'parcelCheckDetails',

    // PLATFORM
    platformHome = 'platformHome',

    platformSettings = 'platformSettings',
    platformAnomalyCodes = 'platformAnomalyCodes',
    platformReasonCodes = 'platformReasonCodes',
    platformLotVisualization = 'platformLotVisualization',
    platformReception = 'platformReception',

    platformLoadingDetails = 'platformLoadingDetails',
    platformLoadingCustomerOrderDetails = 'platformLoadingCustomerOrderDetails',
    platformRdv = 'platformRdv',
    platformRdvCustomerOrder = 'platformRdvCustomerOrder',
    platformExpedition = 'platformExpedition',

    settings = 'settings',
    incidentsList = 'incidentsList',
    machinesList = 'machinesList',
}

export type Routes = {
    [r in RoutePathName]: string;
};

export const routes: Routes = {
    [RoutePathName.home]: '/',
    [RoutePathName.login]: '/login',
    [RoutePathName.notFound]: '/404',

    [RoutePathName.receptionsList]: '/receptions',
    [RoutePathName.receptionParcel]: '/reception/:receptionId/parcel/:parcelId',
    [RoutePathName.receptionParcelPallet]: '/reception/:receptionId/parcel/:parcelId/create-pallet/',
    [RoutePathName.receptionPalletList]: '/reception/:receptionId/parcel/:parcelId/pallets/',
    [RoutePathName.receptionPallet]: '/reception/:receptionId/parcel/:parcelId/pallet/:palletId',
    [RoutePathName.receptionPalletPlaceEdit]: '/reception/:receptionId/pallet/:palletId/place/edit',
    [RoutePathName.receptionPalletPlaceEditFilter]: '/reception/:receptionId/pallet/:palletId/place/edit/filter',
    [RoutePathName.receptionPalletPlaceEditSearch]: '/reception/:receptionId/pallet/:palletId/place/edit/search',

    [RoutePathName.resupplyOrderList]: '/resupply-orders',
    [RoutePathName.resupplyOrderDetails]: '/resupply-orders/:resupplyOrderId',

    [RoutePathName.inventoryMenu]: '/inventories-menu',
    [RoutePathName.inventoryCurrent]: '/inventories/current',
    [RoutePathName.inventoryHistory]: '/inventories/history',
    [RoutePathName.inventoryStockGap]: '/inventories/stock-gap',
    [RoutePathName.inventoryDetails]: '/inventories/:inventoryId',
    [RoutePathName.inventoryPlaceDetails]: '/inventories/:inventoryId/inventory-place/:inventoryPlaceId',
    [RoutePathName.inventoryCreate]: '/inventories/create/:inventoryType',

    [RoutePathName.stockControlMenu]: '/stock-contols-menu',
    [RoutePathName.stockControlList]: '/stock-contols',
    [RoutePathName.stockControlCurrent]: '/stock-contols/current',
    [RoutePathName.stockControlHistory]: '/stock-contols/history',
    [RoutePathName.stockControlStockGap]: '/stock-contols/stock-gap',
    [RoutePathName.stockControlDetails]: '/stock-contols/:stockControlId',
    [RoutePathName.stockControlPlaceDetails]: '/stock-contols/:stockControlId/stock-control-place/:stockControlPlaceId',
    [RoutePathName.stockControlCreate]: '/stock-contols/create/:stockControlType',

    [RoutePathName.search]: '/search',
    [RoutePathName.searchType]: '/search/:type',
    [RoutePathName.searchResults]: '/search/:type/results',
    [RoutePathName.searchDetails]: '/search/:type/results/:id',
    [RoutePathName.searchDetailsHistory]: '/search/:type/results/:id/history',

    [RoutePathName.preparation]: '/preparation/loading-orders',
    [RoutePathName.loadingOrderDetails]: '/preparation/loading-orders/:loadingOrderId',
    [RoutePathName.customerOrderDetails]:
        '/preparation/loading-orders/:loadingOrderId/customer-orders/:customerOrderId',
    [RoutePathName.orderList]: '/preparation/loading-orders/:loadingOrderId/customer-orders/:customerOrderId/orders',
    [RoutePathName.orderDetails]:
        '/preparation/loading-orders/:loadingOrderId/customer-orders/:customerOrderId/orders/:orderId',
    [RoutePathName.preparationDetails]:
        '/preparation/loading-orders/:loadingOrderId/customer-orders/:customerOrderId/preparations/:preparationId/details',
    [RoutePathName.preparationPicking]:
        '/preparation/loading-orders/:loadingOrderId/customer-orders/:customerOrderId/preparations/:preparationId/picking',
    [RoutePathName.preparationDetailType]:
        '/preparation/loading-orders/:loadingOrderId/customer-orders/:customerOrderId/preparations/:preparationId/detail',
    [RoutePathName.preparationControl]:
        '/preparation/loading-orders/:loadingOrderId/customer-orders/:customerOrderId/control',
    [RoutePathName.preparationControlPalletList]:
        '/preparation/loading-orders/:loadingOrderId/customer-orders/:customerOrderId/control/pallets',
    [RoutePathName.preparationControlPalletDetails]:
        '/preparation/loading-orders/:loadingOrderId/customer-orders/:customerOrderId/control/pallets/:palletId',
    [RoutePathName.preparationControlRegularize]:
        '/preparation/loading-orders/:loadingOrderId/customer-orders/:customerOrderId/control/regularize',
    [RoutePathName.preparationControlRegularizePallet]:
        '/preparation/loading-orders/:loadingOrderId/customer-orders/:customerOrderId/control/regularize/:clientRegularizationId',
    [RoutePathName.controlLoadingOrderDetails]: '/control-loading-orders/:controlLoadingOrderId',
    [RoutePathName.controlOrderPalletDetails]:
        '/control-loading-orders/:controlLoadingOrderId/control-loading-order-pallets/:controlOrderPalletId',

    // place management
    [RoutePathName.placeManagementAlveoles]: '/place-management/alveoles',
    [RoutePathName.placeManagementMenu]: '/place-management',
    [RoutePathName.placeManagementSearch]: '/place-management/search',
    [RoutePathName.placeManagementSearchResults]: '/place-management/search/results',
    [RoutePathName.placeManagementCreate]: '/place-management/create',
    [RoutePathName.placeManagementSectionsMenu]: '/place-management/sections',
    [RoutePathName.placeManagementSites]: '/place-management/sites',
    [RoutePathName.placeManagementSectors]: '/place-management/sectors',
    [RoutePathName.placeManagementLanes]: '/place-management/lanes',

    // movements
    [RoutePathName.movementsMenu]: '/movements',
    [RoutePathName.movementsStockMenu]: '/movements/movements-stock-menu',
    [RoutePathName.movementsVariousOutputsMenu]: '/movements/movements-stock-menu/various-outputs-menu',
    [RoutePathName.movementsBrokenList]: '/movements/brokens',
    [RoutePathName.movementsBrokenDetails]: '/movements/brokens/:brokenId',
    [RoutePathName.movementsPlacesFilter]: '/movements/places-filter',
    [RoutePathName.movementsTransferOrderList]: '/movements/transfer-orders',
    [RoutePathName.movementsTransferOrderDetails]: '/movements/transfer-orders/:transferOrderId',
    [RoutePathName.movementTransferOrderPalletPlaces]: '/transfer-order-pallet-places',
    [RoutePathName.movementsTransferOrdersPlacesFilter]: '/transfer-ordersPlaces-filter',
    [RoutePathName.movementsTransferOrdersPalletsList]: '/transfer-orders-pallets-list',
    [RoutePathName.movementsTransferOrdersPackagePlaces]: '/transfer-orders-package-places',
    [RoutePathName.movementCustomerReturnPalletList]: '/movements/customer-return-pallets-list',
    [RoutePathName.movementCustomerReturnPackagePlaces]: '/movements/customer-return-package-places',
    [RoutePathName.movementCustomerReturnPlacesFilter]: '/movements/customer-return-places-filter',

    // movement repairs
    [RoutePathName.movementsRepairs]: '/movements/repairs',
    [RoutePathName.movementsRepairDetails]: '/movements/repairs/:repairId',
    [RoutePathName.movementRepairPalletPlaces]: '/movement/repairs/:repairId/pallet-places',
    [RoutePathName.movementRepairPackagesPlaces]: '/movement/reapirs/:repairId/packages-places',

    // parcel check
    [RoutePathName.parcelCheckDetails]: '/parcel-check/details',

    // platform
    [RoutePathName.platformHome]: '/platform',
    [RoutePathName.platformSettings]: '/platform/settings',
    [RoutePathName.platformAnomalyCodes]: '/platform/settings/anomalies',
    [RoutePathName.platformReasonCodes]: '/platform/settings/reasons',
    [RoutePathName.platformLotVisualization]: '/platform/lot-visualization',
    [RoutePathName.platformReception]: '/platform/reception/:platformReceptionId',
    [RoutePathName.platformLoadingDetails]: '/platform/loading/:loadingId',
    [RoutePathName.platformLoadingCustomerOrderDetails]: '/platform/loading/:loadingId/customerOrder/:customerOrderId',
    [RoutePathName.platformRdv]: '/platform/rendez-vous',
    [RoutePathName.platformRdvCustomerOrder]: '/platform/rendez-vous/customer-order/:customerOrderId',
    [RoutePathName.platformExpedition]: '/platform/expedition',

    // settings
    [RoutePathName.settings]: '/settings',
    [RoutePathName.incidentsList]: '/settings/incidents',
    [RoutePathName.machinesList]: '/settings/machines',
};

export interface RouteParams {
    [param: string]: string | number;
}

// returns raw react-router string path eg: /user/:id
export const getRawRoute = (path: RoutePathName) => {
    if (!routes[path]) {
        debug.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return '/404';
    } else {
        return routes[path];
    }
};

// returns real-world path eg: /user/1337
export const getRoute = (
    path: RoutePathName,
    params?: RouteParams,
    queryParams?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
    anchor?: string
) => {
    let route = getRawRoute(path);
    const rawParams = route.split('/').filter((str) => str.startsWith(':'));
    const providedParamNames = params ? Object.keys(params) : [];

    rawParams.forEach((rawParam) => {
        const isOptionnal = rawParam.endsWith('?');
        const rawParamStripped = isOptionnal ? rawParam.substring(1, rawParam.length - 1) : rawParam.substring(1);

        if (params && providedParamNames.includes(rawParamStripped)) {
            route = route.replace(rawParam, `${params[rawParamStripped]}`);
        } else if (isOptionnal) {
            debug.info(
                `[getRoute] Optionnal param "${rawParamStripped}" not provided in route "${route}", skipping param.`
            );
            route = route.replace(`/${rawParam}`, '');
        } else {
            throw new Error(`[getRoute] Param "${rawParamStripped}" not provided in route "${route}".`);
        }
    });

    if (queryParams && Object.keys(queryParams).length) {
        const urlQueryParams = new URLSearchParams(queryParams);
        route += `?${urlQueryParams.toString()}`;
    }

    if (anchor) {
        route = `${route}#${anchor}`;
    }

    return route;
};
