import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { infos as infosApiCall } from '../api/api';
import { ApiInfos } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// State

export type ApiState = RequestState<ApiInfos>;

const initialState: ApiState = {
    loading: false,
};

// Actions/Reducers

export const infos = new EzeeAsyncAction<ApiState>(
    'infos',
    initialState,
    requestReducer<ApiState, ApiInfos>(initialState)
);

// Reducer

export const apiReducer = infos.reducer;

// Saga

export function* apiSaga() {
    yield takeLatest(infos.type.trigger, simpleAsyncSaga(infosApiCall, infos));
}

export const getApiInfosState = (state: MainReducerState) => state.api;
