import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Divider, Form, Input, InputProps, Select, Spin, Typography } from 'antd';

import { Place, PlaceFilter, PlaceZoneType, TransferType } from '../../../store/api/apiTypes';
import { getPlacesListFiltersState, listFilters } from '../../../store/actions/places';

import { getRoute, RoutePathName } from '../../../routes';
import FixedFooter from '../../../components/FixedFooter';
import Seo from '../../../components/Seo';
import Header from '../../../components/Header';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonGrey from '../../../components/ButtonGrey';
import { stripUndefinedKeysFromObject, urlSearchParamsToObject } from '../../../helpers';
import PageHeader from '../../../components/PageHeader';
import { useActions, useIsMobile } from '../../../hooks';
import useQueryParams from '../../../hooks/queryParams';

type Filters = Pick<Place, 'sector' | 'lane' | 'alveole' | 'level'>;
const filterNames: Array<keyof Filters> = ['sector', 'lane', 'alveole', 'level'];
const filterTranslations = ['Secteur', 'Allée', 'Alvéole', 'Niveau'];

const PlacesFilter: FC = () => {
    const isMobile = useIsMobile();
    const history = useHistory();
    const location = useLocation<{ backRoute: RoutePathName; zoneType: PlaceZoneType; transferType: TransferType }>();
    const [queryParams] = useQueryParams('transferOrdersPlacesFilter');
    const [filters, setFilters] = useState<Filters>(urlSearchParamsToObject(queryParams));
    const [locker, setLocker] = useState<Place['locker']>();
    const fetchFilters = useActions(listFilters.trigger);
    const placesFiltersState = useSelector(getPlacesListFiltersState);

    const backRoute = getRoute(location.state?.backRoute);

    const onChangeSelect = (type: keyof Filters, value: string) => {
        const result = { ...filters };

        if (value !== undefined) {
            result[type] = `${value}`;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete result[type];
        }

        switch (type) {
            case 'sector':
                delete result.lane;
                delete result.alveole;
                delete result.level;
                break;

            case 'lane':
                delete result.alveole;
                delete result.level;
                break;

            case 'alveole':
                delete result.level;
                break;
        }

        setFilters(result);
    };
    const onDeleteFilters = () => {
        setFilters({});
    };
    const onValidateFilters = () => {
        stripUndefinedKeysFromObject(filters);
        const urlSearchParams = locker ? { locker: locker } : (filters as { [key: string]: string });
        history.push({
            pathname: backRoute,
            state: { ...location.state, step: undefined, afterFilter: true, backRoute: undefined },
            search: new URLSearchParams(urlSearchParams).toString(),
        });
    };

    const onChangeLocker: InputProps['onChange'] = (e) => {
        setLocker(e.target.value);
    };

    const isDisabled = (filter: keyof Filters) => {
        switch (filter) {
            case 'sector':
                return !!locker;
            case 'lane':
                return !filters.sector || !!locker;
            case 'alveole':
                return !filters.lane || !!locker;
            case 'level':
                return !filters.alveole || !!locker;
        }
    };

    useEffect(() => {
        fetchFilters({
            ...filters,
            zoneType: location.state?.zoneType,
            storageByAccumalation:
                location.state.zoneType === PlaceZoneType.mass && location.state.transferType !== TransferType.pallet,
        });
    }, [filters, fetchFilters, location.state.zoneType, location.state.transferType]);

    return (
        <>
            <Seo title="Filtrer" />
            <Header title="Filtrer" backRoute={{ pathname: backRoute, state: location.state }} />
            <PageHeader>
                <Spin spinning={placesFiltersState.loading}>
                    <Form layout="vertical">
                        <Form.Item label="Casier">
                            <ArrowNavItem>
                                <Input onChange={onChangeLocker} placeholder="Saisir un casier" />
                            </ArrowNavItem>
                        </Form.Item>
                        <Divider>
                            <Typography.Text className="text-primary" style={{ fontSize: '0.9375rem' }} strong>
                                OU
                            </Typography.Text>
                        </Divider>
                        {filterNames.map((filter, index) => (
                            <Form.Item label={filterTranslations[index]} key={filter}>
                                <ArrowNavItem>
                                    <Select
                                        value={
                                            typeof filters[filter] === 'number'
                                                ? `${filters[filter] ?? ''}`
                                                : filters[filter]
                                        }
                                        placeholder={`Sélectionnez un${
                                            filter === 'lane' || filter === 'alveole' ? 'e' : ''
                                        } ${filterTranslations[index].toLowerCase()}`}
                                        filterOption={false}
                                        onChange={onChangeSelect.bind(null, filter)}
                                        style={{ width: '100%' }}
                                        disabled={isDisabled(filter)}
                                        allowClear
                                        showArrow
                                    >
                                        {(
                                            placesFiltersState.data?.[`${filter}s` as keyof PlaceFilter] as Array<
                                                string | number
                                            >
                                        )?.map((option) => (
                                            <Select.Option value={option} key={option}>
                                                {option}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </ArrowNavItem>
                            </Form.Item>
                        ))}
                    </Form>
                </Spin>
                {!!Object.keys(filters).length && (
                    <ArrowNavItem>
                        <ButtonWithShortcut style={{ marginTop: '25px' }} shortcut="f2" onClick={onDeleteFilters} block>
                            Supprimer les filtres
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                )}
            </PageHeader>
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        shortcut="enter"
                        onClick={onValidateFilters}
                        as={ButtonGrey}
                        size={isMobile ? 'middle' : 'large'}
                        block
                    >
                        Filtrer les emplacements
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </>
    );
};

export default PlacesFilter;
