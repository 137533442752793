import { Switch, SwitchProps, Typography } from 'antd';
import React, { FC } from 'react';

const YesNoSwitch: FC<SwitchProps> = (props) => (
    <div>
        <Typography.Text
            type={props.checked === false ? undefined : 'secondary'}
            style={{
                display: 'inline-block',
                fontSize: '0.75rem',
                transition: 'all 0.2s ease',
                color: props.checked === false ? 'black' : undefined,
                width: 26,
            }}
            strong={props.checked === false}
        >
            NON
        </Typography.Text>
        <Switch style={{ margin: '0 0.5rem' }} {...props} />
        <Typography.Text
            type={props.checked === true ? 'success' : 'secondary'}
            style={{ display: 'inline-block', fontSize: '0.75rem', transition: 'all 0.2s ease', width: 20 }}
            strong={props.checked === true}
        >
            OUI
        </Typography.Text>
    </div>
);

export default YesNoSwitch;
