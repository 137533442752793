import { Form, FormProps, Input, message } from 'antd';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
    removePackage,
    removePackages,
    getControlOrderRemovePackagesState,
    getControlOrderRemovePackageState,
    getControlOrderControlState,
    PackageRef,
    control,
} from '../../store/actions/preparationControl';
import { ClientRegularization, WMSMode } from '../../store/api/apiTypes';

import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import QuantityCard from '../../components/QuantityCard';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { formatNumber, getRemovePackageGetError, getRemovePackagesGetError } from '../../helpers/i18n';
import { isControlPackageAlreadyStoredLocally } from '../../helpers';
import PrintModal from '../../components/PrintModal';
import SuccessModal from '../../components/SuccessModal';
import { getClientRegularizationStateById } from '../../store/actions/controlRegularization';
import { getRoute, RoutePathName } from '../../routes';
import { errorSound } from '../../helpers/sound';

const shortcutScope = 'ControlRegularizeScanModal';

interface ControlRegularizeScanModalProps extends CustomModalProps {
    onFinish: () => void;
    clientRegularizationId: ClientRegularization['id'];
}

const ControlRegularizeScanModal: FC<ControlRegularizeScanModalProps> = ({
    visible,
    onCancel,
    onFinish,
    clientRegularizationId,
}) => {
    const history = useHistory();
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const { loadingOrderId, customerOrderId: customerOrderIdParam } =
        useParams<{ loadingOrderId: string; customerOrderId: string }>();
    const customerOrderId = parseInt(customerOrderIdParam, 10);
    const [form] = Form.useForm();
    const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [isManual, setIsManual] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [successFooter, setSuccessFooter] = useState<ReactNode | null>(null);
    const [refToStore, setRefToStore] = useState<PackageRef>(); // temp data when waiting for API response
    const [barcodeToStore, setBarcodeToStore] = useState<string>(); // temp data when waiting for API response
    const [fetchRemovePackage, sendRemovePackages, setControlRemovePackageState, resetControlRemovePackageState] =
        useActions([
            removePackage.trigger,
            removePackages.trigger,
            control.actions.setRemovePackageData,
            control.actions.resetRemovePackage,
        ]);
    const clientRegularizationState = useSelector(getClientRegularizationStateById(clientRegularizationId));
    const removePackageState = useSelector(getControlOrderRemovePackageState);
    const removePackagesState = useSelector(getControlOrderRemovePackagesState);
    const { packagesRefs, packagesBarcodes } = useSelector(getControlOrderControlState).removePackage;
    const palletId = clientRegularizationState.data?.pallet?.id;
    const totalToRemove =
        (clientRegularizationState.data?.quantity ?? 0) - (clientRegularizationState.data?.quantityRemoved ?? 0);
    const previous = usePrevious({
        visible,
        removePackageState,
        removePackagesState,
        clientRegularizationState,
        isSuccessModalVisible,
    });
    const onClose = useCallback(() => {
        onCancel?.({} as React.MouseEvent<HTMLElement, MouseEvent>);
    }, [onCancel]);
    const onClickCancel = () => {
        onClose();
    };
    const onClosePrintModal = useCallback(() => {
        setIsPrintModalVisible(false);
    }, []);
    const onCloseSuccessModal = useCallback(() => {
        setIsSuccessModalVisible(false);
    }, []);
    const onPrintSuccess = useCallback(() => {
        setIsPrintModalVisible(false);
        setSuccessMessage('Palette traitée avec succès');
        setIsSuccessModalVisible(true);
        const timeout = window.setTimeout(() => {
            if (timeout) {
                setIsSuccessModalVisible(false);
            }
        }, 2000);

        return () => window.clearTimeout(timeout);
    }, []);
    const onClickSuccessClose = useCallback(() => {
        history.push(
            getRoute(RoutePathName.preparationControl, {
                loadingOrderId,
                customerOrderId,
            })
        );
    }, [customerOrderId, history, loadingOrderId]);
    const onSubmitManual: FormProps['onFinish'] = (values) => {
        if (isControlPackageAlreadyStoredLocally(packagesRefs, values)) {
            message.error('Ce colis est déjà dans la liste à supprimer');
        } else {
            setRefToStore({
                reference: values.reference,
                ...(values.cont ? { cont: values.cont } : {}),
            });
            fetchRemovePackage({
                customerOrderId,
                clientRegularizationId,
                reference: values.reference,
                ...(values.cont ? { cont: values.cont } : {}),
            });
        }
    };
    const onSubmit = () => {
        sendRemovePackages({
            customerOrderId,
            barcodes: packagesBarcodes.length ? packagesBarcodes : undefined,
            references: packagesRefs.length ? packagesRefs : undefined,
            clientRegularizationId,
            palletId,
        });
    };
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (packagesBarcodes.includes(barCode.data)) {
                    message.error('Ce colis est déjà dans la liste à supprimer');
                    errorSound();
                } else {
                    setBarcodeToStore(barCode.data);
                    fetchRemovePackage({
                        customerOrderId,
                        clientRegularizationId,
                        barcode: barCode.data,
                    });
                }
            }
        },
        {
            deps: [fetchRemovePackage, packagesBarcodes, customerOrderId, clientRegularizationId],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    useEffect(() => {
        if (visible && previous?.removePackageState.loading && !removePackageState.loading) {
            if (removePackageState.error) {
                const requestError = getRemovePackageGetError(removePackageState);

                if (requestError.message) {
                    message.error(requestError.message);
                }
                errorSound();
            } else if (removePackageState.data) {
                setControlRemovePackageState({
                    packagesBarcodes: barcodeToStore ? [...packagesBarcodes, barcodeToStore] : packagesBarcodes,
                    packagesRefs: refToStore ? [...packagesRefs, refToStore] : packagesRefs,
                });

                form.resetFields();
                setIsManual(false);
                setBarcodeToStore(undefined);
                setRefToStore(undefined);
            }
        }
    }, [
        previous?.removePackageState.loading,
        removePackageState,
        barcodeToStore,
        refToStore,
        form,
        packagesBarcodes,
        packagesRefs,
        setControlRemovePackageState,
        visible,
    ]);

    useEffect(() => {
        if (visible && previous?.removePackagesState.loading && !removePackagesState.loading) {
            if (removePackagesState.error) {
                const requestError = getRemovePackagesGetError(removePackagesState);

                if (requestError.message) {
                    message.error(requestError.message);
                }
            } else {
                if (removePackagesState.data) {
                    // if the total amount removed is the same as the pallet quantity, it means the pallet has been deleted server side.
                    if (
                        packagesRefs.length + packagesBarcodes.length ===
                        clientRegularizationState.data?.pallet?.quantity
                    ) {
                        onClose();
                        setSuccessMessage('Palette supprimée avec succès');
                        setIsSuccessModalVisible(true);
                    } else {
                        onClose();
                        setSuccessMessage('Les colis ont été retirés');
                        setIsSuccessModalVisible(true);
                    }
                } else {
                    message.error("Une erreur est survenue pendant l'ajout de colis");
                }
            }
        }
    }, [
        previous?.removePackagesState.loading,
        removePackagesState.loading,
        removePackagesState.error,
        removePackagesState.data,
        packagesRefs.length,
        packagesBarcodes.length,
        clientRegularizationState.data?.pallet?.quantity,
        onClose,
        removePackagesState,
        visible,
    ]);

    // display success modal when clientRegularization is completed
    useEffect(() => {
        if (visible && previous?.clientRegularizationState.loading && !clientRegularizationState.loading) {
            if (!clientRegularizationState.error) {
                setSuccessMessage(
                    'Régularisation effectuée. Merci de mettre les colis de côté, un ordre de transfert va être créé.'
                );
                setSuccessFooter(
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClickSuccessClose}
                            ghost
                        >
                            Fermer
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                );
                setIsSuccessModalVisible(true);
            }
        }
    }, [
        previous?.clientRegularizationState.loading,
        clientRegularizationState.loading,
        clientRegularizationState.error,
        onClickSuccessClose,
        visible,
    ]);

    useEffect(() => {
        if (!visible) {
            setBarcodeToStore(undefined);
            setRefToStore(undefined);
        }
    }, [visible, setBarcodeToStore, setRefToStore]);

    useEffect(() => {
        let timeout: number;

        if (!previous?.isSuccessModalVisible && isSuccessModalVisible) {
            timeout = window.setTimeout(() => {
                if (timeout) {
                    onFinish();
                    setIsSuccessModalVisible(false);

                    if (
                        packagesRefs.length + packagesBarcodes.length > 0 &&
                        packagesRefs.length + packagesBarcodes.length <
                            (clientRegularizationState.data?.pallet?.quantity ?? 0)
                    ) {
                        setIsPrintModalVisible(true);
                    }

                    resetControlRemovePackageState([]);
                }
            }, 2000);
        }

        return () => {
            window.clearTimeout(timeout);
        };
    }, [
        isSuccessModalVisible,
        onFinish,
        previous?.isSuccessModalVisible,
        packagesRefs.length,
        packagesBarcodes.length,
        clientRegularizationState.data?.pallet?.quantity,
        resetControlRemovePackageState,
    ]);

    return (
        <>
            <CustomModal
                footer={
                    isManual ? (
                        <>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="enter"
                                    type="primary"
                                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                    shortcutScope={shortcutScope}
                                    onClick={() => form.submit()}
                                    loading={removePackageState.loading}
                                >
                                    Valider
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="esc"
                                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                    onClick={setIsManual.bind(null, false)}
                                    ghost
                                >
                                    Annuler
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </>
                    ) : (
                        <>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="enter"
                                    type="primary"
                                    shortcutScope={shortcutScope}
                                    onClick={onSubmit}
                                    loading={removePackagesState.loading}
                                >
                                    Valider
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="f1"
                                    type="primary"
                                    shortcutScope={shortcutScope}
                                    onClick={setIsManual.bind(null, true)}
                                    ghost
                                >
                                    Saisir manuellement
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="esc"
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                    onClick={onClickCancel}
                                    ghost
                                >
                                    Annuler
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </>
                    )
                }
                visible={visible}
                onCancel={onCancel}
                title={isManual ? 'Saisir manuellement' : 'Veuillez scanner individuellement les colis à retirer :'}
                width={368}
                keyboard={false}
                transitionName=""
                maskTransitionName=""
                altTitle={isManual}
            >
                {isManual ? (
                    <Form form={form} onFinish={onSubmitManual}>
                        <Form.Item
                            name="reference"
                            rules={[{ required: true, message: 'Veuillez saisir une référence' }]}
                        >
                            <ArrowNavItem scope={shortcutScope}>
                                <Input placeholder="Saisir référence colis" autoFocus />
                            </ArrowNavItem>
                        </Form.Item>
                        <Form.Item
                            name="cont"
                            rules={[{ required: true, message: 'Veuillez saisir un CONT' }]}
                            style={{ marginBottom: 0 }}
                        >
                            <ArrowNavItem scope={shortcutScope}>
                                <Input placeholder="Saisir CONT" />
                            </ArrowNavItem>
                        </Form.Item>
                    </Form>
                ) : (
                    <QuantityCard
                        value={formatNumber(packagesRefs.length + packagesBarcodes.length)}
                        label={`colis scannés sur ${formatNumber(totalToRemove)}`}
                        outline
                    />
                )}
            </CustomModal>
            <PrintModal
                visible={isPrintModalVisible}
                onCancel={onClosePrintModal}
                cancellable={false}
                palletId={palletId}
                onSuccess={onPrintSuccess}
                mode={WMSMode.preparation}
            />
            <SuccessModal
                visible={isSuccessModalVisible}
                onCancel={onCloseSuccessModal}
                message={successMessage}
                footer={successFooter}
            />
        </>
    );
};

export default ControlRegularizeScanModal;
