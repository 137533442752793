import { SearchPaginationQuery } from '.';
import { LoadingOrder } from './apiTypes';
import api from './_client';

export type LoadingOrderListPayload = SearchPaginationQuery;
export const list = async (payload: LoadingOrderListPayload = {}) =>
    await api.get('/loadingOrders', { params: payload });

export interface LoadingOrderDetailsPayload {
    loadingOrderId: LoadingOrder['id'];
}
export const details = async ({ loadingOrderId, ...payload }: LoadingOrderDetailsPayload) =>
    await api.get(`/loadingOrders/${loadingOrderId}`, { params: payload });

export type LoadingOrderUpdatePayload = LoadingOrder;
export const update = async (payload: LoadingOrderUpdatePayload) =>
    await api.put(`/loadingOrders/${payload.id}`, payload);

export interface LoadingOrderOrderListPayload {
    loadingOrderId: LoadingOrder['id'];
}
export const listOrders = async ({ loadingOrderId, ...payload }: LoadingOrderDetailsPayload) =>
    await api.get(`/loadingOrders/${loadingOrderId}/orders`, { params: payload });

export interface LoadingOrderOrderRemovePayload {
    loadingOrderId: LoadingOrder['id'];
}
export const del = async ({ loadingOrderId }: LoadingOrderOrderRemovePayload) => {
    await api.delete(`/loadingOrders/${loadingOrderId}`);
};
