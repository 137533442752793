import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { getUser } from '../../store/actions/auth';

import Seo from '../../components/Seo';
import MainMenu from '../../components/MainMenu';
import Header from '../../components/Header';
import { hasRole } from '../../helpers/security';
import { RoleName } from '../../store/api/apiTypes';
import { Redirect } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';

const Home: FC = () => {
    const user = useSelector(getUser);

    if (user && hasRole(user, [RoleName.pfUser])) {
        return <Redirect to={getRoute(RoutePathName.platformHome)} />;
    }

    return (
        <>
            <Seo title="Accueil" />
            <Header />
            <MainMenu />
        </>
    );
};

export default Home;
