import { SearchPaginationQuery } from '.';
import { Customer, CustomerOrder, Expedition, LoadingOrder, Pallet } from './apiTypes';
import api from './_client';

export type ExpeditionListPayload = SearchPaginationQuery &
    Pick<Expedition, 'deliveryDate'> &
    Pick<CustomerOrder, 'day' | 'tour'> &
    Pick<LoadingOrder, 'plateformCode'> & {
        customerReference: Customer['reference'];
        palletStatus: Pallet['status'];
    };
export const list = async (payload: ExpeditionListPayload) => await api.get('/expeditions', { params: payload });

export interface ExpeditionDetailsPayload {
    expeditionId: Expedition['id'];
}
export const details = async ({ expeditionId, ...payload }: ExpeditionDetailsPayload) =>
    await api.get(`/expeditions/${expeditionId}`, { params: payload });
