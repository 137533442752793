import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { PlaceStore } from '../api/apiTypes';
import { ListResponse } from '../api';

// Controlers
import {
    PlaceStoreListPayload,
    list as listApiCall,
    PlaceStoreCreatePayload,
    create as createApiCall,
    PlaceStoreUpdatePayload,
    update as updateApiCall,
    PlaceStoreDeletePayload,
    del as delApiCall,
} from '../api/placeStores';

// States
export interface PlaceStoresState {
    list: RequestState<ListResponse<PlaceStore>>;
    create: RequestState<PlaceStore>;
    update: RequestState<PlaceStore>;
    del: RequestState;
}

const initialState: PlaceStoresState = {
    list: { ...requestInitialState },
    create: { ...requestInitialState },
    update: { ...requestInitialState },
    del: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<PlaceStoresState['list'], PlaceStoreListPayload, ListResponse<PlaceStore>>(
    'placeStores/list',
    initialState.list,
    requestReducer<PlaceStoresState['list'], ListResponse<PlaceStore>>(initialState.list)
);

export const create = new EzeeAsyncAction<PlaceStoresState['create'], PlaceStoreCreatePayload, PlaceStore>(
    'placeStores/create',
    initialState.create,
    requestReducer<PlaceStoresState['create'], PlaceStore>(initialState.create)
);

export const update = new EzeeAsyncAction<PlaceStoresState['update'], PlaceStoreUpdatePayload, PlaceStore>(
    'placeStores/update',
    initialState.update,
    requestReducer<PlaceStoresState['update'], PlaceStore>(initialState.update)
);

export const del = new EzeeAsyncAction<PlaceStoresState['del'], PlaceStoreDeletePayload>(
    'placeStores/del',
    initialState.del,
    requestReducer<PlaceStoresState['del'], PlaceStore>(initialState.del)
);

// Reducer
export const placeStoresReducer = combineReducers<PlaceStoresState>({
    list: list.reducer,
    create: create.reducer,
    update: update.reducer,
    del: del.reducer,
});

// Saga
export function* placeStoresSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
}

// Store helpers
export const getPlaceStoresListState = (state: MainReducerState) => state.placeStores.list;
export const getPlaceStoreCreateState = (state: MainReducerState) => state.placeStores.create;
export const getPlaceStoreUpdateState = (state: MainReducerState) => state.placeStores.update;
export const getPlaceStoreDeleteState = (state: MainReducerState) => state.placeStores.del;
