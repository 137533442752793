import React, {
    DetailedHTMLProps,
    HTMLAttributes,
    forwardRef,
    ForwardRefRenderFunction,
    Children,
    isValidElement,
    cloneElement,
} from 'react';

import '../assets/styles/BasicList.less';

import { classNames } from '../helpers';

interface BasicListProps extends DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement> {
    inline?: boolean;
    inlineStretch?: boolean;
    grid?: boolean;
}

const BasicList: ForwardRefRenderFunction<HTMLUListElement, BasicListProps> = (
    { children, className, grid, inline, inlineStretch },
    ref
) => (
    <ul
        className={classNames('basic-list', inline && 'basic-list-inline', grid && 'basic-list-grid', className)}
        ref={ref}
    >
        {inlineStretch && children !== null && children !== undefined
            ? Children.map(children, (child) => {
                  if (isValidElement(child)) {
                      return cloneElement(child, {
                          style: {
                              flex: `1 1 ${100 / Children.count(children)}%`,
                              minWidth: 0,
                          },
                      });
                  }
                  return child;
              })
            : children}
    </ul>
);

export default forwardRef(BasicList);
