import React, { FC } from 'react';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useArrowNavScope, useShortcutScope } from '../../../hooks';
import { PlaceZoneType, TransferType } from '../../../store/api/apiTypes';
import { formatZoneType, translatePlaceZoneType } from '../../../helpers/i18n';

const shortcutScope = 'FinishZoneType';
interface FinishZoneTypeModalProps extends CustomModalProps {
    onSelect: (type: PlaceZoneType | undefined) => void;
    onReturn: () => void;
    transferType?: TransferType;
    startZoneType?: PlaceZoneType;
}

const FinishZoneTypeModal: FC<FinishZoneTypeModalProps> = ({
    visible,
    onSelect,
    onReturn,
    transferType,
    startZoneType,
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    return (
        <CustomModal
            visible={visible}
            title="zone d'arrivée"
            width={368}
            keyboard={false}
            altTitle
            transitionName=""
            maskTransitionName=""
            footer={
                <>
                    {startZoneType === PlaceZoneType.picking ? (
                        <>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="1"
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                    onClick={onSelect.bind(null, PlaceZoneType.mass)}
                                >
                                    {translatePlaceZoneType(PlaceZoneType.mass)}
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="2"
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                    onClick={onSelect.bind(null, PlaceZoneType.accumulation)}
                                >
                                    {translatePlaceZoneType(PlaceZoneType.accumulation)}
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </>
                    ) : transferType === TransferType.pallet ? (
                        <>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="2"
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                    onClick={onSelect.bind(null, PlaceZoneType.mass)}
                                >
                                    {translatePlaceZoneType(PlaceZoneType.mass)}
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="3"
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                    onClick={onSelect.bind(null, PlaceZoneType.accumulation)}
                                >
                                    {translatePlaceZoneType(PlaceZoneType.accumulation)}
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </>
                    ) : (
                        <>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="1"
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                    onClick={onSelect.bind(null, PlaceZoneType.picking)}
                                >
                                    {translatePlaceZoneType(PlaceZoneType.picking)}
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="2"
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                    onClick={onSelect.bind(null, PlaceZoneType.mass)}
                                >
                                    {formatZoneType(PlaceZoneType.mass)}
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </>
                    )}
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onReturn}
                            ghost
                        >
                            Retour
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
        ></CustomModal>
    );
};

export default FinishZoneTypeModal;
