import { Input, InputProps, message } from 'antd';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { create, getControlLoadingOrderCreateState } from '../../store/actions/controlLoadingOrders';

import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import { isZebra } from '../../helpers/enterprise-browser';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';

const shortcutScope = 'SearchControlLoadingOrderModal';

const SearchControlLoadingOrderModal: FC<CustomModalProps> = ({ visible, onCancel }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const history = useHistory();
    const createControlLoadingOrder = useActions(create.trigger);
    const [tourNumber, setTourNumber] = useState<string>();
    const controlLoadingOrderCreateState = useSelector(getControlLoadingOrderCreateState);
    const previous = usePrevious({ controlLoadingOrderCreateState });
    const inputRef = useRef<Input | null>(null);
    const isMounted = useIsMounted();
    const onSubmit = () => {
        createControlLoadingOrder({ tourNumber });
    };
    const onChangeField: InputProps['onChange'] = (e) => {
        setTourNumber(e.target.value);
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                createControlLoadingOrder({ tourNumber: barCode.data });
            }
        },
        {
            deps: [setTourNumber],
            disable: !visible,
        }
    );

    useEffect(() => {
        if (!visible) {
            setTourNumber(undefined);
            if (inputRef.current) {
                inputRef.current.blur();
            }
        } else {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [visible, setTourNumber]);

    useEffect(() => {
        if (visible && previous?.controlLoadingOrderCreateState.loading && !controlLoadingOrderCreateState.loading) {
            if (controlLoadingOrderCreateState.error) {
                if (controlLoadingOrderCreateState.error?.status === 400) {
                    if (controlLoadingOrderCreateState.error?.data?.laodingOrderNotCompleted) {
                        message.error("La préparation/contrôle de l'OC n'est pas encore terminée");
                    } else if (controlLoadingOrderCreateState.error?.data?.operatorAlreadyAssigned) {
                        message.error('Cet OC est déjà assigné à un autre chargeur');
                    } else {
                        message.error("Une erreur est survenue lors de la recherche d'OC (400)");
                    }
                } else if (controlLoadingOrderCreateState.error?.status === 404) {
                    message.error('OC non trouvé');
                } else {
                    message.error(
                        `Une erreur est survenue lors de la recherche d'OC (${
                            controlLoadingOrderCreateState.error?.status ?? '0'
                        })`
                    );
                }
            } else {
                onClose();
                history.push(
                    getRoute(RoutePathName.controlLoadingOrderDetails, {
                        controlLoadingOrderId: controlLoadingOrderCreateState.data?.id ?? '',
                    })
                );
            }
        }
    }, [
        previous?.controlLoadingOrderCreateState,
        controlLoadingOrderCreateState.loading,
        controlLoadingOrderCreateState.error,
        controlLoadingOrderCreateState.data?.id,
        isMounted,
        onClose,
        history,
        visible,
    ]);

    return (
        <CustomModal
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onSubmit}
                            loading={controlLoadingOrderCreateState.loading}
                            disabled={!tourNumber}
                        >
                            Rechercher l&rsquo;OC
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClose}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            visible={visible}
            onCancel={onCancel}
            title="Ordre de chargement"
            width={368}
            keyboard={false}
            altTitle
        >
            <ArrowNavItem scope={shortcutScope}>
                <Input
                    placeholder={isZebra ? 'Flasher/entrer le n° de tournée' : 'Entrer le n° de tournée'}
                    value={tourNumber}
                    onChange={onChangeField}
                    ref={inputRef}
                    autoFocus
                />
            </ArrowNavItem>
        </CustomModal>
    );
};

export default SearchControlLoadingOrderModal;
