import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge, Form, message, Select, SelectProps, Spin, PaginationProps } from 'antd';

import {
    Permission,
    PermissionRight,
    Place,
    PlaceCapacity,
    PlaceZoneType,
    ResupplyOrder,
    ResupplyOrderStatus,
    ResupplyOrderType,
} from '../../store/api/apiTypes';
import {
    getResupplyOrderListState,
    getResupplyOrderUpdateState,
    list,
    update,
    listPolling as resupplyOrderListPolling,
} from '../../store/actions/resupplyOrders';
import { getUser } from '../../store/actions/auth';
import { getPlacesListState, list as placesList } from '../../store/actions/places';

import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonGrey from '../../components/ButtonGrey';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import FixedFooter from '../../components/FixedFooter';
import Header from '../../components/Header';
import List, { ListColumn } from '../../components/list/List';
import Seo from '../../components/Seo';
import { useActions, useArrowNavScope, useIsDesktop, useIsMobile, usePrevious } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import ResupplyOrderModal from './ResupplyOrderModal';
import { formatDate, translateResupplyOrderStatus, translateResupplyOrderType } from '../../helpers/i18n';
import CustomModal from '../../components/CustomModal';
import BasicList from '../../components/BasicList';
import { isZebra } from '../../helpers/enterprise-browser';
import { getOperatorName } from '../../helpers';
import SortableList from '../../components/list/SortableList';
import { hasPermission } from '../../helpers/security';
import useQueryParams from '../../hooks/queryParams';

const filterScope = 'filterModal';

const ResupplyOrderList: FC = () => {
    const isMobile = useIsMobile();
    const isDesktop = useIsDesktop();
    const history = useHistory();
    const [queryParams, setQueryParams] = useQueryParams('ResupplyOrderList');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const [isOrderModalVisible, setIsOrderModalVisible] = useState(false);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [isReordering, setIsReordering] = useState(false);
    const [type, setType] = useState<ResupplyOrderType | 'all'>('all');
    const [placeId, setPlaceId] = useState<Place['id']>();
    const [fetchResupplyOrders, fetchPlaces, updateResupplyOrder, stopResupplyOrderListPolling] = useActions([
        list.trigger,
        placesList.trigger,
        update.trigger,
        resupplyOrderListPolling.actions.stopPolling,
    ]);
    const user = useSelector(getUser);
    const canReadDesktop = !isZebra && hasPermission(user, Permission.resupply);
    const canEditDesktop = !isZebra && hasPermission(user, Permission.resupply, PermissionRight.write);
    const orderListState = useSelector(getResupplyOrderListState);
    const orderUpdateState = useSelector(getResupplyOrderUpdateState);
    const placesListState = useSelector(getPlacesListState);
    const previous = usePrevious({ orderUpdateState });
    useArrowNavScope(filterScope, !isFilterModalOpen);
    const onClickOrder = (record: ResupplyOrder) => {
        if (canReadDesktop) {
            history.push(getRoute(RoutePathName.resupplyOrderDetails, { resupplyOrderId: record.id }));
        } else {
            // this happens when the resupplyOrder is in progress and has been unblocked by an admin
            // so we have to reassign it to the current user that clicked on it
            const isInProgressWithNoOperator = record.status === ResupplyOrderStatus.inProgress && !record.operator;

            if (user?.id === record.operator?.id) {
                history.push(getRoute(RoutePathName.resupplyOrderDetails, { resupplyOrderId: record.id }));
            } else if (
                record.status === ResupplyOrderStatus.toBeProcessed ||
                isInProgressWithNoOperator ||
                !record.operator
            ) {
                updateResupplyOrder({
                    id: record.id,
                    operatorId: user?.id,
                    status: ResupplyOrderStatus.inProgress,
                });
            }
        }
    };
    const onPlaceSearch: SelectProps<Place['id']>['onSearch'] = (value) => {
        fetchPlaces(
            { size: PlaceCapacity.large, zoneType: PlaceZoneType.preparation, ...(value ? { search: value } : {}) },
            { throttling: 300 }
        );
    };
    const onFilter = () => {
        setIsFilterModalOpen(false);
        fetchResupplyOrders({
            status: [ResupplyOrderStatus.inProgress, ResupplyOrderStatus.toBeProcessed],
            ...(type !== 'all' ? { type } : {}),
            placeId,
        });
    };
    const onResetFilters = () => {
        setIsFilterModalOpen(false);
        setType('all');
        setPlaceId(undefined);
        fetchResupplyOrders({
            status: [ResupplyOrderStatus.inProgress, ResupplyOrderStatus.toBeProcessed],
        });
    };
    const onChangeOrder = (item: ResupplyOrder & { newIndex: number }) => {
        updateResupplyOrder({
            id: item.id,
            index: item.newIndex ?? 0,
        });
    };
    const onValidateReorder = () => {
        setIsReordering(false);
    };
    const columns: Array<ListColumn<ResupplyOrder>> = (
        [
            {
                key: 'reference',
                title: 'Réference',
                flex: '1 1 30%',
                render: (record) => record.parcel.reference,
                cellStyle: {
                    alignItems: 'flex-start',
                },
            },
            {
                key: 'date',
                title: 'Date',
                flex: '1 1 30%',
                render: (record) => formatDate(record.date),
                cellStyle: {
                    alignItems: 'flex-start',
                },
            },
            {
                key: 'time',
                title: 'Heure',
                flex: '1 1 30%',
                render: (record) =>
                    formatDate(record.date, {
                        minute: '2-digit',
                        hour: '2-digit',
                    }),
                cellStyle: {
                    alignItems: 'flex-start',
                },
            },
            {
                key: 'type',
                title: 'Type',
                flex: '1 1 30%',
                render: (record) => translateResupplyOrderType(record.type, true),
                cellStyle: {
                    alignItems: 'flex-start',
                },
            },
            {
                key: 'status',
                title: 'Statut',
                flex: isZebra ? '2 1 60%' : '1 1 30%',
                render: (record) => translateResupplyOrderStatus(record.status),
                cellStyle: {
                    alignItems: 'flex-start',
                },
            },
            canReadDesktop && {
                key: 'operator',
                title: 'Cariste',
                flex: '1 1 30%',
                render: (record) => getOperatorName(record.operator) || '—',
                cellStyle: {
                    alignItems: 'flex-start',
                },
            },
        ] as Array<ListColumn<ResupplyOrder>>
    ).filter(Boolean);
    const onCloseOrderModal = () => {
        setIsOrderModalVisible(false);
        fetchResupplyOrderList();
    };
    const onPaginationChange: PaginationProps['onChange'] = (p) => {
        setQueryParams({
            page: p ? p - 1 : 0,
        });
    };
    const fetchResupplyOrderList = useCallback(() => {
        fetchResupplyOrders(
            {
                status: [ResupplyOrderStatus.inProgress, ResupplyOrderStatus.toBeProcessed],
                sort: 'index',
                sortOrder: 'asc',
                page,
            },
            {
                poll: true,
            }
        );
    }, [fetchResupplyOrders, page]);

    useEffect(() => {
        if (previous?.orderUpdateState.loading && !orderUpdateState.loading) {
            if (orderUpdateState.error) {
                if (orderUpdateState.error?.data?.operatorAssigned) {
                    message.error('Un cariste est déjà assigné à cet OR, veuillez en choisir un autre.');
                    fetchResupplyOrderList();
                } else {
                    message.error("Une erreur est survenue lors de la mise à jour de l'OR");
                }
            } else {
                // when we update resupplyOrder when reordering; update the list of resupplyOrders
                if (isReordering) {
                    fetchResupplyOrderList();
                } else {
                    // when we update resupplyOrder when NOT reordering; send to details page
                    history.push(
                        getRoute(RoutePathName.resupplyOrderDetails, {
                            resupplyOrderId: orderUpdateState.data?.id ?? '',
                        })
                    );
                }
            }
        }
    }, [
        previous?.orderUpdateState.loading,
        orderUpdateState.loading,
        orderUpdateState.error,
        orderUpdateState.data?.id,
        history,
        isReordering,
        fetchResupplyOrderList,
    ]);

    useEffect(() => {
        fetchPlaces({ size: PlaceCapacity.large, zoneType: PlaceZoneType.preparation });
        fetchResupplyOrderList();
    }, [fetchPlaces, fetchResupplyOrderList]);

    useEffect(
        () => () => {
            stopResupplyOrderListPolling();
        },
        [stopResupplyOrderListPolling]
    );

    return (
        <FixedFooter.Wrapper>
            <Seo title="Réapprovisionnement" />
            <Header title="Réapprovisionnement" backRoute={getRoute(RoutePathName.home)} />
            {isReordering ? (
                <SortableList<ResupplyOrder>
                    columns={columns}
                    data={orderListState.data?.items}
                    rowKey={(record) => `${record.id}`}
                    isLoading={orderListState.loading}
                    isDragDisabled={(record) => !!record.operator}
                    onOrderChange={onChangeOrder}
                    title="Ordres de réappro. / Ordres direct"
                    style={isMobile ? { marginTop: 24 } : undefined}
                />
            ) : (
                <Spin spinning={orderUpdateState.loading}>
                    <List<ResupplyOrder>
                        columns={columns}
                        data={orderListState.data?.items}
                        rowKey={(record) => `${record.id}`}
                        onRowEnterPress={onClickOrder}
                        onRowClick={onClickOrder}
                        isLoading={orderListState.loading}
                        isRowLocked={(record) =>
                            !canEditDesktop &&
                            (record.status === ResupplyOrderStatus.toBeProcessed ||
                                record.status === ResupplyOrderStatus.inProgress) &&
                            !!record.operator &&
                            user?.id !== record.operator?.id
                        }
                        title="Ordres de réappro. / Ordres direct"
                        style={isMobile ? { marginTop: 24 } : undefined}
                        disableArrowNav={isOrderModalVisible}
                        loadingRowsCount={12}
                        headerInCell
                        pagination={
                            (orderListState.data?.pageCount ?? 0) > 1
                                ? {
                                      total: orderListState.data?.totalCount,
                                      pageSize: orderListState.data?.pageSize,
                                      hideOnSinglePage: true,
                                      current: page + 1,
                                      onChange: onPaginationChange,
                                  }
                                : undefined
                        }
                    />
                </Spin>
            )}
            <FixedFooter>
                {isReordering ? (
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            shortcut="enter"
                            size={isMobile ? 'middle' : 'large'}
                            as={ButtonGrey}
                            onClick={onValidateReorder}
                            block
                        >
                            Terminer
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                ) : (
                    <BasicList inline={isDesktop} inlineStretch={isDesktop}>
                        <li>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    shortcut="f1"
                                    size={isMobile ? 'middle' : 'large'}
                                    as={ButtonGrey}
                                    onClick={setIsOrderModalVisible.bind(null, true)}
                                    block
                                >
                                    Déclencher un OR
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </li>
                        <li>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    shortcut="f2"
                                    onClick={setIsFilterModalOpen.bind(null, true)}
                                    size={isMobile ? 'middle' : 'large'}
                                    block
                                >
                                    Filtrer&nbsp;
                                    <Badge count={(type !== 'all' ? 1 : 0) + (placeId ? 1 : 0)} />
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </li>
                        {canEditDesktop && (
                            <li>
                                <ArrowNavItem>
                                    <ButtonWithShortcut
                                        shortcut="f3"
                                        onClick={setIsReordering.bind(null, true)}
                                        size={isMobile ? 'middle' : 'large'}
                                        block
                                    >
                                        Réordonner
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </li>
                        )}
                    </BasicList>
                )}
            </FixedFooter>
            <ResupplyOrderModal visible={isOrderModalVisible} onCancel={onCloseOrderModal} />
            <CustomModal
                visible={isFilterModalOpen}
                onCancel={setIsFilterModalOpen.bind(null, false)}
                title="Filtrer"
                footer={
                    <>
                        <ArrowNavItem scope={filterScope}>
                            <ButtonWithShortcut
                                type="primary"
                                shortcut="enter"
                                as={ButtonGrey}
                                shortcutScope={filterScope}
                                onClick={onFilter}
                            >
                                Filtrer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        {(type !== 'all' || placeId) && (
                            <ArrowNavItem scope={filterScope}>
                                <ButtonWithShortcut
                                    type="primary"
                                    shortcut="f1"
                                    shortcutScope={filterScope}
                                    onClick={onResetFilters}
                                    ghost
                                >
                                    Supprimer les filtres
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        )}
                    </>
                }
                width={isMobile ? '100%' : undefined}
                fullScreen={isMobile}
                altTitle={!isMobile}
                closable
            >
                <Form.Item label="Type d'ordre" labelCol={{ span: 24 }}>
                    <ArrowNavItem scope={filterScope}>
                        <Select style={{ width: '100%' }} value={type} onChange={setType}>
                            <Select.Option value="all">Tous</Select.Option>
                            {Object.values(ResupplyOrderType).map((t) => (
                                <Select.Option value={t} key={t}>
                                    {translateResupplyOrderType(t)}
                                </Select.Option>
                            ))}
                        </Select>
                    </ArrowNavItem>
                </Form.Item>
                <Form.Item label="Emplacement de préparation" labelCol={{ span: 24 }}>
                    <ArrowNavItem scope={filterScope}>
                        <Select
                            style={{ width: '100%' }}
                            value={placeId}
                            onChange={setPlaceId}
                            filterOption={false}
                            onSearch={onPlaceSearch}
                            placeholder="Choisir un emplacement"
                            loading={placesListState.loading}
                            allowClear
                            showSearch
                        >
                            {placesListState.data?.items.map((place) => (
                                <Select.Option value={place.id} key={place.id}>
                                    {place.locker}
                                </Select.Option>
                            ))}
                        </Select>
                    </ArrowNavItem>
                </Form.Item>
            </CustomModal>
        </FixedFooter.Wrapper>
    );
};

export default ResupplyOrderList;
