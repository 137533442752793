import { combineReducers } from 'redux';
import { takeLatest, all, call, put } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { Order, OrderFurniture, OrderParcel, User } from '../api/apiTypes';
import { ListResponse } from '../api/';

// Controlers
import {
    OrderListPayload,
    list as listApiCall,
    OrderDetailsPayload,
    details as detailsApiCall,
    OrderUpdatePayload,
    update as updateApiCall,
    OrderOperatorListPayload,
    operatorList as operatorListApiCall,
    OrderDeletePayload,
    del as delApiCall,
} from '../api/orders';
import { del as delOrderParcelApiCall } from '../api/orderParcels';
import { del as delOrderFurnitureApiCall } from '../api/orderFurnitures';
import { DataAction } from '../helpers/EzeeAction';

// States
export interface OrdersState {
    list: RequestState<ListResponse<Order>>;
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: Order | undefined;
        };
    };
    update: RequestState<Order>;
    operatorList: RequestState<ListResponse<User>>;
    del: RequestState;
    delOrderParcelsAndOrderFurnitures: RequestState;
}

const initialState: OrdersState = {
    list: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
    update: { ...requestInitialState },
    operatorList: { ...requestInitialState },
    del: { ...requestInitialState },
    delOrderParcelsAndOrderFurnitures: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<OrdersState['list'], OrderListPayload, ListResponse<Order>>(
    'orders/list',
    initialState.list,
    requestReducer<OrdersState['list'], ListResponse<Order>>(initialState.list)
);

export const details = new EzeeAsyncAction<OrdersState['details'], OrderDetailsPayload, Order>(
    'orders/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

export const update = new EzeeAsyncAction<OrdersState['update'], OrderUpdatePayload, Order>(
    'orders/update',
    initialState.update,
    requestReducer<OrdersState['update'], Order>(initialState.update)
);

export const operatorList = new EzeeAsyncAction<
    OrdersState['operatorList'],
    OrderOperatorListPayload,
    ListResponse<User>
>(
    'orders/operatorList',
    initialState.operatorList,
    requestReducer<OrdersState['operatorList'], ListResponse<User>>(initialState.operatorList)
);

export const del = new EzeeAsyncAction<OrdersState['del'], OrderDeletePayload>(
    'orders/del',
    initialState.del,
    requestReducer<OrdersState['del']>(initialState.del)
);

export interface OrderParcelsAndOrderFurnituresDeletePayload {
    orderParcelIds?: Array<OrderParcel['id']>;
    orderFurnitureIds?: Array<OrderFurniture['id']>;
}
export const delOrderParcelsAndOrderFurnitures = new EzeeAsyncAction<
    OrdersState['delOrderParcelsAndOrderFurnitures'],
    OrderParcelsAndOrderFurnituresDeletePayload
>(
    'orders/delOrderParcelsAndOrderFurnitures',
    initialState.delOrderParcelsAndOrderFurnitures,
    requestReducer<OrdersState['delOrderParcelsAndOrderFurnitures']>(initialState.delOrderParcelsAndOrderFurnitures)
);

// Reducer
export const ordersReducer = combineReducers<OrdersState>({
    list: list.reducer,
    details: details.reducer,
    update: update.reducer,
    operatorList: operatorList.reducer,
    del: del.reducer,
    delOrderParcelsAndOrderFurnitures: delOrderParcelsAndOrderFurnitures.reducer,
});

// Saga
export function* ordersSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(operatorList.type.trigger, simpleAsyncSaga(operatorListApiCall, operatorList));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
    yield takeLatest(delOrderParcelsAndOrderFurnitures.type.trigger, delOrderParcelsAndOrderFurnituresSaga);
}

function* delOrderParcelsAndOrderFurnituresSaga(actionData: DataAction<OrderParcelsAndOrderFurnituresDeletePayload>) {
    try {
        const { orderParcelIds, orderFurnitureIds } = actionData.payload || {};

        yield all([
            ...(orderParcelIds ?? []).map((id) => call(delOrderParcelApiCall, { orderParcelId: id })),
            ...(orderFurnitureIds ?? []).map((id) => call(delOrderFurnitureApiCall, { orderFurnitureId: id })),
        ]);

        return yield put(delOrderParcelsAndOrderFurnitures.success());
    } catch (error) {
        return yield put(delOrderParcelsAndOrderFurnitures.failure(error));
    }
}

// Store helpers
export const getOrderListState = (state: MainReducerState) => state.orders.list;
export const getOrderStateById = (id?: Order['id']) => (state: MainReducerState) => ({
    loading: state.orders.details.loading,
    error: state.orders.details.error,
    data: id ? state.orders.details.data[id] : undefined,
});
export const getOrderUpdateState = (state: MainReducerState) => state.orders.update;
export const getOrderOperatorListState = (state: MainReducerState) => state.orders.operatorList;
export const getOrderDeleteState = (state: MainReducerState) => state.orders.del;
export const getOrderParcelAndOrderFurnitureDeleteState = (state: MainReducerState) =>
    state.orders.delOrderParcelsAndOrderFurnitures;
