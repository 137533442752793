import React, { FC, useCallback, useEffect, useState } from 'react';
import { Input, message, Form, FormProps, Button, Select } from 'antd';
import { useSelector } from 'react-redux';

import {
    getPlaceAlveoleCreateState,
    getPlaceAlveoleUpdateState,
    getPlaceAlveoleDeleteState,
    create as placeAlveoleCreate,
    update as placeAlveoleUpdate,
    del as placeAlveoleDelete,
} from '../../store/actions/placeAlveoles';
import { PlaceAlveole, PlaceStoreType } from '../../store/api/apiTypes';
import { getPlaceSectorsListState, list as placeSectorsList } from '../../store/actions/placeSectors';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useShortcutScope } from '../../hooks';
import SuccessMessage from '../../components/SuccessMessage';
import ButtonRed from '../../components/ButtonRed';
import PlaceStoreSelect from '../../components/PlaceStoreSelect';
import { requiredRule } from '../../helpers';
import PlaceLaneSelect from '../../components/PlaceLaneSelect';

const shortcutScope = 'PlaceAlveoleFormModal';

interface PlaceAlveoleFormModalProps extends CustomModalProps {
    placeAlveole?: PlaceAlveole;
    onSuccess?: () => void;
}

const PlaceAlveoleFormModal: FC<PlaceAlveoleFormModalProps> = ({ visible, onCancel, placeAlveole, onSuccess }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const isEditing = !!placeAlveole;
    const isMounted = useIsMounted();
    const [form] = Form.useForm();
    const [success, setSuccess] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [createPlaceAlveole, updatePlaceAlveole, deletePlaceAlveole, listPlaceSectors] = useActions([
        placeAlveoleCreate.trigger,
        placeAlveoleUpdate.trigger,
        placeAlveoleDelete.trigger,
        placeSectorsList.trigger,
    ]);
    const placeAlveoleCreateState = useSelector(getPlaceAlveoleCreateState);
    const placeAlveoleUpdateState = useSelector(getPlaceAlveoleUpdateState);
    const placeAlveoleDeleteState = useSelector(getPlaceAlveoleDeleteState);
    const placeSectorsState = useSelector(getPlaceSectorsListState);
    const previous = usePrevious({ placeAlveoleCreateState, placeAlveoleUpdateState, placeAlveoleDeleteState });
    const onSubmit: FormProps['onFinish'] = (values) => {
        const payload = values;

        delete payload.placeStore;

        if (isEditing) {
            updatePlaceAlveole({
                placeAlveoleId: placeAlveole?.id,
                ...payload,
            });
        } else {
            createPlaceAlveole(payload);
        }
    };
    const onDelete = () => {
        deletePlaceAlveole({ placeAlveoleId: placeAlveole?.id });
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const onFormValuesChange: FormProps['onValuesChange'] = (changedValues) => {
        const changedField = Object.keys(changedValues)?.[0];

        if (changedField === 'placeStore') {
            listPlaceSectors({ placeStoreId: changedValues.placeStore?.id });
            form.setFieldsValue({ placeSectorId: undefined, placeLaneId: undefined });
        } else if (changedField === 'placeSectorId') {
            form.setFieldsValue({ placeLaneId: undefined });
        }
    };

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            setSuccess(false);
        }
    }, [visible, form, setSuccess]);

    useEffect(() => {
        if (previous?.placeAlveoleCreateState.loading && !placeAlveoleCreateState.loading) {
            if (placeAlveoleCreateState.error) {
                if (placeAlveoleCreateState.error?.status === 409) {
                    message.error('Ce nom est déjà utilisé par une autre alvéole');
                } else {
                    message.error("Une erreur est survenue pendant la création de l'alvéole");
                }
            } else {
                setSuccess(true);
            }
        }
    }, [placeAlveoleCreateState.error, placeAlveoleCreateState.loading, previous?.placeAlveoleCreateState.loading]);

    useEffect(() => {
        if (previous?.placeAlveoleUpdateState.loading && !placeAlveoleUpdateState.loading) {
            if (placeAlveoleUpdateState.error) {
                if (
                    placeAlveoleUpdateState.error?.status === 409 &&
                    placeAlveoleUpdateState.error?.data?.placeNotEmpty
                ) {
                    message.error("Cette alvéole n'est pas vide, vous ne pouvez pas la modifier");
                } else {
                    message.error("Une erreur est survenue pendant la mise à jour de l'alvéole");
                }
            } else {
                setSuccess(true);
            }
        }
    }, [placeAlveoleUpdateState.error, placeAlveoleUpdateState.loading, previous?.placeAlveoleUpdateState.loading]);

    useEffect(() => {
        if (previous?.placeAlveoleDeleteState.loading && !placeAlveoleDeleteState.loading) {
            if (placeAlveoleDeleteState.error) {
                if (placeAlveoleDeleteState.error?.status === 400) {
                    message.error("Vous ne pouvez pas supprimer cette alvéole car elle n'est pas vide");
                } else {
                    message.error("Une erreur est survenue pendant la suppression de l'alvéole");
                }
            } else {
                setSuccess(true);
            }
        }
    }, [placeAlveoleDeleteState.error, placeAlveoleDeleteState.loading, previous?.placeAlveoleDeleteState.loading]);

    useEffect(() => {
        let timeout: number;

        if (success) {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onClose();
                    onSuccess?.();
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [isMounted, onClose, success, onSuccess]);

    useEffect(() => {
        if (placeAlveole) {
            form.setFieldsValue(placeAlveole);
        } else {
            form.resetFields();
        }
    }, [placeAlveole, form]);

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            setIsDeleting(false);
        }
    }, [visible, form]);

    return (
        <CustomModal
            footer={
                success ? null : isDeleting ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                as={ButtonRed}
                                onClick={onDelete}
                                loading={placeAlveoleDeleteState.loading}
                            >
                                Oui, supprimer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                disabled={placeAlveoleDeleteState.loading}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={form.submit}
                                loading={placeAlveoleCreateState.loading || placeAlveoleUpdateState.loading}
                                disabled={placeAlveoleDeleteState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        {isEditing && (
                            <ArrowNavItem scope={shortcutScope}>
                                <Button onClick={setIsDeleting.bind(null, true)} danger>
                                    Supprimer
                                </Button>
                            </ArrowNavItem>
                        )}
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                disabled={placeAlveoleDeleteState.loading}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={
                !success &&
                (isDeleting
                    ? `Voulez-vous vraiment supprimer cette alvéole maintenant ?`
                    : isEditing
                    ? 'Éditer'
                    : 'Ajouter une alvéole')
            }
            width={368}
            keyboard={false}
            altTitle={!isDeleting}
        >
            {success ? (
                <SuccessMessage
                    message={`L'alvéole a été ${
                        isDeleting ? 'supprimée' : isEditing ? 'éditée' : 'ajoutée'
                    } avec succès`}
                />
            ) : !isDeleting ? (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                    initialValues={placeAlveole}
                    onValuesChange={onFormValuesChange}
                    requiredMark={false}
                >
                    {!isEditing && (
                        <>
                            <Form.Item name="placeStore" label="Site" rules={[requiredRule]}>
                                <PlaceStoreSelect
                                    placeStoreType={PlaceStoreType.site}
                                    allowClear={false}
                                    withArrowNav
                                />
                            </Form.Item>
                            <Form.Item name="placeSectorId" label="Secteur" rules={[requiredRule]}>
                                <ArrowNavItem>
                                    <Select
                                        placeholder="Filtrer par secteur"
                                        filterOption={false}
                                        style={{ width: '100%' }}
                                        allowClear
                                        showArrow
                                    >
                                        {placeSectorsState.data?.items?.map((option) => (
                                            <Select.Option value={option.id} key={option.id}>
                                                {option.sector}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </ArrowNavItem>
                            </Form.Item>
                            <Form.Item shouldUpdate noStyle>
                                {({ getFieldValue }) => (
                                    <Form.Item
                                        name="placeLaneId"
                                        label="Allée"
                                        rules={[requiredRule]}
                                        dependencies={['placeSectorId']}
                                    >
                                        <PlaceLaneSelect placeSectorId={getFieldValue('placeSectorId')} withArrowNav />
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </>
                    )}
                    <Form.Item
                        name="alveole"
                        label="Alvéole"
                        normalize={(value?: string) => `${value ?? ''}`.toUpperCase()}
                        rules={[requiredRule]}
                    >
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir un nom" />
                        </ArrowNavItem>
                    </Form.Item>
                </Form>
            ) : null}
        </CustomModal>
    );
};

export default PlaceAlveoleFormModal;
