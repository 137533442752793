import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { Printer } from '../api/apiTypes';
import { ListResponse } from '../api/';

// Controlers
import { PrinterListPayload, list as listApiCall } from '../api/printers';
import { EzeeAction } from '../helpers/EzeeAction';

// States
export interface PrintersState {
    list: RequestState<ListResponse<Printer>>;
    defaultPrinter: {
        printer: Printer | undefined;
    };
}

const initialState: PrintersState = {
    list: { ...requestInitialState },
    defaultPrinter: {
        printer: undefined,
    },
};

export const list = new EzeeAsyncAction<PrintersState['list'], PrinterListPayload, ListResponse<Printer>>(
    'printers/list',
    initialState.list,
    requestReducer<PrintersState['list'], ListResponse<Printer>>(initialState.list)
);

export const defaultPrinter = new EzeeAction<PrintersState['defaultPrinter']>(
    'printer/default',
    initialState.defaultPrinter,
    {
        setDefaultPrinter: (state, payload) => {
            return {
                printer: payload,
            };
        },
    }
);

// Reducer
export const printersReducer = combineReducers<PrintersState>({
    list: list.reducer,
    defaultPrinter: defaultPrinter.reducer,
});

// Saga
export function* printersSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
}

// Store helpers
export const getPrintersListState = (state: MainReducerState) => state.printers.list;

export const getDefaultPrinterState = (state: MainReducerState) => state.printers.defaultPrinter;
