import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, message, Spin } from 'antd';

import { getPalletPrintState, print as printPalletAction } from '../store/actions/pallets';
import { Pallet, Printer, WMSMode } from '../store/api/apiTypes';

import { useActions, useArrowNavScope, useIsMounted, usePrevious, useShortcutScope } from '../hooks';
import ButtonWithShortcut from './ButtonWithShortcut';
import CustomModal, { CustomModalProps } from './CustomModal';
import ArrowNavItem from './ArrowNavItem';
import PrintersSelect from './PrintersSelect';
import { getDefaultPrinterState } from '../store/actions/printers';

const shortcutScope = 'printModal';

interface PrintModalProps extends CustomModalProps {
    palletId?: Pallet['id'];
    cancellable?: boolean;
    onSuccess?: () => void;
    mode: WMSMode;
}

const PrintModal: FC<PrintModalProps> = ({
    visible,
    onCancel,
    palletId,
    cancellable = true,
    cancelText,
    onSuccess,
    mode,
    ...props
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const defaultPrinterState = useSelector(getDefaultPrinterState);
    const [printer, setPrinter] = useState<Printer | undefined>(defaultPrinterState.printer);
    const [success, setSuccess] = useState(false);
    const [print] = useActions([printPalletAction.trigger]);
    const isMounted = useIsMounted();
    const printState = useSelector(getPalletPrintState);
    const previous = usePrevious({ printState });
    const onValidate = () => {
        if (printer) {
            print({ palletId, printerId: printer.id, mode });
        }
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    useEffect(() => {
        if (!visible) {
            setSuccess(false);
        }
    }, [visible]);

    useEffect(() => {
        if (previous?.printState.loading && !printState.loading) {
            if (printState.error) {
                message.error("Erreur lors de l'envoi à l'impression");
            } else {
                setSuccess(true);
            }
        }
    }, [previous?.printState.loading, printState.loading, printState.error, setSuccess]);

    useEffect(() => {
        let timeout: number;

        if (success) {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    if (onSuccess) {
                        onSuccess();
                    } else {
                        onClose();
                    }
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [success, isMounted, onClose, onSuccess]);

    return (
        <CustomModal
            footer={
                !success && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                type="primary"
                                shortcut="enter"
                                shortcutScope={shortcutScope}
                                shortcutOptions={{ enabled: !!printer }}
                                onClick={onValidate}
                                disabled={!printer}
                                loading={printState.loading}
                            >
                                Imprimer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        {cancellable && (
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="esc"
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                    onClick={onClose}
                                    ghost
                                >
                                    {cancelText ?? 'Annuler'}
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        )}
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={!success && "Impression de l'étiquette"}
            width={368}
            closable={cancellable}
            maskClosable={cancellable}
            altTitle
            centered
            {...props}
        >
            {success ? (
                <div style={{ textAlign: 'center' }}>
                    <p>Impression en cours...</p>
                    <Spin size="large" />
                </div>
            ) : (
                <Form.Item label="Imprimante" style={{ margin: 0 }} labelCol={{ span: 24 }}>
                    <PrintersSelect onChange={setPrinter} withArrowNav arrowNavScope={shortcutScope} />
                </Form.Item>
            )}
        </CustomModal>
    );
};

export default PrintModal;
