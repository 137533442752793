import { SearchPaginationQuery } from '.';
import { Parcel, MovementHistoryType, Organization } from './apiTypes';
import api from './_client';

export type MovementHistoryListPayload = SearchPaginationQuery & { parcelId?: Parcel['id'] };
export const list = async (payload: MovementHistoryListPayload = {}) =>
    await api.get('/movementHistories', { params: payload });

export interface MouvementHistoryCreatePayload {
    type: MovementHistoryType;
    parcelId: Parcel['id'];
    sourceId?: number;
    destinationId: number;
    quantity: number;
    date: string;
    operatorId: number;
    organizationId: Organization['id'];
}

export const create = async (payload: MouvementHistoryCreatePayload) => await api.post('/movementHistories', payload);
