import React, { FC, useCallback, useEffect, useState } from 'react';
import { Form, message, Select, SelectProps, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
    Preparation,
    PreparationStatus,
    Place,
    PlaceCapacity,
    PlaceZoneType,
    ResupplyOrderType,
} from '../../store/api/apiTypes';
import { getPlacesListState, list as placesList } from '../../store/actions/places';
import { create as resupplyOrderCreate, getResupplyOrderCreateState } from '../../store/actions/resupplyOrders';
import {
    details as preparationDetails,
    getPreparationUpdateState,
    update as preparationUpdate,
} from '../../store/actions/preparations';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useShortcutScope } from '../../hooks';
import SuccessMessage from '../../components/SuccessMessage';
import QuantityCard from '../../components/QuantityCard';
import { getRoute, RoutePathName } from '../../routes';
import { formatNumber } from '../../helpers/i18n';

const shortcutScope = 'DirectResupplyModal';

interface DirectResupplyModalProps extends CustomModalProps {
    preparation?: Preparation;
}

const DirectResupplyModal: FC<DirectResupplyModalProps> = ({ visible, onCancel, preparation }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const history = useHistory();
    const isMounted = useIsMounted();
    const { loadingOrderId, customerOrderId } = useParams<{ loadingOrderId: string; customerOrderId: string }>();
    const [step, setStep] = useState<'start' | 'zone' | 'success'>('start');
    const [place, setPlace] = useState<Place>();
    const [isCancelling, setIsCancelling] = useState(false);
    const onChangePlace: SelectProps<Place['id']>['onChange'] = (value) => {
        setPlace(placesListState.data?.items.find((p) => p.id === value));
    };
    const [fetchPlaces, createResupplyOrder, fetchPreparation, updatePreparation] = useActions([
        placesList.trigger,
        resupplyOrderCreate.trigger,
        preparationDetails.trigger,
        preparationUpdate.trigger,
    ]);
    const placesListState = useSelector(getPlacesListState);
    const resupplyOrderCreateState = useSelector(getResupplyOrderCreateState);
    const preparationUpdateState = useSelector(getPreparationUpdateState);
    const previous = usePrevious({ resupplyOrderCreateState, preparationUpdateState, isCancelling });
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const afterModalClose = () => {
        setStep('start');
    };
    const onPlaceSearch: SelectProps<Place['id']>['onSearch'] = (value) => {
        fetchPlaces(
            { size: PlaceCapacity.large, zoneType: PlaceZoneType.preparation, ...(value ? { search: value } : {}) },
            { throttling: 300 }
        );
    };
    const onSubmit = () => {
        createResupplyOrder({
            placeId: place?.id,
            type: ResupplyOrderType.direct,
            quantityToBeTransferred: preparation?.directQtyToPrep,
            preparationId: preparation?.id,
        });
    };
    const onClickCancel = () => {
        setIsCancelling(true);
    };

    useEffect(() => {
        if (!previous?.isCancelling && isCancelling) {
            updatePreparation({
                id: preparation?.id,
                operatorId: null,
                picking: null,
                direct: null,
                pickingQtyToPrep: null,
                directQtyToPrep: null,
                pickingPrepStatus: null,
                status: PreparationStatus.toBeProcessed,
            });
        }
    }, [isCancelling, preparation?.id, previous?.isCancelling, updatePreparation]);

    // reset preparation when using browser back button and picking status is toProceed
    useEffect(() => {
        return () => {
            if (visible && history.action === 'POP' && !preparationUpdateState.loading) {
                updatePreparation({
                    id: preparation?.id,
                    operatorId: null,
                    picking: null,
                    direct: null,
                    pickingQtyToPrep: null,
                    directQtyToPrep: null,
                    pickingPrepStatus: null,
                    status: PreparationStatus.toBeProcessed,
                });
            }
        };
    });

    useEffect(() => {
        fetchPlaces({ size: PlaceCapacity.large, zoneType: PlaceZoneType.preparation });
    }, [fetchPlaces]);

    useEffect(() => {
        if (visible && previous?.resupplyOrderCreateState.loading && !resupplyOrderCreateState.loading) {
            if (resupplyOrderCreateState.error) {
                if (resupplyOrderCreateState.error?.status === 409) {
                    if (resupplyOrderCreateState.error?.data?.packageFound) {
                        message.error(
                            "Impossible de créer l'ordre de direct car l'emplacement cible contient des colis"
                        );
                    } else {
                        message.error("Un ordre de direct existe déjà pour l'emplacement cible");
                    }
                } else if (
                    resupplyOrderCreateState.error?.status === 400 &&
                    resupplyOrderCreateState.error?.data?.reservedPalletNotAvailable
                ) {
                    message.error("La palette réservée n'est plus disponible, veuillez relancer la préparation");
                } else {
                    message.error("Une erreur est survenue lors de la création de l'ordre direct");
                }
            } else {
                setStep('success');
            }
        }
    }, [
        visible,
        previous?.resupplyOrderCreateState.loading,
        resupplyOrderCreateState.loading,
        resupplyOrderCreateState.error,
    ]);

    // refresh preparation after success
    useEffect(() => {
        let timeout: number;

        if (visible && step === 'success') {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onClose();
                    fetchPreparation({ preparationId: preparation?.id });
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [visible, step, onClose, isMounted, fetchPreparation, preparation?.id]);

    useEffect(() => {
        if (visible && isCancelling && previous?.preparationUpdateState.loading && !preparationUpdateState.loading) {
            if (preparationUpdateState.error) {
                message.error("Une erreur est survenue pendant la mis à jour de l'ordre");
            } else {
                onClose();
                history.push(
                    getRoute(RoutePathName.customerOrderDetails, {
                        loadingOrderId,
                        customerOrderId,
                    })
                );
            }

            setIsCancelling(false);
        }
    }, [
        previous?.preparationUpdateState.loading,
        preparationUpdateState.loading,
        preparationUpdateState.error,
        onClose,
        history,
        visible,
        loadingOrderId,
        customerOrderId,
        isCancelling,
    ]);

    return (
        <CustomModal
            footer={
                step !== 'success' ? (
                    <>
                        {step === 'start' && (
                            <>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="enter"
                                        type="primary"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        onClick={setStep.bind(null, 'zone')}
                                    >
                                        Suivant
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="esc"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        type="primary"
                                        onClick={onClickCancel}
                                        ghost
                                    >
                                        Annuler
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </>
                        )}
                        {step === 'zone' && (
                            <>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="enter"
                                        type="primary"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        onClick={onSubmit}
                                        loading={resupplyOrderCreateState.loading}
                                        disabled={!place}
                                    >
                                        Valider la demande
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="esc"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        type="primary"
                                        onClick={setStep.bind(null, 'start')}
                                        ghost
                                    >
                                        Retour
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </>
                        )}
                    </>
                ) : null
            }
            afterClose={afterModalClose}
            visible={visible}
            onCancel={onCancel}
            title={step === 'success' ? undefined : 'Demande de transfert direct'}
            width={step === 'success' ? 266 : 368}
            keyboard={false}
            altTitle
        >
            {step === 'start' && (
                <>
                    <div className="centered-description mb-24">
                        <div style={{ minWidth: 100 }}>
                            <p>Référence</p>
                            <p>{preparation?.parcel?.reference ?? '—'}</p>
                        </div>
                        <div style={{ minWidth: 100 }}>
                            <p>Désignation</p>
                            <Typography.Paragraph style={{ margin: 0 }} ellipsis>
                                {preparation?.parcel?.label ?? '—'}
                            </Typography.Paragraph>
                        </div>
                    </div>
                    <QuantityCard value={formatNumber(preparation?.directQtyToPrep)} label="Colis à livrer" onWhite />
                </>
            )}
            {step === 'zone' && (
                <Form.Item label="Zone de mise à disposition" style={{ margin: 0 }} labelCol={{ span: 24 }}>
                    <ArrowNavItem scope={shortcutScope}>
                        <Select
                            style={{ width: '100%' }}
                            value={place?.id}
                            onChange={onChangePlace}
                            filterOption={false}
                            onSearch={onPlaceSearch}
                            placeholder="Sélectionner la zone"
                            loading={placesListState.loading}
                            showSearch
                        >
                            {placesListState.data?.items.map((place) => (
                                <Select.Option value={place.id} key={place.id}>
                                    {place.locker}
                                </Select.Option>
                            ))}
                        </Select>
                    </ArrowNavItem>
                </Form.Item>
            )}
            {step === 'success' && <SuccessMessage message="Demande de transfert direct envoyée" />}
        </CustomModal>
    );
};

export default DirectResupplyModal;
