import React, { FC } from 'react';
import { Col, Row, Spin, Typography } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import ArrowNavItem from '../../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../../components/ButtonWithShortcut';

interface ConfirmNewPalletModalContentProps {
    loading?: boolean;
    message: string;
    hasIcon: boolean;
}

interface ConfirmNewPalletModalFooterProps {
    shortcutScope: string;
    onConfirm: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const ConfirmNewPalletModalContent: FC<ConfirmNewPalletModalContentProps> = ({ loading, message, hasIcon }) => {
    return (
        <Row justify="center">
            <Col xs={16} className="text-center">
                {loading ? (
                    <Spin spinning={loading} size="large" />
                ) : (
                    <>
                        {hasIcon && (
                            <Typography.Title level={4} style={{ fontSize: '35px' }} type="warning">
                                <WarningOutlined />
                            </Typography.Title>
                        )}
                        <Text>{message}</Text>
                    </>
                )}
            </Col>
        </Row>
    );
};

const ConfirmNewPalletModalFooter: FC<ConfirmNewPalletModalFooterProps> = ({ shortcutScope, onConfirm, onClose }) => {
    return (
        <>
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut
                    shortcut="enter"
                    shortcutScope={shortcutScope}
                    type="primary"
                    onClick={onConfirm}
                    block
                >
                    Continuer
                </ButtonWithShortcut>
            </ArrowNavItem>

            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut shortcut="esc" shortcutScope={shortcutScope} type="ghost" onClick={onClose} block>
                    Annuler
                </ButtonWithShortcut>
            </ArrowNavItem>
        </>
    );
};

export { ConfirmNewPalletModalContent, ConfirmNewPalletModalFooter };
