import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Pallet, PalletSize, Place, PlaceZoneType, TransferOrderStatus, WMSMode } from '../../../store/api/apiTypes';

import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import Seo from '../../../components/Seo';
import { useActions } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import FixedFooter from '../../../components/FixedFooter';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';

import { getPlacesListState, list } from '../../../store/actions/places';
import {
    create as createTransferOrder,
    updateAndStorePackages,
    detailsTransferOrder,
    getTransferOrdersDetails,
} from '../../../store/actions/transferOrders';
import useQueryParams from '../../../hooks/queryParams';
import { urlSearchParamsToObject } from '../../../helpers';

import { Button, Divider, PageHeader } from 'antd';
import QuantityCard from '../../../components/QuantityCard';
import ConfirmModal from '../../../components/ConfirmModal';
import TransferQuantityModal from './TransferQuantityModal';
import PrintModal from '../../../components/PrintModal';
import PalletSizeModal from './PalletSizeModal';
import StorePackageByPlaceModal from './StorePackageByPlaceModal';
import { isZebra } from '../../../helpers/enterprise-browser';

const PackagePlaces: FC = () => {
    const history = useHistory();
    const [fetchPlaces, resetTransferOrderCreateState, resetUpdatePackageState, fetchTransferOrder] = useActions([
        list.trigger,
        createTransferOrder.reset,
        updateAndStorePackages.reset,
        detailsTransferOrder.trigger,
    ]);
    const placesListState = useSelector(getPlacesListState);

    const [queryParams] = useQueryParams('transferOrdersPlacesFilter');

    const transferOrder = useSelector(getTransferOrdersDetails);
    const [selectedPlace, setSelectedPlace] = useState<Place>();

    type createPalletStep = 'confirmation' | 'print' | 'quantity' | 'scan' | 'size' | undefined;

    const location =
        useLocation<{
            totalPackages: number;
            step?: createPalletStep;
            zoneType: PlaceZoneType;
            afterFilter: boolean;
        }>();

    const [step, setStep] = useState<createPalletStep>(location.state?.step);
    const [palletIdToPrint, setPalletIdToPrint] = useState<Pallet['id']>();
    const [isTransferOrderStatusCompleted, setIsTransferOrderStatusCompleted] = useState(false);

    const [palletSize, setPalletSize] = useState<PalletSize>();
    const [quantity, setQuantity] = useState(location.state?.totalPackages);

    const columns = (
        [
            {
                key: 'sector',
                title: 'Secteur',
                flex: '1 1 25%',
                render: (record) => record.sector,
            },
            {
                key: 'locker',
                title: 'Casier',
                flex: '1 1 25%',
                render: (record) => record.locker,
            },
            {
                key: 'spot',
                title: 'Emplacement',
                flex: '1 1 25%',
                render: (record) => record.spot,
            },
        ] as Array<ListColumn<Place>>
    ).filter(Boolean);

    const handleFinishPrint = () => {
        setStep(undefined);
        setPalletIdToPrint(undefined);

        if (isTransferOrderStatusCompleted) {
            resetTransferOrderCreateState();
            resetUpdatePackageState();
            history.push(getRoute(RoutePathName.movementsTransferOrderList), {});
        } else {
            history.push(getRoute(RoutePathName.movementsTransferOrdersPalletsList), { ...location.state });
        }
    };

    const handlePlaceSelect = (place?: Place) => {
        setSelectedPlace(place);
    };

    const selectPackagePlace = () => {
        setStep('scan');
    };

    const handleFilterClick = () => {
        history.push(getRoute(RoutePathName.movementsTransferOrdersPlacesFilter), {
            ...location.state,
            totalPackages: quantity,
            backRoute: RoutePathName.movementsTransferOrdersPackagePlaces,
        });
    };
    const handleOTCreationSuccess = (palletId: Pallet['id'] | undefined, transferOrderStatus?: TransferOrderStatus) => {
        setPalletIdToPrint(palletId);
        setStep('print');

        if (transferOrderStatus !== TransferOrderStatus.completed) {
            fetchTransferOrder({ id: transferOrder.data?.id });
        } else {
            setIsTransferOrderStatusCompleted(true);
        }
    };

    const onLoadMore = () => {
        fetchPlaces(
            {
                ...urlSearchParamsToObject(queryParams),
                zoneType: PlaceZoneType.mass,
                palletId: transferOrder.data?.startZone?.pallet?.id,
                page: (placesListState.data?.page ?? 0) + 1,
                pageSize: 10,
            },
            { loadMore: true }
        );
    };

    const selectPackagesQuantity = (quantity: number) => {
        setQuantity(quantity);
        setStep(undefined);
        fetchPlaces({
            ...urlSearchParamsToObject(queryParams),
            zoneType: PlaceZoneType.mass,
            size: palletSize,
            pageSize: 10,
        });
    };

    const selectPalletSize = (size?: PalletSize) => {
        setPalletSize(size);
        setStep('quantity');
    };

    const getBack = () => {
        history.push(getRoute(RoutePathName.movementsTransferOrdersPalletsList), { ...location.state });
    };

    useEffect(() => {
        if (!isZebra) {
            history.push(getRoute(RoutePathName.notFound));
        }
    }, [history]);

    useEffect(() => {
        if (!palletIdToPrint && !location.state.afterFilter) {
            setStep('confirmation');
        }
    }, [palletIdToPrint, location.state.afterFilter]);

    // return to home page when there is no OT in store (reload page, direct access to page by url...)
    useEffect(() => {
        if (!transferOrder.data && !transferOrder.loading) {
            history.push(getRoute(RoutePathName.movementsTransferOrderList));
        }
    }, [history, transferOrder.data, transferOrder.loading]);

    useEffect(() => {
        fetchPlaces({
            ...urlSearchParamsToObject(queryParams),
            zoneType: PlaceZoneType.mass,
            palletId: palletIdToPrint ?? transferOrder.data?.startZone?.pallet?.id,
            pageSize: 10,
        });
    }, [fetchPlaces, palletIdToPrint, queryParams, transferOrder.data?.startZone?.pallet?.id]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="autre emplacement" />
            <Header
                title="autre emplacement"
                backRoute={{
                    pathname: getRoute(RoutePathName.movementsTransferOrdersPalletsList),
                    state: location.state,
                }}
            />
            <PageHeader>
                <QuantityCard value={quantity ?? transferOrder.data?.packageQuantity} label={'colis à transférer'} />
            </PageHeader>
            <Divider />
            <List<Place>
                columns={columns}
                data={placesListState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowEnterPress={selectPackagePlace}
                onRowClick={handlePlaceSelect}
                onRowFocus={handlePlaceSelect}
                onRowBlur={handlePlaceSelect}
                isLoading={placesListState.loading && placesListState.data?.page === undefined}
                title="Emplacements disponibles"
                style={{ marginTop: 24 }}
                columnWidthGrow={true}
            />
            {placesListState.data && placesListState.data.page < placesListState.data.pageCount - 1 && (
                <Button
                    onClick={onLoadMore}
                    loading={placesListState.loading}
                    size="small"
                    style={{ marginBottom: 60, marginTop: 16, fontSize: 14 }}
                    block
                >
                    Plus de résultats
                </Button>
            )}

            {step === 'confirmation' && (
                <ConfirmModal
                    visible={step === 'confirmation'}
                    onCancel={getBack}
                    onOk={setStep.bind(null, 'size')}
                    message="En cliquant sur continuer vous allez créer une nouvelle palette"
                    okText="Continuer"
                    cancelText="Annuler"
                    hasIcon={true}
                />
            )}
            {step === 'size' && (
                <PalletSizeModal visible={step === 'size'} onSubmit={selectPalletSize} onCancel={getBack} />
            )}
            {step === 'quantity' && (
                <TransferQuantityModal
                    onSubmit={selectPackagesQuantity}
                    onReturn={setStep.bind(null, 'size')}
                    max={location.state.totalPackages}
                    visible={step === 'quantity'}
                    title="Quantité colis"
                    text={`sur ${location.state.totalPackages} à transférer`}
                    cancelText="Retour"
                />
            )}
            {step === 'print' && (
                <PrintModal
                    visible={step === 'print'}
                    onCancel={handleFinishPrint}
                    onSuccess={handleFinishPrint}
                    palletId={palletIdToPrint}
                    mode={WMSMode.movement}
                />
            )}
            {step === 'scan' && (
                <StorePackageByPlaceModal
                    onCancel={setStep.bind(null, undefined)}
                    onSuccess={handleOTCreationSuccess}
                    packagesNumber={quantity ?? location.state?.totalPackages}
                    totalPackages={location.state?.totalPackages}
                    visible={step === 'scan'}
                    selectedPlace={selectedPlace}
                    palletSize={palletSize}
                />
            )}
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        disabled={!selectedPlace?.id}
                        onClick={selectPackagePlace}
                        shortcut="enter"
                        block
                        className="btn-grey"
                    >
                        Ranger les colis
                    </ButtonWithShortcut>
                </ArrowNavItem>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        onClick={handleFilterClick}
                        shortcut="f1"
                        style={{ marginBottom: 10, fontSize: 14 }}
                        type="ghost"
                        block
                    >
                        Filtrer
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </FixedFooter.Wrapper>
    );
};

export default PackagePlaces;
