import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { StockDifference } from '../api/apiTypes';

// Controlers
import { StockDifferencesSendPayload, send as sendApiCall } from '../api/stockDifferences';

// States
export interface StockDifferencesState {
    send: RequestState<StockDifference>;
}

const initialState: StockDifferencesState = {
    send: { ...requestInitialState },
};

export const send = new EzeeAsyncAction<StockDifferencesState['send'], StockDifferencesSendPayload, StockDifference>(
    'stockDifferences/send',
    initialState.send,
    requestReducer<StockDifferencesState['send'], StockDifference>(initialState.send)
);

// Reducer
export const stockDifferencesReducer = combineReducers<StockDifferencesState>({
    send: send.reducer,
});

// Saga
export function* stockDifferencesSaga() {
    yield takeLatest(send.type.trigger, simpleAsyncSaga(sendApiCall, send));
}

// Store helpers
export const getStockDifferencesState = (state: MainReducerState) => state.stockDifferences.send;
