import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Result } from 'antd';
import { ResultProps } from 'antd/lib/result';
import { useSelector } from 'react-redux';

import { getUser } from '../../store/actions/auth';

import Seo from '../../components/Seo';
import ButtonLink from '../../components/ButtonLink';
import { getRoute, RoutePathName } from '../../routes';
import { hasRole } from '../../helpers/security';
import { RoleName } from '../../store/api/apiTypes';

interface ErrorPageProps {
    status?: number;
}

const ErrorPage: FC<ErrorPageProps> = ({ status }) => {
    const user = useSelector(getUser);
    const homePage = hasRole(user, [RoleName.pfAdmin, RoleName.pfUser])
        ? getRoute(RoutePathName.platformHome)
        : getRoute(RoutePathName.home);
    const { 0: param } = useParams<{ 0?: string }>();
    let statusCode = status ?? parseInt(param?.substring(1) ?? '', 10);
    let resultStatus: ResultProps['status'] = 'error';
    let title: ResultProps['title'] = 'Erreur';
    let subTitle: ResultProps['subTitle'] =
        'Nous sommes désolés, une erreur est survenue, veuillez réessayer plus tard ou contacter le support.';

    if (isNaN(statusCode)) {
        statusCode = 404;
    }

    if (`${statusCode}`.length === 3 && `${statusCode}`.startsWith('5')) {
        resultStatus = '500';
        title = `${statusCode} Erreur interne`;
    } else if (statusCode === 403 || statusCode === 401) {
        resultStatus = '403';
        title = `${statusCode} Non autorisé`;
        subTitle =
            statusCode === 403
                ? "Nous sommes désolés, vous n'êtes pas autorisé à accéder à cette ressource."
                : 'Nous sommes désolés, vous devez être authentifié pour accéder à cette ressource.';
    } else if (statusCode === 400) {
        title = statusCode;
        subTitle = 'Nous sommes désolés, les informations envoyées ne sont pas valides.';
    } else {
        resultStatus = '404';
        title = 404;
        subTitle = "Nous sommes désolés, la page que vous cherchez n'existe pas.";
    }

    return (
        <>
            <Seo title={`${statusCode}`} />
            <Result
                status={resultStatus}
                title={title}
                subTitle={subTitle}
                extra={
                    <ButtonLink to={homePage} type="primary">
                        Retourner à l&rsquo;accueil
                    </ButtonLink>
                }
            />
        </>
    );
};

export default ErrorPage;
