import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 6">
        <rect width="32" height="6" rx="3" fill="currentColor" fillRule="evenodd" />
    </svg>
);

const IconMinus: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-minus${props.className ? ` ${props.className}` : ''}`} />
);

export default IconMinus;
