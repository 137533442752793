import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { Site } from '../api/apiTypes';
import { ListResponse } from '../api';

// Controlers
import {
    SiteListPayload,
    list as listApiCall,
    SiteCreatePayload,
    create as createApiCall,
    SiteUpdatePayload,
    update as updateApiCall,
    SiteDeletePayload,
    del as delApiCall,
} from '../api/sites';

// States
export interface SitesState {
    list: RequestState<ListResponse<Site>>;
    create: RequestState<Site>;
    update: RequestState<Site>;
    del: RequestState;
}

const initialState: SitesState = {
    list: { ...requestInitialState },
    create: { ...requestInitialState },
    update: { ...requestInitialState },
    del: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<SitesState['list'], SiteListPayload, ListResponse<Site>>(
    'sites/list',
    initialState.list,
    requestReducer<SitesState['list'], ListResponse<Site>>(initialState.list)
);

export const create = new EzeeAsyncAction<SitesState['create'], SiteCreatePayload, Site>(
    'sites/create',
    initialState.create,
    requestReducer<SitesState['create'], Site>(initialState.create)
);

export const update = new EzeeAsyncAction<SitesState['update'], SiteUpdatePayload, Site>(
    'sites/update',
    initialState.update,
    requestReducer<SitesState['update'], Site>(initialState.update)
);

export const del = new EzeeAsyncAction<SitesState['del'], SiteDeletePayload>(
    'sites/del',
    initialState.del,
    requestReducer<SitesState['del'], Site>(initialState.del)
);

// Reducer
export const sitesReducer = combineReducers<SitesState>({
    list: list.reducer,
    create: create.reducer,
    update: update.reducer,
    del: del.reducer,
});

// Saga
export function* sitesSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
}

// Store helpers
export const getSitesListState = (state: MainReducerState) => state.sites.list;
export const getSiteCreateState = (state: MainReducerState) => state.sites.create;
export const getSiteUpdateState = (state: MainReducerState) => state.sites.update;
export const getSiteDeleteState = (state: MainReducerState) => state.sites.del;
