import React, { FC } from 'react';
import ArrowNavItem from '../../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../../components/ButtonWithShortcut';
import { Input, InputProps } from 'antd';
import { useScanner } from '../../../../hooks';

interface ReturnPackageFooterProps {
    shortcutScope: string;
    onValid: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    isValidateDisabled: boolean;
    loading: boolean;
}

interface ReturnPackageContentProps {
    shortcutScope: string;
    onChangeField: InputProps['onChange'];
    inputRef: React.MutableRefObject<Input | null>;
    isScanDisabled: boolean;
    onScanSuccess: (data: { barcode: string }) => void;
}

const ReturnPackageFooter: FC<ReturnPackageFooterProps> = ({
    shortcutScope,
    onValid,
    onClose,
    isValidateDisabled,
    loading,
}) => {
    return (
        <>
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut
                    shortcut="enter"
                    type="primary"
                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                    shortcutScope={shortcutScope}
                    onClick={onValid}
                    disabled={isValidateDisabled}
                    loading={loading}
                >
                    Valider
                </ButtonWithShortcut>
            </ArrowNavItem>
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut
                    shortcut="esc"
                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                    shortcutScope={shortcutScope}
                    type="primary"
                    onClick={onClose}
                    ghost
                >
                    Annuler
                </ButtonWithShortcut>
            </ArrowNavItem>
        </>
    );
};

const ReturnPackageContent: FC<ReturnPackageContentProps> = ({
    shortcutScope,
    onChangeField,
    inputRef,
    isScanDisabled,
    onScanSuccess,
}) => {
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                onScanSuccess({ barcode: barCode.data });
            }
        },
        {
            disable: isScanDisabled,
        }
    );
    return (
        <ArrowNavItem scope={shortcutScope}>
            <Input placeholder="Flasher le code barre du colis" onChange={onChangeField} ref={inputRef} autoFocus />
        </ArrowNavItem>
    );
};

export { ReturnPackageFooter, ReturnPackageContent };
