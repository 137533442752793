import { SearchPaginationQuery } from '.';
import { PlaceLane } from './apiTypes';
import api from './_client';

export type PlaceLaneListPayload = SearchPaginationQuery;
export const list = async (payload: PlaceLaneListPayload = {}) => await api.get('/placeLanes', { params: payload });

export type PlaceLaneCreatePayload = Omit<PlaceLane, 'id'>;
export const create = async (payload: PlaceLaneCreatePayload) => await api.post('/placeLanes', payload);

export type PlaceLaneUpdatePayload = PlaceLane & {
    placeLaneId: PlaceLane['id'];
};
export const update = async ({ placeLaneId, ...payload }: PlaceLaneUpdatePayload) =>
    await api.put(`/placeLanes/${placeLaneId}`, payload);

export interface PlaceLaneDeletePayload {
    placeLaneId: PlaceLane['id'];
}
export const del = async ({ placeLaneId }: PlaceLaneDeletePayload) => await api.delete(`/placeLanes/${placeLaneId}`);
