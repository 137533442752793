import React, { FC } from 'react';
import { Form } from 'antd';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import { Printer } from '../../../store/api/apiTypes';
import PrintersSelect from '../../../components/PrintersSelect';

interface PalletPrintModalContentProps {
    shortcutScope: string;
    setPrinter: React.Dispatch<React.SetStateAction<Printer | undefined>>;
}

interface PalletPrintModalFooterProps {
    shortcutScope: string;
    printer: Printer | undefined;
    onValidate: () => void;
    onClose: () => void;
    loading: boolean;
}

const PalletPrintModalContent: FC<PalletPrintModalContentProps> = ({ shortcutScope, setPrinter }) => {
    return (
        <>
            <Form.Item label="Imprimante" style={{ margin: 0 }} labelCol={{ span: 24 }}>
                <PrintersSelect onChange={setPrinter} withArrowNav arrowNavScope={shortcutScope} />
            </Form.Item>
        </>
    );
};

const PalletPrintModalFooter: FC<PalletPrintModalFooterProps> = ({
    shortcutScope,
    printer,
    onValidate,
    onClose,
    loading,
}) => {
    return (
        <>
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut
                    type="primary"
                    shortcut="enter"
                    shortcutScope={shortcutScope}
                    shortcutOptions={{ enabled: !!printer }}
                    onClick={() => onValidate()}
                    disabled={!printer}
                    loading={loading}
                >
                    Imprimer
                </ButtonWithShortcut>
            </ArrowNavItem>
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut
                    shortcut="esc"
                    shortcutScope={shortcutScope}
                    type="primary"
                    onClick={() => onClose()}
                    ghost
                >
                    Annuler
                </ButtonWithShortcut>
            </ArrowNavItem>
        </>
    );
};

export { PalletPrintModalContent, PalletPrintModalFooter };
