import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useScanner, useShortcutScope } from '../../../hooks';

import { Input, InputProps, message, Spin } from 'antd';
import { isZebra } from '../../../helpers/enterprise-browser';

import { useHistory } from 'react-router-dom';
import {
    updateAndStorePackages,
    getTransferOrderStorePackagesState,
    getTransferOrdersDetails,
} from '../../../store/actions/transferOrders';
import SuccessMessage from '../../../components/SuccessMessage';

import { detailsByScan, getPalletDetailsByScanState } from '../../../store/actions/pallets';
import { StorePackagesPayload } from '../../../store/api/TransferOrders';
import { Pallet, PlaceZoneType, TransferOrderStatus, TransferType } from '../../../store/api/apiTypes';
import { translateTransferOrderError } from '../../../helpers/errors';
import { formatZoneType } from '../../../helpers/i18n';

const shortcutScope = 'StorePackagesByPalletModal';
interface StorePackagesByPalletModalProps extends CustomModalProps {
    onCancel: () => void;
    visible: boolean;
    finishZoneType: PlaceZoneType;
    packagesNumber: number;
    totalPackages: number;
    selectedPallet?: Pallet;
    onSucess: () => void;
}

const StorePackagesByPalletModal: FC<StorePackagesByPalletModalProps> = ({
    visible,
    onCancel,
    packagesNumber,
    totalPackages,
    selectedPallet,
    onSucess,
    finishZoneType,
}) => {
    const [reference, setReference] = useState<string>();
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [success, setSuccess] = useState<boolean>();

    const [fetchPalletByScan, updateAndStorePackagesAction] = useActions([
        detailsByScan.trigger,
        updateAndStorePackages.trigger,
    ]);

    const palletByScanState = useSelector(getPalletDetailsByScanState);

    const storeTransferOrderPackgesState = useSelector(getTransferOrderStorePackagesState);

    const transferOrder = useSelector(getTransferOrdersDetails);
    const isMounted = useIsMounted();
    const history = useHistory();

    const handlePlaceChange: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };

    const handleSubmit = () => {
        fetchPalletByScan({ reference });
    };
    const previous = usePrevious({
        palletByScanState,
        storeTransferOrderPackgesState,
    });

    useEffect(() => {
        if (previous?.palletByScanState.loading && !palletByScanState.loading) {
            if (palletByScanState.error) {
                message.error('Palette non reconnue');
            } else {
                if (selectedPallet?.id !== palletByScanState.data?.id) {
                    message.error('La palette sélectionnée est different de palette flashée');
                } else {
                    if (transferOrder.data?.id) {
                        const payload: StorePackagesPayload = {
                            id: transferOrder.data?.id,
                            transferType: TransferType.package,
                            finishPlaceZoneType: palletByScanState.data?.currentPlace?.zoneType,
                            transferedPackages: packagesNumber,
                            ...(transferOrder.data?.packageQuantity ? {} : { packageQuantity: totalPackages }),
                            palletId: palletByScanState.data?.id,
                        };
                        updateAndStorePackagesAction(payload);
                    }
                }
            }
        }
    }, [
        packagesNumber,
        palletByScanState.data?.currentPlace?.id,
        palletByScanState.data?.currentPlace?.zoneType,
        palletByScanState.data?.id,
        palletByScanState.error,
        palletByScanState.loading,
        previous?.palletByScanState.loading,
        selectedPallet?.id,
        totalPackages,
        transferOrder.data?.packageQuantity,
        transferOrder.data?.id,
        updateAndStorePackagesAction,
    ]);

    useEffect(() => {
        if (previous?.storeTransferOrderPackgesState.loading && !storeTransferOrderPackgesState.loading) {
            if (storeTransferOrderPackgesState.success) {
                setSuccess(true);
            } else {
                message.error(translateTransferOrderError(storeTransferOrderPackgesState.error?.data));
            }
        }
    }, [
        previous?.storeTransferOrderPackgesState.loading,
        storeTransferOrderPackgesState.error?.data,
        storeTransferOrderPackgesState.loading,
        storeTransferOrderPackgesState.success,
    ]);

    useEffect(() => {
        let timeout: number;

        if (success) {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onSucess();
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [success, isMounted, onSucess, history]);

    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                fetchPalletByScan({ barcode: barCode.data });
            }
        },
        {
            deps: [],
            disable: !visible,
        }
    );
    return (
        <CustomModal
            visible={visible}
            title={
                success ?? storeTransferOrderPackgesState.loading ? '' : `Rangement - ${formatZoneType(finishZoneType)}`
            }
            width={368}
            keyboard={false}
            altTitle
            transitionName=""
            maskTransitionName=""
            footer={
                !storeTransferOrderPackgesState.loading &&
                !success && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={handleSubmit}
                            >
                                Valider le rangement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onCancel}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
        >
            {storeTransferOrderPackgesState.loading ? (
                <div style={{ textAlign: 'center' }}>
                    <p>Transfert en cours..</p>
                    <Spin size="large" />
                </div>
            ) : success ? (
                <SuccessMessage
                    message={
                        storeTransferOrderPackgesState.data?.status === TransferOrderStatus.completed
                            ? 'Toutes les colis ont été transférés'
                            : 'Colis transférés avec succès'
                    }
                />
            ) : (
                <ArrowNavItem scope={shortcutScope}>
                    <Input
                        placeholder={isZebra ? `Flasher la palette` : `Entrer la reference de palette`}
                        value={reference}
                        onChange={handlePlaceChange}
                    />
                </ArrowNavItem>
            )}
        </CustomModal>
    );
};

export default StorePackagesByPalletModal;
