import React, { useEffect, useState } from 'react';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import CustomModal from '../../components/CustomModal';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import { Col, message, Row, Typography } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import SuccessMessage from '../../components/SuccessMessage';
import {
    del as deleteIncidentAction,
    getIncidentsDelState,
    list as listIncidentsAction,
} from '../../store/actions/incidents';
import {
    del as deleteMachineAction,
    getMachinesDelState,
    list as listMachinesAction,
} from '../../store/actions/machines';
import { useSelector } from 'react-redux';
import { Incident, Machine } from '../../store/api/apiTypes';
import { useRouteMatch } from 'react-router-dom';
import { getRawRoute, RoutePathName } from '../../routes';

const shortcutScope = 'ConfirmRemoveMachineIncidentModal';

interface ConfirmRemoveIncidentModalProps {
    incidentId?: Incident['id'];
    machineId?: Machine['id'];
}

const ConfirmRemoveMachineIncidentModal = NiceModal.create(
    ({ incidentId, machineId }: ConfirmRemoveIncidentModalProps) => {
        const modal = useModal();
        const isMachinesListRoute = !!useRouteMatch(getRawRoute(RoutePathName.machinesList));
        const { visible, hide } = modal;
        useShortcutScope(shortcutScope, !visible);
        useArrowNavScope(shortcutScope, !visible);
        const [success, setSuccess] = useState<boolean>(false);
        const [deleteIncident, fetchIncidents, deleteMachine, fetchMachines] = useActions([
            deleteIncidentAction.trigger,
            listIncidentsAction.trigger,
            deleteMachineAction.trigger,
            listMachinesAction.trigger,
        ]);
        const incidentDelState = useSelector(getIncidentsDelState);
        const machineDelState = useSelector(getMachinesDelState);
        const previous = usePrevious({ incidentDelState, machineDelState });
        const onValid = () => {
            if (isMachinesListRoute) {
                deleteMachine({ machineId });
            } else {
                deleteIncident({ incidentId });
            }
        };

        useEffect(() => {
            if (previous?.incidentDelState.loading && !incidentDelState.loading) {
                if (incidentDelState.error) {
                    message.error(`Erreur lors de la suppression de l'incident`);
                } else if (incidentDelState.success) {
                    setSuccess(true);
                    fetchIncidents({
                        exclude: ['RAS'],
                    });
                }
            }
        }, [
            fetchIncidents,
            incidentDelState.error,
            incidentDelState.loading,
            incidentDelState.success,
            previous?.incidentDelState.loading,
        ]);
        useEffect(() => {
            if (previous?.machineDelState.loading && !machineDelState.loading) {
                if (machineDelState.error) {
                    message.error(`Erreur lors de la suppression de l'engin`);
                } else if (machineDelState.success) {
                    setSuccess(true);
                    fetchMachines();
                }
            }
        }, [
            fetchMachines,
            machineDelState.error,
            machineDelState.loading,
            machineDelState.success,
            previous?.machineDelState.loading,
        ]);

        useEffect(() => {
            let timeout: number;
            if (success) {
                timeout = window.setTimeout(() => {
                    hide();
                }, 2000);
            }
            return () => {
                window.clearTimeout(timeout);
            };
        }, [hide, success]);

        return (
            <CustomModal
                {...antdModal(modal)}
                footer={
                    !success && (
                        <>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    type="primary"
                                    shortcut="enter"
                                    shortcutScope={shortcutScope}
                                    onClick={onValid}
                                    loading={isMachinesListRoute ? machineDelState.loading : incidentDelState.loading}
                                >
                                    Valider
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    type="primary"
                                    shortcut="esc"
                                    shortcutScope={shortcutScope}
                                    onClick={hide}
                                    ghost
                                >
                                    Annuler
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </>
                    )
                }
                width={!success ? 368 : 232}
            >
                {!success ? (
                    <Row justify="center">
                        <Col xs={30} className="text-center">
                            <Typography.Title level={4} style={{ fontSize: '35px' }} type="warning">
                                <WarningOutlined />
                            </Typography.Title>
                            <Text strong>
                                Vous êtes sur le point de supprimer l&lsquo;{isMachinesListRoute ? 'engin' : 'incident'}
                                . <br /> Souhaitez-vous confirmer ?
                            </Text>
                        </Col>
                    </Row>
                ) : (
                    <SuccessMessage message={`${isMachinesListRoute ? 'Engin' : 'Incident'} supprimé avec succès`} />
                )}
            </CustomModal>
        );
    }
);

export default ConfirmRemoveMachineIncidentModal;
