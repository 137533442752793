import React, { useEffect, useRef, useState } from 'react';
import CustomModal from '../../../components/CustomModal';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../../hooks';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import { Pallet, PalletPlace, PalletSize, Parcel, Place, ReturnZoneType } from '../../../store/api/apiTypes';
import { Input, InputProps, message } from 'antd';
import ReturnSuccessModal from './ReturnSuccessModal';
import { useSelector } from 'react-redux';
import {
    create as createCustomerReturnAction,
    getCustomerReturnCreateState,
} from '../../../store/actions/customerReturn';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../../routes';

const shortcutScope = 'ReturnScanModal';

interface CustomerReturnScanPlaceModalProps {
    returnZoneType: ReturnZoneType;
    parcelId: Parcel['id'];
    quantity: number;
    isNewPallet?: boolean;
    palletSize?: PalletSize;
    selectedPlace?: Place;
    setSelectedPlace?: React.Dispatch<React.SetStateAction<Place | undefined>>;
    selectedPalletPlace?: PalletPlace;
    setSelectedPalletPlace?: React.Dispatch<React.SetStateAction<PalletPlace | undefined>>;
}

const ReturnScanModal = NiceModal.create<CustomerReturnScanPlaceModalProps>(
    ({ parcelId, quantity, returnZoneType, isNewPallet, selectedPlace, selectedPalletPlace, palletSize }) => {
        const modal = useModal();
        const successModal = useModal(ReturnSuccessModal);
        const [placeReference, setPlaceReference] = useState<Place['reference']>();
        const [palletReference, setPalletReference] = useState<Pallet['reference']>();
        const inputRef = useRef<Input | null>(null);
        const createCustomerReturn = useActions(createCustomerReturnAction.trigger);
        const customerReturnCreateState = useSelector(getCustomerReturnCreateState);
        const previous = usePrevious({ customerReturnCreateState });
        const history = useHistory();
        const { hide } = modal;
        useShortcutScope(shortcutScope);
        useArrowNavScope(shortcutScope);

        useEffect(() => {
            if (placeReference && inputRef) {
                inputRef.current?.setValue(placeReference);
            }
        }, [placeReference, inputRef]);

        useEffect(() => {
            if (previous?.customerReturnCreateState.loading && !customerReturnCreateState.loading) {
                if (customerReturnCreateState.error) {
                    if (customerReturnCreateState.error.data.data.cannotPopulatePlace) {
                        message.error("Erreur lors de l'ajout de la palette");
                    } else {
                        message.error('Code barre / réference non reconnu');
                    }
                } else {
                    hide().then(
                        async () =>
                            await successModal.show({
                                returnZoneType,
                            })
                    );
                    returnZoneType === ReturnZoneType.picking &&
                        history.push(getRoute(RoutePathName.movementsStockMenu));
                }
            }
        }, [
            customerReturnCreateState.loading,
            customerReturnCreateState.error,
            previous?.customerReturnCreateState.loading,
            hide,
            returnZoneType,
            history,
            successModal,
        ]);

        const onAdd = () => {
            if (returnZoneType === ReturnZoneType.picking) {
                if (parcelId && quantity && placeReference && selectedPalletPlace?.place.reference === placeReference) {
                    createCustomerReturn({
                        parcelId: `${parcelId}`,
                        placeReference,
                        quantity,
                    });
                } else {
                    message.error("L'emplacement sélectionné ne correspond pas à celui scanné");
                }
            } else {
                if (
                    isNewPallet &&
                    parcelId &&
                    quantity &&
                    placeReference &&
                    palletSize &&
                    selectedPlace?.reference === placeReference
                ) {
                    createCustomerReturn({
                        parcelId: `${parcelId}`,
                        placeReference,
                        palletSize,
                        index: selectedPlace.index,
                        quantity,
                    });
                } else if (
                    parcelId &&
                    quantity &&
                    palletReference &&
                    selectedPalletPlace?.pallet &&
                    selectedPalletPlace.pallet.reference === palletReference
                ) {
                    createCustomerReturn({
                        parcelId: `${parcelId}`,
                        palletReference,
                        quantity,
                    });
                } else {
                    message.error(
                        `${isNewPallet ? "L'emplacement sélectionné" : 'La palette sélectionnée'} ne correspond pas ${
                            isNewPallet ? 'à celui scanné' : 'à celle scannée'
                        }`
                    );
                }
            }
        };

        const onChangeField: InputProps['onChange'] = (e) => {
            if (returnZoneType === ReturnZoneType.picking) {
                setPlaceReference(e.target.value.toUpperCase());
            } else {
                if (isNewPallet) {
                    setPlaceReference(e.target.value.toUpperCase());
                } else {
                    setPalletReference(e.target.value.toUpperCase());
                }
            }
        };

        useScanner(
            shortcutScope,
            (barCode) => {
                if (barCode.data && quantity) {
                    if (returnZoneType === ReturnZoneType.picking) {
                        if (parcelId && selectedPalletPlace?.place.barcode === barCode.data) {
                            createCustomerReturn({
                                parcelId: `${parcelId}`,
                                placeBarcode: barCode.data,
                                quantity,
                            });
                        } else {
                            message.error("L'emplacement sélectionné ne correspond pas à celui scanné");
                        }
                    } else {
                        if (isNewPallet && parcelId && palletSize && selectedPlace?.barcode === barCode.data) {
                            createCustomerReturn({
                                parcelId: `${parcelId}`,
                                placeBarcode: barCode.data,
                                palletSize,
                                index: selectedPlace.index,
                                quantity,
                            });
                        } else if (
                            selectedPalletPlace?.pallet &&
                            selectedPalletPlace?.pallet.barcode === barCode.data &&
                            parcelId
                        ) {
                            createCustomerReturn({
                                parcelId: `${parcelId}`,
                                palletBarcode: barCode.data,
                                quantity,
                            });
                        } else {
                            message.error(
                                `${
                                    isNewPallet ? "L'emplacement sélectionné" : 'La palette sélectionnée'
                                } ne correspond pas ${isNewPallet ? 'à celui scanné' : 'à celle scannée'}`
                            );
                        }
                    }
                }
            },
            {
                disable: !modal.visible,
            }
        );

        return (
            <CustomModal
                {...antdModal(modal)}
                footer={
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={onAdd}
                                loading={customerReturnCreateState.loading}
                                disabled={!placeReference && !palletReference}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={async () => await hide()}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                }
                width={368}
                title={'rangement'}
                altTitle
            >
                <ArrowNavItem scope={shortcutScope}>
                    <Input
                        placeholder={`Flasher ${
                            returnZoneType === ReturnZoneType.picking || isNewPallet ? "l'emplacement" : 'la palette'
                        }`}
                        onChange={onChangeField}
                        ref={inputRef}
                        autoFocus
                    />
                </ArrowNavItem>
            </CustomModal>
        );
    }
);

export default ReturnScanModal;
