import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { OrderParcel } from '../api/apiTypes';

// Controlers
import {
    OrderParcelUpdatePayload,
    update as updateApiCall,
    OrderParcelDeletePayload,
    del as delApiCall,
} from '../api/orderParcels';

// States
export interface OrderParcelsState {
    update: RequestState<OrderParcel>;
    del: RequestState;
}

const initialState: OrderParcelsState = {
    update: { ...requestInitialState },
    del: { ...requestInitialState },
};

export const update = new EzeeAsyncAction<OrderParcelsState['update'], OrderParcelUpdatePayload, OrderParcel>(
    'orderParcels/update',
    initialState.update,
    requestReducer<OrderParcelsState['update'], OrderParcel>(initialState.update)
);

export const del = new EzeeAsyncAction<OrderParcelsState['del'], OrderParcelDeletePayload>(
    'orderParcels/del',
    initialState.del,
    requestReducer<OrderParcelsState['del'], OrderParcel>(initialState.del)
);

// Reducer
export const orderParcelsReducer = combineReducers<OrderParcelsState>({
    update: update.reducer,
    del: del.reducer,
});

// Saga
export function* orderParcelsSaga() {
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
}

// Store helpers
export const getOrderParcelUpdateState = (state: MainReducerState) => state.orderParcels.update;
export const getOrderParcelDeleteState = (state: MainReducerState) => state.orderParcels.del;
