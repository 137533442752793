import { Input, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ControlLoadingOrder, ControlOrderPallet } from '../../store/api/apiTypes';
import {
    controlPallet,
    controlPallets,
    getControlOrderPalletControlPalletsState,
    getControlOrderPalletControlPalletState,
} from '../../store/actions/controlOrderPallets';
import {
    checkControlLoadingOrderCompletion,
    getControlLoadingOrderCheckCompletionStateById,
} from '../../store/actions/controlLoadingOrders';

import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import QuantityCard from '../../components/QuantityCard';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { formatNumber } from '../../helpers/i18n';
import SuccessMessage from '../../components/SuccessMessage';
import { getRoute, RoutePathName } from '../../routes';

const shortcutScope = 'ControlScanModals';
let timeout: number;

interface ControlScanModalProps extends CustomModalProps {
    checkedQuantity?: number;
    quantityToCheck?: number;
    controlOrderPalletId?: ControlOrderPallet['id'];
    controlLoadingOrderId?: ControlLoadingOrder['id'];
    onFinish: () => void;
    isPlatform?: boolean;
}

const ControlScanModal: FC<ControlScanModalProps> = ({
    visible,
    onCancel,
    checkedQuantity,
    quantityToCheck,
    controlOrderPalletId,
    controlLoadingOrderId,
    isPlatform,
    onFinish,
}) => {
    const history = useHistory();
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [barcodes, setBarcodes] = useState<string[]>([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isManual, setIsManual] = useState(false);
    const [manualInputCode, setManualInputCode] = useState<string>();
    const [fetchControlPallet, sendControlPallets, checkCompletion] = useActions([
        controlPallet.trigger,
        controlPallets.trigger,
        checkControlLoadingOrderCompletion.trigger,
    ]);
    const controlPalletState = useSelector(getControlOrderPalletControlPalletState);
    const controlPalletsState = useSelector(getControlOrderPalletControlPalletsState);
    const checkCompletionState = useSelector(
        getControlLoadingOrderCheckCompletionStateById(controlLoadingOrderId ?? -1, controlOrderPalletId)
    );
    const previous = usePrevious({
        controlPalletState,
        controlPalletsState,
        checkCompletionState,
    });
    const onClose = useCallback(() => {
        onCancel?.({} as React.MouseEvent<HTMLElement, MouseEvent>);
    }, [onCancel]);
    const onSubmit = () => {
        if (isPlatform) {
            sendControlPallets({
                controlLoadingOrderId,
                barcodes,
            });
        } else {
            sendControlPallets({
                controlOrderPalletId,
                barcodes,
            });
        }
    };

    const submitManualPalletCheck = () => {
        if (manualInputCode) {
            if (barcodes.includes(manualInputCode)) {
                message.error('Cette palette a déjà été controlée');
            } else {
                if (isPlatform) {
                    fetchControlPallet({
                        controlLoadingOrderId,
                        reference: manualInputCode,
                    });
                } else {
                    fetchControlPallet({
                        controlOrderPalletId,
                        reference: manualInputCode,
                    });
                }
            }
        }
    };

    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (barcodes.includes(barCode.data)) {
                    message.error('Cette palette a déjà été controlée');
                } else {
                    if (isPlatform) {
                        fetchControlPallet({
                            controlLoadingOrderId,
                            barcode: barCode.data,
                        });
                    } else {
                        fetchControlPallet({
                            controlOrderPalletId,
                            barcode: barCode.data,
                        });
                    }
                }
            }
        },
        {
            deps: [fetchControlPallet, controlOrderPalletId, controlLoadingOrderId, barcodes],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    useEffect(() => {
        if (visible && previous?.controlPalletState.loading && !controlPalletState.loading) {
            if (controlPalletState.error) {
                if (controlPalletState.error?.status === 400) {
                    if (controlPalletState.error?.data?.palletNotForThisLoad) {
                        message.error('Cette palette ne fait pas partie de ce client');
                    } else {
                        message.error('Une erreur est survenue pendant le contrôle de la palette (400)');
                    }
                } else if (controlPalletState.error?.status === 404 && controlPalletState.error?.data?.palletNotFound) {
                    message.error('Palette non trouvée ou ne faisant pas partie de ce client');
                } else {
                    message.error(
                        `Une erreur est survenue pendant le contrôle de la palette (${
                            controlPalletState.error?.status ?? '0'
                        })`
                    );
                }
            } else {
                if (controlPalletState.data?.barcode) {
                    setBarcodes((data) => [...data, controlPalletState.data!.barcode]);
                }
                setIsManual(false);
                setManualInputCode(undefined);
            }
        }
    }, [
        previous?.controlPalletState.loading,
        controlPalletState.loading,
        controlPalletState.error,
        controlPalletState.data,
        visible,
    ]);

    useEffect(() => {
        if (visible && previous?.controlPalletsState.loading && !controlPalletsState.loading) {
            if (controlPalletsState.error) {
                message.error('Une erreur est survenue pendant le contrôle des palettes');
            } else {
                checkCompletion({
                    controlLoadingOrderId,
                    controlOrderPalletId,
                });
            }
        }
    }, [
        previous?.controlPalletsState.loading,
        controlPalletsState.loading,
        controlPalletsState.error,
        controlLoadingOrderId,
        controlOrderPalletId,
        checkCompletion,
        visible,
    ]);

    useEffect(() => {
        if (previous?.checkCompletionState.loading && !checkCompletionState.loading) {
            if (!checkCompletionState.error) {
                if (checkCompletionState.data?.controlLoadingOrder) {
                    setSuccessMessage('Toutes les palettes du chargement ont été contrôlées');
                    setIsSuccess(true);
                    onFinish();
                    timeout = window.setTimeout(() => {
                        if (timeout) {
                            onClose();
                            history.push(
                                getRoute(RoutePathName.controlLoadingOrderDetails, {
                                    controlLoadingOrderId: controlLoadingOrderId ?? '',
                                })
                            );
                        }
                    }, 2000);
                } else if (checkCompletionState.data?.controlOrderPallet) {
                    setSuccessMessage('Toutes les palettes du client ont été contrôlées');
                    setIsSuccess(true);
                    timeout = window.setTimeout(() => {
                        if (timeout) {
                            onClose();
                            onFinish();
                        }
                    }, 2000);
                } else {
                    onClose();
                    onFinish();
                }
            }
        }
    }, [
        previous?.checkCompletionState.loading,
        checkCompletionState.loading,
        checkCompletionState.error,
        checkCompletionState.data?.controlLoadingOrder,
        checkCompletionState.data?.controlOrderPallet,
        onClose,
        onFinish,
        history,
        controlLoadingOrderId,
    ]);

    useEffect(() => {
        if (!visible) {
            setBarcodes([]);
            setIsSuccess(false);
        }
    }, [visible]);

    useEffect(() => () => window.clearTimeout(timeout), []);

    return (
        <CustomModal
            footer={
                !isSuccess && (
                    <>
                        {isManual ? (
                            <>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="enter"
                                        type="primary"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        onClick={() => submitManualPalletCheck()}
                                        loading={controlPalletState.loading}
                                    >
                                        Valider
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="esc"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        type="primary"
                                        onClick={setIsManual.bind(null, false)}
                                        ghost
                                    >
                                        Annuler
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </>
                        ) : (
                            <>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="enter"
                                        type="primary"
                                        shortcutScope={shortcutScope}
                                        onClick={onSubmit}
                                        loading={controlPalletsState.loading}
                                        disabled={!barcodes.length}
                                    >
                                        Valider
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="f1"
                                        type="ghost"
                                        shortcutScope={shortcutScope}
                                        onClick={setIsManual.bind(null, true)}
                                        loading={controlPalletsState.loading}
                                    >
                                        Saisir manuellement
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="esc"
                                        shortcutScope={shortcutScope}
                                        type="primary"
                                        onClick={onClose}
                                        ghost
                                    >
                                        Annuler
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </>
                        )}
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={!isSuccess ? 'Veuillez scanner les palettes que vous souhaitez charger :' : undefined}
            width={368}
            keyboard={false}
        >
            {isSuccess ? (
                <SuccessMessage message={successMessage} />
            ) : (
                <>
                    {isManual ? (
                        <ArrowNavItem scope={shortcutScope}>
                            <Input
                                placeholder="Saisir référence palette"
                                autoFocus
                                onChange={(e) => setManualInputCode(e.target.value)}
                            />
                        </ArrowNavItem>
                    ) : (
                        <QuantityCard
                            value={`${formatNumber((checkedQuantity ?? 0) + barcodes.length)}/${formatNumber(
                                quantityToCheck
                            )}`}
                            label="palettes contrôlées"
                            outline
                        />
                    )}
                </>
            )}
        </CustomModal>
    );
};

export default ControlScanModal;
