import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { LoadingOrder } from '../api/apiTypes';
import { ListResponse } from '../api/';

// Controlers
import {
    LoadingOrderListPayload,
    list as listApiCall,
    LoadingOrderUpdatePayload,
    update as updateApiCall,
    details as detailsApiCall,
    del as delApiCall,
    LoadingOrderDetailsPayload,
    LoadingOrderOrderRemovePayload,
} from '../api/loadingOrders';

// States
export interface LoadingOrdersState {
    list: RequestState<ListResponse<LoadingOrder>>;
    update: RequestState<LoadingOrder>;
    del: RequestState;
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: LoadingOrder | undefined;
        };
    };
}

const initialState: LoadingOrdersState = {
    list: { ...requestInitialState },
    update: { ...requestInitialState },
    del: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
};

export const list = new EzeeAsyncAction<
    LoadingOrdersState['list'],
    LoadingOrderListPayload,
    ListResponse<LoadingOrder>
>('loadingOrders/list', initialState.list, {
    trigger: (state, _, meta) => ({
        ...state,
        loading: true,
        data: meta?.loadMore ? state.data : undefined,
    }),
    success: (state, payload, meta) => ({
        data: {
            ...payload,
            items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.list,
    }),
});

export const update = new EzeeAsyncAction<LoadingOrdersState['update'], LoadingOrderUpdatePayload, LoadingOrder>(
    'loadingOrders/update',
    initialState.update,
    requestReducer<LoadingOrdersState['update'], LoadingOrder>(initialState.update)
);

export const del = new EzeeAsyncAction<LoadingOrdersState['del'], LoadingOrderOrderRemovePayload>(
    'loadingOrders/del',
    initialState.del,
    requestReducer<LoadingOrdersState['del']>(initialState.del)
);

export const details = new EzeeAsyncAction<LoadingOrdersState['details'], LoadingOrderDetailsPayload, LoadingOrder>(
    'loadingOrders/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

// Reducer
export const loadingOrdersReducer = combineReducers<LoadingOrdersState>({
    list: list.reducer,
    update: update.reducer,
    del: del.reducer,
    details: details.reducer,
});

// Saga
export function* loadingOrdersSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
}

// Store helpers
export const getLoadingOrderListState = (state: MainReducerState) => state.loadingOrders.list;
export const getLoadingOrderUpdateState = (state: MainReducerState) => state.loadingOrders.update;
export const getLoadingOrderStateById = (id?: LoadingOrder['id']) => (state: MainReducerState) => ({
    loading: state.loadingOrders.details.loading,
    error: state.loadingOrders.details.error,
    data: id ? state.loadingOrders.details.data[id] : undefined,
});
export const getLoadingOrderDelState = (state: MainReducerState) => state.loadingOrders.del;
