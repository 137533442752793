import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { Production } from '../api/apiTypes';

// Controlers
import { ProductionUpdatePayload, update as updateApiCall } from '../api/productions';

// States
export interface ProductionsState {
    update: RequestState<Production>;
}

const initialState: ProductionsState = {
    update: { ...requestInitialState },
};

export const update = new EzeeAsyncAction<ProductionsState['update'], ProductionUpdatePayload, Production>(
    'productions/update',
    initialState.update,
    requestReducer<ProductionsState['update'], Production>(initialState.update)
);

// Reducer
export const productionsReducer = combineReducers<ProductionsState>({
    update: update.reducer,
});

// Saga
export function* productionsSaga() {
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
}

// Store helpers
export const getProductionUpdateState = (state: MainReducerState) => state.productions.update;
