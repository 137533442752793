import {
    DndContext,
    DropAnimation,
    KeyboardSensor,
    Modifiers,
    MouseSensor,
    PointerActivationConstraint,
    TouchSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
    closestCenter,
    defaultDropAnimation,
    DragOverlay,
} from '@dnd-kit/core';
import {
    AnimateLayoutChanges,
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Empty, EmptyProps, Skeleton, Typography } from 'antd';
import React, { CSSProperties, ReactNode, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import '../../assets/styles/List.less';

import { classNames } from '../../helpers';
import BasicList from '../BasicList';
import TitleBlack from '../TitleBlack';
import { ListProps } from './List';
import ListRow from './ListRow';
import SortableListRow from './SortableListRow';

const defaultDropAnimationConfig: DropAnimation = {
    ...defaultDropAnimation,
    dragSourceOpacity: 0.5,
};

export interface SortableListColumn<RecordType> {
    dataIndex?: string;
    key?: string;
    render?: (record: RecordType) => ReactNode;
    hidden?: (record: RecordType) => boolean;
    title?: ReactNode;
    style?: CSSProperties;
    cellStyle?: CSSProperties;
    flex: string;
}

interface SortableListProps<RecordType> extends ListProps<RecordType> {
    data?: RecordType[];
    columns: Array<SortableListColumn<RecordType>>;
    rowKey: (record: RecordType, index: number) => string;
    className?: string;
    renderCellFooter?: (record: RecordType) => ReactNode;
    headerInCell?: boolean;
    isRowHiglighted?: (record: RecordType) => boolean;
    isRowWarning?: (record: RecordType) => boolean;
    isRowError?: (record: RecordType) => boolean;
    isRowLocked?: (record: RecordType) => boolean;
    isSelectionDisabled?: (record: RecordType) => boolean;
    isLoading?: boolean;
    loadingRowsCount?: number;
    hideWhenEmpty?: boolean;
    titleSkeletonWidth?: number;
    title?: string | undefined;
    emptyDescription?: EmptyProps['description'];
    emptyImage?: EmptyProps['image'];
    columnWidthGrow?: boolean;
    altTitle?: boolean;
    noWarningIcon?: (record: RecordType) => boolean;
    size?: 'small' | 'middle' | 'large';

    activationConstraint?: PointerActivationConstraint;
    animateLayoutChanges?: AnimateLayoutChanges;
    adjustScale?: boolean;
    dropAnimation?: DropAnimation | null;
    itemCount?: number;
    items?: string[];
    modifiers?: Modifiers;
    useDragOverlay?: boolean;
    getItemStyles?: (args: {
        id: UniqueIdentifier;
        index: number;
        isSorting: boolean;
        isDragOverlay: boolean;
        overIndex: number;
        isDragging: boolean;
    }) => CSSProperties;
    wrapperStyle?: (args: { index: number; isDragging: boolean; id: string }) => CSSProperties;
    isDragDisabled?: (item: RecordType) => boolean;
    onOrderChange: (item: RecordType & { newIndex: number }) => void;
}

function SortableList<RecordType extends Record<string, any>>({
    data,
    className,
    columns,
    rowKey,
    renderCellFooter,
    headerInCell,
    isRowHiglighted,
    isRowWarning,
    isRowError,
    isRowLocked,
    isSelectionDisabled,
    isLoading,
    loadingRowsCount = 4,
    hideWhenEmpty,
    titleSkeletonWidth,
    title,
    emptyDescription,
    emptyImage,
    columnWidthGrow = true,
    altTitle,
    noWarningIcon,
    size = 'middle',

    activationConstraint,
    animateLayoutChanges,
    adjustScale = false,
    dropAnimation = defaultDropAnimationConfig,
    getItemStyles = () => ({}),
    modifiers,
    useDragOverlay = true,
    wrapperStyle = () => ({}),
    isDragDisabled,
    onOrderChange,
    ...props
}: SortableListProps<RecordType>) {
    const getColumnKey = useCallback((column: SortableListColumn<RecordType>, columnIndex: number) => {
        return `${(column.dataIndex as string | number) ?? column.key ?? columnIndex}`;
    }, []);
    const titleRender = title ? (
        <>
            {altTitle ? (
                <Typography.Title level={3} className="green-title">
                    {title}
                </Typography.Title>
            ) : (
                <TitleBlack>{title}</TitleBlack>
            )}
        </>
    ) : null;
    const [items, setItems] = useState<RecordType[]>(data ?? []);
    const [activeId, setActiveId] = useState<string | null>(null);
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint,
        }),
        useSensor(TouchSensor, {
            activationConstraint,
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const activeIndex = activeId ? items.findIndex((item) => item.id === parseInt(activeId, 10)) : -1;

    useEffect(() => {
        if (data) {
            setItems(data);
        }
    }, [data]);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={({ active }) => {
                if (!active) {
                    return;
                }

                setActiveId(active.id);
            }}
            onDragEnd={({ over }) => {
                setActiveId(null);

                if (over) {
                    const overIndex = items.findIndex((item) => item.id === over.id);
                    if (activeIndex !== overIndex) {
                        const itemToUpdate = { ...items[activeIndex], newIndex: items[overIndex].index };
                        onOrderChange(itemToUpdate);
                        setItems((items) => arrayMove(items, activeIndex, overIndex));
                    }
                }
            }}
            onDragCancel={() => setActiveId(null)}
            modifiers={modifiers}
        >
            <div className={classNames('list-wrapper', !columnWidthGrow && 'no-column-grow')} {...props}>
                {title &&
                    (hideWhenEmpty ? (
                        <>
                            {isLoading && <Skeleton paragraph={false} title={{ width: titleSkeletonWidth ?? 150 }} />}
                            {!isLoading && !!data?.length && titleRender}
                        </>
                    ) : (
                        titleRender
                    ))}
                {isLoading && (
                    <BasicList className={classNames('list', headerInCell && 'list-header-in-cell', className)}>
                        {!headerInCell && (
                            <li className={classNames('list-row', 'list-row-column')} style={{ marginBottom: '1rem' }}>
                                {columns.map((column, columnIndex) => (
                                    <div key={columnIndex} style={{ ...column.style, flex: column.flex }}>
                                        <Skeleton.Button />
                                    </div>
                                ))}
                            </li>
                        )}
                        {Array.from({ length: loadingRowsCount }, (_, index) => (
                            <li key={index}>
                                <Skeleton paragraph={false} title active />
                                {typeof renderCellFooter === 'function' && <Skeleton paragraph={false} title active />}
                            </li>
                        ))}
                    </BasicList>
                )}
                {!isLoading && !data?.length && !hideWhenEmpty && (
                    <Empty image={emptyImage ?? Empty.PRESENTED_IMAGE_SIMPLE} description={emptyDescription} />
                )}
                {!isLoading && !!data?.length && (
                    <>
                        <SortableContext
                            items={
                                items as unknown as Array<{
                                    id: UniqueIdentifier;
                                }>
                            }
                            strategy={verticalListSortingStrategy}
                        >
                            <BasicList className={classNames('list', headerInCell && 'list-header-in-cell', className)}>
                                {!headerInCell && (
                                    <li className={classNames('list-row', 'list-row-column')}>
                                        {columns.map((column, columnIndex) => (
                                            <div
                                                className="list-column"
                                                key={getColumnKey(column, columnIndex)}
                                                style={{
                                                    ...column.style,
                                                    flex: column.flex,
                                                }}
                                            >
                                                {column.title}
                                            </div>
                                        ))}
                                    </li>
                                )}
                                {items.map((record) => (
                                    <SortableListRow<RecordType>
                                        {...props}
                                        record={record}
                                        columns={columns}
                                        index={record.index}
                                        isHiglighted={typeof isRowHiglighted === 'function' && isRowHiglighted(record)}
                                        isWarning={typeof isRowWarning === 'function' && isRowWarning(record)}
                                        isError={typeof isRowError === 'function' && isRowError(record)}
                                        isLocked={typeof isRowLocked === 'function' && isRowLocked(record)}
                                        isSelectionDisabled={
                                            typeof isSelectionDisabled === 'function' && isSelectionDisabled(record)
                                        }
                                        key={rowKey(record, record.index)}
                                        renderCellFooter={renderCellFooter}
                                        headerInCell={headerInCell}
                                        noWarningIcon={noWarningIcon}
                                        id={record.id}
                                        style={getItemStyles}
                                        wrapperStyle={wrapperStyle}
                                        animateLayoutChanges={animateLayoutChanges}
                                        useDragOverlay={useDragOverlay}
                                        isDragDisabled={isDragDisabled?.(record)}
                                        size={size}
                                    />
                                ))}
                            </BasicList>
                        </SortableContext>
                        {createPortal(
                            <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
                                {activeId !== null && activeIndex !== -1 ? (
                                    <ListRow<RecordType>
                                        record={items[activeIndex]}
                                        columns={columns}
                                        index={-1}
                                        size={size}
                                        isHiglighted={
                                            typeof isRowHiglighted === 'function' && isRowHiglighted(items[activeIndex])
                                        }
                                        isWarning={
                                            typeof isRowWarning === 'function' && isRowWarning(items[activeIndex])
                                        }
                                        isError={typeof isRowError === 'function' && isRowError(items[activeIndex])}
                                        isLocked={typeof isRowLocked === 'function' && isRowLocked(items[activeIndex])}
                                        isSelectionDisabled={
                                            typeof isSelectionDisabled === 'function' &&
                                            isSelectionDisabled(items[activeIndex])
                                        }
                                        wrapperStyle={wrapperStyle({
                                            index: activeIndex,
                                            isDragging: true,
                                            id: items[activeIndex].id,
                                        })}
                                        style={getItemStyles({
                                            id: items[activeIndex].id,
                                            index: activeIndex,
                                            isSorting: activeId !== null,
                                            isDragging: true,
                                            overIndex: -1,
                                            isDragOverlay: true,
                                        })}
                                        dragOverlay
                                    />
                                ) : null}
                            </DragOverlay>,
                            document.body
                        )}
                    </>
                )}
            </div>
        </DndContext>
    );
}

export default SortableList;
