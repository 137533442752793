import React, { FC, useState, useCallback } from 'react';
import ButtonWithShortcut from './ButtonWithShortcut';
import CustomModal, { CustomModalProps } from './CustomModal';
import ArrowNavItem from './ArrowNavItem';
import { useArrowNavScope, useShortcutScope } from '../hooks';
import QuantityInput from './QuantityInput';
import TitleBlack from './TitleBlack';

const shortcutScope = 'QuantitySelectModal';

interface QuantitySelectModalProps extends CustomModalProps {
    onSubmit: (packagesQuantity: number) => void;
    onReturn?: () => void;
    subTitle?: string;
    text?: string;
    max: number;
}

const QuantitySelectModal: FC<QuantitySelectModalProps> = ({
    visible,
    onReturn,
    cancelText,
    onSubmit,
    title,
    subTitle,
    text,
    max,
    onCancel,
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const [quantity, setQuantity] = useState<number>(max);

    const handleSubmit = () => {
        onSubmit(quantity);
    };

    return (
        <CustomModal
            visible={visible}
            title={title}
            width={368}
            keyboard={false}
            altTitle
            transitionName=""
            maskTransitionName=""
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            onClick={handleSubmit}
                            shortcut="enter"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            disabled={quantity <= 0 || quantity > max}
                        >
                            Valider
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onReturn ?? onClose}
                            ghost
                        >
                            {cancelText ?? 'Annuler'}
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
        >
            <ArrowNavItem scope={shortcutScope}>
                {subTitle && <TitleBlack>{subTitle}</TitleBlack>}
                <QuantityInput max={max} value={quantity} onChange={setQuantity} text={text} />
            </ArrowNavItem>
        </CustomModal>
    );
};

export default QuantitySelectModal;
