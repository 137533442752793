import { configureStore as configStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

import mainReducer from './reducers';
import mainSaga from './sagas';

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares: any[] = [sagaMiddleware];

    if (process.env.NODE_ENV === 'development') {
        middlewares.push(logger);
    }

    const store = configStore({
        reducer: mainReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
        devTools: process.env.NODE_ENV !== 'production',
    });

    sagaMiddleware.run(mainSaga);

    return store;
};

export default configureStore;
