import React, { FC, useEffect } from 'react';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import { useHistory } from 'react-router-dom';
import { Descriptions } from 'antd';
import PageHeader from '../../components/PageHeader';
import { useSelector } from 'react-redux';
import { getParcelScanState } from '../../store/actions/parcels';

const ParcelCheckingDetails: FC = () => {
    const history = useHistory();
    const parcelScanState = useSelector(getParcelScanState);
    useEffect(() => {
        if (!parcelScanState.loading && !parcelScanState.data) {
            history.push('/');
        }
    }, [history, parcelScanState.data, parcelScanState.loading]);
    return (
        <>
            <Seo title={'Détail référence'} />
            <Header
                title={'Détail référence'}
                onClickBack={() => {
                    history.goBack();
                }}
            />
            <PageHeader>
                <Descriptions column={{ xs: 2 }} size="small" colon={false} layout="vertical">
                    <Descriptions.Item label="Référence">{parcelScanState.data?.reference}</Descriptions.Item>
                    <Descriptions.Item label="Désignation">{parcelScanState.data?.label}</Descriptions.Item>
                    <Descriptions.Item label="Dimensions (L * l * Ep)">
                        {parcelScanState.data?.size?.width ?? '—'}
                        {' * '}
                        {parcelScanState.data?.size?.height ?? '—'}
                        {' * '}
                        {parcelScanState.data?.size?.depth ?? '—'}
                    </Descriptions.Item>
                </Descriptions>
            </PageHeader>
        </>
    );
};

export default ParcelCheckingDetails;
