import { Descriptions, Spin, Divider, Card, Button, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    Pallet,
    PalletStatus,
    Permission,
    PermissionRight,
    PlatformReceptionStatus,
} from '../../../store/api/apiTypes';
import {
    details,
    getPlatformReceptionDetailsStateById,
    palletList,
    getPlatformReceptionPalletListState,
    sendPallets as platformReceptionSendPallets,
    getPlatformReceptionSendPalletsState,
} from '../../../store/actions/platformReceptions';
import { getUser } from '../../../store/actions/auth';

import {
    formatNumber,
    getPlatformReceptionSendPalletsError,
    translatePalletStatus,
    translatePlatformReceptionStatus,
} from '../../../helpers/i18n';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonGrey from '../../../components/ButtonGrey';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import FixedFooter from '../../../components/FixedFooter';
import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import PageHeader from '../../../components/PageHeader';
import QuantityCard from '../../../components/QuantityCard';
import Seo from '../../../components/Seo';
import { useActions, usePrevious } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import { IconWarning } from '../../../components/icons';
import { isZebra } from '../../../helpers/enterprise-browser';
import { hasPermission } from '../../../helpers/security';
import BasicList from '../../../components/BasicList';
import PalletScanModal from './PalletScanModal';
import PalletAnomalyModal from './PalletAnomalyModal';
import SuccessModal from '../../../components/SuccessModal';

const PlatformReception: FC = () => {
    const { platformReceptionId } = useParams<{ platformReceptionId: string }>();
    const [isPalletScanModalVisible, setIsPalletScanModalVisible] = useState(false);
    const [isAnomalyModalVisible, setIsAnomalyModalVisible] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [selectedPallet, setSelectedPallet] = useState<Pallet>();
    const [fetchPlatformReception, listPallets, sendPallets] = useActions([
        details.trigger,
        palletList.trigger,
        platformReceptionSendPallets.trigger,
    ]);
    const palletListState = useSelector(getPlatformReceptionPalletListState);
    const platformReceptionState = useSelector(getPlatformReceptionDetailsStateById(parseInt(platformReceptionId, 10)));
    const sendPalletsState = useSelector(getPlatformReceptionSendPalletsState);
    const user = useSelector(getUser);
    const canEditDesktop = !isZebra && hasPermission(user, Permission.platformReception, PermissionRight.write);
    const previous = usePrevious({ sendPalletsState });
    const onDeclareAnomaly = () => {
        if (!selectedPallet) {
            message.error('Veuillez sélectionner un lot');
        } else {
            setIsAnomalyModalVisible(true);
        }
    };
    const onMarkAsReceived = () => {
        sendPallets({
            platformReceptionId,
            references: [{ reference: selectedPallet?.reference }],
        });
    };
    const onClickReceive = () => {
        setIsPalletScanModalVisible(true);
    };
    const onClosePalletScanModal = useCallback(() => {
        setIsPalletScanModalVisible(false);
    }, []);
    const onCloseSuccessModal = useCallback(() => {
        setIsSuccessModalVisible(false);
    }, []);
    const onPalletScanModalFinish = useCallback(() => {
        fetchPlatformReception({ platformReceptionId });
        listPallets({
            platformReceptionId,
        });
        if (sendPalletsState.data?.status === PlatformReceptionStatus.completed) {
            setIsSuccessModalVisible(true);
        }
    }, [fetchPlatformReception, listPallets, platformReceptionId, sendPalletsState.data?.status]);
    const onPalletScanModalAnomaly = useCallback(() => {
        window.setTimeout(() => {
            setIsAnomalyModalVisible(true);
        }, 300);
    }, []);
    const onClosePalletAnomalyModal = useCallback(() => {
        setIsAnomalyModalVisible(false);
    }, []);
    const onPalletAnomalyModalFinish = useCallback(() => {
        if (isZebra) {
            setIsPalletScanModalVisible(true);
        } else {
            fetchPlatformReception({ platformReceptionId });
            listPallets({
                platformReceptionId,
            });

            if (sendPalletsState.data?.status === PlatformReceptionStatus.completed) {
                setIsSuccessModalVisible(true);
            }
        }
    }, [fetchPlatformReception, listPallets, platformReceptionId, sendPalletsState.data?.status]);
    const onPalletAnomalyModalCancel = useCallback(() => {
        if (isZebra) {
            setIsPalletScanModalVisible(true);
        }
    }, []);
    const onLoadMore = () => {
        listPallets(
            {
                platformReceptionId,
                page: (palletListState.data?.page ?? 0) + 1,
            },
            { loadMore: true }
        );
    };
    const columns: Array<ListColumn<Pallet>> = [
        {
            dataIndex: 'reference',
            title: 'N° de lot',
            flex: '1 1 60%',
        },
        {
            key: 'status',
            title: 'Statut',
            flex: '1 1 40%',
            render: (record) => translatePalletStatus(record.status, true),
        },
    ];

    useEffect(() => {
        if (platformReceptionId) {
            fetchPlatformReception({ platformReceptionId });
            listPallets({
                platformReceptionId,
            });
        }
    }, [fetchPlatformReception, listPallets, platformReceptionId]);

    useEffect(() => {
        if (!isAnomalyModalVisible && previous?.sendPalletsState.loading && !sendPalletsState.loading) {
            if (sendPalletsState.error) {
                const requestError = getPlatformReceptionSendPalletsError(sendPalletsState, true);
                if (requestError.message) {
                    message.error(requestError.message);
                }
            } else {
                fetchPlatformReception({ platformReceptionId });
                listPallets({
                    platformReceptionId,
                });

                if (sendPalletsState.data?.status === PlatformReceptionStatus.completed) {
                    setIsSuccessModalVisible(true);
                }
            }
        }
    }, [
        previous?.sendPalletsState.loading,
        sendPalletsState.loading,
        sendPalletsState.error,
        sendPalletsState,
        fetchPlatformReception,
        platformReceptionId,
        listPallets,
        isAnomalyModalVisible,
    ]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Réception" />
            <Header title="Réception" backRoute={getRoute(RoutePathName.platformHome)} />
            <PageHeader>
                <Spin spinning={platformReceptionState.loading}>
                    <Descriptions column={4} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="N° tournée">
                            {platformReceptionState.data?.loadingOrder?.tourNumber}
                        </Descriptions.Item>
                        <Descriptions.Item label="Statut">
                            {translatePlatformReceptionStatus(platformReceptionState.data?.status)}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>
            <QuantityCard
                label={
                    platformReceptionState.data?.status !== PlatformReceptionStatus.toBeProcessed
                        ? 'Lots réceptionnés'
                        : 'Lots à réceptionner'
                }
                value={
                    platformReceptionState.data?.status !== PlatformReceptionStatus.toBeProcessed
                        ? `${formatNumber(platformReceptionState.data?.receivedQuantity)}/${formatNumber(
                              platformReceptionState.data?.quantity
                          )}`
                        : formatNumber(platformReceptionState.data?.quantity)
                }
                isComplete={platformReceptionState.data?.status === PlatformReceptionStatus.completed}
                isWarning={platformReceptionState.data?.status === PlatformReceptionStatus.partial}
            />
            <Divider />
            <List<Pallet>
                columns={columns}
                data={palletListState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowFocus={
                    canEditDesktop
                        ? (record) => record.status === PalletStatus.loaded && setSelectedPallet(record)
                        : undefined
                }
                onRowClick={
                    canEditDesktop
                        ? (record) => record.status === PalletStatus.loaded && setSelectedPallet(record)
                        : undefined
                }
                isRowSelected={canEditDesktop ? (record) => record.reference === selectedPallet?.reference : undefined}
                isRowHiglighted={(record) => record.status === PalletStatus.receivedInPlatform}
                isRowWarning={(record) => record.status === PalletStatus.receivedInPlatformDamaged}
                isLoading={palletListState.loading && palletListState.data?.page === undefined}
            />
            {palletListState.data && palletListState.data.page < palletListState.data.pageCount - 1 && (
                <Button
                    onClick={onLoadMore}
                    loading={palletListState.loading}
                    size="small"
                    style={{ margin: '1rem 0', fontSize: 14 }}
                    block
                >
                    Plus de résultats
                </Button>
            )}
            {palletListState.data?.totalCount === 0 && (
                <Card>
                    <p style={{ margin: 0, textAlign: 'center', color: '#EA9741' }}>
                        <IconWarning style={{ fontSize: '1.5rem' }} />
                        <br />
                        Il n&rsquo;y a pas de lots à réceptionner
                    </p>
                </Card>
            )}
            {isZebra && (
                <PalletScanModal
                    visible={isPalletScanModalVisible}
                    onCancel={onClosePalletScanModal}
                    onFinish={onPalletScanModalFinish}
                    onAnomaly={onPalletScanModalAnomaly}
                />
            )}
            {isAnomalyModalVisible && (
                <PalletAnomalyModal
                    visible={isAnomalyModalVisible}
                    onCancel={onClosePalletAnomalyModal}
                    onFinish={onPalletAnomalyModalFinish}
                    onCancelAnomaly={onPalletAnomalyModalCancel}
                    palletReference={selectedPallet?.reference}
                />
            )}
            <SuccessModal
                visible={isSuccessModalVisible}
                onCancel={onCloseSuccessModal}
                message="Tous les lots ont été reçus avec succès"
                footer={null}
                autoClose
            />
            {isZebra && (
                <FixedFooter>
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            shortcut="enter"
                            size="large"
                            as={ButtonGrey}
                            onClick={onClickReceive}
                            block
                        >
                            Réceptionner
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </FixedFooter>
            )}
            {canEditDesktop && selectedPallet && (
                <FixedFooter>
                    <BasicList inline inlineStretch>
                        <li>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    shortcut="enter"
                                    size="large"
                                    as={ButtonGrey}
                                    onClick={onMarkAsReceived}
                                    disabled={selectedPallet.status !== PalletStatus.loaded}
                                    loading={sendPalletsState.loading}
                                    block
                                >
                                    Marquer comme reçu
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </li>
                        <li>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    shortcut="1"
                                    type="primary"
                                    size="large"
                                    onClick={onDeclareAnomaly}
                                    disabled={selectedPallet.status !== PalletStatus.loaded}
                                    ghost
                                    block
                                >
                                    Déclarer en avarie
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </li>
                    </BasicList>
                </FixedFooter>
            )}
        </FixedFooter.Wrapper>
    );
};

export default PlatformReception;
