import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { CustomerOrderPlatformReasonCode } from '../api/apiTypes';

// Controlers
import {
    CustomerOrderPlatformReasonCodeUpdatePayload,
    update as updateApiCall,
    CustomerOrderPlatformReasonCodeSendMailPayload,
    sendMail as sendMailApiCall,
} from '../api/customerOrderPlatformReasonCodes';

// States
export interface CustomerOrderPlatformReasonCodesState {
    update: RequestState<CustomerOrderPlatformReasonCode>;
    sendMail: RequestState;
}

const initialState: CustomerOrderPlatformReasonCodesState = {
    update: { ...requestInitialState },
    sendMail: { ...requestInitialState },
};

export const update = new EzeeAsyncAction<
    CustomerOrderPlatformReasonCodesState['update'],
    CustomerOrderPlatformReasonCodeUpdatePayload,
    CustomerOrderPlatformReasonCode
>(
    'customerOrderPlatformReasonCodes/update',
    initialState.update,
    requestReducer<CustomerOrderPlatformReasonCodesState['update'], CustomerOrderPlatformReasonCode>(
        initialState.update
    )
);

export const sendMail = new EzeeAsyncAction<
    CustomerOrderPlatformReasonCodesState['sendMail'],
    CustomerOrderPlatformReasonCodeSendMailPayload,
    CustomerOrderPlatformReasonCode
>(
    'customerOrderPlatformReasonCodes/sendMail',
    initialState.sendMail,
    requestReducer<CustomerOrderPlatformReasonCodesState['sendMail']>(initialState.sendMail)
);

// Reducer
export const customerOrderPlatformReasonCodesReducer = combineReducers<CustomerOrderPlatformReasonCodesState>({
    update: update.reducer,
    sendMail: sendMail.reducer,
});

// Saga
export function* customerOrderPlatformReasonCodesSaga() {
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(sendMail.type.trigger, simpleAsyncSaga(sendMailApiCall, sendMail));
}

// Store helpers
export const getCustomerOrderPlatformReasonCodeUpdateState = (state: MainReducerState) =>
    state.customerOrderPlatformReasonCodes.update;
export const getCustomerOrderPlatformReasonCodeSendMailState = (state: MainReducerState) =>
    state.customerOrderPlatformReasonCodes.sendMail;
