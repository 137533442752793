import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getUser } from '../../store/actions/auth';
import { Permission, PermissionRight } from '../../store/api/apiTypes';

import { getRoute, RoutePathName } from '../../routes';
import { useIsMounted } from '../../hooks';
import BasicList from '../../components/BasicList';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ButtonLink from '../../components/ButtonLink';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import CustomModal from '../../components/CustomModal';
import SuccessMessage from '../../components/SuccessMessage';
import InventoryCreateTypeModal from './InventoryCreateTypeModal';
import { hasPermission } from '../../helpers/security';
import { isZebra } from '../../helpers/enterprise-browser';

const InventoryMenu: FC = () => {
    const isMounted = useIsMounted();
    const { createSuccess } = useLocation<{ createSuccess?: boolean }>().state || {};
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(!!createSuccess);
    const [isTypeModalVisible, setIsTypeModalVisible] = useState(false);
    const user = useSelector(getUser);
    const canEditDesktop = !isZebra && hasPermission(user, Permission.inventory, PermissionRight.write);

    useEffect(() => {
        let timeout: number;

        if (isSuccessModalVisible) {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    setIsSuccessModalVisible(false);
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [isMounted, setIsSuccessModalVisible, isSuccessModalVisible]);

    return (
        <>
            <Seo title="Inventaires tournants" />
            <Header title="Inventaires tournants" backRoute={getRoute(RoutePathName.home)} />
            <nav className="menu">
                <BasicList>
                    {canEditDesktop && (
                        <li>
                            <ArrowNavItem>
                                <ButtonWithShortcut shortcut="1" onClick={setIsTypeModalVisible.bind(null, true)} block>
                                    Déclencher inventaire
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </li>
                    )}
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="2"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.inventoryCurrent)}
                                block
                            >
                                Inventaires en cours
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="3"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.inventoryStockGap)}
                                block
                            >
                                Régularisation de stock
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="4"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.inventoryHistory)}
                                block
                            >
                                Historique des inventaires
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                </BasicList>
            </nav>
            <InventoryCreateTypeModal visible={isTypeModalVisible} onCancel={setIsTypeModalVisible.bind(null, false)} />
            <CustomModal
                footer={null}
                visible={isSuccessModalVisible}
                onCancel={setIsSuccessModalVisible.bind(null, false)}
                width={232}
                keyboard={false}
            >
                <SuccessMessage message="Inventaire créé avec succès." />
            </CustomModal>
        </>
    );
};

export default InventoryMenu;
