import {
    Button,
    Col,
    Descriptions,
    Empty,
    Form,
    FormProps,
    Input,
    message,
    Pagination,
    PaginationProps,
    Result,
    Row,
    Skeleton,
    Space,
    Spin,
    Typography,
} from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import printjs from 'print-js';

import '../../../assets/styles/LotVisualization.less';

import {
    CustomerOrder,
    Expedition,
    ExpeditionStatus,
    PlatformLoading,
    OrganizationType,
    Pallet,
    PalletStatus,
    Permission,
    PermissionRight,
    RoleName,
} from '../../../store/api/apiTypes';
import { list, getExpeditionListState } from '../../../store/actions/expeditions';
import {
    create,
    getPlatformLoadingCreateState,
    getPlatformLoadingPdfState,
    pdf,
} from '../../../store/actions/platformLoadings';
import { getUser } from '../../../store/actions/auth';

import useQueryParams from '../../../hooks/queryParams';
import FixedFooter from '../../../components/FixedFooter';
import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import PageHeader from '../../../components/PageHeader';
import Seo from '../../../components/Seo';
import { useActions, usePrevious } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import {
    defaultErrorMessage,
    formatDate,
    formatNumber,
    formatTimeFromSeconds,
    translateDayOfWeek,
    translatePalletStatus,
} from '../../../helpers/i18n';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonGrey from '../../../components/ButtonGrey';
import BasicList from '../../../components/BasicList';
import { hasPermission, hasRole } from '../../../helpers/security';
import DatePicker from '../../../components/DatePicker';
import DayOfWeekSelect from '../../../components/DayOfWeekSelect';
import OrganizationSelect from '../../../components/OrganizationSelect';
import ButtonRed from '../../../components/ButtonRed';
import SuccessModal from '../../../components/SuccessModal';
import PlatformLoadingDeleteModal from './PlatformLoadingDeleteModal';

let searchTimeout: number;
const palletColumns: Array<ListColumn<Pallet>> = [
    {
        dataIndex: 'reference',
        title: 'N° de lot',
        flex: '1 1 50%',
    },
    {
        key: 'type',
        title: 'Type',
        flex: '1 1 20%',
        render: (record) => (record.vrac ? 'Vrac' : 'Palette'),
    },
    {
        key: 'status',
        title: 'Statut',
        flex: '1 1 30%',
        render: (record) => translatePalletStatus(record.status, true),
    },
    {
        key: 'date',
        title: 'Date de réception',
        flex: '1 1 30%',
        render: (record) => formatDate(record?.platformReceptionDate),
    },
    {
        key: 'weight',
        title: 'Poids (kg)',
        flex: '1 1 30%',
        render: (record) => formatNumber(record.weight),
    },
];
const customerOrderColumns: Array<ListColumn<CustomerOrderWithExpeditionId>> = [
    {
        dataIndex: 'customer.reference',
        title: 'Code client',
        flex: '1 1 20%',
    },
    {
        key: 'date',
        title: 'Rendez-vous',
        flex: '1 1 30%',
        render: (record) => `${translateDayOfWeek(record.day, true)} ${formatTimeFromSeconds(record?.time)}`,
    },
    {
        dataIndex: 'customer.label',
        title: 'Client',
        flex: '1 1 50%',
    },
    {
        dataIndex: 'loadingOrder.organization.name',
        title: 'Usine',
        flex: '1 1 30%',
    },
    {
        dataIndex: 'loadingOrder.tourNumber',
        title: 'Tournée',
        flex: '1 1 30%',
    },
];

type CustomerOrderWithExpeditionId = CustomerOrder & {
    expeditionId: Expedition['id'];
};

const Expeditions: FC = () => {
    const [urlSearchParams, setSearchParams] = useQueryParams('Expedition');
    const page = urlSearchParams.get('page') !== null ? parseInt(urlSearchParams.get('page')!, 10) || 0 : 0;
    const user = useSelector(getUser);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [platformLoadingIdToDelete, setPlatformLoadingIdToDelete] = useState<PlatformLoading['id']>();
    const [listExpeditions, createPlatformLoading, fetchPlatformLoadingPdf] = useActions([
        list.trigger,
        create.trigger,
        pdf.trigger,
    ]);
    const expeditionsListState = useSelector(getExpeditionListState);
    const platformLoadingCreateState = useSelector(getPlatformLoadingCreateState);
    const platformLoadingPdfState = useSelector(getPlatformLoadingPdfState);
    const [selectedCustomerOrders, setSelectedCustomerOrders] = useState<CustomerOrderWithExpeditionId[]>();
    const canEdit = hasPermission(user, Permission.platformShipping, PermissionRight.write);
    const previous = usePrevious({ platformLoadingCreateState, platformLoadingPdfState });
    const refresh = useCallback(() => {
        setSelectedCustomerOrders(undefined);
        listExpeditions({
            customerReference: urlSearchParams.get('customerReference') ?? undefined,
            deliveryDate: urlSearchParams.get('deliveryDate')
                ? dayjs(Number(urlSearchParams.get('deliveryDate'))).toISOString()
                : undefined,
            day: urlSearchParams.get('day') ?? undefined,
            tour: urlSearchParams.get('tour') ?? undefined,
            plateformCode: urlSearchParams.get('plateformCode') ?? undefined,
            status: [ExpeditionStatus.toBeProcessed, ExpeditionStatus.inProgress],
            page,
        });
    }, [listExpeditions, urlSearchParams, page]);
    const onPaginationChange: PaginationProps['onChange'] = (p) => {
        setSearchParams({
            page: p ? p - 1 : 0,
        });
    };
    const onFilterSubmit: FormProps['onValuesChange'] = (values) => {
        const val = { ...values, page: undefined };

        Object.keys(val).forEach((key) => {
            if (val[key] === '' || val[key] === null) {
                val[key] = undefined;
            } else if (key === 'deliveryDate') {
                val[key] = val[key].valueOf();
            }
        });

        window.clearTimeout(searchTimeout);

        if (['customerReference', 'tour'].includes(Object.keys(val)?.[0])) {
            searchTimeout = window.setTimeout(() => {
                setSearchParams(val);
            }, 300);
        } else {
            setSearchParams(val);
        }
    };
    const onCustomerOrderListRowEnter = (record: CustomerOrderWithExpeditionId) => {
        const containsLoadedOrDamagedPallets = !!record.pallets?.some((pallet) =>
            [PalletStatus.loaded, PalletStatus.receivedInPlatformDamaged].includes(pallet.status)
        );
        if (!containsLoadedOrDamagedPallets) {
            if (selectedCustomerOrders?.some((item) => item.id === record.id)) {
                setSelectedCustomerOrders((items) => items?.filter((item) => item.id !== record.id));
            } else {
                const containsItemsFromDifferentExpedition = selectedCustomerOrders?.some(
                    (selected) => selected.expeditionId !== record.expeditionId
                );

                if (containsItemsFromDifferentExpedition) {
                    setSelectedCustomerOrders([record]);
                } else {
                    setSelectedCustomerOrders((items) => [...(items ?? []), record]);
                }
            }
        }
    };
    const onClickDeletePlatformLoading = (id: PlatformLoading['id']) => {
        setPlatformLoadingIdToDelete(id);
        setIsDeleteModalVisible(true);
    };
    const onClickPrintAgain = (record: PlatformLoading) => {
        fetchPlatformLoadingPdf({ id: record.id });
    };
    const onCloseSuccessModal = useCallback(() => {
        setIsSuccessModalVisible(false);
        fetchPlatformLoadingPdf({ id: platformLoadingCreateState.data?.id });
    }, [fetchPlatformLoadingPdf, platformLoadingCreateState.data?.id]);
    const onCloseDeleteModal = useCallback(() => {
        setIsDeleteModalVisible(false);
    }, []);
    const onDeleteSuccess = useCallback(() => {
        refresh();
    }, [refresh]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const onValidateSelection = () => {
        createPlatformLoading({
            expeditionId: selectedCustomerOrders?.[0]?.expeditionId,
            customerOrderIds: selectedCustomerOrders?.map((customerOrder) => customerOrder.id),
        });
    };

    const renderCustomerOrderList = (
        expeditionId: Expedition['id'],
        customerOrders: CustomerOrder[] | undefined,
        selectable?: boolean
    ) => (
        <List<CustomerOrderWithExpeditionId>
            columns={customerOrderColumns}
            className="main-list"
            data={customerOrders?.map((customerOrder) => ({ ...customerOrder, expeditionId }))}
            rowKey={(record) => `${record.id}`}
            onRowClick={selectable ? onCustomerOrderListRowEnter : undefined}
            onRowEnterPress={selectable ? onCustomerOrderListRowEnter : undefined}
            isRowSelected={
                selectable
                    ? (record) => selectedCustomerOrders?.some((row) => row.id === record.id) ?? false
                    : undefined
            }
            isSelectionDisabled={
                selectable
                    ? (record) =>
                          !!record.pallets?.some((pallet) =>
                              [PalletStatus.loaded, PalletStatus.receivedInPlatformDamaged].includes(pallet.status)
                          )
                    : undefined
            }
            focusable={!!selectable}
            rowSelectType="checkbox"
            renderCellAddonRight={(record) => (
                <Descriptions column={2} size="small" colon={false} layout="vertical">
                    <Descriptions.Item label="Lot">{formatNumber(record.pallets?.length)}</Descriptions.Item>
                    <Descriptions.Item label="Poids (kg)">
                        <span style={{ whiteSpace: 'nowrap' }}>{formatNumber(record.loadedWeight)}</span>
                    </Descriptions.Item>
                </Descriptions>
            )}
            renderCellFooter={(record) => (
                <List<Pallet>
                    columns={palletColumns}
                    data={record.pallets}
                    rowKey={(record) => `${record.id}`}
                    isRowHiglighted={(record) => record.status === PalletStatus.receivedInPlatform}
                    isRowWarning={(record) => record.status === PalletStatus.receivedInPlatformDamaged}
                    isRowSuccess={(record) =>
                        [PalletStatus.loadedSendToClient, PalletStatus.loadedSendToClientDerogated].includes(
                            record.status
                        )
                    }
                    focusable={false}
                    alt={true}
                    size="small"
                    noWarningIcon={() => true}
                />
            )}
            hideWhenEmpty
            headerInCell
        />
    );

    const renderItem = (expedition: Expedition) => (
        <li className="lot-visualization-item" key={expedition.id}>
            <header>
                <Typography.Title level={2}>
                    Livraison du {formatDate(expedition.deliveryDate)} - Tour : {expedition.tour}
                </Typography.Title>
                <div>
                    <p>
                        LOTS :
                        <strong>
                            {formatNumber(
                                (expedition.customerOrders
                                    ?.map((item) => item.pallets?.length ?? 0)
                                    .reduce((prev, curr) => prev + curr, 0) ?? 0) +
                                    (expedition.platformLoadings
                                        ?.map((item) => item.quantityToLoad ?? 0)
                                        .reduce((prev, curr) => prev + curr, 0) ?? 0)
                            )}
                        </strong>
                    </p>
                    <p>
                        POIDS (KG) :
                        <strong>
                            {formatNumber(
                                (expedition.customerOrders
                                    ?.map((item) => item.loadedWeight ?? 0)
                                    .reduce((prev, curr) => prev + curr, 0) ?? 0) +
                                    (expedition.platformLoadings
                                        ?.map((item) => item.weight ?? 0)
                                        .reduce((prev, curr) => prev + curr, 0) ?? 0)
                            )}
                        </strong>
                    </p>
                </div>
            </header>
            <Space direction="vertical" size="large">
                {!expedition.customerOrders?.length && !expedition?.platformLoadings?.length && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
                {!!expedition.customerOrders?.length &&
                    renderCustomerOrderList(expedition.id, expedition.customerOrders, true)}
                {expedition?.platformLoadings?.map((platformLoading) => (
                    <Spin
                        key={platformLoading.id}
                        spinning={
                            expedition.id === platformLoadingCreateState.data?.id && platformLoadingPdfState.loading
                        }
                    >
                        <div className="expedition-platform-loading">
                            <Row justify="space-between" align="middle" className="mb-16">
                                <Col>
                                    <Typography.Title level={2} className="m-0">
                                        #{platformLoading.reference}
                                    </Typography.Title>
                                </Col>
                                <Col>
                                    <div className="flex">
                                        <ArrowNavItem>
                                            <Button
                                                type="primary"
                                                onClick={onClickPrintAgain.bind(null, platformLoading)}
                                                size="small"
                                                ghost
                                                block
                                            >
                                                Réimprimer
                                            </Button>
                                        </ArrowNavItem>
                                        {!platformLoading.customerOrders?.some((customerOrder) =>
                                            customerOrder.pallets?.some((pallet) =>
                                                [
                                                    PalletStatus.loadedSendToClient,
                                                    PalletStatus.loadedSendToClientDerogated,
                                                ].includes(pallet.status)
                                            )
                                        ) && (
                                            <ArrowNavItem>
                                                <ButtonRed
                                                    onClick={onClickDeletePlatformLoading.bind(
                                                        null,
                                                        platformLoading.id
                                                    )}
                                                    size="small"
                                                    style={{ marginLeft: '1rem' }}
                                                    ghost
                                                    block
                                                >
                                                    Supprimer
                                                </ButtonRed>
                                            </ArrowNavItem>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            {renderCustomerOrderList(expedition.id, platformLoading.customerOrders)}
                        </div>
                    </Spin>
                ))}
            </Space>
        </li>
    );

    useEffect(() => {
        if (previous?.platformLoadingCreateState.loading && !platformLoadingCreateState.loading) {
            if (platformLoadingCreateState.error) {
                message.error(
                    `Une erreur est survenue pendant la création de la FRL (${
                        platformLoadingCreateState.error?.status ?? 0
                    })`
                );
            } else {
                setIsSuccessModalVisible(true);
                refresh();
            }
        }
    }, [
        previous?.platformLoadingCreateState.loading,
        platformLoadingCreateState.loading,
        platformLoadingCreateState.error,
        refresh,
    ]);

    useEffect(() => {
        if (previous?.platformLoadingPdfState.loading && !platformLoadingPdfState.loading) {
            if (platformLoadingPdfState.error) {
                message.error(
                    `Une erreur est survenue pendant la génération du fichier PDF de la FRL (${
                        platformLoadingPdfState.error?.status ?? 0
                    })`
                );
            } else if (platformLoadingPdfState.data) {
                printjs(platformLoadingPdfState.data);
            } else {
                message.error(`Une erreur est survenue pendant la génération du fichier PDF de la FRL (fichier)`);
            }
        }
    }, [
        previous?.platformLoadingPdfState.loading,
        platformLoadingPdfState.loading,
        platformLoadingPdfState.error,
        platformLoadingPdfState.data,
    ]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Expédition" />
            <Header title="Expédition" backRoute={getRoute(RoutePathName.platformHome)} />
            <PageHeader>
                <Form
                    onValuesChange={onFilterSubmit}
                    initialValues={{
                        deliveryDate: urlSearchParams.get('deliveryDate')
                            ? dayjs(Number(urlSearchParams.get('deliveryDate')))
                            : undefined,
                        customerReference: urlSearchParams.get('customerReference') ?? undefined,
                        day: urlSearchParams.get('day') ?? undefined,
                        tour: urlSearchParams.get('tour') ?? undefined,
                        plateformCode: urlSearchParams.get('plateformCode') ?? undefined,
                    }}
                >
                    {hasRole(user, [RoleName.pfAdmin]) && (
                        <Form.Item
                            className="form-item-inline form-item-inline-start"
                            label="Afficher les lots de la plateforme :"
                            name="plateformCode"
                        >
                            <OrganizationSelect
                                placeholder="Plateforme"
                                payload={{ type: OrganizationType.platform }}
                                style={{ width: 250 }}
                                valueProp="slug"
                                withArrowNav
                            />
                        </Form.Item>
                    )}
                    <BasicList inline>
                        <li style={{ flex: '1 1 40%' }}>
                            <Form.Item name="customerReference">
                                <ArrowNavItem>
                                    <Input.Search
                                        placeholder="Saisir un code client"
                                        size="small"
                                        className="expedition-filter"
                                        allowClear
                                    />
                                </ArrowNavItem>
                            </Form.Item>
                        </li>
                        <li style={{ flex: '1 1 20%' }}>
                            <Form.Item name="deliveryDate">
                                <ArrowNavItem>
                                    <DatePicker placeholder="Date de livraison" format="DD/MM/YYYY" />
                                </ArrowNavItem>
                            </Form.Item>
                        </li>
                        <li style={{ flex: '1 1 20%' }}>
                            <Form.Item name="day">
                                <DayOfWeekSelect withArrowNav />
                            </Form.Item>
                        </li>
                        <li style={{ flex: '1 1 20%' }}>
                            <Form.Item name="tour">
                                <ArrowNavItem>
                                    <Input.Search
                                        placeholder="Tour"
                                        size="small"
                                        className="expedition-filter"
                                        allowClear
                                    />
                                </ArrowNavItem>
                            </Form.Item>
                        </li>
                    </BasicList>
                </Form>
            </PageHeader>
            {expeditionsListState.loading ? (
                <BasicList>
                    {Array.from({ length: 5 }, (_, index) => (
                        <li key={index}>
                            <Skeleton paragraph={false} title active />
                            <Skeleton active />
                        </li>
                    ))}
                </BasicList>
            ) : expeditionsListState.error ? (
                <Result title={defaultErrorMessage} />
            ) : expeditionsListState.data?.items.length ? (
                <>
                    <BasicList className="lot-visualization-list">
                        {expeditionsListState.data?.items.map(renderItem)}
                    </BasicList>
                    <Pagination
                        total={expeditionsListState.data?.totalCount}
                        pageSize={expeditionsListState.data?.pageSize ?? 0}
                        current={page + 1}
                        onChange={onPaginationChange}
                        showSizeChanger={false}
                        hideOnSinglePage
                    />
                </>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            <SuccessModal
                message="FRL créée avec succès"
                visible={isSuccessModalVisible}
                onCancel={onCloseSuccessModal}
                closable={false}
                maskClosable={false}
                noFooter
                autoClose
            />
            <PlatformLoadingDeleteModal
                visible={isDeleteModalVisible}
                onCancel={onCloseDeleteModal}
                onSuccess={onDeleteSuccess}
                platformLoadingId={platformLoadingIdToDelete}
            />
            {!!selectedCustomerOrders?.length && canEdit && (
                <FixedFooter>
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            shortcut="enter"
                            as={ButtonGrey}
                            onClick={onValidateSelection}
                            loading={platformLoadingCreateState.loading}
                            block
                        >
                            Valider la sélection
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </FixedFooter>
            )}
        </FixedFooter.Wrapper>
    );
};

export default Expeditions;
