import {
    TransferOrderIdPayload,
    TransferOrderUpdatePayload,
    TransferOrderCreationPayload,
    TransferOrdersListPayload,
} from '../api/TransferOrders';

import { EzeeAsyncAction } from './../helpers/EzeeAsyncAction';
import { requestInitialState, requestReducer } from './../helpers/index';
import { ListResponse } from './../api/index';
import { MainReducerState, RequestState } from '../reducers';
import { TransferOrder } from './../api/apiTypes';
import { combineReducers } from 'redux';
import { call, delay, put, race, take, takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';

import * as apiCall from '../api/TransferOrders';
import { DataAction, EzeeAction } from '../helpers/EzeeAction';

export interface TransferOrdersState {
    list: RequestState<ListResponse<TransferOrder>>;
    create: RequestState<TransferOrder>;
    update: RequestState<TransferOrder, { operatorAssigned?: boolean }>;
    del: RequestState;
    details: RequestState<TransferOrder>;
    updateAndStore: RequestState<TransferOrder>;
    updateAndStorePackages: RequestState<TransferOrder>;
    updateAndStorePackagesMultiPallet: RequestState<TransferOrder>;
}

const initialState: TransferOrdersState = {
    list: { ...requestInitialState },
    create: { ...requestInitialState },
    update: { ...requestInitialState },
    del: { ...requestInitialState },
    details: { ...requestInitialState },
    updateAndStore: { ...requestInitialState },
    updateAndStorePackages: { ...requestInitialState },
    updateAndStorePackagesMultiPallet: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<
    TransferOrdersState['list'],
    TransferOrdersListPayload,
    ListResponse<TransferOrder>
>(
    'transferOrders/list',
    initialState.list,
    requestReducer<TransferOrdersState['list'], ListResponse<TransferOrder>>(initialState.list)
);
export const create = new EzeeAsyncAction<TransferOrdersState['create'], TransferOrderCreationPayload, TransferOrder>(
    'transferOrders/create',
    initialState.create,
    requestReducer<TransferOrdersState['create'], TransferOrder>(initialState.create)
);

export const update = new EzeeAsyncAction<TransferOrdersState['update'], TransferOrderUpdatePayload, TransferOrder>(
    'transferOrders/update',
    initialState.update,
    requestReducer<TransferOrdersState['update'], TransferOrder>(initialState.update)
);

export const del = new EzeeAsyncAction<TransferOrdersState['del'], TransferOrderIdPayload>(
    'transferOrders/del',
    initialState.del,
    requestReducer<TransferOrdersState['del'], TransferOrder>(initialState.del)
);

export const detailsTransferOrder = new EzeeAsyncAction<
    TransferOrdersState['details'],
    TransferOrderIdPayload,
    TransferOrder
>('transferOrders/details', initialState.details, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        data: undefined, // reset when fetching
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.details,
    }),
});
export const updateAndStore = new EzeeAsyncAction<
    TransferOrdersState['updateAndStore'],
    apiCall.StoreTransferOrderPayload,
    TransferOrder
>(
    'transferOrder/updateAndStore',
    initialState.updateAndStore,
    requestReducer<TransferOrdersState['updateAndStore'], TransferOrder>(initialState.updateAndStore)
);

export const updateAndStorePackages = new EzeeAsyncAction<
    TransferOrdersState['updateAndStorePackages'],
    apiCall.StorePackagesPayload,
    TransferOrder
>(
    'transferOrder/updateAndStorePackages',
    initialState.updateAndStorePackages,
    requestReducer<TransferOrdersState['updateAndStorePackages'], TransferOrder>(initialState.updateAndStorePackages)
);

export const updateAndStorePackagesMultiPallet = new EzeeAsyncAction<
    TransferOrdersState['updateAndStorePackagesMultiPallet'],
    apiCall.StorePackagesMultiPalletPayload,
    TransferOrder
>(
    'transferOrder/updateAndStorePackagesMultiPallet',
    initialState.updateAndStorePackagesMultiPallet,
    requestReducer<TransferOrdersState['updateAndStorePackagesMultiPallet'], TransferOrder>(
        initialState.updateAndStorePackagesMultiPallet
    )
);

export const listPolling = new EzeeAction(
    'transferOrder/listPolling',
    {},
    {
        stopPolling: () => ({}),
        startPolling: () => ({}),
    }
);

// Reducer
export const transferOrdersReducer = combineReducers<TransferOrdersState>({
    list: list.reducer,
    create: create.reducer,
    update: update.reducer,
    del: del.reducer,
    details: detailsTransferOrder.reducer,
    updateAndStore: updateAndStore.reducer,
    updateAndStorePackages: updateAndStorePackages.reducer,
    updateAndStorePackagesMultiPallet: updateAndStorePackagesMultiPallet.reducer,
});

// Saga
export function* transferOrdersSaga() {
    yield takeLatest(list.type.trigger, listSaga);
    yield takeLatest(create.type.trigger, simpleAsyncSaga(apiCall.create, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(apiCall.update, update));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(apiCall.del, del));
    yield takeLatest(detailsTransferOrder.type.trigger, simpleAsyncSaga(apiCall.details, detailsTransferOrder));
    yield takeLatest(updateAndStore.type.trigger, simpleAsyncSaga(apiCall.updateAndStore, updateAndStore));
    yield takeLatest(
        updateAndStorePackages.type.trigger,
        simpleAsyncSaga(apiCall.updateAndStorePackages, updateAndStorePackages)
    );
    yield takeLatest(
        updateAndStorePackagesMultiPallet.type.trigger,
        simpleAsyncSaga(apiCall.UpdateAndStorePackagesMultiPallet, updateAndStorePackagesMultiPallet)
    );
}

function* listSaga(actionData: DataAction<TransferOrdersListPayload>) {
    if (actionData.meta?.poll) {
        yield put(listPolling.actions.startPolling());
        yield race([call(listPollTask, actionData), take(listPolling.actions.stopPolling().type)]);
    } else {
        try {
            const response = yield call(apiCall.list, actionData.payload);

            return yield put(list.success(response));
        } catch (error) {
            return yield put(list.failure(error));
        }
    }
}

function* listPollTask(action: DataAction<TransferOrdersListPayload>) {
    while (true) {
        try {
            const response = yield call(apiCall.list, action.payload);

            yield put(list.success(response));
            yield delay(5000);
        } catch (error) {
            yield put(list.failure(error));
            yield delay(5000);
        }
    }
}

// Store helpers
export const getTransferOrdersListState = (state: MainReducerState) => state.transferOrders.list;
export const getTransferOrdersCreateState = (state: MainReducerState) => state.transferOrders.create;
export const getTransferOrdersUpdateState = (state: MainReducerState) => state.transferOrders.update;
export const getTransferOrdersDeleteState = (state: MainReducerState) => state.transferOrders.del;
export const getTransferOrdersDetails = (state: MainReducerState) => state.transferOrders.details;
export const getTransferOrderStoreState = (state: MainReducerState) => state.transferOrders.updateAndStore;
export const getTransferOrderStorePackagesState = (state: MainReducerState) =>
    state.transferOrders.updateAndStorePackages;
export const getOTStorePackagesMultiPalletState = (state: MainReducerState) =>
    state.transferOrders.updateAndStorePackagesMultiPallet;
