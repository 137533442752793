import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 44 44">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M1.726 0l-.23.012C.724.102.12.706.015 1.462l-.015.231L0 42.274l.012.23c.09.771.694 1.376 1.45 1.48l.231.015L21.968 44l.23-.012a1.694 1.694 0 001.48-1.426l.016-.223-.012-.23a1.693 1.693 0 00-1.45-1.479L22 40.615H3.386V3.385l18.573.001.23-.012a1.693 1.693 0 00.032-3.358l-.23-.015L1.727 0z" />
            <path d="M28.84 9.245l-.24.196-.242.253c-.709.875-.72 2.056-.062 2.912l.211.238 7.011 6.755-24.204.002-.287.014c-1.284.143-2.228 1.163-2.228 2.375 0 1.198.921 2.206 2.144 2.366l.295.02h24.305l-6.979 6.72-.206.223c-.791.977-.704 2.33.18 3.182.872.84 2.248.931 3.23.23l.244-.201 11.236-10.815.207-.222a2.35 2.35 0 00.367-.604l.11-.333.051-.27.013-.122.004-.218-.015-.217-.024-.164a2.356 2.356 0 00-.563-1.133L32.073 9.525l-.23-.2c-.903-.677-2.128-.695-3.002-.08z" />
        </g>
    </svg>
);

const IconLogout: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-logout${props.className ? ` ${props.className}` : ''}`} />
);

export default IconLogout;
