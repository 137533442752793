import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestInitialState, requestReducer } from '../helpers';

// Types
import { MovementHistory } from '../api/apiTypes';
import { ListResponse } from '../api/';

// Controlers
import {
    MovementHistoryListPayload,
    list as listApiCall,
    create as createApiCall,
    MouvementHistoryCreatePayload,
} from '../api/movementHistories';

// States
export interface MovementHistoriesState {
    list: RequestState<ListResponse<MovementHistory>>;
    create: RequestState<MovementHistory>;
}

const initialState: MovementHistoriesState = {
    list: { ...requestInitialState },
    create: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<
    MovementHistoriesState['list'],
    MovementHistoryListPayload,
    ListResponse<MovementHistory>
>('movementHistories/list', initialState.list, {
    trigger: (state, _, meta) => ({
        ...state,
        loading: true,
        data: meta?.loadMore ? state.data : undefined,
    }),
    success: (state, payload, meta) => ({
        data: {
            ...payload,
            items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.list,
    }),
});

export const create = new EzeeAsyncAction<
    MovementHistoriesState['create'],
    MouvementHistoryCreatePayload,
    MovementHistory
>(
    'movementHistories/create',
    initialState.create,
    requestReducer<MovementHistoriesState['create'], MovementHistory>(initialState.create)
);

// Reducer
export const movementHistoriesReducer = combineReducers<MovementHistoriesState>({
    list: list.reducer,
    create: create.reducer,
});

// Saga
export function* movementHistoriesSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
}

// Store helpers
export const getMovementHistoriesListState = (state: MainReducerState) => state.movementHistories.list;
export const getMovementHistoriesCreateState = (state: MainReducerState) => state.movementHistories.create;
