import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="44" height="44" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M42.533 0C43.343 0 44 .657 44 1.467v41.066c0 .81-.657 1.467-1.467 1.467H1.467C.657 44 0 43.343 0 42.533V1.467C0 .657.657 0 1.467 0h41.066Zm-1.466 2.933H24.933V8.8c0 .81-.656 1.467-1.466 1.467h-2.934c-.81 0-1.466-.657-1.466-1.467V2.933H2.933v38.134h38.134V2.933Z" />
            <path d="m17.644 23.423-1.928-1.506h9.896c2.944.025 5.321 2.305 5.321 5.108 0 2.804-2.377 5.083-5.326 5.108h-14.1c-.832 0-1.507.642-1.507 1.434 0 .791.675 1.433 1.508 1.433h14.104c4.633-.006 8.385-3.574 8.388-7.977l-.005-.258c-.148-4.281-3.842-7.708-8.38-7.714h-9.898l1.93-1.508c.417-.323.622-.836.534-1.342a1.453 1.453 0 0 0-.96-1.11 1.57 1.57 0 0 0-1.49.239l-5.177 4.043-.092.082-.084.081-.02.045-.012-.01-.092.123-.078.118-.064.139-.039.104-.027.089-.03.144-.01.109v.175l.014.126.002.007.012.066.039.141.048.126.067.138.134.188.027.03.005.013.091.089.065.06 5.22 4.083a1.57 1.57 0 0 0 1.49.238c.505-.18.871-.603.959-1.11a1.397 1.397 0 0 0-.535-1.344Z" />
        </g>
    </svg>
);

const IconReturnLeft: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-warning${props.className ? ` ${props.className}` : ''}`} />
);

export default IconReturnLeft;
