import React, { FC, useCallback, useEffect, useState } from 'react';
import { message, Typography } from 'antd';
import { useSelector } from 'react-redux';

import { PlatformLoading } from '../../../store/api/apiTypes';
import { del, getPlatformLoadingsDelState } from '../../../store/actions/platformLoadings';

import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useShortcutScope } from '../../../hooks';
import SuccessMessage from '../../../components/SuccessMessage';
import ButtonRed from '../../../components/ButtonRed';
import { IconWarning } from '../../../components/icons';

const shortcutScope = 'PlatformLoadingDeleteModal';

interface PlatformLoadingDeleteModalProps extends CustomModalProps {
    platformLoadingId?: PlatformLoading['id'];
    onSuccess?: () => void;
}

const PlatformLoadingDeleteModal: FC<PlatformLoadingDeleteModalProps> = ({
    visible,
    onCancel,
    platformLoadingId,
    onSuccess,
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [deletePlatformLoading] = useActions([del.trigger]);
    const [isSuccess, setIsSuccess] = useState(false);
    const platformLoadingDeleteState = useSelector(getPlatformLoadingsDelState);
    const previous = usePrevious({
        platformLoadingDeleteState,
    });
    const isMounted = useIsMounted();
    const onSubmitDelete = () => {
        deletePlatformLoading({ id: platformLoadingId });
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    useEffect(() => {
        if (visible && previous?.platformLoadingDeleteState.loading && !platformLoadingDeleteState.loading) {
            if (platformLoadingDeleteState.error) {
                message.error('Une erreur est survenue pendant la suppression de la FRL');
            } else {
                setIsSuccess(true);
            }
        }
    }, [
        visible,
        previous?.platformLoadingDeleteState.loading,
        platformLoadingDeleteState.loading,
        platformLoadingDeleteState.error,
    ]);

    useEffect(() => {
        let timeout: number;

        if (isSuccess) {
            onSuccess?.();
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onClose();
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [isSuccess, isMounted, onClose, onSuccess]);

    useEffect(() => {
        if (!visible) {
            setIsSuccess(false);
        }
    }, [visible]);

    return (
        <CustomModal
            footer={
                !isSuccess && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                as={ButtonRed}
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={onSubmitDelete}
                                loading={platformLoadingDeleteState.loading}
                            >
                                Supprimer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={
                !isSuccess && (
                    <p style={{ textAlign: 'center', margin: 0 }}>
                        <Typography.Text>
                            <Typography.Text type="warning">
                                <IconWarning style={{ fontSize: '2.25rem', marginBottom: '1rem' }} />
                            </Typography.Text>
                            <br />
                            Êtes-vous sûr de vouloir supprimer cette feuille de route de livraison ?
                        </Typography.Text>
                    </p>
                )
            }
            width={368}
            keyboard={false}
        >
            {isSuccess && <SuccessMessage message="FRL supprimée avec succès" />}
        </CustomModal>
    );
};

export default PlatformLoadingDeleteModal;
