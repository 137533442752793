import React, { useEffect, useState } from 'react';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import CustomModal from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import { Form, message, Spin } from 'antd';
import PrintersSelect from '../../components/PrintersSelect';
import { Pallet, Printer, WMSMode } from '../../store/api/apiTypes';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import { getPalletPrintState, print as printPalletAction } from '../../store/actions/pallets';
import { useSelector } from 'react-redux';
import { getDefaultPrinterState } from '../../store/actions/printers';

type printPalletStep = 'print' | 'printing';
interface PrintPalletModalProps {
    palletId: Pallet['id'];
}

const shortcutScope = 'PrintPalletModal';

const PrintPalletModal = NiceModal.create<PrintPalletModalProps>(({ palletId }) => {
    const modal = useModal();
    const { hide, visible } = modal;
    const defaultPrinterState = useSelector(getDefaultPrinterState);
    const [printer, setPrinter] = useState<Printer | undefined>(defaultPrinterState.printer);
    const print = useActions(printPalletAction.trigger);
    const printState = useSelector(getPalletPrintState);
    const previous = usePrevious({ printState });
    const [step, setStep] = useState<printPalletStep>('print');
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const onClickPrint = () => {
        if (printer) {
            print({ palletId, printerId: printer.id, mode: WMSMode.reception });
        }
    };

    useEffect(() => {
        let timeout: number;
        if (step === 'printing') {
            timeout = window.setTimeout(() => {
                hide();
            }, 1000);
        }
        return () => window.clearTimeout(timeout);
    }, [hide, palletId, print, printer, step]);

    useEffect(() => {
        if (previous?.printState.loading && !printState.loading) {
            if (printState.error) {
                message.error(`Erreur lors de l'envoi à l'impression`);
            } else if (printState.success) {
                setStep('printing');
            }
        }
    }, [previous?.printState.loading, printState.error, printState.loading, printState.success]);

    return (
        <CustomModal
            {...antdModal(modal)}
            footer={
                step === 'print' ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                type="primary"
                                shortcut="enter"
                                shortcutScope={shortcutScope}
                                shortcutOptions={{ enabled: !!printer }}
                                onClick={onClickPrint}
                                disabled={!printer}
                                loading={printState.loading}
                            >
                                Imprimer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={hide}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <p>Impression en cours...</p>
                        <Spin size="large" />
                    </div>
                )
            }
            title={step === 'print' ? `impression de l'étiquette` : undefined}
            width={368}
            altTitle
        >
            {step === 'print' ? (
                <>
                    <Form.Item label="Imprimante" style={{ margin: 0 }} labelCol={{ span: 24 }}>
                        <PrintersSelect onChange={setPrinter} withArrowNav arrowNavScope={shortcutScope} />
                    </Form.Item>
                </>
            ) : undefined}
        </CustomModal>
    );
});

export default PrintPalletModal;
