import React, { FC, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../../hooks';
import { Place, PlaceZoneType } from '../../../store/api/apiTypes';

import { Input, InputProps, message } from 'antd';

import { detailsByScan, getPlaceDetailsByScanState } from '../../../store/actions/places';

const shortcutScope = 'ScanPlaceModal';
interface ScanPlaceModalProps extends CustomModalProps {
    zoneType?: PlaceZoneType;
    visible: boolean;
    onSuccess: ({ place }: { place?: Place }) => void;
    selectPlaceId?: Place['id'];
}

const ScanPlaceModal: FC<ScanPlaceModalProps> = ({ visible, onCancel, zoneType, onSuccess, selectPlaceId }) => {
    const [reference, setReference] = useState<string>();
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    const fetchPlaceDetails = useActions(detailsByScan.trigger);

    const placeScanState = useSelector(getPlaceDetailsByScanState);

    const handlePlaceChange: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };

    const handleSubmit = () => {
        fetchPlaceDetails({ reference });
    };

    const previous = usePrevious({
        placeScanState,
    });

    useEffect(() => {
        if (visible && previous?.placeScanState.loading && !placeScanState.loading) {
            if (placeScanState.error) {
                message.error('Emplacement non reconnue');
            } else {
                if (zoneType !== placeScanState.data?.zoneType) {
                    message.error(`Emlacement non reconnue en ${zoneType ?? ''}`);
                } else if (selectPlaceId !== placeScanState.data?.id) {
                    message.error("L'emplacement sélectionné est different de l'emplacement flashé");
                } else {
                    onSuccess({ place: placeScanState.data });
                }
            }
        }
    }, [
        onSuccess,
        placeScanState.data,
        placeScanState.error,
        placeScanState.loading,
        previous?.placeScanState.loading,
        selectPlaceId,
        visible,
        zoneType,
    ]);

    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                fetchPlaceDetails({ barcode: barCode.data });
            }
        },
        {
            deps: [],
            disable: !visible,
        }
    );

    return (
        <CustomModal
            visible={visible}
            title={`rangement`}
            width={368}
            keyboard={false}
            altTitle
            transitionName=""
            maskTransitionName=""
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={handleSubmit}
                            loading={placeScanState.loading}
                        >
                            Valider le rangement
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClose}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
        >
            <ArrowNavItem scope={shortcutScope}>
                <Input placeholder={`Flasher l'emplacement`} value={reference} onChange={handlePlaceChange} />
            </ArrowNavItem>
        </CustomModal>
    );
};

export default ScanPlaceModal;
