import React, { FC, useCallback, useEffect, useState } from 'react';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getPreparationStateById, getPreparationUpdateState, update } from '../../store/actions/preparations';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import { IconWarning } from '../../components/icons';
import { formatNumber } from '../../helpers/i18n';

const shortcutScope = 'PreparationQuantityChangeModal';

interface PreparationQuantityChangeModalProps {
    onClose: () => void;
}

const PreparationQuantityChangeModal: FC<PreparationQuantityChangeModalProps> = ({ onClose }) => {
    const [isVisible, setIsVisible] = useState(false);
    useShortcutScope(shortcutScope, !isVisible);
    useArrowNavScope(shortcutScope, !isVisible);
    const { preparationId } = useParams<{ preparationId: string }>();
    const updatePreparation = useActions(update.trigger);
    const preparationDetailsState = useSelector(getPreparationStateById(parseInt(preparationId, 10)));
    const preparationUpdateState = useSelector(getPreparationUpdateState);
    const previous = usePrevious({ preparationDetailsState, preparationUpdateState });
    const quantityToPutAside = Math.abs(
        (preparationDetailsState.data?.preparedQuantity ?? 0) - (preparationDetailsState.data?.quantity ?? 0)
    );
    const onCloseModal = useCallback(() => {
        setIsVisible(false);
    }, []);
    const onClickClose = () => {
        updatePreparation({ id: preparationId, quantityUpdateFlag: false });
    };

    // open modal when the flag has been set to true
    useEffect(() => {
        if (
            !previous?.preparationDetailsState.data?.quantityUpdatedFlag &&
            preparationDetailsState.data?.quantityUpdatedFlag !== undefined &&
            !!preparationDetailsState.data?.quantityUpdatedFlag
        ) {
            setIsVisible(true);
        }
    }, [previous?.preparationDetailsState, preparationDetailsState]);

    useEffect(() => {
        if (previous?.preparationUpdateState.loading && !preparationUpdateState.loading) {
            if (!preparationUpdateState.error) {
                onCloseModal();
                onClose();
            }
        }
    }, [
        previous?.preparationUpdateState.loading,
        preparationUpdateState.loading,
        preparationUpdateState.error,
        onCloseModal,
        onClose,
    ]);

    return (
        <CustomModal
            visible={isVisible}
            onCancel={onCloseModal}
            title={
                <Typography.Text>
                    <Typography.Text type="warning">
                        <IconWarning style={{ fontSize: '2.25rem', marginBottom: '1rem' }} />
                    </Typography.Text>
                    <br />
                    {'La quantité de colis à préparer a été modifiée.'}
                    {quantityToPutAside > 0 && (
                        <>
                            <br />
                            <br />
                            {`Merci de mettre de côté ${formatNumber(quantityToPutAside)} colis déjà préparé${
                                quantityToPutAside > 1 ? 's' : ''
                            }, un ordre de transfert va être créé.`}
                        </>
                    )}
                </Typography.Text>
            }
            footer={
                <ArrowNavItem scope={shortcutScope}>
                    <ButtonWithShortcut
                        shortcut="enter"
                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                        type="primary"
                        shortcutScope={shortcutScope}
                        onClick={onClickClose}
                        loading={preparationUpdateState.loading}
                        ghost
                    >
                        Fermer
                    </ButtonWithShortcut>
                </ArrowNavItem>
            }
            width={368}
            maskClosable={false}
        />
    );
};

export default PreparationQuantityChangeModal;
