import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 56">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M70.8 44c.664 0 1.2.536 1.2 1.2v9.6a1.2 1.2 0 01-1.2 1.2h-9.6a1.2 1.2 0 01-1.2-1.2v-3.6H42v3.6a1.2 1.2 0 01-1.2 1.2h-9.6a1.2 1.2 0 01-1.2-1.2v-3.6H12v3.6a1.2 1.2 0 01-1.2 1.2H1.2A1.2 1.2 0 010 54.8v-9.6A1.2 1.2 0 011.2 44zm-1.2 7.2h-7.2v2.4h7.2v-2.4zm-30 0h-7.2v2.4h7.2v-2.4zm-30 0H2.4v2.4h7.2v-2.4zm60-4.8H2.4v2.4h67.2v-2.4zM33.293.465C42.83-1.77 52.342 4.27 54.543 13.953c2.2 9.683-3.743 19.346-13.277 21.583-8.72 2.045-17.506-2.84-20.581-11.228l-.09-.252-.777 2.214a1.448 1.448 0 01-1.723.933l-.134-.042a1.477 1.477 0 01-.918-1.746l.04-.131 2.12-6.038c.149-.425.478-.75.871-.888l.13-.052c.308-.105.646-.103.968.016l.14.061 5.653 2.847a1.48 1.48 0 01.655 1.968 1.447 1.447 0 01-1.815.724l-.133-.058-2.497-1.258.074.228c2.382 7.175 9.701 11.462 17.047 9.906l.315-.07c7.971-1.87 12.943-9.954 11.102-18.057-1.84-8.102-9.795-13.151-17.765-11.282-5.005 1.174-9.02 4.87-10.684 9.788a1.447 1.447 0 01-1.846.914 1.475 1.475 0 01-.9-1.868C22.506 6.29 27.308 1.87 33.293.465z"
        />
    </svg>
);

const IconResupplying: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-resupplying${props.className ? ` ${props.className}` : ''}`} />
);

export default IconResupplying;
