import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { getAuthState, logout as logoutAction } from '../store/actions/auth';

import { useActions } from '../hooks';
import ButtonWithShortcut from './ButtonWithShortcut';
import CustomModal, { CustomModalProps } from './CustomModal';
import { defaultPrinter } from '../store/actions/printers';

const LogoutModal: FC<CustomModalProps> = ({ visible, onCancel }) => {
    const logout = useActions(logoutAction.trigger);
    const authState = useSelector(getAuthState);
    const setDefaultPrinter = useActions(defaultPrinter.actions.setDefaultPrinter);
    const onLogout = () => {
        setDefaultPrinter(undefined);
        logout();
    };
    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    };

    return (
        <CustomModal
            footer={
                <>
                    <ButtonWithShortcut shortcut="F1" type="primary" onClick={onLogout} loading={authState.loading}>
                        Oui
                    </ButtonWithShortcut>
                    <ButtonWithShortcut shortcut="F2" type="primary" onClick={onClose} ghost>
                        Non
                    </ButtonWithShortcut>
                </>
            }
            visible={visible}
            onCancel={onCancel}
            title="Êtes-vous sur de vouloir vous déconnecter maintenant ?"
            width={368}
            centered
        />
    );
};

export default LogoutModal;
