import React, { FC, useCallback, useEffect, useState } from 'react';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useShortcutScope } from '../../../hooks';
import { MovementBroken, MovementBrokenStatus } from '../../../store/api/apiTypes';
import { useSelector } from 'react-redux';

import SuccessMessage from '../../../components/SuccessMessage';
import { message } from 'antd';
import QuantityInput from '../../../components/QuantityInput';

import {
    getMovementBrokensStateById,
    details as detailsBroken,
    createBrokenRepair,
    createBrokenDestruction,
    getCreateBrokenRepairState,
    getCreateBrokenDestructionState,
    createDiscountShipping,
    getCreateBrokenDiscountShippingState,
} from '../../../store/actions/movementBrokens';

const shortcutScope = 'ActionsModal';

type ActionStep = 'selectQantity' | 'success' | 'complete';
type ActionType = 'repair' | 'sendSolder' | 'destruct';

interface ActionsModalProps extends CustomModalProps {
    brokenId: MovementBroken['id'];
    max: number;
}

const ActionsModal: FC<ActionsModalProps> = ({ visible, onCancel, brokenId, max }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    const [step, setStep] = useState<ActionStep>();
    const [actionType, setActionType] = useState<ActionType>();

    const [quantity, setQuantity] = useState<number>(max);

    const [createRepair, createDestruction, fetchBroken, sendToDiscountShipping] = useActions([
        createBrokenRepair.trigger,
        createBrokenDestruction.trigger,
        detailsBroken.trigger,
        createDiscountShipping.trigger,
    ]);

    const createRepairState = useSelector(getCreateBrokenRepairState);
    const createDestructionState = useSelector(getCreateBrokenDestructionState);
    const sendToDiscountShippingState = useSelector(getCreateBrokenDiscountShippingState);

    const brokenState = useSelector(getMovementBrokensStateById(brokenId));
    const previous = usePrevious({
        createDestructionState,
        createRepairState,
        brokenState,
        sendToDiscountShippingState,
    });

    useEffect(() => {
        if (previous?.createRepairState.loading && !createRepairState.loading) {
            if (createRepairState.error) {
                message.error('Une erreur est survenue lors de la création de réparation');
            } else {
                fetchBroken({ id: brokenState.data?.id });
            }
        }
        if (previous?.createDestructionState.loading && !createDestructionState.loading) {
            if (createDestructionState.error) {
                message.error('Une erreur est survenue lors de la création de destruction');
            } else {
                fetchBroken({ id: brokenState.data?.id });
            }
        }
        if (previous?.sendToDiscountShippingState.loading && !sendToDiscountShippingState.loading) {
            if (sendToDiscountShippingState.error) {
                message.error("Une erreur est survenue lors de l'envoi soldeur");
            } else {
                fetchBroken({ id: brokenState.data?.id });
            }
        }
    }, [
        brokenState.data?.id,
        createDestructionState.error,
        createDestructionState.loading,
        createRepairState.error,
        createRepairState.loading,
        fetchBroken,
        previous?.createDestructionState.loading,
        previous?.createRepairState.loading,
        previous?.sendToDiscountShippingState.loading,
        sendToDiscountShippingState.error,
        sendToDiscountShippingState.loading,
    ]);

    useEffect(() => {
        if (previous?.brokenState.loading && !brokenState.loading) {
            if (brokenState.error) {
                onClose();
            } else {
                setStep('success');
            }
        }
    }, [brokenState.error, brokenState.loading, onClose, previous?.brokenState.loading]);

    const isMounted = useIsMounted();

    useEffect(() => {
        let timeout: number;
        if (step === 'success') {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    if (brokenState.data?.status === MovementBrokenStatus.completed) {
                        setStep('complete');
                    } else {
                        onClose();
                    }
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [brokenState.data?.status, isMounted, onClose, step]);

    const renderModalTitle = () => {
        switch (step) {
            case undefined:
                return 'Actions';
            case 'selectQantity':
                return 'Quantité à traiter';
            default:
                return '';
        }
    };

    const selectAction = (action: ActionType) => {
        setStep('selectQantity');
        setActionType(action);
    };

    const onValid = () => {
        const communPayload = {
            movementBrokenId: brokenState.data?.id,
            quantity,
            status: MovementBrokenStatus.toBeProcessed,
        };
        switch (actionType) {
            case 'repair':
                createRepair(communPayload);

                break;
            case 'destruct':
                createDestruction({
                    movementBrokenId: brokenState.data?.id,
                    quantity,
                    status: MovementBrokenStatus.completed,
                });
                break;
            case 'sendSolder':
                sendToDiscountShipping(communPayload);
                break;
            default:
                break;
        }
    };

    return (
        <CustomModal
            visible={visible}
            title={renderModalTitle()}
            width={368}
            keyboard={false}
            altTitle
            footer={
                <>
                    {!step ? (
                        <>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="1"
                                    onClick={selectAction.bind(null, 'repair')}
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                >
                                    Réparation
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="2"
                                    onClick={selectAction.bind(null, 'sendSolder')}
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                >
                                    Envoi soldeur
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="3"
                                    onClick={selectAction.bind(null, 'destruct')}
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                >
                                    Destruction
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </>
                    ) : (
                        !['success', 'complete'].includes(step) && (
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="enter"
                                    disabled={
                                        step === 'selectQantity' &&
                                        (!quantity || quantity > (brokenState.data?.quantity ?? 0))
                                    }
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                    onClick={onValid}
                                >
                                    Valider
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        )
                    )}

                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClose}
                            ghost
                        >
                            {[undefined, 'complete'].includes(step) ? 'Fermer' : 'Annuler'}
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
        >
            {step === 'selectQantity' && (
                <QuantityInput
                    value={quantity}
                    smallText={true}
                    text={`sur ${max} à traiter`}
                    min={1}
                    max={max}
                    onChange={setQuantity}
                />
            )}
            {step === 'success' && (
                <SuccessMessage
                    message={`Colis déclarés pour ${
                        actionType === 'repair'
                            ? 'réparation'
                            : actionType === 'destruct'
                            ? 'destruction'
                            : 'envoi soldeur'
                    }`}
                />
            )}
            {step === 'complete' && (
                <SuccessMessage message="Casse traité avec succès. Merci de déplacer les colis dans le puits correspondant." />
            )}
        </CustomModal>
    );
};

export default ActionsModal;
