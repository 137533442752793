import { Pallet, Parcel, Place } from './apiTypes';
import api from './_client';

export interface StockDifferencesSendPayload {
    placeId: Place['id'];
    parcelId: Parcel['id'];
    palletId: Pallet['id'];
    stockDifferenceQuantity?: number;
    packageAnomaliesQuantity?: number;
    message?: string;
}
export const send = async (payload: StockDifferencesSendPayload) => await api.post('/stockDifferences', payload);
