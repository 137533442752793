import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Button, Col, Divider, Row, Select, Form, Empty, Pagination, PaginationProps } from 'antd';

import '../../assets/styles/PlaceManagementSection.less';

import { getPlaceSectorsListState, list as placeSectorsList } from '../../store/actions/placeSectors';
import { getPlaceLanesListState, list as placeLanesList } from '../../store/actions/placeLanes';
import { getPlaceAlveolesListState, list as placeAlveolesList } from '../../store/actions/placeAlveoles';
import { PlaceAlveole, PlaceLane, PlaceSector, PlaceStore, PlaceStoreType } from '../../store/api/apiTypes';

import { useActions, useIsMobile } from '../../hooks';
import { getRawRoute, getRoute, RoutePathName } from '../../routes';
import Header from '../../components/Header';
import Seo from '../../components/Seo';
import FixedFooter from '../../components/FixedFooter';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import BasicList from '../../components/BasicList';
import PlaceStoreSelect from '../../components/PlaceStoreSelect';
import CardSkeleton from '../../components/CardSkeleton';
import PlaceSectorFormModal from './PlaceSectorFormModal';
import PlaceLaneFormModal from './PlaceLaneFormModal';
import PlaceAlveoleFormModal from './PlaceAlveoleFormModal';
import useQueryParams from '../../hooks/queryParams';
import PlaceLaneSelect from '../../components/PlaceLaneSelect';

interface WhichRoute {
    isSectors: boolean;
    isLanes: boolean;
    isAlveoles: boolean;
}

const getTitle = ({ isSectors, isLanes, isAlveoles }: WhichRoute) => {
    if (isSectors) {
        return 'Secteurs';
    } else if (isLanes) {
        return 'Allées';
    } else if (isAlveoles) {
        return 'Alvéoles';
    }

    return '';
};

const getItemSection = ({ isSectors, isLanes, isAlveoles }: WhichRoute) => {
    if (isSectors) {
        return 'Secteur';
    } else if (isLanes) {
        return 'Allée';
    } else if (isAlveoles) {
        return 'Alvéole';
    }

    return '';
};

const getAddButtonText = ({ isSectors, isLanes, isAlveoles }: WhichRoute) => {
    if (isSectors) {
        return 'Ajouter un secteur';
    } else if (isLanes) {
        return 'Ajouter une allée';
    } else if (isAlveoles) {
        return 'Ajouter une alvéole';
    }

    return '';
};

const PlaceManagementSection: FC = () => {
    const isSectors = !!useRouteMatch(getRawRoute(RoutePathName.placeManagementSectors));
    const isLanes = !!useRouteMatch(getRawRoute(RoutePathName.placeManagementLanes));
    const isAlveoles = !!useRouteMatch(getRawRoute(RoutePathName.placeManagementAlveoles));
    const isMobile = useIsMobile();
    const [queryParams, setQueryParams] = useQueryParams('sectionList');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const placeStoreId =
        queryParams.get('placeStoreId') !== null ? parseInt(queryParams.get('placeStoreId')!, 10) : undefined;
    const placeSectorId =
        queryParams.get('placeSectorId') !== null ? parseInt(queryParams.get('placeSectorId')!, 10) : undefined;
    const placeLaneId =
        queryParams.get('placeLaneId') !== null ? parseInt(queryParams.get('placeLaneId')!, 10) : undefined;
    const [isFormModalVisible, setIsFormModalVisible] = useState(false);
    const [sectionToEdit, setSectionToEdit] = useState<PlaceSector | PlaceLane | PlaceAlveole>();
    const [listPlaceSectors, listPlaceLanes, listPlaceAlveoles] = useActions([
        placeSectorsList.trigger,
        placeLanesList.trigger,
        placeAlveolesList.trigger,
    ]);
    const placeSectorsState = useSelector(getPlaceSectorsListState);
    const placeLanesState = useSelector(getPlaceLanesListState);
    const placeAlveolesState = useSelector(getPlaceAlveolesListState);
    const onClickItem = (item: PlaceSector | PlaceLane | PlaceAlveole) => {
        setSectionToEdit(item);
        setIsFormModalVisible(true);
    };
    const onClickAdd = () => {
        setSectionToEdit(undefined);
        setIsFormModalVisible(true);
    };
    const onChangePlaceStore = (value?: PlaceStore) => {
        setQueryParams({
            placeStoreId: value?.id,
            placeSectorId: undefined,
            placeLaneId: undefined,
            page: undefined,
        });
    };
    const onChangePlaceSector = (value: PlaceSector['id']) => {
        setQueryParams({
            placeSectorId: value,
            placeLaneId: undefined,
            page: undefined,
        });
    };
    const onChangePlaceLane = (value: PlaceLane['id']) => {
        setQueryParams({
            placeLaneId: value,
            page: undefined,
        });
    };
    const onCloseFormModal = () => {
        setIsFormModalVisible(false);
        doRequests();
    };
    const currentListState = isSectors ? placeSectorsState : isLanes ? placeLanesState : placeAlveolesState;
    const onPaginationChange: PaginationProps['onChange'] = (p) => {
        setQueryParams({
            page: p ? p - 1 : 0,
        });
    };
    const renderListItem = (item: PlaceSector | PlaceLane | PlaceAlveole, label: string) => (
        <li key={item.id}>
            <ArrowNavItem>
                <Button onClick={onClickItem.bind(null, item)} block>
                    <span>{getItemSection({ isSectors, isLanes, isAlveoles })}</span>
                    <span>{label || '—'}</span>
                </Button>
            </ArrowNavItem>
        </li>
    );
    const doRequests = useCallback(() => {
        if (placeStoreId) {
            const payload = {
                sort: 'updatedAt',
                sortOrder: 'desc',
                ...(placeStoreId ? { placeStoreId } : {}),
                ...(placeSectorId ? { placeSectorId } : {}),
                ...(placeLaneId ? { placeLaneId } : {}),
                page,
            };
            if (isSectors) {
                listPlaceSectors(payload);
            } else if (isLanes) {
                listPlaceSectors(placeStoreId ? { placeStoreId } : undefined);
                listPlaceLanes(payload);
            } else if (isAlveoles) {
                listPlaceSectors(placeStoreId ? { placeStoreId } : undefined);
                listPlaceLanes(placeSectorId ? { placeSectorId } : undefined);
                listPlaceAlveoles(payload);
            }
        }
    }, [
        isSectors,
        isLanes,
        isAlveoles,
        placeSectorId,
        placeLaneId,
        listPlaceSectors,
        listPlaceLanes,
        listPlaceAlveoles,
        placeStoreId,
        page,
    ]);

    useEffect(() => {
        doRequests();
    }, [doRequests]);

    useEffect(() => {
        if (!isFormModalVisible) {
            doRequests();
        }
    }, [isFormModalVisible, doRequests]);

    return (
        <FixedFooter.Wrapper>
            <Seo title={getTitle({ isSectors, isLanes, isAlveoles })} />
            <Header
                title={getTitle({ isSectors, isLanes, isAlveoles })}
                backRoute={getRoute(RoutePathName.placeManagementSectionsMenu)}
            />
            <Row gutter={24}>
                <Col xs={8}>
                    <Form.Item label="Site" labelCol={{ span: 24 }}>
                        <PlaceStoreSelect
                            onChange={onChangePlaceStore}
                            placeStoreType={PlaceStoreType.site}
                            value={placeStoreId}
                            allowClear={false}
                            withArrowNav
                        />
                    </Form.Item>
                </Col>
                {(isAlveoles || isLanes) && (
                    <Col xs={8}>
                        <Form.Item label="Secteur" labelCol={{ span: 24 }}>
                            <ArrowNavItem>
                                <Select
                                    placeholder="Filtrer par secteur"
                                    filterOption={false}
                                    onChange={onChangePlaceSector}
                                    style={{ width: '100%' }}
                                    value={placeSectorId}
                                    allowClear
                                    showArrow
                                >
                                    {placeSectorsState.data?.items?.map((option) => (
                                        <Select.Option value={option.id} key={option.id}>
                                            {option.sector}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </ArrowNavItem>
                        </Form.Item>
                    </Col>
                )}
                {isAlveoles && (
                    <Col xs={8}>
                        <Form.Item label="Allée" labelCol={{ span: 24 }}>
                            <PlaceLaneSelect
                                value={placeLaneId}
                                onChange={onChangePlaceLane}
                                placeSectorId={placeSectorId}
                                withArrowNav
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Divider />
            {isSectors && !placeSectorsState.loading && !placeSectorsState.data?.items?.length && (
                <div className="flex flex-center">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            )}
            {isLanes && !placeLanesState.loading && !placeLanesState.data?.items?.length && (
                <div className="flex flex-center">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            )}
            {isAlveoles && !placeAlveolesState.loading && !placeAlveolesState.data?.items?.length && (
                <div className="flex flex-center">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            )}
            <BasicList className="section-item-list" grid>
                {isSectors &&
                    !placeSectorsState.loading &&
                    placeSectorsState.data?.items?.map((item) => renderListItem(item, item.sector))}
                {isLanes &&
                    !placeLanesState.loading &&
                    placeLanesState.data?.items?.map((item) => renderListItem(item, item.lane))}
                {isAlveoles &&
                    !placeAlveolesState.loading &&
                    placeAlveolesState.data?.items?.map((item) => renderListItem(item, item.alveole))}
                {((isSectors && placeSectorsState.loading) ||
                    (isLanes && placeLanesState.loading) ||
                    (isAlveoles && placeAlveolesState.loading)) &&
                    Array.from({ length: 12 }, (_, i: number) => (
                        <li key={`${i}`}>
                            <CardSkeleton />
                        </li>
                    ))}
            </BasicList>
            <Pagination
                total={currentListState.data?.totalCount}
                pageSize={currentListState.data?.pageSize ?? 0}
                current={page + 1}
                onChange={onPaginationChange}
                showSizeChanger={false}
                hideOnSinglePage
            />
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        shortcut="f1"
                        onClick={onClickAdd}
                        size={isMobile ? 'middle' : 'large'}
                        type="primary"
                        ghost
                        block
                    >
                        {getAddButtonText({ isSectors, isLanes, isAlveoles })}
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
            {isSectors && (
                <PlaceSectorFormModal
                    visible={isFormModalVisible}
                    onCancel={onCloseFormModal}
                    placeSector={sectionToEdit as PlaceSector}
                />
            )}
            {isLanes && (
                <PlaceLaneFormModal
                    visible={isFormModalVisible}
                    onCancel={onCloseFormModal}
                    placeLane={sectionToEdit as PlaceLane}
                />
            )}
            {isAlveoles && (
                <PlaceAlveoleFormModal
                    visible={isFormModalVisible}
                    onCancel={onCloseFormModal}
                    placeAlveole={sectionToEdit as PlaceAlveole}
                />
            )}
        </FixedFooter.Wrapper>
    );
};

export default PlaceManagementSection;
