import {
    Card,
    Col,
    Descriptions,
    Divider,
    Empty,
    Form,
    FormProps,
    Input,
    InputNumber,
    Pagination,
    PaginationProps,
    Result,
    Row,
    Select,
    Skeleton,
    Space,
    Typography,
} from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import '../../../assets/styles/LotVisualization.less';

import {
    Expedition,
    ExpeditionStatus,
    OrganizationType,
    Pallet,
    PalletStatus,
    Permission,
    PermissionRight,
    RoleName,
} from '../../../store/api/apiTypes';
import { list, getExpeditionListState } from '../../../store/actions/expeditions';
import { getUser } from '../../../store/actions/auth';

import useQueryParams from '../../../hooks/queryParams';
import FixedFooter from '../../../components/FixedFooter';
import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import PageHeader from '../../../components/PageHeader';
import Seo from '../../../components/Seo';
import { useActions } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import {
    defaultErrorMessage,
    formatDate,
    formatNumber,
    formatTimeFromSeconds,
    translateDayOfWeek,
    translatePalletStatus,
} from '../../../helpers/i18n';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonGrey from '../../../components/ButtonGrey';
import BasicList from '../../../components/BasicList';
import { hasPermission, hasRole } from '../../../helpers/security';
import DatePicker from '../../../components/DatePicker';
import OrganizationSelect from '../../../components/OrganizationSelect';
import DayOfWeekSelect from '../../../components/DayOfWeekSelect';
import LotStatusModal from './LotStatusModal';

let searchTimeout: number;

const LotVisualization: FC = () => {
    const [urlSearchParams, setSearchParams] = useQueryParams('LotVisualization');
    const page = urlSearchParams.get('page') !== null ? parseInt(urlSearchParams.get('page')!, 10) || 0 : 0;
    const user = useSelector(getUser);
    const [listExpeditions] = useActions([list.trigger]);
    const expeditionsListState = useSelector(getExpeditionListState);
    const [selectedPallet, setSelectedPallet] = useState<Pallet>();
    const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
    const canEdit = hasPermission(user, Permission.platformLotVisualization, PermissionRight.write);
    const refresh = useCallback(() => {
        setSelectedPallet(undefined);
        listExpeditions({
            customerReference: urlSearchParams.get('customerReference') ?? undefined,
            deliveryDate: urlSearchParams.get('deliveryDate')
                ? dayjs(Number(urlSearchParams.get('deliveryDate'))).toISOString()
                : undefined,
            day: urlSearchParams.get('day') ?? undefined,
            tour: urlSearchParams.get('tour') ?? undefined,
            palletStatus: urlSearchParams.get('palletStatus') ?? undefined,
            plateformCode: urlSearchParams.get('plateformCode') ?? undefined,
            status: [ExpeditionStatus.toBeProcessed, ExpeditionStatus.inProgress],
            page,
        });
    }, [listExpeditions, urlSearchParams, page]);
    const onClickEditStatus = () => {
        setIsStatusModalVisible(true);
    };
    const onCloseStatusModal = useCallback(() => {
        setIsStatusModalVisible(false);
    }, []);
    const onStatusModalSuccess = useCallback(() => {
        refresh();
    }, [refresh]);
    const onPaginationChange: PaginationProps['onChange'] = (p) => {
        setSearchParams({
            page: p ? p - 1 : 0,
        });
    };
    const onFilterSubmit: FormProps['onValuesChange'] = (values) => {
        const val = { ...values, page: undefined };

        Object.keys(val).forEach((key) => {
            if (val[key] === '' || val[key] === null) {
                val[key] = undefined;
            } else if (key === 'deliveryDate') {
                val[key] = val[key].valueOf();
            }
        });

        window.clearTimeout(searchTimeout);

        if (['customerReference', 'tour'].includes(Object.keys(val)?.[0])) {
            searchTimeout = window.setTimeout(() => {
                setSearchParams(val);
            }, 300);
        } else {
            setSearchParams(val);
        }
    };

    useEffect(() => {
        refresh();
    }, [refresh]);

    const renderItem = useCallback(
        (expedition: Expedition) => {
            const columns: Array<ListColumn<Pallet>> = [
                {
                    dataIndex: 'reference',
                    title: 'N° de lot',
                    flex: '1 1 50%',
                },
                {
                    key: 'type',
                    title: 'Type',
                    flex: '1 1 20%',
                    render: (record) => (record.vrac ? 'Vrac' : 'Palette'),
                },
                {
                    key: 'status',
                    title: 'Statut',
                    flex: '1 1 30%',
                    render: (record) => translatePalletStatus(record.status, true),
                },
                {
                    key: 'date',
                    title: 'Date de réception',
                    flex: '1 1 30%',
                    render: (record) => formatDate(record?.platformReceptionDate),
                },
                {
                    key: 'weight',
                    title: 'Poids (kg)',
                    flex: '1 1 30%',
                    render: (record) => formatNumber(record.weight),
                },
            ];

            return (
                <li className="lot-visualization-item" key={expedition.id}>
                    <header>
                        <Typography.Title level={2}>
                            Livraison du {formatDate(expedition.deliveryDate)} - Tour : {expedition.tour}
                        </Typography.Title>
                        <div>
                            <p>
                                LOTS :
                                <strong>
                                    {formatNumber(
                                        expedition.customerOrders
                                            ?.map((item) => item.pallets?.length ?? 0)
                                            .reduce((prev, curr) => prev + curr, 0)
                                    )}
                                </strong>
                            </p>
                            <p>
                                POIDS (KG) :
                                <strong>
                                    {formatNumber(
                                        expedition.customerOrders
                                            ?.map((item) => item.loadedWeight ?? 0)
                                            .reduce((prev, curr) => prev + curr, 0)
                                    )}
                                </strong>
                            </p>
                        </div>
                    </header>
                    {!expedition.customerOrders?.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    {!!expedition.customerOrders?.length && (
                        <BasicList>
                            {expedition.customerOrders?.map((customerOrder) => (
                                <li key={customerOrder.id}>
                                    <Space direction="vertical" size="large">
                                        <Card className="mb-24">
                                            <Row wrap={false} gutter={16}>
                                                <Col flex="auto">
                                                    <Descriptions
                                                        column={5}
                                                        size="small"
                                                        colon={false}
                                                        layout="vertical"
                                                    >
                                                        <Descriptions.Item label="Code client">
                                                            {customerOrder.customer?.reference ?? '—'}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Rendez-vous">
                                                            {`${translateDayOfWeek(
                                                                customerOrder.day,
                                                                true
                                                            )} ${formatTimeFromSeconds(customerOrder?.time)}`}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Client" span={3}>
                                                            {customerOrder.customer?.label ?? '—'}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Usine">
                                                            {customerOrder.loadingOrder?.organization?.name ?? '—'}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Tournée">
                                                            {customerOrder.loadingOrder?.tourNumber ?? '—'}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                <Col flex="17px">
                                                    <Divider type="vertical" />
                                                </Col>
                                                <Col flex="275px">
                                                    <div className="flex-align-center" style={{ height: '100%' }}>
                                                        <Descriptions
                                                            column={2}
                                                            size="small"
                                                            colon={false}
                                                            layout="vertical"
                                                        >
                                                            <Descriptions.Item label="Lot">
                                                                {formatNumber(customerOrder.pallets?.length)}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label="Poids (kg)">
                                                                {formatNumber(customerOrder.loadedWeight)}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <List<Pallet>
                                            columns={columns}
                                            data={customerOrder.pallets}
                                            rowKey={(record) => `${record.id}`}
                                            onRowClick={canEdit ? setSelectedPallet : undefined}
                                            onRowEnterPress={canEdit ? setSelectedPallet : undefined}
                                            isRowSelected={
                                                canEdit ? (record) => record.id === selectedPallet?.id : undefined
                                            }
                                            isRowHiglighted={(record) =>
                                                record.status === PalletStatus.receivedInPlatform
                                            }
                                            isRowWarning={(record) =>
                                                record.status === PalletStatus.receivedInPlatformDamaged
                                            }
                                            size="small"
                                        />
                                    </Space>
                                </li>
                            ))}
                        </BasicList>
                    )}
                </li>
            );
        },
        [canEdit, selectedPallet?.id]
    );

    return (
        <FixedFooter.Wrapper>
            <Seo title="Visualisation des lots" />
            <Header title="Visualisation des lots" backRoute={getRoute(RoutePathName.platformHome)} />
            <PageHeader>
                <Form
                    onValuesChange={onFilterSubmit}
                    initialValues={{
                        deliveryDate: urlSearchParams.get('deliveryDate')
                            ? dayjs(Number(urlSearchParams.get('deliveryDate')))
                            : undefined,
                        customerReference: urlSearchParams.get('customerReference') ?? undefined,
                        day: urlSearchParams.get('day') ?? undefined,
                        tour: urlSearchParams.get('tour') ?? undefined,
                        palletStatus: urlSearchParams.get('palletStatus') ?? undefined,
                        plateformCode: urlSearchParams.get('plateformCode') ?? undefined,
                    }}
                >
                    {hasRole(user, [RoleName.pfAdmin]) && (
                        <Form.Item
                            className="form-item-inline form-item-inline-start"
                            label="Afficher les lots de la plateforme :"
                            name="plateformCode"
                        >
                            <OrganizationSelect
                                placeholder="Plateforme"
                                payload={{ type: OrganizationType.platform }}
                                style={{ width: 250 }}
                                valueProp="slug"
                                withArrowNav
                            />
                        </Form.Item>
                    )}
                    <BasicList inline inlineStretch>
                        <li>
                            <Form.Item name="palletStatus">
                                <ArrowNavItem>
                                    <Select style={{ minWidth: '160' }} placeholder="Statut" allowClear>
                                        {[
                                            PalletStatus.loaded,
                                            PalletStatus.receivedInPlatform,
                                            PalletStatus.receivedInPlatformDamaged,
                                        ].map((status) => (
                                            <Select.Option value={status} key={status}>
                                                {translatePalletStatus(status, true)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </ArrowNavItem>
                            </Form.Item>
                        </li>
                        <li>
                            <Form.Item name="customerReference">
                                <ArrowNavItem>
                                    <Input.Search
                                        placeholder="Code client"
                                        size="small"
                                        className="expedition-filter"
                                        allowClear
                                    />
                                </ArrowNavItem>
                            </Form.Item>
                        </li>
                        <li>
                            <Form.Item name="deliveryDate">
                                <ArrowNavItem>
                                    <DatePicker placeholder="Date de livraison" format="DD/MM/YYYY" />
                                </ArrowNavItem>
                            </Form.Item>
                        </li>
                        <li>
                            <Form.Item name="day">
                                <DayOfWeekSelect withArrowNav />
                            </Form.Item>
                        </li>
                        <li style={{ flex: '1 1 10%' }}>
                            <Form.Item name="tour">
                                <ArrowNavItem>
                                    <InputNumber
                                        placeholder="Tour"
                                        size="small"
                                        className="expedition-filter"
                                        min={0}
                                    />
                                </ArrowNavItem>
                            </Form.Item>
                        </li>
                    </BasicList>
                </Form>
            </PageHeader>
            {expeditionsListState.loading ? (
                <BasicList>
                    {Array.from({ length: 5 }, (_, index) => (
                        <li key={index}>
                            <Skeleton paragraph={false} title active />
                            <Skeleton active />
                        </li>
                    ))}
                </BasicList>
            ) : expeditionsListState.error ? (
                <Result title={defaultErrorMessage} />
            ) : expeditionsListState.data?.items.length ? (
                <>
                    <BasicList className="lot-visualization-list">
                        {expeditionsListState.data?.items.map(renderItem)}
                    </BasicList>
                    <Pagination
                        total={expeditionsListState.data?.totalCount}
                        pageSize={expeditionsListState.data?.pageSize ?? 0}
                        current={page + 1}
                        onChange={onPaginationChange}
                        showSizeChanger={false}
                        hideOnSinglePage
                    />
                </>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {selectedPallet?.status === PalletStatus.receivedInPlatformDamaged && (
                <FixedFooter>
                    <ArrowNavItem>
                        <ButtonWithShortcut shortcut="enter" as={ButtonGrey} onClick={onClickEditStatus} block>
                            Modifier le statut
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </FixedFooter>
            )}
            {canEdit && (
                <LotStatusModal
                    visible={isStatusModalVisible}
                    onCancel={onCloseStatusModal}
                    palletId={selectedPallet?.id}
                    onSuccess={onStatusModalSuccess}
                />
            )}
        </FixedFooter.Wrapper>
    );
};

export default LotVisualization;
