import React, { FC, useEffect } from 'react';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Descriptions, message, Modal, Spin } from 'antd';
import { getRawRoute, getRoute, RoutePathName } from '../../routes';
import { useActions, useIsDesktop, useIsMobile, usePrevious } from '../../hooks';
import FixedFooter from '../../components/FixedFooter';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import { formatNumber, translateParcelType, translateReceptionStatus } from '../../helpers/i18n';
import QuantityCard from '../../components/QuantityCard';
import TitleBlack from '../../components/TitleBlack';
import { ReceptionPalletCreate } from '.';
import {
    details as receptionDetails,
    getReceptionDetailsStateById,
    getReceptionUpdateState,
    update as updateReceptionAction,
} from '../../store/actions/receptions';
import BasicList from '../../components/BasicList';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import * as ApiTypes from '../../store/api/apiTypes';
import ButtonGrey from '../../components/ButtonGrey';
import ButtonRed from '../../components/ButtonRed';
import { useModal } from '@ebay/nice-modal-react';
import ReceptionDeleteModal from './ReceptionDeleteModal';
import ReceptionPallets from './ReceptionPallets';
import ReceptionPallet from './ReceptionPallet';

const ReceptionDetails: FC = () => {
    const isMobile = useIsMobile();
    const isDesktop = useIsDesktop();
    const history = useHistory();
    const receptionDeleteModal = useModal(ReceptionDeleteModal);
    const { receptionId, parcelId } =
        useParams<{
            receptionId: string;
            parcelId: string;
        }>();
    const isPalletCreateRoute = !!useRouteMatch(getRawRoute(RoutePathName.receptionParcelPallet));
    const [fetchReception, updateReception] = useActions([receptionDetails.trigger, updateReceptionAction.trigger]);
    const receptionState = useSelector(getReceptionDetailsStateById(parseInt(receptionId, 10)));
    const receptionUpdateState = useSelector(getReceptionUpdateState);
    const previous = usePrevious({ receptionUpdateState, receptionState });
    const canAddNewPallet = receptionState.data?.status === ApiTypes.ReceptionStatus.inProgress;
    const canFinishReception = !!(
        receptionState.data?.pallets?.length && receptionState.data?.status === ApiTypes.ReceptionStatus.inProgress
    );
    const canStoreReceptionPallets = !!receptionState.data?.pallets?.length;
    const canDeleteReception =
        !receptionState.data?.pallets?.length && receptionState.data?.status === ApiTypes.ReceptionStatus.inProgress;
    const onClickNewPallet = () => {
        history.push(
            getRoute(RoutePathName.receptionParcelPallet, {
                receptionId,
                parcelId,
            })
        );
    };

    const onClickDeleteReception = () => {
        receptionDeleteModal.show({ receptionId: parseInt(receptionId, 10) });
    };

    const onClickFinishReception = () => {
        Modal.confirm({
            title: 'Êtes-vous sûr de vouloir terminer la réception de cette référence ?',
            onOk() {
                updateReception({
                    id: receptionId,
                    status: ApiTypes.ReceptionStatus.pending,
                });
            },
        });
    };

    const onClickReceptionPallets = () => {
        history.push(
            getRoute(RoutePathName.receptionPalletList, {
                receptionId,
                parcelId,
            })
        );
    };

    const onClickBack = () => {
        if (isPalletCreateRoute) {
            history.push(
                getRoute(RoutePathName.receptionParcel, {
                    receptionId,
                    parcelId,
                })
            );
        } else {
            history.push(getRoute(RoutePathName.receptionsList));
        }
    };

    useEffect(() => {
        if (receptionId) {
            fetchReception({ receptionId });
        }
    }, [fetchReception, receptionId]);

    useEffect(() => {
        if (previous?.receptionState.loading && !receptionState.loading) {
            if (receptionState.error && receptionState.error.status === 404) {
                history.push(getRoute(RoutePathName.receptionsList));
            }
        }
    }, [history, previous?.receptionState.loading, receptionState.error, receptionState.loading]);

    useEffect(() => {
        if (previous?.receptionUpdateState.loading && !receptionUpdateState.loading) {
            if (receptionUpdateState.error) {
                message.error(`Erreur lors de mise à jour de la réception`);
            } else if (receptionUpdateState.success) {
                fetchReception({ receptionId });
            }
        }
    }, [
        fetchReception,
        previous?.receptionUpdateState.loading,
        receptionId,
        receptionUpdateState.error,
        receptionUpdateState.loading,
        receptionUpdateState.success,
    ]);

    return (
        <FixedFooter.Wrapper>
            <Switch>
                <Route path={getRawRoute(RoutePathName.receptionPalletList)} exact>
                    <ReceptionPallets />
                </Route>
                <Route path={getRawRoute(RoutePathName.receptionPallet)} exact>
                    <ReceptionPallet />
                </Route>
                <Route
                    path={[
                        getRawRoute(RoutePathName.receptionParcel),
                        getRawRoute(RoutePathName.receptionParcelPallet),
                    ]}
                    exact
                >
                    <Seo title="Détail colis" />
                    <Header title="Détail colis" onClickBack={onClickBack} enableHomeButton />
                    <PageHeader>
                        <Spin spinning={receptionState.loading}>
                            <Descriptions column={{ xs: 3, md: 4 }} size="small" colon={false} layout="vertical">
                                <Descriptions.Item label="Référence">
                                    {receptionState.data?.parcel?.reference}
                                </Descriptions.Item>
                                <Descriptions.Item label="Type">
                                    {translateParcelType(receptionState.data?.parcel?.type)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Status">
                                    {translateReceptionStatus(receptionState.data?.status)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Désignation">
                                    {receptionState.data?.parcel?.label}
                                </Descriptions.Item>
                            </Descriptions>
                        </Spin>
                    </PageHeader>
                    <Route
                        path={[
                            getRawRoute(RoutePathName.receptionParcel),
                            getRawRoute(RoutePathName.receptionParcelPallet),
                        ]}
                        exact
                    >
                        <TitleBlack>Quantité reçue</TitleBlack>
                    </Route>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '1 1',
                            ...(isDesktop ? { textAlign: 'center' } : {}),
                        }}
                    >
                        <Route path={getRawRoute(RoutePathName.receptionParcel)} exact>
                            <QuantityCard
                                value={formatNumber(receptionState.data?.quantity)}
                                label="COLIS REÇUS"
                                style={{
                                    display: 'block',
                                    width: 432,
                                    maxWidth: '100%',
                                    margin: '0 auto 1.5rem',
                                }}
                            />
                            {(canAddNewPallet ||
                                canFinishReception ||
                                canStoreReceptionPallets ||
                                canDeleteReception) && (
                                <FixedFooter>
                                    <BasicList inline={isDesktop} inlineStretch={isDesktop}>
                                        {canFinishReception && (
                                            <li>
                                                <ArrowNavItem>
                                                    <ButtonWithShortcut
                                                        shortcut="enter"
                                                        onClick={onClickFinishReception}
                                                        as={ButtonGrey}
                                                        size={isMobile ? 'middle' : 'large'}
                                                        block
                                                    >
                                                        Terminer la réception
                                                    </ButtonWithShortcut>
                                                </ArrowNavItem>
                                            </li>
                                        )}

                                        {canAddNewPallet && (
                                            <li>
                                                <ArrowNavItem>
                                                    <ButtonWithShortcut
                                                        shortcut="1"
                                                        type="primary"
                                                        onClick={onClickNewPallet}
                                                        size={isMobile ? 'middle' : 'large'}
                                                        style={isDesktop ? { width: 432, maxWidth: '100%' } : undefined}
                                                        block
                                                    >
                                                        Nouvelle palette
                                                    </ButtonWithShortcut>
                                                </ArrowNavItem>
                                            </li>
                                        )}
                                        {canDeleteReception && (
                                            <li>
                                                <ArrowNavItem>
                                                    <ButtonWithShortcut
                                                        shortcut="2"
                                                        as={!receptionState.data?.quantity ? ButtonRed : ButtonGrey}
                                                        onClick={onClickDeleteReception}
                                                        size={isMobile ? 'middle' : 'large'}
                                                        style={isDesktop ? { width: 432, maxWidth: '100%' } : undefined}
                                                        block
                                                    >
                                                        {!receptionState.data?.quantity
                                                            ? `Supprimer la reception`
                                                            : `Terminer la réception`}
                                                    </ButtonWithShortcut>
                                                </ArrowNavItem>
                                            </li>
                                        )}
                                        {canStoreReceptionPallets && (
                                            <li>
                                                <ArrowNavItem>
                                                    <ButtonWithShortcut
                                                        shortcut={canFinishReception ? 'f1' : 'enter'}
                                                        size={isMobile ? 'middle' : 'large'}
                                                        onClick={onClickReceptionPallets}
                                                        block
                                                    >
                                                        Palettes à ranger
                                                    </ButtonWithShortcut>
                                                </ArrowNavItem>
                                            </li>
                                        )}
                                    </BasicList>
                                </FixedFooter>
                            )}
                        </Route>
                        <Route path={getRawRoute(RoutePathName.receptionParcelPallet)} exact>
                            <ReceptionPalletCreate />
                        </Route>
                    </div>
                </Route>
            </Switch>
        </FixedFooter.Wrapper>
    );
};

export default ReceptionDetails;
