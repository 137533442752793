import { SearchPaginationQuery } from '.';
import { PlaceAlveole } from './apiTypes';
import api from './_client';

export type PlaceAlveoleListPayload = SearchPaginationQuery;
export const list = async (payload: PlaceAlveoleListPayload = {}) =>
    await api.get('/placeAlveoles', { params: payload });

export type PlaceAlveoleCreatePayload = Omit<PlaceAlveole, 'id'>;
export const create = async (payload: PlaceAlveoleCreatePayload) => await api.post('/placeAlveoles', payload);

export type PlaceAlveoleUpdatePayload = PlaceAlveole & {
    placeAlveoleId: PlaceAlveole['id'];
};
export const update = async ({ placeAlveoleId, ...payload }: PlaceAlveoleUpdatePayload) =>
    await api.put(`/placeAlveoles/${placeAlveoleId}`, payload);

export interface PlaceAlveoleDeletePayload {
    placeAlveoleId: PlaceAlveole['id'];
}
export const del = async ({ placeAlveoleId }: PlaceAlveoleDeletePayload) =>
    await api.delete(`/placeAlveoles/${placeAlveoleId}`);
