import React, { CSSProperties } from 'react';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';

import '../../assets/styles/List.less';

import ListRow, { ListRowProps } from './ListRow';

interface SortableListRowProps<RecordType> extends Omit<ListRowProps<RecordType>, 'style' | 'wrapperStyle'> {
    animateLayoutChanges?: AnimateLayoutChanges;
    isDragDisabled?: boolean;
    id: string;
    useDragOverlay?: boolean;
    style: (values: any) => CSSProperties;
    wrapperStyle: (params: { index: number; isDragging: boolean; id: string }) => CSSProperties;
}

function SortableListRow<RecordType extends Record<string, any>>({
    isDragDisabled,
    animateLayoutChanges,
    id,
    index,
    style,
    useDragOverlay,
    wrapperStyle,
    ...props
}: SortableListRowProps<RecordType>) {
    const { attributes, isDragging, isSorting, listeners, overIndex, transform, transition, setNodeRef } = useSortable({
        animateLayoutChanges,
        id,
        disabled: isDragDisabled,
    });

    return (
        <ListRow<RecordType>
            ref={setNodeRef}
            index={index}
            isDragDisabled={isDragDisabled}
            dragging={isDragging}
            sorting={isSorting}
            style={style({
                index,
                id,
                isDragging,
                isSorting,
                overIndex,
            })}
            transform={transform}
            transition={!useDragOverlay && isDragging ? 'none' : transition}
            wrapperStyle={wrapperStyle({ index, isDragging, id })}
            listeners={listeners}
            data-index={index}
            data-id={id}
            dragOverlay={!useDragOverlay && isDragging}
            isSortable
            {...props}
            {...attributes}
        />
    );
}

export default SortableListRow;
