import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../../hooks';
import { OrderStatus, PlaceZoneType, ResupplyOrderType, TransferOrderType } from '../../../store/api/apiTypes';
import { formatZoneType, translatePlaceZoneType } from '../../../helpers/i18n';

import { Input, InputProps, message } from 'antd';
import { isZebra } from '../../../helpers/enterprise-browser';
import { detailsByScan as detailsPlaceByScan, getPlaceDetailsByScanState } from '../../../store/actions/places';
import { detailsByScan as detailsPalletByScan, getPalletDetailsByScanState } from '../../../store/actions/pallets';
import {
    create,
    detailsTransferOrder,
    getTransferOrdersCreateState,
    getTransferOrdersDetails,
} from '../../../store/actions/transferOrders';
import { translateTransferOrderError } from '../../../helpers/errors';

const shortcutScope = 'StartZoneScanModal';

interface StartZoneScanModalProps extends CustomModalProps {
    zoneType?: PlaceZoneType;
    onSubmit: (place: any) => void;
    onReturn?: () => void;
}

const StartZoneScanModal: FC<StartZoneScanModalProps> = ({ visible, zoneType, onSubmit, onReturn }) => {
    const [reference, setReference] = useState<string>();
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const [fetchPlaceDetails, fetchPalletByScan, createTransferOrder, fetchTransferOrder] = useActions([
        detailsPlaceByScan.trigger,
        detailsPalletByScan.trigger,
        create.trigger,
        detailsTransferOrder.trigger,
    ]);

    const placeScanState = useSelector(getPlaceDetailsByScanState);

    const palletByScanState = useSelector(getPalletDetailsByScanState);
    const createTransferOrdersState = useSelector(getTransferOrdersCreateState);
    const transferOrderState = useSelector(getTransferOrdersDetails);

    const handlePlaceChange: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };

    const handleSubmit = () => {
        if (zoneType === PlaceZoneType.picking) {
            fetchPlaceDetails({ reference });
        } else {
            fetchPalletByScan({ reference });
        }
    };
    const previous = usePrevious({ palletByScanState, placeScanState, createTransferOrdersState, transferOrderState });

    useEffect(() => {
        if (visible && previous?.palletByScanState.loading && !palletByScanState.loading) {
            if (palletByScanState.error) {
                message.error('Palette non reconnue');
            } else if (palletByScanState.data?.resupply?.type === ResupplyOrderType.direct) {
                message.error(`Palette réservée pour une préparation directe`);
            } else {
                const palletId = palletByScanState.data?.id;
                createTransferOrder({
                    palletId,
                    startPlaceZoneType: zoneType,
                    status: OrderStatus.toBeProcessed,
                    type: TransferOrderType.manual,
                });
            }
        }
    }, [
        visible,
        previous?.palletByScanState.loading,
        createTransferOrder,
        palletByScanState.loading,
        palletByScanState.error,
        palletByScanState.data?.id,
        zoneType,
        palletByScanState.data?.resupply?.type,
    ]);

    useEffect(() => {
        if (visible && previous?.placeScanState.loading && !placeScanState.loading) {
            if (placeScanState.error) {
                message.error('Emplacement non reconnue');
            } else {
                if (placeScanState.data?.zoneType !== zoneType) {
                    message.error(`Emplacement non reconnue en ${translatePlaceZoneType(zoneType)}`);
                } else {
                    const placeId = placeScanState.data?.id;
                    createTransferOrder({
                        placeId,
                        startPlaceZoneType: zoneType,
                        status: OrderStatus.toBeProcessed,
                        type: TransferOrderType.manual,
                    });
                }
            }
        }
    }, [
        visible,
        previous?.placeScanState.loading,
        createTransferOrder,
        placeScanState.loading,
        placeScanState.error,
        placeScanState.data?.id,
        zoneType,
        placeScanState.data?.zoneType,
    ]);

    useEffect(() => {
        if (visible && previous?.createTransferOrdersState.loading && !createTransferOrdersState.loading) {
            if (createTransferOrdersState.success) {
                fetchTransferOrder({ id: createTransferOrdersState.data?.id });
            } else {
                message.error(translateTransferOrderError(createTransferOrdersState.error?.data));
            }
        }
    }, [
        visible,
        previous?.createTransferOrdersState.loading,
        onSubmit,
        createTransferOrdersState.loading,
        createTransferOrdersState.success,
        createTransferOrdersState.error,
        createTransferOrdersState.data?.id,
        fetchTransferOrder,
    ]);

    useEffect(() => {
        if (previous?.transferOrderState.loading && !transferOrderState.loading) {
            if (transferOrderState.error) {
                onReturn?.();
            } else {
                onSubmit(createTransferOrdersState.data?.id);
            }
        }
    }, [
        createTransferOrdersState.data?.id,
        onReturn,
        onSubmit,
        previous?.transferOrderState.loading,
        transferOrderState.error,
        transferOrderState.loading,
        transferOrderState.success,
    ]);

    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (zoneType === PlaceZoneType.picking) {
                    fetchPlaceDetails({ barcode: barCode.data });
                } else {
                    fetchPalletByScan({ barcode: barCode.data });
                }
            }
        },
        {
            deps: [zoneType],
            disable: !visible,
        }
    );

    return (
        <CustomModal
            visible={visible}
            title={`depart - ${formatZoneType(zoneType)}`}
            width={368}
            keyboard={false}
            altTitle
            transitionName=""
            maskTransitionName=""
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={handleSubmit}
                        >
                            Valider
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onReturn}
                            ghost
                        >
                            Retour
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
        >
            <ArrowNavItem scope={shortcutScope}>
                <Input
                    placeholder={
                        isZebra
                            ? `Flasher ${zoneType === PlaceZoneType.picking ? "l'emplacement" : 'la palette'}`
                            : `Entrer la référence de ${
                                  zoneType === PlaceZoneType.picking ? "l'emplacement" : 'la palette'
                              }`
                    }
                    value={reference}
                    onChange={handlePlaceChange}
                />
            </ArrowNavItem>
        </CustomModal>
    );
};

export default StartZoneScanModal;
