import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { message, Spin } from 'antd';

import { getInventoriesListState, list as inventoriesList } from '../store/actions/inventories';
import { InventoryStatus, RoleName, Permission } from '../store/api/apiTypes';

import { getUser } from '../store/actions/auth';

import '../assets/styles/MainMenu.less';

import SearchPlatformReceptionModal from '../pages/platform/reception/SearchPlatformReceptionModal';
import { SearchLoadingOrderModal } from '../pages/controlLoadingOrders';
import { useActions, usePrevious } from '../hooks';
import { hasPermission, hasRole } from '../helpers/security';
import { isZebra } from '../helpers/enterprise-browser';
import BasicList from './BasicList';
import ArrowNavItem from './ArrowNavItem';
import { getRoute, RoutePathName } from '../routes';
import ButtonWithShortcut from './ButtonWithShortcut';
import ButtonLink from './ButtonLink';
import {
    IconCalendar,
    IconCheck,
    IconForklift,
    IconInventory,
    IconLoading,
    IconPlaces,
    IconPreparation,
    IconReception,
    IconResupplying,
    IconReturnLeft,
    IconSettings,
    IconStockSearch,
    IconIncidentMenu,
} from './icons';

import SearchPlatformSlugModal from './SearchPlatformSlugModal';
import SearchPlatformRdvModal from '../pages/platform/rdv/SearchPlatformRdvModal';
import PlatformLoadingScanModal from '../pages/platform/loading/PlatformLoadingScanModal';

import PlatformReturnModal from '../pages/platform/return/PlatformReturnModal';
import { useModal } from '@ebay/nice-modal-react';
import ParcelCheckingModal from '../pages/parcelChecking/parcelCheckingModal';
import SelectSectorModal from '../pages/settings/SelectSectorModal';
import ReportIncidentModal from '../pages/machinesIncidents/ReportIncidentModal';

interface MenuItem {
    link?: string;
    loading?: boolean;
    icon: typeof IconCheck;
    text: string;
    onClick?: () => void;
    component?: ReactNode;
    permissions: Permission[];
    isVisible?: boolean;
}

interface MainMenuProps {
    isPlatform?: boolean;
}

const MainMenu: FC<MainMenuProps> = ({ isPlatform }) => {
    const history = useHistory();
    const [isLoadingOrderModalVisible, setIsLoadingOrderModalVisible] = useState(false);
    const [isPlatformReceptionModalVisible, setIsPlatformReceptionModalVisible] = useState(false);
    const [isPlatformLoadingVisible, setIsPlatformLoadingVisible] = useState(false);
    const [whichPlatformSlugModalIsVisible, setWhichPlatformSlugModalIsVisible] =
        useState<'expedition' | 'lotVisualization'>();
    const [isPlatformRdvModalVisible, setIsPlatformRdvModalVisible] = useState(false);
    const [isPlatformReturnModalVisible, setIsPlatformReturnModalVisible] = useState(false);
    const [fetchInventories] = useActions([inventoriesList.trigger]);
    const inventoryListState = useSelector(getInventoriesListState);
    const user = useSelector(getUser);
    const isPlatformAdmin = hasRole(user, [RoleName.pfAdmin]);
    const isParcelCheck = hasRole(user, [RoleName.embpmkRole]);
    const checkParcelModal = useModal(ParcelCheckingModal);
    const selectSectorModal = useModal(SelectSectorModal);
    const reportIncidentModal = useModal(ReportIncidentModal);

    const previous = usePrevious({ inventoryListState });
    const onClickInventories = () => {
        if (isZebra && hasPermission(user, Permission.inventory)) {
            fetchInventories({
                status: [InventoryStatus.toBeProcessed, InventoryStatus.inProgress],
                sort: 'date',
                sortOrder: 'asc',
            });
        } else if (hasPermission(user, Permission.inventory)) {
            history.push(getRoute(RoutePathName.inventoryMenu));
        }
    };
    const onClosePlatformReceptionModal = useCallback(() => {
        setIsPlatformReceptionModalVisible(false);
    }, []);
    const onClosePlatformSlugModal = useCallback(() => {
        setWhichPlatformSlugModalIsVisible(undefined);
    }, []);
    const onClosePlatformRdvModal = useCallback(() => {
        setIsPlatformRdvModalVisible(false);
    }, []);

    const onClickLotVisualization = useCallback(() => {
        if (isZebra) {
            history.push(getRoute(RoutePathName.platformLotVisualization));
        } else {
            if (hasRole(user, [RoleName.pfAdmin])) {
                setWhichPlatformSlugModalIsVisible('lotVisualization');
            } else if (!isZebra && hasPermission(user, Permission.platformLotVisualization)) {
                history.push(getRoute(RoutePathName.platformLotVisualization));
            }
        }
    }, [history, user]);

    const onClickRdv = useCallback(() => {
        if (hasRole(user, [RoleName.pfAdmin])) {
            setIsPlatformRdvModalVisible(true);
        } else if (!isZebra && hasPermission(user, Permission.platformRDV)) {
            history.push(getRoute(RoutePathName.platformRdv));
        }
    }, [history, user]);

    const onClickExpedition = useCallback(() => {
        if (isPlatformAdmin) {
            setWhichPlatformSlugModalIsVisible('expedition');
        } else {
            history.push(getRoute(RoutePathName.platformExpedition));
        }
    }, [history, isPlatformAdmin]);

    const onClickCheckParcel = async () => await checkParcelModal.show();

    const onClickReception = async () =>
        await selectSectorModal.show().then(() => {
            history.push(getRoute(RoutePathName.receptionsList));
        });

    const onClickIncidentsMachines = () => {
        reportIncidentModal.show();
    };
    const wmsMenuItems = [
        {
            icon: IconReception,
            text: 'Réception',
            onClick: onClickReception,
            permissions: [Permission.reception],
        },
        {
            link: getRoute(RoutePathName.preparation),
            icon: IconPreparation,
            text: 'Préparation',
            permissions: [Permission.preparation],
        },
        {
            icon: IconLoading,
            text: 'Chargement',
            onClick: setIsLoadingOrderModalVisible.bind(null, true),
            component: (
                <SearchLoadingOrderModal
                    visible={isLoadingOrderModalVisible}
                    onCancel={setIsLoadingOrderModalVisible.bind(null, false)}
                />
            ),
            permissions: [Permission.loading],
        },
        {
            link: getRoute(RoutePathName.resupplyOrderList),
            icon: IconResupplying,
            text: 'Réappro.',
            permissions: [Permission.resupply],
        },
        {
            link: getRoute(RoutePathName.movementsMenu),
            icon: IconForklift,
            text: 'Mouvements',
            permissions: [
                Permission.transferOrder,
                Permission.customerReturn,
                Permission.repair,
                Permission.backToWorkshop,
                Permission.break,
                Permission.opac,
            ],
        },
        {
            icon: IconInventory,
            text: 'Inventaires tournants',
            loading: inventoryListState.loading,
            onClick: onClickInventories,
            permissions: [Permission.inventory],
        },
        {
            link: getRoute(RoutePathName.search),
            icon: IconStockSearch,
            text: 'Recherche stock',
            permissions: [Permission.searchStock],
        },
        {
            link: getRoute(RoutePathName.placeManagementMenu),
            icon: IconPlaces,
            text: 'Gestion des emplacements',
            permissions: [Permission.placeManagement],
        },
        {
            link: getRoute(RoutePathName.settings),
            icon: IconSettings,
            text: 'Paramétrages',
            permissions: [Permission.settings],
        },

        {
            onClick: onClickIncidentsMachines,
            icon: IconIncidentMenu,
            text: 'Incident engins roulants',
            permissions: [Permission.incidents],
            isVisible: isZebra,
        },
    ];

    const wmsParcelCheckingMenuItems = [
        {
            icon: IconStockSearch,
            text: 'Contrôle code barre',
            onClick: onClickCheckParcel,
            permissions: [Permission.parcels],
        },
    ];

    const platformMenuItems = [
        {
            icon: IconReception,
            text: 'Réception',
            onClick: setIsPlatformReceptionModalVisible.bind(null, true),
            component: (
                <SearchPlatformReceptionModal
                    visible={isPlatformReceptionModalVisible}
                    onCancel={onClosePlatformReceptionModal}
                />
            ),
            permissions: [Permission.platformReception],
        },
        {
            icon: IconStockSearch,
            text: 'Visualisation des lots',
            onClick: onClickLotVisualization,
            component: (
                <SearchPlatformSlugModal
                    visible={whichPlatformSlugModalIsVisible === 'lotVisualization'}
                    onCancel={onClosePlatformSlugModal}
                    redirectLink={RoutePathName.platformLotVisualization}
                    title="Visualisation des lots"
                />
            ),
            permissions: [Permission.platformLotVisualization],
        },
        {
            icon: IconCalendar,
            text: 'Rendez-vous',
            onClick: onClickRdv,
            component: (
                <SearchPlatformRdvModal visible={isPlatformRdvModalVisible} onCancel={onClosePlatformRdvModal} />
            ),
            permissions: [Permission.platformRDV],
        },

        {
            icon: IconPreparation,
            text: 'Expédition',
            permissions: [Permission.platformShipping],
            onClick: onClickExpedition,
            component: (
                <SearchPlatformSlugModal
                    visible={whichPlatformSlugModalIsVisible === 'expedition'}
                    onCancel={onClosePlatformSlugModal}
                    redirectLink={RoutePathName.platformExpedition}
                    title="Expédition"
                />
            ),
            isVisible: !isZebra,
        },

        {
            icon: IconLoading,
            text: 'Chargement',
            onClick: setIsPlatformLoadingVisible.bind(null, true),
            component: (
                <PlatformLoadingScanModal
                    visible={isPlatformLoadingVisible}
                    onCancel={setIsPlatformLoadingVisible.bind(null, false)}
                />
            ),
            permissions: [Permission.platformLoading],
        },
        {
            link: getRoute(RoutePathName.platformSettings),
            icon: IconSettings,
            text: 'Paramètres',
            permissions: [Permission.platformSetting],
        },
        {
            icon: IconReturnLeft,
            text: 'Retour',
            permissions: [Permission.platformReturn],
            onClick: setIsPlatformReturnModalVisible.bind(null, true),
            component: (
                <PlatformReturnModal
                    visible={isPlatformReturnModalVisible}
                    onCancel={() => setIsPlatformReturnModalVisible(false)}
                />
            ),
        },
    ];

    let menuItems: MenuItem[];
    if (isPlatform) {
        menuItems = platformMenuItems;
    } else if (isParcelCheck) {
        menuItems = wmsParcelCheckingMenuItems;
    } else {
        menuItems = wmsMenuItems;
    }

    menuItems = menuItems.filter(({ permissions, isVisible = true }) => {
        if (permissions?.length) {
            return permissions.some((permission) => hasPermission(user, permission)) && isVisible;
        }

        return true;
    });

    useEffect(() => {
        if (previous?.inventoryListState.loading && !inventoryListState.loading) {
            if (inventoryListState.error) {
                message.error('Une erreur est survenue pendant la récupération des inventaires');
            } else {
                if (inventoryListState.data?.items.length) {
                    history.push(
                        getRoute(RoutePathName.inventoryDetails, { inventoryId: inventoryListState.data?.items[0].id })
                    );
                } else {
                    message.info("Il n'y a aucun inventaire tournant pour le moment");
                }
            }
        }
    }, [
        previous?.inventoryListState.loading,
        inventoryListState.loading,
        inventoryListState.error,
        inventoryListState.data?.items,
        history,
    ]);

    return (
        <nav id="main-menu" className="menu">
            <BasicList grid>
                {menuItems.map(({ link, component, onClick, text, icon: Icon, loading = false }, index) => (
                    <li key={text}>
                        <Spin spinning={loading}>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    shortcut={`${index + 1}`}
                                    as={link ? ButtonLink : undefined}
                                    to={link ?? undefined}
                                    onClick={onClick}
                                    block
                                >
                                    <Icon />
                                    {text}
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </Spin>
                        {component}
                    </li>
                ))}
            </BasicList>
        </nav>
    );
};

export default MainMenu;
