import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Descriptions } from 'antd';

import { list as listPlacesAction, getPlacesListState } from '../../store/actions/places';
import { Place, PlaceZoneType } from '../../store/api/apiTypes';

import Seo from '../../components/Seo';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import List, { ListColumn } from '../../components/list/List';
import { getRoute, RoutePathName } from '../../routes';
import { useActions } from '../../hooks';
import useQueryParams from '../../hooks/queryParams';
import { stripUndefinedKeysFromObject, urlSearchParamsToObject } from '../../helpers';
import { translatePlaceZoneType } from '../../helpers/i18n';
import PlaceFormModal from './PlaceFormModal';

const PlaceManagementSearchResults: FC = () => {
    const [fetchPlaces] = useActions([listPlacesAction.trigger]);
    const [isFormModalVisible, setIsFormModalVisible] = useState(false);
    const [placeToEdit, setPlaceToEdit] = useState<Place>();
    const placesListState = useSelector(getPlacesListState);
    const [urlSearchParams] = useQueryParams('placeManagementSearchResults');
    const searchParams = useMemo(() => urlSearchParamsToObject(urlSearchParams), [urlSearchParams]);
    const { placeStoreLabel, sector, lane, alveole, level, zoneType, locker, placeStoreId } = searchParams;
    const onListRowEnter = (place: Place) => {
        setPlaceToEdit(place);
        setIsFormModalVisible(true);
    };
    const onLoadMore = () => {
        fetchPlaces(
            {
                ...searchParams,
                zoneType: PlaceZoneType.mass,
                page: (placesListState.data?.page ?? 0) + 1,
            },
            { loadMore: true }
        );
    };
    const columns: Array<ListColumn<Place>> = [
        {
            dataIndex: 'sector',
            title: 'Secteur',
            flex: '1 1 33.33333%',
        },
        {
            key: 'locker',
            title: 'Casier',
            flex: '1 1 33.33333%',
            render: ({ locker }) => locker ?? '—',
        },
        {
            key: 'zoneType',
            title: 'Type',
            flex: '1 1 33.33333%',
            render: ({ zoneType }) => translatePlaceZoneType(zoneType),
        },
        {
            key: 'units',
            title: 'Unité(s)',
            flex: '1 1 33.33333%',
            render: ({ space }) => space ?? '—',
        },
        {
            key: 'occupation',
            title: 'Occupation',
            flex: '1 1 33.33333%',
            render: ({ spot }) => spot ?? '—',
        },
    ];
    const fetchList = useCallback(() => {
        const payload = {
            zoneType,
            sector,
            lane,
            alveole,
            level,
            locker,
            store: placeStoreId,
        };
        stripUndefinedKeysFromObject(payload);
        fetchPlaces({
            ...payload,
        });
    }, [alveole, fetchPlaces, lane, level, sector, zoneType, locker, placeStoreId]);

    useEffect(() => {
        fetchList();
    }, [fetchList]);

    return (
        <>
            <Seo title="Rechercher un emplacement" />
            <Header title="Rechercher un emplacement" backRoute={getRoute(RoutePathName.placeManagementSearch)} />
            <PageHeader>
                <Descriptions size="small" column={5} colon={false} layout="vertical">
                    {placeStoreLabel && <Descriptions.Item label="Site">{placeStoreLabel}</Descriptions.Item>}
                    {sector && <Descriptions.Item label="Secteur">{sector}</Descriptions.Item>}
                    {lane && <Descriptions.Item label="Allée">{lane}</Descriptions.Item>}
                    {alveole && <Descriptions.Item label="Alveole">{alveole}</Descriptions.Item>}
                    {level && <Descriptions.Item label="Niveau">{level}</Descriptions.Item>}
                    {locker && <Descriptions.Item label="Casier">{(locker as string).toUpperCase()}</Descriptions.Item>}
                </Descriptions>
            </PageHeader>
            <List<Place>
                columns={columns}
                data={placesListState.data?.items}
                isLoading={placesListState.loading && placesListState.data?.page === undefined}
                rowKey={(record) => `${record.id}`}
                title={`${placesListState.data?.totalCount ?? 0} Emplacement${
                    placesListState.data?.totalCount ?? 0 > 1 ? 's' : ''
                } trouvé${placesListState.data?.totalCount ?? 0 > 1 ? 's' : ''}`}
                onRowClick={onListRowEnter}
                onRowEnterPress={onListRowEnter}
                style={{ marginBottom: 48 }}
            />
            {placesListState.data && placesListState.data.page < placesListState.data.pageCount - 1 && (
                <Button
                    onClick={onLoadMore}
                    loading={placesListState.loading}
                    size="small"
                    style={{ marginBottom: 16, fontSize: 14 }}
                    block
                >
                    Plus de résultats
                </Button>
            )}
            <PlaceFormModal
                visible={isFormModalVisible}
                onCancel={setIsFormModalVisible.bind(null, false)}
                onSuccess={fetchList}
                place={placeToEdit}
            />
        </>
    );
};

export default PlaceManagementSearchResults;
