import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Input, message, Form, FormProps, Select, Button } from 'antd';
import { useSelector } from 'react-redux';

import {
    getPlaceStoreCreateState,
    getPlaceStoreUpdateState,
    getPlaceStoreDeleteState,
    create as placeStoreCreate,
    update as placeStoreUpdate,
    del as placeStoreDelete,
} from '../../store/actions/placeStores';
import { PlaceStore, PlaceStoreType } from '../../store/api/apiTypes';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useShortcutScope } from '../../hooks';
import SuccessMessage from '../../components/SuccessMessage';
import ButtonRed from '../../components/ButtonRed';
import { translatePlaceStoreType } from '../../helpers/i18n';

const shortcutScope = 'PlaceStoreFormModal';

interface PlaceStoreFormModalProps extends CustomModalProps {
    placeStore?: PlaceStore;
    onSuccess?: () => void;
}

const PlaceStoreFormModal: FC<PlaceStoreFormModalProps> = ({ visible, onCancel, placeStore, onSuccess }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const isEditing = !!placeStore;
    const isMounted = useIsMounted();
    const [form] = Form.useForm();
    const [success, setSuccess] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [type, setType] = useState<PlaceStoreType>(PlaceStoreType.site);
    const [createPlaceStore, updatePlaceStore, deletePlaceStore] = useActions([
        placeStoreCreate.trigger,
        placeStoreUpdate.trigger,
        placeStoreDelete.trigger,
    ]);
    const placeStoreCreateState = useSelector(getPlaceStoreCreateState);
    const placeStoreUpdateState = useSelector(getPlaceStoreUpdateState);
    const placeStoreDeleteState = useSelector(getPlaceStoreDeleteState);
    const previous = usePrevious({ placeStoreCreateState, placeStoreUpdateState, placeStoreDeleteState });
    const inputRef = useRef<Input | null>(null);
    const onSubmit: FormProps['onFinish'] = (values) => {
        if (isEditing) {
            updatePlaceStore({
                placeStoreId: placeStore?.id,
                ...values,
            });
        } else {
            createPlaceStore(values);
        }
    };
    const onFormValuesChange: FormProps['onValuesChange'] = (changedValues) => {
        const changedField = Object.keys(changedValues)?.[0];
        if (changedField === 'type') {
            setType(changedValues.type);
        }
    };
    const onDelete = () => {
        deletePlaceStore({ placeStoreId: placeStore?.id });
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            setSuccess(false);
            if (inputRef.current) {
                inputRef.current.blur();
            }
        } else {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [visible, form, setSuccess]);

    useEffect(() => {
        if (previous?.placeStoreCreateState.loading && !placeStoreCreateState.loading) {
            if (placeStoreCreateState.error) {
                if (placeStoreCreateState.error?.status === 409) {
                    message.error('Ce code est déjà utilisé par un autre site/puits');
                } else {
                    message.error(
                        `Une erreur est survenue pendant la création du ${translatePlaceStoreType(
                            type
                        ).toLocaleLowerCase()}`
                    );
                }
            } else {
                setSuccess(true);
            }
        }
    }, [placeStoreCreateState.error, placeStoreCreateState.loading, previous?.placeStoreCreateState.loading, type]);

    useEffect(() => {
        if (previous?.placeStoreUpdateState.loading && !placeStoreUpdateState.loading) {
            if (placeStoreUpdateState.error) {
                if (placeStoreUpdateState.error?.status === 409) {
                    message.error('Ce code est déjà utilisé par un autre site/puits');
                } else {
                    message.error(
                        `Une erreur est survenue pendant la mise à jour du ${translatePlaceStoreType(
                            type
                        ).toLocaleLowerCase()}`
                    );
                }
            } else {
                setSuccess(true);
            }
        }
    }, [placeStoreUpdateState.error, placeStoreUpdateState.loading, previous?.placeStoreUpdateState.loading, type]);

    useEffect(() => {
        if (previous?.placeStoreDeleteState.loading && !placeStoreDeleteState.loading) {
            if (placeStoreDeleteState.error) {
                message.error(
                    `Une erreur est survenue pendant la suppression du ${translatePlaceStoreType(
                        type
                    ).toLocaleLowerCase()}`
                );
            } else {
                setSuccess(true);
            }
        }
    }, [placeStoreDeleteState.error, placeStoreDeleteState.loading, previous?.placeStoreDeleteState.loading, type]);

    useEffect(() => {
        let timeout: number;

        if (success) {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onClose();
                    onSuccess?.();
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [isMounted, onClose, success, onSuccess]);

    useEffect(() => {
        if (placeStore) {
            form.setFieldsValue(placeStore);
            setType(placeStore.type);
        } else {
            form.resetFields();
        }
    }, [placeStore, form]);

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            setType(PlaceStoreType.site);
            setIsDeleting(false);
        }
    }, [visible, form]);

    return (
        <CustomModal
            footer={
                success ? null : isDeleting ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                as={ButtonRed}
                                onClick={onDelete}
                                loading={placeStoreDeleteState.loading}
                            >
                                Oui, supprimer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                disabled={placeStoreDeleteState.loading}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={form.submit}
                                loading={placeStoreCreateState.loading || placeStoreUpdateState.loading}
                                disabled={placeStoreDeleteState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        {isEditing && (
                            <ArrowNavItem scope={shortcutScope}>
                                <Button onClick={setIsDeleting.bind(null, true)} danger>
                                    Supprimer
                                </Button>
                            </ArrowNavItem>
                        )}
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                disabled={placeStoreDeleteState.loading}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={
                !success &&
                (isDeleting
                    ? `Voulez-vous vraiment supprimer ce ${translatePlaceStoreType(
                          type
                      ).toLocaleLowerCase()} maintenant ?`
                    : isEditing
                    ? 'Éditer'
                    : 'Ajouter site / puits')
            }
            width={368}
            keyboard={false}
            altTitle={!isDeleting}
        >
            {success ? (
                <SuccessMessage
                    message={`Le ${translatePlaceStoreType(type).toLocaleLowerCase()} a été ${
                        isDeleting ? 'supprimé' : isEditing ? 'édité' : 'ajouté'
                    } avec succès`}
                />
            ) : !isDeleting ? (
                <Form
                    form={form}
                    onValuesChange={onFormValuesChange}
                    layout="vertical"
                    onFinish={onSubmit}
                    initialValues={{ type: PlaceStoreType.site }}
                    requiredMark={false}
                >
                    <Form.Item
                        name="type"
                        label="Type"
                        rules={[{ required: true, message: 'Veuillez choisir un type' }]}
                    >
                        <ArrowNavItem scope={shortcutScope}>
                            <Select<PlaceStoreType> style={{ width: '100%' }} filterOption={false} showSearch>
                                <Select.Option value={PlaceStoreType.site}>
                                    {translatePlaceStoreType(PlaceStoreType.site)}
                                </Select.Option>
                                <Select.Option value={PlaceStoreType.well}>
                                    {translatePlaceStoreType(PlaceStoreType.well)}
                                </Select.Option>
                            </Select>
                        </ArrowNavItem>
                    </Form.Item>
                    <Form.Item name="number" label="Code">
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir un code" readOnly={isEditing} />
                        </ArrowNavItem>
                    </Form.Item>
                    <Form.Item name="name" label="Nom" style={{ marginBottom: 0 }}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir un nom" />
                        </ArrowNavItem>
                    </Form.Item>
                </Form>
            ) : null}
        </CustomModal>
    );
};

export default PlaceStoreFormModal;
