import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { ControlPreparationStatus, Pallet, Parcel } from '../api/apiTypes';

// Controllers
import {
    PreparationControlPackagePayload,
    controlPackage as controlPackageApiCall,
    PreparationControlPackagesPayload,
    controlPackages as controlPackagesApiCall,
    PreparationControlTransferPackagePayload,
    transferPackage as transferPackageApiCall,
    PreparationControlTransferPackagesPayload,
    transferPackages as transferPackagesApiCall,
    PreparationControlRemovePackagePayload,
    removePackage as removePackageApiCall,
    PreparationControlRemovePackagesPayload,
    removePackages as removePackagesApiCall,
    brokenPackages as brokenPackagesApiCall,
    PreparationControlBrokenPackagesPayload,
    removePallet as removePalletApiCall,
    PreparationControlRemovePalletPayload,
} from '../api/preparationControl';
import { DataAction, EzeeAction } from '../helpers/EzeeAction';

import { listControlByCustomerOrderId } from './preparations';

// States

export interface PackageRef {
    reference: string;
    cont?: string;
}
export interface PreparationControlState {
    controlPackage: RequestState<Parcel>;
    controlPackages: RequestState<Pallet>;
    transferPackage: RequestState<Parcel>;
    transferPackages: RequestState<Pallet>;
    removePackage: RequestState<Parcel>;
    removePackages: RequestState<Pallet>;
    removePallet: RequestState;
    brokenPackages: RequestState<Pallet>;
    control: {
        localParcelCountByParcelId: {
            // local count, initially populated when listing current preparation,
            // and incremented each time we call controlPackage,
            // doesn't reflect server side controlled quantity, only local, because the user can
            // control packages but can also cancel before validating the control (controlPackages)
            [key: string]: number;
        };
        createPallet: {
            packagesRefs: PackageRef[];
            packagesBarcodes: string[];
            isWaitingForTransfer: boolean;
            transferredCount: number;
        };
        transfer: {
            packagesRefs: PackageRef[];
            packagesBarcodes: string[];
        };
        addPackage: {
            packagesRefs: PackageRef[];
            packagesBarcodes: string[];
        };
        removePackage: {
            packagesRefs: PackageRef[];
            packagesBarcodes: string[];
            localParcelToRemoveCountByParcelId: {
                [key: string]: number;
            };
        };
        brokenPackages: {
            packagesRefs: PackageRef[];
            packagesBarcodes: string[];
        };
        vrac: {
            packagesRefs: PackageRef[];
            packagesBarcodes: string[];
        };
        print: {
            controlPalletId?: Pallet['id'];
            vracPalletId?: Pallet['id'];
            transferPalletIds: Array<Pallet['id']>;
        };
    };
}

const initialState: PreparationControlState = {
    controlPackage: { ...requestInitialState },
    controlPackages: { ...requestInitialState },
    transferPackage: { ...requestInitialState },
    transferPackages: { ...requestInitialState },
    removePackage: { ...requestInitialState },
    removePackages: { ...requestInitialState },
    brokenPackages: { ...requestInitialState },
    removePallet: { ...requestInitialState },
    control: {
        createPallet: {
            packagesRefs: [],
            packagesBarcodes: [],
            isWaitingForTransfer: false,
            transferredCount: 0,
        },
        localParcelCountByParcelId: {},
        transfer: {
            packagesRefs: [],
            packagesBarcodes: [],
        },
        addPackage: {
            packagesRefs: [],
            packagesBarcodes: [],
        },
        removePackage: {
            packagesRefs: [],
            packagesBarcodes: [],
            localParcelToRemoveCountByParcelId: {},
        },
        brokenPackages: {
            packagesRefs: [],
            packagesBarcodes: [],
        },
        vrac: {
            packagesRefs: [],
            packagesBarcodes: [],
        },
        print: {
            transferPalletIds: [],
        },
    },
};

export const controlPackage = new EzeeAsyncAction<
    PreparationControlState['controlPackage'],
    PreparationControlPackagePayload,
    Parcel
>(
    'preparationControl/controlPackage',
    initialState.controlPackage,
    requestReducer<PreparationControlState['controlPackage'], Parcel>(initialState.controlPackage)
);

export const controlPackages = new EzeeAsyncAction<
    PreparationControlState['controlPackages'],
    PreparationControlPackagesPayload,
    Pallet
>(
    'preparationControl/controlPackages',
    initialState.controlPackages,
    requestReducer<PreparationControlState['controlPackages'], Pallet>(initialState.controlPackages)
);

export const transferPackage = new EzeeAsyncAction<
    PreparationControlState['transferPackage'],
    PreparationControlTransferPackagePayload,
    Parcel
>(
    'preparationControl/transferPackage',
    initialState.transferPackage,
    requestReducer<PreparationControlState['transferPackage'], Parcel>(initialState.transferPackage)
);

export const transferPackages = new EzeeAsyncAction<
    PreparationControlState['transferPackages'],
    PreparationControlTransferPackagesPayload,
    Pallet
>(
    'preparationControl/transferPackages',
    initialState.transferPackages,
    requestReducer<PreparationControlState['transferPackages'], Pallet>(initialState.transferPackages)
);

export const removePackage = new EzeeAsyncAction<
    PreparationControlState['removePackage'],
    PreparationControlRemovePackagePayload,
    Parcel
>(
    'preparationControl/removePackage',
    initialState.removePackage,
    requestReducer<PreparationControlState['removePackage'], Parcel>(initialState.removePackage)
);

export const removePackages = new EzeeAsyncAction<
    PreparationControlState['removePackages'],
    PreparationControlRemovePackagesPayload,
    Pallet
>(
    'preparationControl/removePackages',
    initialState.removePackages,
    requestReducer<PreparationControlState['removePackages'], Pallet>(initialState.removePackages)
);
export const brokenPackages = new EzeeAsyncAction<
    PreparationControlState['brokenPackages'],
    PreparationControlBrokenPackagesPayload,
    Pallet
>(
    'preparationControl/brokenPackages',
    initialState.brokenPackages,
    requestReducer<PreparationControlState['brokenPackages'], Pallet>(initialState.brokenPackages)
);

export const removePallet = new EzeeAsyncAction<
    PreparationControlState['removePallet'],
    PreparationControlRemovePalletPayload
>(
    'preparationControl/removePallet',
    initialState.removePallet,
    requestReducer<PreparationControlState['removePallet']>(initialState.removePallet)
);

export const control = new EzeeAction<PreparationControlState['control']>(
    'preparationControl/control',
    initialState.control,
    {
        setLocalParcelCountByParcelId: (state, payload) => ({
            ...state,
            localParcelCountByParcelId: payload,
        }),
        setCreatePalletData: (state, payload) => ({
            ...state,
            createPallet: {
                ...state.createPallet,
                ...payload,
            },
        }),
        setTransferData: (state, payload) => ({
            ...state,
            transfer: {
                ...state.transfer,
                ...payload,
            },
        }),
        setAddPackageData: (state, payload) => ({
            ...state,
            addPackage: {
                ...state.addPackage,
                ...payload,
            },
        }),
        setRemovePackageData: (state, payload) => ({
            ...state,
            removePackage: {
                ...state.removePackage,
                ...payload,
            },
        }),
        setBrokenPackageData: (state, payload) => ({
            ...state,
            brokenPackages: {
                ...state.brokenPackages,
                ...payload,
            },
        }),
        setVracData: (state, payload) => ({
            ...state,
            vrac: {
                ...state.vrac,
                ...payload,
            },
        }),
        setPrintData: (state, payload) => ({
            ...state,
            print: {
                ...state.print,
                ...payload,
            },
        }),
        resetCreatePallet: (state) => ({
            ...state,
            createPallet: {
                ...initialState.control.createPallet,
            },
        }),
        resetTransfer: (state) => ({
            ...state,
            transfer: {
                ...initialState.control.transfer,
            },
        }),
        resetAddPackage: (state) => ({
            ...state,
            addPackage: {
                ...initialState.control.addPackage,
            },
        }),
        resetRemovePackage: (state) => ({
            ...state,
            removePackage: {
                ...initialState.control.removePackage,
            },
        }),
        resetBrokenPackages: (state) => ({
            ...state,
            brokenPackages: {
                ...initialState.control.brokenPackages,
            },
        }),
        resetVrac: (state) => ({
            ...state,
            vrac: {
                ...initialState.control.vrac,
            },
        }),
        resetPrint: (state) => ({
            ...state,
            print: {
                ...initialState.control.print,
            },
        }),
    }
);

// Reducer
export const preparationControlReducer = combineReducers<PreparationControlState>({
    controlPackage: controlPackage.reducer,
    controlPackages: controlPackages.reducer,
    transferPackage: transferPackage.reducer,
    transferPackages: transferPackages.reducer,
    removePackage: removePackage.reducer,
    removePackages: removePackages.reducer,
    brokenPackages: brokenPackages.reducer,
    removePallet: removePallet.reducer,
    control: control.reducer,
});

// Saga
export function* preparationControlSaga() {
    yield takeLatest(controlPackage.type.trigger, simpleAsyncSaga(controlPackageApiCall, controlPackage));
    yield takeLatest(controlPackages.type.trigger, controlPackagesSaga);
    yield takeLatest(transferPackage.type.trigger, simpleAsyncSaga(transferPackageApiCall, transferPackage));
    yield takeLatest(transferPackages.type.trigger, simpleAsyncSaga(transferPackagesApiCall, transferPackages));
    yield takeLatest(removePackage.type.trigger, simpleAsyncSaga(removePackageApiCall, removePackage));
    yield takeLatest(removePackages.type.trigger, removePackagesSaga);
    yield takeLatest(brokenPackages.type.trigger, simpleAsyncSaga(brokenPackagesApiCall, brokenPackages));
    yield takeLatest(removePallet.type.trigger, simpleAsyncSaga(removePalletApiCall, removePallet));
}

function* controlPackagesSaga(actionData: DataAction<PreparationControlPackagesPayload>) {
    try {
        const response = yield call(controlPackagesApiCall, actionData.payload);

        yield put(
            listControlByCustomerOrderId.trigger({
                customerOrderId: actionData.payload.customerOrderId,
                controlStatus: [
                    ControlPreparationStatus.toBeProcessed,
                    ControlPreparationStatus.inProgress,
                    ControlPreparationStatus.completed,
                    ControlPreparationStatus.toRegularize,
                ],
                pageSize: 1337,
            })
        );

        return yield put(controlPackages.success(response));
    } catch (error) {
        return yield put(controlPackages.failure(error));
    }
}

function* removePackagesSaga(actionData: DataAction<PreparationControlRemovePackagesPayload>) {
    try {
        const response = yield call(removePackagesApiCall, actionData.payload);

        yield put(
            listControlByCustomerOrderId.trigger({
                customerOrderId: actionData.payload.customerOrderId,
                controlStatus: [
                    ControlPreparationStatus.toBeProcessed,
                    ControlPreparationStatus.inProgress,
                    ControlPreparationStatus.completed,
                    ControlPreparationStatus.toRegularize,
                ],
                pageSize: 1337,
            })
        );

        return yield put(removePackages.success(response));
    } catch (error) {
        return yield put(removePackages.failure(error));
    }
}

// Store helpers
export const getControlOrderControlPackageState = (state: MainReducerState) => state.preparationControl.controlPackage;
export const getControlOrderControlPackagesState = (state: MainReducerState) =>
    state.preparationControl.controlPackages;
export const getControlOrderTransferPackageState = (state: MainReducerState) =>
    state.preparationControl.transferPackage;
export const getControlOrderTransferPackagesState = (state: MainReducerState) =>
    state.preparationControl.transferPackages;
export const getControlOrderRemovePackageState = (state: MainReducerState) => state.preparationControl.removePackage;
export const getControlOrderRemovePackagesState = (state: MainReducerState) => state.preparationControl.removePackages;
export const getControlOrderControlState = (state: MainReducerState) => state.preparationControl.control;
export const getControlOrderBrokenPackagesState = (state: MainReducerState) => state.preparationControl.brokenPackages;
export const getControlOrderRemovePalletState = (state: MainReducerState) => state.preparationControl.removePallet;
