import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import CustomModal from '../../components/CustomModal';
import React, { useEffect, useState } from 'react';
import { useActions, useArrowNavScope, useShortcutScope } from '../../hooks';
import {
    defaultSector,
    getPlaceDefaultSectorState,
    getPlacesListFiltersState,
    listFilters,
} from '../../store/actions/places';
import { Place, PlaceZoneType } from '../../store/api/apiTypes';
import { Form, Select } from 'antd';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import { useSelector } from 'react-redux';

const shortcutScope = 'SelectSectorModal';

const SelectSectorModal = NiceModal.create(() => {
    const modal = useModal();
    const { hide, visible, resolve } = modal;
    const [fetchFilters, setDefaultSector] = useActions([listFilters.trigger, defaultSector.actions.setDefaultSector]);
    const filtersState = useSelector(getPlacesListFiltersState);
    const defaultSectorState = useSelector(getPlaceDefaultSectorState);
    const [sector, setSector] = useState<Place['sector']>(defaultSectorState.sector);
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    useEffect(() => {
        fetchFilters({ zoneType: PlaceZoneType.mass });
    }, [fetchFilters]);

    const onChangeSelect = (newValue: string) => {
        if (newValue) {
            setSector(newValue);
        } else {
            setSector(undefined);
        }
    };

    const onClickValid = () => {
        setDefaultSector(sector);
        resolve();
        hide();
    };

    return (
        <CustomModal
            {...antdModal(modal)}
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            type="primary"
                            shortcut="enter"
                            shortcutScope={shortcutScope}
                            shortcutOptions={{ enabled: !!sector }}
                            onClick={onClickValid}
                            disabled={!sector}
                        >
                            Valider
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={hide}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            title={'secteur par défaut'}
            width={368}
            altTitle
        >
            <>
                <Form.Item label="Secteur" style={{ margin: 0 }} labelCol={{ span: 24 }}>
                    <ArrowNavItem scope={shortcutScope}>
                        <Select
                            value={sector}
                            placeholder={'Sélectionner un secteur'}
                            loading={filtersState.loading}
                            filterOption={false}
                            onChange={onChangeSelect}
                            style={{ width: '100%' }}
                            allowClear
                            showArrow
                        >
                            {filtersState.data?.sectors?.map((sector) => (
                                <Select.Option value={sector} key={sector}>
                                    {sector}
                                </Select.Option>
                            ))}
                        </Select>
                    </ArrowNavItem>
                </Form.Item>
            </>
        </CustomModal>
    );
});

export default SelectSectorModal;
