import React, { useState, forwardRef, useEffect, ForwardRefRenderFunction } from 'react';
import { Select, SelectProps } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { useSelector } from 'react-redux';

import { Organization } from '../store/api/apiTypes';
import { getOrganizationListState, list as listOrganizationsAction } from '../store/actions/organizations';
import { OrganizationListPayload } from '../store/api/organizations';

import { useActions, usePrevious } from '../hooks';
import ArrowNavItem from './ArrowNavItem';

interface OrganizationSelectProps {
    size?: SizeType;
    placeholder?: string;
    onChange?: (value: any) => void;
    withArrowNav?: boolean;
    arrowNavScope?: string;
    payload?: OrganizationListPayload;
    value?: Organization['id'] | Organization['slug'];
    style?: SelectProps<string>['style'];
    valueProp?: 'id' | 'slug';
}

const OrganizationSelect: ForwardRefRenderFunction<typeof Select, OrganizationSelectProps> = (props, ref: any) => {
    const listOrganizations = useActions(listOrganizationsAction.trigger);
    const organizationsListState = useSelector(getOrganizationListState);
    const { onChange, size, placeholder, withArrowNav, arrowNavScope, payload, value, valueProp, style } = props;
    const [internalValue, setInternalValue] = useState<Organization['id'] | Organization['slug'] | undefined>(value);
    const previous = usePrevious({ organizationsListState });
    const onSearch: SelectProps<string>['onSearch'] = (value) => {
        listOrganizations(
            {
                ...payload,
                ...(value ? { search: value } : {}),
            },
            { throttling: 300 }
        );
    };

    const onChangeSelect = (newValue: any) => {
        if (newValue) {
            setInternalValue(newValue);
        } else {
            setInternalValue(undefined);
        }

        onChange?.(newValue);
    };

    useEffect(() => {
        setInternalValue(undefined);
        listOrganizations({ ...payload });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (previous?.organizationsListState.loading && !organizationsListState.loading) {
            if (!organizationsListState.error) {
                if (value && !internalValue) {
                    setInternalValue(value);
                }
            }
        }
    }, [
        previous?.organizationsListState.loading,
        organizationsListState.loading,
        organizationsListState.error,
        value,
        internalValue,
    ]);

    const select = (
        <Select
            size={size}
            ref={ref}
            value={internalValue}
            placeholder={placeholder ?? 'Sélectionner une plateforme'}
            loading={organizationsListState.loading}
            filterOption={false}
            onChange={onChangeSelect}
            onSearch={onSearch}
            style={{ width: '100%', ...style }}
            allowClear
            showSearch
            showArrow
        >
            {organizationsListState.data?.items?.map((organization) => (
                <Select.Option value={organization[valueProp ?? 'id']} key={organization.id}>
                    {organization.slug}
                </Select.Option>
            ))}
        </Select>
    );

    return withArrowNav ? <ArrowNavItem scope={arrowNavScope}>{select}</ArrowNavItem> : select;
};

export default forwardRef(OrganizationSelect);
