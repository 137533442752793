import { Descriptions, Divider, message, Spin, Typography } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    ControlLoadingOrderStatus,
    ControlOrderPallet,
    ControlOrderPalletStatus,
    LoadingOrderType,
    Permission,
    PermissionRight,
} from '../../store/api/apiTypes';
import {
    getControlLoadingOrderStateById,
    details as controlLoadingOrderDetails,
    palletList as controlLoadingOrderPalletList,
    close as controlLoadingOrderClose,
    detailsPolling as controlLoadingOrderDetailsPolling,
    getControlLoadingOrderPalletListState,
    getControlLoadingOrderCloseState,
} from '../../store/actions/controlLoadingOrders';
import { getUser } from '../../store/actions/auth';

import { useActions, useArrowNavScope, useIsDesktop, usePrevious, useShortcutScope } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import {
    formatDate,
    formatNumber,
    translateControlLoadingOrderStatus,
    translateControlOrderPalletStatus,
} from '../../helpers/i18n';
import { isZebra } from '../../helpers/enterprise-browser';
import { hasPermission } from '../../helpers/security';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Seo from '../../components/Seo';
import QuantityCard from '../../components/QuantityCard';
import List, { ListColumn } from '../../components/list/List';
import FixedFooter from '../../components/FixedFooter';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ButtonGrey from '../../components/ButtonGrey';
import CustomModal from '../../components/CustomModal';
import { IconWarning } from '../../components/icons';
import { getOperatorName } from '../../helpers';
import ControlLoadingOrderUnblockModal from './ControlLoadingOrderUnblockModal';
import { ControlScanModal } from '.';
import ConfirmModal from '../../components/ConfirmModal';

const ControlLoadingOrderDetails: FC = () => {
    const history = useHistory();
    const isDesktop = useIsDesktop();
    const { controlLoadingOrderId } = useParams<{ controlLoadingOrderId: string }>();
    const [isPalletControlModalVisible, setIsPalletControlModalVisible] = useState(false);
    const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
    const [isUnblockModalVisible, setIsUnblockModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    useShortcutScope('warningModal', !isWarningModalVisible);
    useArrowNavScope('warningModal', !isWarningModalVisible);
    const [
        fetchControlLoadingOrder,
        fetchControlLoadingOrderPallets,
        closeControlLoadingOrder,
        stopControlLoadingOrderPolling,
    ] = useActions([
        controlLoadingOrderDetails.trigger,
        controlLoadingOrderPalletList.trigger,
        controlLoadingOrderClose.trigger,
        controlLoadingOrderDetailsPolling.actions.stopPolling,
    ]);
    const controlLoadingOrderDetailsState = useSelector(
        getControlLoadingOrderStateById(parseInt(controlLoadingOrderId, 10))
    );
    const controlLoadingOrderCloseState = useSelector(getControlLoadingOrderCloseState);
    const controlLoadingOrderPalletListState = useSelector(getControlLoadingOrderPalletListState);
    const user = useSelector(getUser);
    const previous = usePrevious({ controlLoadingOrderDetailsState, controlLoadingOrderCloseState });
    const canReadDesktop = !isZebra && hasPermission(user, Permission.loading);
    const canEditDesktop = !isZebra && hasPermission(user, Permission.loading, PermissionRight.write);
    const onClickControlOrderPallet = (record: ControlOrderPallet) => {
        if (
            canReadDesktop ||
            controlLoadingOrderDetailsState.data?.loadingOrder?.type === LoadingOrderType.logisticsPlateform ||
            (controlLoadingOrderDetailsState.data?.loadingOrder?.type === LoadingOrderType.directDelevery &&
                controlLoadingOrderPalletListState.data?.items
                    .filter((item) => item.status !== ControlOrderPalletStatus.completed)
                    .findIndex((item) => item.id === record.id) === 0)
        ) {
            history.push(
                getRoute(RoutePathName.controlOrderPalletDetails, {
                    controlLoadingOrderId,
                    controlOrderPalletId: record.id,
                })
            );
        } else {
            setIsWarningModalVisible(true);
        }
    };
    const onUnblockSuccess = () => {
        fetchControlLoadingOrder({ controlLoadingOrderId });
    };
    const onControlScanFinish = useCallback(() => {
        if (controlLoadingOrderId) {
            fetchControlLoadingOrder({ controlLoadingOrderId });
            fetchControlLoadingOrderPallets({ controlLoadingOrderId, sort: 'rank', sortOrder: 'desc' });
        }
    }, [controlLoadingOrderId, fetchControlLoadingOrder, fetchControlLoadingOrderPallets]);
    const onClickCloseControlLoadingOrder = useCallback(() => {
        setIsConfirmModalVisible(true);
    }, []);
    const onCloseConfirmModal = useCallback(() => {
        setIsConfirmModalVisible(false);
    }, []);
    const onConfirmCloseControlLoadingOrder = useCallback(() => {
        closeControlLoadingOrder({
            controlLoadingOrderId,
        });
    }, [closeControlLoadingOrder, controlLoadingOrderId]);
    const checkedQuantity = controlLoadingOrderDetailsState.data?.palletQuantityChecked ?? 0;
    const quantityToCheck = controlLoadingOrderDetailsState.data?.palletQuantityToCheck ?? 0;
    const columns: Array<ListColumn<ControlOrderPallet>> = [
        {
            dataIndex: 'customerOrder.customer.reference',
            title: 'Code client',
            flex: '1 1 25%',
        },
        {
            dataIndex: 'customerOrder.rank',
            title: 'Rang',
            flex: '1 1 20%',
        },
        {
            dataIndex: 'customerOrder.nbOfPackageEstimated',
            title: 'Colis',
            flex: '1 1 20%',
        },
        {
            key: 'status',
            title: 'Statut',
            flex: '1 1 35%',
            render: (record) => translateControlOrderPalletStatus(record.status),
        },
        {
            dataIndex: 'customerOrder.customer.label',
            title: 'Client',
            flex: '1 1 100%',
        },
    ];

    useEffect(() => {
        if (controlLoadingOrderId) {
            fetchControlLoadingOrder({ controlLoadingOrderId }, { poll: isZebra });
            fetchControlLoadingOrderPallets({ controlLoadingOrderId, sort: 'rank', sortOrder: 'desc' });
        }
    }, [fetchControlLoadingOrder, fetchControlLoadingOrderPallets, controlLoadingOrderId]);

    // check if user has been unassigned of this controlLoadingOrder, if true redirect to home page
    useEffect(() => {
        if (
            isZebra &&
            user &&
            previous?.controlLoadingOrderDetailsState.data?.operator &&
            previous?.controlLoadingOrderDetailsState.data.operator.id === user.id &&
            controlLoadingOrderDetailsState.data?.operator?.id !== user.id
        ) {
            message.warn('Vous avez été désassigné de ce chargement', 5);
            history.push(getRoute(RoutePathName.home));
        }
    }, [previous?.controlLoadingOrderDetailsState, controlLoadingOrderDetailsState.data, user, history]);

    useEffect(
        () => () => {
            stopControlLoadingOrderPolling();
        },
        [stopControlLoadingOrderPolling]
    );

    useEffect(() => {
        if (previous?.controlLoadingOrderCloseState.loading && !controlLoadingOrderCloseState.loading) {
            if (controlLoadingOrderCloseState.error) {
                message.error('Une erreur est survenue pendant la clôture du chargement.');
            } else {
                message.success('Chargement clôturé avec succès');
                fetchControlLoadingOrder({ controlLoadingOrderId });
                fetchControlLoadingOrderPallets({ controlLoadingOrderId, sort: 'rank', sortOrder: 'desc' });
                setIsConfirmModalVisible(false);
            }
        }
    }, [
        previous?.controlLoadingOrderCloseState.loading,
        controlLoadingOrderCloseState.loading,
        controlLoadingOrderCloseState.error,
        fetchControlLoadingOrder,
        controlLoadingOrderId,
        fetchControlLoadingOrderPallets,
    ]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Détail de l'OC" />
            <Header title="Détail de l'OC" backRoute={getRoute(RoutePathName.home)} />
            <PageHeader>
                <Spin spinning={controlLoadingOrderDetailsState.loading}>
                    <Descriptions column={canReadDesktop ? 5 : 4} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="N° de l'OC">
                            {controlLoadingOrderDetailsState.data?.loadingOrder?.tourNumber ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Code plat.">
                            {controlLoadingOrderDetailsState.data?.loadingOrder?.plateformCode ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Date de livraison">
                            {formatDate(controlLoadingOrderDetailsState.data?.loadingOrder?.deliveryDate, {
                                day: 'numeric',
                                month: 'numeric',
                                year: '2-digit',
                            })}
                        </Descriptions.Item>
                        <Descriptions.Item label="Statut">
                            {translateControlLoadingOrderStatus(controlLoadingOrderDetailsState.data?.status)}
                        </Descriptions.Item>
                        {canReadDesktop && (
                            <Descriptions.Item label="Cariste">
                                {getOperatorName(controlLoadingOrderDetailsState.data?.operator)}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </Spin>
            </PageHeader>
            <QuantityCard
                value={
                    controlLoadingOrderDetailsState.data?.status !== ControlLoadingOrderStatus.toBeProcessed
                        ? `${formatNumber(checkedQuantity)}/${formatNumber(quantityToCheck)}`
                        : formatNumber(quantityToCheck)
                }
                isComplete={controlLoadingOrderDetailsState.data?.status === ControlLoadingOrderStatus.completed}
                label={
                    controlLoadingOrderDetailsState.data?.status !== ControlLoadingOrderStatus.toBeProcessed
                        ? `Palette${checkedQuantity !== 1 ? 's' : ''} contrôlée${checkedQuantity !== 1 ? 's' : ''}`
                        : 'Palettes à charger'
                }
            />
            <Divider />
            <List<ControlOrderPallet>
                columns={columns}
                data={controlLoadingOrderPalletListState.data?.items}
                rowKey={(record) => `${record.id}`}
                isLoading={controlLoadingOrderPalletListState.loading}
                isRowHiglighted={(record) => record.status === ControlOrderPalletStatus.completed}
                onRowEnterPress={onClickControlOrderPallet}
                onRowClick={onClickControlOrderPallet}
                title="Clients"
                headerInCell
            />
            <CustomModal
                visible={isWarningModalVisible}
                onCancel={setIsWarningModalVisible.bind(null, false)}
                width={368}
                footer={
                    <ArrowNavItem scope="warningModal">
                        <ButtonWithShortcut
                            type="primary"
                            shortcut="enter"
                            shortcutScope="warningModal"
                            onClick={setIsWarningModalVisible.bind(null, false)}
                            block
                        >
                            Fermer
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                }
            >
                <p style={{ textAlign: 'center', margin: 0 }}>
                    <Typography.Text>
                        <Typography.Text type="warning">
                            <IconWarning style={{ fontSize: '2.25rem', marginBottom: '1rem' }} />
                        </Typography.Text>
                        <br />
                        Vous devez effectuer le chargement des clients dans l&rsquo;ordre de la liste.
                    </Typography.Text>
                </p>
            </CustomModal>
            {isZebra && controlLoadingOrderDetailsState.data?.status !== ControlLoadingOrderStatus.completed && (
                <FixedFooter>
                    {controlLoadingOrderDetailsState.data?.loadingOrder?.type ===
                        LoadingOrderType.logisticsPlateform && (
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="enter"
                                as={ButtonGrey}
                                onClick={setIsPalletControlModalVisible.bind(null, true)}
                                block
                            >
                                Contrôle des palettes
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    )}
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            shortcut={
                                controlLoadingOrderDetailsState.data?.loadingOrder?.type ===
                                LoadingOrderType.logisticsPlateform
                                    ? '1'
                                    : 'enter'
                            }
                            as={ButtonGrey}
                            onClick={onClickCloseControlLoadingOrder}
                            block
                        >
                            Clôre le chargement
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </FixedFooter>
            )}
            <ConfirmModal
                visible={isConfirmModalVisible}
                onCancel={onCloseConfirmModal}
                onOk={onConfirmCloseControlLoadingOrder}
                okText="Continuer"
                cancelText="Annuler"
                message="En cliquant sur « Continuer » vous validez la clôture du chargement."
            />
            <ControlScanModal
                visible={isPalletControlModalVisible}
                onCancel={setIsPalletControlModalVisible.bind(null, false)}
                controlLoadingOrderId={controlLoadingOrderDetailsState.data?.id}
                checkedQuantity={checkedQuantity}
                quantityToCheck={quantityToCheck}
                onFinish={onControlScanFinish}
                isPlatform
            />
            <ControlLoadingOrderUnblockModal
                visible={isUnblockModalVisible}
                onCancel={setIsUnblockModalVisible.bind(null, false)}
                controlLoadingOrderId={controlLoadingOrderDetailsState.data?.id}
                onSuccess={onUnblockSuccess}
            />
            {canEditDesktop && controlLoadingOrderDetailsState.data?.operator && (
                <FixedFooter>
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            shortcut="f1"
                            onClick={setIsUnblockModalVisible.bind(null, true)}
                            size={!isDesktop ? 'middle' : 'large'}
                            block
                        >
                            Débloquer
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </FixedFooter>
            )}
        </FixedFooter.Wrapper>
    );
};

export default ControlLoadingOrderDetails;
