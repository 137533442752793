import React, { FC, useCallback, useState } from 'react';
import { Select } from 'antd';

import { PalletSize } from '../../../store/api/apiTypes';

import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useArrowNavScope, useShortcutScope } from '../../../hooks';
import { translatePalletSize } from '../../../helpers/i18n';

const shortcutScope = 'PalletSizeModal';
interface PalletSizeModalProps extends CustomModalProps {
    onSubmit: (size?: PalletSize) => void;
}
const PalletSizeModal: FC<PalletSizeModalProps> = ({ visible, onCancel, onSubmit }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const [size, setSize] = useState<PalletSize>();

    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    return (
        <CustomModal
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            type="primary"
                            shortcut="enter"
                            shortcutScope={shortcutScope}
                            onClick={onSubmit.bind(null, size)}
                            disabled={!size}
                        >
                            Suivant
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            type="primary"
                            shortcut="esc"
                            shortcutScope={shortcutScope}
                            onClick={onClose}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            visible={visible}
            onCancel={onCancel}
            title={'Taille de la palette'}
            width={368}
            keyboard={false}
            altTitle
        >
            <ArrowNavItem scope={shortcutScope}>
                <Select style={{ width: '100%' }} value={size} onChange={setSize} placeholder="Choisir une taille">
                    {Object.values(PalletSize).map((size) => (
                        <Select.Option value={size} key={size}>
                            {translatePalletSize(size)}
                        </Select.Option>
                    ))}
                </Select>
            </ArrowNavItem>
        </CustomModal>
    );
};

export default PalletSizeModal;
