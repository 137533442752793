import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import CustomModal from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import React, { useEffect, useRef, useState } from 'react';
import { isZebra } from '../../helpers/enterprise-browser';
import { Input, InputProps, message, Spin } from 'antd';
import { Pallet, PalletStatus } from '../../store/api/apiTypes';
import {
    detailsByBarcode,
    detailsByReference,
    getPalletDetailsStateByBarcode,
    getPalletDetailsStateByReference,
} from '../../store/actions/pallets';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';

const shortcutScope = 'ReceptionPalletScanModal';

const ReceptionPalletScanModal = NiceModal.create(() => {
    const modal = useModal();
    const { visible, hide } = modal;
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const history = useHistory();
    const inputRef = useRef<Input | null>(null);
    const [loadPalletDetailsByBarcode, loadPalletDetailsByRef] = useActions([
        detailsByBarcode.trigger,
        detailsByReference.trigger,
    ]);
    const [reference, setReference] = useState<Pallet['reference']>();
    const [barcode, setBarcode] = useState<Pallet['barcode']>();
    const palletByRefState = useSelector(getPalletDetailsStateByReference(reference));
    const palletByBarcodeState = useSelector(getPalletDetailsStateByBarcode(barcode));
    const previous = usePrevious({ palletByRefState, palletByBarcodeState });

    const onSubmit = () => {
        loadPalletDetailsByRef({ reference });
    };

    const onChangeField: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };

    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                setBarcode(barCode.data);
                loadPalletDetailsByBarcode({
                    barcode: barCode.data,
                });
            }
        },
        {
            deps: [loadPalletDetailsByBarcode],
            disable: !visible || palletByBarcodeState.loading,
        }
    );

    useEffect(() => {
        if (previous?.palletByRefState.loading && !palletByRefState.loading) {
            if (palletByRefState.error) {
                if (palletByRefState.error?.status === 404) {
                    message.error('Palette non trouvée');
                } else {
                    message.error('Une erreur est survenue lors de la demande de palette');
                }
            } else if (
                !palletByRefState.data?.reception?.id ||
                !palletByRefState.data?.parcel?.id ||
                palletByRefState.data.status === PalletStatus.opened
            ) {
                message.error(`Il n'y a pas de réception en cours pour cette palette`);
            } else {
                hide();
                history.push(
                    getRoute(RoutePathName.receptionPallet, {
                        receptionId: palletByRefState.data.reception.id,
                        parcelId: palletByRefState.data.parcel.id,
                        palletId: palletByRefState.data.id,
                    })
                );
            }
        }
    }, [
        hide,
        history,
        palletByRefState.data?.id,
        palletByRefState.data?.parcel?.id,
        palletByRefState.data?.reception?.id,
        palletByRefState.data?.status,
        palletByRefState.error,
        palletByRefState.loading,
        previous?.palletByRefState.loading,
    ]);

    useEffect(() => {
        if (previous?.palletByBarcodeState.loading && !palletByBarcodeState.loading) {
            if (palletByBarcodeState.error) {
                if (palletByBarcodeState.error?.status === 404) {
                    message.error('Palette non trouvée');
                } else {
                    message.error('Une erreur est survenue lors de la demande de palette');
                }
            } else if (
                !palletByBarcodeState.data?.reception?.id ||
                !palletByBarcodeState.data?.parcel?.id ||
                palletByRefState.data?.status === PalletStatus.opened
            ) {
                message.error(`Il n'y a pas de réception en cours pour cette palette`);
            } else {
                hide();
                history.push(
                    getRoute(RoutePathName.receptionPallet, {
                        receptionId: palletByBarcodeState.data.reception.id,
                        parcelId: palletByBarcodeState.data.parcel.id,
                        palletId: palletByBarcodeState.data.id,
                    })
                );
            }
        }
    }, [
        hide,
        history,
        palletByBarcodeState.data?.id,
        palletByBarcodeState.data?.parcel?.id,
        palletByBarcodeState.data?.reception?.id,
        palletByBarcodeState.error,
        palletByBarcodeState.loading,
        palletByRefState.data?.status,
        previous?.palletByBarcodeState.loading,
    ]);

    useEffect(() => {
        if (!visible) {
            if (inputRef.current) {
                setReference(undefined);
                inputRef.current.blur();
            }
        } else {
            if (inputRef.current) {
                inputRef.current.setValue('');
                inputRef.current.focus();
            }
        }
    }, [visible]);

    return (
        <CustomModal
            {...antdModal(modal)}
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onSubmit}
                            disabled={!reference}
                            loading={palletByRefState.loading}
                        >
                            Valider
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={hide}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            title="Rangement des palettes"
            width={368}
            keyboard={false}
            altTitle
        >
            <Spin spinning={palletByBarcodeState.loading}>
                <ArrowNavItem scope={shortcutScope}>
                    <Input
                        placeholder={`Entrer la référence de la palette${isZebra ? ' ou flasher le code-barre' : ''}`}
                        onChange={onChangeField}
                        ref={inputRef}
                        autoFocus
                    />
                </ArrowNavItem>
            </Spin>
        </CustomModal>
    );
});

export default ReceptionPalletScanModal;
