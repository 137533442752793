/* eslint-disable @typescript-eslint/no-unused-vars */
import { Descriptions, Spin, Divider } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    CustomerOrder,
    CustomerOrderStatus,
    Permission,
    PermissionRight,
    PlatformLoadingStatus,
    SortOrder,
} from '../../../store/api/apiTypes';

import { formatNumber, translateCustomerOrderStatus, translatePlatformLoadingStatus } from '../../../helpers/i18n';

import FixedFooter from '../../../components/FixedFooter';
import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import PageHeader from '../../../components/PageHeader';
import QuantityCard from '../../../components/QuantityCard';
import Seo from '../../../components/Seo';

import { getRoute, RoutePathName } from '../../../routes';
import { details, getPlatformLoadingsStateById } from '../../../store/actions/platformLoadings';
import { useActions, useIsMobile } from '../../../hooks';
import WarningModal from '../../../components/WarningModal';
import { getCustomerOrderListState, list as listCustomerOrders } from '../../../store/actions/customerOrders';

const PlatformLoadingDetails: FC = () => {
    const { loadingId } = useParams<{ loadingId: string }>();

    const history = useHistory();
    const [fetchLoadingDetails, fetchCustomerOrders] = useActions([details.trigger, listCustomerOrders.trigger]);
    const customOrdersState = useSelector(getCustomerOrderListState);
    const loadingState = useSelector(getPlatformLoadingsStateById(Number(loadingId)));
    const [isWarninModalVisible, setIsWarningModalVisible] = useState(false);
    useEffect(() => {
        fetchLoadingDetails({ id: loadingId });
        fetchCustomerOrders({ platformLoadingId: loadingId, sort: 'rank', sortOrder: SortOrder.asc });
    }, [fetchCustomerOrders, fetchLoadingDetails, loadingId]);

    const isMobile = useIsMobile();
    const columns: Array<ListColumn<CustomerOrder>> = [
        {
            dataIndex: 'customer.reference',
            title: 'Code client',
            flex: isMobile ? '1 1 25%' : '1 1 20%',
        },
        {
            dataIndex: 'rank',
            title: 'Rang',
            flex: isMobile ? '1 1 20%' : '1 1 10%',
        },
        {
            dataIndex: 'nbOfPackageEstimated',
            title: 'Colis',
            flex: isMobile ? '1 1 20%' : '1 1 10%',
        },
        {
            key: 'status',
            title: 'Statut',
            flex: isMobile ? '1 1 35%' : '1 1 20%',
            render: (record) => translateCustomerOrderStatus(record.status),
        },
        {
            dataIndex: 'customer.label',
            title: 'Client',
            flex: isMobile ? '1 1 100%' : '1 1 40%',
        },
    ];

    const isValidOrder = (record: CustomerOrder) => {
        return !customOrdersState.data?.items?.some(
            (customerOrder, index, arr) =>
                index < arr.findIndex((co) => co.id === record.id) &&
                customerOrder.status !== CustomerOrderStatus.loadedSendToClient
        );
    };
    const onRowClick = (record: CustomerOrder) => {
        if (isValidOrder(record)) {
            history.push(
                getRoute(RoutePathName.platformLoadingCustomerOrderDetails, {
                    loadingId: loadingId,
                    customerOrderId: record.id,
                })
            );
        } else {
            setIsWarningModalVisible(true);
        }
    };

    return (
        <FixedFooter.Wrapper>
            <Seo title="Détail du chargement" />
            <Header title="Détail du chargement" backRoute={getRoute(RoutePathName.platformHome)} />
            <PageHeader>
                <Spin spinning={loadingState.loading}>
                    <Descriptions column={4} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="N° de chargement">{loadingState.data?.reference}</Descriptions.Item>
                        <Descriptions.Item label="Statut">
                            {translatePlatformLoadingStatus(loadingState.data?.status)}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>
            <QuantityCard
                label="Lots à charger"
                value={
                    loadingState.data?.quantityLoaded
                        ? `${formatNumber(loadingState.data?.quantityLoaded)}/${formatNumber(
                              loadingState.data?.quantityToLoad
                          )}`
                        : formatNumber(loadingState.data?.quantityToLoad)
                }
                isComplete={loadingState.data?.status === PlatformLoadingStatus.completed}
            />
            <Divider />
            <WarningModal
                message="Vous devez effectuer le chargement des clients dans l'ordre de la liste"
                visible={isWarninModalVisible}
                onCancel={setIsWarningModalVisible.bind(null, false)}
            />
            <List<CustomerOrder>
                columns={columns}
                data={customOrdersState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowClick={onRowClick}
                onRowEnterPress={onRowClick}
                isLoading={loadingState.loading || customOrdersState.loading}
                isRowHiglighted={(record) => record.status === CustomerOrderStatus.loadedSendToClient}
                title="Clients"
                headerInCell
            />
        </FixedFooter.Wrapper>
    );
};

export default PlatformLoadingDetails;
