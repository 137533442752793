import React, { FC, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PaginationProps } from 'antd';
import { useSelector } from 'react-redux';

import { Inventory, InventoryStatus } from '../../store/api/apiTypes';
import { getInventoriesListState, list } from '../../store/actions/inventories';
import { ListResponse } from '../../store/api';

import Header from '../../components/Header';
import List, { ListColumn } from '../../components/list/List';
import Seo from '../../components/Seo';
import { useActions, useIsMobile } from '../../hooks';
import { getRawRoute, getRoute, RoutePathName } from '../../routes';
import { formatDate, formatNumber, translateInventoryStatus, translateInventoryType } from '../../helpers/i18n';
import { getOperatorName } from '../../helpers';
import FixedFooter from '../../components/FixedFooter';
import useQueryParams from '../../hooks/queryParams';

const getPageTitle = (isCurrent: boolean) => {
    if (isCurrent) {
        return 'Inventaires en cours';
    } else {
        return 'Historique des inventaires';
    }
};

const getRequestStatuses = (isCurrent: boolean) => {
    if (isCurrent) {
        return [InventoryStatus.toBeProcessed, InventoryStatus.inProgress];
    } else {
        return InventoryStatus.completed;
    }
};

const getListTitle = (listState: ListResponse<Inventory> | undefined, isCurrent: boolean) => {
    if (listState) {
        if (isCurrent) {
            return `${formatNumber(listState?.totalCount)} inventaire${
                listState?.totalCount > 1 ? 's' : ''
            } en cours / à traiter`;
        } else {
            return `${formatNumber(listState?.totalCount)} inventaire${
                listState?.totalCount > 1 ? 's' : ''
            } ${`réalisé${listState?.totalCount > 1 ? 's' : ''}`}`;
        }
    } else {
        if (isCurrent) {
            return 'Inventaires en cours / à traiter';
        } else {
            return 'Inventaires réalisés';
        }
    }
};

const InventoryList: FC = () => {
    const isMobile = useIsMobile();
    const history = useHistory();
    const isCurrent = !!useRouteMatch(getRawRoute(RoutePathName.inventoryCurrent));
    const [queryParams, setQueryParams] = useQueryParams('inventoryList');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const fetchInventories = useActions(list.trigger);
    const inventoryListState = useSelector(getInventoriesListState);
    const onClickInventory = (record: Inventory) => {
        history.push(getRoute(RoutePathName.inventoryDetails, { inventoryId: record.id }));
    };
    const onPaginationChange: PaginationProps['onChange'] = (p) => {
        setQueryParams({
            page: p ? p - 1 : 0,
        });
    };
    const pageTitle = getPageTitle(isCurrent);
    const columns: Array<ListColumn<Inventory>> = [
        {
            key: 'date',
            title: 'Date',
            flex: '1 1 25%',
            render: (record) => formatDate(record.date),
        },
        {
            key: 'time',
            title: 'Heure',
            flex: '1 1 25%',
            render: (record) =>
                formatDate(record.date, {
                    minute: '2-digit',
                    hour: '2-digit',
                }),
        },
        {
            key: 'type',
            title: 'Type',
            flex: '1 1 25%',
            render: (record) => translateInventoryType(record.type),
        },
        {
            key: 'status',
            title: 'Statut',
            flex: '1 1 25%',
            render: (record) => translateInventoryStatus(record.status),
        },
        {
            key: 'operator',
            title: 'Cariste',
            flex: '1 1 25%',
            render: (record) => getOperatorName(record.operator),
        },
    ];

    useEffect(() => {
        fetchInventories({
            status: getRequestStatuses(isCurrent),
            sort: 'date',
            sortOrder: 'desc',
            page,
        });
    }, [fetchInventories, isCurrent, page]);

    return (
        <FixedFooter.Wrapper>
            <Seo title={pageTitle} />
            <Header title={pageTitle} backRoute={getRoute(RoutePathName.inventoryMenu)} />
            <List<Inventory>
                columns={columns}
                data={inventoryListState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowEnterPress={onClickInventory}
                onRowClick={onClickInventory}
                isLoading={inventoryListState.loading}
                title={getListTitle(inventoryListState.data, isCurrent)}
                style={isMobile ? { marginTop: 24 } : undefined}
                columnWidthGrow={false}
                pagination={{
                    total: inventoryListState.data?.totalCount,
                    pageSize: inventoryListState.data?.pageSize,
                    hideOnSinglePage: true,
                    current: page + 1,
                    onChange: onPaginationChange,
                }}
            />
        </FixedFooter.Wrapper>
    );
};

export default InventoryList;
