import {
    MovementHistoryType,
    OrderStatus,
    Pallet,
    Place,
    PlaceZoneType,
    TransferOrder,
    TransferOrderStatus,
    TransferOrderType,
    TransferType,
} from './apiTypes';
import api from './_client';

export interface TransferOrdersListPayload {
    type?: TransferOrderType;
    status?: TransferOrderStatus | TransferOrderStatus[];
}

export const list = async (payload: TransferOrdersListPayload) =>
    await api.get(`/transferOrders`, {
        params: {
            type: TransferOrderType.auto,
            status: [TransferOrderStatus.inProgress, TransferOrderStatus.toBeProcessed],
            ...payload,
        },
    });
export interface TransferOrderIdPayload {
    id: TransferOrder['id'];
}

export const details = async ({ id, ...payload }: TransferOrderIdPayload) => {
    return await api.get(`/transferOrders/${id}`, { params: payload });
};

export type TransferOrderUpdatePayload = Partial<TransferOrder> & TransferOrderIdPayload;

export const update = async ({ id, ...payload }: TransferOrderUpdatePayload) => {
    return await api.put(`/transferOrders/${id}`, payload);
};

export const del = async ({ id, ...payload }: TransferOrderIdPayload) => {
    return await api.delete(`/transferOrders/${id}`, { params: payload });
};

export interface StoreTransferOrderPayload {
    id: TransferOrder['id'];
    palletId: Pallet['id'];
    placeId: Place['id'];
    type: MovementHistoryType;
    index?: Place['index'];
}

export const updateAndStore = async ({ id, ...payload }: StoreTransferOrderPayload) =>
    await api.post(`/transferOrders/${id}/store`, payload);

export interface TransferOrderCreationPayload {
    placeId?: Place['id'];
    palletId?: Pallet['id'];
    startPlaceZoneType: PlaceZoneType;
    status: OrderStatus;
    type: TransferOrderType;
}

export const create = async (payload: TransferOrderCreationPayload) => await api.post(`/transferOrders`, payload);

export interface StorePackagesPayload {
    id: TransferOrder['id'];
    placeId?: number;
    palletId?: Pallet['id'];
    packageQuantity?: number;
    transferedPackages: number;
    transferType: TransferType;
    finishPlaceZoneType?: PlaceZoneType;
}

export const updateAndStorePackages = async ({ id, ...payload }: StorePackagesPayload) =>
    await api.post(`/transferOrders/${id}/packages`, { ...payload, type: MovementHistoryType.transfer });

export interface StorePackagesMultiPalletPayload {
    id: TransferOrder['id'];
    placeId?: number;
    palletId?: Pallet['id'];
    packageQuantity?: number;
    transferedPackages: number;
    transferType: TransferType;
    finishPlaceZoneType?: PlaceZoneType;
    status?: TransferOrderStatus;
    type: MovementHistoryType;
    index?: number;
}
export const UpdateAndStorePackagesMultiPallet = async ({ id, ...payload }: StorePackagesPayload) =>
    await api.post(`/transferOrders/${id}/storepackages`, { ...payload, type: MovementHistoryType.transfer });
