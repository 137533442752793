import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestInitialState, requestReducer } from '../helpers';

// Types
import { Organization } from '../api/apiTypes';

// Controlers
import { list as listApiCall, OrganizationListPayload } from '../api/organizations';
import { ListResponse } from '../api';

// States
export interface OrganizationsState {
    list: RequestState<ListResponse<Organization>>;
}

const initialState: OrganizationsState = {
    list: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<
    OrganizationsState['list'],
    OrganizationListPayload,
    ListResponse<Organization>
>(
    'organizations/list',
    initialState.list,
    requestReducer<OrganizationsState['list'], ListResponse<Organization>>(initialState.list)
);

// Reducer
export const organizationsReducer = combineReducers<OrganizationsState>({
    list: list.reducer,
});

// Saga
export function* organizationsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
}

// Store helpers
export const getOrganizationListState = (state: MainReducerState) => state.organizations.list;
