import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 56 56">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M0 56h56V0H0v56zm42.627-42.627h10.866V2.507H42.627v10.866zm-13.373 0h10.865V2.507H29.254v10.866zm-13.373 0h10.866V2.507H15.881v10.866zm-13.374 0h10.867V2.507H2.507v10.866zm40.12 13.373h10.866V15.881H42.627v10.865zm-13.373 0h10.865V15.881H29.254v10.865zm-13.373 0h10.866V15.881H15.881v10.865zm-13.374 0h10.867V15.881H2.507v10.865zm40.12 13.374h10.866V29.254H42.627V40.12zm-13.373 0h10.865V29.254H29.254V40.12zm-13.373 0h10.866V29.254H15.881V40.12zm-13.374 0h10.867V29.254H2.507V40.12zm40.12 13.373h10.866V42.627H42.627v10.866zm-13.373 0h10.865V42.627H29.254v10.866zm-13.373 0h10.866V42.627H15.881v10.866zm-13.374 0h10.867V42.627H2.507v10.866z"
        />
    </svg>
);

const IconPlaces: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-places${props.className ? ` ${props.className}` : ''}`} />
);

export default IconPlaces;
