import { Form, FormProps, Input, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    checkPallet as platformReceptionCheckPallet,
    sendPallets as platformReceptionSendPallets,
    getPlatformReceptionCheckPalletState,
    getPlatformReceptionSendPalletsState,
    getPlatformReceptionDetailsStateById,
    getPlatformReceptionReceptionState,
    reception,
} from '../../../store/actions/platformReceptions';

import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import QuantityCard from '../../../components/QuantityCard';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../../hooks';
import {
    formatNumber,
    getPlatformReceptionCheckPalletError,
    getPlatformReceptionSendPalletsError,
} from '../../../helpers/i18n';

const shortcutScope = 'PalletScanModal';

interface PalletScanModalProps extends CustomModalProps {
    onFinish: () => void;
    onAnomaly: () => void;
}

const PalletScanModal: FC<PalletScanModalProps> = ({ visible, onCancel, onFinish, onAnomaly }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const { platformReceptionId: platformReceptionIdParam } = useParams<{ platformReceptionId: string }>();
    const platformReceptionId = parseInt(platformReceptionIdParam, 10);
    const [form] = Form.useForm();
    const [isManual, setIsManual] = useState(false);
    const [tempReference, setTempReference] = useState<string>(); // temp data when waiting for API response
    const [tempBarcode, setTempBarcode] = useState<string>(); // temp data when waiting for API response
    const [checkPallet, sendPallets, setReceiveState, resetReceiveState] = useActions([
        platformReceptionCheckPallet.trigger,
        platformReceptionSendPallets.trigger,
        reception.actions.setReceiveData,
        reception.actions.resetReceive,
    ]);
    const { palletRefs, palletBarcodes } = useSelector(getPlatformReceptionReceptionState).receive;
    const receivedCount = palletRefs.length + palletBarcodes.length;
    const anomalyCount =
        palletRefs.reduce((count, ref) => (count += ref.anomalyCode ? 1 : 0), 0) +
        palletBarcodes.reduce((count, barcode) => (count += barcode.anomalyCode ? 1 : 0), 0);
    const platformReceptionState = useSelector(getPlatformReceptionDetailsStateById(platformReceptionId));
    const checkPalletState = useSelector(getPlatformReceptionCheckPalletState);
    const sendPalletsState = useSelector(getPlatformReceptionSendPalletsState);
    const remainingQuantityToReceive =
        (platformReceptionState.data?.quantity ?? 0) -
        (platformReceptionState.data?.receivedQuantity ?? 0) -
        palletRefs.length -
        palletBarcodes.length;
    const previous = usePrevious({ visible, checkPalletState, sendPalletsState });
    const onClose = useCallback(() => {
        onCancel?.({} as React.MouseEvent<HTMLElement, MouseEvent>);
    }, [onCancel]);
    const onClickCancel = () => {
        onClose();
        setTempReference(undefined);
        setTempBarcode(undefined);
        form.resetFields();
        setIsManual(false);
        resetReceiveState();
    };
    const onClickAnomaly = () => {
        setReceiveState({ isWaitingForAnomaly: true });
        onClose();
        onAnomaly();
    };
    const onSubmitManual: FormProps['onFinish'] = (values) => {
        if (palletRefs.some((palletRef) => palletRef.reference === values.reference)) {
            message.error('Vous avez déjà entré ce lot');
        } else {
            setTempReference(values.reference);
            checkPallet({
                platformReceptionId,
                reference: values.reference,
            });
        }
    };
    const onSubmit = () => {
        sendPallets({
            platformReceptionId,
            barcodes: palletBarcodes.length ? palletBarcodes : undefined,
            references: palletRefs.length ? palletRefs : undefined,
        });
    };
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (palletBarcodes.some((palletBarcode) => palletBarcode.barcode === barCode.data)) {
                    message.error('Vous avez déjà scanné ce lot');
                } else {
                    setTempBarcode(barCode.data);
                    checkPallet({
                        platformReceptionId,
                        barcode: barCode.data,
                    });
                }
            } else {
                console.error(barCode);
            }
        },
        {
            deps: [checkPallet, palletBarcodes, platformReceptionId, visible],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    useEffect(() => {
        if (visible && previous?.checkPalletState.loading && !checkPalletState.loading) {
            if (checkPalletState.error) {
                const requestError = getPlatformReceptionCheckPalletError(checkPalletState);

                if (requestError.message) {
                    message.error(requestError.message);
                }
            } else if (checkPalletState.data) {
                if (remainingQuantityToReceive === 0) {
                    message.error('Le nombre maximum de lots à réceptionner est déjà atteint', 5);
                } else {
                    setReceiveState({
                        palletBarcodes: tempBarcode ? [...palletBarcodes, { barcode: tempBarcode }] : palletBarcodes,
                        palletRefs: tempReference ? [...palletRefs, { reference: tempReference }] : palletRefs,
                    });
                }

                form.resetFields();
                setIsManual(false);
            }
            setTempBarcode(undefined);
            setTempReference(undefined);
        }
    }, [
        previous?.checkPalletState.loading,
        checkPalletState,
        tempBarcode,
        tempReference,
        form,
        palletBarcodes,
        palletRefs,
        visible,
        remainingQuantityToReceive,
        setReceiveState,
    ]);

    useEffect(() => {
        if (visible && previous?.sendPalletsState.loading && !sendPalletsState.loading) {
            if (sendPalletsState.error) {
                const requestError = getPlatformReceptionSendPalletsError(sendPalletsState);

                if (requestError.message) {
                    message.error(requestError.message);
                }
            } else {
                resetReceiveState();
                onFinish();
                onClose();
            }
        }
    }, [
        previous?.sendPalletsState.loading,
        sendPalletsState.loading,
        sendPalletsState.error,
        sendPalletsState.data,
        onFinish,
        visible,
        sendPalletsState,
        resetReceiveState,
        onClose,
    ]);

    return (
        <CustomModal
            footer={
                isManual ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={() => form.submit()}
                                loading={checkPalletState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={setIsManual.bind(null, false)}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={onSubmit}
                                loading={sendPalletsState.loading}
                                disabled={receivedCount === 0}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="f1"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={setIsManual.bind(null, true)}
                                ghost
                            >
                                Saisir manuellement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="f2"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={onClickAnomaly}
                                ghost
                            >
                                Déclarer avarie
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClickCancel}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={isManual ? 'Saisir manuellement' : 'Veuillez scanner les lots que vous souhaitez réceptionner :'}
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            maskClosable={false}
            altTitle={isManual}
        >
            {isManual ? (
                <Form form={form} onFinish={onSubmitManual}>
                    <Form.Item name="reference" rules={[{ required: true, message: 'Veuillez saisir une référence' }]}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir référence lot" autoFocus />
                        </ArrowNavItem>
                    </Form.Item>
                </Form>
            ) : (
                <QuantityCard
                    value={formatNumber(receivedCount)}
                    label={`lot${receivedCount > 1 ? 's' : ''} reçu${receivedCount > 1 ? 's' : ''}${
                        anomalyCount ? ` dont ${anomalyCount} en avarie` : ''
                    }`}
                    outline
                />
            )}
        </CustomModal>
    );
};

export default PalletScanModal;
