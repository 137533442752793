import { Form, FormProps, Input, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    checkPlatformLoadingsPallet,
    checkPlatformLoadingsPallets,
    getChekPlatformLoadingsPalletState,
    getChekPlatformLoadingsPalletsState,
    reception,
    getPlatformReceptionState,
} from '../../../store/actions/platformLoadings';

import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import QuantityCard from '../../../components/QuantityCard';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../../hooks';
import { formatNumber, getPlatformLoadingCheckPalletError } from '../../../helpers/i18n';

const shortcutScope = 'PalletScanModal';

interface PalletScanModalProps extends CustomModalProps {
    onFinish: () => void;
    palletToLoadCount: number;
}

const PalletScanModal: FC<PalletScanModalProps> = ({ visible, onCancel, onFinish, palletToLoadCount }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const params = useParams<{ customerOrderId: string; loadingId: string }>();
    const customerOrderId = parseInt(params.customerOrderId, 10);
    const [form] = Form.useForm();
    const [isManual, setIsManual] = useState(false);
    const [tempReference, setTempReference] = useState<string>(); // temp data when waiting for API response
    const [tempBarcode, setTempBarcode] = useState<string>(); // temp data when waiting for API response
    const [checkPallet, sendPallets, setReceiveState, resetReceiveState] = useActions([
        checkPlatformLoadingsPallet.trigger,
        checkPlatformLoadingsPallets.trigger,
        reception.actions.setReceiveData,
        reception.actions.resetReceive,
    ]);
    const { palletRefs, palletBarcodes } = useSelector(getPlatformReceptionState).receive;

    const receivedCount = palletRefs.length + palletBarcodes.length;
    const checkPalletState = useSelector(getChekPlatformLoadingsPalletState);
    const sendPalletsState = useSelector(getChekPlatformLoadingsPalletsState);

    const remainingQuantityToReceive = palletToLoadCount - palletRefs.length - palletBarcodes.length;

    const previous = usePrevious({ visible, checkPalletState, sendPalletsState });
    const onClose = useCallback(() => {
        onCancel?.({} as React.MouseEvent<HTMLElement, MouseEvent>);
    }, [onCancel]);
    const onClickCancel = () => {
        onClose();
        setTempReference(undefined);
        setTempBarcode(undefined);
        form.resetFields();
        setIsManual(false);
        resetReceiveState();
    };
    const IsSinglePallet = useCallback(() => {
        return [...palletBarcodes, ...palletRefs].length <= 1;
    }, [palletBarcodes, palletRefs]);

    const onSubmitManual: FormProps['onFinish'] = (values) => {
        if (palletRefs.some((palletRef) => palletRef === values.reference)) {
            message.error('Vous avez déjà entré ce lot');
        } else {
            setTempReference(values.reference);
            checkPallet({
                customerOrderId,
                reference: values.reference,
            });
        }
    };
    const onSubmit = () => {
        sendPallets({
            customerOrderId,
            barcodes: palletBarcodes.length ? palletBarcodes : undefined,
            references: palletRefs.length ? palletRefs : undefined,
        });
    };
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (palletBarcodes.some((palletBarcode) => palletBarcode.barcode === barCode.data)) {
                    message.error('Vous avez déjà scanné ce lot');
                } else {
                    setTempBarcode(barCode.data);
                    checkPallet({
                        customerOrderId,
                        barcode: barCode.data,
                    });
                }
            } else {
                console.error(barCode);
            }
        },
        {
            deps: [checkPallet, palletBarcodes, customerOrderId, visible],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    useEffect(() => {
        if (visible && previous?.checkPalletState.loading && !checkPalletState.loading) {
            if (checkPalletState.error) {
                const requestError = getPlatformLoadingCheckPalletError(checkPalletState, IsSinglePallet());

                if (requestError.message) {
                    message.error(requestError.message);
                }
            } else if (checkPalletState.data) {
                if (remainingQuantityToReceive === 0) {
                    message.error('Le nombre maximum de lots à charger est déjà atteint', 5);
                } else {
                    setReceiveState({
                        palletBarcodes: tempBarcode ? [...palletBarcodes, tempBarcode] : palletBarcodes,
                        palletRefs: tempReference ? [...palletRefs, tempReference] : palletRefs,
                    });
                }

                form.resetFields();
                setIsManual(false);
            }

            setTempBarcode(undefined);
            setTempReference(undefined);
        }
    }, [
        previous?.checkPalletState.loading,
        checkPalletState,
        tempBarcode,
        tempReference,
        form,
        palletBarcodes,
        palletRefs,
        visible,
        remainingQuantityToReceive,
        setReceiveState,
        IsSinglePallet,
    ]);

    useEffect(() => {
        if (visible && previous?.sendPalletsState.loading && !sendPalletsState.loading) {
            if (sendPalletsState.error) {
                const requestError = getPlatformLoadingCheckPalletError(sendPalletsState, IsSinglePallet());

                if (requestError.message) {
                    message.error(requestError.message);
                }
            } else {
                resetReceiveState();
                onFinish();
                onClose();
            }
        }
    }, [
        onFinish,
        visible,
        resetReceiveState,
        onClose,
        previous?.sendPalletsState.loading,
        sendPalletsState,
        palletBarcodes,
        palletRefs,
        IsSinglePallet,
    ]);

    return (
        <CustomModal
            footer={
                isManual ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={() => form.submit()}
                                loading={checkPalletState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={setIsManual.bind(null, false)}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={onSubmit}
                                loading={sendPalletsState.loading}
                                disabled={receivedCount === 0}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="f1"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={setIsManual.bind(null, true)}
                                ghost
                            >
                                Saisir manuellement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClickCancel}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={isManual ? 'Saisir manuellement' : 'Veuillez scanner les lots que vous souhaitez charger :'}
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            maskClosable={false}
            altTitle={isManual}
        >
            {isManual ? (
                <Form form={form} onFinish={onSubmitManual}>
                    <Form.Item name="reference" rules={[{ required: true, message: 'Veuillez saisir une référence' }]}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir référence lot" autoFocus />
                        </ArrowNavItem>
                    </Form.Item>
                </Form>
            ) : (
                <QuantityCard
                    value={`${formatNumber(receivedCount)}/${formatNumber(palletToLoadCount)}`}
                    label={`lots contrôlés`}
                    outline
                />
            )}
        </CustomModal>
    );
};

export default PalletScanModal;
