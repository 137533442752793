import { LoadingOrder, Order } from './apiTypes';
import api from './_client';

export interface OrderListPayload {
    loadingOrderId: LoadingOrder['id'];
}
export const list = async (payload: OrderListPayload) => await api.get('/orders', { params: payload });

export interface OrderDetailsPayload {
    orderId: Order['id'];
}
export const details = async ({ orderId, ...payload }: OrderDetailsPayload) =>
    await api.get(`/orders/${orderId}`, { params: payload });

export type OrderUpdatePayload = Order;
export const update = async (payload: OrderUpdatePayload) => await api.put(`/orders/${payload.id}`, payload);

export interface OrderOperatorListPayload {
    orderId: Order['id'];
}
export const operatorList = async ({ orderId, ...payload }: OrderOperatorListPayload) =>
    await api.get(`/orders/${orderId}/operatorList`, { params: payload });

export interface OrderDeletePayload {
    orderId: Order['id'];
}
export const del = async ({ orderId }: OrderDeletePayload) => await api.delete(`/orders/${orderId}`);
