import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Place, PlaceZoneType, TransferType } from '../../../store/api/apiTypes';

import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import Seo from '../../../components/Seo';
import { useActions, usePrevious } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import FixedFooter from '../../../components/FixedFooter';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';

import { getPlacesListState, list } from '../../../store/actions/places';
import { getTransferOrdersDetails } from '../../../store/actions/transferOrders';
import { TransferOrderModalsType } from './TransferOrderCreateModals';
import useQueryParams from '../../../hooks/queryParams';
import { urlSearchParamsToObject } from '../../../helpers';
import { translatePlaceZoneType } from '../../../helpers/i18n';
import { Button } from 'antd';
import { isZebra } from '../../../helpers/enterprise-browser';

const PalletPlaces: FC = () => {
    const history = useHistory();
    const previousModal: TransferOrderModalsType = 'finishZoneType';
    const location = useLocation<{ zoneType: PlaceZoneType; transferType: TransferType }>();
    const fetchPlaces = useActions(list.trigger);
    const placesListState = useSelector(getPlacesListState);
    const transferOrderstate = useSelector(getTransferOrdersDetails);

    const [selectedPlace, setSelectedPlace] = useState<Place>();

    const columns = (
        [
            {
                key: 'sector',
                title: 'Secteur',
                flex: '1 1 25%',
                render: (record) => record.sector,
            },
            {
                key: 'locker',
                title: 'Casier',
                flex: '1 1 25%',
                render: (record) => record.locker,
            },
            {
                key: 'spot',
                title: 'Emplacement',
                flex: '1 1 25%',
                render: (record) => record.spot,
            },
        ] as Array<ListColumn<Place>>
    ).filter(Boolean);

    const [queryParams] = useQueryParams('PalletPlaceManagement');

    const zoneType = transferOrderstate.data?.finishPlaceZoneType ?? location.state.zoneType;
    const transferType = transferOrderstate.data?.transferType ?? location.state?.transferType;
    const previous = usePrevious({ placesListState });

    // return to home page when there is no OT in store (reload page, direct access to page by url...)
    useEffect(() => {
        if (!transferOrderstate.data && !transferOrderstate.loading) {
            history.push(getRoute(RoutePathName.movementsTransferOrderList));
        }
    }, [history, transferOrderstate.data, transferOrderstate.loading]);

    useEffect(() => {
        fetchPlaces({
            ...urlSearchParamsToObject(queryParams),
            zoneType,
            palletId: transferOrderstate.data?.startZone?.pallet?.id,
            ...(zoneType === PlaceZoneType.picking
                ? {}
                : { storageByAccumalation: zoneType === PlaceZoneType.accumulation }),
            pageSize: 10,
        });
    }, [fetchPlaces, location.state, queryParams, transferOrderstate.data?.startZone?.pallet?.id, zoneType]);

    useEffect(() => {
        if (
            zoneType === PlaceZoneType.picking &&
            previous?.placesListState.loading &&
            !placesListState.loading &&
            placesListState.data?.totalCount === 0
        ) {
            history.push(getRoute(RoutePathName.movementsTransferOrderList), {
                activeModal: 'noData',
                zoneType,
                transferType,
            });
        }
    }, [
        history,
        placesListState.data,
        placesListState.data?.totalCount,
        placesListState.loading,
        previous?.placesListState.loading,
        queryParams,
        transferType,
        zoneType,
    ]);

    const handlePlaceSelect = (place?: Place) => {
        setSelectedPlace(place);
    };

    const handleTransferOrderPlace = () => {
        const place: Partial<Place> = {
            id: selectedPlace?.id,
            index: selectedPlace?.index,
        };
        const activeModal: TransferOrderModalsType = 'storage';
        history.push(getRoute(RoutePathName.movementsTransferOrderList), {
            place,
            activeModal,
            zoneType,
            transferType,
        });
    };

    const handleFilterClick = () => {
        history.push(getRoute(RoutePathName.movementsTransferOrdersPlacesFilter), {
            ...location.state,
            backRoute: RoutePathName.movementTransferOrderPalletPlaces,
            zoneType: zoneType,
            transferType,
        });
    };

    const onLoadMore = () => {
        fetchPlaces(
            {
                ...urlSearchParamsToObject(queryParams),
                zoneType,
                palletId: transferOrderstate.data?.startZone?.pallet?.id,
                ...(zoneType === PlaceZoneType.picking
                    ? {}
                    : { storageByAccumalation: zoneType === PlaceZoneType.accumulation }),
                page: (placesListState.data?.page ?? 0) + 1,
                pageSize: 10,
            },
            { loadMore: true }
        );
    };

    return isZebra ? (
        <FixedFooter.Wrapper>
            <Seo title={`arrivée - ${translatePlaceZoneType(zoneType)}`} />
            <Header
                title={`arrivée - ${translatePlaceZoneType(zoneType)}`}
                backRoute={{
                    pathname: getRoute(RoutePathName.movementsTransferOrderList),
                    state: { ...location.state, activeModal: previousModal },
                }}
            />
            <List<Place>
                columns={columns}
                data={placesListState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowEnterPress={handleTransferOrderPlace}
                onRowClick={handlePlaceSelect}
                onRowFocus={handlePlaceSelect}
                onRowBlur={handlePlaceSelect}
                isLoading={placesListState.loading && placesListState.data?.page === undefined}
                title="Emplacements disponibles"
                style={{ marginTop: 24 }}
                columnWidthGrow={true}
            />
            {placesListState.data && placesListState.data.page < placesListState.data.pageCount - 1 && (
                <Button
                    onClick={onLoadMore}
                    loading={placesListState.loading}
                    size="small"
                    style={{ marginBottom: 60, marginTop: 16, fontSize: 14 }}
                    block
                >
                    Plus de résultats
                </Button>
            )}
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        disabled={!selectedPlace?.id}
                        onClick={handleTransferOrderPlace}
                        shortcut="enter"
                        block
                        className="btn-grey"
                    >
                        Ranger la palette
                    </ButtonWithShortcut>
                </ArrowNavItem>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        onClick={handleFilterClick}
                        shortcut="f1"
                        style={{ marginBottom: 10, fontSize: 14 }}
                        type="ghost"
                        block
                    >
                        Filtrer
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </FixedFooter.Wrapper>
    ) : null;
};

export default PalletPlaces;
