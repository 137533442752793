import React, { FC, useEffect } from 'react';
import List, { ListColumn } from '../../components/list/List';
import { Pallet } from '../../store/api/apiTypes';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import { Descriptions, message, Spin } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import { useActions, usePrevious } from '../../hooks';
import { details as receptionDetails, getReceptionDetailsStateById } from '../../store/actions/receptions';
import { useSelector } from 'react-redux';
import { formatNumber, translateReceptionStatus } from '../../helpers/i18n';

const ReceptionPallets: FC = () => {
    const { receptionId, parcelId } =
        useParams<{
            receptionId: string;
            parcelId: string;
        }>();

    const fetchReception = useActions(receptionDetails.trigger);
    const receptionState = useSelector(getReceptionDetailsStateById(parseInt(receptionId, 10)));
    const previous = usePrevious({ receptionState });
    const history = useHistory();

    useEffect(() => {
        if (previous?.receptionState.loading && !receptionState.loading) {
            if (receptionState.error) {
                message.error('Erreur lors de récupération de la réception');
            } else if (!receptionState.data?.pallets?.length) {
                history.push(getRoute(RoutePathName.receptionParcel, { receptionId, parcelId }));
            }
        }
    }, [
        history,
        parcelId,
        previous?.receptionState.loading,
        receptionId,
        receptionState.data?.pallets?.length,
        receptionState.error,
        receptionState.loading,
    ]);

    const backRoute = getRoute(RoutePathName.receptionParcel, {
        receptionId,
        parcelId,
    });

    const onClickPallet = (record: Pallet) => {
        history.push(
            getRoute(RoutePathName.receptionPallet, {
                receptionId,
                parcelId,
                palletId: record.id,
            })
        );
    };

    const columns: Array<ListColumn<Pallet>> = [
        {
            dataIndex: 'reference',
            title: 'Référence palette',
            flex: '1 1 60%',
        },
        {
            dataIndex: 'quantity',
            title: 'Quantité colis',
            flex: '1 1 40%',
        },
    ];

    useEffect(() => {
        if (receptionId) {
            fetchReception({ receptionId });
        }
    }, [fetchReception, receptionId]);

    return (
        <>
            <Seo title="Détail des palettes" />
            <Header title="Détail des palettes" backRoute={backRoute} enableHomeButton />
            <PageHeader>
                <Spin spinning={receptionState.loading}>
                    <Descriptions column={{ xs: 3, md: 4 }} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="Référence">
                            {receptionState.data?.parcel?.reference}
                        </Descriptions.Item>
                        <Descriptions.Item label="Total palettes">
                            {formatNumber(receptionState.data?.pallets?.length)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Statut">
                            {translateReceptionStatus(receptionState.data?.status)}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>
            <List<Pallet>
                columns={columns}
                data={receptionState.data?.pallets}
                isLoading={receptionState.loading}
                rowKey={(record) => `${record.id}`}
                onRowEnterPress={onClickPallet}
                onRowClick={onClickPallet}
                title="Palettes à ranger"
                emptyDescription="Il n'y a aucune palette à ranger"
                emptyImage={false}
            />
        </>
    );
};

export default ReceptionPallets;
