import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import CustomModal from '../../components/CustomModal';
import React, { useEffect, useState } from 'react';
import { message, Typography } from 'antd';
import { IconWarning } from '../../components/icons';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import SuccessMessage from '../../components/SuccessMessage';
import { useHistory, useParams } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import {
    getControlOrderRemovePalletState,
    removePallet as removePalletAction,
} from '../../store/actions/preparationControl';
import { useActions, usePrevious } from '../../hooks';
import { useSelector } from 'react-redux';

type RemovePalletSteps = 'confirm' | 'success';

const shortcutScope = 'ConfirmRemovePalletModal';

const ConfirmRemovePalletModal = NiceModal.create(() => {
    const [step, setStep] = useState<RemovePalletSteps>('confirm');
    const {
        loadingOrderId,
        customerOrderId: customerOrderIdParam,
        palletId,
    } = useParams<{ loadingOrderId: string; customerOrderId: string; palletId: string }>();
    const customerOrderId = parseInt(customerOrderIdParam, 10);
    const modal = useModal();
    const history = useHistory();
    const removePallet = useActions(removePalletAction.trigger);
    const removePalletState = useSelector(getControlOrderRemovePalletState);
    const previous = usePrevious({ removePalletState });
    const { hide } = modal;
    const onConfirm = () => {
        removePallet({
            customerOrderId,
            palletId: parseInt(palletId, 10),
        });
    };

    useEffect(() => {
        if (previous?.removePalletState.loading && !removePalletState.loading) {
            if (removePalletState.error) {
                message.error('Erreur lors de la suppression de la palette');
            } else {
                setStep('success');
            }
        }
    }, [previous?.removePalletState.loading, removePalletState.error, removePalletState.loading]);

    useEffect(() => {
        let timeout: number;
        if (step === 'success') {
            timeout = window.setTimeout(() => {
                hide();
                history.push(
                    getRoute(RoutePathName.preparationControl, {
                        loadingOrderId,
                        customerOrderId: customerOrderIdParam,
                    })
                );
            }, 1000);
        }
        return () => window.clearTimeout(timeout);
    }, [customerOrderIdParam, hide, history, loadingOrderId, step]);

    return (
        <CustomModal
            {...antdModal(modal)}
            title={
                step === 'confirm' && (
                    <Typography.Text>
                        <Typography.Text type="warning">
                            <IconWarning style={{ fontSize: '2.25rem', marginBottom: '1rem' }} />
                        </Typography.Text>
                        <br />
                        Vous êtes sur le point de supprimer la palette. <br />
                        Souhaitez-vous confirmer ?
                    </Typography.Text>
                )
            }
            width={step === 'success' ? 236 : 368}
            footer={
                step === 'confirm' && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={onConfirm}
                            >
                                Confirmer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={hide}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
        >
            {step === 'success' && <SuccessMessage message={'Palette supprimée avec succès'} />}
        </CustomModal>
    );
});

export default ConfirmRemovePalletModal;
