import { Reception } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';
import { ListResponse } from '../api';
import { requestInitialState, requestReducer } from '../helpers';
import {
    CreateReceptionPayload,
    DeleteReceptionPayload,
    ReceptionListPayload,
    StoreReceptionPalletPayload,
    UpdateReceptionPayload,
    list as listApiCall,
    details as detailsApiCall,
    create as createApiCall,
    update as updateApiCall,
    storePallet as storeApiCall,
    del as delApiCall,
    ReceptionDetailPayload,
} from '../api/receptions';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';

export interface ReceptionsState {
    list: RequestState<ListResponse<Reception>>;
    create: RequestState<Reception>;
    details: RequestState<{
        [id: number]: Reception;
    }>;
    update: RequestState;
    del: RequestState;
    storePallet: RequestState;
}

const initialState: ReceptionsState = {
    list: { ...requestInitialState },
    create: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
    update: { ...requestInitialState },
    del: { ...requestInitialState },
    storePallet: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<ReceptionsState['list'], ReceptionListPayload, ListResponse<Reception>>(
    'receptions/list',
    initialState.list,
    requestReducer<ReceptionsState['list'], ListResponse<Reception>>(initialState.list)
);

export const create = new EzeeAsyncAction<ReceptionsState['create'], CreateReceptionPayload, Reception>(
    'receptions/create',
    initialState.create,
    requestReducer<ReceptionsState['create'], Reception>(initialState.create)
);

export const details = new EzeeAsyncAction<ReceptionsState['details'], ReceptionDetailPayload, Reception>(
    'reception/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

export const update = new EzeeAsyncAction<ReceptionsState['create'], UpdateReceptionPayload, Reception>(
    'receptions/update',
    initialState.update,
    requestReducer<ReceptionsState['update'], Reception>(initialState.update)
);

export const del = new EzeeAsyncAction<ReceptionsState['del'], DeleteReceptionPayload>(
    'receptions/del',
    initialState.del,
    requestReducer<ReceptionsState['del']>(initialState.update)
);

export const storePallet = new EzeeAsyncAction<ReceptionsState['storePallet'], StoreReceptionPalletPayload>(
    'receptions/storePallet',
    initialState.storePallet,
    requestReducer<ReceptionsState['storePallet']>(initialState.storePallet)
);

// Reducer
export const receptionsReducer = combineReducers<ReceptionsState>({
    list: list.reducer,
    create: create.reducer,
    details: details.reducer,
    update: update.reducer,
    del: del.reducer,
    storePallet: storePallet.reducer,
});

// Saga
export function* receptionsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(storePallet.type.trigger, simpleAsyncSaga(storeApiCall, storePallet));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
}

// Store helpers
export const getReceptionsListState = (state: MainReducerState) => state.receptions.list;

export const getReceptionDetailsStateById = (id: Reception['id']) => (state: MainReducerState) => ({
    loading: state.receptions.details.loading,
    error: state.receptions.details.error,
    data: state.receptions.details.data?.[id],
});
export const getReceptionCreateState = (state: MainReducerState) => state.receptions.create;
export const getReceptionUpdateState = (state: MainReducerState) => state.receptions.update;
export const getReceptionStorePalletState = (state: MainReducerState) => state.receptions.storePallet;
export const getReceptionDelState = (state: MainReducerState) => state.receptions.del;
