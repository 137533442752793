import { Card, Descriptions, Divider, message, Spin, Typography } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    details as preparationDetails,
    getPreparationStateById,
    detailsPolling as preparationDetailsPolling,
} from '../../store/actions/preparations';
import {
    Permission,
    PermissionRight,
    PickingStatus,
    PreparationStatus,
    ResupplyOrderStatus,
} from '../../store/api/apiTypes';
import { getUser } from '../../store/actions/auth';

import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Seo from '../../components/Seo';
import { useActions, useIsDesktop, usePrevious } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import {
    translatePreparationStatus,
    translatePreparationPickingStatus,
    translateResupplyOrderStatus,
    formatNumber,
} from '../../helpers/i18n';
import QuantityCard from '../../components/QuantityCard';
import TitleBlack from '../../components/TitleBlack';
import VerticalDescriptions from '../../components/VerticalDescriptions';
import { IconWarning } from '../../components/icons';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ButtonGrey from '../../components/ButtonGrey';
import DirectResupplyModal from './DirectResupplyModal';
import { isZebra } from '../../helpers/enterprise-browser';
import FixedFooter from '../../components/FixedFooter';
import ArrowNavItem from '../../components/ArrowNavItem';
import PreparationUnblockModal from './PreparationUnblockModal';
import { getOperatorName } from '../../helpers';
import { hasPermission } from '../../helpers/security';

const PreparationDetails: FC = () => {
    const history = useHistory();
    const isDesktop = useIsDesktop();
    const { loadingOrderId, customerOrderId, preparationId } =
        useParams<{ loadingOrderId: string; customerOrderId: string; preparationId: string }>();
    const [isUnblockModalVisible, setIsUnblockModalVisible] = useState(false);
    const [isDirectModalVisible, setIsDirectModalVisible] = useState(false);
    const [fetchPreparation, stopPreparationPolling] = useActions([
        preparationDetails.trigger,
        preparationDetailsPolling.actions.stopPolling,
    ]);
    const preparationDetailsState = useSelector(getPreparationStateById(parseInt(preparationId, 10)));
    const user = useSelector(getUser);
    const previous = usePrevious({ preparationDetailsState });
    const canReadDesktop = !isZebra && hasPermission(user, Permission.preparation);
    const canEditDesktop = !isZebra && hasPermission(user, Permission.preparation, PermissionRight.write);
    const onClickPicking = () => {
        history.push(getRoute(RoutePathName.preparationPicking, { loadingOrderId, customerOrderId, preparationId }));
    };
    const onUnblockSuccess = () => {
        fetchPreparation({ preparationId });
    };
    const onCloseDirectResupplyModal = useCallback(() => {
        setIsDirectModalVisible(false);
    }, [setIsDirectModalVisible]);
    const receivedQuantity = preparationDetailsState.data?.preparedQuantity
        ? preparationDetailsState.data?.preparedQuantity
        : (preparationDetailsState.data?.resupplyDirectPrep?.quantity ?? 0) +
              (preparationDetailsState.data?.pickingPreparedQty ?? 0) ?? 0;

    useEffect(() => {
        if (preparationId) {
            fetchPreparation({ preparationId }, { poll: true });
        }
    }, [fetchPreparation, preparationId]);

    useEffect(
        () => () => {
            stopPreparationPolling();
        },
        [stopPreparationPolling]
    );

    useEffect(() => {
        if (previous?.preparationDetailsState.loading && !preparationDetailsState.loading) {
            if (preparationDetailsState.error) {
                message.error("Une erreur est survenue pendant la récupération de l'ordre de chargement");
            } else if (preparationDetailsState.data?.direct && !preparationDetailsState.data?.resupplyDirectPrep) {
                setIsDirectModalVisible(true);
            }
        }
    }, [
        previous?.preparationDetailsState.loading,
        preparationDetailsState.loading,
        preparationDetailsState.error,
        preparationDetailsState.data,
    ]);

    // check if user has been unassigned of this preparation, if true redirect to customer order details
    useEffect(() => {
        if (
            isZebra &&
            user &&
            previous?.preparationDetailsState.data?.operator &&
            previous?.preparationDetailsState.data.operator.id === user.id &&
            preparationDetailsState.data?.operator?.id !== user.id
        ) {
            message.warn('Vous avez été désassigné de cette préparation', 5);
            history.push(getRoute(RoutePathName.customerOrderDetails, { loadingOrderId, customerOrderId }));
        }
    }, [
        previous?.preparationDetailsState,
        preparationDetailsState.data,
        user,
        history,
        loadingOrderId,
        customerOrderId,
    ]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Détail préparation" />
            <Header
                title="Détail préparation"
                backRoute={getRoute(RoutePathName.customerOrderDetails, { loadingOrderId, customerOrderId })}
                enableHomeButton
            />
            <PageHeader>
                <Spin spinning={preparationDetailsState.loading}>
                    <Descriptions column={canReadDesktop ? 5 : 4} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="Référence">
                            {preparationDetailsState.data?.parcel?.reference ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Désignation" span={canReadDesktop ? 4 : 3}>
                            {preparationDetailsState.data?.parcel?.label ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Dimensions (L * l * Ep)" span={2}>
                            {preparationDetailsState.data?.parcel?.size?.width ?? '—'}
                            {' * '}
                            {preparationDetailsState.data?.parcel?.size?.height ?? '—'}
                            {' * '}
                            {preparationDetailsState.data?.parcel?.size?.depth ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Statut">
                            {translatePreparationStatus(preparationDetailsState.data?.status)}
                        </Descriptions.Item>
                        {canReadDesktop && (
                            <Descriptions.Item label="Cariste">
                                {getOperatorName(preparationDetailsState.data?.operator)}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </Spin>
            </PageHeader>
            <QuantityCard
                value={
                    receivedQuantity > 0
                        ? `${formatNumber(receivedQuantity)}/${formatNumber(preparationDetailsState.data?.quantity)}`
                        : formatNumber(preparationDetailsState.data?.quantity)
                }
                isComplete={preparationDetailsState.data?.status === PreparationStatus.completed}
                label={receivedQuantity > 0 ? `Colis préparé${receivedQuantity !== 1 ? 's' : ''}` : 'Colis à préparer'}
            />
            {preparationDetailsState.data?.picking && (
                <Card className="medium-card" style={{ marginTop: '1.5rem' }}>
                    <div className="flex flex-between">
                        <TitleBlack style={{ textAlign: 'left', margin: 0 }}>Picking</TitleBlack>
                        <Typography.Text
                            type={
                                preparationDetailsState.data?.pickingPrepStatus === PickingStatus.waiting
                                    ? 'warning'
                                    : 'secondary'
                            }
                        >
                            {preparationDetailsState.data?.pickingPrepStatus === PickingStatus.waiting && (
                                <IconWarning style={{ fontSize: '1.5rem' }} />
                            )}{' '}
                            {translatePreparationPickingStatus(preparationDetailsState.data?.pickingPrepStatus)}
                        </Typography.Text>
                    </div>
                    <Divider style={{ margin: '1rem 0' }} />
                    <VerticalDescriptions
                        items={[
                            {
                                label: 'Colis prélevés',
                                value: `${formatNumber(
                                    preparationDetailsState.data?.pickingPreparedQty
                                )}/${formatNumber(preparationDetailsState.data?.pickingQtyToPrep)}`,
                            },
                        ]}
                        inCard
                        bigValue
                    />
                    {preparationDetailsState.data?.pickingPrepStatus === PickingStatus.finished && (
                        <ButtonWithShortcut
                            shortcut="f1"
                            onClick={onClickPicking}
                            style={{ marginTop: '1rem' }}
                            size="small"
                            type="primary"
                            ghost
                            block
                        >
                            Historique du picking
                        </ButtonWithShortcut>
                    )}
                    {preparationDetailsState.data?.pickingPrepStatus !== PickingStatus.finished && (
                        <ButtonWithShortcut
                            shortcut="enter"
                            onClick={onClickPicking}
                            as={ButtonGrey}
                            style={{ marginTop: '1rem' }}
                            block
                        >
                            Picking
                        </ButtonWithShortcut>
                    )}
                </Card>
            )}
            {preparationDetailsState.data?.direct && (
                <Card className="medium-card" style={{ marginTop: '1.5rem' }}>
                    <div className="flex flex-between">
                        <div>
                            <TitleBlack style={{ textAlign: 'left', margin: 0, display: 'inline-block' }}>
                                Direct
                            </TitleBlack>
                            <Typography.Text style={{ marginLeft: '1rem', fontSize: '0.875rem' }} type="secondary">
                                {preparationDetailsState.data?.resupplyDirectPrep?.place?.locker}
                            </Typography.Text>
                        </div>
                        <Typography.Text
                            type={
                                preparationDetailsState.data?.resupplyDirectPrep?.status ===
                                ResupplyOrderStatus.toBeProcessed
                                    ? 'warning'
                                    : 'secondary'
                            }
                        >
                            {preparationDetailsState.data?.resupplyDirectPrep?.status ===
                            ResupplyOrderStatus.toBeProcessed ? (
                                <>
                                    <IconWarning style={{ fontSize: '1.5rem' }} />
                                    {' En attente'}
                                </>
                            ) : (
                                translateResupplyOrderStatus(preparationDetailsState.data?.resupplyDirectPrep?.status)
                            )}
                        </Typography.Text>
                    </div>
                    <Divider style={{ margin: '1rem 0' }} />
                    <VerticalDescriptions
                        items={[
                            {
                                label: 'Colis livrés',
                                value: `${formatNumber(
                                    preparationDetailsState.data?.resupplyDirectPrep?.quantity
                                )}/${formatNumber(
                                    preparationDetailsState.data?.resupplyDirectPrep?.quantityToBeTransferred
                                )}`,
                            },
                        ]}
                        inCard
                        bigValue
                    />
                </Card>
            )}
            <DirectResupplyModal
                visible={isDirectModalVisible}
                onCancel={onCloseDirectResupplyModal}
                preparation={preparationDetailsState.data}
            />
            <PreparationUnblockModal
                visible={isUnblockModalVisible}
                onCancel={setIsUnblockModalVisible.bind(null, false)}
                preparationId={preparationDetailsState.data?.id}
                onSuccess={onUnblockSuccess}
            />
            {canEditDesktop && preparationDetailsState.data?.operator && (
                <FixedFooter>
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            shortcut="f1"
                            onClick={setIsUnblockModalVisible.bind(null, true)}
                            size={!isDesktop ? 'middle' : 'large'}
                            block
                        >
                            Débloquer
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </FixedFooter>
            )}
        </FixedFooter.Wrapper>
    );
};

export default PreparationDetails;
