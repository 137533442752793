import React, { FC } from 'react';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';

import { ResupplyOrderType, Place } from '../../store/api/apiTypes';
import { create as resupplyOrderCreate, getResupplyOrderCreateState } from '../../store/actions/resupplyOrders';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useShortcutScope } from '../../hooks';
import { IconWarning } from '../../components/icons';

const shortcutScope = 'PickingResupplyModal';

interface PickingResupplyModalProps extends CustomModalProps {
    placeId?: Place['id'];
}

const PickingResupplyModal: FC<PickingResupplyModalProps> = ({ visible, onCancel, placeId }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [createResupplyOrder] = useActions([resupplyOrderCreate.trigger]);
    const resupplyOrderCreateState = useSelector(getResupplyOrderCreateState);
    const onSubmitResupplyOrderCreate = () => {
        createResupplyOrder({
            placeId,
            type: ResupplyOrderType.resupply,
            quantityToBeTransferred: 100,
        });
    };

    return (
        <CustomModal
            visible={visible}
            onCancel={onCancel}
            title={
                <Typography.Text>
                    <Typography.Text type="warning">
                        <IconWarning style={{ fontSize: '2.25rem', marginBottom: '1rem' }} />
                    </Typography.Text>
                    <br />
                    {'Cet emplacement est vide. Souhaitez-vous créer un ordre de réapprovisionnement ?'}
                </Typography.Text>
            }
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onSubmitResupplyOrderCreate}
                            loading={resupplyOrderCreateState.loading}
                        >
                            Créer l&rsquo;ordre de réappro
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            type="primary"
                            shortcutScope={shortcutScope}
                            onClick={onCancel}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            width={368}
        />
    );
};

export default PickingResupplyModal;
