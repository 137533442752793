import { Descriptions, message, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonGrey from '../../../components/ButtonGrey';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import ConfirmModal from '../../../components/ConfirmModal';
import FixedFooter from '../../../components/FixedFooter';
import Header from '../../../components/Header';
import PageHeader from '../../../components/PageHeader';
import QuantityCard from '../../../components/QuantityCard';
import Seo from '../../../components/Seo';
import VerticalDescriptions from '../../../components/VerticalDescriptions';
import { getOperatorName } from '../../../helpers';

import { isZebra } from '../../../helpers/enterprise-browser';
import { formatDate, formatNumber, translateRepairStatus } from '../../../helpers/i18n';
import { useActions, usePrevious } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import { detailsRepair, getRepairsDetails, getRepairsUpdateState, updateRepair } from '../../../store/actions/repairs';
import { PlaceZoneType, RepairStatus } from '../../../store/api/apiTypes';
import SelectZoneTypeModal from './SelectZoneTypeModal';

const RepairDetails: FC = () => {
    const params = useParams<{ repairId: string }>();
    const history = useHistory();
    const [isZoneTypeModalVisible, setIsZoneTypeModalVisible] = useState<boolean>(false);
    const [isUnlockModalVisible, setIsUnlockModalVisible] = useState<boolean>(false);

    const selectZoneType = (zoneType: PlaceZoneType) => {
        history.push(getRoute(RoutePathName.movementRepairPalletPlaces, { repairId: params.repairId }), { zoneType });
    };

    const [fetchRepair, update] = useActions([detailsRepair.trigger, updateRepair.trigger]);
    const repairState = useSelector(getRepairsDetails);
    const updateState = useSelector(getRepairsUpdateState);

    const previous = usePrevious({ updateState });

    useEffect(() => {
        fetchRepair({ id: params.repairId });
    }, [fetchRepair, params.repairId]);

    const isCompleted = repairState.data?.status === RepairStatus.completed;

    const unlockRepair = () => {
        update({ id: params.repairId, operatorId: null });
    };

    useEffect(() => {
        if (previous?.updateState.loading && !updateState.loading) {
            if (updateState.error) {
                message.error('Une erreur est servenue lors de débloquage de réparation');
            } else {
                message.success('Réparation débloquée avec succès');
                setIsUnlockModalVisible(false);
                fetchRepair({ id: params.repairId });
            }
        }
    }, [updateState.error, updateState.loading, params.repairId, previous?.updateState.loading, fetchRepair]);
    return (
        <FixedFooter.Wrapper>
            <Seo title="Détail réparation" />
            <Header title="Détail réparation" backRoute={getRoute(RoutePathName.movementsRepairs)} enableHomeButton />
            <PageHeader loading={repairState.loading}>
                <Descriptions size="small" column={isZebra ? 3 : 5} layout="vertical">
                    <Descriptions.Item label="Date">{formatDate(repairState.data?.createdAt)}</Descriptions.Item>

                    <Descriptions.Item label="Heure">
                        {formatDate(repairState.data?.createdAt, { minute: '2-digit', hour: '2-digit' })}
                    </Descriptions.Item>
                    <Descriptions.Item label="Statut">
                        {translateRepairStatus(repairState.data?.status)}
                    </Descriptions.Item>
                    {!isZebra && (
                        <Descriptions.Item label="Cariste">
                            {getOperatorName(repairState.data?.operator)}
                        </Descriptions.Item>
                    )}
                </Descriptions>
            </PageHeader>
            <Space direction="vertical" size="large">
                <VerticalDescriptions
                    items={[{ label: 'Référence colis', value: repairState.data?.parcel?.reference ?? '—' }]}
                />

                {isZebra ? (
                    <QuantityCard
                        loading={repairState.loading}
                        isComplete={isCompleted}
                        value={
                            repairState.data?.transferedPackages
                                ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                  `${repairState.data.transferedPackages ?? ''}/${formatNumber(
                                      repairState.data?.quantity
                                  )}`
                                : formatNumber(repairState.data?.quantity)
                        }
                        label="Colis à ranger"
                    />
                ) : (
                    <QuantityCard
                        loading={repairState.loading}
                        isComplete={isCompleted}
                        value={(repairState.data?.quantity ?? 0) - (repairState.data?.transferedPackages ?? 0)}
                        label="Colis à ranger"
                    />
                )}
            </Space>
            <SelectZoneTypeModal
                visible={isZoneTypeModalVisible}
                onSelect={selectZoneType}
                onCancel={setIsZoneTypeModalVisible.bind(null, false)}
            />
            <ConfirmModal
                visible={isUnlockModalVisible}
                message="Êtes-vous sûr de vouloir débloquer cette Réparation maintenant ?"
                okText="Oui"
                cancelText="Non"
                onCancel={setIsUnlockModalVisible.bind(null, false)}
                onOk={unlockRepair}
            />
            {!isCompleted && (isZebra || (!isZebra && repairState.data?.operator)) && (
                <FixedFooter>
                    <ArrowNavItem>
                        {isZebra ? (
                            <ButtonWithShortcut
                                as={ButtonGrey}
                                onClick={setIsZoneTypeModalVisible.bind(null, true)}
                                shortcut="enter"
                                type="primary"
                                block
                            >
                                Rangement
                            </ButtonWithShortcut>
                        ) : (
                            <ButtonWithShortcut
                                onClick={setIsUnlockModalVisible.bind(null, true)}
                                shortcut="enter"
                                type="primary"
                                block
                                loading={repairState.loading}
                                ghost
                            >
                                Débloquer
                            </ButtonWithShortcut>
                        )}
                    </ArrowNavItem>
                </FixedFooter>
            )}
        </FixedFooter.Wrapper>
    );
};

export default RepairDetails;
