import React, { FC, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Divider, Form, Input, InputNumber, message, Radio, Select, Typography } from 'antd';
import { FormInstance, FormProps, useForm } from 'antd/lib/form/Form';

import { Place, PlaceFilter, PlaceStore, PlaceStoreType, SearchType } from '../../store/api/apiTypes';
import { getPlacesListFiltersState, listFilters, PlacesState } from '../../store/actions/places';
import { detailsByScan as parcelByScan, getParcelScanState } from '../../store/actions/parcels';
import {
    detailsByReference as palletByRef,
    detailsByBarcode as palletByBarcode,
    getPalletDetailsStateByReference,
    getPalletDetailsStateByBarcode,
} from '../../store/actions/pallets';

import { getRoute, RoutePathName } from '../../routes';
import { useActions, useIsMobile, usePrevious, useScanner } from '../../hooks';
import { stripUndefinedKeysFromObject, urlSearchParamsToObject } from '../../helpers';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Seo from '../../components/Seo';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import FixedFooter from '../../components/FixedFooter';
import ButtonGrey from '../../components/ButtonGrey';
import PlaceStoreSelect from '../../components/PlaceStoreSelect';
import useQueryParams from '../../hooks/queryParams';
import { isZebra } from '../../helpers/enterprise-browser';

type PlaceFilters = Pick<Place, 'store' | 'sector' | 'lane' | 'alveole' | 'level'>;
const placeFilterNames: Array<keyof PlaceFilters> = ['store', 'sector', 'lane', 'alveole', 'level'];
const placeFilterTranslations = ['Secteur', 'Allée', 'Alvéole', 'Niveau'];

const getTitle = (type: SearchType) => {
    switch (type) {
        case SearchType.parcels:
            return 'Recherche - Référence';
        case SearchType.places:
            return 'Recherche - Emplacement';
        case SearchType.pallets:
            return 'Recherche - Palette';
        case SearchType.quantity:
            return 'Recherche Stock';
    }
};

const getFields = (type: SearchType, placesFiltersState: PlacesState['listFilters'], form: FormInstance) => {
    switch (type) {
        case SearchType.parcels:
            return (
                <Form.Item
                    label="Référence"
                    name="parcelReference"
                    rules={[{ required: true, message: 'Veuillez saisir une référence' }]}
                >
                    <ArrowNavItem>
                        <Input placeholder={`Saisir ${isZebra ? 'ou scanner ' : ''}une référence`} autoFocus />
                    </ArrowNavItem>
                </Form.Item>
            );

        case SearchType.pallets:
            return (
                <Form.Item
                    label="Palette"
                    name="palletReference"
                    rules={[{ required: true, message: 'Veuillez saisir une référence palette' }]}
                >
                    <ArrowNavItem>
                        <Input placeholder={`Saisir ${isZebra ? 'ou scanner ' : ''}une référence palette`} autoFocus />
                    </ArrowNavItem>
                </Form.Item>
            );

        case SearchType.places: {
            const isDisabled = (filter: keyof PlaceFilters | (string | undefined)) => {
                switch (filter) {
                    case 'store':
                        return false;
                    case 'locker':
                        return !form.getFieldValue('store') || !!form.getFieldValue('sector');
                    case 'sector':
                        return !form.getFieldValue('store') || !!form.getFieldValue('locker');
                    case 'lane':
                        return !form.getFieldValue('sector') || !!form.getFieldValue('locker');
                    case 'alveole':
                        return !form.getFieldValue('lane') || !!form.getFieldValue('locker');
                    case 'level':
                        return !form.getFieldValue('alveole') || !!form.getFieldValue('locker');
                    default:
                        return false;
                }
            };

            return (
                <>
                    <Form.Item label="Casier" name="locker">
                        <ArrowNavItem>
                            <Input placeholder="Saisir un casier" />
                        </ArrowNavItem>
                    </Form.Item>
                    <Divider>
                        <Typography.Text className="text-primary" style={{ fontSize: '0.9375rem' }} strong>
                            OU
                        </Typography.Text>
                    </Divider>
                    <Form.Item shouldUpdate noStyle>
                        {() =>
                            placeFilterNames
                                .filter((filter) => filter !== 'store')
                                .map((filter, index) => (
                                    <Form.Item label={placeFilterTranslations[index]} name={filter} key={filter}>
                                        <ArrowNavItem>
                                            <Select
                                                value={
                                                    typeof form.getFieldValue(filter) === 'number'
                                                        ? `${(form.getFieldValue(filter) as string | undefined) ?? ''}`
                                                        : form.getFieldValue(filter)
                                                }
                                                placeholder={`Sélectionnez un${
                                                    filter === 'lane' || filter === 'alveole' ? 'e' : ''
                                                } ${placeFilterTranslations[index].toLowerCase()}`}
                                                style={{ width: '100%' }}
                                                disabled={isDisabled(filter)}
                                                showSearch
                                                allowClear
                                                showArrow
                                            >
                                                {(
                                                    placesFiltersState.data?.[
                                                        `${filter}s` as keyof PlaceFilter
                                                    ] as Array<string | number>
                                                )?.map((option, index) => (
                                                    <Select.Option value={option} key={`${option}-${index}`}>
                                                        {option}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </ArrowNavItem>
                                    </Form.Item>
                                ))
                        }
                    </Form.Item>
                </>
            );
        }

        case SearchType.quantity:
            return (
                <>
                    <Form.Item label="Symbole" name="operatorSymbole">
                        <ArrowNavItem>
                            <Radio.Group buttonStyle="solid" className="stretch">
                                <Radio.Button value="<">{'<'}</Radio.Button>
                                <Radio.Button value="=">=</Radio.Button>
                                <Radio.Button value=">">{'>'}</Radio.Button>
                            </Radio.Group>
                        </ArrowNavItem>
                    </Form.Item>
                    <Form.Item
                        label="Quantité"
                        name="quantity"
                        rules={[
                            { required: true, message: 'Veuillez saisir une quantité' },
                            { type: 'number', message: 'Veuillez entrer un nombre' },
                        ]}
                    >
                        <ArrowNavItem>
                            <InputNumber placeholder="Saisir une quantité" min={0} style={{ width: '100%' }} />
                        </ArrowNavItem>
                    </Form.Item>
                </>
            );

        default:
            return null;
    }
};

const formatStoreName = (store: PlaceStore) => `${store?.number ?? ''}`;

const Search: FC = () => {
    const [form] = useForm<PlaceFilters & { locker?: string | undefined }>();
    const history = useHistory();
    const isMobile = useIsMobile();
    const { type } = useParams<{ type: SearchType }>();
    const [urlSearchParams] = useQueryParams('searchType');
    const [barcodeKey, setBarcodeKey] = useState<string>();
    const [fetchFilters, fetchPalletByRef, fetchPalletByBarcode, fetchParcel] = useActions([
        listFilters.trigger,
        palletByRef.trigger,
        palletByBarcode.trigger,
        parcelByScan.trigger,
    ]);
    const parcelScanState = useSelector(getParcelScanState);
    const palletByRefState = useSelector(getPalletDetailsStateByReference(form.getFieldValue('palletReference')));
    const palletByBarcodeState = useSelector(getPalletDetailsStateByBarcode(barcodeKey));
    const placesFiltersState = useSelector(getPlacesListFiltersState);
    const previous = usePrevious({ palletByRefState, parcelScanState, palletByBarcodeState });
    const urlParamsObject = urlSearchParamsToObject(urlSearchParams);
    const initialValues = {
        operatorSymbole: '<',
        ...urlParamsObject,
        quantity: urlParamsObject.quantity !== undefined ? Number(urlParamsObject.quantity) : undefined,
        level: urlSearchParams.get('level') ?? undefined,
    };
    const onSubmit: FormProps['onFinish'] = (values) => {
        const strippedValues = { ...values };
        strippedValues.locker = values.locker || undefined;
        stripUndefinedKeysFromObject(strippedValues);
        const searchParams = new URLSearchParams(strippedValues);

        searchParams.delete('store');
        searchParams.set('placeStoreId', strippedValues.store?.id);
        searchParams.set('placeStoreLabel', formatStoreName(strippedValues.store));

        if (type === SearchType.pallets) {
            fetchPalletByRef({ reference: strippedValues.palletReference });
        } else if (type === SearchType.parcels) {
            fetchParcel({ reference: strippedValues.parcelReference });
        } else {
            if (strippedValues.locker) {
                searchParams.delete('sector');
                searchParams.delete('lane');
                searchParams.delete('alveole');
                searchParams.delete('level');
            }
            history.push({
                pathname: getRoute(RoutePathName.searchResults, { type }),
                search: searchParams.toString(),
            });
        }
    };
    const onFormChange: FormProps['onFieldsChange'] = (changedFields, allFields) => {
        if (
            changedFields.some((field) =>
                placeFilterNames.includes(
                    (Array.isArray(field.name) ? field.name[0] : field.name) as keyof PlaceFilters
                )
            )
        ) {
            fetchFilters(
                allFields
                    .filter((field) =>
                        placeFilterNames.includes(
                            (Array.isArray(field.name) ? field.name[0] : field.name) as keyof PlaceFilters
                        )
                    )
                    .reduce(
                        (acc, field) => {
                            let fieldName = Array.isArray(field.name) ? field.name[0] : field.name;
                            const fieldValue = fieldName === 'store' ? field.value?.id : field.value;

                            fieldName = fieldName === 'store' ? 'storeId' : fieldName;

                            return {
                                ...acc,
                                [fieldName]: fieldValue,
                            };
                        },
                        {
                            storeId: form.getFieldValue('store')?.id,
                        }
                    )
            );

            changedFields.forEach((field) => {
                if (field.value === undefined) {
                    switch (Array.isArray(field.name) ? field.name[0] : field.name) {
                        case 'store':
                            form.setFieldsValue({
                                sector: undefined,
                                lane: undefined,
                                alveole: undefined,
                                level: undefined,
                            });
                            break;

                        case 'locker':
                            form.setFieldsValue({
                                sector: undefined,
                                lane: undefined,
                                alveole: undefined,
                                level: undefined,
                            });
                            break;

                        case 'sector':
                            form.setFieldsValue({
                                lane: undefined,
                                alveole: undefined,
                                level: undefined,
                                locker: undefined,
                            });
                            break;

                        case 'lane':
                            form.setFieldsValue({ alveole: undefined, level: undefined });
                            break;

                        case 'alveole':
                            form.setFieldsValue({ level: undefined });
                            break;
                    }
                } else {
                    switch (Array.isArray(field.name) ? field.name[0] : field.name) {
                        case 'locker':
                            form.setFieldsValue({
                                sector: undefined,
                                lane: undefined,
                                alveole: undefined,
                                level: undefined,
                            });
                            break;
                    }
                }
            });
        }
    };
    useScanner(
        'SearchType',
        (barCode) => {
            if (barCode.data) {
                if (type === SearchType.pallets) {
                    fetchPalletByBarcode({ barcode: barCode.data });
                    setBarcodeKey(barCode.data);
                } else if (type === SearchType.parcels) {
                    fetchParcel({ barcode: barCode.data });
                }
            } else {
                message.error('Code-barre non reconnu');
            }
        },
        {
            deps: [type],
            disable: type !== SearchType.pallets && type !== SearchType.parcels,
        }
    );

    const formPlaceStoreId = form.getFieldValue('store')?.id;

    useEffect(() => {
        if (type === SearchType.places) {
            if (formPlaceStoreId) {
                fetchFilters({
                    storeId: formPlaceStoreId,
                    sector: urlSearchParams.get('sector') ?? undefined,
                    lane: urlSearchParams.get('lane') ?? undefined,
                    alveole: urlSearchParams.get('alveole') ?? undefined,
                    level: urlSearchParams.get('level') ?? undefined,
                });
            }
        }
    }, [type, fetchFilters, urlSearchParams, formPlaceStoreId]);

    useEffect(() => {
        if (previous?.palletByRefState.loading && !palletByRefState.loading) {
            if (palletByRefState.error) {
                if (palletByRefState.error.status === 404) {
                    message.error('Palette non trouvée');
                } else {
                    message.error("Une erreur est survenue lors de l'envoi de la recherche");
                }
            } else {
                history.push({
                    pathname: getRoute(RoutePathName.searchDetails, { type, id: palletByRefState.data?.id ?? '' }),
                    search: new URLSearchParams({
                        placeStoreId: form.getFieldValue('store')?.id,
                        placeStoreLabel: formatStoreName(form.getFieldValue('store')),
                    }).toString(),
                });
            }
        }
    }, [
        previous?.palletByRefState.loading,
        palletByRefState.loading,
        palletByRefState.error,
        palletByRefState.data?.id,
        history,
        type,
        form,
    ]);

    useEffect(() => {
        if (previous?.palletByBarcodeState.loading && !palletByBarcodeState.loading) {
            if (palletByBarcodeState.error) {
                if (palletByBarcodeState.error.status === 404) {
                    message.error('Palette non trouvée');
                } else {
                    message.error("Une erreur est survenue lors de l'envoi de la recherche");
                }
                setBarcodeKey(undefined);
            } else {
                history.push({
                    pathname: getRoute(RoutePathName.searchDetails, { type, id: palletByBarcodeState.data?.id ?? '' }),
                    search: new URLSearchParams({
                        placeStoreId: form.getFieldValue('store')?.id,
                        placeStoreLabel: formatStoreName(form.getFieldValue('store')),
                    }).toString(),
                });
            }
        }
    }, [
        previous?.palletByBarcodeState.loading,
        palletByBarcodeState.loading,
        palletByBarcodeState.error,
        palletByBarcodeState.data?.id,
        history,
        type,
        form,
    ]);

    useEffect(() => {
        if (previous?.parcelScanState.loading && !parcelScanState.loading) {
            if (parcelScanState.error) {
                if (parcelScanState.error.status === 404) {
                    message.error('Colis non trouvé');
                } else {
                    message.error("Une erreur est survenue lors de l'envoi de la recherche");
                }
            } else {
                history.push({
                    pathname: getRoute(RoutePathName.searchDetails, { type, id: parcelScanState.data?.id ?? '' }),
                    search: new URLSearchParams({
                        placeStoreId: form.getFieldValue('store')?.id,
                        placeStoreLabel: formatStoreName(form.getFieldValue('store')),
                    }).toString(),
                });
            }
        }
    }, [
        previous?.parcelScanState.loading,
        parcelScanState.loading,
        parcelScanState.error,
        parcelScanState.data?.id,
        history,
        type,
        form,
    ]);

    if (!Object.values(SearchType).includes(type as SearchType)) {
        return <Redirect to={getRoute(RoutePathName.search)} />;
    }

    return (
        <FixedFooter.Wrapper>
            <Seo title={getTitle(type as SearchType)} />
            <Header title={getTitle(type as SearchType)} backRoute={getRoute(RoutePathName.search)} enableHomeButton />
            <PageHeader className="page-header-small">
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onSubmit}
                    initialValues={initialValues}
                    onFieldsChange={onFormChange}
                >
                    <Form.Item name="store" label="Site">
                        <PlaceStoreSelect placeStoreType={PlaceStoreType.site} withArrowNav />
                    </Form.Item>
                    {getFields(type as SearchType, placesFiltersState, form)}
                    <button type="submit" style={{ display: 'none' }} />
                </Form>
            </PageHeader>
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        shortcut="enter"
                        onClick={() => form.submit()}
                        as={ButtonGrey}
                        size={isMobile ? 'middle' : 'large'}
                        loading={palletByRefState.loading}
                        block
                    >
                        Rechercher
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </FixedFooter.Wrapper>
    );
};

export default Search;
