import React, { FC, useCallback, useEffect, useState } from 'react';
import { Input, message, Form, FormProps, Button } from 'antd';
import { useSelector } from 'react-redux';

import {
    getPlatformAnomalyCodeCreateState,
    getPlatformAnomalyCodeUpdateState,
    getPlatformAnomalyCodeDeleteState,
    create as anomalyCodeCreate,
    update as anomalyCodeUpdate,
    del as anomalyCodeDelete,
} from '../../../store/actions/platformAnomalyCodes';
import {
    getPlatformReasonCodeCreateState,
    getPlatformReasonCodeUpdateState,
    getPlatformReasonCodeDeleteState,
    create as reasonCodeCreate,
    update as reasonCodeUpdate,
    del as reasonCodeDelete,
} from '../../../store/actions/platformReasonCodes';
import { PlatformAnomalyCode, PlatformReasonCode } from '../../../store/api/apiTypes';

import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../../hooks';
import SuccessMessage from '../../../components/SuccessMessage';
import ButtonRed from '../../../components/ButtonRed';

const shortcutScope = 'CodeFormModal';

interface CodeFormModalProps extends CustomModalProps {
    anomalyCode?: PlatformAnomalyCode;
    reasonCode?: PlatformReasonCode;
    onSuccess?: () => void;
    isAnomaly?: boolean;
    isReason?: boolean;
}

const CodeFormModal: FC<CodeFormModalProps> = ({
    visible,
    onCancel,
    anomalyCode,
    reasonCode,
    isAnomaly,
    isReason,
    onSuccess,
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const isEditing = !!anomalyCode || !!reasonCode;
    const [form] = Form.useForm();
    const [success, setSuccess] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [
        createAnomalyCode,
        updateAnomalyCode,
        deleteAnomalyCode,
        createReasonCode,
        updateReasonCode,
        deleteReasonCode,
    ] = useActions([
        anomalyCodeCreate.trigger,
        anomalyCodeUpdate.trigger,
        anomalyCodeDelete.trigger,
        reasonCodeCreate.trigger,
        reasonCodeUpdate.trigger,
        reasonCodeDelete.trigger,
    ]);
    const anomalyCodeCreateState = useSelector(getPlatformAnomalyCodeCreateState);
    const anomalyCodeUpdateState = useSelector(getPlatformAnomalyCodeUpdateState);
    const anomalyCodeDeleteState = useSelector(getPlatformAnomalyCodeDeleteState);
    const reasonCodeCreateState = useSelector(getPlatformReasonCodeCreateState);
    const reasonCodeUpdateState = useSelector(getPlatformReasonCodeUpdateState);
    const reasonCodeDeleteState = useSelector(getPlatformReasonCodeDeleteState);
    const previous = usePrevious({
        anomalyCodeCreateState,
        anomalyCodeUpdateState,
        anomalyCodeDeleteState,
        reasonCodeCreateState,
        reasonCodeUpdateState,
        reasonCodeDeleteState,
    });
    const onSubmit: FormProps['onFinish'] = (values) => {
        if (isEditing) {
            if (isAnomaly) {
                updateAnomalyCode({
                    platformAnomalyCodeId: anomalyCode?.id,
                    ...values,
                });
            } else if (isReason) {
                updateReasonCode({
                    platformReasonCodeId: reasonCode?.id,
                    ...values,
                });
            }
        } else {
            if (isAnomaly) {
                createAnomalyCode(values);
            } else if (isReason) {
                createReasonCode(values);
            }
        }
    };
    const onDelete = () => {
        if (isAnomaly) {
            deleteAnomalyCode({ platformAnomalyCodeId: anomalyCode?.id });
        } else if (isReason) {
            deleteReasonCode({ platformReasonCodeId: reasonCode?.id });
        }
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            setSuccess(false);
        }
    }, [visible, form, setSuccess]);

    useEffect(() => {
        if (previous?.anomalyCodeCreateState.loading && !anomalyCodeCreateState.loading) {
            if (anomalyCodeCreateState.error) {
                if (anomalyCodeCreateState.error?.status === 409) {
                    message.error('Ce nom est déjà utilisé par une autre code anomalie');
                } else {
                    message.error('Une erreur est survenue pendant la création du code anomalie');
                }
            } else {
                setSuccess(true);
            }
        }
    }, [anomalyCodeCreateState.error, anomalyCodeCreateState.loading, previous?.anomalyCodeCreateState.loading]);

    useEffect(() => {
        if (previous?.anomalyCodeUpdateState.loading && !anomalyCodeUpdateState.loading) {
            if (anomalyCodeUpdateState.error) {
                if (anomalyCodeUpdateState.error?.status === 409) {
                    message.error('Ce nom est déjà utilisé par une autre code anomalie');
                } else {
                    message.error('Une erreur est survenue pendant la mise à jour du code anomalie');
                }
            } else {
                setSuccess(true);
            }
        }
    }, [anomalyCodeUpdateState.error, anomalyCodeUpdateState.loading, previous?.anomalyCodeUpdateState.loading]);

    useEffect(() => {
        if (previous?.anomalyCodeDeleteState.loading && !anomalyCodeDeleteState.loading) {
            if (anomalyCodeDeleteState.error) {
                message.error('Une erreur est survenue pendant la suppression du code anomalie');
            } else {
                setSuccess(true);
            }
        }
    }, [anomalyCodeDeleteState.error, anomalyCodeDeleteState.loading, previous?.anomalyCodeDeleteState.loading]);

    useEffect(() => {
        if (previous?.reasonCodeCreateState.loading && !reasonCodeCreateState.loading) {
            if (reasonCodeCreateState.error) {
                if (reasonCodeCreateState.error?.status === 409) {
                    message.error('Ce nom est déjà utilisé par un autre code motif');
                } else {
                    message.error('Une erreur est survenue pendant la création du code motif');
                }
            } else {
                setSuccess(true);
            }
        }
    }, [reasonCodeCreateState.error, reasonCodeCreateState.loading, previous?.reasonCodeCreateState.loading]);

    useEffect(() => {
        if (previous?.reasonCodeUpdateState.loading && !reasonCodeUpdateState.loading) {
            if (reasonCodeUpdateState.error) {
                if (reasonCodeUpdateState.error?.status === 409) {
                    message.error('Ce nom est déjà utilisé par un autre code motif');
                } else {
                    message.error('Une erreur est survenue pendant la mise à jour du code motif');
                }
            } else {
                setSuccess(true);
            }
        }
    }, [reasonCodeUpdateState.error, reasonCodeUpdateState.loading, previous?.reasonCodeUpdateState.loading]);

    useEffect(() => {
        if (previous?.reasonCodeDeleteState.loading && !reasonCodeDeleteState.loading) {
            if (reasonCodeDeleteState.error) {
                message.error('Une erreur est survenue pendant la suppression du code motif');
            } else {
                setSuccess(true);
            }
        }
    }, [reasonCodeDeleteState.error, reasonCodeDeleteState.loading, previous?.reasonCodeDeleteState.loading]);

    useEffect(() => {
        let timeout: number;

        if (success) {
            onSuccess?.();

            timeout = window.setTimeout(() => {
                if (timeout) {
                    onClose();
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [onClose, success, onSuccess]);

    useEffect(() => {
        if (anomalyCode) {
            form.setFieldsValue(anomalyCode);
        } else {
            form.resetFields();
        }
    }, [anomalyCode, form]);

    useEffect(() => {
        if (reasonCode) {
            form.setFieldsValue(reasonCode);
        } else {
            form.resetFields();
        }
    }, [reasonCode, form]);

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            setIsDeleting(false);
        }
    }, [visible, form]);

    return (
        <CustomModal
            footer={
                success ? null : isDeleting ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                as={ButtonRed}
                                onClick={onDelete}
                                loading={anomalyCodeDeleteState.loading}
                            >
                                Oui, supprimer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                disabled={anomalyCodeDeleteState.loading}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={form.submit}
                                loading={anomalyCodeCreateState.loading || anomalyCodeUpdateState.loading}
                                disabled={anomalyCodeDeleteState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        {isEditing && (
                            <ArrowNavItem scope={shortcutScope}>
                                <Button onClick={setIsDeleting.bind(null, true)} danger>
                                    Supprimer
                                </Button>
                            </ArrowNavItem>
                        )}
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                disabled={anomalyCodeDeleteState.loading}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={
                !success &&
                (isDeleting
                    ? `Voulez-vous vraiment supprimer ce code ${isAnomaly ? 'anomalie' : 'motif'} ?`
                    : isEditing
                    ? 'Éditer'
                    : `Ajouter un code ${isAnomaly ? 'anomalie' : 'motif'}`)
            }
            width={368}
            keyboard={false}
            altTitle={!isDeleting}
        >
            {success ? (
                <SuccessMessage
                    message={`Le code ${isAnomaly ? 'anomalie' : 'motif'} a été ${
                        isDeleting ? 'supprimé' : isEditing ? 'édité' : 'ajouté'
                    } avec succès`}
                />
            ) : !isDeleting ? (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                    initialValues={isAnomaly ? anomalyCode : isReason ? reasonCode : undefined}
                    requiredMark={false}
                >
                    <Form.Item name="reference" label="Code">
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir un code" readOnly={isEditing} />
                        </ArrowNavItem>
                    </Form.Item>
                    <Form.Item name="label" label="Libellé">
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir un libellé" />
                        </ArrowNavItem>
                    </Form.Item>
                </Form>
            ) : null}
        </CustomModal>
    );
};

export default CodeFormModal;
