import { SearchPaginationQuery } from '.';
import { PlaceStore } from './apiTypes';
import api from './_client';

export type PlaceStoreListPayload = SearchPaginationQuery;
export const list = async (payload: PlaceStoreListPayload = {}) => await api.get('/placeStores', { params: payload });

export type PlaceStoreCreatePayload = Omit<PlaceStore, 'id'>;
export const create = async (payload: PlaceStoreCreatePayload) => await api.post('/placeStores', payload);

export type PlaceStoreUpdatePayload = PlaceStore & {
    placeStoreId: PlaceStore['id'];
};
export const update = async ({ placeStoreId, ...payload }: PlaceStoreUpdatePayload) =>
    await api.put(`/placeStores/${placeStoreId}`, payload);

export interface PlaceStoreDeletePayload {
    placeStoreId: PlaceStore['id'];
}
export const del = async ({ placeStoreId }: PlaceStoreDeletePayload) =>
    await api.delete(`/placeStores/${placeStoreId}`);
