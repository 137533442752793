import { Descriptions, Divider, Spin } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ControlCustomerOrderStatus, ControlPreparationStatus, Preparation } from '../../store/api/apiTypes';
import { details as customerOrderDetails, getCustomerOrderStateById } from '../../store/actions/customerOrders';
import {
    listControlByCustomerOrderId as preparationControlList,
    getPreparationControlListByCustomerOrderIdState,
} from '../../store/actions/preparations';

import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Seo from '../../components/Seo';
import { useActions } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import {
    formatNumber,
    translateCustomerOrderControlStatus,
    translatePreparationControlStatus,
} from '../../helpers/i18n';
import QuantityCard from '../../components/QuantityCard';
import List, { ListColumn } from '../../components/list/List';
import FixedFooter from '../../components/FixedFooter';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ButtonGrey from '../../components/ButtonGrey';
import ControlActionsModal from './ControlActionsModal';

const ControlDetails: FC = () => {
    const { loadingOrderId, customerOrderId: customerOrderIdParam } =
        useParams<{ loadingOrderId: string; customerOrderId: string }>();
    const customerOrderId = parseInt(customerOrderIdParam, 10);
    const [isActionModalVisible, setIsActionModalVisible] = useState(false);
    const [fetchCustomerOrder, listPreparationControls] = useActions([
        customerOrderDetails.trigger,
        preparationControlList.trigger,
    ]);
    const customerOrderState = useSelector(getCustomerOrderStateById(customerOrderId));
    const preparationControlListState = useSelector(getPreparationControlListByCustomerOrderIdState(customerOrderId));
    const controlledQuantity = customerOrderState.data?.controlledQuantity ?? 0;
    const columns: Array<ListColumn<Preparation>> = [
        {
            dataIndex: 'parcel.reference',
            title: 'Référence colis',
            flex: '1 1 70%',
        },
        {
            key: 'status',
            title: 'Statut',
            flex: '1 1 50%',
            render: (record) => translatePreparationControlStatus(record.controlStatus),
        },
        {
            key: 'quantity',
            title: 'Quantité contrôlée',
            flex: '1 1 30%',
            render: (record) =>
                `${formatNumber(record.controlledQuantity)}/${formatNumber(
                    (record.preparedQuantity ?? 0) > record.quantity ? record.quantity : record.preparedQuantity
                )}`,
        },
    ];
    const listControl = useCallback(() => {
        listPreparationControls({
            customerOrderId,
            controlStatus: [
                ControlPreparationStatus.toBeProcessed,
                ControlPreparationStatus.inProgress,
                ControlPreparationStatus.completed,
                ControlPreparationStatus.toRegularize,
            ],
            pageSize: 1337,
        });
    }, [listPreparationControls, customerOrderId]);
    const refreshCustomerOrder = useCallback(() => {
        if (customerOrderId) {
            fetchCustomerOrder({ customerOrderId });
            listControl();
        }
    }, [customerOrderId, fetchCustomerOrder, listControl]);
    const onCloseActionsModal = useCallback(() => {
        setIsActionModalVisible(false);
    }, []);

    const onClickActions = () => {
        fetchCustomerOrder({ customerOrderId });
        setIsActionModalVisible(true);
    };

    useEffect(() => {
        if (customerOrderId) {
            fetchCustomerOrder({ customerOrderId });
            listControl();
        }
    }, [fetchCustomerOrder, listControl, customerOrderId]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Contrôle des colis" />
            <Header
                title="Contrôle des colis"
                backRoute={getRoute(RoutePathName.customerOrderDetails, { loadingOrderId, customerOrderId })}
                enableHomeButton
            />
            <PageHeader>
                <Spin spinning={customerOrderState.loading}>
                    <Descriptions column={4} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="Code client">
                            {customerOrderState.data?.customer?.reference ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Statut">
                            {translateCustomerOrderControlStatus(customerOrderState.data?.controlStatus)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Client" span={4}>
                            {customerOrderState.data?.customer?.label ?? '—'}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>
            <QuantityCard
                value={
                    customerOrderState.data?.controlStatus !== ControlCustomerOrderStatus.toBeProcessed
                        ? `${formatNumber(controlledQuantity)}/${formatNumber(
                              customerOrderState.data?.quantityToControl
                          )}`
                        : formatNumber(customerOrderState.data?.quantityToControl)
                }
                isComplete={customerOrderState.data?.controlStatus === ControlCustomerOrderStatus.completed}
                isWarning={controlledQuantity > (customerOrderState.data?.quantityToControl ?? 0)}
                label={
                    customerOrderState.data?.controlStatus !== ControlCustomerOrderStatus.toBeProcessed
                        ? `Colis contrôlé${controlledQuantity !== 1 ? 's' : ''}`
                        : 'Colis à contrôler'
                }
            />
            <Divider />
            <List<Preparation>
                columns={columns}
                data={preparationControlListState.data?.items}
                rowKey={(record) => `${record.id}`}
                isLoading={preparationControlListState.loading}
                isRowHiglighted={(record) => record.controlStatus === ControlPreparationStatus.completed}
                isRowWarning={(record) => record.controlStatus === ControlPreparationStatus.toRegularize}
                title="Références à contrôler"
                emptyDescription="Aucune référence à contrôler"
            />
            <ControlActionsModal
                visible={isActionModalVisible}
                onCancel={onCloseActionsModal}
                refreshCustomerOrder={refreshCustomerOrder}
                setIsVisible={setIsActionModalVisible}
            />
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut shortcut="enter" as={ButtonGrey} onClick={onClickActions} block>
                        Actions
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </FixedFooter.Wrapper>
    );
};

export default ControlDetails;
