import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { PurchaseOrder } from '../api/apiTypes';

// Controlers
import { PurchaseOrderUpdatePayload, update as updateApiCall } from '../api/purchaseOrders';

// States
export interface PurchaseOrdersState {
    update: RequestState<PurchaseOrder>;
}

const initialState: PurchaseOrdersState = {
    update: { ...requestInitialState },
};

export const update = new EzeeAsyncAction<PurchaseOrdersState['update'], PurchaseOrderUpdatePayload, PurchaseOrder>(
    'purchaseOrders/update',
    initialState.update,
    requestReducer<PurchaseOrdersState['update'], PurchaseOrder>(initialState.update)
);

// Reducer
export const purchaseOrdersReducer = combineReducers<PurchaseOrdersState>({
    update: update.reducer,
});

// Saga
export function* purchaseOrdersSaga() {
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
}

// Store helpers
export const getPurchaseOrderUpdateState = (state: MainReducerState) => state.purchaseOrders.update;
