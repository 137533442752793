import React, { FC, useCallback } from 'react';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useArrowNavScope, useShortcutScope } from '../../../hooks';
import { PlaceZoneType } from '../../../store/api/apiTypes';
import { translatePlaceZoneType } from '../../../helpers/i18n';

const shortcutScope = 'StartZoneType';

interface StartZoneTypeModel extends CustomModalProps {
    onSelect: (type: PlaceZoneType) => void;
}

const StartZoneTypeModal: FC<StartZoneTypeModel> = ({ visible, onCancel, onSelect }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    const formatZoneType = (zoneType?: PlaceZoneType) => {
        if (zoneType === PlaceZoneType.mass) {
            return `${translatePlaceZoneType(PlaceZoneType.mass)} / ${translatePlaceZoneType(
                PlaceZoneType.accumulation
            )}`;
        }
        return translatePlaceZoneType(zoneType);
    };

    return (
        <CustomModal
            visible={visible}
            onCancel={onCancel}
            title="zone de départ"
            width={368}
            keyboard={false}
            altTitle
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="1"
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onSelect.bind(null, PlaceZoneType.picking)}
                        >
                            {translatePlaceZoneType(PlaceZoneType.picking)}
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="2"
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onSelect.bind(null, PlaceZoneType.mass)}
                        >
                            {formatZoneType(PlaceZoneType.mass)}
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClose}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
        ></CustomModal>
    );
};

export default StartZoneTypeModal;
