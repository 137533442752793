import React, { FC, useCallback, useEffect, useState } from 'react';
import { Input, message, Form, FormProps, Button, Typography, Divider } from 'antd';
import { useSelector } from 'react-redux';

import {
    getPlaceSectorCreateState,
    getPlaceSectorUpdateState,
    getPlaceSectorDeleteState,
    create as placeSectorCreate,
    update as placeSectorUpdate,
    del as placeSectorDelete,
} from '../../store/actions/placeSectors';
import { PlaceSector, PlaceStoreType, PlaceZoneType } from '../../store/api/apiTypes';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useShortcutScope } from '../../hooks';
import SuccessMessage from '../../components/SuccessMessage';
import ButtonRed from '../../components/ButtonRed';
import PlaceStoreSelect from '../../components/PlaceStoreSelect';
import YesNoSwitchFormItem from '../../components/YesNoSwitchFormItem';
import { requiredRule } from '../../helpers';

const formatFormValues = (placeSector?: PlaceSector) => ({
    ...placeSector,
    picking: placeSector?.zoneType === PlaceZoneType.picking ? true : undefined,
    mass: placeSector?.zoneType === PlaceZoneType.mass ? true : undefined,
    storageByAccumulation: placeSector?.storageByAccumulation ? true : undefined,
});

const shortcutScope = 'PlaceSectorFormModal';

interface PlaceSectorFormModalProps extends CustomModalProps {
    placeSector?: PlaceSector;
    onSuccess?: () => void;
}

const PlaceSectorFormModal: FC<PlaceSectorFormModalProps> = ({ visible, onCancel, placeSector, onSuccess }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const isEditing = !!placeSector;
    const isMounted = useIsMounted();
    const [form] = Form.useForm();
    const [success, setSuccess] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [createPlaceSector, updatePlaceSector, deletePlaceSector] = useActions([
        placeSectorCreate.trigger,
        placeSectorUpdate.trigger,
        placeSectorDelete.trigger,
    ]);
    const placeSectorCreateState = useSelector(getPlaceSectorCreateState);
    const placeSectorUpdateState = useSelector(getPlaceSectorUpdateState);
    const placeSectorDeleteState = useSelector(getPlaceSectorDeleteState);
    const previous = usePrevious({ placeSectorCreateState, placeSectorUpdateState, placeSectorDeleteState });
    const onSubmit: FormProps['onFinish'] = ({ placeStore, picking, mass, ...values }) => {
        const zoneType = picking ? PlaceZoneType.picking : mass ? PlaceZoneType.mass : undefined;

        if (isEditing) {
            updatePlaceSector({
                placeSectorId: placeSector?.id,
                placeStoreId: placeStore?.id,
                ...values,
                zoneType,
            });
        } else {
            createPlaceSector({
                ...values,
                placeStoreId: placeStore?.id,
                zoneType,
            });
        }
    };
    const onDelete = () => {
        deletePlaceSector({ placeSectorId: placeSector?.id });
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const onFormValuesChange: FormProps['onValuesChange'] = (changedValues) => {
        const changedField = Object.keys(changedValues)?.[0];

        if (changedField === 'picking' && changedValues.picking) {
            form.setFieldsValue({
                mass: false,
                storageByAccumulation: false,
            });
        } else if (
            (changedField === 'mass' && changedValues.mass) ||
            (changedField === 'storageByAccumulation' && changedValues.storageByAccumulation)
        ) {
            form.setFieldsValue({
                picking: false,
            });
        }
    };

    useEffect(() => {
        if (previous?.placeSectorCreateState.loading && !placeSectorCreateState.loading) {
            if (placeSectorCreateState.error) {
                if (placeSectorCreateState.error?.status === 409) {
                    message.error('Ce nom est déjà utilisé par un autre secteur');
                } else {
                    message.error('Une erreur est survenue pendant la création du secteur');
                }
            } else {
                setSuccess(true);
            }
        }
    }, [placeSectorCreateState.error, placeSectorCreateState.loading, previous?.placeSectorCreateState.loading]);

    useEffect(() => {
        if (previous?.placeSectorUpdateState.loading && !placeSectorUpdateState.loading) {
            if (placeSectorUpdateState.error) {
                if (placeSectorUpdateState.error?.status === 409 && placeSectorUpdateState.error?.data?.placeNotEmpty) {
                    message.error("Ce secteur n'est pas vide, vous ne pouvez pas le modifier");
                } else {
                    message.error('Une erreur est survenue pendant la mise à jour du secteur');
                }
            } else {
                setSuccess(true);
            }
        }
    }, [placeSectorUpdateState.error, placeSectorUpdateState.loading, previous?.placeSectorUpdateState.loading]);

    useEffect(() => {
        if (previous?.placeSectorDeleteState.loading && !placeSectorDeleteState.loading) {
            if (placeSectorDeleteState.error) {
                if (placeSectorDeleteState.error?.status === 400) {
                    message.error("Vous ne pouvez pas supprimer ce secteur car il n'est pas vide");
                } else {
                    message.error('Une erreur est survenue pendant la suppression du secteur');
                }
            } else {
                setSuccess(true);
            }
        }
    }, [placeSectorDeleteState.error, placeSectorDeleteState.loading, previous?.placeSectorDeleteState.loading]);

    useEffect(() => {
        let timeout: number;

        if (success) {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onClose();
                    onSuccess?.();
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [isMounted, onClose, success, onSuccess]);

    useEffect(() => {
        if (placeSector) {
            form.setFieldsValue(formatFormValues(placeSector));
        } else {
            form.resetFields();
        }
    }, [placeSector, form]);

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            setIsDeleting(false);
            setSuccess(false);
        }
    }, [visible, form]);

    return (
        <CustomModal
            footer={
                success ? null : isDeleting ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                as={ButtonRed}
                                onClick={onDelete}
                                loading={placeSectorDeleteState.loading}
                            >
                                Oui, supprimer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                disabled={placeSectorDeleteState.loading}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={form.submit}
                                loading={placeSectorCreateState.loading || placeSectorUpdateState.loading}
                                disabled={placeSectorDeleteState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        {isEditing && (
                            <ArrowNavItem scope={shortcutScope}>
                                <Button onClick={setIsDeleting.bind(null, true)} danger>
                                    Supprimer
                                </Button>
                            </ArrowNavItem>
                        )}
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                disabled={placeSectorDeleteState.loading}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={
                !success &&
                (isDeleting
                    ? `Voulez-vous vraiment supprimer ce secteur maintenant ?`
                    : isEditing
                    ? 'Éditer'
                    : 'Ajouter un secteur')
            }
            width={368}
            keyboard={false}
            altTitle={!isDeleting}
        >
            {success ? (
                <SuccessMessage
                    message={`Le secteur a été ${isDeleting ? 'supprimé' : isEditing ? 'édité' : 'ajouté'} avec succès`}
                />
            ) : !isDeleting ? (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                    onValuesChange={onFormValuesChange}
                    initialValues={formatFormValues(placeSector)}
                    requiredMark={false}
                >
                    {!isEditing && (
                        <Form.Item name="placeStore" label="Site" rules={[requiredRule]}>
                            <PlaceStoreSelect placeStoreType={PlaceStoreType.site} allowClear={false} withArrowNav />
                        </Form.Item>
                    )}
                    <Form.Item
                        name="sector"
                        label="Secteur"
                        normalize={(value?: string) => `${value ?? ''}`.toUpperCase()}
                        rules={[requiredRule]}
                    >
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir un nom" />
                        </ArrowNavItem>
                    </Form.Item>
                    <YesNoSwitchFormItem
                        label="Picking"
                        name="picking"
                        rules={[
                            {
                                validator: async (_, value) => {
                                    if (!value && !form.getFieldValue('mass')) {
                                        return await Promise.reject(
                                            new Error('Veuillez choisir au moins picking ou masse')
                                        );
                                    }

                                    return await Promise.resolve();
                                },
                            },
                        ]}
                        dependencies={['mass']}
                    />
                    <Divider>
                        <Typography.Text className="text-primary" style={{ fontSize: '0.9375rem' }} strong>
                            OU
                        </Typography.Text>
                    </Divider>
                    <YesNoSwitchFormItem
                        label="Masse"
                        name="mass"
                        rules={[
                            {
                                validator: async (_, value) => {
                                    if (!value && !form.getFieldValue('picking')) {
                                        return await Promise.reject(
                                            new Error('Veuillez choisir au moins picking ou masse')
                                        );
                                    }

                                    return await Promise.resolve();
                                },
                            },
                        ]}
                        dependencies={['picking']}
                    />
                    <YesNoSwitchFormItem label="Stockage par accumulation" name="storageByAccumulation" />
                </Form>
            ) : null}
        </CustomModal>
    );
};

export default PlaceSectorFormModal;
