import { SearchPaginationQuery } from '.';
import {
    MovementHistoryType,
    Order,
    Pallet,
    PalletSize,
    PalletStatus,
    Place,
    PlaceZoneType,
    Printer,
    Reception,
    StickerType,
    WMSMode,
} from './apiTypes';
import api from './_client';

export interface PalletListByParcelPayload extends SearchPaginationQuery {
    parcelId: string;
    zoneType?: PlaceZoneType;
}
export const listByParcel = async ({ parcelId, ...payload }: PalletListByParcelPayload) =>
    await api.get(`/parcels/${parcelId}/pallets`, { params: payload });

export interface PalletDetailsPayload {
    palletId: number;
}
export const details = async ({ palletId, ...payload }: PalletDetailsPayload) =>
    await api.get(`/pallets/${palletId}`, payload);

export interface PalletDetailsByBarcodePayload {
    barcode: Pallet['barcode'];
}
export const detailsByBarcode = async ({ barcode, ...payload }: PalletDetailsByBarcodePayload) =>
    await api.get(`/pallets/barcode/${barcode}`, payload);

export interface PalletDetailsByReferencePayload {
    reference: Pallet['reference'];
}
export const detailsByReference = async ({ reference, ...payload }: PalletDetailsByReferencePayload) =>
    await api.get(`/pallets/reference/${reference}`, payload);

export interface PalletCreatePayload {
    quantity: number; // 1
    status: PalletStatus;
    receptionId?: Reception['id'];
    size: PalletSize; // 1
    operatorId: number;
    parcelId: number; // 1
    orderId?: Order['id'];
}
export const create = async (payload: PalletCreatePayload) => await api.post('/pallets', payload);

export type PalletUpdatePayload = Pallet;
export const update = async (payload: PalletUpdatePayload) => await api.put(`/pallets/${payload.id}`, payload);

export interface PalletPrintPayload {
    palletId: Pallet['id'];
    palletIds?: Array<Pallet['id']>; // only in action payload
    printerId: Printer['id'];
    stickerType?: StickerType;
    mode: WMSMode;
}
export const print = async ({ palletId, printerId, ...payload }: PalletPrintPayload) =>
    await api.post(`/pallets/${palletId}/print/${printerId}`, payload);

export interface PalletPlacePayload {
    palletId: Pallet['id'];
}
export const place = async ({ palletId }: PalletPlacePayload) => await api.get(`/pallets/${palletId}/place`);

export interface PalletStorePayload {
    palletId: Pallet['id'];
    barcode?: Place['barcode'];
    reference?: Place['reference'];
    type: MovementHistoryType;
    index?: number;
    reserved?: boolean;
}
export const store = async ({ palletId, ...payload }: PalletStorePayload) =>
    await api.post(`/pallets/${palletId}/store`, payload);

export interface PalletDelPayload {
    palletId: Pallet['id'];
}
export const del = async ({ palletId }: PalletDelPayload) => await api.delete(`/pallets/${palletId}`);

export interface PalletPackageListPayload {
    palletId: number;
}
export const packageList = async ({ palletId, ...payload }: PalletPackageListPayload) =>
    await api.get(`/pallets/${palletId}/packages`, payload);

export interface PalletDetailsByScanPayload {
    barcode?: Pallet['barcode'];
    reference?: Pallet['reference'];
}
export const detailsPalletByScan = async ({ barcode, reference, ...payload }: PalletDetailsByScanPayload) =>
    await api.get(`/pallets/${reference ? 'reference' : 'barcode'}/${reference ?? barcode ?? ''}`, { params: payload });

export interface PalletChangeResupplyIdPayload {
    palletId: Pallet['id'];
    barcode?: Pallet['barcode'];
    reference?: Pallet['reference'];
}
export const changeResupplyId = async ({ palletId, ...payload }: PalletChangeResupplyIdPayload) =>
    await api.post(`/pallets/${palletId}/changeResupplyId`, payload);
