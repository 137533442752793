import React, {
    CSSProperties,
    forwardRef,
    KeyboardEvent,
    ReactNode,
    Ref,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { Button, Checkbox, Radio } from 'antd';
import { Transform } from '@dnd-kit/utilities';
import { DraggableSyntheticListeners } from '@dnd-kit/core';

import '../../assets/styles/List.less';

import { IconAngleRight, IconEnter, IconHandle, IconLock, IconWarning } from '../icons';
import { classNames, debug } from '../../helpers';
import ArrowNavItem from '../ArrowNavItem';
import { ListColumn } from './List';

export interface ListRowProps<RecordType> {
    record: RecordType;
    columns: Array<ListColumn<RecordType>>;
    isHiglighted: boolean;
    isWarning: boolean;
    isError: boolean;
    isLocked: boolean;
    isSelectionDisabled: boolean;
    isSelected?: (record: RecordType) => boolean;
    selectType?: 'checkbox' | 'radio';
    isSortable?: boolean;
    index: number;
    renderCellFooter?: (record: RecordType) => ReactNode;
    renderCellAddonRight?: (record: RecordType) => ReactNode;
    onRowEnterPress?: (record: RecordType) => void;
    onRowClick?: (record: RecordType) => void;
    onRowFocus?: (record: RecordType) => void;
    onRowBlur?: (record: RecordType) => void;
    headerInCell?: boolean;
    disableArrowNav?: boolean;
    arrowNavScope?: string;
    noWarningIcon?: (record: RecordType) => boolean;
    focusable?: boolean;
    size: 'small' | 'middle' | 'large';

    // Sortable list row props
    dragOverlay?: boolean;
    isDragDisabled?: boolean;
    dragging?: boolean;
    fadeIn?: boolean;
    transform?: Transform | null;
    listeners?: DraggableSyntheticListeners;
    sorting?: boolean;
    style?: CSSProperties;
    transition?: string | null;
    wrapperStyle?: CSSProperties;
    isSuccess?: boolean;
}

function ListRow<RecordType extends Record<string, any>>(
    {
        record,
        columns,
        isHiglighted,
        isWarning,
        isError,
        isSelected,
        isSelectionDisabled,
        selectType,
        isLocked,
        isSortable,
        renderCellFooter,
        renderCellAddonRight,
        onRowEnterPress,
        onRowClick,
        onRowFocus,
        onRowBlur,
        headerInCell,
        disableArrowNav,
        arrowNavScope,
        noWarningIcon,
        focusable,
        size,

        dragOverlay,
        isDragDisabled,
        dragging,
        fadeIn,
        index,
        listeners,
        sorting,
        style,
        transition,
        transform,
        wrapperStyle,
        isSuccess,
        ...props
    }: ListRowProps<RecordType>,
    ref: Ref<HTMLLIElement>
) {
    const [isFocused, setIsFocused] = useState(false);
    const hasSelectLogic = typeof isSelected === 'function';
    const hasCheckboxSelectLogic = hasSelectLogic && selectType === 'checkbox';
    const hasRadioSelectLogic = hasSelectLogic && selectType === 'radio';
    const hasFooter = typeof renderCellFooter === 'function';
    const hasAddonRight = typeof renderCellAddonRight === 'function';
    const hasEnterPressListener = typeof onRowEnterPress === 'function';
    const getDataFromDataIndex = useCallback((dataIndex: string, record: RecordType) => {
        try {
            return dataIndex.split('.').reduce((acc, key) => acc[key], record);
        } catch (error) {
            debug.warn('[ListRow] getDataFromDataIndex error', error);
            return null;
        }
    }, []);
    const getColumnKey = useCallback((column: ListColumn<RecordType>, columnIndex: number) => {
        return `${(column.dataIndex as string | number) ?? column.key ?? columnIndex}`;
    }, []);
    const getCellContent = useCallback(
        (column: ListColumn<RecordType>, record: RecordType) => {
            if (typeof column.render === 'function') {
                if (column.dataIndex) {
                    return column.render(getDataFromDataIndex(column.dataIndex, record) ?? record);
                }

                return column.render(record);
            }

            if (column.dataIndex) {
                return getDataFromDataIndex(column.dataIndex, record) ?? '—';
            }

            return null;
        },
        [getDataFromDataIndex]
    );
    const onKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
        if (!isLocked) {
            if (e.key === 'Enter' || e.key === ' ') {
                debug.log('[ListRow] enter', record);
                if (hasEnterPressListener) {
                    onRowEnterPress?.(record);
                }

                e.preventDefault();
            }
        }
    };
    const onClick = () => {
        onRowClick?.(record);
    };
    const onFocus = () => {
        debug.log('[ListRow] focus', record);
        onRowFocus?.(record);
        setIsFocused(true);
    };
    const onBlur = () => {
        if (typeof onRowBlur === 'function') {
            onRowBlur(record);
        }
        setIsFocused(false);
    };
    const ariaRole = focusable
        ? hasCheckboxSelectLogic
            ? 'checkbox'
            : hasRadioSelectLogic
            ? 'radio'
            : 'button'
        : undefined;
    const ariaChecked = hasCheckboxSelectLogic || hasRadioSelectLogic ? isSelected?.(record) : undefined;

    useEffect(() => {
        if (!dragOverlay) {
            return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
            document.body.style.cursor = '';
        };
    }, [dragOverlay]);

    return (
        <li
            {...props}
            className={classNames(
                'list-row',
                isHiglighted && 'list-row-highlighted',
                isWarning && 'list-row-warning',
                isError && 'list-row-error',
                isSuccess && 'list-row-success',
                hasCheckboxSelectLogic && 'list-row-has-checkbox',
                isSelected?.(record) && selectType === 'radio' && 'list-row-selected',
                isSelected?.(record) && selectType === 'checkbox' && 'list-row-selected-checkbox',
                isLocked && 'list-row-locked',
                fadeIn && 'list-row-fade-in',
                sorting && 'list-row-sorting',
                dragOverlay && 'list-row-drag-overlay',
                dragging && 'list-row-dragging'
            )}
            style={
                {
                    ...wrapperStyle,
                    transition,
                    '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
                    '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
                    '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
                    '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
                    '--index': index,
                } as React.CSSProperties
            }
            ref={ref}
        >
            <ArrowNavItem disabled={disableArrowNav} scope={arrowNavScope}>
                <div
                    onKeyPress={focusable ? onKeyPress : undefined}
                    onClick={focusable && !isLocked ? onClick : undefined}
                    onFocus={focusable && !isLocked ? onFocus : undefined}
                    onBlur={focusable && !isLocked ? onBlur : undefined}
                    role={ariaRole}
                    aria-checked={ariaChecked}
                    tabIndex={focusable ? (isLocked ? -1 : 0) : undefined}
                    className={classNames(
                        'list-row-wrapper',
                        dragging && 'list-row-wrapper-dragging',
                        dragOverlay && 'list-row-wrapper-drag-overlay',
                        isLocked && 'list-row-wrapper-locked',
                        isSelectionDisabled && 'list-row-wrapper-selection-disabled'
                    )}
                    style={style}
                    data-cypress="draggable-item"
                >
                    {(!!isSortable || dragOverlay) && (
                        <div className="list-cell-sort">
                            <Button type="link" disabled={isDragDisabled} {...listeners}>
                                <IconHandle />
                            </Button>
                        </div>
                    )}
                    {hasCheckboxSelectLogic && (
                        <div
                            className={classNames(
                                'list-cell-prefix list-cell-prefix-checkbox',
                                isSelectionDisabled && 'list-cell-prefix-checkbox-disabled'
                            )}
                        >
                            <Checkbox
                                disabled={isLocked || isSelectionDisabled}
                                checked={isSelected?.(record)}
                                tabIndex={-1}
                                aria-hidden
                            />
                        </div>
                    )}
                    <div className="list-cell-content-wrapper">
                        <div className="list-cell-addon-wrapper">
                            <div
                                className={classNames(
                                    'list-cell-columns',
                                    hasRadioSelectLogic && 'has-suffix-radio',
                                    hasCheckboxSelectLogic && 'has-prefix-checkbox',
                                    hasFooter && 'has-footer',
                                    hasAddonRight && 'has-addon-right'
                                )}
                                style={headerInCell ? { flexWrap: 'wrap' } : undefined}
                            >
                                {columns
                                    .filter((column) => !column.hidden?.(record))
                                    .map((column, columnIndex) => (
                                        <div
                                            className={classNames('list-cell', `list-cell-${size}`)}
                                            key={getColumnKey(column, columnIndex)}
                                            style={{
                                                ...column.cellStyle,
                                                flex: column.flex,
                                            }}
                                        >
                                            {headerInCell && (
                                                <div className="list-cell-inner-column-title" style={column.style}>
                                                    {column.title}
                                                </div>
                                            )}
                                            <div className="list-cell-inner">
                                                {columnIndex === 0 && isWarning && !noWarningIcon?.(record) && (
                                                    <IconWarning style={{ marginRight: 12 }} />
                                                )}
                                                {getCellContent(column, record)}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            {hasAddonRight && (
                                <div
                                    className={classNames(
                                        'list-cell-addon-right',
                                        ((hasEnterPressListener && !hasCheckboxSelectLogic) || hasRadioSelectLogic) &&
                                            'has-suffix'
                                    )}
                                >
                                    {renderCellAddonRight?.(record)}
                                </div>
                            )}
                        </div>
                        {hasFooter && (
                            <div
                                className={classNames(
                                    'list-cell-footer',
                                    ((hasEnterPressListener && !hasCheckboxSelectLogic) || hasRadioSelectLogic) &&
                                        'has-suffix',
                                    hasCheckboxSelectLogic && 'has-prefix-checkbox'
                                )}
                            >
                                {renderCellFooter?.(record)}
                            </div>
                        )}
                    </div>
                    {isLocked ? (
                        <div className="list-cell-suffix list-cell-suffix-locked">
                            <IconLock />
                        </div>
                    ) : (
                        <>
                            {hasSelectLogic && selectType === 'radio' ? (
                                <div className="list-cell-suffix list-cell-suffix-radio">
                                    {isSelected?.(record) ? (
                                        <Radio disabled={isSelectionDisabled} checked aria-hidden />
                                    ) : (
                                        <Radio disabled={isSelectionDisabled} checked={false} aria-hidden />
                                    )}
                                </div>
                            ) : (
                                <>
                                    {hasEnterPressListener && !hasCheckboxSelectLogic && (
                                        <div className="list-cell-suffix">
                                            {isFocused ? <IconEnter /> : <IconAngleRight />}
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </ArrowNavItem>
        </li>
    );
}

export default forwardRef(ListRow);
