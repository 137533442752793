import React, { FC, ReactNode } from 'react';

import { IconSingleCheck } from './icons';

interface SuccessMessageProps {
    message: ReactNode;
}

const SuccessMessage: FC<SuccessMessageProps> = ({ message }) => (
    <p style={{ textAlign: 'center', margin: 0 }}>
        <IconSingleCheck style={{ color: '#83b726', fontSize: '2rem' }} />
        <br />
        <span style={{ fontSize: '1rem' }}>{message}</span>
    </p>
);

export default SuccessMessage;
