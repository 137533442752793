import { Order, Pallet } from './apiTypes';
import api from './_client';

export interface RelatedPalletsDetailsByBarcodePayload {
    barcode: Pallet['barcode'];
    customerOrder: Order['id'];
}
export const detailsByBarcode = async ({ barcode, ...payload }: RelatedPalletsDetailsByBarcodePayload) =>
    await api.get(`/relatedPallets/barcode/${barcode}`, { params: payload });

export interface RelatedPalletsDetailsByReferencePayload {
    reference: Pallet['reference'];
    customerOrder: Order['id'];
}
export const detailsByReference = async ({ reference, ...payload }: RelatedPalletsDetailsByReferencePayload) =>
    await api.get(`/relatedPallets/reference/${reference}`, { params: payload });
