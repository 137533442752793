import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { Select, SelectProps } from 'antd';

import { DayOfWeek } from '../store/api/apiTypes';

import ArrowNavItem from './ArrowNavItem';
import { translateDayOfWeek } from '../helpers/i18n';

interface DayOfWeekSelectProps extends SelectProps<DayOfWeek> {
    multi?: boolean;
    placeholder?: string;
    onChange?: (value: any) => void;
    withArrowNav?: boolean;
    arrowNavScope?: string;
}

const DayOfWeekSelect: ForwardRefRenderFunction<typeof Select, DayOfWeekSelectProps> = (props, ref: any) => {
    const { multi, placeholder, withArrowNav, arrowNavScope, ...selectProps } = props;

    const select = (
        <Select
            mode={multi ? 'multiple' : undefined}
            ref={ref}
            placeholder={placeholder ?? 'Jour de livraison'}
            filterOption={false}
            style={{ width: '100%' }}
            allowClear
            showArrow
            {...selectProps}
        >
            {Object.values(DayOfWeek).map((day) => (
                <Select.Option value={day} key={day}>
                    {translateDayOfWeek(day)}
                </Select.Option>
            ))}
        </Select>
    );

    return withArrowNav ? <ArrowNavItem scope={arrowNavScope}>{select}</ArrowNavItem> : select;
};

export default forwardRef(DayOfWeekSelect);
