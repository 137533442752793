import React, { FC } from 'react';

import BasicList from '../../components/BasicList';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ButtonLink from '../../components/ButtonLink';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import { getRoute, RoutePathName } from '../../routes';

const MovementsMenu: FC = () => {
    return (
        <>
            <Seo title="Mouvements" />
            <Header title="Mouvements" backRoute={getRoute(RoutePathName.home)} />
            <nav className="menu">
                <BasicList>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="1"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.movementsTransferOrderList)}
                                block
                            >
                                Ordre de transfert
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="2"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.movementsStockMenu)}
                                block
                            >
                                Mouvement de stock
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                </BasicList>
            </nav>
        </>
    );
};

export default MovementsMenu;
