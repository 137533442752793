import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Input, InputProps, message, Spin, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    detailsByBarcode,
    detailsByReference,
    getPalletDetailsStateByBarcode,
    getPalletDetailsStateByReference,
} from '../../store/actions/relatedPallets';
import { control } from '../../store/actions/preparationControl';

import { isZebra } from '../../helpers/enterprise-browser';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { IconWarning } from '../../components/icons';

const shortcutScope = 'ControlVracPrintAgainModal';

interface ControlVracPrintAgainModalProps extends CustomModalProps {
    onStartControl: () => void;
    onPrint: () => void;
}

const ControlVracPrintAgainModal: FC<ControlVracPrintAgainModalProps> = ({
    visible,
    onCancel,
    onStartControl,
    onPrint,
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const { customerOrderId } = useParams<{ customerOrderId: string }>();
    const [loadPalletDetailsByBarcode, loadPalletDetailsByRef, setControlPrintState] = useActions([
        detailsByBarcode.trigger,
        detailsByReference.trigger,
        control.actions.setPrintData,
    ]);
    const [isNotControlledView, setIsNotControlledView] = useState(false);
    const [reference, setReference] = useState<string>();
    const palletByRefState = useSelector(getPalletDetailsStateByReference);
    const palletByBarcodeState = useSelector(getPalletDetailsStateByBarcode);
    const previous = usePrevious({ palletByRefState, palletByBarcodeState });
    const inputRef = useRef<Input | null>(null);
    const onSubmit = () => {
        loadPalletDetailsByRef({ reference, customerOrderId });
    };
    const onChangeField: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const onControlNow = () => {
        onClose();
        onStartControl();
    };
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                loadPalletDetailsByBarcode({ barcode: barCode.data, customerOrderId });
            }
        },
        {
            deps: [loadPalletDetailsByBarcode],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    useEffect(() => {
        if (!visible) {
            setReference(undefined);
            if (inputRef.current) {
                inputRef.current.blur();
            }
        } else {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [visible, setReference]);

    useEffect(() => {
        if (visible && previous?.palletByRefState.loading && !palletByRefState.loading) {
            if (palletByRefState.error) {
                if (palletByRefState.error?.status === 404) {
                    message.error('Colis vrac non trouvé');
                } else if (palletByRefState.error?.status === 409) {
                    setIsNotControlledView(true);
                } else {
                    message.error('Une erreur est survenue lors de la demande de palette');
                }
            } else {
                if (palletByRefState.data?.id) {
                    setControlPrintState({
                        vracPalletId: palletByRefState.data?.id,
                    });
                    onPrint();
                } else {
                    message.error('Une erreur est survenue lors de la demande de palette (id manquant)');
                }
            }
        }
    }, [
        previous?.palletByRefState,
        palletByRefState.loading,
        palletByRefState.error,
        visible,
        palletByRefState.data?.id,
        onPrint,
        setControlPrintState,
    ]);

    useEffect(() => {
        if (visible && previous?.palletByBarcodeState.loading && !palletByBarcodeState.loading) {
            if (palletByBarcodeState.error) {
                if (palletByBarcodeState.error?.status === 404) {
                    message.error('Colis vrac non trouvé');
                } else if (palletByBarcodeState.error?.status === 409) {
                    setIsNotControlledView(true);
                } else {
                    message.error('Une erreur est survenue lors de la demande de palette');
                }
            } else {
                if (palletByBarcodeState.data?.id) {
                    setControlPrintState({
                        vracPalletId: palletByBarcodeState.data?.id,
                    });
                    onPrint();
                } else {
                    message.error('Une erreur est survenue lors de la demande de palette (id manquant)');
                }
            }
        }
    }, [
        previous?.palletByBarcodeState,
        palletByBarcodeState.loading,
        palletByBarcodeState.error,
        palletByBarcodeState.data?.id,
        setControlPrintState,
        onPrint,
        visible,
    ]);

    return (
        <CustomModal
            footer={
                isNotControlledView ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={onControlNow}
                            >
                                Contrôler maintenant
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={setIsNotControlledView.bind(null, false)}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={onSubmit}
                                loading={palletByRefState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={!isNotControlledView && 'Ré-imprimer étiquette'}
            width={368}
            keyboard={false}
            altTitle
        >
            {isNotControlledView ? (
                <Typography.Text>
                    <Typography.Text type="warning">
                        <IconWarning style={{ fontSize: '2.25rem', marginBottom: '1rem' }} />
                    </Typography.Text>
                    <br />
                    Ce colis n&rsquo;a pas été contrôlé. <br />
                    Que souhaitez-vous faire ?
                </Typography.Text>
            ) : (
                <Spin spinning={palletByBarcodeState.loading}>
                    <ArrowNavItem scope={shortcutScope}>
                        <Input
                            placeholder={isZebra ? 'Flasher palette/colis' : 'Entrer référence palette/CONT colis'}
                            onChange={onChangeField}
                            ref={inputRef}
                            autoFocus
                        />
                    </ArrowNavItem>
                </Spin>
            )}
        </CustomModal>
    );
};

export default ControlVracPrintAgainModal;
