import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { Customer } from '../api/apiTypes';
import { ListResponse } from '../api/';

// Controlers
import {
    CustomerListPayload,
    list as listApiCall,
    details as detailsApiCall,
    CustomerDetailsPayload,
} from '../api/customers';

// States
export interface CustomersState {
    list: RequestState<ListResponse<Customer>>;
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: Customer | undefined;
        };
    };
}

const initialState: CustomersState = {
    list: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
};

export const list = new EzeeAsyncAction<CustomersState['list'], CustomerListPayload, ListResponse<Customer>>(
    'customers/list',
    initialState.list,
    requestReducer<CustomersState['list'], ListResponse<Customer>>(initialState.list)
);

export const details = new EzeeAsyncAction<CustomersState['details'], CustomerDetailsPayload, Customer>(
    'customers/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

// Reducer
export const customersReducer = combineReducers<CustomersState>({
    list: list.reducer,
    details: details.reducer,
});

// Saga
export function* customersSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getCustomersListState = (state: MainReducerState) => state.customers.list;
export const getCustomerStateById = (id: Customer['id']) => (state: MainReducerState) => ({
    loading: state.customers.details.loading,
    error: state.customers.details.error,
    data: state.customers.details.data[id],
});
