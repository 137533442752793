import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { message } from 'antd';

import { Pallet, PalletStatus } from '../../../store/api/apiTypes';
import { getPalletUpdateState, update } from '../../../store/actions/pallets';

import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../../hooks';
import { translatePalletStatus } from '../../../helpers/i18n';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import SuccessMessage from '../../../components/SuccessMessage';

const shortcutScope = 'LotStatusModal';

interface LotStatusModalProps extends CustomModalProps {
    palletId?: Pallet['id'];
    onSuccess: () => void;
}

const LotStatusModal: FC<LotStatusModalProps> = ({ visible, onCancel, onSuccess, palletId }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [isSuccess, setIsSuccess] = useState(false);
    const updatePallet = useActions(update.trigger);
    const palletUpdateState = useSelector(getPalletUpdateState);
    const previous = usePrevious({ palletUpdateState });
    const onClickStatus = (status: PalletStatus) => {
        updatePallet({
            id: palletId,
            status,
        });
    };

    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    useEffect(() => {
        if (previous?.palletUpdateState.loading && !palletUpdateState.loading) {
            if (palletUpdateState.error) {
                message.error('Une erreur est survenue pendant la mise à jour du statut');
            } else {
                setIsSuccess(true);
            }
        }
    }, [previous?.palletUpdateState.loading, palletUpdateState.loading, palletUpdateState.error]);

    useEffect(() => {
        let timeout: number;

        if (!visible) {
            setIsSuccess(false);
        } else if (isSuccess) {
            onSuccess();

            timeout = window.setTimeout(() => {
                if (timeout) {
                    onClose();
                }
            }, 2000);
        }

        return () => {
            window.clearTimeout(timeout);
        };
    }, [visible, onClose, onSuccess, isSuccess]);

    return (
        <CustomModal
            visible={visible}
            width={368}
            keyboard={false}
            title={!isSuccess && 'Quel nouveau statut voulez-vous donner à ce lot ?'}
            footer={
                !isSuccess && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="1"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClickStatus.bind(null, PalletStatus.receivedInPlatform)}
                            >
                                {translatePalletStatus(PalletStatus.receivedInPlatform)}
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="2"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClickStatus.bind(null, PalletStatus.receivedInPlatformDerogated)}
                            >
                                {translatePalletStatus(PalletStatus.receivedInPlatformDerogated)}
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Fermer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
        >
            {isSuccess && <SuccessMessage message="Statut modifié avec succès" />}
        </CustomModal>
    );
};

export default LotStatusModal;
