import { RequestError } from './../store/api/index';

export enum TransferOrderErrors {
    notMass = 'Palette non reconnue en masse',
    transferOrderNotFound = `Un erreur est survenue pendant la création d'ordre de transfert`,
    placeNotFound = 'Place non reconnue',
    palletNotFoundInPlace = 'Palette non trouvée dans l’emplacement',
    parcelNotExist = `Il y a aucun colis dans cette palette`,
    default = `Une erreur est survenue pendant la création d'ordre de transfert`,
    resupplyOrderExist = `Il y a une commande de réapprovisionnement pour cet emplacement`,
    inventoryExist = `Il y a un inventaire pour cet emplacement`,
    stockControlExist = `Il y a un contrôle de stock pour cet emplacement`,
}
enum RepairErrors {
    default = 'Une erreur se produite pendant le rangement des colis',
    resupplyOrderExist = `Il y a une commande de réapprovisionnement pour cet emplacement`,
    inventoryExist = `Il y a un inventaire pour cet emplacement`,
    stockControlExist = `Il y a un contrôle de stock pour cet emplacement`,
}
export interface TransferOrderError {
    message: string;
    name: string;
}

export const translateTransferOrderError = (err: RequestError) => {
    const definedError = Object.entries(TransferOrderErrors).find(([key]) => Object.keys(err.data).includes(key));
    return definedError ? definedError[1] : TransferOrderErrors.default;
};

export const translateStoreRepairPackagesError = (error: RequestError) => {
    const { resupplyOrderExist, inventoryExist, stockControlExist } = error?.data;
    return resupplyOrderExist
        ? RepairErrors.resupplyOrderExist
        : inventoryExist
        ? RepairErrors.inventoryExist
        : stockControlExist
        ? RepairErrors.stockControlExist
        : RepairErrors.default;
};
