import React, { FC, useCallback, useEffect, useState } from 'react';
import { Descriptions, message, Spin } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { InventoryPlace, InventoryStatus, InventoryType } from '../../store/api/apiTypes';
import {
    close,
    getInventoryCloseState,
    getInventoryPlacesListState,
    getInventoryPlaceStateById,
    getInventoryPlacesUpdateState,
    getInventoryUpdateState,
    placeDetails,
    placeList,
    placeUpdate,
    update as updateInventoryAction,
} from '../../store/actions/inventories';

import { useActions, useArrowNavScope, useIsMobile, useIsMounted, usePrevious, useScanner } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import PageHeader from '../../components/PageHeader';
import Header from '../../components/Header';
import Seo from '../../components/Seo';
import FixedFooter from '../../components/FixedFooter';
import QuantityInput from '../../components/QuantityInput';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonGrey from '../../components/ButtonGrey';
import QuantityCard from '../../components/QuantityCard';
import CustomModal from '../../components/CustomModal';
import InventoryAddParcelModal from './InventoryAddParcelModal';
import TitleBlack from '../../components/TitleBlack';
import SuccessMessage from '../../components/SuccessMessage';
import { formatNumber } from '../../helpers/i18n';

const getParcel = (data?: InventoryPlace) => {
    if (data?.theoreticalQuantity === 0 && data?.foundParcel) {
        return data?.foundParcel;
    } else if ((data?.theoreticalQuantity ?? 0) > 0 && data?.parcel) {
        return data?.parcel;
    } else {
        return undefined;
    }
};

const stockModalScope = 'stockModal';
const successModalScope = 'successModal';

const InventoryPlaceDetails: FC = () => {
    const isMobile = useIsMobile();
    const isMounted = useIsMounted();
    const history = useHistory();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [isAddParcelModalVisible, setIsAddParcelModalVisible] = useState(false);
    const [isStockModalVisible, setIsStockModalVisible] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [isClosingPlace, setIsClosingPlace] = useState(false);
    const { inventoryId, inventoryPlaceId } = useParams<{ inventoryId: string; inventoryPlaceId: string }>();
    const [fetchInventoryPlace, updateInventoryPlace, fetchPlaces, closeInventory, updateInventory] = useActions([
        placeDetails.trigger,
        placeUpdate.trigger,
        placeList.trigger,
        close.trigger,
        updateInventoryAction.trigger,
    ]);
    const inventoryPlaceState = useSelector(getInventoryPlaceStateById(parseInt(inventoryPlaceId, 10)));
    const inventoryPlaceUpdateState = useSelector(getInventoryPlacesUpdateState);
    const inventoryPlacesState = useSelector(getInventoryPlacesListState);
    const inventoryCloseState = useSelector(getInventoryCloseState);
    const inventoryUpdateState = useSelector(getInventoryUpdateState);
    const [quantity, setQuantity] = useState(inventoryPlaceState.data?.quantity ?? 0);
    const parcel = getParcel(inventoryPlaceState.data);
    const previous = usePrevious({
        inventoryPlaceState,
        inventoryPlaceUpdateState,
        inventoryPlacesState,
        inventoryCloseState,
        inventoryUpdateState,
    });
    useArrowNavScope(stockModalScope, !isStockModalVisible);
    useArrowNavScope(successModalScope, !isSuccessModalVisible);
    const onSubmit = () => {
        if (quantity !== inventoryPlaceState.data?.theoreticalQuantity) {
            setIsStockModalVisible(true);
        } else {
            setIsClosingPlace(true);
            updateInventoryPlace({
                id: inventoryPlaceState.data?.id,
                status: InventoryStatus.completed,
            });
            if (inventoryPlaceState.data?.inventory.status === InventoryStatus.toBeProcessed) {
                updateInventory({
                    id: inventoryId,
                    status: InventoryStatus.inProgress,
                });
            }
        }
    };
    const onChangeQuantity = useCallback(
        (value: number) => {
            setQuantity(value);
            updateInventoryPlace(
                {
                    id: inventoryPlaceState.data?.id,
                    quantity: value,
                },
                { throttling: 300 }
            );
        },
        [inventoryPlaceState.data?.id, updateInventoryPlace]
    );
    const onValidateStockDelta = () => {
        setIsClosingPlace(true);
        updateInventoryPlace({
            id: inventoryPlaceState.data?.id,
            status: InventoryStatus.toValidGap,
            quantity,
        });
        if (inventoryPlaceState.data?.inventory.status === InventoryStatus.toBeProcessed) {
            updateInventory({
                id: inventoryId,
                status: InventoryStatus.inProgress,
            });
        }
    };
    const onRecount = () => {
        setIsStockModalVisible(false);
        setQuantity(0);
        updateInventoryPlace({
            id: inventoryPlaceState.data?.id,
            quantity: 0,
        });
    };
    const onClickCloseAndReturn = () => {
        setIsSuccessModalVisible(false);
        history.push(getRoute(RoutePathName.home));
    };

    useScanner(
        'InventoryPlaceDetails',
        (barCode) => {
            if (
                barCode.data &&
                (barCode.data === inventoryPlaceState.data?.parcel?.barcode ||
                    (inventoryPlaceState.data?.theoreticalQuantity === 0 &&
                        barCode.data === inventoryPlaceState.data?.foundParcel?.barcode))
            ) {
                onChangeQuantity(quantity + 1);
            } else {
                message.error('Code-barre non reconnu');
            }
        },
        {
            deps: [
                onChangeQuantity,
                inventoryPlaceState.data?.parcel?.barcode,
                inventoryPlaceState.data?.theoreticalQuantity,
                inventoryPlaceState.data?.foundParcel?.barcode,
                quantity,
            ],
            disable:
                (!!inventoryPlaceState.data?.status &&
                    inventoryPlaceState.data.status !== InventoryStatus.inProgress) ||
                isAddParcelModalVisible ||
                (inventoryPlaceState.data?.theoreticalQuantity === 0 && !inventoryPlaceState.data?.foundParcel),
        }
    );

    useEffect(() => {
        fetchInventoryPlace({ inventoryPlaceId });
    }, [fetchInventoryPlace, inventoryPlaceId]);

    // Update inventory status
    useEffect(() => {
        if (previous?.inventoryUpdateState.loading && !inventoryUpdateState.loading) {
            if (inventoryUpdateState.error) {
                message.error("Une erreur est survenue pendant la mise à jour de l'inventaire");
            } else {
                fetchInventoryPlace({ inventoryPlaceId });
            }
        }
    }, [
        fetchInventoryPlace,
        inventoryPlaceId,
        inventoryUpdateState.error,
        inventoryUpdateState.loading,
        previous?.inventoryUpdateState.loading,
    ]);

    // update status to inProgress if it is toBeProcessed
    useEffect(() => {
        if (
            previous?.inventoryPlaceState.loading &&
            !inventoryPlaceState.loading &&
            !inventoryPlaceState.error &&
            inventoryPlaceState.data &&
            inventoryPlaceState.data.status === InventoryStatus.toBeProcessed
        ) {
            setIsUpdatingStatus(true);
            updateInventoryPlace({ id: inventoryPlaceId, status: InventoryStatus.inProgress });
        }
    }, [previous?.inventoryPlaceState, inventoryPlaceState, updateInventoryPlace, inventoryPlaceId]);

    // refresh after updating status
    useEffect(() => {
        if (
            previous?.inventoryPlaceUpdateState.loading &&
            !inventoryPlaceUpdateState.loading &&
            !inventoryPlaceUpdateState.error &&
            isUpdatingStatus
        ) {
            setIsUpdatingStatus(false);
            fetchInventoryPlace({ inventoryPlaceId });
        }
    }, [
        fetchInventoryPlace,
        inventoryPlaceId,
        inventoryPlaceUpdateState.error,
        inventoryPlaceUpdateState.loading,
        previous?.inventoryPlaceUpdateState.loading,
        isUpdatingStatus,
        setIsUpdatingStatus,
    ]);

    // inventoryPlace update response when closing it
    useEffect(() => {
        if (previous?.inventoryPlaceUpdateState.loading && !inventoryPlaceUpdateState.loading && isClosingPlace) {
            if (inventoryPlaceUpdateState.error) {
                message.error("Une erreur est survenue pendant la mise à jour de l'inventaire");
            } else {
                setIsStockModalVisible(false);
                fetchInventoryPlace({ inventoryPlaceId });
            }
        }
    }, [
        inventoryPlaceUpdateState.error,
        inventoryPlaceUpdateState.loading,
        isClosingPlace,
        previous?.inventoryPlaceUpdateState.loading,
        fetchPlaces,
        inventoryPlaceId,
        fetchInventoryPlace,
    ]);

    // inventoryPlace get response when closing it, fetch places
    useEffect(() => {
        if (previous?.inventoryPlaceState.loading && !inventoryPlaceState.loading && isClosingPlace) {
            setIsClosingPlace(false);

            if (!inventoryPlaceState.error) {
                fetchPlaces({
                    inventoryId,
                    status: [InventoryStatus.toBeProcessed, InventoryStatus.inProgress],
                });
            }
        }
    }, [
        inventoryPlaceState.error,
        inventoryPlaceState.loading,
        isClosingPlace,
        previous?.inventoryPlaceState.loading,
        fetchPlaces,
        inventoryId,
    ]);

    // inventory places list response, show success modal
    useEffect(() => {
        if (previous?.inventoryPlacesState.loading && !inventoryPlacesState.loading && !inventoryPlacesState.error) {
            if (inventoryPlacesState.data?.totalCount) {
                setIsSuccessModalVisible(true);
            } else {
                closeInventory({ inventoryId });
            }
        }
    }, [
        setIsSuccessModalVisible,
        inventoryPlacesState.error,
        inventoryPlacesState.loading,
        previous?.inventoryPlacesState.loading,
        inventoryPlacesState.data?.totalCount,
        closeInventory,
        inventoryId,
    ]);

    // inventory close response, show success modal
    useEffect(() => {
        if (previous?.inventoryCloseState.loading && !inventoryCloseState.loading && !inventoryCloseState.error) {
            setIsSuccessModalVisible(true);
        }
    }, [
        setIsSuccessModalVisible,
        inventoryCloseState.error,
        inventoryCloseState.loading,
        previous?.inventoryCloseState.loading,
    ]);

    // auto close success modal if it's not the last inventoryPlace to do
    useEffect(() => {
        let timeout: number;

        if (isSuccessModalVisible && !!inventoryPlacesState.data?.totalCount) {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    setIsSuccessModalVisible(false);
                }
            }, 1500);
        }

        return () => window.clearTimeout(timeout);
    }, [inventoryPlacesState.data?.totalCount, isMounted, isSuccessModalVisible]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Inventaire emplacement" />
            <Header
                title="Inventaire emplacement"
                backRoute={getRoute(RoutePathName.inventoryDetails, { inventoryId })}
                enableHomeButton
            />
            <PageHeader>
                <Spin spinning={inventoryPlaceState.loading}>
                    <Descriptions column={{ xs: 4 }} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="Référence">{parcel?.reference ?? '—'}</Descriptions.Item>
                        <Descriptions.Item label="Désignation">{parcel?.label ?? '—'}</Descriptions.Item>
                        <Descriptions.Item label="Site">
                            {inventoryPlaceState.data?.place.store?.name ?? '—'}
                        </Descriptions.Item>
                        {!!inventoryPlaceState.data?.place.sector && (
                            <Descriptions.Item label="Secteur">
                                {inventoryPlaceState.data?.place.sector ?? '—'}
                            </Descriptions.Item>
                        )}
                        {(inventoryPlaceState.data?.place.lane ??
                            inventoryPlaceState.data?.place.alveole ??
                            inventoryPlaceState.data?.place.level) && (
                            <Descriptions.Item label="Casier">{`${inventoryPlaceState.data?.place.lane ?? ''}${
                                inventoryPlaceState.data?.place.alveole ?? ''
                            }${inventoryPlaceState.data?.place.level ?? ''}`}</Descriptions.Item>
                        )}
                        <Descriptions.Item label="Emplacement">
                            {inventoryPlaceState.data?.place.spot ?? '—'}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>
            {!inventoryPlaceState.loading && (
                <>
                    {inventoryPlaceState.data?.inventory.status &&
                    [InventoryStatus.completed, InventoryStatus.toValidGap].includes(
                        inventoryPlaceState.data.status
                    ) ? (
                        <>
                            <QuantityCard
                                value={
                                    inventoryPlaceState.data?.quantity === 0
                                        ? null
                                        : formatNumber(inventoryPlaceState.data?.quantity)
                                }
                                label={
                                    inventoryPlaceState.data?.quantity === 0 ? 'Emplacement vide' : 'Colis inventoriés'
                                }
                                isComplete={
                                    (inventoryPlaceState.data?.quantity ?? 0) ===
                                    inventoryPlaceState.data?.theoreticalQuantity
                                }
                                isPartial={
                                    inventoryPlaceState.data?.quantity !== undefined &&
                                    inventoryPlaceState.data?.quantity !== inventoryPlaceState.data?.theoreticalQuantity
                                }
                            />
                            <CustomModal
                                visible={isSuccessModalVisible}
                                onCancel={setIsSuccessModalVisible.bind(null, false)}
                                footer={
                                    !inventoryPlacesState.data?.totalCount && (
                                        <ArrowNavItem scope={stockModalScope}>
                                            <ButtonWithShortcut
                                                shortcut="enter"
                                                type="primary"
                                                disabled={!isSuccessModalVisible}
                                                onClick={onClickCloseAndReturn}
                                                loading={inventoryPlaceUpdateState.loading}
                                            >
                                                Fermer et retourner à l&rsquo;accueil
                                            </ButtonWithShortcut>
                                        </ArrowNavItem>
                                    )
                                }
                                width={inventoryPlacesState.data?.totalCount ? 232 : 368}
                            >
                                <SuccessMessage
                                    message={
                                        inventoryPlacesState.data?.totalCount
                                            ? 'Emplacement inventorié avec succès.'
                                            : 'Tous les emplacements ont été inventoriés avec succès.'
                                    }
                                />
                            </CustomModal>
                        </>
                    ) : (
                        <>
                            {parcel ? (
                                <>
                                    <TitleBlack>Quantité</TitleBlack>
                                    <QuantityInput onChange={onChangeQuantity} value={quantity} hasArrowNav />
                                </>
                            ) : (
                                <>
                                    <ButtonWithShortcut
                                        shortcut="f2"
                                        onClick={setIsAddParcelModalVisible.bind(null, true)}
                                        block
                                    >
                                        L&rsquo;emplacement n&rsquo;est pas vide
                                    </ButtonWithShortcut>
                                    <InventoryAddParcelModal
                                        visible={isAddParcelModalVisible}
                                        onCancel={setIsAddParcelModalVisible.bind(null, false)}
                                    />
                                </>
                            )}
                            <FixedFooter>
                                <ArrowNavItem>
                                    <ButtonWithShortcut
                                        shortcut="enter"
                                        onClick={onSubmit}
                                        as={ButtonGrey}
                                        size={isMobile ? 'middle' : 'large'}
                                        block
                                    >
                                        Clore l&rsquo;inventaire de{' '}
                                        {inventoryPlaceState.data?.inventory.type === InventoryType.place
                                            ? 'cet emplacement'
                                            : 'cette référence'}
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </FixedFooter>
                            {quantity !== inventoryPlaceState.data?.theoreticalQuantity && (
                                <CustomModal
                                    visible={isStockModalVisible}
                                    onCancel={onRecount}
                                    onOk={onValidateStockDelta}
                                    title="Vous allez déclarer un écart de stock. Que souhaitez-vous faire ?"
                                    footer={
                                        <>
                                            <ArrowNavItem scope={stockModalScope}>
                                                <ButtonWithShortcut
                                                    shortcut="enter"
                                                    type="primary"
                                                    disabled={!isStockModalVisible}
                                                    onClick={onValidateStockDelta}
                                                    loading={inventoryPlaceUpdateState.loading}
                                                >
                                                    Valider l&rsquo;écart
                                                </ButtonWithShortcut>
                                            </ArrowNavItem>
                                            <ArrowNavItem scope={stockModalScope}>
                                                <ButtonWithShortcut
                                                    shortcut="esc"
                                                    type="primary"
                                                    disabled={!isStockModalVisible}
                                                    onClick={onRecount}
                                                    ghost
                                                >
                                                    Recompter
                                                </ButtonWithShortcut>
                                            </ArrowNavItem>
                                        </>
                                    }
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </FixedFooter.Wrapper>
    );
};

export default InventoryPlaceDetails;
