import { CustomerOrder, Package, Pallet, Parcel } from './apiTypes';
import api from './_client';

export interface PreparationControlPackagePayload {
    customerOrderId: CustomerOrder['id'];
    cont?: Package['cont'];
    reference?: Parcel['reference'];
    barcode?: Parcel['barcode'];
}
export const controlPackage = async (payload: PreparationControlPackagePayload) =>
    await api.get('/preparationsControlPackage', { params: payload });

export interface PreparationControlPackagesPayload {
    customerOrderId: CustomerOrder['id'];
    palletId: Pallet['id'];
    barcodes?: string[];
    references?: Array<{
        reference: Parcel['reference'];
        cont: Package['cont'];
    }>;
}
export const controlPackages = async (payload: PreparationControlPackagesPayload) =>
    await api.post('/preparationsControlPackages', payload);

export interface PreparationControlTransferPackagePayload {
    customerOrderId: CustomerOrder['id'];
    cont?: Package['cont'];
    reference?: Parcel['reference'];
    barcode?: Parcel['barcode'];
}
export const transferPackage = async (payload: PreparationControlTransferPackagePayload) =>
    await api.get('/preparationsTransferPackage', { params: payload });

export interface PreparationControlTransferPackagesPayload {
    customerOrderId: CustomerOrder['id'];
    palletId: Pallet['id'];
    barcodes?: string[];
    references?: Array<{
        reference: Parcel['reference'];
        cont: Package['cont'];
    }>;
}
export const transferPackages = async (payload: PreparationControlTransferPackagesPayload) =>
    await api.post('/preparationsTransferPackages', payload);

export interface PreparationControlRemovePackagePayload {
    customerOrderId: CustomerOrder['id'];
    cont?: Package['cont'];
    reference?: Parcel['reference'];
    barcode?: Parcel['barcode'];
}
export const removePackage = async (payload: PreparationControlRemovePackagePayload) =>
    await api.get('/preparationsRemovePackage', { params: payload });

export interface PreparationControlRemovePackagesPayload {
    customerOrderId: CustomerOrder['id'];
    palletId: Pallet['id'];
    barcodes?: string[];
    references?: Array<{
        reference: Parcel['reference'];
        cont: Package['cont'];
    }>;
}
export const removePackages = async (payload: PreparationControlRemovePackagesPayload) =>
    await api.post('/preparationsRemovePackages', payload);
export interface PreparationControlBrokenPackagesPayload {
    customerOrderId: CustomerOrder['id'];
    palletId: Pallet['id'];
    barcodes?: string[];
    references?: Array<{
        reference: Parcel['reference'];
        cont: Package['cont'];
    }>;
}
export const brokenPackages = async (payload: PreparationControlBrokenPackagesPayload) =>
    await api.post('/preparationsBrokenPackages', payload);

export interface PreparationControlRemovePalletPayload {
    customerOrderId: CustomerOrder['id'];
    palletId: Pallet['id'];
}
export const removePallet = async (payload: PreparationControlRemovePalletPayload) => {
    await api.post('/preparationsRemovePallet', payload);
};
