import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { listControlByCustomerOrderId } from '../../store/actions/preparations';
import { details as customerOrderDetails, getCustomerOrderStateById } from '../../store/actions/customerOrders';

import { useActions, useArrowNavScope, useShortcutScope } from '../../hooks';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ArrowNavItem from '../../components/ArrowNavItem';
import SuccessModal from '../../components/SuccessModal';
import ControlPalletAddPackageModal from './ControlPalletAddPackageModal';
import ControlPrintModal from './ControlPrintModal';
import { ControlPreparationStatus, CustomerOrderStatus } from '../../store/api/apiTypes';
import ControlPalletRemovePackageModal from './ControlPalletRemovePackageModal';
import ConfirmRemovePalletModal from './ConfirmRemovePalletModal';
import { show } from '@ebay/nice-modal-react';

let timeout: number;

interface ControlPalletActionsModalProps extends CustomModalProps {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    onRefresh: () => void;
}

const ControlPalletActionsModal: FC<ControlPalletActionsModalProps> = ({
    visible,
    onCancel,
    setIsVisible,
    onRefresh,
}) => {
    const { customerOrderId, palletId } = useParams<{ customerOrderId: string; palletId?: string }>();
    const [successMessage, setSuccessMessage] = useState<string>();
    const [isAddPackageModalVisible, setIsAddPackageModalVisible] = useState(false);
    const [isRemovePackageModalVisible, setIsRemovePackageModalVisible] = useState(false);
    const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [isMultiplePrinting, setIsMultiplePrinting] = useState(false);
    const [fetchCustomerOrder, listPreparationControls] = useActions([
        customerOrderDetails.trigger,
        listControlByCustomerOrderId.trigger,
    ]);
    const shortcutScope = useMemo(() => {
        if (isAddPackageModalVisible) {
            return 'ControlPalletAddPackageModal';
        } else if (isRemovePackageModalVisible) {
            return 'ControlPalletRemovePackageModal';
        } else if (isPrintModalVisible) {
            return 'ControlPrintModal';
        } else {
            return 'ControlPalletActionsModal';
        }
    }, [isAddPackageModalVisible, isPrintModalVisible, isRemovePackageModalVisible]);

    const scopeEnabled = useMemo(() => {
        if (shortcutScope === 'ControlPalletAddPackageModal') {
            return isAddPackageModalVisible;
        } else if (shortcutScope === 'ControlPalletRemovePackageModal') {
            return isRemovePackageModalVisible;
        } else if (shortcutScope === 'ControlPrintModal') {
            return isPrintModalVisible;
        } else {
            return visible;
        }
    }, [isAddPackageModalVisible, isPrintModalVisible, isRemovePackageModalVisible, shortcutScope, visible]);

    useShortcutScope(shortcutScope, !scopeEnabled);
    useArrowNavScope(shortcutScope, !scopeEnabled);

    const customerOrderState = useSelector(getCustomerOrderStateById(parseInt(customerOrderId, 10)));
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const listControl = useCallback(() => {
        listPreparationControls({
            customerOrderId,
            controlStatus: [
                ControlPreparationStatus.toBeProcessed,
                ControlPreparationStatus.inProgress,
                ControlPreparationStatus.completed,
                ControlPreparationStatus.toRegularize,
            ],
            pageSize: 1337,
        });
    }, [listPreparationControls, customerOrderId]);
    const onCancelAddPackageModal = useCallback(() => {
        setIsAddPackageModalVisible(false);
        listControl();
    }, [listControl]);
    const onCancelRemovePackageModal = useCallback(() => {
        setIsRemovePackageModalVisible(false);
        listControl();
    }, [listControl]);
    const onClickAddPackage = () => {
        setIsVisible(false);
        setIsAddPackageModalVisible(true);
        setSuccessMessage('Colis ajoutés avec succès');
    };
    const onAddPackageFinish = () => {
        setIsAddPackageModalVisible(false);
        setIsPrintModalVisible(true);
    };
    const onClickRemovePackage = () => {
        setIsVisible(false);
        setIsRemovePackageModalVisible(true);
        setSuccessMessage('Colis supprimés avec succès');
    };
    const onRemovePackageFinish = () => {
        setIsRemovePackageModalVisible(false);
        setIsPrintModalVisible(true);
    };
    const onPrintSuccessOrPrintLater = () => {
        onRefresh();
        setIsPrintModalVisible(false);

        // only show success modal for add/remove package actions
        if (!isMultiplePrinting) {
            setIsSuccessModalVisible(true);

            timeout = window.setTimeout(() => {
                if (timeout) {
                    setIsSuccessModalVisible(false);
                }
            }, 2000);
        }
    };
    const onCloseSuccessModal = () => {
        setIsSuccessModalVisible(false);
        setIsMultiplePrinting(false);
    };
    const onClickPrint = () => {
        setIsVisible(false);
        setIsPrintModalVisible(true);
        setIsMultiplePrinting(true);
    };
    const onClosePrintModal = () => {
        setIsPrintModalVisible(false);
    };
    const onClickRemovePallet = () => {
        setIsVisible(false);
        show('confirmPalletModal');
    };
    const actions = [
        customerOrderState.data?.status &&
            ![CustomerOrderStatus.toBeLoaded, CustomerOrderStatus.toRegularize].includes(
                customerOrderState.data?.status
            ) && {
                onClick: onClickAddPackage,
                label: 'Ajouter colis',
            },
        customerOrderState.data?.status &&
            ![CustomerOrderStatus.toBeLoaded, CustomerOrderStatus.toRegularize].includes(
                customerOrderState.data?.status
            ) && {
                onClick: onClickRemovePackage,
                label: 'Supprimer colis',
            },
        customerOrderState.data?.status &&
            ![CustomerOrderStatus.toBeLoaded, CustomerOrderStatus.toRegularize].includes(
                customerOrderState.data?.status
            ) && {
                onClick: onClickRemovePallet,
                label: 'Supprimer la palette',
            },
        {
            onClick: onClickPrint,
            label: 'Impression étiquettes',
        },
    ].filter(Boolean) as Array<{ onClick: () => void; label: string }>;

    useEffect(() => {
        if (!customerOrderState.data) {
            fetchCustomerOrder({ customerOrderId });
            listControl();
        }
    }, [customerOrderId, customerOrderState.data, fetchCustomerOrder, listControl]);

    useEffect(
        () => () => {
            window.clearTimeout(timeout);
        },
        []
    );

    return (
        <>
            <CustomModal
                footer={
                    <>
                        {actions.map(({ onClick, label }, index) => (
                            <ArrowNavItem scope={shortcutScope} key={index}>
                                <ButtonWithShortcut
                                    shortcut={`f${index + 1}`}
                                    type="primary"
                                    shortcutScope={shortcutScope}
                                    onClick={onClick}
                                >
                                    {label}
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        ))}
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                }
                visible={visible}
                onCancel={onCancel}
                title="Actions"
                width={368}
                keyboard={false}
                altTitle
            />
            <ControlPrintModal
                visible={isPrintModalVisible}
                onCancel={onClosePrintModal}
                palletId={parseInt(palletId ?? '-1', 10)}
                onPrintSuccessOrPrintLater={onPrintSuccessOrPrintLater}
                withPrintLater={!isMultiplePrinting}
                withLabelSelect={isMultiplePrinting}
                customerOrderFileNameOfClientMask={customerOrderState.data?.fileNameOfClientMask}
            />
            <ControlPalletAddPackageModal
                visible={isAddPackageModalVisible}
                onCancel={onCancelAddPackageModal}
                onFinish={onAddPackageFinish}
            />
            <ControlPalletRemovePackageModal
                visible={isRemovePackageModalVisible}
                onCancel={onCancelRemovePackageModal}
                onFinish={onRemovePackageFinish}
            />
            <SuccessModal
                visible={isSuccessModalVisible}
                onCancel={onCloseSuccessModal}
                footer={null}
                message={successMessage}
                width={256}
            />
            <ConfirmRemovePalletModal id={'confirmPalletModal'} />
        </>
    );
};

export default ControlPalletActionsModal;
