import { Card, CardProps } from 'antd';
import React, { FC, ReactNode } from 'react';

import '../assets/styles/QuantityCard.less';

import { classNames } from '../helpers';

interface QuantityCardProps extends CardProps {
    isPartial?: boolean; // yellow
    isComplete?: boolean; // blue
    isWarning?: boolean; // orange
    label: ReactNode;
    value: ReactNode;
    onWhite?: boolean;
    outline?: boolean;
}

const QuantityCard: FC<QuantityCardProps> = ({
    isPartial,
    isComplete,
    isWarning,
    label,
    value,
    onWhite,
    outline,
    ...props
}) => (
    <Card
        className={classNames(
            'quantity-card',
            isPartial && 'is-partial',
            isComplete && 'is-complete',
            isWarning && 'is-warning',
            onWhite && 'on-white',
            outline && 'outline'
        )}
        bordered={false}
        {...props}
    >
        <p>
            <span className="quantity-card-value">{value}</span>
            {!outline && <span className="quantity-card-label">{label}</span>}
        </p>
        {outline && (
            <p>
                <span className="quantity-card-label">{label}</span>
            </p>
        )}
    </Card>
);

export default QuantityCard;
