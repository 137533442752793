import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Input, InputProps, message } from 'antd';
import { useSelector } from 'react-redux';

import { getPalletStoreState, store } from '../../store/actions/pallets';
import {
    getResupplyOrderDetailsStateById,
    getResupplyOrderUpdateState,
    update,
    details,
    collectPallet,
} from '../../store/actions/resupplyOrders';
import {
    MovementHistoryType,
    Pallet,
    ResupplyOrder,
    ResupplyOrderStatus,
    ResupplyOrderType,
} from '../../store/api/apiTypes';

import { useActions, useArrowNavScope, useIsMounted, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { isZebra } from '../../helpers/enterprise-browser';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import SuccessMessage from '../../components/SuccessMessage';
import { translatePlaceZoneType } from '../../helpers/i18n';

const shortcutScope = 'ResupplyOrderDeliveryModal';

interface ResupplyOrderDeliveryModalProps extends CustomModalProps {
    resupplyOrder?: ResupplyOrder;
    palletId?: Pallet['id'];
}

const ResupplyOrderDeliveryModal: FC<ResupplyOrderDeliveryModalProps> = ({
    visible,
    onCancel,
    resupplyOrder,
    palletId,
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [storePallet, updateResupplyOrder, fetchOrder, resetCollectPallet] = useActions([
        store.trigger,
        update.trigger,
        details.trigger,
        collectPallet.reset,
    ]);
    const [isValidating, setIsValidating] = useState(false);
    const [reference, setReference] = useState<string | undefined>();
    const [success, setSuccess] = useState(false);
    const storePalletState = useSelector(getPalletStoreState);
    const updateResupplyOrderState = useSelector(getResupplyOrderUpdateState);
    const resupplyOrderState = useSelector(getResupplyOrderDetailsStateById(resupplyOrder?.id ?? -1));
    const previous = usePrevious({ storePalletState, updateResupplyOrderState, resupplyOrderState });
    const inputRef = useRef<Input | null>(null);
    const isMounted = useIsMounted();
    const onSubmit = () => {
        storePallet({
            palletId,
            reference,
            type:
                resupplyOrder?.type === ResupplyOrderType.direct
                    ? MovementHistoryType.direct
                    : MovementHistoryType.resupply,
        });
    };
    const onChangeField: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                storePallet({
                    palletId,
                    barcode: barCode.data,
                    type:
                        resupplyOrder?.type === ResupplyOrderType.direct
                            ? MovementHistoryType.direct
                            : MovementHistoryType.resupply,
                });
            }
        },
        {
            deps: [storePallet, palletId, resupplyOrder?.type],
            disable: !visible,
        }
    );

    useEffect(() => {
        if (!visible) {
            setReference(undefined);
            setSuccess(false);
            if (inputRef.current) {
                inputRef.current.blur();
            }
        } else {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [visible, setReference, setSuccess]);

    // after store pallet request, set the order to completed if its type is resupply, for direct type,
    // fetch the order again to check if the required quantity is met
    useEffect(() => {
        if (previous?.storePalletState.loading && !storePalletState.loading) {
            if (storePalletState.error) {
                message.error('Une erreur est survenue lors du rangement de la palette');
            } else {
                resetCollectPallet();
                if (resupplyOrder?.type === ResupplyOrderType.resupply) {
                    updateResupplyOrder({ id: resupplyOrder?.id, status: ResupplyOrderStatus.completed });
                } else if (resupplyOrder?.type === ResupplyOrderType.direct) {
                    setIsValidating(true);
                }
            }
        }
    }, [
        previous?.storePalletState,
        storePalletState.loading,
        storePalletState.error,
        fetchOrder,
        resupplyOrder?.id,
        resupplyOrder?.type,
        updateResupplyOrder,
        resetCollectPallet,
    ]);

    useEffect(() => {
        if (isValidating) {
            fetchOrder({ resupplyOrderId: resupplyOrder?.id });
        }
    }, [isValidating, fetchOrder, resupplyOrder?.id]);

    useEffect(() => {
        if (visible && isValidating && previous?.resupplyOrderState.loading && !resupplyOrderState.loading) {
            setIsValidating(false);
            if ((resupplyOrderState?.data?.quantity ?? 0) >= (resupplyOrderState?.data?.quantityToBeTransferred ?? 0)) {
                updateResupplyOrder({ id: resupplyOrder?.id, status: ResupplyOrderStatus.completed });
            } else {
                setSuccess(true);
            }
        }
    }, [
        visible,
        isValidating,
        previous?.resupplyOrderState.loading,
        resupplyOrder?.id,
        resupplyOrderState?.data?.quantity,
        resupplyOrderState?.data?.quantityToBeTransferred,
        resupplyOrderState.loading,
        updateResupplyOrder,
    ]);

    useEffect(() => {
        if (visible && previous?.updateResupplyOrderState.loading && !updateResupplyOrderState.loading) {
            setIsValidating(false);
            if (updateResupplyOrderState.error) {
                message.error("Une erreur est survenue lors de la clôture de l'OR");
            } else {
                setSuccess(true);
            }
        }
    }, [
        visible,
        previous?.updateResupplyOrderState,
        updateResupplyOrderState.loading,
        updateResupplyOrderState.error,
        setSuccess,
        onClose,
    ]);

    useEffect(() => {
        let timeout: number;

        if (success) {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onClose();
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [isMounted, onClose, success]);

    return (
        <CustomModal
            footer={
                success ? null : (
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onSubmit}
                            loading={storePalletState.loading}
                        >
                            Valider la livraison
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={!success && 'Livraison'}
            width={success ? 232 : 368}
            keyboard={false}
            maskClosable={false}
            closable={false}
            altTitle
        >
            {success ? (
                <SuccessMessage
                    message={
                        (resupplyOrder?.quantity ?? 0) < (resupplyOrder?.quantityToBeTransferred ?? 0)
                            ? 'Colis livrés avec succès'
                            : resupplyOrder?.type === ResupplyOrderType.resupply
                            ? 'Ordre terminé avec succès.'
                            : 'Ordre direct terminé avec succès'
                    }
                />
            ) : (
                <>
                    <div className="centered-description mb-24">
                        <div>
                            <p>Livraison</p>
                            <p>
                                {resupplyOrder
                                    ? `${translatePlaceZoneType(resupplyOrder.place.zoneType)} - ${
                                          resupplyOrder.place.locker ?? '—'
                                      } ${resupplyOrder.place.spot ?? ''}`
                                    : '—'}
                            </p>
                        </div>
                    </div>
                    <ArrowNavItem scope={shortcutScope}>
                        <Input
                            placeholder={isZebra ? "Flasher l'emplacement" : "Entrer la référence de l'emplacement"}
                            value={reference}
                            onChange={onChangeField}
                            ref={inputRef}
                            autoFocus
                        />
                    </ArrowNavItem>
                </>
            )}
        </CustomModal>
    );
};

export default ResupplyOrderDeliveryModal;
