import { Descriptions, message, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonGrey from '../../../components/ButtonGrey';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import ConfirmModal from '../../../components/ConfirmModal';
import FixedFooter from '../../../components/FixedFooter';
import Header from '../../../components/Header';
import PageHeader from '../../../components/PageHeader';
import QuantityCard from '../../../components/QuantityCard';
import Seo from '../../../components/Seo';
import VerticalDescriptions from '../../../components/VerticalDescriptions';
import { getOperatorName } from '../../../helpers';
import { isZebra } from '../../../helpers/enterprise-browser';
import { formatDate, formatNumber, translateMovementBrokenStatus } from '../../../helpers/i18n';
import { useActions, usePrevious } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import {
    details as detailsBroken,
    getMovementBrokensStateById,
    getMovementBrokenUpdateState,
    update,
} from '../../../store/actions/movementBrokens';
import { MovementBrokenStatus } from '../../../store/api/apiTypes';
import ActionsModal from './ActionsModal';

const BrokenDetails: FC = () => {
    const params = useParams<{ brokenId: string }>();
    const [isActionsModalVisible, setIsActionsModalVisible] = useState<boolean>(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);

    const [fetchBrokenById, updateBroken] = useActions([detailsBroken.trigger, update.trigger]);
    const brokenState = useSelector(getMovementBrokensStateById(Number(params.brokenId)));
    const brokenUpdateState = useSelector(getMovementBrokenUpdateState);
    const previous = usePrevious({ brokenUpdateState });

    useEffect(() => {
        fetchBrokenById({ id: params.brokenId });
    }, [fetchBrokenById, params.brokenId]);

    const { destroyedQuantities = 0, repairedQuantities = 0, discountQuantities = 0 } = brokenState?.data ?? {};

    const progressedCassesNumber = repairedQuantities + destroyedQuantities + discountQuantities;

    const isCompleted = brokenState.data?.status === MovementBrokenStatus.completed;

    const unlockBroken = () => {
        updateBroken({
            id: brokenState.data?.id,
            operatorId: null,
        });
    };

    useEffect(() => {
        if (previous?.brokenUpdateState.loading && !brokenUpdateState.loading) {
            if (brokenUpdateState.error) {
                message.error('Une erreur est survenue lors du débloquage de la casse');
            } else {
                message.success('Casse débloquée avec succès');
                fetchBrokenById({ id: params.brokenId });
                setIsConfirmModalVisible(false);
            }
        }
    }, [
        brokenUpdateState.error,
        brokenUpdateState.loading,
        fetchBrokenById,
        params.brokenId,
        previous?.brokenUpdateState.loading,
    ]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Détail casse" />
            <Header title="Détail casse" backRoute={getRoute(RoutePathName.movementsBrokenList)} enableHomeButton />
            <PageHeader loading={brokenState.loading}>
                <Descriptions size="small" column={3} layout="vertical">
                    <Descriptions.Item label="Date">{formatDate(brokenState.data?.createdAt)}</Descriptions.Item>
                    {isZebra && (
                        <Descriptions.Item label="Heure">
                            {formatDate(brokenState.data?.createdAt, { minute: '2-digit', hour: '2-digit' })}
                        </Descriptions.Item>
                    )}
                    <Descriptions.Item label="Statut">
                        {translateMovementBrokenStatus(brokenState.data?.status)}
                    </Descriptions.Item>
                    {!isZebra && (
                        <Descriptions.Item label="Cariste">
                            {getOperatorName(brokenState.data?.operator)}
                        </Descriptions.Item>
                    )}
                </Descriptions>
            </PageHeader>
            <Space direction="vertical" size="large">
                <VerticalDescriptions
                    items={[{ label: 'Référence colis', value: brokenState.data?.parcel?.reference ?? '—' }]}
                />

                {isZebra ? (
                    <QuantityCard
                        loading={brokenState.loading}
                        isComplete={isCompleted}
                        value={
                            progressedCassesNumber
                                ? `${progressedCassesNumber}/${formatNumber(brokenState.data?.quantity)}`
                                : formatNumber(brokenState.data?.quantity)
                        }
                        label="Colis à traiter"
                    />
                ) : (
                    <QuantityCard
                        loading={brokenState.loading}
                        isComplete={isCompleted}
                        value={(brokenState.data?.quantity ?? 0) - progressedCassesNumber}
                        label="Colis à traiter"
                    />
                )}

                {repairedQuantities ? (
                    <VerticalDescriptions items={[{ label: 'Réparation', value: repairedQuantities ?? '—' }]} />
                ) : null}
                {destroyedQuantities ? (
                    <VerticalDescriptions items={[{ label: 'Destruction', value: destroyedQuantities ?? '—' }]} />
                ) : null}
                {discountQuantities ? (
                    <VerticalDescriptions items={[{ label: 'Envoi soldeur', value: discountQuantities ?? '—' }]} />
                ) : null}
            </Space>
            {brokenState.data && isActionsModalVisible && (
                <ActionsModal
                    visible={isActionsModalVisible}
                    onCancel={setIsActionsModalVisible.bind(null, false)}
                    max={brokenState.data.quantity - progressedCassesNumber}
                    brokenId={brokenState.data.id}
                />
            )}
            <ConfirmModal
                visible={isConfirmModalVisible}
                message="Êtes-vous sûr de vouloir débloquer cette casse maintenant ?"
                okText="Oui"
                cancelText="Non"
                onCancel={setIsConfirmModalVisible.bind(null, false)}
                onOk={unlockBroken}
            />

            {!isCompleted && (isZebra || (!isZebra && brokenState.data?.operator)) && (
                <FixedFooter>
                    <ArrowNavItem>
                        {isZebra ? (
                            <ButtonWithShortcut
                                as={ButtonGrey}
                                onClick={setIsActionsModalVisible.bind(null, true)}
                                shortcut="enter"
                                type="primary"
                                block
                            >
                                Actions
                            </ButtonWithShortcut>
                        ) : (
                            <ButtonWithShortcut
                                onClick={setIsConfirmModalVisible.bind(null, true)}
                                shortcut="enter"
                                type="primary"
                                block
                                loading={brokenUpdateState.loading}
                                ghost
                            >
                                Débloquer
                            </ButtonWithShortcut>
                        )}
                    </ArrowNavItem>
                </FixedFooter>
            )}
        </FixedFooter.Wrapper>
    );
};

export default BrokenDetails;
