import { Descriptions, Spin, Divider, Space, message, Card, Button } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    Pallet,
    PalletStatus,
    Permission,
    PermissionRight,
    PlaceZoneType,
    PurchaseOrderQualityControl,
    ResupplyOrderStatus,
    ResupplyOrderType,
} from '../../store/api/apiTypes';
import {
    details as resupplyOrderDetails,
    update as resupplyOrderUpdate,
    getResupplyOrderDetailsStateById,
    getResupplyOrderPickPalletState,
    detailsPolling as resupplyOrderDetailsPolling,
    getResupplyOrderUpdateState,
} from '../../store/actions/resupplyOrders';
import {
    getPalletChangeResupplyIdState,
    getParcelPalletsListState,
    listByParcel as listPalletsAction,
} from '../../store/actions/pallets';
import { getUser } from '../../store/actions/auth';

import {
    formatDate,
    formatNumber,
    translatePlaceZoneType,
    translateResupplyOrderStatus,
    translateResupplyOrderType,
} from '../../helpers/i18n';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonGrey from '../../components/ButtonGrey';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import FixedFooter from '../../components/FixedFooter';
import Header from '../../components/Header';
import List, { ListColumn } from '../../components/list/List';
import PageHeader from '../../components/PageHeader';
import QuantityCard from '../../components/QuantityCard';
import Seo from '../../components/Seo';
import VerticalDescriptions from '../../components/VerticalDescriptions';
import { useActions, useIsDesktop, useIsMobile, usePrevious } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import { IconWarning } from '../../components/icons';
import TitleBlack from '../../components/TitleBlack';
import ResupplyPalletScanModal from './ResupplyPalletScanModal';
import ResupplyOrderDeliveryModal from './ResupplyOrderDeliveryModal';
import { getOperatorName } from '../../helpers';
import { isZebra } from '../../helpers/enterprise-browser';
import ResupplyOrderUnblockModal from './ResupplyOrderUnblockModal';
import { hasPermission } from '../../helpers/security';
import { show } from '@ebay/nice-modal-react';
import ChangeReservationScanModal from './ChangeReservationScanModal';

const changeReservationScanModalId = 'changeReservationScanModal';

const ResupplyOrderDetails: FC = () => {
    const history = useHistory();
    const isMobile = useIsMobile();
    const isDesktop = useIsDesktop();
    const [selectedPallet, setSelectedPallet] = useState<Pallet | undefined>();
    const [isUnblockModalVisible, setIsUnblockModalVisible] = useState(false);
    const { resupplyOrderId } = useParams<{ resupplyOrderId: string }>();
    const [hasFetchedResupplyOrderOnce, setHasFetchedResupplyOrderOnce] = useState(false);
    const [isOrderModalVisible, setIsOrderModalVisible] = useState(false);
    const [hasClosedDeliveryModalOnce, setHasClosedDeliveryModalOnce] = useState(false);
    const [isDeliveryModalVisible, setIsDeliveryModalVisible] = useState(false);
    const [isWaitingForBackButton, setIsWaitingForBackButton] = useState(false);
    const [fetchResupplyOrder, listPallets, stopResupplyOrderPolling, updateResupplyOrder] = useActions([
        resupplyOrderDetails.trigger,
        listPalletsAction.trigger,
        resupplyOrderDetailsPolling.actions.stopPolling,
        resupplyOrderUpdate.trigger,
    ]);
    const collectPalletState = useSelector(getResupplyOrderPickPalletState);
    const palletListState = useSelector(getParcelPalletsListState);
    const resupplyOrderState = useSelector(getResupplyOrderDetailsStateById(parseInt(resupplyOrderId, 10)));
    const resupplyOrderUpdateState = useSelector(getResupplyOrderUpdateState);
    const changeResupplyIdState = useSelector(getPalletChangeResupplyIdState);
    const previous = usePrevious({
        isDeliveryModalVisible,
        resupplyOrderState,
        resupplyOrderUpdateState,
        changeResupplyIdState,
    });
    const user = useSelector(getUser);
    const canReadDesktop = !isZebra && hasPermission(user, Permission.resupply);
    const canEditDesktop = !isZebra && hasPermission(user, Permission.resupply, PermissionRight.write);
    const isQuantityComplete =
        resupplyOrderState.data?.quantity &&
        resupplyOrderState.data?.quantityToBeTransferred &&
        resupplyOrderState.data.quantity >= resupplyOrderState.data.quantityToBeTransferred;
    const backRoute = getRoute(RoutePathName.resupplyOrderList);
    const hasPendingDelivery = Boolean(resupplyOrderState.data?.inProgressOperationPalletPlace?.pallet?.id);
    const isDirect = resupplyOrderState.data?.type === ResupplyOrderType.direct;
    const onValidatePallet = () => {
        if (selectedPallet) {
            setIsOrderModalVisible(true);
        } else {
            message.error('Veuillez choisir un emplacement');
        }
    };

    const onChangeReservation = () => {
        if (isDirect) {
            if (selectedPallet) {
                show(changeReservationScanModalId, { pallet: selectedPallet });
            } else {
                message.error('Veuillez choisir un emplacement');
            }
        }
    };
    const onClickBackButton = () => {
        if (!isZebra) {
            history.push(backRoute);
        } else if (resupplyOrderState.data?.status === ResupplyOrderStatus.inProgress) {
            setIsWaitingForBackButton(true);
        } else {
            history.push(backRoute);
        }
    };
    const onListRowEnter = (record: Pallet) => {
        setSelectedPallet(record);
        setIsOrderModalVisible(true);
    };
    const onLoadMore = () => {
        listPallets(
            {
                parcelId: resupplyOrderState.data?.parcel.id,
                status: PalletStatus.stored,
                zoneType: PlaceZoneType.mass,
                quality: PurchaseOrderQualityControl.conform,
                sort: 'quantity',
                sortOrder: isDirect ? 'desc' : 'asc',
                resupplyId: isDirect ? resupplyOrderState.data?.id : undefined,
                page: (palletListState.data?.page ?? 0) + 1,
            },
            { loadMore: true }
        );
    };
    const onUnblockSuccess = () => {
        fetchResupplyOrder({ resupplyOrderId });
    };
    const columns: Array<ListColumn<Pallet>> = [
        {
            key: 'place',
            title: 'Emplacement',
            flex: '1 1 40%',
            render: (record) => (
                <>
                    {record.currentPlace?.sector ?? '—'}
                    &nbsp;
                    {record.currentPlace?.locker ?? '—'}
                    &nbsp;
                    {record.currentPlace?.spot ?? '—'}
                </>
            ),
        },
        {
            dataIndex: 'reference',
            title: 'Réf palette',
            flex: '1 1 40%',
        },
        {
            dataIndex: 'quantity',
            title: 'Quantité disponible',
            flex: '1 1 20%',
        },
    ];

    // Reset selected pallet when changing resupplyId
    useEffect(() => {
        if (previous?.changeResupplyIdState.loading && !changeResupplyIdState.loading && !changeResupplyIdState.error) {
            setSelectedPallet(undefined);
        }
    }, [previous?.changeResupplyIdState.loading, changeResupplyIdState.loading, changeResupplyIdState.error]);

    useEffect(() => {
        if (resupplyOrderId) {
            fetchResupplyOrder({ resupplyOrderId }, { poll: isZebra });
        }
    }, [fetchResupplyOrder, resupplyOrderId]);

    useEffect(() => {
        if (previous?.resupplyOrderState.loading && !resupplyOrderState.loading && !resupplyOrderState.error) {
            listPallets({
                parcelId: resupplyOrderState.data?.parcel.id,
                status: PalletStatus.stored,
                zoneType: PlaceZoneType.mass,
                quality: PurchaseOrderQualityControl.conform,
                sort: 'quantity',
                sortOrder: isDirect ? 'desc' : 'asc',
                resupplyId: isDirect ? resupplyOrderState.data?.id : undefined,
            });
            // zebra only : if we have a pending delivery, directly open delivery modal with the corresponding pallet
            if (
                isZebra &&
                !hasClosedDeliveryModalOnce &&
                resupplyOrderState.data?.inProgressOperationPalletPlace?.pallet?.id
            ) {
                setSelectedPallet(resupplyOrderState.data?.inProgressOperationPalletPlace?.pallet);
                setIsDeliveryModalVisible(true);
            }

            setHasFetchedResupplyOrderOnce(true);
        }
    }, [
        previous?.resupplyOrderState,
        resupplyOrderState,
        listPallets,
        hasClosedDeliveryModalOnce,
        user,
        history,
        backRoute,
        isDirect,
    ]);

    useEffect(() => {
        if (previous?.isDeliveryModalVisible && !isDeliveryModalVisible) {
            setHasClosedDeliveryModalOnce(true);
            fetchResupplyOrder({ resupplyOrderId });
            listPallets({
                parcelId: resupplyOrderState.data?.parcel.id,
                status: PalletStatus.stored,
                zoneType: PlaceZoneType.mass,
                quality: PurchaseOrderQualityControl.conform,
                sort: 'quantity',
                sortOrder: isDirect ? 'desc' : 'asc',
                resupplyId: isDirect ? resupplyOrderState.data?.id : undefined,
            });
        }
    }, [
        previous?.isDeliveryModalVisible,
        isDeliveryModalVisible,
        fetchResupplyOrder,
        resupplyOrderId,
        listPallets,
        resupplyOrderState.data?.parcel.id,
        resupplyOrderState.data?.quantityToBeTransferred,
        resupplyOrderState.data?.type,
        resupplyOrderState.data?.id,
        isDirect,
    ]);

    useEffect(() => {
        if (
            !isDeliveryModalVisible &&
            previous?.resupplyOrderUpdateState.loading &&
            !resupplyOrderUpdateState.loading
        ) {
            if (!resupplyOrderUpdateState.error && isWaitingForBackButton) {
                setIsWaitingForBackButton(false);
                history.push(backRoute);
            }
        }
    }, [
        isDeliveryModalVisible,
        previous?.resupplyOrderUpdateState.loading,
        resupplyOrderUpdateState.loading,
        resupplyOrderUpdateState.error,
        isWaitingForBackButton,
        history,
        backRoute,
    ]);

    // reset resupplyOrder when clicking back button and resupplyOrder status is inProgress
    useEffect(() => {
        if (isWaitingForBackButton) {
            updateResupplyOrder({
                id: parseInt(resupplyOrderId, 10),
                status: ResupplyOrderStatus.toBeProcessed,
                operatorId: null,
            });
        }
    }, [isWaitingForBackButton, updateResupplyOrder, resupplyOrderId]);

    // reset resupplyOrder when using browser back button and resupplyOrder status is inProgress
    useEffect(() => {
        return () => {
            if (
                history.action === 'POP' &&
                isZebra &&
                resupplyOrderState.data?.status === ResupplyOrderStatus.inProgress &&
                !resupplyOrderState.loading
            ) {
                updateResupplyOrder({
                    id: parseInt(resupplyOrderId, 10),
                    status: ResupplyOrderStatus.toBeProcessed,
                    operatorId: null,
                });
            }
        };
    });

    // check if user has been unassigned of this resupplyOrder, if true redirect to list
    useEffect(() => {
        if (
            isZebra &&
            user &&
            hasFetchedResupplyOrderOnce &&
            resupplyOrderState.data &&
            (resupplyOrderState.data?.operator?.id !== user.id || !resupplyOrderState.data?.operator)
        ) {
            message.warn('Vous avez été désassigné de cet ordre de réapprovisionnement', 5);
            history.push(backRoute);
        }
    }, [previous?.resupplyOrderState, resupplyOrderState.data, user, history, backRoute, hasFetchedResupplyOrderOnce]);

    useEffect(
        () => () => {
            stopResupplyOrderPolling();
        },
        [stopResupplyOrderPolling]
    );

    return (
        <FixedFooter.Wrapper>
            <Seo title="Détail ordre" />
            <Header
                title="Détail ordre"
                onClickBack={onClickBackButton}
                backButtonLoading={
                    resupplyOrderState.data?.status === ResupplyOrderStatus.inProgress &&
                    resupplyOrderUpdateState.loading
                }
            />
            <PageHeader>
                <Spin spinning={resupplyOrderState.loading}>
                    <Descriptions column={canReadDesktop ? 5 : 4} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="Date">{formatDate(resupplyOrderState.data?.date)}</Descriptions.Item>
                        <Descriptions.Item label="Heure">
                            {formatDate(resupplyOrderState.data?.date, {
                                minute: '2-digit',
                                hour: '2-digit',
                            })}
                        </Descriptions.Item>
                        <Descriptions.Item label="Type">
                            {translateResupplyOrderType(resupplyOrderState.data?.type, true)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Statut">
                            {translateResupplyOrderStatus(resupplyOrderState.data?.status)}
                        </Descriptions.Item>
                        {canReadDesktop && (
                            <Descriptions.Item label="Cariste">
                                {getOperatorName(resupplyOrderState.data?.operator)}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </Spin>
            </PageHeader>
            <Space direction="vertical" size="large">
                <VerticalDescriptions
                    items={[
                        { label: 'Référence colis', value: resupplyOrderState.data?.parcel.reference ?? '—' },
                        {
                            label: 'Livraison',
                            value: resupplyOrderState.data
                                ? `${translatePlaceZoneType(resupplyOrderState.data.place.zoneType)} - ${
                                      resupplyOrderState.data.place.locker ?? '—'
                                  } ${resupplyOrderState.data.place.spot ?? ''}`
                                : '—',
                        },
                    ]}
                />
                {(resupplyOrderState.data?.type === ResupplyOrderType.direct ||
                    (resupplyOrderState.data?.type === ResupplyOrderType.resupply &&
                        resupplyOrderState.data?.status === ResupplyOrderStatus.completed)) && (
                    <QuantityCard
                        label={resupplyOrderState.data?.quantity !== null ? 'Colis livrés' : 'Colis à transférer'}
                        value={
                            resupplyOrderState.data?.type === ResupplyOrderType.direct
                                ? resupplyOrderState.data?.quantity !== null
                                    ? `${formatNumber(resupplyOrderState.data?.quantity)}/${formatNumber(
                                          resupplyOrderState.data?.quantityToBeTransferred
                                      )}`
                                    : formatNumber(resupplyOrderState.data?.quantityToBeTransferred)
                                : formatNumber(resupplyOrderState.data?.quantity)
                        }
                        isComplete={resupplyOrderState.data?.status === ResupplyOrderStatus.completed}
                    />
                )}
            </Space>
            <Divider />
            {resupplyOrderState.data?.status !== ResupplyOrderStatus.completed && (
                <>
                    <List<Pallet>
                        columns={columns}
                        data={palletListState.data?.items}
                        rowKey={(record) => `${record.id}`}
                        onRowFocus={hasPendingDelivery ? undefined : setSelectedPallet}
                        onRowClick={hasPendingDelivery ? undefined : setSelectedPallet}
                        onRowEnterPress={hasPendingDelivery ? undefined : onListRowEnter}
                        isRowSelected={(record) => !!selectedPallet && record.id === selectedPallet?.id}
                        isLoading={palletListState.loading && palletListState.data?.page === undefined}
                        focusable={!hasPendingDelivery}
                        title="Colis en stock"
                        hideWhenEmpty
                    />
                    {palletListState.data && palletListState.data.page < palletListState.data.pageCount - 1 && (
                        <Button
                            onClick={onLoadMore}
                            loading={palletListState.loading}
                            size="small"
                            style={{ margin: '1rem 0', fontSize: 14 }}
                            block
                        >
                            Plus de résultats
                        </Button>
                    )}
                    {palletListState.data?.totalCount === 0 && (
                        <>
                            <TitleBlack>Colis en stock</TitleBlack>
                            <Card>
                                <p style={{ margin: 0, textAlign: 'center', color: '#EA9741' }}>
                                    <IconWarning style={{ fontSize: '1.5rem' }} />
                                    <br />
                                    Il n&rsquo;y a plus de colis disponible en stock
                                </p>
                            </Card>
                        </>
                    )}
                </>
            )}
            {isZebra && resupplyOrderState.data && resupplyOrderState.data.status !== ResupplyOrderStatus.completed && (
                <>
                    {!collectPalletState?.data && (palletListState.data?.totalCount ?? 0) > 0 && !isQuantityComplete && (
                        <>
                            <FixedFooter>
                                <ArrowNavItem>
                                    <ButtonWithShortcut
                                        shortcut="enter"
                                        size={isMobile ? 'middle' : 'large'}
                                        as={ButtonGrey}
                                        onClick={onValidatePallet}
                                        block
                                    >
                                        Prélever sur l&rsquo;emplacement
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                {isDirect && (
                                    <ArrowNavItem>
                                        <ButtonWithShortcut
                                            shortcut="f1"
                                            size={isMobile ? 'middle' : 'large'}
                                            onClick={onChangeReservation}
                                            block
                                        >
                                            Modifier la réservation
                                        </ButtonWithShortcut>
                                    </ArrowNavItem>
                                )}
                            </FixedFooter>
                        </>
                    )}
                    {collectPalletState?.data && (
                        <FixedFooter>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    shortcut="f1"
                                    size={isMobile ? 'middle' : 'large'}
                                    as={ButtonGrey}
                                    onClick={setIsDeliveryModalVisible.bind(null, true)}
                                    block
                                >
                                    Valider la livraison
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </FixedFooter>
                    )}
                </>
            )}
            {canEditDesktop && resupplyOrderState.data?.operator && (
                <FixedFooter>
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            shortcut="f1"
                            onClick={setIsUnblockModalVisible.bind(null, true)}
                            size={!isDesktop ? 'middle' : 'large'}
                            block
                        >
                            Débloquer
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </FixedFooter>
            )}
            <ResupplyOrderUnblockModal
                visible={isUnblockModalVisible}
                onCancel={setIsUnblockModalVisible.bind(null, false)}
                resupplyOrderId={resupplyOrderState.data?.id}
                onSuccess={onUnblockSuccess}
            />
            <ResupplyPalletScanModal
                visible={isOrderModalVisible}
                onCancel={setIsOrderModalVisible.bind(null, false)}
                pallet={selectedPallet}
                resupplyOrder={resupplyOrderState.data}
                onSuccess={setIsDeliveryModalVisible.bind(null, true)}
            />
            <ResupplyOrderDeliveryModal
                visible={isDeliveryModalVisible}
                onCancel={setIsDeliveryModalVisible.bind(null, false)}
                palletId={selectedPallet?.id ?? resupplyOrderState.data?.inProgressOperationPalletPlace?.pallet?.id}
                resupplyOrder={resupplyOrderState.data}
            />
            <ChangeReservationScanModal id={changeReservationScanModalId} />
        </FixedFooter.Wrapper>
    );
};

export default ResupplyOrderDetails;
