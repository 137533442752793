import { Modal, ModalProps } from 'antd';
import React, { FC } from 'react';

import '../assets/styles/CustomModal.less';

import { classNames } from '../helpers';

export interface CustomModalProps extends ModalProps {
    altTitle?: boolean;
    fullScreen?: boolean;
}

const CustomModal: FC<CustomModalProps> = ({ altTitle, className, fullScreen, maskClosable, ...props }) => (
    <Modal
        closable={false}
        maskClosable={maskClosable ?? false}
        centered
        {...props}
        className={classNames(
            'custom-modal',
            fullScreen && 'fullscreen-modal',
            altTitle && 'alt-title',
            className,
            !props.children && 'no-body',
            !props.title && 'no-title'
        )}
    />
);

export default CustomModal;
