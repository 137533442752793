import React, { FC, useCallback, useEffect, useState } from 'react';
import { Input, message, Form, FormProps, Button, Select } from 'antd';
import { useSelector } from 'react-redux';

import {
    getPlaceLaneCreateState,
    getPlaceLaneUpdateState,
    getPlaceLaneDeleteState,
    create as placeLaneCreate,
    update as placeLaneUpdate,
    del as placeLaneDelete,
} from '../../store/actions/placeLanes';
import { PlaceLane, PlaceStore, PlaceStoreType } from '../../store/api/apiTypes';
import { getPlaceSectorsListState, list as placeSectorsList } from '../../store/actions/placeSectors';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useShortcutScope } from '../../hooks';
import SuccessMessage from '../../components/SuccessMessage';
import ButtonRed from '../../components/ButtonRed';
import PlaceStoreSelect from '../../components/PlaceStoreSelect';

const shortcutScope = 'PlaceLaneFormModal';

interface PlaceLaneFormModalProps extends CustomModalProps {
    placeLane?: PlaceLane;
    onSuccess?: () => void;
}

const PlaceLaneFormModal: FC<PlaceLaneFormModalProps> = ({ visible, onCancel, placeLane, onSuccess }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const isEditing = !!placeLane;
    const isMounted = useIsMounted();
    const [form] = Form.useForm();
    const [success, setSuccess] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [createPlaceLane, updatePlaceLane, deletePlaceLane, listPlaceSectors] = useActions([
        placeLaneCreate.trigger,
        placeLaneUpdate.trigger,
        placeLaneDelete.trigger,
        placeSectorsList.trigger,
    ]);
    const placeLaneCreateState = useSelector(getPlaceLaneCreateState);
    const placeLaneUpdateState = useSelector(getPlaceLaneUpdateState);
    const placeLaneDeleteState = useSelector(getPlaceLaneDeleteState);
    const placeSectorsState = useSelector(getPlaceSectorsListState);
    const previous = usePrevious({ placeLaneCreateState, placeLaneUpdateState, placeLaneDeleteState });
    const onSubmit: FormProps['onFinish'] = (values) => {
        const payload = values;

        delete payload.placeStore;

        if (isEditing) {
            updatePlaceLane({
                placeLaneId: placeLane?.id,
                ...payload,
            });
        } else {
            createPlaceLane(payload);
        }
    };
    const onDelete = () => {
        deletePlaceLane({ placeLaneId: placeLane?.id });
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const onChangePlaceStore = (value: PlaceStore) => {
        listPlaceSectors({ placeStoreId: value?.id });
        form.setFieldsValue({ placeSectorId: undefined });
    };

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            setSuccess(false);
        }
    }, [visible, form, setSuccess]);

    useEffect(() => {
        if (previous?.placeLaneCreateState.loading && !placeLaneCreateState.loading) {
            if (placeLaneCreateState.error) {
                if (placeLaneCreateState.error?.status === 409) {
                    message.error("Ce nom est déjà utilisé par une autre l'allée");
                } else {
                    message.error("Une erreur est survenue pendant la création de l'allée");
                }
            } else {
                setSuccess(true);
            }
        }
    }, [placeLaneCreateState.error, placeLaneCreateState.loading, previous?.placeLaneCreateState.loading]);

    useEffect(() => {
        if (previous?.placeLaneUpdateState.loading && !placeLaneUpdateState.loading) {
            if (placeLaneUpdateState.error) {
                if (placeLaneUpdateState.error?.status === 409 && placeLaneUpdateState.error?.data?.placeNotEmpty) {
                    message.error("Cette allée n'est pas vide, vous ne pouvez pas la modifier");
                } else {
                    message.error("Une erreur est survenue pendant la mise à jour de l'allée");
                }
            } else {
                setSuccess(true);
            }
        }
    }, [placeLaneUpdateState.error, placeLaneUpdateState.loading, previous?.placeLaneUpdateState.loading]);

    useEffect(() => {
        if (previous?.placeLaneDeleteState.loading && !placeLaneDeleteState.loading) {
            if (placeLaneDeleteState.error) {
                if (placeLaneDeleteState.error?.status === 400) {
                    message.error("Vous ne pouvez pas supprimer cette allée car elle n'est pas vide");
                } else {
                    message.error("Une erreur est survenue pendant la suppression de l'allée");
                }
            } else {
                setSuccess(true);
            }
        }
    }, [placeLaneDeleteState.error, placeLaneDeleteState.loading, previous?.placeLaneDeleteState.loading]);

    useEffect(() => {
        let timeout: number;

        if (success) {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onClose();
                    onSuccess?.();
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [isMounted, onClose, success, onSuccess]);

    useEffect(() => {
        if (placeLane) {
            form.setFieldsValue(placeLane);
        } else {
            form.resetFields();
        }
    }, [placeLane, form]);

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            setIsDeleting(false);
        }
    }, [visible, form]);

    return (
        <CustomModal
            footer={
                success ? null : isDeleting ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                as={ButtonRed}
                                onClick={onDelete}
                                loading={placeLaneDeleteState.loading}
                            >
                                Oui, supprimer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                disabled={placeLaneDeleteState.loading}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={form.submit}
                                loading={placeLaneCreateState.loading || placeLaneUpdateState.loading}
                                disabled={placeLaneDeleteState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        {isEditing && (
                            <ArrowNavItem scope={shortcutScope}>
                                <Button onClick={setIsDeleting.bind(null, true)} danger>
                                    Supprimer
                                </Button>
                            </ArrowNavItem>
                        )}
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                disabled={placeLaneDeleteState.loading}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={
                !success &&
                (isDeleting
                    ? `Voulez-vous vraiment supprimer cette allée maintenant ?`
                    : isEditing
                    ? 'Éditer'
                    : 'Ajouter une allée')
            }
            width={368}
            keyboard={false}
            altTitle={!isDeleting}
        >
            {success ? (
                <SuccessMessage
                    message={`L'allée a été ${isDeleting ? 'supprimée' : isEditing ? 'éditée' : 'ajoutée'} avec succès`}
                />
            ) : !isDeleting ? (
                <Form form={form} layout="vertical" onFinish={onSubmit} initialValues={placeLane} requiredMark={false}>
                    {!isEditing && (
                        <>
                            <Form.Item name="placeStore" label="Site">
                                <PlaceStoreSelect
                                    placeStoreType={PlaceStoreType.site}
                                    allowClear={false}
                                    onChange={onChangePlaceStore}
                                    withArrowNav
                                />
                            </Form.Item>
                            <Form.Item name="placeSectorId" label="Secteur">
                                <ArrowNavItem>
                                    <Select
                                        placeholder="Filtrer par secteur"
                                        filterOption={false}
                                        style={{ width: '100%' }}
                                        allowClear
                                        showArrow
                                    >
                                        {placeSectorsState.data?.items?.map((option) => (
                                            <Select.Option value={option.id} key={option.id}>
                                                {option.sector}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </ArrowNavItem>
                            </Form.Item>
                        </>
                    )}
                    <Form.Item name="lane" label="Allée" normalize={(value?: string) => `${value ?? ''}`.toUpperCase()}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir un nom" />
                        </ArrowNavItem>
                    </Form.Item>
                </Form>
            ) : null}
        </CustomModal>
    );
};

export default PlaceLaneFormModal;
