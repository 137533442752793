import { MainReducerState, RequestState } from '../reducers';
import { Pallet, PalletSize, Parcel, Place, ReturnZoneType } from '../api/apiTypes';
import { requestInitialState, requestReducer } from '../helpers';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import * as apiCall from '../api/customerReturn';

export interface CustomerReturnState {
    create: RequestState<Pallet>;
}

const initialState: CustomerReturnState = {
    create: { ...requestInitialState },
};

export interface CustomerReturnCreationPayload {
    parcelId: Parcel['id'];
    placeReference?: Place['reference'];
    placeBarcode?: Place['barcode'];
    palletReference?: Pallet['reference'];
    palletBarcode?: Pallet['barcode'];
    palletSize?: PalletSize;
    index?: number;
    returnZoneType: ReturnZoneType;
    quantity: number;
}

export const create = new EzeeAsyncAction<CustomerReturnState['create'], CustomerReturnCreationPayload, Pallet>(
    'customerReturn/create',
    initialState.create,
    requestReducer<CustomerReturnState['create'], Pallet>(initialState.create)
);

export const customerReturnReducer = combineReducers<CustomerReturnState>({
    create: create.reducer,
});

export function* customerReturnSaga() {
    yield takeLatest(create.type.trigger, simpleAsyncSaga(apiCall.create, create));
}

export const getCustomerReturnCreateState = (state: MainReducerState) => state.customerReturn.create;
