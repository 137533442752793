import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Input, message, Spin } from 'antd';

import { Place, PlaceZoneType } from '../../store/api/apiTypes';
import { update as updatePalletAction, getPalletUpdateState } from '../../store/actions/pallets';
import { list as listPlacesAction, getPlacesListState } from '../../store/actions/places';

import { getRoute, RoutePathName } from '../../routes';
import FixedFooter from '../../components/FixedFooter';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonGrey from '../../components/ButtonGrey';
import PageHeader from '../../components/PageHeader';
import { IconEnter } from '../../components/icons';
import List, { ListColumn } from '../../components/list/List';
import { useActions, useIsMobile, usePrevious } from '../../hooks';
import { formatNumber } from '../../helpers/i18n';
interface ReceptionPlaceEditSearchProps {
    reservedPlaceId?: Place['id'];
}

const ReceptionPalletPlaceEditSearch: FC<ReceptionPlaceEditSearchProps> = ({ reservedPlaceId }) => {
    const isMobile = useIsMobile();
    const history = useHistory();
    const location = useLocation();
    const { palletId, receptionId } = useParams<{ palletId: string; receptionId: string }>();
    const [search, setSearch] = useState<string | undefined>();
    const [selectedPlace, setSelectedPlace] = useState<Place | undefined>();
    const { from } = useLocation<{ from: string }>().state || {};
    const [updatePallet, fetchPlaces] = useActions([updatePalletAction.trigger, listPlacesAction.trigger]);
    const placesListState = useSelector(getPlacesListState);
    const palletUpdateState = useSelector(getPalletUpdateState);
    const previous = usePrevious({ palletUpdateState });
    const onValidatePlace = () => {
        updatePallet({
            id: palletId,
            reservedPlaceId: selectedPlace?.id,
            storageIndex: selectedPlace?.index,
        });
    };
    const onListRowEnter = (record: Place) => {
        setSelectedPlace(record);
    };
    const backRoute = getRoute(RoutePathName.receptionPalletPlaceEdit, {
        palletId,
        receptionId,
    });
    const columns: Array<ListColumn<Place>> = [
        {
            dataIndex: 'sector',
            title: 'Secteur',
            flex: '1 1 33.33333%',
            cellStyle: { fontSize: 20 },
        },
        {
            key: 'locker',
            title: 'Casier',
            flex: '1 1 33.33333%',
            render: ({ locker }) => locker ?? '—',
            cellStyle: { fontSize: 20 },
        },
        {
            dataIndex: 'spot',
            title: 'Emplacement',
            flex: '1 1 33.33333%',
            cellStyle: { fontSize: 20 },
        },
    ];

    useEffect(() => {
        fetchPlaces({
            ...(search ? { search } : {}),
            zoneType: PlaceZoneType.mass,
            palletId,
            excludePlace: reservedPlaceId,
        });
    }, [fetchPlaces, search, palletId, reservedPlaceId]);

    // after updating pallet navigate back to previous page
    useEffect(() => {
        if (previous?.palletUpdateState.loading && !palletUpdateState.loading) {
            if (palletUpdateState.error) {
                message.error('Erreur lors de la sauvegarde de la palette');
            } else {
                history.push(from, { fromEditPlace: true });
            }
        }
    }, [
        previous?.palletUpdateState.loading,
        palletUpdateState.loading,
        palletUpdateState.error,
        fetchPlaces,
        history,
        from,
        palletId,
    ]);

    return (
        <>
            <Seo title="Rechercher" />
            <Header title="Rechercher" backRoute={{ pathname: backRoute, state: location.state }} />
            <PageHeader>
                <Input.Search
                    placeholder="Saisissez un emplacement"
                    enterButton={placesListState.loading ? <Spin /> : <IconEnter />}
                    onSearch={setSearch}
                    className="reception-place-edit-search"
                    allowClear
                />
            </PageHeader>
            <List<Place>
                columns={columns}
                data={placesListState.data?.items}
                isLoading={placesListState.loading}
                rowKey={(record) => `${record.id}`}
                title={
                    !placesListState.loading && !!search
                        ? `${formatNumber(placesListState.data?.totalCount)} emplacement${
                              placesListState.data?.totalCount !== 1 ? 's' : ''
                          } trouvé${placesListState.data?.totalCount !== 1 ? 's' : ''} pour « ${search} »`
                        : 'Emplacements disponibles'
                }
                onRowFocus={setSelectedPlace}
                onRowClick={setSelectedPlace}
                onRowEnterPress={onListRowEnter}
                isRowSelected={(record) =>
                    selectedPlace ? record.id === selectedPlace?.id : record.id === reservedPlaceId
                }
                style={{ marginBottom: 48 }}
            />
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        shortcut="enter"
                        onClick={onValidatePlace}
                        loading={palletUpdateState.loading}
                        as={ButtonGrey}
                        size={isMobile ? 'middle' : 'large'}
                        block
                    >
                        Valider l&rsquo;emplacement
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </>
    );
};

export default ReceptionPalletPlaceEditSearch;
