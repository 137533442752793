import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 24">
        <path
            fill="currentColor"
            fillRule="nonzero"
            d="M.251.27A.813.813 0 011.382.194l.081.076L11.75 11.347a.976.976 0 01.071 1.219l-.071.087L1.463 23.73a.815.815 0 01-1.212 0A.976.976 0 01.18 22.51l.071-.087L9.93 12 .251 1.576A.976.976 0 01.18.357L.25.27z"
        />
    </svg>
);

const IconAngleRight: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-angle-right${props.className ? ` ${props.className}` : ''}`} />
);

export default IconAngleRight;
