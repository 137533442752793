import React, { FC, useCallback, useEffect, useState } from 'react';
import { PaginationProps } from 'antd';
import { useSelector } from 'react-redux';

import { PlatformReasonCode } from '../../../store/api/apiTypes';
import { getPlatformReasonCodesListState, list } from '../../../store/actions/platformReasonCodes';

import { useActions } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import useQueryParams from '../../../hooks/queryParams';
import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import Seo from '../../../components/Seo';
import FixedFooter from '../../../components/FixedFooter';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CodeFormModal from './CodeFormModal';

const ReasonCodes: FC = () => {
    const [queryParams, setQueryParams] = useQueryParams('reasonCodes');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const [isFormModalVisible, setIsFormModalVisible] = useState(false);
    const [reasonToEdit, setReasonToEdit] = useState<PlatformReasonCode>();
    const fetchReasonCodes = useActions(list.trigger);
    const reasonCodesListState = useSelector(getPlatformReasonCodesListState);
    const onClickPlatformReasonCode = (record: PlatformReasonCode) => {
        setReasonToEdit(record);
        setIsFormModalVisible(true);
    };
    const onClickAdd = () => {
        setReasonToEdit(undefined);
        setIsFormModalVisible(true);
    };
    const onCloseFormModal = useCallback(() => {
        setIsFormModalVisible(false);
    }, []);
    const onFormSuccess = useCallback(() => {
        fetchReasonCodes({
            sort: 'updateAt',
            sortOrder: 'desc',
            page,
        });
    }, [fetchReasonCodes, page]);
    const onPaginationChange: PaginationProps['onChange'] = (p) => {
        setQueryParams({
            page: p ? p - 1 : 0,
        });
    };
    const columns: Array<ListColumn<PlatformReasonCode>> = [
        {
            dataIndex: 'reference',
            title: 'Code motif',
            flex: '1 1 20%',
        },
        {
            dataIndex: 'label',
            title: 'Libellé',
            flex: '1 1 80%',
        },
    ];

    useEffect(() => {
        fetchReasonCodes({
            sort: 'updateAt',
            sortOrder: 'desc',
            page,
        });
    }, [fetchReasonCodes, page]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Codes motifs (Modification de livraison)" />
            <Header
                title="Codes motifs (Modification de livraison)"
                backRoute={getRoute(RoutePathName.platformSettings)}
            />
            <List<PlatformReasonCode>
                columns={columns}
                data={reasonCodesListState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowEnterPress={onClickPlatformReasonCode}
                onRowClick={onClickPlatformReasonCode}
                isLoading={reasonCodesListState.loading}
                style={{ marginTop: '1.5rem' }}
                pagination={{
                    total: reasonCodesListState.data?.totalCount,
                    pageSize: reasonCodesListState.data?.pageSize,
                    hideOnSinglePage: true,
                    current: page + 1,
                    onChange: onPaginationChange,
                }}
            />
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut onClick={onClickAdd} shortcut="1" type="primary" ghost block>
                        Ajouter un nouveau code
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
            <CodeFormModal
                visible={isFormModalVisible}
                onCancel={onCloseFormModal}
                onSuccess={onFormSuccess}
                reasonCode={reasonToEdit}
                isReason
            />
        </FixedFooter.Wrapper>
    );
};

export default ReasonCodes;
