import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, message, Select, Spin } from 'antd';

import { getPalletPrintState, print as printPalletAction } from '../../store/actions/pallets';
import { control, getControlOrderControlState } from '../../store/actions/preparationControl';
import { CustomerOrder, Pallet, Printer, StickerType, WMSMode } from '../../store/api/apiTypes';

import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import PrintersSelect from '../../components/PrintersSelect';
import { translateStickerType } from '../../helpers/i18n';
import { getDefaultPrinterState } from '../../store/actions/printers';

const shortcutScope = 'ControlPrintModal';
let timeout: number;

interface ControlPrintModalProps extends CustomModalProps {
    onPrintSuccessOrPrintLater: () => void;
    withLabelSelect?: boolean;
    withPrintLater?: boolean;
    origin?: 'scan' | 'transfer' | 'vrac';
    palletId?: Pallet['id'];
    customerOrderFileNameOfClientMask?: CustomerOrder['fileNameOfClientMask'];
}

const ControlPrintModal: FC<ControlPrintModalProps> = ({
    visible,
    onCancel,
    withLabelSelect,
    withPrintLater = true,
    onPrintSuccessOrPrintLater,
    origin,
    palletId,
    customerOrderFileNameOfClientMask,
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const defaultPrinterState = useSelector(getDefaultPrinterState);
    const [stickerType, setStickerType] = useState<StickerType>();
    const [printer, setPrinter] = useState<Printer | undefined>(defaultPrinterState.printer);
    const [isPrinting, setIsPrinting] = useState(false);
    const [print, resetPrintData] = useActions([printPalletAction.trigger, control.actions.resetPrint]);
    const { transferPalletIds, controlPalletId, vracPalletId } = useSelector(getControlOrderControlState).print;
    const printState = useSelector(getPalletPrintState);
    const previous = usePrevious({ printState });
    const onValidate = () => {
        if (printer) {
            const payload = {
                printerId: printer.id,
                stickerType: stickerType?.length ? stickerType : undefined,
                mode: WMSMode.preparation,
            };
            switch (origin) {
                case 'scan':
                    print({ palletId: controlPalletId, ...payload });
                    break;

                case 'transfer':
                    print({ palletIds: transferPalletIds, ...payload });
                    break;

                case 'vrac':
                    print({ palletId: vracPalletId, ...payload });
                    break;

                // use palletId provided in props (used in pallet actions modal)
                default:
                    print({ palletId, ...payload });
                    break;
            }
        }
    };
    const onChangeLabel = (value: StickerType) => {
        setStickerType(value);
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    useEffect(() => {
        if (!visible) {
            setIsPrinting(false);
            setStickerType(undefined);
        }
    }, [visible]);

    useEffect(() => {
        if (previous?.printState.loading && !printState.loading) {
            if (printState.error) {
                message.error("Erreur lors de l'envoi à l'impression");
            } else {
                setIsPrinting(true);
                resetPrintData();
                timeout = window.setTimeout(() => {
                    if (timeout) {
                        onPrintSuccessOrPrintLater();
                    }
                }, 2000);
            }
        }
    }, [
        previous?.printState.loading,
        printState.loading,
        printState.error,
        setIsPrinting,
        resetPrintData,
        onPrintSuccessOrPrintLater,
    ]);

    useEffect(
        () => () => {
            window.clearTimeout(timeout);
        },
        []
    );

    return (
        <CustomModal
            footer={
                !isPrinting && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                type="primary"
                                shortcut="enter"
                                shortcutScope={shortcutScope}
                                shortcutOptions={{ enableOnTags: ['INPUT'], enabled: !!printer }}
                                onClick={onValidate}
                                disabled={!printer}
                                loading={printState.loading}
                            >
                                {withPrintLater ? 'Imprimer maintenant' : 'Imprimer'}
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        {withPrintLater && (
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="f1"
                                    shortcutScope={shortcutScope}
                                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                    type="primary"
                                    onClick={onPrintSuccessOrPrintLater}
                                    ghost
                                >
                                    Imprimer plus tard
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        )}
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                {withPrintLater ? 'Retour' : 'Annuler'}
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={!isPrinting && 'Impression des étiquettes'}
            width={368}
            transitionName=""
            maskTransitionName=""
            altTitle
            centered
        >
            {isPrinting ? (
                <div style={{ textAlign: 'center' }}>
                    <p>Impression en cours...</p>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    {withLabelSelect && (
                        <Form.Item label="Étiquettes" style={{ margin: 0 }} labelCol={{ span: 24 }}>
                            <Select
                                placeholder="Choisir une étiquette"
                                filterOption={false}
                                style={{ width: '100%' }}
                                onChange={onChangeLabel}
                                defaultActiveFirstOption
                                allowClear
                                showArrow
                            >
                                {Object.values(StickerType)
                                    .filter((stickerTypeValue) =>
                                        customerOrderFileNameOfClientMask
                                            ? true
                                            : stickerTypeValue !== StickerType.clientStickers
                                    )
                                    .map((stickerTypeValue) => (
                                        <Select.Option key={stickerTypeValue} value={stickerTypeValue}>
                                            {translateStickerType(stickerTypeValue)}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    )}
                    <Form.Item label="Imprimante" style={{ margin: 0 }} labelCol={{ span: 24 }}>
                        <PrintersSelect onChange={setPrinter} withArrowNav arrowNavScope={shortcutScope} />
                    </Form.Item>
                </>
            )}
        </CustomModal>
    );
};

export default ControlPrintModal;
