import React, { FC, useState } from 'react';

import BasicList from '../../components/BasicList';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ButtonLink from '../../components/ButtonLink';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import { getRoute, RoutePathName } from '../../routes';
import BackToWorkShopCreateModal from './backToWorkShop/BackToWorkShopCreateModals';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/actions/auth';
import { hasPermission } from '../../helpers/security';
import { Permission, PermissionRight } from '../../store/api/apiTypes';
const VariousOutputsMenu: FC = () => {
    const [isBackToWorkShopModalVisible, setIsBackToWorkShopModalVisible] = useState<boolean>(false);
    const user = useSelector(getUser);

    const canEditBackToWorkshop = hasPermission(user, Permission.backToWorkshop, PermissionRight.write);
    const openWorkShopModal = () => {
        if (canEditBackToWorkshop) {
            setIsBackToWorkShopModalVisible(true);
        }
    };
    const closeWorkShopModal = () => {
        setIsBackToWorkShopModalVisible(false);
    };

    return (
        <>
            <Seo title="Sorties diverses" />
            <Header title="Sorties diverses" backRoute={getRoute(RoutePathName.movementsStockMenu)} enableHomeButton />
            <BackToWorkShopCreateModal visible={isBackToWorkShopModalVisible} onCancel={closeWorkShopModal} />
            <nav className="menu">
                <BasicList>
                    {canEditBackToWorkshop ? (
                        <li>
                            <ArrowNavItem>
                                <ButtonWithShortcut shortcut="1" onClick={openWorkShopModal} block>
                                    Retour atelier
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </li>
                    ) : null}
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="2"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.movementsBrokenList)}
                                block
                            >
                                casse
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                </BasicList>
            </nav>
        </>
    );
};

export default VariousOutputsMenu;
