import React, { FC, useCallback } from 'react';
import ButtonWithShortcut from './ButtonWithShortcut';
import CustomModal, { CustomModalProps } from './CustomModal';
import ArrowNavItem from './ArrowNavItem';
import { useArrowNavScope, useShortcutScope } from '../hooks';
import Text from 'antd/lib/typography/Text';
import { Col, Row, Spin, Typography } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

const shortcutScope = 'ConfirmModal';

interface ConfirmModalProps extends CustomModalProps {
    message?: string;
    hasIcon?: boolean;
    loading?: boolean;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
    visible,
    onCancel,
    onOk,
    title,
    message,
    okText,
    cancelText,
    hasIcon,
    loading,
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    const onConfirm = useCallback(() => {
        if (typeof onOk === 'function') {
            onOk({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onOk]);

    return (
        <CustomModal
            visible={visible}
            title={title ?? ''}
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            altTitle
            footer={
                !loading && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="1"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onConfirm}
                                block
                            >
                                {okText}
                            </ButtonWithShortcut>
                        </ArrowNavItem>

                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="2"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                block
                            >
                                {cancelText}
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
        >
            <Row justify="center">
                <Col xs={16} className="text-center">
                    {loading ? (
                        <Spin spinning={loading} size="large" />
                    ) : (
                        <>
                            {hasIcon && (
                                <Typography.Title level={4} style={{ fontSize: '35px' }} type="warning">
                                    <WarningOutlined />
                                </Typography.Title>
                            )}
                            <Text>{message}</Text>
                        </>
                    )}
                </Col>
            </Row>
        </CustomModal>
    );
};

export default ConfirmModal;
