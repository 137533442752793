import { combineReducers } from 'redux';
import { call, delay, put, race, take, takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { CustomerOrder } from '../api/apiTypes';
import { ListResponse } from '../api';

// Controlers
import {
    CustomerOrderListPayload,
    list as listApiCall,
    details as detailsApiCall,
    CustomerOrderUpdatePayload,
    update as updateApiCall,
    CustomerOrderDetailsPayload,
    close as closeApiCall,
    CustomerOrderClosePayload,
    closeDetails as closeDetailsApiCall,
    CustomerOrderCloseDetailsPayload,
} from '../api/customerOrders';
import { DataAction, EzeeAction } from '../helpers/EzeeAction';

// States
export interface CustomerOrdersState {
    list: RequestState<ListResponse<CustomerOrder>>;
    update: RequestState<CustomerOrder>;
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: CustomerOrder | undefined;
        };
    };
    closeDetails: RequestState<CustomerOrder>;
    close: RequestState<CustomerOrder>;
}

const initialState: CustomerOrdersState = {
    list: { ...requestInitialState },
    update: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
    closeDetails: { ...requestInitialState },
    close: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<
    CustomerOrdersState['list'],
    CustomerOrderListPayload,
    ListResponse<CustomerOrder>
>(
    'customerOrders/list',
    initialState.list,
    requestReducer<CustomerOrdersState['list'], ListResponse<CustomerOrder>>(initialState.list)
);

export const update = new EzeeAsyncAction<CustomerOrdersState['update'], CustomerOrderUpdatePayload, CustomerOrder>(
    'customerOrders/update',
    initialState.update,
    requestReducer<CustomerOrdersState['update'], CustomerOrder>(initialState.update)
);

export const details = new EzeeAsyncAction<CustomerOrdersState['details'], CustomerOrderDetailsPayload, CustomerOrder>(
    'customerOrders/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

export const detailsPolling = new EzeeAction(
    'customerOrders/detailsPolling',
    {},
    {
        stopPolling: () => ({}),
        startPolling: () => ({}),
    }
);

export const closeDetails = new EzeeAsyncAction<
    CustomerOrdersState['closeDetails'],
    CustomerOrderCloseDetailsPayload,
    CustomerOrder
>(
    'customerOrders/closeDetails',
    initialState.closeDetails,
    requestReducer<CustomerOrdersState['closeDetails'], CustomerOrder>(initialState.closeDetails)
);

export const close = new EzeeAsyncAction<CustomerOrdersState['close'], CustomerOrderClosePayload, CustomerOrder>(
    'customerOrders/close',
    initialState.close,
    requestReducer<CustomerOrdersState['close'], CustomerOrder>(initialState.close)
);

// Reducer
export const customerOrdersReducer = combineReducers<CustomerOrdersState>({
    list: list.reducer,
    update: update.reducer,
    details: details.reducer,
    closeDetails: closeDetails.reducer,
    close: close.reducer,
});

// Saga
export function* customerOrdersSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(details.type.trigger, detailsSaga);
    yield takeLatest(closeDetails.type.trigger, simpleAsyncSaga(closeDetailsApiCall, closeDetails));
    yield takeLatest(close.type.trigger, simpleAsyncSaga(closeApiCall, close));
}

function* detailsSaga(actionData: DataAction<CustomerOrderDetailsPayload>) {
    if (actionData.meta?.poll) {
        yield put(detailsPolling.actions.startPolling());
        yield race([call(detailsPollTask, actionData), take(detailsPolling.actions.stopPolling().type)]);
    } else {
        try {
            const response = yield call(detailsApiCall, actionData.payload);

            return yield put(details.success(response));
        } catch (error) {
            return yield put(details.failure(error));
        }
    }
}

/* Worker Function */
function* detailsPollTask(action: DataAction<CustomerOrderDetailsPayload>) {
    while (true) {
        try {
            const response = yield call(detailsApiCall, action.payload);

            yield put(details.success(response));
            yield delay(5000);
        } catch (error) {
            yield put(details.failure(error));
            yield delay(10000);
        }
    }
}

// Store helpers
export const getCustomerOrderListState = (state: MainReducerState) => state.customerOrders.list;
export const getCustomerOrderUpdateState = (state: MainReducerState) => state.customerOrders.update;
export const getCustomerOrderStateById = (id?: CustomerOrder['id']) => (state: MainReducerState) => ({
    loading: state.customerOrders.details.loading,
    error: state.customerOrders.details.error,
    data: id ? state.customerOrders.details.data[id] : undefined,
});
export const getCustomerOrderCloseDetailsState = (state: MainReducerState) => state.customerOrders.closeDetails;
export const getCustomerOrderCloseState = (state: MainReducerState) => state.customerOrders.close;
