import api from './_client';
import { SearchPaginationQuery } from '.';
import { Organization, Pallet, Parcel, Place, Reception, ReceptionStatus } from './apiTypes';

export type ReceptionListPayload = SearchPaginationQuery;
export interface CreateReceptionPayload {
    parcelId: Parcel['id'];
    organizationId: Organization['id'];
}
export const create = async (payload: CreateReceptionPayload) => await api.post(`/reception`, payload);

export const list = async (payload: ReceptionListPayload = {}) => await api.get('/receptions', { params: payload });

export interface ReceptionDetailPayload {
    receptionId: Reception['id'];
}
export const details = async ({ receptionId }: ReceptionDetailPayload) => await api.get(`/reception/${receptionId}`);

export interface UpdateReceptionPayload {
    id: Reception['id'];
    status: ReceptionStatus;
}
export const update = async (payload: UpdateReceptionPayload) => await api.put(`/reception/${payload.id}`, payload);

export interface DeleteReceptionPayload {
    id: Reception['id'];
}
export const del = async (payload: DeleteReceptionPayload) => await api.delete(`/reception/${payload.id}`);

export interface StoreReceptionPalletPayload {
    id: Reception['id'];
    palletId: Pallet['id'];
    barcode?: Place['barcode'];
    reference?: Place['reference'];
}
export const storePallet = async (payload: StoreReceptionPalletPayload) =>
    await api.post(`/reception/${payload.id}/store/${payload.palletId}`, payload);
