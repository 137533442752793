import React, { FC } from 'react';
import { useCurrentBreakpointName } from 'react-socks';

const mobileBreakpoints = ['xs', 'sm'];

const MobileBreakpoint: FC = ({ children }) => {
    const breakpoint = useCurrentBreakpointName();

    return mobileBreakpoints.includes(breakpoint) ? <>{children}</> : null;
};

export default MobileBreakpoint;
