import React, { useEffect, useRef, useState } from 'react';
import { Input, InputProps, message, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
    detailsByBarcode,
    detailsByReference,
    getPalletDetailsStateByBarcode,
    getPalletDetailsStateByReference,
} from '../../store/actions/relatedPallets';

import { isZebra } from '../../helpers/enterprise-browser';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';

const shortcutScope = 'ControlSearchPalletModal';

const ControlSearchPalletModal = NiceModal.create(() => {
    const modal = useModal();
    const { visible, hide } = modal;
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const history = useHistory();
    const { loadingOrderId, customerOrderId } = useParams<{ loadingOrderId: string; customerOrderId: string }>();
    const [loadPalletDetailsByBarcode, loadPalletDetailsByRef] = useActions([
        detailsByBarcode.trigger,
        detailsByReference.trigger,
    ]);
    const [reference, setReference] = useState<string>();
    const palletByRefState = useSelector(getPalletDetailsStateByReference);
    const palletByBarcodeState = useSelector(getPalletDetailsStateByBarcode);
    const previous = usePrevious({ palletByRefState, palletByBarcodeState });
    const inputRef = useRef<Input | null>(null);
    const onSubmit = () => {
        loadPalletDetailsByRef({ reference, customerOrderId });
    };
    const onChangeField: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };

    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                loadPalletDetailsByBarcode({ barcode: barCode.data, customerOrderId });
            } else {
                message.error('Code-barre non valide');
            }
        },
        {
            deps: [loadPalletDetailsByBarcode, customerOrderId],
            disable: !visible,
        }
    );

    useEffect(() => {
        if (!visible) {
            setReference(undefined);
            if (inputRef.current) {
                inputRef.current.blur();
            }
        } else {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [visible, setReference]);

    useEffect(() => {
        if (visible && previous?.palletByRefState.loading && !palletByRefState.loading) {
            if (palletByRefState.error) {
                if (palletByRefState.error?.status === 400 && palletByRefState.error?.data?.invalidBarcode) {
                    message.error('Erreur de format de code barre');
                } else if (palletByRefState.error?.status === 404) {
                    message.error('Palette non trouvée');
                } else if (palletByRefState.error?.status === 409) {
                    message.error("Ce colis n'a pas encore été contrôlé");
                } else {
                    message.error('Une erreur est survenue lors de la demande de palette');
                }
            } else {
                hide();
                history.push(
                    getRoute(RoutePathName.preparationControlPalletDetails, {
                        loadingOrderId,
                        customerOrderId,
                        palletId: palletByRefState.data?.id ?? '',
                    })
                );
            }
        }
    }, [
        previous?.palletByRefState,
        palletByRefState.loading,
        palletByRefState.error,
        palletByRefState.data?.id,
        history,
        loadingOrderId,
        customerOrderId,
        visible,
    ]);

    useEffect(() => {
        if (visible && previous?.palletByBarcodeState.loading && !palletByBarcodeState.loading) {
            if (palletByBarcodeState.error) {
                if (palletByBarcodeState.error?.status === 400 && palletByBarcodeState.error?.data?.invalidBarcode) {
                    message.error('Erreur de format de code barre');
                } else if (palletByBarcodeState.error?.status === 404) {
                    message.error('Palette non trouvée');
                } else if (palletByBarcodeState.error?.status === 409) {
                    message.error("Ce colis n'a pas encore été contrôlé");
                } else {
                    message.error('Une erreur est survenue lors de la demande de palette');
                }
            } else {
                history.push(
                    getRoute(RoutePathName.preparationControlPalletDetails, {
                        loadingOrderId,
                        customerOrderId,
                        palletId: palletByBarcodeState.data?.id ?? '',
                    })
                );
            }
        }
    }, [
        previous?.palletByBarcodeState,
        palletByBarcodeState.loading,
        palletByBarcodeState.error,
        palletByBarcodeState.data?.id,
        history,
        loadingOrderId,
        customerOrderId,
        visible,
    ]);

    return (
        <CustomModal
            {...antdModal(modal)}
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onSubmit}
                            loading={palletByRefState.loading}
                            disabled={!reference}
                        >
                            Valider
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={hide}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            visible={visible}
            title="Rechercher une palette"
            width={368}
            keyboard={false}
            altTitle
        >
            <Spin spinning={palletByBarcodeState.loading}>
                <ArrowNavItem scope={shortcutScope}>
                    <Input
                        placeholder={isZebra ? 'Flasher palette/colis' : 'Entrer référence palette/CONT colis'}
                        onChange={onChangeField}
                        value={reference}
                        autoFocus
                    />
                </ArrowNavItem>
            </Spin>
        </CustomModal>
    );
});

export default ControlSearchPalletModal;
