import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import CustomModal from '../../components/CustomModal';
import React, { useEffect, useState } from 'react';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import { message, Select } from 'antd';
import { Pallet, PalletSize } from '../../store/api/apiTypes';
import { translatePalletSize } from '../../helpers/i18n';
import {
    details as detailsPalletAction,
    getPalletUpdateState,
    update as updatePalletAction,
} from '../../store/actions/pallets';
import { useSelector } from 'react-redux';

const shortcutScope = 'SizePalletModal';

interface SizePalletModalProps {
    palletId: Pallet['id'];
}

const SizePalletModal = NiceModal.create<SizePalletModalProps>(({ palletId }) => {
    const modal = useModal();
    const { visible, hide } = modal;
    const [size, setSize] = useState<PalletSize>();
    const [fetchPallet, updatePallet] = useActions([detailsPalletAction.trigger, updatePalletAction.trigger]);
    const palletUpdateState = useSelector(getPalletUpdateState);
    const previous = usePrevious({ palletUpdateState });
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    useEffect(() => {
        if (previous?.palletUpdateState.loading && !palletUpdateState.loading) {
            if (palletUpdateState.error) {
                message.error(`Erreur lors de la mise à jour de la taille de la palette`);
            } else if (palletUpdateState.data) {
                fetchPallet({ palletId });
                hide();
            }
        }
    }, [
        fetchPallet,
        hide,
        palletId,
        palletUpdateState.data,
        palletUpdateState.error,
        palletUpdateState.loading,
        previous?.palletUpdateState.loading,
    ]);

    const onConfirm = () => {
        updatePallet({
            id: palletId,
            size,
        });
    };

    return (
        <CustomModal
            {...antdModal(modal)}
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            type="primary"
                            shortcut="enter"
                            shortcutScope={shortcutScope}
                            onClick={onConfirm}
                            disabled={!size}
                        >
                            Suivant
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            type="primary"
                            shortcut="esc"
                            shortcutScope={shortcutScope}
                            onClick={hide}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            title={'taille de la palette'}
            width={368}
            altTitle
        >
            <ArrowNavItem scope={shortcutScope}>
                <Select style={{ width: '100%' }} value={size} onChange={setSize} placeholder="Choisir une taille">
                    {Object.values(PalletSize).map((size) => (
                        <Select.Option value={size} key={size}>
                            {translatePalletSize(size)}
                        </Select.Option>
                    ))}
                </Select>
            </ArrowNavItem>
        </CustomModal>
    );
});

export default SizePalletModal;
