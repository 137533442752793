import { Descriptions, Divider, message, Spin, Typography } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    details as preparationDetails,
    getPreparationStateById,
    update as preparationUpdate,
    getPreparationUpdateState,
    detailsPolling as preparationDetailsPolling,
} from '../../store/actions/preparations';
import {
    getPalletPlaceListState,
    list as palletPlacesList,
    listPolling as palletPlacesListPolling,
} from '../../store/actions/palletPlaces';
import {
    HistoryPreparationPicking,
    PalletPlace,
    PalletQuality,
    Permission,
    PickingStatus,
    PlaceZoneType,
    Preparation,
    PreparationStatus,
    ResupplyOrderStatus,
} from '../../store/api/apiTypes';
import { getResupplyOrderCreateState } from '../../store/actions/resupplyOrders';
import { getUser } from '../../store/actions/auth';

import { formatNumber, getResupplyOrderCreateError, translatePreparationPickingStatus } from '../../helpers/i18n';
import { useActions, useArrowNavScope, useIsMobile, usePrevious, useShortcutScope } from '../../hooks';
import { getRawRoute, getRoute, RoutePathName } from '../../routes';
import { formatPlace, getOperatorName } from '../../helpers';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import FixedFooter from '../../components/FixedFooter';
import QuantityCard from '../../components/QuantityCard';
import List, { ListColumn } from '../../components/list/List';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ButtonGrey from '../../components/ButtonGrey';
import { IconWarning } from '../../components/icons';
import { isZebra } from '../../helpers/enterprise-browser';
import { hasPermission } from '../../helpers/security';
import PickingModal from './PickingModal';
import PickingResupplyModal from './PickingResupplyModal';
import PreparationQuantityChangeModal from './PreparationQuantityChangeModal';

const getQuantityValue = (preparation?: Preparation) => {
    if (!preparation) {
        return '—';
    }

    if (preparation?.status === PreparationStatus.toRegularize || preparation?.pickingPreparedQty) {
        return `${formatNumber(preparation?.pickingPreparedQty)}/${formatNumber(preparation?.pickingQtyToPrep)}`;
    } else if (preparation?.pickingPrepStatus === PickingStatus.finished) {
        return formatNumber(preparation?.pickingPreparedQty);
    } else {
        return formatNumber(preparation?.pickingQtyToPrep) ?? '—';
    }
};

const PreparationPicking: FC = () => {
    const isDetailType = !!useRouteMatch(getRawRoute(RoutePathName.preparationDetailType));
    const history = useHistory();
    const isMobile = useIsMobile();
    const { loadingOrderId, customerOrderId, preparationId } =
        useParams<{ loadingOrderId: string; customerOrderId: string; preparationId: string }>();
    const [isResupplyModalVisible, setIsResupplyModalVisible] = useState(false);
    useShortcutScope('resupplyModal', !isResupplyModalVisible);
    useArrowNavScope('resupplyModal', !isResupplyModalVisible);
    const [selectedPalletPlace, setSelectedPalletPlace] = useState<PalletPlace>();
    const [isPickingModalVisible, setIsPickingModalVisible] = useState(false);
    const [isWaitingForBackButton, setIsWaitingForBackButton] = useState(false);
    const [
        fetchPreparation,
        fetchPalletPlaces,
        updatePreparation,
        stopPreparationPolling,
        stopPalletPlacesListPolling,
    ] = useActions([
        preparationDetails.trigger,
        palletPlacesList.trigger,
        preparationUpdate.trigger,
        preparationDetailsPolling.actions.stopPolling,
        palletPlacesListPolling.actions.stopPolling,
    ]);
    const preparationUpdateState = useSelector(getPreparationUpdateState);
    const preparationDetailsState = useSelector(getPreparationStateById(parseInt(preparationId, 10)));
    const palletPlaceListState = useSelector(getPalletPlaceListState);
    const resupplyOrderCreateState = useSelector(getResupplyOrderCreateState);
    const user = useSelector(getUser);
    const previous = usePrevious({
        preparationDetailsState,
        palletPlaceListState,
        preparationUpdateState,
        resupplyOrderCreateState,
    });
    const isPickingOnly = preparationDetailsState.data?.picking && !preparationDetailsState.data?.direct;
    const backRoute =
        isDetailType || isPickingOnly
            ? getRoute(RoutePathName.customerOrderDetails, { loadingOrderId, customerOrderId })
            : getRoute(RoutePathName.preparationDetails, { loadingOrderId, customerOrderId, preparationId });
    const canReadDesktop = !isZebra && hasPermission(user, Permission.preparation);
    const onValidatePallet = () => {
        if (selectedPalletPlace) {
            // check if pallet is empty, then offer choice to create a resupply on it
            if (!selectedPalletPlace.pallet?.quantity) {
                if (isDetailType) {
                    // shouldn't happen
                    message.warning('Cette palette est vide, veuillez en choisir une autre');
                } else {
                    if (
                        selectedPalletPlace.place.resupply &&
                        selectedPalletPlace.place.resupply?.status !== ResupplyOrderStatus.completed
                    ) {
                        message.warning(
                            'Cet emplacement est en attente de réapprovisionnement, veuillez en choisir un autre.'
                        );
                    } else {
                        setIsResupplyModalVisible(true);
                    }
                }
            } else {
                setIsPickingModalVisible(true);
            }
        } else {
            message.error('Veuillez choisir un emplacement');
        }
    };
    const onListRowEnter = (record: PalletPlace) => {
        setSelectedPalletPlace(record);
    };
    const onClickBackButton = () => {
        if (
            preparationDetailsState.data?.pickingPrepStatus === PickingStatus.toProceed &&
            !preparationDetailsState.data?.direct
        ) {
            setIsWaitingForBackButton(true);
        } else {
            history.push(backRoute);
        }
    };
    const onClosePickingModal = useCallback(() => {
        setIsPickingModalVisible(false);
    }, [setIsPickingModalVisible]);
    const onCloseResupplyModal = useCallback(() => {
        setIsResupplyModalVisible(false);
    }, [setIsResupplyModalVisible]);
    const onCloseQuantityChangeModal = useCallback(() => {
        // cancel any open modal
        setIsResupplyModalVisible(false);
        setIsPickingModalVisible(false);
    }, [setIsResupplyModalVisible]);
    const columns: Array<ListColumn<PalletPlace>> = [
        {
            key: 'place',
            title: 'Emplacement',
            flex: '1 1 70%',
            render: (record) => (
                <>
                    {formatPlace(record.place)}
                    {record.place.resupply && record.place.resupply?.status !== ResupplyOrderStatus.completed && (
                        <Typography.Text type="warning">
                            <IconWarning style={{ fontSize: '1.25rem', marginLeft: '1rem' }} />
                            <Typography.Text type="warning" style={{ fontSize: '0.75rem' }}>
                                {' Réappro. en cours'}
                            </Typography.Text>
                        </Typography.Text>
                    )}
                </>
            ),
        },
        {
            key: 'quantity',
            title: isDetailType ? 'Quantité' : 'Quantité disponible',
            flex: '1 1 30%',
            render: (record) => formatNumber(record.pallet?.quantity),
        },
    ];

    if (isDetailType) {
        columns.splice(1, 0, {
            dataIndex: 'pallet.reference',
            title: 'Réf. palette',
            flex: '1 1 70%',
        });
    }

    const historyPickingPlaceColumns: Array<ListColumn<HistoryPreparationPicking>> = [
        {
            key: 'place',
            title: 'Emplacement',
            flex: '1 1 70%',
            render: (record) => `${record.sector} ${record.lane}${record.alveole}${record.level}`,
        },
        {
            key: 'quantity',
            title: 'Quantité prélevée',
            flex: '1 1 30%',
            render: (record) => formatNumber(record.quantity),
        },
    ];

    useEffect(() => {
        if (preparationId) {
            fetchPreparation({ preparationId }, { poll: true });
        }
    }, [fetchPreparation, preparationId]);

    useEffect(
        () => () => {
            stopPreparationPolling();
        },
        [stopPreparationPolling]
    );

    useEffect(
        () => () => {
            stopPalletPlacesListPolling();
        },
        [stopPalletPlacesListPolling]
    );

    useEffect(() => {
        if (!isPickingModalVisible && previous?.preparationDetailsState.loading && !preparationDetailsState.loading) {
            if (preparationDetailsState.error) {
                message.error('Une erreur est survenue lors de la récupération');
            } else {
                fetchPalletPlaces(
                    {
                        parcelId: preparationDetailsState.data?.parcel?.id,
                        zoneType: isDetailType ? PlaceZoneType.mass : PlaceZoneType.picking,
                        quality: isDetailType ? PalletQuality.conform : undefined,
                        pageSize: 300,
                    },
                    { poll: true }
                );
            }
        }
    }, [
        isPickingModalVisible,
        fetchPalletPlaces,
        preparationDetailsState.data?.parcel?.id,
        preparationDetailsState.error,
        preparationDetailsState.loading,
        previous?.preparationDetailsState.loading,
        isDetailType,
    ]);

    useEffect(() => {
        if (!isPickingModalVisible && previous?.preparationUpdateState.loading && !preparationUpdateState.loading) {
            if (preparationUpdateState.error) {
                message.error("Une erreur est survenue lors de la mise à jour de l'ordre de préparation");
            } else {
                // if we updated preparation after clicking back button, redirect to back route
                if (isWaitingForBackButton) {
                    setIsWaitingForBackButton(false);
                    history.push(backRoute);
                } else {
                    fetchPreparation({ preparationId });
                }
            }
        }
    }, [
        isPickingModalVisible,
        previous?.preparationUpdateState.loading,
        preparationUpdateState.loading,
        preparationUpdateState.error,
        fetchPreparation,
        preparationId,
        isWaitingForBackButton,
        history,
        backRoute,
    ]);

    useEffect(() => {
        if (!isPickingModalVisible && previous?.resupplyOrderCreateState.loading && !resupplyOrderCreateState.loading) {
            if (resupplyOrderCreateState.error) {
                message.error(getResupplyOrderCreateError(resupplyOrderCreateState));
            } else {
                message.success('Demande de réappro. créée avec succès.');
                setIsResupplyModalVisible(false);
                fetchPalletPlaces({
                    parcelId: preparationDetailsState.data?.parcel?.id,
                    zoneType: isDetailType ? PlaceZoneType.mass : PlaceZoneType.picking,
                    quality: isDetailType ? PalletQuality.conform : undefined,
                    pageSize: 300,
                });
            }
        }
    }, [
        previous?.resupplyOrderCreateState.loading,
        resupplyOrderCreateState.loading,
        resupplyOrderCreateState.error,
        resupplyOrderCreateState,
        preparationDetailsState.data?.parcel?.id,
        fetchPalletPlaces,
        isPickingModalVisible,
        isDetailType,
    ]);

    // reset preparation when clicking back button and picking status is toProceed
    useEffect(() => {
        if (isWaitingForBackButton) {
            updatePreparation({
                id: preparationId,
                operatorId: null,
                picking: null,
                direct: null,
                pickingQtyToPrep: null,
                directQtyToPrep: null,
                pickingPrepStatus: null,
                status: PreparationStatus.toBeProcessed,
            });
        }
    }, [isWaitingForBackButton, updatePreparation, preparationId, preparationDetailsState.data?.pickingPrepStatus]);

    // reset preparation when using browser back button and picking status is toProceed
    useEffect(() => {
        return () => {
            if (
                history.action === 'POP' &&
                preparationDetailsState.data?.pickingPrepStatus === PickingStatus.toProceed &&
                !preparationDetailsState.data?.direct &&
                !preparationUpdateState.loading
            ) {
                updatePreparation({
                    id: preparationId,
                    operatorId: null,
                    picking: null,
                    direct: null,
                    pickingQtyToPrep: null,
                    directQtyToPrep: null,
                    pickingPrepStatus: null,
                    status: PreparationStatus.toBeProcessed,
                });
            }
        };
    });

    // check if user has been unassigned of this preparation, if true redirect to customer order details
    useEffect(() => {
        if (
            isZebra &&
            user &&
            previous?.preparationDetailsState.data?.operator &&
            previous?.preparationDetailsState.data.operator.id === user.id &&
            preparationDetailsState.data?.operator?.id !== user.id
        ) {
            message.warn('Vous avez été désassigné de cette préparation', 5);
            history.push(getRoute(RoutePathName.customerOrderDetails, { loadingOrderId, customerOrderId }));
        }
    }, [
        previous?.preparationDetailsState,
        preparationDetailsState.data,
        user,
        history,
        loadingOrderId,
        customerOrderId,
    ]);

    return (
        <FixedFooter.Wrapper>
            <Seo title={isDetailType ? 'Détail' : 'Picking'} />
            <Header
                title={isDetailType ? 'Détail' : 'Picking'}
                onClickBack={onClickBackButton}
                disableShortcut={isPickingModalVisible || isResupplyModalVisible}
                backButtonLoading={
                    preparationDetailsState.data?.pickingPrepStatus === PickingStatus.toProceed &&
                    preparationUpdateState.loading
                }
                enableHomeButton
            />
            <PageHeader>
                <Spin spinning={preparationDetailsState.loading}>
                    <Descriptions column={canReadDesktop ? 5 : 4} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="Référence">
                            {preparationDetailsState.data?.parcel?.reference ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Désignation" span={canReadDesktop ? 4 : 3}>
                            {preparationDetailsState.data?.parcel?.label ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Dimensions (L * l * Ep)" span={2}>
                            {preparationDetailsState.data?.parcel?.size?.width ?? '—'}
                            {' * '}
                            {preparationDetailsState.data?.parcel?.size?.height ?? '—'}
                            {' * '}
                            {preparationDetailsState.data?.parcel?.size?.depth ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Statut">
                            {translatePreparationPickingStatus(preparationDetailsState.data?.pickingPrepStatus)}
                        </Descriptions.Item>
                        {canReadDesktop && (
                            <Descriptions.Item label="Cariste">
                                {getOperatorName(preparationDetailsState.data?.operator)}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </Spin>
            </PageHeader>
            <QuantityCard
                value={getQuantityValue(preparationDetailsState.data)}
                isComplete={
                    preparationDetailsState.data?.status !== PreparationStatus.toRegularize &&
                    preparationDetailsState.data?.pickingPrepStatus === PickingStatus.finished
                }
                isWarning={preparationDetailsState.data?.status === PreparationStatus.toRegularize}
                label={preparationDetailsState.data?.pickingPreparedQty ? 'Colis prélevés' : 'Colis à prélever'}
            />
            <Divider />
            <PreparationQuantityChangeModal onClose={onCloseQuantityChangeModal} />
            {preparationDetailsState.data?.pickingPrepStatus === PickingStatus.finished ? (
                !isDetailType && (
                    <List<HistoryPreparationPicking>
                        columns={historyPickingPlaceColumns}
                        data={preparationDetailsState.data?.historyPickingPlace}
                        rowKey={(record) => `${record.id}`}
                        isLoading={preparationDetailsState.loading}
                        title="Historique du picking"
                        hideWhenEmpty
                    />
                )
            ) : (
                <>
                    <List<PalletPlace>
                        columns={columns}
                        data={palletPlaceListState.data?.items}
                        rowKey={(record) => `${record.id}`}
                        onRowFocus={setSelectedPalletPlace}
                        onRowClick={onListRowEnter}
                        onRowEnterPress={onListRowEnter}
                        isRowSelected={(record) => !!selectedPalletPlace && record.id === selectedPalletPlace?.id}
                        isLoading={palletPlaceListState.loading}
                        title={isDetailType ? 'Stock disponible' : 'Colis en zone de picking'}
                    />
                    <FixedFooter>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="f1"
                                size={isMobile ? 'middle' : 'large'}
                                as={ButtonGrey}
                                onClick={onValidatePallet}
                                disabled={palletPlaceListState.data?.totalCount === 0}
                                block
                            >
                                Picking sur l&rsquo;emplacement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </FixedFooter>
                    <PickingResupplyModal
                        visible={isResupplyModalVisible}
                        onCancel={onCloseResupplyModal}
                        placeId={selectedPalletPlace?.pallet?.currentPlace?.id ?? selectedPalletPlace?.place?.id}
                    />
                    <PickingModal
                        visible={isPickingModalVisible}
                        onCancel={onClosePickingModal}
                        pallet={selectedPalletPlace?.pallet}
                        preparationId={preparationDetailsState.data?.id}
                        setSelectedPalletPlace={setSelectedPalletPlace}
                        zIndex={1001} // put this modal above the no-stock modal (default = 1000)
                    />
                </>
            )}
        </FixedFooter.Wrapper>
    );
};

export default PreparationPicking;
