import { Form, FormProps, Input, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    checkPallet as platformReceptionCheckPallet,
    sendPallets as platformReceptionSendPallets,
    getPlatformReceptionCheckPalletState,
    getPlatformReceptionSendPalletsState,
    getPlatformReceptionDetailsStateById,
    getPlatformReceptionReceptionState,
    reception,
} from '../../../store/actions/platformReceptions';
import { Pallet, PlatformAnomalyCode } from '../../../store/api/apiTypes';

import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../../hooks';
import { getPlatformReceptionCheckPalletError, getPlatformReceptionSendPalletsError } from '../../../helpers/i18n';
import { isZebra } from '../../../helpers/enterprise-browser';
import SuccessMessage from '../../../components/SuccessMessage';
import AnomalyCodeSelect from '../../../components/AnomalyCodeSelect';

const shortcutScope = 'PalletAnomalyModal';

interface PalletAnomalyModalProps extends CustomModalProps {
    onFinish: () => void;
    onCancelAnomaly: () => void;
    palletReference?: Pallet['reference']; // supplied on desktop
}

const PalletAnomalyModal: FC<PalletAnomalyModalProps> = ({
    visible,
    onCancel,
    onFinish,
    onCancelAnomaly,
    palletReference,
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const { platformReceptionId: platformReceptionIdParam } = useParams<{ platformReceptionId: string }>();
    const platformReceptionId = parseInt(platformReceptionIdParam, 10);
    const [form] = Form.useForm();
    const [step, setStep] = useState<'scan' | 'anomaly' | 'success'>(isZebra ? 'scan' : 'anomaly');
    const [tempReference, setTempReference] = useState<string>(); // temp data when waiting for API response
    const [tempBarcode, setTempBarcode] = useState<string>(); // temp data when waiting for API response
    const [anomalyCode, setAnomalyCode] = useState<PlatformAnomalyCode>();
    const [checkPallet, sendPallets, setReceiveState] = useActions([
        platformReceptionCheckPallet.trigger,
        platformReceptionSendPallets.trigger,
        reception.actions.setReceiveData,
    ]);
    const { palletRefs, palletBarcodes } = useSelector(getPlatformReceptionReceptionState).receive;
    const platformReceptionState = useSelector(getPlatformReceptionDetailsStateById(platformReceptionId));
    const checkPalletState = useSelector(getPlatformReceptionCheckPalletState);
    const sendPalletsState = useSelector(getPlatformReceptionSendPalletsState);
    const remainingQuantityToReceive =
        (platformReceptionState.data?.quantity ?? 0) -
        (platformReceptionState.data?.receivedQuantity ?? 0) -
        palletRefs.length -
        palletBarcodes.length;
    const previous = usePrevious({ visible, checkPalletState, sendPalletsState });
    const onClose = useCallback(() => {
        onCancel?.({} as React.MouseEvent<HTMLElement, MouseEvent>);
    }, [onCancel]);
    const reset = useCallback(() => {
        setTempReference(undefined);
        setTempBarcode(undefined);
        form.resetFields();
        setStep(isZebra ? 'scan' : 'anomaly');
    }, [form]);
    const onClickCancel = () => {
        onCancelAnomaly();
        onClose();
        reset();
    };
    const onSubmitManual: FormProps['onFinish'] = (values) => {
        if (palletRefs.some((palletRef) => palletRef.reference === values.reference)) {
            message.error('Vous avez déjà entré ce lot');
        } else {
            setTempReference(values.reference);
            checkPallet({
                platformReceptionId,
                reference: values.reference,
            });
        }
    };
    const onSubmit = () => {
        if (isZebra) {
            if (tempBarcode) {
                if (palletBarcodes.some((palletBarcode) => palletBarcode.barcode === tempBarcode)) {
                    setReceiveState({
                        palletBarcodes: [
                            ...palletBarcodes.filter((palletBarcode) => palletBarcode.barcode !== tempBarcode),
                            { barcode: tempBarcode, anomalyCode: anomalyCode?.id },
                        ],
                    });
                } else {
                    setReceiveState({
                        palletBarcodes: [...palletBarcodes, { barcode: tempBarcode, anomalyCode: anomalyCode?.id }],
                    });
                }
            } else if (tempReference) {
                if (palletRefs.some((palletRef) => palletRef.reference === tempReference)) {
                    setReceiveState({
                        palletRefs: [
                            ...palletRefs.filter((palletRef) => palletRef.reference !== tempReference),
                            { reference: tempReference, anomalyCode: anomalyCode?.id },
                        ],
                    });
                } else {
                    setReceiveState({
                        palletRefs: [...palletRefs, { reference: tempReference, anomalyCode: anomalyCode?.id }],
                    });
                }
            }

            setStep('success');
        } else {
            sendPallets({
                platformReceptionId,
                references: [{ reference: palletReference, anomalyCode: anomalyCode?.id }],
            });
        }
    };
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (palletBarcodes.some((palletBarcode) => palletBarcode.barcode === barCode.data)) {
                    message.error('Vous avez déjà scanné ce lot');
                } else {
                    setTempBarcode(barCode.data);
                    checkPallet({
                        platformReceptionId,
                        barcode: barCode.data,
                    });
                }
            } else {
                console.error(barCode);
            }
        },
        {
            deps: [checkPallet, palletBarcodes, platformReceptionId, visible],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    useEffect(() => {
        if (visible && previous?.checkPalletState.loading && !checkPalletState.loading) {
            if (checkPalletState.error) {
                const requestError = getPlatformReceptionCheckPalletError(checkPalletState);

                if (requestError.message) {
                    message.error(requestError.message);
                }
            } else if (checkPalletState.data) {
                if (remainingQuantityToReceive === 0) {
                    message.error('Le nombre maximum de lots à réceptionner est déjà atteint', 5);
                } else {
                    setStep('anomaly');
                }

                form.resetFields();
            }
        }
    }, [
        previous?.checkPalletState.loading,
        checkPalletState,
        tempBarcode,
        tempReference,
        form,
        palletBarcodes,
        palletRefs,
        visible,
        remainingQuantityToReceive,
        setReceiveState,
    ]);

    useEffect(() => {
        if (visible && previous?.sendPalletsState.loading && !sendPalletsState.loading) {
            if (sendPalletsState.error) {
                const requestError = getPlatformReceptionSendPalletsError(sendPalletsState);

                if (requestError.message) {
                    message.error(requestError.message);
                }
            } else {
                setStep('success');
            }
        }
    }, [
        previous?.sendPalletsState.loading,
        sendPalletsState.loading,
        sendPalletsState.error,
        sendPalletsState.data,
        onFinish,
        visible,
        sendPalletsState,
        onClose,
    ]);

    useEffect(() => {
        let timeout: number;

        if (step === 'success') {
            timeout = window.setTimeout(() => {
                if (timeout) {
                    onFinish();
                    reset();
                    onClose();
                }
            }, 2000);
        }

        return () => {
            window.clearTimeout(timeout);
        };
    }, [step, onFinish, onClose, reset]);

    return (
        <CustomModal
            footer={
                step === 'scan' ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={() => form.submit()}
                                loading={checkPalletState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClickCancel}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : step === 'anomaly' ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={onSubmit}
                                loading={sendPalletsState.loading}
                                disabled={!anomalyCode}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={isZebra ? setStep.bind(null, 'scan') : onClickCancel}
                                ghost
                            >
                                {isZebra ? 'Retour' : 'Annuler'}
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : null
            }
            visible={visible}
            onCancel={onCancel}
            title={step !== 'success' ? 'Déclarer avarie lot' : undefined}
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            maskClosable={false}
            altTitle
        >
            {step === 'success' && <SuccessMessage message="Lot déclaré en avarie avec succès" />}
            {step === 'scan' && (
                <Form form={form} onFinish={onSubmitManual}>
                    <Form.Item name="reference" rules={[{ required: true, message: 'Veuillez saisir une référence' }]}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir/flasher référence lot" autoFocus />
                        </ArrowNavItem>
                    </Form.Item>
                </Form>
            )}
            {step === 'anomaly' && <AnomalyCodeSelect withArrowNav onChange={setAnomalyCode} />}
        </CustomModal>
    );
};

export default PalletAnomalyModal;
