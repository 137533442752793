import { message } from 'antd';
import axios from 'axios';
import constants from '../../config/constants';
import { showErrorMessage } from '../../helpers/i18n';
import { RequestError } from './';

const client = axios.create({
    baseURL: constants.API.BASE_URL,
    headers: {
        ...constants.API.HEADERS,
    },
    withCredentials: true, // automatically send saved cookie
});

client.interceptors.response.use(
    (response) => response.data,
    (error) => {
        const formattedError: RequestError = {
            status: 0,
            message: '',
        };
        if (error.response) {
            formattedError.status = error.response.status;
            formattedError.data = error.response.data;
            formattedError.headers = error.response.headers;

            if (error.response.status === 401) {
                message.error(
                    "Vous n'avez pas le droit d'effectuer cette action, veuillez vous rapprocher de votre administrateur"
                );

                // disabled auto redirect to login if we receive a 401, in case of operator changes for example, or wrong permission configuration

                // const e = document.createEvent('CustomEvent');
                // e.initCustomEvent('unauthorized.error', true, false, 401);
                // window.dispatchEvent(e);
            }

            showErrorMessage(error.response.data);
        }

        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw formattedError;
    }
);

export default client;
