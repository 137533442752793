import { Descriptions, message, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonGrey from '../../../components/ButtonGrey';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import ConfirmModal from '../../../components/ConfirmModal';
import FixedFooter from '../../../components/FixedFooter';
import Header from '../../../components/Header';
import PageHeader from '../../../components/PageHeader';
import QuantityCard from '../../../components/QuantityCard';
import Seo from '../../../components/Seo';
import VerticalDescriptions from '../../../components/VerticalDescriptions';
import { getOperatorName } from '../../../helpers';
import { isZebra } from '../../../helpers/enterprise-browser';
import { formatDate, formatNumber, translateTransferOrderStatus } from '../../../helpers/i18n';
import { useActions, usePrevious } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import { getUser } from '../../../store/actions/auth';
import {
    detailsTransferOrder,
    getTransferOrdersDetails,
    getTransferOrdersUpdateState,
    update,
} from '../../../store/actions/transferOrders';
import { TransferOrderStatus } from '../../../store/api/apiTypes';

import TransferOrderCreateModals from './TransferOrderCreateModals';

const TransferOrdersDetails: FC = () => {
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);
    const [isWaitingForBackButton, setIsWaitingForBackButton] = useState(false);

    const params = useParams<{ transferOrderId: string }>();

    const user = useSelector(getUser);

    const [fetchTransferOrder, updateTransferOrder] = useActions([detailsTransferOrder.trigger, update.trigger]);
    const transferOrderUpdateState = useSelector(getTransferOrdersUpdateState);

    const transferOrderState = useSelector(getTransferOrdersDetails);

    const previous = usePrevious({ transferOrderState, transferOrderUpdateState });

    const backRoute = getRoute(RoutePathName.movementsTransferOrderList);

    useEffect(() => {
        fetchTransferOrder({ id: params.transferOrderId });
    }, [fetchTransferOrder, params.transferOrderId]);

    const unlockOrder = () => {
        updateTransferOrder({ id: transferOrderState.data?.id, operatorId: null });
    };
    const onClickBackButton = () => {
        if (!isZebra) {
            history.push(backRoute);
        } else if (transferOrderState.data?.status === TransferOrderStatus.inProgress) {
            setIsWaitingForBackButton(true);
        } else {
            history.push(backRoute);
        }
    };

    useEffect(() => {
        if (previous?.transferOrderUpdateState.loading && !transferOrderState.loading) {
            if (transferOrderUpdateState.error) {
                message.error("Une erreur est survenue lors du débloquage de l'ordre de transfert");
            } else {
                if (isZebra && isWaitingForBackButton) {
                    setIsWaitingForBackButton(false);
                    history.push(backRoute);
                } else {
                    fetchTransferOrder({ id: transferOrderState.data?.id });
                    setIsConfirmModalVisible(false);
                }
            }
        }
    }, [
        backRoute,
        fetchTransferOrder,
        history,
        isWaitingForBackButton,
        previous?.transferOrderUpdateState.loading,
        transferOrderState.data?.id,
        transferOrderState.loading,
        transferOrderUpdateState.error,
    ]);

    // reset transferOrder when clicking back button and transferOrder status is inProgress
    useEffect(() => {
        if (isWaitingForBackButton) {
            updateTransferOrder({
                id: parseInt(params.transferOrderId, 10),
                status: TransferOrderStatus.toBeProcessed,
                operatorId: null,
            });
        }
    }, [isWaitingForBackButton, updateTransferOrder, params.transferOrderId]);

    // reset transferOrder when using browser back button and transferOrder status is inProgress
    useEffect(() => {
        return () => {
            if (
                history.action === 'POP' &&
                isZebra &&
                transferOrderState.data?.status === TransferOrderStatus.inProgress &&
                !transferOrderState.loading
            ) {
                updateTransferOrder({
                    id: parseInt(params.transferOrderId, 10),
                    status: TransferOrderStatus.toBeProcessed,
                    operatorId: null,
                });
            }
        };
    });

    // check if user has been unassigned of this transferOrder, if true redirect to list
    useEffect(() => {
        if (
            isZebra &&
            user &&
            previous?.transferOrderState.data?.operator &&
            transferOrderState.data?.operator &&
            previous?.transferOrderState.data.operator.id === user.id &&
            transferOrderState.data?.operator?.id !== user.id
        ) {
            message.warn('Vous avez été désassigné de cet ordre de transfert', 5);
            history.push(backRoute);
        }
    }, [previous?.transferOrderState, transferOrderState.data, user, history, backRoute]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Ordre de transfert" />
            <Header
                title="ORDRE DE TRANSFERT"
                onClickBack={onClickBackButton}
                backButtonLoading={
                    transferOrderState.data?.status === TransferOrderStatus.inProgress &&
                    transferOrderUpdateState.loading
                }
                enableHomeButton
            />
            <PageHeader>
                <Descriptions size="small" column={isZebra ? 3 : 4} layout="vertical">
                    <Descriptions.Item label="Date">{formatDate(transferOrderState.data?.createdAt)}</Descriptions.Item>
                    <Descriptions.Item label="Heure">
                        {formatDate(transferOrderState.data?.createdAt, { minute: '2-digit', hour: '2-digit' })}
                    </Descriptions.Item>
                    <Descriptions.Item label="Statut">
                        {translateTransferOrderStatus(transferOrderState.data?.status)}
                    </Descriptions.Item>
                    {!isZebra && (
                        <Descriptions.Item label="Cariste">
                            {getOperatorName(transferOrderState.data?.operator)}
                        </Descriptions.Item>
                    )}
                </Descriptions>
            </PageHeader>
            <Space direction="vertical" size="large">
                <VerticalDescriptions
                    items={[
                        { label: 'Référence colis', value: transferOrderState.data?.parcel?.reference ?? '-' },
                        {
                            label: 'N° de tournée',
                            value: transferOrderState.data?.customerOrder?.loadingOrder?.tourNumber ?? '-',
                        },
                        {
                            label: 'Code client',
                            value: transferOrderState.data?.customerOrder?.customer?.reference ?? '-',
                        },
                        { label: 'Client', value: transferOrderState.data?.customerOrder?.customer?.label ?? '-' },
                    ]}
                />

                <QuantityCard
                    value={`${
                        transferOrderState.data?.transferedPackages?.toString()?.concat(' / ') ?? ''
                    }${formatNumber(transferOrderState.data?.packageQuantity)}`}
                    label={transferOrderState.data?.transferedPackages ? 'Colis Transférés' : 'Colis à transférer'}
                />
            </Space>
            {isModalVisible && (
                <TransferOrderCreateModals visible={isModalVisible} onCancel={setIsModalVisible.bind(null, false)} />
            )}
            <ConfirmModal
                visible={isConfirmModalVisible}
                message="Êtes-vous sûr de vouloir débloquer cet ordre maintenant ?"
                okText="Oui"
                cancelText="Non"
                onCancel={setIsConfirmModalVisible.bind(null, false)}
                onOk={unlockOrder}
                loading={transferOrderUpdateState.loading}
            />

            {isZebra ? (
                <FixedFooter>
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            onClick={setIsModalVisible.bind(null, true)}
                            as={ButtonGrey}
                            shortcut="enter"
                            type="primary"
                            block
                            loading={transferOrderState.loading}
                        >
                            Ranger
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </FixedFooter>
            ) : transferOrderState.data?.operator ? (
                <FixedFooter>
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            onClick={setIsConfirmModalVisible.bind(null, true)}
                            shortcut="enter"
                            type="primary"
                            block
                            ghost
                        >
                            Débloquer
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </FixedFooter>
            ) : null}
        </FixedFooter.Wrapper>
    );
};

export default TransferOrdersDetails;
