import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { Inventory, InventoryPlace, User } from '../api/apiTypes';
import { ListResponse } from '../api/';

// Controlers
import {
    InventoryListPayload,
    list as listApiCall,
    InventoryPlacesListPayload,
    placeList as placeListApiCall,
    details as detailsApiCall,
    InventoryDetailsPayload,
    update as updateApiCall,
    InventoryUpdatePayload,
    create as createApiCall,
    InventoryCreatePayload,
    close as closeApiCall,
    InventoryClosePayload,
    InventoryPlaceDetailsPayload,
    placeDetails as placeDetailsApiCall,
    InventoryPlaceUpdatePayload,
    placeUpdate as placeUpdateApiCall,
    InventoryOperatorListPayload,
    operatorList as operatorListApiCall,
    InventoryPlaceValidateGapPayload,
    placeValidateGap as placeValidateGapApiCall,
    del as deleteApiCall,
    InventoryDeletePayload,
} from '../api/inventories';

// States
export interface InventoriesState {
    list: RequestState<ListResponse<Inventory>>;
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: Inventory | undefined;
        };
    };
    create: RequestState<Inventory>;
    update: RequestState<Inventory>;
    close: RequestState;
    del: RequestState;
    placeList: RequestState<ListResponse<InventoryPlace>>;
    placeDetails: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: InventoryPlace | undefined;
        };
    };
    placeUpdate: RequestState<InventoryPlace>;
    operatorList: RequestState<ListResponse<User>>;
    placeValidateGap: RequestState;
}

const initialState: InventoriesState = {
    list: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
    create: { ...requestInitialState },
    update: { ...requestInitialState },
    close: { ...requestInitialState },
    del: { ...requestInitialState },
    placeList: { ...requestInitialState },
    placeDetails: { ...requestInitialState, data: {} },
    placeUpdate: { ...requestInitialState },
    operatorList: { ...requestInitialState },
    placeValidateGap: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<InventoriesState['list'], InventoryListPayload, ListResponse<Inventory>>(
    'inventories/list',
    initialState.list,
    requestReducer<InventoriesState['list'], ListResponse<Inventory>>(initialState.list)
);

export const details = new EzeeAsyncAction<InventoriesState['details'], InventoryDetailsPayload, Inventory>(
    'inventories/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

export const create = new EzeeAsyncAction<InventoriesState['create'], InventoryCreatePayload, Inventory>(
    'inventories/create',
    initialState.create,
    requestReducer<InventoriesState['create'], Inventory>(initialState.create)
);

export const update = new EzeeAsyncAction<InventoriesState['update'], InventoryUpdatePayload, Inventory>(
    'inventories/update',
    initialState.update,
    requestReducer<InventoriesState['update'], Inventory>(initialState.update)
);

export const close = new EzeeAsyncAction<InventoriesState['close'], InventoryClosePayload, Inventory>(
    'inventories/close',
    initialState.close,
    requestReducer<InventoriesState['close'], Inventory>(initialState.close)
);

export const del = new EzeeAsyncAction<InventoriesState['del'], InventoryDeletePayload>(
    'inventories/del',
    initialState.del,
    requestReducer<InventoriesState['del']>(initialState.del)
);

export const placeList = new EzeeAsyncAction<
    InventoriesState['placeList'],
    InventoryPlacesListPayload,
    ListResponse<InventoryPlace>
>('inventories/placeList', initialState.placeList, {
    trigger: (state, _, meta) => ({
        ...state,
        loading: true,
        data: meta?.loadMore ? state.data : undefined,
    }),
    success: (state, payload, meta) => ({
        data: {
            ...payload,
            items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.placeList,
    }),
});

export const placeDetails = new EzeeAsyncAction<
    InventoriesState['placeDetails'],
    InventoryPlaceDetailsPayload,
    InventoryPlace
>('inventories/placeDetails', initialState.placeDetails, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...state.data,
            [payload.id]: payload,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.placeDetails,
    }),
});

export const placeUpdate = new EzeeAsyncAction<
    InventoriesState['placeUpdate'],
    InventoryPlaceUpdatePayload,
    InventoryPlace
>(
    'inventories/placeUpdate',
    initialState.placeUpdate,
    requestReducer<InventoriesState['placeUpdate'], InventoryPlace>(initialState.placeUpdate)
);

export const operatorList = new EzeeAsyncAction<
    InventoriesState['operatorList'],
    InventoryOperatorListPayload,
    ListResponse<User>
>(
    'inventories/operatorList',
    initialState.operatorList,
    requestReducer<InventoriesState['operatorList'], ListResponse<User>>(initialState.operatorList)
);

export const placeValidateGap = new EzeeAsyncAction<
    InventoriesState['placeValidateGap'],
    InventoryPlaceValidateGapPayload
>(
    'inventories/placeValidateGap',
    initialState.placeValidateGap,
    requestReducer<InventoriesState['placeValidateGap']>(initialState.placeValidateGap)
);

// Reducer
export const inventoriesReducer = combineReducers<InventoriesState>({
    list: list.reducer,
    details: details.reducer,
    create: create.reducer,
    update: update.reducer,
    close: close.reducer,
    del: del.reducer,
    placeList: placeList.reducer,
    placeDetails: placeDetails.reducer,
    placeUpdate: placeUpdate.reducer,
    operatorList: operatorList.reducer,
    placeValidateGap: placeValidateGap.reducer,
});

// Saga
export function* inventoriesSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(close.type.trigger, simpleAsyncSaga(closeApiCall, close));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(deleteApiCall, del));
    yield takeLatest(placeList.type.trigger, simpleAsyncSaga(placeListApiCall, placeList));
    yield takeLatest(placeDetails.type.trigger, simpleAsyncSaga(placeDetailsApiCall, placeDetails));
    yield takeLatest(placeUpdate.type.trigger, simpleAsyncSaga(placeUpdateApiCall, placeUpdate));
    yield takeLatest(operatorList.type.trigger, simpleAsyncSaga(operatorListApiCall, operatorList));
    yield takeLatest(placeValidateGap.type.trigger, simpleAsyncSaga(placeValidateGapApiCall, placeValidateGap));
}

// Store helpers
export const getInventoriesListState = (state: MainReducerState) => state.inventories.list;
export const getInventoryStateById = (id: Inventory['id']) => (state: MainReducerState) => ({
    loading: state.inventories.details.loading,
    error: state.inventories.details.error,
    data: state.inventories.details.data[id],
});
export const getInventoryCreateState = (state: MainReducerState) => state.inventories.create;
export const getInventoryUpdateState = (state: MainReducerState) => state.inventories.update;
export const getInventoryCloseState = (state: MainReducerState) => state.inventories.close;
export const getInventoryDelState = (state: MainReducerState) => state.inventories.del;
export const getInventoryPlacesListState = (state: MainReducerState) => state.inventories.placeList;
export const getInventoryPlaceStateById = (id: InventoryPlace['id']) => (state: MainReducerState) => ({
    loading: state.inventories.placeDetails.loading,
    error: state.inventories.placeDetails.error,
    data: state.inventories.placeDetails.data[id],
});
export const getInventoryPlacesUpdateState = (state: MainReducerState) => state.inventories.placeUpdate;
export const getInventoryOperatorListState = (state: MainReducerState) => state.inventories.operatorList;
export const getInventoryPlaceValidateGapState = (state: MainReducerState) => state.inventories.placeValidateGap;
