import React, { FC, useCallback, useEffect, useState } from 'react';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useScanner, useShortcutScope } from '../../../hooks';
import { MovementBackToWorkshopStatus, PlaceZoneType, ResupplyOrderType } from '../../../store/api/apiTypes';
import { useSelector } from 'react-redux';

import {
    getMovementsBackToWorkShopCrateState,
    create as backToWorkshopCreation,
} from '../../../store/actions/MovementsBackToWorkshops';

import { getPlaceDetailsByScanState, detailsByScan as detailsPlaceByScan } from '../../../store/actions/places';
import { getPalletDetailsByScanState, detailsByScan as detailsPalletByScan } from '../../../store/actions/pallets';
import { Descriptions, Divider, Input, message, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import QuantityInput from '../../../components/QuantityInput';
import SuccessMessage from '../../../components/SuccessMessage';
import { isZebra } from '../../../helpers/enterprise-browser';
import { translatePlaceZoneType } from '../../../helpers/i18n';
import { MovementsBackToWorkshopCreatePayload } from '../../../store/api/movementsBackToWorkshops';

const shortcutScope = 'BackToWorkShopCreateModal';

export type CreateBackToWorkshopStep = 'zoneType' | 'scan' | 'quantity' | 'success' | undefined;

const BackToWorkShopCreateModal: FC<CustomModalProps> = ({ visible, onCancel }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const [step, setStep] = useState<CreateBackToWorkshopStep>('zoneType');
    const [zoneType, setZoneType] = useState<PlaceZoneType | undefined>();
    const [reference, setReference] = useState<string>();
    const [quantity, setQuantity] = useState<number | undefined>();

    const [
        fetchPlaceDetails,
        fetchPalletDetails,
        createBackToWorkshop,
        resetPlaceDetails,
        resetPalletDetails,
        resetCreateBroken,
    ] = useActions([
        detailsPlaceByScan.trigger,
        detailsPalletByScan.trigger,
        backToWorkshopCreation.trigger,
        detailsPlaceByScan.reset,
        detailsPalletByScan.reset,
        backToWorkshopCreation.reset,
    ]);
    const placeScanState = useSelector(getPlaceDetailsByScanState);
    const palletScanState = useSelector(getPalletDetailsByScanState);
    const backToWorkshopCreateState = useSelector(getMovementsBackToWorkShopCrateState);

    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            initStates();
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    const parcelInfoLabel =
        zoneType === PlaceZoneType.picking
            ? placeScanState.data?.pallets?.[0]?.parcel?.label ?? '-'
            : palletScanState.data?.parcel?.label ?? '-';

    const parcelInfoReference =
        zoneType === PlaceZoneType.picking
            ? placeScanState.data?.pallets?.[0]?.parcel?.reference ?? '-'
            : palletScanState.data?.parcel?.reference ?? '-';

    const maxQuantity =
        (zoneType === PlaceZoneType.picking
            ? placeScanState.data?.pallets?.[0].quantity
            : palletScanState.data?.quantity) ?? 0;

    const initStates = () => {
        setStep('zoneType');
        setZoneType(undefined);
        setReference(undefined);
        setQuantity(undefined);
    };

    const previous = usePrevious({ placeScanState, palletScanState, backToWorkshopCreateState });

    const handleActionByStep = () => {
        switch (step) {
            case 'scan':
                if (zoneType === PlaceZoneType.picking) {
                    fetchPlaceDetails({ reference });
                } else {
                    fetchPalletDetails({ reference });
                }
                break;

            case 'quantity':
                handleCreateBroken();
                break;
            default:
                break;
        }
    };

    const onZoneTypeSelect = (type: PlaceZoneType) => {
        setZoneType(type);
        setStep('scan');
    };

    const handleCreateBroken = () => {
        const payload: MovementsBackToWorkshopCreatePayload = {
            ...(zoneType === PlaceZoneType.picking
                ? { placeId: placeScanState.data?.id }
                : { palletId: palletScanState.data?.id }),
            status: MovementBackToWorkshopStatus.completed,
            quantity: quantity ?? 0,
        };
        createBackToWorkshop(payload);
    };

    const renderModalTitle = () => {
        switch (step) {
            case 'zoneType':
                return 'Zone de départ';
            case 'scan':
            case 'quantity':
                return 'retour atelier';
            default:
                return '';
        }
    };

    useEffect(() => {
        if (previous?.placeScanState.loading && !placeScanState.loading) {
            if (placeScanState.error) {
                message.error('Emplacement non reconnu');
            } else if (placeScanState.data?.zoneType !== PlaceZoneType.picking) {
                message.error(`Emplacement non reconnu en ${translatePlaceZoneType(zoneType)}`);
            } else if (!placeScanState.data.pallets?.[0]?.quantity) {
                message.error('Emplacement vide');
            } else {
                setQuantity(placeScanState.data?.pallets?.[0]?.quantity);
                setStep('quantity');
            }
        }
    }, [
        placeScanState.data?.pallets,
        placeScanState.data?.zoneType,
        placeScanState.error,
        placeScanState.loading,
        previous?.placeScanState.loading,
        zoneType,
    ]);

    useEffect(() => {
        if (previous?.palletScanState.loading && !palletScanState.loading) {
            if (palletScanState.error) {
                message.error('Palette non reconnue');
            } else if (palletScanState.data?.currentPlace?.zoneType !== PlaceZoneType.mass) {
                message.error(`Palette non reconnue en ${translatePlaceZoneType(zoneType)}`);
            } else if (palletScanState.data?.resupply?.type === ResupplyOrderType.direct) {
                message.error(`Palette réservée pour une préparation directe`);
            } else {
                setQuantity(palletScanState.data?.quantity);
                setStep('quantity');
            }
        }
    }, [
        palletScanState.data?.currentPlace?.zoneType,
        palletScanState.data?.quantity,
        palletScanState.data?.resupply?.type,
        palletScanState.error,
        palletScanState.loading,
        previous?.palletScanState.loading,
        zoneType,
    ]);

    useEffect(() => {
        if (previous?.backToWorkshopCreateState.loading && !backToWorkshopCreateState.loading) {
            if (backToWorkshopCreateState.error) {
                message.error('Une erreur est survenue lors de la création retour atelier');
            } else {
                setStep('success');
                resetCreateBroken();
                if (zoneType === PlaceZoneType.picking) {
                    resetPlaceDetails();
                } else {
                    resetPalletDetails();
                }
            }
        }
    }, [
        backToWorkshopCreateState.error,
        backToWorkshopCreateState.loading,
        previous?.backToWorkshopCreateState.loading,
        resetCreateBroken,
        resetPalletDetails,
        resetPlaceDetails,
        zoneType,
    ]);
    const isMounted = useIsMounted();

    useEffect(() => {
        let timeout: number;

        if (step === 'success') {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onClose();
                }
            }, 3000);
        }

        return () => window.clearTimeout(timeout);
    }, [isMounted, onClose, step]);

    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (zoneType === PlaceZoneType.picking) {
                    fetchPlaceDetails({ barcode: barCode.data });
                } else {
                    fetchPalletDetails({ barcode: barCode.data });
                }
            }
        },
        {
            deps: [zoneType],
            disable: !visible,
        }
    );
    useEffect(() => {
        initStates();
    }, []);
    return (
        <CustomModal
            visible={visible}
            title={renderModalTitle()}
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            altTitle
            footer={
                <>
                    {step === 'zoneType' && (
                        <>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="1"
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                    onClick={onZoneTypeSelect.bind(null, PlaceZoneType.picking)}
                                    block
                                >
                                    Picking
                                </ButtonWithShortcut>
                            </ArrowNavItem>

                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="2"
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                    onClick={onZoneTypeSelect.bind(null, PlaceZoneType.mass)}
                                    block
                                >
                                    Masse / Accumulation
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </>
                    )}
                    {['scan', 'quantity'].some((s) => s === step) && (
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={handleActionByStep}
                                disabled={
                                    !!(step === 'quantity' && (!quantity || quantity > maxQuantity || quantity < 1)) ||
                                    (step === 'scan' && !reference)
                                }
                                loading={
                                    placeScanState.loading ||
                                    palletScanState.loading ||
                                    backToWorkshopCreateState.loading
                                }
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    )}

                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClose}
                            ghost
                        >
                            {step === 'success' ? 'Fermer' : 'Annuler'}
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
        >
            {step === 'scan' && (
                <ArrowNavItem scope={shortcutScope}>
                    <Input
                        placeholder={`Flasher ${zoneType === PlaceZoneType.picking ? "l'emplacement" : 'la palette'}`}
                        value={reference}
                        onChange={(e) => setReference(e.target.value)}
                    />
                </ArrowNavItem>
            )}

            {step === 'quantity' && (
                <>
                    <Spin spinning={placeScanState.loading || palletScanState.loading}>
                        <Descriptions className="blue-label" column={isZebra ? 5 : 2} size="small" layout="vertical">
                            <Descriptions.Item span={isZebra ? 2 : 1} label="Référence">
                                {parcelInfoReference}
                            </Descriptions.Item>
                            <Descriptions.Item span={isZebra ? 3 : 1} label="Désignation">
                                {parcelInfoLabel.length > 20
                                    ? parcelInfoLabel.substr(0, 20).concat('...')
                                    : parcelInfoLabel}
                            </Descriptions.Item>
                        </Descriptions>
                    </Spin>
                    <Divider />
                    <Text>Quantité à retourner</Text>
                    <QuantityInput value={quantity} onChange={setQuantity} max={maxQuantity} min={1} />
                </>
            )}
            {step === 'success' && (
                <SuccessMessage message="'Les colis ont été déclaré en retours atelier. Merci de les déplacer dans le puits correspondant.'" />
            )}
        </CustomModal>
    );
};

export default BackToWorkShopCreateModal;
