import React, { FC, useCallback, useEffect, useState } from 'react';
import { useActions, usePrevious } from '../../../hooks';
import { PlaceZoneType, TransferOrderType, TransferType, WMSMode } from '../../../store/api/apiTypes';

import { message } from 'antd';

import PrintModal from '../../../components/PrintModal';
import FinishtZoneTypeModal from './FinishZoneTypeModal';
import StartZoneScanModal from './StartZoneScanModal';
import StartZoneTypeModal from './StartZoneTypeModal';
import TransferTypeModal from './TransferTypeModal';

import PackageQuantity from './TransferQuantityModal';
import {
    getTransferOrdersUpdateState,
    getTransferOrdersDetails,
    del as transferOrderDelete,
    create as transferOrderCreate,
    getTransferOrdersDeleteState,
} from '../../../store/actions/transferOrders';
import { useSelector } from 'react-redux';
import { getPalletPrintState } from '../../../store/actions/pallets';
import { useHistory, useLocation } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../../routes';
import FinishZoneScanModal from './FinishZoneScanModal';
import WarningModal from '../../../components/WarningModal';
import ConfirmModal from './../../../components/ConfirmModal';

export type TransferOrderModalsType =
    | 'startZone'
    | 'placeReference'
    | 'transferType'
    | 'finishZoneType'
    | 'print'
    | 'transferQuantity'
    | 'storage'
    | 'noData'
    | 'cancel';

type ModalState = {
    [key in TransferOrderModalsType]: boolean;
};

interface Props {
    visible: boolean;
    onCancel: () => void;
}

const TransferOrderCreateModals: FC<Props> = ({ visible, onCancel }) => {
    const [startZoneType, setStartZonetype] = useState<PlaceZoneType | undefined>();

    const [finishZoneType, setFinishZoneType] = useState<PlaceZoneType | undefined>();

    const [transferType, setTransferType] = useState<TransferType>();

    const [modals, setModals] = useState<ModalState>({
        startZone: false,
        placeReference: false,
        transferType: false,
        finishZoneType: false,
        print: false,
        transferQuantity: false,
        storage: false,
        noData: false,
        cancel: false,
    });

    const [deleteTransferOrder, resetTransferOrderCreateState] = useActions([
        transferOrderDelete.trigger,
        transferOrderCreate.reset,
    ]);
    const transferOrderDeleteState = useSelector(getTransferOrdersDeleteState);
    const transferOrderUpdateState = useSelector(getTransferOrdersUpdateState);
    const printPalletState = useSelector(getPalletPrintState);
    const transferOrderState = useSelector(getTransferOrdersDetails);

    const previous = usePrevious({
        transferOrderUpdateState,
        printPalletState,
        transferOrderDeleteState,
    });

    const history = useHistory();

    const location =
        useLocation<{ activeModal: TransferOrderModalsType; transferType: TransferType; zoneType: PlaceZoneType }>();

    const handleModals = useCallback(
        (modalToOpen?: TransferOrderModalsType) => {
            setModals({
                ...Object.keys(modals).reduce<any>((acc, key) => ({ ...acc, [key]: false }), {}),
                ...(modalToOpen ? { [modalToOpen]: true } : {}),
            });
        },
        [modals]
    );

    const getFirstModal = transferOrderState.data?.type === TransferOrderType.auto ? 'finishZoneType' : 'startZone';

    const closeAll = useCallback(() => {
        handleModals(getFirstModal);
        onCancel();
        history.replace({ pathname: getRoute(RoutePathName.movementsTransferOrderList), state: {} });
    }, [getFirstModal, handleModals, history, onCancel]);

    const handleTransferTypeSelect = (type: TransferType | undefined) => {
        setTransferType(type);

        if (transferOrderState.data?.startPlaceZoneType === PlaceZoneType.picking) {
            if (type === TransferType.pallet) {
                handleModals('print');
            } else {
                handleModals('transferQuantity');
            }
        } else {
            handleModals('finishZoneType');
        }
    };

    const handleDepartZoneTypeSelect = (zoneType: PlaceZoneType) => {
        setStartZonetype(zoneType);
        handleModals('placeReference');
    };

    const handleStartZoneSubmit = () => {
        if (transferOrderState.data?.transferedPackages) {
            history.push(getRoute(RoutePathName.movementsTransferOrdersPalletsList));
        } else {
            handleModals('transferType');
        }
    };

    const onPlaceReferenceClose = () => {
        handleModals('startZone');
    };

    const onTransferTypeReturn = () => {
        handleModals('cancel');
    };

    const onPackageQuantityCancel = () => {
        if (transferOrderState.data?.type === TransferOrderType.auto) {
            handleModals('finishZoneType');
        } else {
            handleModals('transferType');
        }
    };

    const handlePackageQuantitySubmit = (quantity: number) => {
        history.push(getRoute(RoutePathName.movementsTransferOrdersPalletsList), {
            packageQuantity: quantity,
            finishZoneType,
        });
    };

    const onCancelTransferOrderCreation = () => {
        if (transferOrderState.data?.type === TransferOrderType.manual) {
            deleteTransferOrder({ id: transferOrderState.data.id });
            resetTransferOrderCreateState();
        } else {
            closeAll();
        }
    };

    const handleStorageModalCancel = () => {
        const type = finishZoneType ?? location.state?.zoneType;
        if ([PlaceZoneType.mass, PlaceZoneType.picking].includes(type)) {
            history.push(getRoute(RoutePathName.movementTransferOrderPalletPlaces), { ...location.state });
        } else {
            handleModals('finishZoneType');
        }
    };
    const handlePalletStoreSucess = () => {
        closeAll();
    };

    const handleFinishZoneType = (zoneType?: PlaceZoneType) => {
        setFinishZoneType(zoneType);

        if (transferOrderState.data?.type === TransferOrderType.auto) {
            history.push(getRoute(RoutePathName.movementsTransferOrdersPalletsList), {
                packageQuantity: transferOrderState.data.packageQuantity,
                finishZoneType: zoneType,
                backRoute: getRoute(RoutePathName.movementsTransferOrderDetails, {
                    transferOrderId: transferOrderState.data.id,
                }),
            });
        } else {
            const _transferType = transferType ?? location?.state?.transferType;

            if (transferOrderState.data?.startPlaceZoneType === PlaceZoneType.picking) {
                if (zoneType === PlaceZoneType.mass) {
                    history.push(getRoute(RoutePathName.movementTransferOrderPalletPlaces), {
                        ...location.state,
                        zoneType,
                        transferType: _transferType,
                    });
                } else {
                    handleModals('storage');
                }
            } else {
                if (_transferType === TransferType.package) {
                    handleModals('transferQuantity');
                } else if (zoneType === PlaceZoneType.accumulation) {
                    handleModals('storage');
                } else {
                    history.push(getRoute(RoutePathName.movementTransferOrderPalletPlaces), {
                        ...location.state,
                        zoneType,
                        transferType: _transferType,
                    });
                }
            }
        }
    };
    const handleFinishZoneTypeReturn = () => {
        if (transferOrderState.data?.type === TransferOrderType.auto) {
            onCancel?.();
        } else {
            if (transferOrderState.data?.startPlaceZoneType === PlaceZoneType.mass) {
                handleModals('transferType');
            } else {
                handleModals('print');
            }
        }
    };

    useEffect(() => {
        if (visible) {
            handleModals(location?.state?.activeModal ? location.state.activeModal : getFirstModal);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useEffect(() => {
        if (visible && previous?.printPalletState.loading && !printPalletState.loading) {
            if (printPalletState.error) {
                message.error("Une erreur est survenue pendant l'impression de la palette");
            }
        }
    }, [
        visible,
        previous?.printPalletState.loading,
        printPalletState.loading,
        printPalletState.success,
        printPalletState.error,
    ]);

    useEffect(() => {
        if (visible && previous?.transferOrderDeleteState.loading && !transferOrderDeleteState.loading) {
            if (transferOrderDeleteState.error) {
                message.error("Une erreur est survenue pendant la suppression de l'ordre de transfert");
            } else {
                closeAll();
            }
        }
    }, [
        visible,
        previous?.transferOrderDeleteState.loading,
        transferOrderDeleteState.loading,
        transferOrderDeleteState.success,
        transferOrderDeleteState.error,
        closeAll,
    ]);

    return visible ? (
        <>
            {modals.startZone && (
                <StartZoneTypeModal
                    onCancel={closeAll}
                    onSelect={handleDepartZoneTypeSelect}
                    visible={modals.startZone}
                />
            )}
            {modals.transferType && (
                <TransferTypeModal
                    onSelect={handleTransferTypeSelect}
                    visible={modals.transferType}
                    onReturn={onTransferTypeReturn}
                />
            )}
            {modals.placeReference && (
                <StartZoneScanModal
                    onSubmit={handleStartZoneSubmit}
                    visible={modals.placeReference}
                    onReturn={onPlaceReferenceClose}
                    zoneType={startZoneType}
                />
            )}
            {modals.finishZoneType && (
                <FinishtZoneTypeModal
                    onSelect={handleFinishZoneType}
                    visible={modals.finishZoneType}
                    onReturn={handleFinishZoneTypeReturn}
                    transferType={transferType ?? location.state?.transferType}
                    startZoneType={transferOrderState.data?.startPlaceZoneType}
                />
            )}
            {modals.print && (
                <PrintModal
                    visible={modals.print}
                    onCancel={() => handleModals('transferType')}
                    palletId={transferOrderState.data?.startZone.pallet?.id}
                    onSuccess={handleModals.bind(null, 'finishZoneType')}
                    mode={WMSMode.movement}
                />
            )}
            {modals.transferQuantity && (
                <PackageQuantity
                    visible={modals.transferQuantity}
                    onReturn={onPackageQuantityCancel}
                    cancelText="Retour"
                    onSubmit={handlePackageQuantitySubmit}
                    title="transfert - colis"
                    subTitle="Quantité à transferer"
                    transferOrderType={transferOrderState.data?.type}
                    max={transferOrderState.data?.startZone?.pallet?.quantity ?? 0}
                />
            )}
            {modals.storage && (
                <FinishZoneScanModal
                    visible={modals.storage}
                    finishZoneType={finishZoneType}
                    onReturn={handleStorageModalCancel}
                    transferType={transferType}
                    onSuccess={handlePalletStoreSucess}
                />
            )}
            {modals.noData && (
                <WarningModal
                    visible={modals.noData}
                    message={`Il n'y a aucun emplacement picking disponible`}
                    onCancel={onCancelTransferOrderCreation}
                />
            )}
            {modals.cancel && (
                <ConfirmModal
                    visible={modals.cancel}
                    onCancel={() => handleModals('transferType')}
                    onOk={onCancelTransferOrderCreation}
                    okText="Continuer"
                    cancelText="Retour"
                    message="En cliquant sur « Continuer » vous annulerez la création de l'ordre de transfert."
                    hasIcon={true}
                />
            )}
        </>
    ) : null;
};

export default TransferOrderCreateModals;
