import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getUser } from '../../store/actions/auth';
import { RoleName } from '../../store/api/apiTypes';

import { getRoute, RoutePathName } from '../../routes';
import { hasRole } from '../../helpers/security';
import Seo from '../../components/Seo';
import MainMenu from '../../components/MainMenu';
import Header from '../../components/Header';

const PlatformHome: FC = () => {
    const user = useSelector(getUser);

    if (user && !hasRole(user, [RoleName.pfAdmin, RoleName.pfUser])) {
        return <Redirect to={getRoute(RoutePathName.home)} />;
    }

    return (
        <>
            <Seo title="Accueil plateforme" />
            <Header />
            <MainMenu isPlatform />
        </>
    );
};

export default PlatformHome;
