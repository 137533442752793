import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Badge, Button, PaginationProps, Select } from 'antd';

import { getLoadingOrderListState, list } from '../../store/actions/loadingOrders';
import { LoadingOrder, LoadingOrderStatus } from '../../store/api/apiTypes';

import { formatDate, translateLoadingOrderStatus } from '../../helpers/i18n';
import { shortcut } from '../../helpers/shortcut';
import { useActions, useArrowNavScope, useIsMobile } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import Header from '../../components/Header';
import List, { ListColumn } from '../../components/list/List';
import Seo from '../../components/Seo';
import FixedFooter from '../../components/FixedFooter';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal from '../../components/CustomModal';
import BasicList from '../../components/BasicList';
import { isZebra } from '../../helpers/enterprise-browser';
import useQueryParams from '../../hooks/queryParams';

type SortValue = 'tourNumber' | 'dateAsc' | 'dateDesc' | 'status' | 'none';
type FilterValue = LoadingOrderStatus | 'all';

const filterScope = 'filterModal';
const sortScope = 'sortModal';

const LoadingOrderList: FC = () => {
    const history = useHistory();
    const isMobile = useIsMobile();
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [isSortModalOpen, setIsSortModalOpen] = useState(false);
    const [listLoadingOrders] = useActions([list.trigger]);
    const listState = useSelector(getLoadingOrderListState);
    const [queryParams, setQueryParams] = useQueryParams('LoadingOrderList');
    const sort = (queryParams.get('sort') ?? 'none') as SortValue;
    const filter = (queryParams.get('filter') ?? 'all') as FilterValue;
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const [tempFilter, setTempFilter] = useState<FilterValue>(filter);
    const [tempSort, setTempSort] = useState<SortValue>(sort);
    useArrowNavScope(filterScope, !isFilterModalOpen);
    useArrowNavScope(sortScope, !isSortModalOpen);
    const onClickLoadingOrder = (record: LoadingOrder) => {
        history.push(getRoute(RoutePathName.loadingOrderDetails, { loadingOrderId: record.id }));
    };
    const onFilter = useCallback(() => {
        setIsFilterModalOpen(false);
        setQueryParams({
            filter: tempFilter,
            page: 0,
        });
    }, [setQueryParams, tempFilter]);
    const onSort = useCallback(() => {
        setIsSortModalOpen(false);
        setQueryParams({
            sort: tempSort,
            page: 0,
        });
    }, [setQueryParams, tempSort]);
    const onCancelSort = useCallback(() => {
        setIsSortModalOpen(false);
        setQueryParams({
            sort: undefined,
            page: 0,
        });
    }, [setQueryParams]);
    const onCancelFilter = useCallback(() => {
        setIsFilterModalOpen(false);
        setQueryParams({
            filter: undefined,
            page: 0,
        });
    }, [setQueryParams]);
    const onPaginationChange: PaginationProps['onChange'] = (p) => {
        setQueryParams({
            page: p ? p - 1 : 0,
        });
    };
    const onLoadMore = () => {
        fetchLoadingOrders(true);
    };
    const columns: Array<ListColumn<LoadingOrder>> = [
        {
            dataIndex: 'tourNumber',
            title: 'Tournée',
            flex: '1 1 25%',
        },
        {
            key: 'date',
            title: 'Date charg.',
            flex: '1 1 25%',
            render: (record) =>
                formatDate(record.date, {
                    day: 'numeric',
                    month: 'numeric',
                    year: '2-digit',
                }),
        },
        {
            dataIndex: 'plateformCode',
            title: 'Code plat.',
            flex: '1 1 23%',
        },
        {
            key: 'status',
            title: 'Statut',
            flex: '1 1 27%',
            render: (record) => translateLoadingOrderStatus(record.status),
        },
    ];
    const fetchLoadingOrders = useCallback(
        (loadMore?: boolean) => {
            listLoadingOrders(
                {
                    status:
                        filter !== 'all'
                            ? filter
                            : Object.values(LoadingOrderStatus).filter((s) => s !== LoadingOrderStatus.loaded),
                    ...(sort === 'tourNumber'
                        ? {
                              sort: 'tourNumber',
                          }
                        : {}),
                    ...(sort === 'dateAsc'
                        ? {
                              sort: 'date',
                              sortOrder: 'asc',
                          }
                        : {}),
                    ...(sort === 'none' || sort === 'dateDesc'
                        ? {
                              sort: 'date',
                              sortOrder: 'desc',
                          }
                        : {}),
                    ...(sort === 'status' ? { sort: 'status' } : {}),
                    page: loadMore ? page + 1 : page,
                },
                { loadMore }
            );
        },
        [listLoadingOrders, filter, sort, page]
    );

    useEffect(() => {
        fetchLoadingOrders();
    }, [fetchLoadingOrders]);

    useEffect(() => {
        if (isFilterModalOpen) {
            shortcut.scope = 'filterModal';
        } else {
            shortcut.resetScope();
        }
    }, [isFilterModalOpen]);

    useEffect(() => {
        if (isSortModalOpen) {
            shortcut.scope = 'orderModal';
        } else {
            shortcut.resetScope();
        }
    }, [isSortModalOpen]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Préparation" />
            <Header title="Préparation" backRoute={getRoute(RoutePathName.home)} />
            <List<LoadingOrder>
                columns={columns}
                data={listState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowEnterPress={onClickLoadingOrder}
                onRowClick={onClickLoadingOrder}
                isLoading={isZebra ? listState.loading && listState.data?.page === undefined : listState.loading}
                isRowHiglighted={(record) =>
                    [LoadingOrderStatus.completed, LoadingOrderStatus.toBeLoaded].includes(record.status)
                }
                loadingRowsCount={11}
                title="Ordres de chargement"
                style={isMobile ? { marginTop: 24 } : undefined}
                pagination={
                    isZebra
                        ? false
                        : {
                              total: listState.data?.totalCount,
                              pageSize: listState.data?.pageSize,
                              hideOnSinglePage: true,
                              current: page + 1,
                              onChange: onPaginationChange,
                          }
                }
            />
            {isZebra && listState.data && listState.data.page < listState.data.pageCount - 1 && (
                <Button
                    onClick={onLoadMore}
                    loading={listState.loading}
                    size="small"
                    style={{ marginTop: 16, fontSize: 14 }}
                    block
                >
                    Plus de résultats
                </Button>
            )}
            <FixedFooter>
                <BasicList inline inlineStretch>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="f1"
                                onClick={setIsFilterModalOpen.bind(null, true)}
                                size={isMobile ? 'middle' : 'large'}
                                block
                            >
                                Filtrer&nbsp;
                                <Badge count={filter !== 'all' ? 1 : 0} />
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="f2"
                                onClick={setIsSortModalOpen.bind(null, true)}
                                size={isMobile ? 'middle' : 'large'}
                                block
                            >
                                Trier&nbsp;
                                <Badge count={sort !== 'none' ? 1 : 0} />
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                </BasicList>
            </FixedFooter>
            <CustomModal
                visible={isFilterModalOpen}
                title="Filtrer par statut"
                footer={
                    <>
                        <ArrowNavItem scope={filterScope}>
                            <ButtonWithShortcut
                                type="primary"
                                shortcut="enter"
                                shortcutScope="filterModal"
                                onClick={onFilter}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={filterScope}>
                            <ButtonWithShortcut
                                type="primary"
                                shortcut="esc"
                                shortcutScope="filterModal"
                                onClick={onCancelFilter}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                }
                width={368}
                altTitle
            >
                <ArrowNavItem scope={filterScope}>
                    <Select<FilterValue> style={{ width: '100%' }} value={tempFilter} onChange={setTempFilter}>
                        <Select.Option value="all">Tous les statuts</Select.Option>
                        {Object.values(LoadingOrderStatus)
                            .filter((s) => s !== LoadingOrderStatus.loaded)
                            .map((size) => (
                                <Select.Option value={size} key={size}>
                                    {translateLoadingOrderStatus(size)}
                                </Select.Option>
                            ))}
                    </Select>
                </ArrowNavItem>
            </CustomModal>
            <CustomModal
                visible={isSortModalOpen}
                title="Trier"
                footer={
                    <>
                        <ArrowNavItem scope={sortScope}>
                            <ButtonWithShortcut
                                type="primary"
                                shortcut="enter"
                                shortcutScope="orderModal"
                                onClick={onSort}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={sortScope}>
                            <ButtonWithShortcut
                                type="primary"
                                shortcut="esc"
                                shortcutScope="orderModal"
                                onClick={onCancelSort}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                }
                width={368}
                altTitle
            >
                <ArrowNavItem scope={sortScope}>
                    <Select<SortValue> style={{ width: '100%' }} value={tempSort} onChange={setTempSort}>
                        <Select.Option value="none">Par défaut</Select.Option>
                        <Select.Option value="tourNumber">N° de tournée</Select.Option>
                        <Select.Option value="dateDesc">Plus récent</Select.Option>
                        <Select.Option value="dateAsc">Plus ancien</Select.Option>
                        <Select.Option value="status">Statut</Select.Option>
                    </Select>
                </ArrowNavItem>
            </CustomModal>
        </FixedFooter.Wrapper>
    );
};

export default LoadingOrderList;
