import { movementBrokensSaga } from './../actions/movementBrokens';
import { fork } from 'redux-saga/effects';

import { apiSaga } from '../actions/api';
import { authSaga } from '../actions/auth';
import { controlLoadingOrdersSaga } from '../actions/controlLoadingOrders';
import { controlOrderPalletsSaga } from '../actions/controlOrderPallets';
import { controlRegularizationSaga } from '../actions/controlRegularization';
import { customerOrdersSaga } from '../actions/customerOrders';
import { customerOrderPlatformReasonCodesSaga } from '../actions/customerOrderPlatformReasonCodes';
import { customersSaga } from '../actions/customers';
import { expeditionsSaga } from '../actions/expeditions';
import { inventoriesSaga } from '../actions/inventories';
import { loadingOrdersSaga } from '../actions/loadingOrders';
import { logsSaga } from '../actions/logs';
import { movementHistoriesSaga } from '../actions/movementHistories';
import { orderFurnituresSaga } from '../actions/orderFurnitures';
import { orderParcelsSaga } from '../actions/orderParcels';
import { ordersSaga } from '../actions/orders';
import { organizationsSaga } from '../actions/organizations';
import { palletPlacesSaga } from '../actions/palletPlaces';
import { palletsSaga } from '../actions/pallets';
import { parcelsSaga } from '../actions/parcels';
import { placesSaga } from '../actions/places';
import { placeAlveolesSaga } from '../actions/placeAlveoles';
import { placeLanesSaga } from '../actions/placeLanes';
import { placeSectorsSaga } from '../actions/placeSectors';
import { placeStoresSaga } from '../actions/placeStores';
import { platformAnomalyCodesSaga } from '../actions/platformAnomalyCodes';
import { platformLoadingsSaga } from '../actions/platformLoadings';
import { platformReasonCodesSaga } from '../actions/platformReasonCodes';
import { platformReceptionsSaga } from '../actions/platformReceptions';
import { preparationControlSaga } from '../actions/preparationControl';
import { preparationsSaga } from '../actions/preparations';
import { printersSaga } from '../actions/printers';
import { productionsSaga } from '../actions/productions';
import { purchaseOrdersSaga } from '../actions/purchaseOrders';
import { relatedPalletsSaga } from '../actions/relatedPallets';
import { resupplyOrdersSaga } from '../actions/resupplyOrders';
import { sitesSaga } from '../actions/sites';
import { stockDifferencesSaga } from '../actions/stockDifferences';
import { stockResearchSaga } from '../actions/stockResearch';
import { usersSaga } from '../actions/users';
import { transferOrdersSaga } from '../actions/transferOrders';
import { customerReturnSaga } from '../actions/customerReturn';
import { repairsSaga } from '../actions/repairs';
import { movementsBackToWorkShopsSaga } from '../actions/MovementsBackToWorkshops';
import { platformReturnsSaga } from '../actions/platformReturn';
import { receptionsSaga } from '../actions/receptions';
import { machinesSaga } from '../actions/machines';
import { incidentsSaga } from '../actions/incidents';
import { machinesIncidentsSaga } from '../actions/machinesIncidents';

export default function* mainSaga() {
    yield fork(apiSaga);
    yield fork(authSaga);
    yield fork(controlLoadingOrdersSaga);
    yield fork(controlOrderPalletsSaga);
    yield fork(controlRegularizationSaga);
    yield fork(customerOrdersSaga);
    yield fork(customerOrderPlatformReasonCodesSaga);
    yield fork(customersSaga);
    yield fork(expeditionsSaga);
    yield fork(inventoriesSaga);
    yield fork(loadingOrdersSaga);
    yield fork(logsSaga);
    yield fork(movementHistoriesSaga);
    yield fork(orderFurnituresSaga);
    yield fork(orderParcelsSaga);
    yield fork(ordersSaga);
    yield fork(organizationsSaga);
    yield fork(palletPlacesSaga);
    yield fork(palletsSaga);
    yield fork(parcelsSaga);
    yield fork(placesSaga);
    yield fork(placeAlveolesSaga);
    yield fork(placeLanesSaga);
    yield fork(placeSectorsSaga);
    yield fork(placeStoresSaga);
    yield fork(platformAnomalyCodesSaga);
    yield fork(platformLoadingsSaga);
    yield fork(platformReasonCodesSaga);
    yield fork(platformReceptionsSaga);
    yield fork(preparationControlSaga);
    yield fork(preparationsSaga);
    yield fork(printersSaga);
    yield fork(productionsSaga);
    yield fork(purchaseOrdersSaga);
    yield fork(relatedPalletsSaga);
    yield fork(resupplyOrdersSaga);
    yield fork(sitesSaga);
    yield fork(stockDifferencesSaga);
    yield fork(stockResearchSaga);
    yield fork(usersSaga);
    yield fork(repairsSaga);
    yield fork(movementBrokensSaga);
    yield fork(transferOrdersSaga);
    yield fork(movementsBackToWorkShopsSaga);
    yield fork(platformReturnsSaga);
    yield fork(customerReturnSaga);
    yield fork(receptionsSaga);
    yield fork(machinesSaga);
    yield fork(incidentsSaga);
    yield fork(machinesIncidentsSaga);
}
