import React, { FC, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { message } from 'antd';

import { Preparation, PreparationType } from '../../store/api/apiTypes';
import { getPreparationStartState, start } from '../../store/actions/preparations';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';

const shortcutScope = 'PreparationTypeModal';

interface PreparationTypeModalProps extends CustomModalProps {
    preparationId?: Preparation['id'];
}

const PreparationTypeModal: FC<PreparationTypeModalProps> = ({ visible, onCancel, preparationId }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const history = useHistory();
    const { loadingOrderId, customerOrderId } = useParams<{ loadingOrderId: string; customerOrderId: string }>();
    const [sendPreparation] = useActions([start.trigger]);
    const preparationStartState = useSelector(getPreparationStartState);
    const previous = usePrevious({ preparationStartState });
    const onPrimary = (type: PreparationType) => {
        sendPreparation({ preparationId, preparationType: type });
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    useEffect(() => {
        if (visible && previous?.preparationStartState.loading && !preparationStartState.loading) {
            if (!preparationStartState.error) {
                const route =
                    preparationStartState.data?.picking && !preparationStartState.data?.direct
                        ? RoutePathName.preparationPicking
                        : RoutePathName.preparationDetails;

                history.push(
                    getRoute(route, {
                        preparationId: preparationId ?? '',
                        loadingOrderId: loadingOrderId ?? '',
                        customerOrderId: customerOrderId ?? '',
                    })
                );
            } else if (preparationStartState.error?.status === 400) {
                message.error("Il n'y a pas de stock disponible pour l'instant");
            } else {
                message.error("Une erreur est survenue lors de l'envoi du type");
            }
        }
    }, [
        visible,
        history,
        loadingOrderId,
        customerOrderId,
        preparationId,
        preparationStartState.data?.direct,
        preparationStartState.data?.picking,
        preparationStartState.error,
        preparationStartState.loading,
        previous?.preparationStartState.loading,
    ]);

    return (
        <CustomModal
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="1"
                            type="primary"
                            shortcutScope={shortcutScope}
                            onClick={onPrimary.bind(null, PreparationType.picking)}
                            loading={preparationStartState.loading}
                        >
                            Picking
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="2"
                            type="primary"
                            shortcutScope={shortcutScope}
                            onClick={onPrimary.bind(null, PreparationType.directOrPicking)}
                            loading={preparationStartState.loading}
                        >
                            Direct / Picking
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClose}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            visible={visible}
            onCancel={onCancel}
            title="Type de préparation"
            width={368}
            keyboard={false}
            altTitle
        />
    );
};

export default PreparationTypeModal;
