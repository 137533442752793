import React, { FC } from 'react';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import { Pallet } from '../../../store/api/apiTypes';
import TitleBlack from '../../../components/TitleBlack';

interface PalletPlaceModalContentProps {
    pallet: Pallet;
}

interface PalletPlaceModalFooterProps {
    shortcutScope: string;
    onValidate: () => void;
    onClose: () => void;
    onClickEditPlace: () => void;
    loading: boolean;
}

const PalletPlaceModalContent: FC<PalletPlaceModalContentProps> = ({ pallet }) => {
    const { sector, locker, spot } = pallet.reservedPlace ?? {};
    return (
        <>
            <TitleBlack level={1}>Emplacement proposé</TitleBlack>
            <div className="centered-description">
                <div>
                    <p>Secteur</p>
                    <p>{sector ?? '—'}</p>
                </div>
                <div>
                    <p>Casier</p>
                    <p>{locker ?? '—'}</p>
                </div>
                <div>
                    <p>Emplacement</p>
                    <p>{spot ?? '—'}</p>
                </div>
            </div>
        </>
    );
};

const PalletPlaceModalFooter: FC<PalletPlaceModalFooterProps> = ({
    shortcutScope,
    onValidate,
    onClose,
    onClickEditPlace,
    loading,
}) => {
    return (
        <>
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut
                    shortcut="enter"
                    shortcutScope={shortcutScope}
                    type="primary"
                    loading={loading}
                    onClick={() => onValidate()}
                >
                    Valider
                </ButtonWithShortcut>
            </ArrowNavItem>
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut
                    shortcut="f1"
                    shortcutScope={shortcutScope}
                    type="primary"
                    onClick={() => onClickEditPlace()}
                    block
                    ghost
                >
                    Modifier
                </ButtonWithShortcut>
            </ArrowNavItem>
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut
                    shortcut="esc"
                    shortcutScope={shortcutScope}
                    type="primary"
                    onClick={() => onClose()}
                    ghost
                >
                    Annuler
                </ButtonWithShortcut>
            </ArrowNavItem>
        </>
    );
};

export { PalletPlaceModalContent, PalletPlaceModalFooter };
