import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 72 56">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M43.681 0c.68 0 1.23.546 1.23 1.22v20.09h18.766c.679 0 1.23.546 1.23 1.22v21.455c0 .037-.02.069-.023.104H70.8c.664 0 1.2.533 1.2 1.191v9.529c0 .657-.536 1.191-1.2 1.191h-9.6c-.664 0-1.2-.534-1.2-1.191v-3.573H42v3.573c0 .657-.536 1.191-1.2 1.191h-9.6c-.662 0-1.2-.534-1.2-1.191v-3.573H12v3.573c0 .657-.538 1.191-1.2 1.191H1.2C.538 56 0 55.466 0 54.809V45.28c0-.658.538-1.19 1.2-1.19h8.422c-.004-.036-.022-.068-.022-.105V22.53c0-.674.55-1.22 1.229-1.22h5.453V1.22c0-.674.55-1.22 1.228-1.22zM69.6 51.236h-7.2v2.382h7.2v-2.382zm-30 0h-7.2v2.382h7.2v-2.382zm-30 0H2.4v2.382h7.2v-2.382zm60-4.764H2.4v2.382h67.2v-2.382zM52.138 23.749v3.781c0 .674-.551 1.221-1.23 1.221h-1.115c-.68 0-1.229-.547-1.229-1.22v-3.778l-9.828.003v19.009h23.708V23.751l-10.306-.002zm-16.372 0H26.3v3.784c0 .672-.55 1.22-1.228 1.22h-1.677a1.225 1.225 0 01-1.229-1.22l-.018-3.767H12.041l.018 18.997h23.707V23.749zm11.954 11.47c.305 0 .554.246.554.55v4.963c0 .304-.249.55-.554.55h-6.553a.553.553 0 01-.554-.55v-4.963c0-.304.248-.55.554-.55h6.553zm-26.623 0c.306 0 .553.246.553.55v4.963c0 .304-.247.55-.553.55h-6.553a.553.553 0 01-.554-.55v-4.963c0-.304.248-.55.554-.55h6.553zm7.631-32.78H18.74v18.869h11.602c.085.001.17.01.254.026.083-.016.168-.025.254-.026h11.602V2.438H32.366V6.22c0 .673-.55 1.22-1.228 1.22h-1.18c-.68 0-1.23-.547-1.23-1.22V2.438zm-1.06 11.174c.305 0 .554.246.554.55v4.963c0 .304-.249.55-.554.55h-6.553a.552.552 0 01-.554-.55v-4.963c0-.304.247-.55.554-.55h6.553zM63.966.337l.155.134 7.315 7.153c.321.274.533.668.56 1.11l.003.04.001.061v.033l-.001.032-.002.018-.005.082-.012.092-.008.043a1.567 1.567 0 01-.317.692l-.136.152-7.398 7.234a1.67 1.67 0 01-2.324 0 1.582 1.582 0 01-.137-2.122l.137-.151 4.595-4.497H51.945c-.908 0-1.644-.72-1.644-1.608 0-.824.635-1.504 1.452-1.596l.192-.011 14.433-.001-4.581-4.483A1.582 1.582 0 0161.66.622l.137-.151a1.672 1.672 0 012.17-.134z"
        />
    </svg>
);

const IconReception: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-reception${props.className ? ` ${props.className}` : ''}`} />
);

export default IconReception;
