import {
    Col,
    Descriptions,
    Empty,
    message,
    Pagination,
    PaginationProps,
    Result,
    Row,
    Skeleton,
    Spin,
    Typography,
} from 'antd';
import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import '../../../assets/styles/Rdv.less';

import { CustomerOrder, Expedition, ExpeditionStatus, Pallet, PalletStatus } from '../../../store/api/apiTypes';
import { list, getExpeditionListState } from '../../../store/actions/expeditions';
import { details, getCustomerStateById } from '../../../store/actions/customers';

import {
    defaultErrorMessage,
    formatDate,
    formatNumber,
    formatTimeFromSeconds,
    translateDayOfWeek,
    translatePalletStatus,
} from '../../../helpers/i18n';
import { useActions } from '../../../hooks';
import useQueryParams from '../../../hooks/queryParams';
import { getRoute, RoutePathName } from '../../../routes';
import FixedFooter from '../../../components/FixedFooter';
import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import PageHeader from '../../../components/PageHeader';
import Seo from '../../../components/Seo';
import BasicList from '../../../components/BasicList';

const Rdv: FC = () => {
    const history = useHistory();
    const location = useLocation();
    const [urlSearchParams, setQueryParams] = useQueryParams('Rdv');
    const page = urlSearchParams.get('page') !== null ? parseInt(urlSearchParams.get('page')!, 10) || 0 : 0;
    const customerId = urlSearchParams.get('customerId') ? Number(urlSearchParams.get('customerId')) : undefined;
    const [getCustomer, listExpeditions] = useActions([details.trigger, list.trigger]);
    const customerDetailsState = useSelector(getCustomerStateById(customerId ?? -1));
    const expeditionListState = useSelector(getExpeditionListState);
    const onCustomerOrderRowEnter = useCallback(
        (record: CustomerOrder) => {
            history.push({
                pathname: getRoute(RoutePathName.platformRdvCustomerOrder, { customerOrderId: record.id }),
                search: location.search,
            });
        },
        [history, location.search]
    );
    const onPaginationChange: PaginationProps['onChange'] = (p) => {
        setQueryParams({
            page: p ? p - 1 : 0,
        });
    };

    if (!customerId) {
        message.error('Vous devez spécifier un id client');
        history.push(getRoute(RoutePathName.platformHome));
    }

    useEffect(() => {
        getCustomer({ customerId });
    }, [getCustomer, customerId]);

    useEffect(() => {
        if (customerDetailsState.data?.reference && customerDetailsState.data?.platformOrganization?.slug) {
            listExpeditions({
                customerReference: customerDetailsState.data?.reference,
                plateformCode: customerDetailsState.data?.platformOrganization?.slug,
                status: [ExpeditionStatus.toBeProcessed, ExpeditionStatus.inProgress],
                page,
            });
        }
    }, [
        customerDetailsState.data?.platformOrganization?.slug,
        customerDetailsState.data?.reference,
        listExpeditions,
        page,
    ]);

    const renderItem = useCallback(
        (expedition: Expedition) => {
            const customerOrderColumns: Array<ListColumn<CustomerOrder>> = [
                {
                    dataIndex: 'loadingOrder.organization.name',
                    title: 'Usine',
                    flex: '1 1 10%',
                },
                {
                    dataIndex: 'loadingOrder.tourNumber',
                    title: 'Tournée',
                    flex: '1 1 15%',
                },
                {
                    dataIndex: 'customer.label',
                    title: 'Client',
                    flex: '1 1 35%',
                },
                {
                    key: 'platformReasonCode',
                    title: 'Motif modification',
                    flex: '1 1 20%',
                    render: (record) =>
                        record.customerOrderPlatformReasonCodes?.[0]?.platformReasonCode?.reference ?? '—',
                },
                {
                    key: 'date',
                    title: 'Rendez-vous',
                    flex: '1 1 20%',
                    render: (record) =>
                        `${translateDayOfWeek(record.day, true)} ${formatTimeFromSeconds(record.time)}`.toUpperCase(),
                },
            ];
            const palletColumns: Array<ListColumn<Pallet>> = [
                {
                    dataIndex: 'reference',
                    title: 'N° de lot',
                    flex: '1 1 20%',
                },
                {
                    key: 'type',
                    title: 'Type',
                    flex: '1 1 20%',
                    render: (record) => (record.vrac ? 'Vrac' : 'Palette'),
                },
                {
                    key: 'date',
                    title: 'Date de réception',
                    flex: '1 1 30%',
                    render: (record) => formatDate(record?.platformReceptionDate),
                },
                {
                    key: 'status',
                    title: 'Statut',
                    flex: '1 1 30%',
                    render: (record) => translatePalletStatus(record.status, true),
                },
                {
                    key: 'weight',
                    title: 'Poids (kg)',
                    flex: '1 1 30%',
                    render: (record) => formatNumber(record.weight),
                },
            ];

            return (
                <li className="lot-visualization-item" key={expedition.id}>
                    <header>
                        <Typography.Title level={2}>
                            Livraison du {formatDate(expedition.deliveryDate)} - Tour : {expedition.tour}
                        </Typography.Title>
                    </header>

                    <List<CustomerOrder>
                        className="rdv-list"
                        columns={customerOrderColumns}
                        data={expedition.customerOrders}
                        rowKey={(record) => `${record.id}`}
                        onRowClick={onCustomerOrderRowEnter}
                        onRowEnterPress={onCustomerOrderRowEnter}
                        renderCellFooter={(record) => (
                            <List<Pallet>
                                columns={palletColumns}
                                data={record.pallets}
                                rowKey={(r) => `${r.id}`}
                                isRowHiglighted={(record) => record.status === PalletStatus.receivedInPlatform}
                                isRowWarning={(record) => record.status === PalletStatus.receivedInPlatformDamaged}
                                focusable={false}
                                alt
                            />
                        )}
                        headerInCell
                    />
                </li>
            );
        },
        [onCustomerOrderRowEnter]
    );

    return (
        <FixedFooter.Wrapper>
            <Seo title="Livraisons" />
            <Header title="Livraisons" backRoute={getRoute(RoutePathName.platformHome)} />
            <PageHeader>
                <Row wrap={false} gutter={16}>
                    <Col xs={12}>
                        <Spin spinning={customerDetailsState.loading}>
                            <Descriptions column={2} size="small" colon={false} layout="vertical">
                                <Descriptions.Item label="Plateforme">
                                    {customerDetailsState.data?.platformOrganization?.slug ?? '—'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Code client">
                                    {customerDetailsState.data?.reference ?? '—'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Client" span={2}>
                                    {expeditionListState.data?.items?.[0]?.customerOrders?.[0]?.customer?.label ?? '—'}
                                </Descriptions.Item>
                            </Descriptions>
                        </Spin>
                    </Col>
                    <Col xs={12}>
                        <table className="custom-table custom-table-small">
                            <thead>
                                <tr>
                                    <th>Société</th>
                                    <th>Jour de livraison</th>
                                    <th>Tour</th>
                                    <th>Heure</th>
                                </tr>
                            </thead>
                            <tbody className="text-uppercase">
                                <tr>
                                    <td>{customerDetailsState.data?.parisotOrganization?.name}</td>
                                    <td>{translateDayOfWeek(customerDetailsState.data?.parisotDay, true)}</td>
                                    <td>{customerDetailsState.data?.parisotTour ?? '—'}</td>
                                    <td>{formatTimeFromSeconds(customerDetailsState.data?.parisotTime)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </PageHeader>
            {expeditionListState.loading ? (
                <BasicList>
                    {Array.from({ length: 5 }, (_, index) => (
                        <li key={index}>
                            <Skeleton paragraph={false} title active />
                            <Skeleton active />
                        </li>
                    ))}
                </BasicList>
            ) : expeditionListState.error ? (
                <Result title={defaultErrorMessage} />
            ) : expeditionListState.data?.items.length ? (
                <>
                    <BasicList className="lot-visualization-list">
                        {expeditionListState.data?.items.map(renderItem)}
                    </BasicList>
                    <Pagination
                        total={expeditionListState.data?.totalCount}
                        pageSize={expeditionListState.data?.pageSize ?? 0}
                        current={page + 1}
                        onChange={onPaginationChange}
                        showSizeChanger={false}
                        hideOnSinglePage
                    />
                </>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </FixedFooter.Wrapper>
    );
};

export default Rdv;
