import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 26 21">
        <path
            fill="currentColor"
            fillRule="nonzero"
            d="M7.328 20.64v-5.984H25.25V0h-2.703v11.953H7.328V5.984L0 13.313z"
        />
    </svg>
);

const IconEnter: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-enter${props.className ? ` ${props.className}` : ''}`} />
);

export default IconEnter;
