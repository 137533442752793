import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Descriptions, Spin } from 'antd';

import {
    details,
    getInventoryPlacesListState,
    getInventoryStateById,
    placeList as inventoryPlaceList,
} from '../../store/actions/inventories';
import { InventoryPlace, InventoryStatus, InventoryType, Permission } from '../../store/api/apiTypes';

import List, { ListColumn } from '../../components/list/List';
import PageHeader from '../../components/PageHeader';
import Header from '../../components/Header';
import Seo from '../../components/Seo';
import { useActions, useIsDesktop, useIsMobile } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import { formatDate, formatNumber, translateInventoryStatus, translateInventoryType } from '../../helpers/i18n';
import { isZebra } from '../../helpers/enterprise-browser';
import { getLocker, getOperatorName } from '../../helpers';
import FixedFooter from '../../components/FixedFooter';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import InventoryOperatorModal from './InventoryOperatorModal';
import { getUser } from '../../store/actions/auth';
import { hasPermission } from '../../helpers/security';
import BasicList from '../../components/BasicList';
import InventoryRemoveConfirmModal from './InventoryRemoveConfirmModal';
import { show } from '@ebay/nice-modal-react';

const getInventoryPlaceParcel = (data?: InventoryPlace) => {
    if (data?.theoreticalQuantity === 0 && data?.foundParcel) {
        return data?.foundParcel;
    } else if ((data?.theoreticalQuantity ?? 0) > 0 && data?.parcel) {
        return data?.parcel;
    } else {
        return undefined;
    }
};

const InventoryDetails: FC = () => {
    const isMobile = useIsMobile();
    const isDesktop = useIsDesktop();
    const history = useHistory();
    const { inventoryId } = useParams<{ inventoryId: string }>();
    const [isOperatorModalVisible, setIsOperatorModalVisible] = useState(false);
    const [fetchInventory, fetchInventoryPlaces] = useActions([details.trigger, inventoryPlaceList.trigger]);
    const inventoryState = useSelector(getInventoryStateById(parseInt(inventoryId, 10)));
    const inventoryPlacesState = useSelector(getInventoryPlacesListState);
    const user = useSelector(getUser);
    const onClickPlace = (record: InventoryPlace) => {
        history.push(getRoute(RoutePathName.inventoryPlaceDetails, { inventoryId, inventoryPlaceId: record.id }));
    };
    const onClickRemoveInventory = () => {
        show('inventoryRemoveConfirmModal');
    };
    const onLoadMore = () => {
        fetchInventoryPlaces(
            {
                inventoryId,
                page: (inventoryPlacesState.data?.page ?? 0) + 1,
            },
            { loadMore: true }
        );
    };
    const canEditDesktop = !isZebra && hasPermission(user, Permission.inventory);
    const canRemoveInventory = canEditDesktop && inventoryState.data?.status === InventoryStatus.toBeProcessed;
    const zebraColumns = (
        [
            inventoryState.data?.type === InventoryType.place && {
                key: 'parcel.reference',
                title: 'Référence',
                flex: '1 1 25%',
                render: (record) => getInventoryPlaceParcel(record)?.reference ?? '—',
            },
            inventoryState.data?.type === InventoryType.parcel && {
                dataIndex: 'place.store.number',
                title: 'Site',
                flex: '1 1 25%',
            },
            inventoryState.data?.type === InventoryType.parcel && {
                dataIndex: 'place.sector',
                title: 'Secteur',
                flex: '1 1 25%',
            },
            {
                key: 'locker',
                title: 'Casier',
                flex: '1 1 25%',
                render: (record) => getLocker(record.place),
            },
            {
                dataIndex: 'place.spot',
                title: 'Emplacement',
                flex: '1 1 25%',
            },
        ] as Array<ListColumn<InventoryPlace>>
    ).filter(Boolean);

    const desktopColumns = (
        [
            ...(inventoryState.data?.status === InventoryStatus.completed
                ? [
                      {
                          dataIndex: 'place.store.number',
                          title: 'Site',
                          flex: '1 1 25%',
                      },
                      {
                          dataIndex: 'place.sector',
                          title: 'Secteur',
                          flex: '1 1 25%',
                      },
                  ]
                : []),
            {
                key: 'locker',
                title: 'Casier',
                flex: '1 1 25%',
                render: (record) => getLocker(record.place),
            },
            {
                dataIndex: 'place.spot',
                title: 'Emplacement',
                flex: '1 1 25%',
            },
            {
                key: 'reference',
                title: 'Référence',
                flex: '1 1 25%',
                render: (record) => getInventoryPlaceParcel(record)?.reference ?? '—',
            },
            {
                key: 'quantity',
                title: 'Qté théorique',
                flex: '1 1 25%',
                render: (record) => formatNumber(record.theoreticalQuantity),
            },
            ...(inventoryState.data?.status === InventoryStatus.completed
                ? [
                      {
                          key: 'trueQuantity',
                          title: 'Qté réelle',
                          flex: '1 1 25%',
                          render: (record: InventoryPlace) => formatNumber(record.quantity),
                      },
                      {
                          key: 'variation',
                          title: 'Variation',
                          flex: '1 1 25%',
                          render: (record: InventoryPlace) =>
                              formatNumber((record.quantity ?? 0) - (record.theoreticalQuantity ?? 0)),
                      },
                  ]
                : []),
        ] as Array<ListColumn<InventoryPlace>>
    ).filter(Boolean);

    const columns = isZebra ? zebraColumns : desktopColumns;

    useEffect(() => {
        fetchInventory({ inventoryId });
        fetchInventoryPlaces({ inventoryId });
    }, [fetchInventory, fetchInventoryPlaces, inventoryId]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Inventaire" />
            <Header
                title="Inventaire"
                backRoute={
                    isZebra
                        ? getRoute(RoutePathName.home)
                        : inventoryState.data?.status === InventoryStatus.completed
                        ? getRoute(RoutePathName.inventoryHistory)
                        : getRoute(RoutePathName.inventoryCurrent)
                }
            />
            <PageHeader>
                <Spin spinning={inventoryState.loading}>
                    {isZebra ? (
                        <Descriptions column={{ xs: 4 }} size="small" colon={false} layout="vertical">
                            {inventoryState.data?.type === InventoryType.place && (
                                <>
                                    <Descriptions.Item label="Site">
                                        {inventoryState.data?.placeStore?.number}
                                    </Descriptions.Item>
                                    {!!inventoryState.data?.sector && (
                                        <Descriptions.Item label="Secteur">
                                            {inventoryState.data?.sector}
                                        </Descriptions.Item>
                                    )}
                                    {(inventoryState.data?.lane ??
                                        inventoryState.data?.alveole ??
                                        inventoryState.data?.level) && (
                                        <Descriptions.Item label="Casier">{`${inventoryState.data?.lane ?? ''}${
                                            inventoryState.data?.alveole ?? ''
                                        }${inventoryState.data?.level ?? ''}`}</Descriptions.Item>
                                    )}
                                </>
                            )}
                            {inventoryState.data?.type === InventoryType.parcel && (
                                <>
                                    <Descriptions.Item label="Référence">
                                        {inventoryState.data?.parcel?.reference}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Désignation">
                                        {inventoryState.data?.parcel?.label}
                                    </Descriptions.Item>
                                </>
                            )}
                            <Descriptions.Item label="Statut">
                                {translateInventoryStatus(inventoryState.data?.status)}
                            </Descriptions.Item>
                        </Descriptions>
                    ) : (
                        <Descriptions column={5} size="small" colon={false} layout="vertical">
                            <Descriptions.Item label="Date">{formatDate(inventoryState.data?.date)}</Descriptions.Item>
                            <Descriptions.Item label="Heure">
                                {formatDate(inventoryState.data?.date, {
                                    minute: '2-digit',
                                    hour: '2-digit',
                                })}
                            </Descriptions.Item>
                            <Descriptions.Item label="Type">
                                {translateInventoryType(inventoryState.data?.type)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Statut">
                                {translateInventoryStatus(inventoryState.data?.status)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Cariste">
                                {getOperatorName(inventoryState.data?.operator)}
                            </Descriptions.Item>
                            {inventoryState.data?.type === InventoryType.place && (
                                <>
                                    <Descriptions.Item label="Site">
                                        {inventoryState.data?.placeStore?.number}
                                    </Descriptions.Item>
                                    {!!inventoryState.data?.sector && (
                                        <Descriptions.Item label="Secteur">
                                            {inventoryState.data?.sector}
                                        </Descriptions.Item>
                                    )}
                                    {(inventoryState.data?.lane ??
                                        inventoryState.data?.alveole ??
                                        inventoryState.data?.level) && (
                                        <Descriptions.Item label="Casier">{`${inventoryState.data?.lane ?? ''}${
                                            inventoryState.data?.alveole ?? ''
                                        }${inventoryState.data?.level ?? ''}`}</Descriptions.Item>
                                    )}
                                </>
                            )}
                            {inventoryState.data?.type === InventoryType.parcel && (
                                <>
                                    <Descriptions.Item label="Référence">
                                        {inventoryState.data?.parcel?.reference}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Désignation">
                                        {inventoryState.data?.parcel?.label}
                                    </Descriptions.Item>
                                </>
                            )}
                        </Descriptions>
                    )}
                </Spin>
            </PageHeader>
            <List<InventoryPlace>
                columns={columns}
                data={inventoryPlacesState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowEnterPress={isZebra ? onClickPlace : undefined}
                onRowClick={isZebra ? onClickPlace : undefined}
                isLoading={inventoryPlacesState.loading && inventoryPlacesState.data?.page === undefined}
                title={
                    inventoryPlacesState.data
                        ? `${inventoryPlacesState.data?.totalCount} emplacement${
                              inventoryPlacesState.data?.totalCount > 1 ? 's' : ''
                          } ${
                              inventoryState.data?.status !== InventoryStatus.completed
                                  ? 'à inventorier'
                                  : 'inventoriés'
                          }`
                        : `Emplacements ${
                              inventoryState.data?.status !== InventoryStatus.completed
                                  ? 'à inventorier'
                                  : 'inventoriés'
                          }`
                }
                isRowHiglighted={(record) => record.status === InventoryStatus.completed}
                isRowWarning={(record) =>
                    isZebra
                        ? record.status === InventoryStatus.toValidGap
                        : record.quantity !== null &&
                          record.quantity !== undefined &&
                          record.theoreticalQuantity !== record.quantity
                }
                style={isMobile ? { marginTop: 24 } : undefined}
                noWarningIcon={() => true}
            />
            {inventoryPlacesState.data && inventoryPlacesState.data.page < inventoryPlacesState.data.pageCount - 1 && (
                <Button
                    onClick={onLoadMore}
                    loading={inventoryPlacesState.loading}
                    size="small"
                    style={{ margin: '1rem 0', fontSize: 14 }}
                    block
                >
                    Plus de résultats
                </Button>
            )}
            {canEditDesktop &&
                inventoryState.data?.operator &&
                inventoryState.data?.status !== InventoryStatus.completed && (
                    <>
                        <FixedFooter>
                            <BasicList inline inlineStretch>
                                <li>
                                    <ArrowNavItem>
                                        <ButtonWithShortcut
                                            shortcut="f1"
                                            onClick={setIsOperatorModalVisible.bind(null, true)}
                                            size={!isDesktop ? 'middle' : 'large'}
                                            block
                                        >
                                            Modifier l&rsquo;attribution
                                        </ButtonWithShortcut>
                                    </ArrowNavItem>
                                </li>
                                {canRemoveInventory && (
                                    <li>
                                        <ArrowNavItem>
                                            <ButtonWithShortcut
                                                shortcut="f2"
                                                onClick={onClickRemoveInventory}
                                                loading={inventoryState.loading}
                                                size={!isDesktop ? 'middle' : 'large'}
                                                block
                                            >
                                                Annuler l&lsquo;inventaire
                                            </ButtonWithShortcut>
                                        </ArrowNavItem>
                                    </li>
                                )}
                            </BasicList>
                        </FixedFooter>
                        <InventoryOperatorModal
                            visible={isOperatorModalVisible}
                            onCancel={setIsOperatorModalVisible.bind(null, false)}
                            onSuccess={() => fetchInventory({ inventoryId })}
                        />
                        <InventoryRemoveConfirmModal id={'inventoryRemoveConfirmModal'} />
                    </>
                )}
        </FixedFooter.Wrapper>
    );
};

export default InventoryDetails;
