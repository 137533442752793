import React, { Children, cloneElement, FC, FocusEvent, FocusEventHandler, isValidElement } from 'react';
import { classNames } from '../helpers';

// prevent focused element being "trapped" under stickied footer/header. We scroll the window if it's the case.
const onFocus = (childOnFocus: FocusEventHandler | undefined, e: FocusEvent) => {
    const element = e.target as HTMLElement;
    const footer = document.querySelector('.fixed-footer');
    const header = document.getElementById('main-header');
    const elementPosition = element.getBoundingClientRect();

    if (footer && element && !footer?.contains(element)) {
        const footerPosition = footer.getBoundingClientRect();

        if (elementPosition.bottom > footerPosition.top) {
            window.scrollBy({
                top: elementPosition.bottom - footerPosition.top,
            });

            if (typeof childOnFocus === 'function') {
                childOnFocus(e);
            }

            // prevent doing the calculations for header if we are under the footer
            return;
        }
    }

    if (header && element && !header?.contains(element)) {
        const headerPosition = header.getBoundingClientRect();

        if (elementPosition.top < headerPosition.bottom) {
            window.scrollBy({
                top: elementPosition.top - headerPosition.bottom,
            });
        }
    }

    if (typeof childOnFocus === 'function') {
        childOnFocus(e);
    }
};
interface ArrowNavItemProps {
    disabled?: boolean;
    scope?: string;
}

const ArrowNavItem: FC<ArrowNavItemProps> = ({ children, disabled, scope, ...props }) => (
    <>
        {disabled
            ? children
            : Children.map(children, (child) => {
                  if (isValidElement(child)) {
                      return cloneElement(child, {
                          ...props,
                          className: classNames(child.props.className, 'arrow-nav-item'),
                          'data-arrow-nav-scope': scope,
                          onFocus: onFocus.bind(null, child.props.onFocus),
                      });
                  }
                  return child;
              })}
    </>
);

export default ArrowNavItem;
