import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { User } from '../api/apiTypes';
import { ListResponse } from '../api/';

// Controlers
import { UsersListPayload, list as listApiCall } from '../api/users';

// States
export interface UsersState {
    list: RequestState<ListResponse<User>>;
}

const initialState: UsersState = {
    list: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<UsersState['list'], UsersListPayload, ListResponse<User>>(
    'users/list',
    initialState.list,
    requestReducer<UsersState['list'], ListResponse<User>>(initialState.list)
);

// Reducer
export const usersReducer = combineReducers<UsersState>({
    list: list.reducer,
});

// Saga
export function* usersSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
}

// Store helpers
export const getUsersListState = (state: MainReducerState) => state.users.list;
