import React, { FC, useEffect } from 'react';
import { getRoute, RoutePathName } from '../../routes';
import { useHistory, useParams } from 'react-router-dom';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import { Card, Col, Descriptions, message, Row, Space, Spin } from 'antd';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import TitleBlack from '../../components/TitleBlack';
import { getParcelStateById } from '../../store/actions/parcels';
import { useSelector } from 'react-redux';
import { details as detailsPalletAction, getPalletDetailsStateById } from '../../store/actions/pallets';
import { translatePalletSize, translatePalletStatus } from '../../helpers/i18n';
import { useActions, useIsMobile, usePrevious } from '../../hooks';
import ButtonGrey from '../../components/ButtonGrey';
import FixedFooter from '../../components/FixedFooter';
import { useModal } from '@ebay/nice-modal-react';
import PrintPalletModal from './PrintPalletModal';
import StorePalletModal from './StorePalletModal';
import { getReceptionDetailsStateById } from '../../store/actions/receptions';
import { ReceptionStatus } from '../../store/api/apiTypes';

const ReceptionPallet: FC = () => {
    const isMobile = useIsMobile();
    const { receptionId, parcelId, palletId } =
        useParams<{ receptionId: string; parcelId: string; palletId: string }>();
    const [fetchPallet] = useActions([detailsPalletAction.trigger]);
    const parcelState = useSelector(getParcelStateById(parseInt(parcelId, 10)));
    const palletState = useSelector(getPalletDetailsStateById(parseInt(palletId, 10)));
    const receptionState = useSelector(getReceptionDetailsStateById(parseInt(receptionId, 10)));
    const previous = usePrevious({ palletState });
    const { sector, locker, spot } = palletState.data?.reservedPlace ?? {};
    const printPalletModal = useModal(PrintPalletModal);
    const storePalletModal = useModal(StorePalletModal);
    const history = useHistory();

    const onClickPrint = () => {
        printPalletModal.show({ palletId: parseInt(palletId, 10) });
    };

    const onClickEditPlace = () => {
        history.push(
            getRoute(RoutePathName.receptionPalletPlaceEdit, {
                receptionId,
                palletId,
                parcelId,
            }),
            {
                from: getRoute(RoutePathName.receptionPallet, {
                    receptionId,
                    palletId,
                    parcelId,
                }),
            }
        );
    };

    const onClickStore = () => {
        if (palletState.data && receptionState.data) {
            let successRoute = getRoute(RoutePathName.receptionPalletList, {
                receptionId,
                parcelId,
            });
            if (receptionState.data.pallets?.length === 1 && receptionState.data.status === ReceptionStatus.pending) {
                successRoute = getRoute(RoutePathName.receptionsList);
            }
            storePalletModal.show({
                pallet: palletState.data,
                receptionId: parseInt(receptionId, 10),
                parcelId: parseInt(parcelId, 10),
                successRoute,
            });
        }
    };

    useEffect(() => {
        if (palletId) {
            fetchPallet({ palletId });
        }
    }, [fetchPallet, palletId]);

    useEffect(() => {
        if (previous?.palletState.loading && !palletState.loading) {
            if (palletState.error) {
                message.error('Erreur lors de la récupération de la palette');
            } else if (!palletState.data?.reception) {
                history.push(
                    getRoute(RoutePathName.receptionParcel, {
                        receptionId,
                        parcelId,
                    })
                );
            }
        }
    }, [
        history,
        palletState.data?.reception,
        palletState.error,
        palletState.loading,
        parcelId,
        previous?.palletState.loading,
        receptionId,
    ]);

    return (
        <>
            <Seo title="Détail palette" />
            <Header
                title="Détail palette"
                backRoute={getRoute(RoutePathName.receptionPalletList, {
                    receptionId,
                    parcelId,
                })}
                enableHomeButton
            />
            <PageHeader>
                <Spin spinning={parcelState.loading}>
                    <Descriptions column={5} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="Référence palette" span={2}>
                            {palletState.data?.reference}
                        </Descriptions.Item>
                        <Descriptions.Item label="Colis">{palletState.data?.quantity}</Descriptions.Item>
                        <Descriptions.Item label="Statut" span={2}>
                            {translatePalletStatus(palletState.data?.status)}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>
            <Card>
                <TitleBlack>Informations palette</TitleBlack>
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <table className="custom-table">
                        <thead>
                            <tr>
                                <th>Taille</th>
                                <th>Emplacement</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{translatePalletSize(palletState.data?.size, true)}</td>
                                <td>
                                    <Spin spinning={palletState.loading}>
                                        <div className="centered-description">
                                            <div>
                                                <p>Secteur</p>
                                                <p>{sector ?? '—'}</p>
                                            </div>
                                            <div>
                                                <p>Casier</p>
                                                <p>{locker ?? '—'}</p>
                                            </div>
                                            <div>
                                                <p>Emplacement</p>
                                                <p>{spot ?? '—'}</p>
                                            </div>
                                        </div>
                                    </Spin>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Row gutter={16}>
                        <Col xs={12}>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    shortcut="f1"
                                    type="primary"
                                    size="small"
                                    onClick={onClickEditPlace}
                                    block
                                    ghost
                                >
                                    Modifier
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </Col>
                        <Col xs={12}>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    shortcut="f2"
                                    type="primary"
                                    size="small"
                                    onClick={onClickPrint}
                                    block
                                    ghost
                                >
                                    Impression
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </Col>
                    </Row>
                </Space>
            </Card>

            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        shortcut="enter"
                        size={isMobile ? 'middle' : 'large'}
                        as={ButtonGrey}
                        onClick={onClickStore}
                        block
                    >
                        Ranger la palette
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </>
    );
};

export default ReceptionPallet;
