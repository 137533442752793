import React, { forwardRef, useEffect, ForwardRefRenderFunction } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { useSelector } from 'react-redux';

import { PlaceAlveole, PlaceLane } from '../store/api/apiTypes';
import { getPlaceAlveolesListState, list as listPlaceAlveolesAction } from '../store/actions/placeAlveoles';

import { useActions } from '../hooks';
import ArrowNavItem from './ArrowNavItem';

interface PlaceAlveoleSelectProps extends SelectProps<PlaceAlveole['id'] | PlaceAlveole['alveole']> {
    multi?: boolean;
    size?: SizeType;
    placeholder?: string;
    onChange?: (value: any) => void;
    withArrowNav?: boolean;
    arrowNavScope?: string;
    placeLaneId?: PlaceLane['id'];
    valueProp?: 'id' | 'alveole';
}

const PlaceAlveoleSelect: ForwardRefRenderFunction<typeof Select, PlaceAlveoleSelectProps> = (
    { placeLaneId, placeholder, withArrowNav, arrowNavScope, valueProp, ...selectProps },
    ref: any
) => {
    const listPlaceAlveoles = useActions(listPlaceAlveolesAction.trigger);
    const placeAlveolesListState = useSelector(getPlaceAlveolesListState);
    const onSearch: SelectProps<PlaceAlveole['id']>['onSearch'] = (newValue) => {
        listPlaceAlveoles(
            {
                search: newValue || undefined,
                ...(placeLaneId ? { placeLaneId } : {}),
            },
            { throttling: 300 }
        );
    };

    useEffect(() => {
        listPlaceAlveoles(placeLaneId ? { placeLaneId } : undefined);
    }, [listPlaceAlveoles, placeLaneId]);

    const select = (
        <Select<PlaceAlveole['id'] | PlaceAlveole['alveole']>
            placeholder={placeholder ?? 'Filtrer par alvéole'}
            filterOption={false}
            style={{ width: '100%' }}
            onSearch={onSearch}
            loading={placeAlveolesListState.loading}
            notFoundContent={placeAlveolesListState.loading ? <Spin /> : 'Aucun résultat'}
            allowClear
            showArrow
            ref={ref}
            showSearch
            {...selectProps}
        >
            {placeAlveolesListState.data?.items?.map((option) => (
                <Select.Option value={option[valueProp ?? 'id']} key={option.id}>
                    {option.alveole}
                </Select.Option>
            ))}
        </Select>
    );

    return withArrowNav ? <ArrowNavItem scope={arrowNavScope}>{select}</ArrowNavItem> : select;
};

export default forwardRef(PlaceAlveoleSelect);
