import React, { FC } from 'react';
import { Typography } from 'antd';
import { IconWarning } from '../../../components/icons';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';

const PalletDeleteModalTitle: FC = () => {
    return (
        <Typography.Text>
            <Typography.Text type="warning">
                <IconWarning style={{ fontSize: '2.25rem', marginBottom: '1rem' }} />
            </Typography.Text>
            <>
                <br />
                En cliquant sur valider <br />
                vous supprimez la palette
                <br />
                en cours
            </>
        </Typography.Text>
    );
};

interface PalletDeleteFooterProps {
    shortcutScope: string;
    onValid: () => void;
    onClose: () => void;
    loading: boolean;
}
const PalletDeleteFooter: FC<PalletDeleteFooterProps> = ({ shortcutScope, onValid, onClose, loading }) => {
    return (
        <>
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut
                    shortcut="enter"
                    type="primary"
                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                    loading={loading}
                    shortcutScope={shortcutScope}
                    onClick={() => onValid()}
                >
                    Valider
                </ButtonWithShortcut>
            </ArrowNavItem>
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut
                    shortcut="esc"
                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                    shortcutScope={shortcutScope}
                    type="primary"
                    onClick={() => onClose()}
                    ghost
                >
                    Annuler
                </ButtonWithShortcut>
            </ArrowNavItem>
        </>
    );
};

export { PalletDeleteModalTitle, PalletDeleteFooter };
