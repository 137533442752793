import React, { FC } from 'react';
import { useCurrentBreakpointName } from 'react-socks';

const mobileBreakpoints = ['xs', 'sm'];

const DesktopBreakpoint: FC = ({ children }) => {
    const breakpoint = useCurrentBreakpointName();

    return !mobileBreakpoints.includes(breakpoint) ? <>{children}</> : null;
};

export default DesktopBreakpoint;
