import { Descriptions, message, Spin } from 'antd';
import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CustomerOrder, LoadingOrderStatus, RoleName } from '../../store/api/apiTypes';
import {
    details as loadingOrderDetails,
    getLoadingOrderStateById,
    update as loadingOrderUpdate,
} from '../../store/actions/loadingOrders';
import { list as customersList, getCustomerOrderListState } from '../../store/actions/customerOrders';

import FixedFooter from '../../components/FixedFooter';
import Header from '../../components/Header';
import List, { ListColumn } from '../../components/list/List';
import PageHeader from '../../components/PageHeader';
import Seo from '../../components/Seo';
import { useActions, useIsDesktop, useIsMobile, usePrevious } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import { formatDate, translateCustomerOrderStatus, translateLoadingOrderStatus } from '../../helpers/i18n';
import { getUser } from '../../store/actions/auth';
import { hasRole } from '../../helpers/security';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ArrowNavItem from '../../components/ArrowNavItem';
import LoadingOrderConfirmRemoveModal from './LoadingOrderConfirmRemoveModal';
import { show } from '@ebay/nice-modal-react';

const LoadingOrderDetails: FC = () => {
    const isMobile = useIsMobile();
    const isDesktop = useIsDesktop();
    const history = useHistory();
    const { loadingOrderId } = useParams<{ loadingOrderId: string }>();
    const [fetchLoadingOrder, listCustomers, updateLoadingOrder] = useActions([
        loadingOrderDetails.trigger,
        customersList.trigger,
        loadingOrderUpdate.trigger,
    ]);
    const user = useSelector(getUser);
    const customersListState = useSelector(getCustomerOrderListState);
    const loadingOrderState = useSelector(getLoadingOrderStateById(parseInt(loadingOrderId, 10)));
    const previous = usePrevious({ loadingOrderState });
    const canRemoveLoadingOrder =
        isDesktop &&
        hasRole(user, [RoleName.admin]) &&
        loadingOrderState.data?.status === LoadingOrderStatus.toBeProcessed;
    const onListRowEnter = (record: CustomerOrder) => {
        history.push(getRoute(RoutePathName.customerOrderDetails, { loadingOrderId, customerOrderId: record.id }));
    };
    const onClickRemoveLoadingOrder = () => {
        show('loadingOrderConfirmRemoveModal');
    };
    const columns: Array<ListColumn<CustomerOrder>> = [
        {
            dataIndex: 'customer.reference',
            title: 'Code client',
            flex: isMobile ? '1 1 25%' : '1 1 15%',
            cellStyle: isMobile
                ? {
                      alignItems: 'flex-start',
                  }
                : undefined,
        },
        {
            dataIndex: 'rank',
            title: 'Rang',
            flex: isMobile ? '1 1 20%' : '1 1 10%',
            cellStyle: isMobile
                ? {
                      alignItems: 'flex-start',
                  }
                : undefined,
        },
        {
            dataIndex: 'quantity',
            title: 'Colis',
            flex: isMobile ? '1 1 15%' : '1 1 10%',
            cellStyle: isMobile
                ? {
                      alignItems: 'flex-start',
                  }
                : undefined,
        },
        {
            key: 'qtyToControl',
            title: 'Contrôlés',
            flex: isMobile ? '1 1 40%' : '1 1 10%',
            render: (record) => {
                if (record.quantity) {
                    return `${record.controlledQuantity ?? 0}/${record.quantity}`;
                } else {
                    return '-';
                }
            },
            cellStyle: isMobile
                ? {
                      alignItems: 'flex-start',
                  }
                : undefined,
        },
        {
            key: 'status',
            title: 'Statut',
            flex: isMobile ? '1 1 30%' : '1 1 15%',
            render: (record) => translateCustomerOrderStatus(record.status),
            cellStyle: isMobile
                ? {
                      alignItems: 'flex-start',
                  }
                : undefined,
        },
        {
            dataIndex: 'customer.label',
            title: 'Client',
            flex: isMobile ? '1 1 70%' : '1 1 40%',
            cellStyle: isMobile
                ? {
                      alignItems: 'flex-start',
                  }
                : undefined,
        },
    ];

    useEffect(() => {
        if (loadingOrderId) {
            fetchLoadingOrder({ loadingOrderId });
        }
    }, [fetchLoadingOrder, loadingOrderId]);

    useEffect(() => {
        if (previous?.loadingOrderState.loading && !loadingOrderState.loading) {
            if (!loadingOrderState.error) {
                listCustomers({ loadingOrderId });
            } else {
                message.error("Une erreur est survenue lors de la récupération de l'ordre de chargement");
            }
        }
    }, [previous?.loadingOrderState, loadingOrderState, listCustomers, loadingOrderId, updateLoadingOrder]);

    let nbOfPackageToPrepare: string | undefined;
    let nbOfPackageToControl: string | undefined;
    if (loadingOrderState.data?.quantity) {
        nbOfPackageToPrepare = `${loadingOrderState.data.preparedQuantity ?? 0}/${loadingOrderState.data.quantity}`;

        nbOfPackageToControl = `${loadingOrderState.data.controlledQuantity ?? 0}/${loadingOrderState.data.quantity}`;
    }

    return (
        <FixedFooter.Wrapper>
            <Seo title="Ordre de chargement" />
            <Header
                title="Ordre de chargement"
                backRoute={{ pathname: getRoute(RoutePathName.preparation), state: { restoreQueryParams: true } }}
                enableHomeButton
            />
            <PageHeader>
                <Spin spinning={loadingOrderState.loading}>
                    <Descriptions column={{ xs: 6, md: 5 }} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="N° de tournée" span={2}>
                            {loadingOrderState.data?.tourNumber ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Date de charg." span={2}>
                            {formatDate(loadingOrderState.data?.date)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Statut" span={2}>
                            {translateLoadingOrderStatus(loadingOrderState.data?.status)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Code plateforme" span={2}>
                            {loadingOrderState.data?.plateformCode ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Transporteur" span={4}>
                            {loadingOrderState.data?.carrier?.label ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Poids" span={2}>
                            {loadingOrderState.data?.weightEstimated
                                ? new Intl.NumberFormat('fr-FR', {
                                      style: 'unit',
                                      unit: 'kilogram',
                                  }).format(loadingOrderState.data?.weightEstimated ?? 0)
                                : '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Volume" span={2}>
                            {loadingOrderState.data?.volumeEstimated ? (
                                <span>
                                    {loadingOrderState.data.volumeEstimated} m<sup>3</sup>
                                </span>
                            ) : (
                                '—'
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Colis">{loadingOrderState.data?.quantity ?? '—'}</Descriptions.Item>
                        <Descriptions.Item label="Palette">
                            {loadingOrderState.data?.nbOfPalletEstimated ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={`Préparé${loadingOrderState.data?.preparedQuantity !== 1 ? 's' : ''}`}
                            span={2}
                        >
                            {nbOfPackageToPrepare ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={`Contrôlé${loadingOrderState.data?.controlledQuantity !== 1 ? 's' : ''}`}
                        >
                            {nbOfPackageToControl ?? '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>
            <List<CustomerOrder>
                columns={columns}
                data={customersListState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowClick={onListRowEnter}
                onRowEnterPress={onListRowEnter}
                isLoading={customersListState.loading}
                title="Clients"
                headerInCell
            />
            {canRemoveLoadingOrder && (
                <>
                    <LoadingOrderConfirmRemoveModal id={'loadingOrderConfirmRemoveModal'} />
                    <FixedFooter>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="f1"
                                onClick={onClickRemoveLoadingOrder}
                                size={'large'}
                                loading={loadingOrderState.loading}
                                block
                            >
                                Supprimer l&lsquo;ordre de chargement&nbsp;
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </FixedFooter>
                </>
            )}
        </FixedFooter.Wrapper>
    );
};

export default LoadingOrderDetails;
