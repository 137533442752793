import React from 'react';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import PrintModal from '../../../components/PrintModal';
import { WMSMode } from '../../../store/api/apiTypes';
import { useArrowNavScope, useShortcutScope } from '../../../hooks';
import { useSelector } from 'react-redux';
import { getCustomerReturnCreateState } from '../../../store/actions/customerReturn';
import { getRoute, RoutePathName } from '../../../routes';
import { useHistory } from 'react-router-dom';

const shortcutScope = 'ReturnPrintModal';

const ReturnPrintModal = NiceModal.create(() => {
    const modal = useModal();
    const history = useHistory();
    const { hide } = modal;
    useShortcutScope(shortcutScope);
    useArrowNavScope(shortcutScope);
    const customerReturnCreateState = useSelector(getCustomerReturnCreateState);

    return (
        <PrintModal
            {...antdModal(modal)}
            mode={WMSMode.customerReturn}
            palletId={customerReturnCreateState.data?.id}
            onSuccess={async () => await hide().then(() => history.push(getRoute(RoutePathName.movementsStockMenu)))}
            onCancel={async () => await hide().then(() => history.push(getRoute(RoutePathName.movementsStockMenu)))}
            cancelText={'Imprimer plus tard'}
        />
    );
});

export default ReturnPrintModal;
