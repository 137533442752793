import { SearchPaginationQuery } from '.';
import { PlaceSector } from './apiTypes';
import api from './_client';

export type PlaceSectorListPayload = SearchPaginationQuery;
export const list = async (payload: PlaceSectorListPayload = {}) => await api.get('/placeSectors', { params: payload });

export type PlaceSectorCreatePayload = Omit<PlaceSector, 'id'>;
export const create = async (payload: PlaceSectorCreatePayload) => await api.post('/placeSectors', payload);

export type PlaceSectorUpdatePayload = PlaceSector & {
    placeSectorId: PlaceSector['id'];
};
export const update = async ({ placeSectorId, ...payload }: PlaceSectorUpdatePayload) =>
    await api.put(`/placeSectors/${placeSectorId}`, payload);

export interface PlaceSectorDeletePayload {
    placeSectorId: PlaceSector['id'];
}
export const del = async ({ placeSectorId }: PlaceSectorDeletePayload) =>
    await api.delete(`/placeSectors/${placeSectorId}`);
