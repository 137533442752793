import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Input, InputProps, message, Select } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { detailsByScan, getParcelScanState } from '../../store/actions/parcels';
import { getInventoryPlacesUpdateState, placeDetails, placeUpdate } from '../../store/actions/inventories';
import { PalletSize } from '../../store/api/apiTypes';

import { isZebra } from '../../helpers/enterprise-browser';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { translatePalletSize } from '../../helpers/i18n';

const shortcutScope = 'InventoryAddParcelModal';

const InventoryAddParcelModal: FC<CustomModalProps> = ({ visible, onCancel }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const { inventoryPlaceId } = useParams<{ inventoryPlaceId: string }>();
    const [reference, setReference] = useState<string>();
    const [step, setStep] = useState<'scan' | 'size'>('scan');
    const [size, setSize] = useState<PalletSize>();
    const [isAddingParcel, setIsAddingParcel] = useState(false);
    const [loadParcelDetails, fetchInventoryPlace, updateInventoryPlace] = useActions([
        detailsByScan.trigger,
        placeDetails.trigger,
        placeUpdate.trigger,
    ]);
    const inventoryPlaceUpdateState = useSelector(getInventoryPlacesUpdateState);
    const parcelScanState = useSelector(getParcelScanState);
    const previous = usePrevious({ parcelScanState, inventoryPlaceUpdateState });
    const inputRef = useRef<Input | null>(null);
    const onSubmit = () => {
        loadParcelDetails({ reference });
    };
    const onSubmitSize = () => {
        setIsAddingParcel(true);
        updateInventoryPlace({
            id: inventoryPlaceId,
            foundParcelId: parcelScanState.data?.id,
            palletSize: size,
        });
    };
    const onChangeField: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                loadParcelDetails({ barcode: barCode.data });
            }
        },
        {
            deps: [loadParcelDetails],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    useEffect(() => {
        if (!visible) {
            setReference(undefined);
            if (inputRef.current) {
                inputRef.current.blur();
            }
        } else {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [visible, setReference]);

    // receive parcel details by barcode/reference (due to scan/submit), send to size step
    useEffect(() => {
        if (previous?.parcelScanState.loading && !parcelScanState.loading) {
            if (parcelScanState.error) {
                if (parcelScanState.error?.status === 404) {
                    message.error('Colis non reconnu');
                } else {
                    message.error('Erreur lors de la demande de référence/code-barre');
                }
            } else if (parcelScanState.data) {
                setStep('size');
            }
        }
    }, [parcelScanState.data, parcelScanState.error, parcelScanState.loading, previous?.parcelScanState.loading]);

    // update inventoryPlace response, close modal & fetch inventoryPlace to refresh
    useEffect(() => {
        if (previous?.inventoryPlaceUpdateState.loading && !inventoryPlaceUpdateState.loading && isAddingParcel) {
            setIsAddingParcel(false);

            if (inventoryPlaceUpdateState.error) {
                if (inventoryPlaceUpdateState.error?.status === 409) {
                    if (inventoryPlaceUpdateState.error?.data?.wrongParcel) {
                        message.error("L'emplacement est réservé pour une autre référence");
                    } else if (inventoryPlaceUpdateState.error?.data?.unreservedParcel) {
                        message.error(
                            "L'emplacement n'a pas encore été assigné à une référence, veuillez vous rapprocher de votre manager"
                        );
                    }
                    setStep('scan');
                } else {
                    message.error("Une erreur est survenue pendant la mise à jour de l'inventaire");
                }
            } else {
                onClose();
                fetchInventoryPlace({ inventoryPlaceId });
            }
        }
    }, [
        fetchInventoryPlace,
        inventoryPlaceId,
        inventoryPlaceUpdateState.error,
        inventoryPlaceUpdateState.loading,
        isAddingParcel,
        onClose,
        previous?.inventoryPlaceUpdateState.loading,
    ]);

    return (
        <CustomModal
            footer={
                <>
                    {step === 'scan' && (
                        <>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="enter"
                                    type="primary"
                                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                    shortcutScope={shortcutScope}
                                    onClick={onSubmit}
                                    loading={parcelScanState.loading}
                                >
                                    Suivant
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="esc"
                                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                    onClick={onClose}
                                    ghost
                                >
                                    Annuler
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </>
                    )}
                    {step === 'size' && (
                        <>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    type="primary"
                                    shortcut="enter"
                                    shortcutScope={shortcutScope}
                                    onClick={onSubmitSize}
                                    loading={inventoryPlaceUpdateState.loading}
                                    disabled={!size}
                                >
                                    Valider
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    type="primary"
                                    shortcut="esc"
                                    shortcutScope={shortcutScope}
                                    onClick={setStep.bind(null, 'scan')}
                                    ghost
                                >
                                    Retour
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </>
                    )}
                </>
            }
            visible={visible}
            onCancel={onCancel}
            title={step === 'scan' ? 'Corriger emplacement' : 'Taille de la palette'}
            width={368}
            keyboard={false}
            altTitle
        >
            {step === 'scan' && (
                <ArrowNavItem scope={shortcutScope}>
                    <Input
                        placeholder={isZebra ? 'Flasher le colis' : 'Entrer le code barre du colis'}
                        value={reference}
                        onChange={onChangeField}
                        ref={inputRef}
                        autoFocus
                    />
                </ArrowNavItem>
            )}
            {step === 'size' && (
                <ArrowNavItem scope={shortcutScope}>
                    <Select style={{ width: '100%' }} value={size} onChange={setSize} placeholder="Choisir une taille">
                        {Object.values(PalletSize).map((size) => (
                            <Select.Option value={size} key={size}>
                                {translatePalletSize(size)}
                            </Select.Option>
                        ))}
                    </Select>
                </ArrowNavItem>
            )}
        </CustomModal>
    );
};

export default InventoryAddParcelModal;
