import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import CustomModal from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import { message, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import {
    getPreparationSAVState,
    listByTypeAndCustomerOrderId as preparationsListByType,
    sav as preparationSav,
} from '../../store/actions/preparations';
import { useSelector } from 'react-redux';
import { PreparationStatus } from '../../store/api/apiTypes';
import { details as customerOrderDetails } from '../../store/actions/customerOrders';

const shortcutScope = 'ValidSAVModal';

interface ValidSAVModalProps {
    preparationId: number;
    customerOrderId: number;
}

const ValidSAVModal = NiceModal.create<ValidSAVModalProps>(({ preparationId, customerOrderId }) => {
    const modal = useModal();
    const { hide, visible } = modal;
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [savPreparation, listPreparationsByType, fetchCustomerOrder] = useActions([
        preparationSav.trigger,
        preparationsListByType.trigger,
        customerOrderDetails.trigger,
    ]);
    const preparationSAVState = useSelector(getPreparationSAVState);
    const previous = usePrevious({ preparationSAVState });

    const onValid = () => {
        savPreparation({ preparationId });
    };

    useEffect(() => {
        if (previous?.preparationSAVState?.loading && !preparationSAVState.loading) {
            if (preparationSAVState.error) {
                message.error('Une erreur est survenue lors de la validation du SAV');
            } else {
                fetchCustomerOrder({ customerOrderId });
                listPreparationsByType({
                    customerOrderId,
                    status: [
                        PreparationStatus.toBeProcessed,
                        PreparationStatus.inProgress,
                        PreparationStatus.completed,
                        PreparationStatus.toRegularize,
                        PreparationStatus.partial,
                    ],
                    pageSize: 300,
                });
                hide();
            }
        }
    }, [
        customerOrderId,
        fetchCustomerOrder,
        hide,
        listPreparationsByType,
        preparationSAVState.error,
        preparationSAVState.loading,
        previous?.preparationSAVState?.loading,
    ]);

    return (
        <CustomModal
            {...antdModal(modal)}
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onValid}
                            loading={preparationSAVState.loading}
                        >
                            Valider
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={hide}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            title={
                <Typography.Text>
                    <br />
                    Voulez-vous valider la récupération de ce colis SAV ?
                </Typography.Text>
            }
            width={368}
        />
    );
});

export default ValidSAVModal;
