import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Input, InputProps, message } from 'antd';
import { useSelector } from 'react-redux';

import { create, getResupplyOrderCreateState } from '../../store/actions/resupplyOrders';

import { isZebra } from '../../helpers/enterprise-browser';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import SuccessMessage from '../../components/SuccessMessage';
import { getResupplyOrderCreateError } from '../../helpers/i18n';

const shortcutScope = 'ResupplyOrderModal';

const ResupplyOrderModal: FC<CustomModalProps> = ({ visible, onCancel }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const createOrder = useActions(create.trigger);
    const [reference, setReference] = useState<string>();
    const [success, setSuccess] = useState(false);
    const resupplyOrderCreateState = useSelector(getResupplyOrderCreateState);
    const previous = usePrevious({ resupplyOrderCreateState });
    const inputRef = useRef<Input | null>(null);
    const isMounted = useIsMounted();
    const onSubmit = () => {
        createOrder({ reference });
    };
    const onChangeField: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                createOrder({ barcode: barCode.data });
            }
        },
        {
            deps: [createOrder],
            disable: !visible,
        }
    );

    useEffect(() => {
        if (!visible) {
            setReference(undefined);
            setSuccess(false);
            if (inputRef.current) {
                inputRef.current.blur();
            }
        } else {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [visible, setReference, setSuccess]);

    useEffect(() => {
        if (previous?.resupplyOrderCreateState.loading && !resupplyOrderCreateState.loading) {
            if (resupplyOrderCreateState.error) {
                message.error(getResupplyOrderCreateError(resupplyOrderCreateState));
            } else {
                setSuccess(true);
            }
        }
    }, [
        previous?.resupplyOrderCreateState,
        resupplyOrderCreateState.loading,
        resupplyOrderCreateState.error,
        resupplyOrderCreateState,
        setSuccess,
    ]);

    useEffect(() => {
        let timeout: number;

        if (visible && success) {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onClose();
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [isMounted, onClose, success, visible]);

    return (
        <CustomModal
            footer={
                success ? null : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={onSubmit}
                                loading={resupplyOrderCreateState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={!success && 'Déclencher un OR'}
            width={success ? 232 : 368}
            keyboard={false}
            altTitle
        >
            {success ? (
                <SuccessMessage message="OR ajouté avec succès." />
            ) : (
                <ArrowNavItem scope={shortcutScope}>
                    <Input
                        placeholder={isZebra ? "Flasher l'emplacement" : "Entrer la référence de l'emplacement"}
                        value={reference}
                        onChange={onChangeField}
                        ref={inputRef}
                        autoFocus
                    />
                </ArrowNavItem>
            )}
        </CustomModal>
    );
};

export default ResupplyOrderModal;
