import { useEffect, useRef, Ref, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentBreakpointName } from 'react-socks';
import { ActionCreator, bindActionCreators } from 'redux';
import { arrowNav } from '../components/ArrowNav';
import { debug } from '../helpers';

import { BarCodeScannerResponse, disableScanner, enableScanner, ScannerParams } from '../helpers/enterprise-browser';
import { shortcut } from '../helpers/shortcut';

export const usePrevious = <T extends unknown>(value: T) => {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export const useShareForwardedRef = <T extends unknown>(forwardedRef: Ref<T>) => {
    // final ref that will share value with forward ref. this is the one we will attach to components
    const innerRef = useRef<T>(null);

    useEffect(() => {
        // after every render - try to share current ref value with forwarded ref
        if (!forwardedRef) {
            return;
        }
        if (typeof forwardedRef === 'function') {
            forwardedRef(innerRef.current);
        } else {
            // @ts-expect-error
            // by default forwardedRef.current is readonly. Let's ignore it
            forwardedRef.current = innerRef.current;
        }
    });

    return innerRef;
};

export const useIsMobile = () => {
    const breakpoint = useCurrentBreakpointName();

    return breakpoint === 'xs' || breakpoint === 'sm';
};

export const useIsDesktop = () => {
    const breakpoint = useCurrentBreakpointName();

    return breakpoint !== 'xs' && breakpoint !== 'sm';
};

export function useActions<A extends ActionCreator<any>>(actionCreators: A, deps?: any[]): A;
export function useActions<A extends Array<ActionCreator<any>>>(actionCreators: A, deps?: any[]): A;
export function useActions(actions: any, deps?: any[]) {
    const dispatch = useDispatch();

    return useMemo(
        () => {
            if (Array.isArray(actions)) {
                return actions.map((a) => bindActionCreators(a, dispatch));
            }
            return bindActionCreators(actions, dispatch);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps ? [dispatch, ...deps] : [dispatch]
    );
}

export const useIsMounted = () => {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return isMounted;
};

export const useShortcutScope = (scope: string, disabled?: boolean) => {
    useEffect(() => {
        if (!disabled) {
            shortcut.scope = scope;
        } else {
            shortcut.resetScope();
        }
    }, [scope, disabled]);

    useEffect(
        () => () => {
            shortcut.resetScope();
        },
        []
    );
};

export const useArrowNavScope = (scope: string, disabled?: boolean) => {
    useEffect(() => {
        if (!disabled) {
            arrowNav.scope = scope;
        } else {
            arrowNav.resetScope();
        }
    }, [scope, disabled]);

    useEffect(
        () => () => {
            arrowNav.resetScope();
        },
        []
    );
};

type UseScanner = (
    id: string,
    callback: (barcode: BarCodeScannerResponse) => void,
    params?: { deps?: any[]; disable?: boolean; scannerOptions?: ScannerParams }
) => void;

export const useScanner: UseScanner = (id, callback, { deps, disable, scannerOptions } = {}) => {
    const memoizedCallback = useCallback(
        (barcode: BarCodeScannerResponse) => {
            debug.info('[useScanner]', id, barcode);
            callback(barcode);
        },
        deps ? [...deps] : [] // eslint-disable-line react-hooks/exhaustive-deps
    );
    const memoizedScannerOptions = useRef(scannerOptions);

    useEffect(() => {
        if (disable) {
            debug.error('[useScanner] disable scanner', id);
            disableScanner();
        } else {
            debug.info('[useScanner] enable scanner', id);
            enableScanner(memoizedCallback, memoizedScannerOptions.current);
        }
    }, [id, memoizedCallback, disable]);

    useEffect(
        () => () => {
            debug.error('[useScanner] disable scanner', id);
            disableScanner();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
};
