import ArrowNavItem from '../../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../../components/ButtonWithShortcut';
import React, { FC, SetStateAction } from 'react';
import { Descriptions, Divider, Form } from 'antd';
import QuantityInput from '../../../../components/QuantityInput';
import { Parcel } from '../../../../store/api/apiTypes';

interface ReturnQuantityFooterProps {
    shortcutScope: string;
    onValid: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    loading: boolean;
}

interface ReturnQuantityContentProps {
    reference: Parcel['reference'] | undefined;
    label: Parcel['label'];
    quantity: number | undefined;
    setQuantity: React.Dispatch<SetStateAction<number | undefined>>;
    hasNarrowNav: boolean;
}
const ReturnQuantityFooter: FC<ReturnQuantityFooterProps> = ({ shortcutScope, onValid, onClose, loading }) => {
    return (
        <>
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut
                    shortcut="enter"
                    type="primary"
                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                    shortcutScope={shortcutScope}
                    onClick={onValid}
                    loading={loading}
                >
                    Valider
                </ButtonWithShortcut>
            </ArrowNavItem>
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut
                    shortcut="esc"
                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                    shortcutScope={shortcutScope}
                    type="primary"
                    onClick={onClose}
                    ghost
                >
                    Retour
                </ButtonWithShortcut>
            </ArrowNavItem>
        </>
    );
};

const ReturnQuantityContent: FC<ReturnQuantityContentProps> = ({
    reference,
    label,
    setQuantity,
    quantity = 0,
    hasNarrowNav,
}) => {
    return (
        <>
            <Descriptions column={{ xs: 2, md: 2 }} size="small" colon={false} layout="vertical">
                <Descriptions.Item label="Référence">{reference}</Descriptions.Item>
                <Descriptions.Item label="Désignation">{label}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Form.Item>
                <QuantityInput
                    onChange={setQuantity}
                    value={quantity}
                    min={0}
                    hasArrowNav={hasNarrowNav}
                    small={true}
                />
            </Form.Item>
        </>
    );
};

export { ReturnQuantityFooter, ReturnQuantityContent };
