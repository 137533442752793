import { combineReducers } from 'redux';
import { call, delay, put, race, take, takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { ControlLoadingOrder, ControlOrderPallet } from '../api/apiTypes';

// Controlers
import {
    ControlLoadingOrderCreatePayload,
    create as createApiCall,
    ControlLoadingOrderUpdatePayload,
    update as updateApiCall,
    details as detailsApiCall,
    ControlLoadingOrderDetailsPayload,
    palletList as palletListApiCall,
    ControlLoadingOrderPalletListPayload,
    close as closeApiCall,
    ControlLoadingOrderClosePayload,
} from '../api/controlLoadingOrders';
import { details as controlOrderPalletDetailsApiCall } from '../api/controlOrderPallets';
import { ListResponse } from '../api';
import { DataAction, EzeeAction } from '../helpers/EzeeAction';

// States

export interface ControlLoadingOrderCompletion {
    controlOrderPallets: {
        [controlOrderPalletId: string]: boolean | undefined;
    };
    controlLoadingOrder?: boolean;
    controlLoadingOrderId: ControlLoadingOrder['id'];
    controlOrderPalletId?: ControlOrderPallet['id'];
}

export interface ControlLoadingOrderCheckCompletionPayload {
    controlLoadingOrderId: ControlLoadingOrder['id'];
    controlOrderPalletId?: ControlOrderPallet['id'];
}

export interface ControlLoadingOrderCompletionResponse {
    controlLoadingOrder?: boolean;
    controlLoadingOrderId: ControlLoadingOrder['id'];
    controlOrderPalletId: ControlOrderPallet['id'];
    controlOrderPallet?: boolean;
}

export interface ControlLoadingOrdersState {
    create: RequestState<ControlLoadingOrder>;
    update: RequestState<ControlLoadingOrder>;
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: ControlLoadingOrder | undefined;
        };
    };
    palletList: RequestState<ListResponse<ControlOrderPallet>>;
    checkControlLoadingOrderCompletion: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: ControlLoadingOrderCompletion | undefined;
        };
    };
    close: RequestState<ControlLoadingOrder>;
}

const initialState: ControlLoadingOrdersState = {
    create: { ...requestInitialState },
    update: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
    palletList: { ...requestInitialState },
    checkControlLoadingOrderCompletion: { ...requestInitialState, data: {} },
    close: { ...requestInitialState },
};

export const create = new EzeeAsyncAction<
    ControlLoadingOrdersState['create'],
    ControlLoadingOrderCreatePayload,
    ControlLoadingOrder
>(
    'controlLoadingOrders/create',
    initialState.create,
    requestReducer<ControlLoadingOrdersState['create'], ControlLoadingOrder>(initialState.create)
);

export const update = new EzeeAsyncAction<
    ControlLoadingOrdersState['update'],
    ControlLoadingOrderUpdatePayload,
    ControlLoadingOrder
>(
    'controlLoadingOrders/update',
    initialState.update,
    requestReducer<ControlLoadingOrdersState['update'], ControlLoadingOrder>(initialState.update)
);

export const details = new EzeeAsyncAction<
    ControlLoadingOrdersState['details'],
    ControlLoadingOrderDetailsPayload,
    ControlLoadingOrder
>('controlLoadingOrders/details', initialState.details, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...state.data,
            [payload.id]: payload,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.details,
    }),
});

export const palletList = new EzeeAsyncAction<
    ControlLoadingOrdersState['palletList'],
    ControlLoadingOrderPalletListPayload,
    ListResponse<ControlOrderPallet>
>(
    'controlLoadingOrders/palletList',
    initialState.palletList,
    requestReducer<ControlLoadingOrdersState['palletList'], ListResponse<ControlOrderPallet>>(initialState.palletList)
);

export const checkControlLoadingOrderCompletion = new EzeeAsyncAction<
    ControlLoadingOrdersState['checkControlLoadingOrderCompletion'],
    ControlLoadingOrderCheckCompletionPayload,
    ControlLoadingOrderCompletionResponse
>('controlLoadingOrders/checkControlLoadingOrderCompletion', initialState.checkControlLoadingOrderCompletion, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, { controlLoadingOrderId, controlOrderPalletId, controlOrderPallet, controlLoadingOrder }) => ({
        data: {
            ...state.data,
            [controlLoadingOrderId]: {
                controlLoadingOrderId,
                controlOrderPalletId,
                controlLoadingOrder,
                controlOrderPallets: {
                    ...state.data?.[controlLoadingOrderId]?.controlOrderPallets,
                    [controlOrderPalletId]: controlOrderPallet,
                },
            },
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.checkControlLoadingOrderCompletion,
    }),
});

export const detailsPolling = new EzeeAction(
    'controlLoadingOrders/detailsPolling',
    {},
    {
        stopPolling: () => ({}),
        startPolling: () => ({}),
    }
);

export const close = new EzeeAsyncAction<
    ControlLoadingOrdersState['close'],
    ControlLoadingOrderClosePayload,
    ListResponse<ControlOrderPallet>
>(
    'controlLoadingOrders/close',
    initialState.close,
    requestReducer<ControlLoadingOrdersState['close'], ListResponse<ControlOrderPallet>>(initialState.close)
);

// Reducer
export const controlLoadingOrdersReducer = combineReducers<ControlLoadingOrdersState>({
    create: create.reducer,
    update: update.reducer,
    details: details.reducer,
    palletList: palletList.reducer,
    checkControlLoadingOrderCompletion: checkControlLoadingOrderCompletion.reducer,
    close: close.reducer,
});

// Saga
export function* controlLoadingOrdersSaga() {
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(details.type.trigger, detailsSaga);
    yield takeLatest(palletList.type.trigger, simpleAsyncSaga(palletListApiCall, palletList));
    yield takeLatest(checkControlLoadingOrderCompletion.type.trigger, checkControlLoadingOrderCompletionSaga);
    yield takeLatest(close.type.trigger, simpleAsyncSaga(closeApiCall, close));
}

function* checkControlLoadingOrderCompletionSaga(actionData: DataAction<ControlLoadingOrderCheckCompletionPayload>) {
    try {
        const { controlLoadingOrderId, controlOrderPalletId } = actionData.payload;
        let controlLoadingOrder = false;
        let controlOrderPallet = false;

        const controlLoadingOrderResponse = yield call(detailsApiCall, {
            controlLoadingOrderId,
        });

        if (
            controlLoadingOrderResponse.palletQuantityChecked !== undefined &&
            controlLoadingOrderResponse.palletQuantityChecked === controlLoadingOrderResponse.palletQuantityToCheck
        ) {
            controlLoadingOrder = true;
        }

        if (controlOrderPalletId) {
            const controlOrderPalletResponse = yield call(controlOrderPalletDetailsApiCall, {
                controlOrderPalletId,
            });

            if (
                controlOrderPalletResponse.palletQuantityChecked !== undefined &&
                controlOrderPalletResponse.palletQuantityChecked === controlOrderPalletResponse.palletQuantityToCheck
            ) {
                controlOrderPallet = true;
            }
        }

        return yield put(
            checkControlLoadingOrderCompletion.success({
                controlLoadingOrderId,
                controlOrderPalletId,
                controlLoadingOrder,
                controlOrderPallet,
            })
        );
    } catch (error) {
        return yield put(checkControlLoadingOrderCompletion.failure(error));
    }
}

function* detailsSaga(actionData: DataAction<ControlLoadingOrderDetailsPayload>) {
    if (actionData.meta?.poll) {
        yield put(detailsPolling.actions.startPolling());
        yield race([call(detailsPollTask, actionData), take(detailsPolling.actions.stopPolling().type)]);
    } else {
        try {
            const response = yield call(detailsApiCall, actionData.payload);

            return yield put(details.success(response));
        } catch (error) {
            return yield put(details.failure(error));
        }
    }
}

/* Worker Function */
function* detailsPollTask(action: DataAction<ControlLoadingOrderDetailsPayload>) {
    while (true) {
        try {
            const response = yield call(detailsApiCall, action.payload);

            yield put(details.success(response));
            yield delay(5000);
        } catch (error) {
            yield put(details.failure(error));
            yield delay(5000);
        }
    }
}

// Store helpers
export const getControlLoadingOrderCreateState = (state: MainReducerState) => state.controlLoadingOrders.create;
export const getControlLoadingOrderUpdateState = (state: MainReducerState) => state.controlLoadingOrders.update;
export const getControlLoadingOrderStateById = (id?: ControlLoadingOrder['id']) => (state: MainReducerState) => ({
    loading: state.controlLoadingOrders.details.loading,
    error: state.controlLoadingOrders.details.error,
    data: id ? state.controlLoadingOrders.details.data[id] : undefined,
});
export const getControlLoadingOrderPalletListState = (state: MainReducerState) => state.controlLoadingOrders.palletList;
export const getControlLoadingOrderCloseState = (state: MainReducerState) => state.controlLoadingOrders.close;
export const getControlLoadingOrderCheckCompletionStateById =
    (controlLoadingOrderId: ControlLoadingOrder['id'], controlOrderPalletId?: ControlOrderPallet['id']) =>
    (state: MainReducerState) => ({
        loading: state.controlLoadingOrders.checkControlLoadingOrderCompletion.loading,
        error: state.controlLoadingOrders.checkControlLoadingOrderCompletion.error,
        data: {
            controlLoadingOrder:
                !!state.controlLoadingOrders.checkControlLoadingOrderCompletion.data?.[controlLoadingOrderId]
                    ?.controlLoadingOrder,
            controlOrderPallet:
                !!state.controlLoadingOrders.checkControlLoadingOrderCompletion.data?.[controlLoadingOrderId]
                    ?.controlOrderPallets?.[controlOrderPalletId ?? ''],
        },
    });
