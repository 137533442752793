import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { Place, PlaceFilter } from '../api/apiTypes';
import { ListResponse } from '../api/';

// Controlers
import {
    PlaceListPayload,
    list as listApiCall,
    listFilters as listFiltersApiCall,
    PlaceFiltersListPayload,
    create as createApiCall,
    PlaceCreatePayload,
    PlaceUpdatePayload,
    update as updateApiCall,
    PlaceDeletePayload,
    del as delApiCall,
    detailsPlaceByScan as detailsByScanApiCall,
    PlaceDetailsByScanPayload,
} from '../api/places';
import { EzeeAction } from '../helpers/EzeeAction';

// States
export interface PlacesState {
    list: RequestState<ListResponse<Place>>;
    listDesktop: RequestState<ListResponse<Place>>;
    listFilters: RequestState<PlaceFilter>;
    create: RequestState<Place>;
    update: RequestState<Place>;
    del: RequestState;
    detailsByScan: RequestState<Place>;
    defaultSector: {
        sector: Place['sector'] | undefined;
    };
}

const initialState: PlacesState = {
    list: { ...requestInitialState },
    listDesktop: { ...requestInitialState },
    listFilters: { ...requestInitialState },
    create: { ...requestInitialState },
    update: { ...requestInitialState },
    del: { ...requestInitialState },
    detailsByScan: { ...requestInitialState },
    defaultSector: {
        sector: undefined,
    },
};

export const list = new EzeeAsyncAction<PlacesState['list'], PlaceListPayload, ListResponse<Place>>(
    'places/list',
    initialState.list,
    {
        trigger: (state, _, meta) => ({
            ...state,
            loading: true,
            data: meta?.loadMore ? state.data : undefined,
        }),
        success: (state, payload, meta) => ({
            data: {
                ...payload,
                items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.list,
        }),
    }
);

export const listDesktop = new EzeeAsyncAction<PlacesState['listDesktop'], PlaceListPayload, ListResponse<Place>>(
    'places/listDesktop',
    initialState.listDesktop,
    {
        trigger: (state, _, meta) => ({
            ...state,
            loading: true,
            data: meta?.loadMore ? state.data : undefined,
        }),
        success: (state, payload, meta) => ({
            data: {
                ...payload,
                items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.listDesktop,
        }),
    }
);

export const listFilters = new EzeeAsyncAction<PlacesState['listFilters'], PlaceFiltersListPayload, PlaceFilter>(
    'places/listFilters',
    initialState.listFilters,
    requestReducer<PlacesState['listFilters'], PlaceFilter>(initialState.listFilters)
);

export const create = new EzeeAsyncAction<PlacesState['create'], PlaceCreatePayload, Place>(
    'places/create',
    initialState.create,
    requestReducer<PlacesState['create'], Place>(initialState.create)
);

export const update = new EzeeAsyncAction<PlacesState['update'], PlaceUpdatePayload, Place>(
    'places/update',
    initialState.update,
    requestReducer<PlacesState['update'], Place>(initialState.update)
);

export const del = new EzeeAsyncAction<PlacesState['del'], PlaceDeletePayload>(
    'places/del',
    initialState.del,
    requestReducer<PlacesState['del'], Place>(initialState.del)
);

export const detailsByScan = new EzeeAsyncAction<PlacesState['detailsByScan'], PlaceDetailsByScanPayload, Place>(
    'places/detailsByScan',
    initialState.detailsByScan,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            data: undefined, // reset when fetching
            loading: true,
        }),
        success: (state, payload) => ({
            data: payload,
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.detailsByScan,
        }),
    }
);

export const defaultSector = new EzeeAction<PlacesState['defaultSector']>(
    'places/defaultSector',
    initialState.defaultSector,
    {
        setDefaultSector: (state, payload) => {
            return {
                sector: payload,
            };
        },
    }
);
// Reducer
export const placesReducer = combineReducers<PlacesState>({
    list: list.reducer,
    listDesktop: listDesktop.reducer,
    listFilters: listFilters.reducer,
    create: create.reducer,
    update: update.reducer,
    del: del.reducer,
    detailsByScan: detailsByScan.reducer,
    defaultSector: defaultSector.reducer,
});

// Saga
export function* placesSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(listDesktop.type.trigger, simpleAsyncSaga(listApiCall, listDesktop));
    yield takeLatest(listFilters.type.trigger, simpleAsyncSaga(listFiltersApiCall, listFilters));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
    yield takeLatest(detailsByScan.type.trigger, simpleAsyncSaga(detailsByScanApiCall, detailsByScan));
}

// Store helpers
export const getPlacesListState = (state: MainReducerState) => state.places.list;
export const getPlacesListDesktopState = (state: MainReducerState) => state.places.listDesktop;
export const getPlacesListFiltersState = (state: MainReducerState) => state.places.listFilters;
export const getPlaceCreateState = (state: MainReducerState) => state.places.create;
export const getPlaceUpdateState = (state: MainReducerState) => state.places.update;
export const getPlaceDeleteState = (state: MainReducerState) => state.places.del;
export const getPlaceDetailsByScanState = (state: MainReducerState) => state.places.detailsByScan;
export const getPlaceDefaultSectorState = (state: MainReducerState) => state.places.defaultSector;
