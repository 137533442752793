import { Form, FormProps, Input, message, Typography } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    control,
    transferPackage,
    transferPackages,
    getControlOrderTransferPackagesState,
    getControlOrderTransferPackageState,
    getControlOrderControlState,
    PackageRef,
} from '../../store/actions/preparationControl';
import { Pallet } from '../../store/api/apiTypes';

import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import QuantityCard from '../../components/QuantityCard';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { IconWarning } from '../../components/icons';
import SuccessMessage from '../../components/SuccessMessage';
import { formatNumber } from '../../helpers/i18n';
import { isControlPackageAlreadyStoredLocally } from '../../helpers';

const shortcutScope = 'ControlTransferModal';

interface ControlTransferModalProps extends CustomModalProps {
    palletId?: Pallet['id'];
    onSuccess: () => void;
}

const ControlTransferModal: FC<ControlTransferModalProps> = ({ visible, onCancel, onSuccess, palletId }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const isMounted = useIsMounted();
    const { customerOrderId } = useParams<{ customerOrderId?: string }>();
    const [form] = Form.useForm();
    const [step, setStep] = useState<'default' | 'multiple' | 'manual' | 'success'>('default');
    const [refToStore, setRefToStore] = useState<PackageRef>(); // temp data when waiting for API response
    const [barcodeToStore, setBarcodeToStore] = useState<string>(); // temp data when waiting for API response
    const [
        fetchTransferPackage,
        sendTransferPackages,
        setControlTransferState,
        resetControlTransferState,
        setControlCreatePalletState,
        setControlPrintState,
    ] = useActions([
        transferPackage.trigger,
        transferPackages.trigger,
        control.actions.setTransferData,
        control.actions.resetTransfer,
        control.actions.setCreatePalletData,
        control.actions.setPrintData,
    ]);
    const transferPackageState = useSelector(getControlOrderTransferPackageState);
    const transferPackagesState = useSelector(getControlOrderTransferPackagesState);
    const { packagesRefs, packagesBarcodes } = useSelector(getControlOrderControlState).transfer;
    const { transferredCount: alreadyTransferredCount } = useSelector(getControlOrderControlState).createPallet;
    const { transferPalletIds } = useSelector(getControlOrderControlState).print;
    const previous = usePrevious({ transferPackageState, transferPackagesState });
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const onClickCancel = useCallback(() => {
        onClose();
        setControlPrintState({
            transferPalletIds: [],
        });
    }, [onClose, setControlPrintState]);
    const onSubmitManual: FormProps['onFinish'] = (values) => {
        if (isControlPackageAlreadyStoredLocally(packagesRefs, values)) {
            message.error('Ce colis est déjà en cours de transfert');
        } else {
            setRefToStore({
                reference: values.reference,
                ...(values.cont ? { cont: values.cont } : {}),
            });
            fetchTransferPackage({
                customerOrderId,
                reference: values.reference,
                ...(values.cont ? { cont: values.cont } : {}),
            });
        }
    };
    const onSubmitSinglePackage = () => {
        sendTransferPackages({
            customerOrderId,
            barcodes: packagesBarcodes.length ? packagesBarcodes : undefined,
            references: packagesRefs.length ? packagesRefs : undefined,
            palletId,
        });
    };
    const onSubmitMultiplePackages = () => {
        sendTransferPackages({
            customerOrderId,
            barcodes: packagesBarcodes.length ? packagesBarcodes : undefined,
            references: packagesRefs.length ? packagesRefs : undefined,
            palletId,
        });
    };
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (packagesBarcodes.includes(barCode.data)) {
                    message.error('Ce colis est déjà en cours de transfert');
                } else {
                    setBarcodeToStore(barCode.data);
                    fetchTransferPackage({
                        customerOrderId,
                        barcode: barCode.data,
                    });
                }
            }
        },
        {
            deps: [fetchTransferPackage, packagesBarcodes],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    useEffect(() => {
        if (previous?.transferPackageState.loading && !transferPackageState.loading) {
            if (transferPackageState.error) {
                if (transferPackageState.error?.status === 400) {
                    message.error("Ce colis n'a pas encore été contrôlé");
                } else if (
                    transferPackageState.error?.status === 404 &&
                    transferPackageState.error?.data?.parcelNotFound
                ) {
                    message.error('Colis non trouvé');
                } else {
                    message.error('Une erreur est survenue pendant le transfert');
                }
            } else if (transferPackageState.data) {
                setControlTransferState({
                    packagesBarcodes: barcodeToStore ? [...packagesBarcodes, barcodeToStore] : packagesBarcodes,
                    packagesRefs: refToStore ? [...packagesRefs, refToStore] : packagesRefs,
                });
                if (transferPackageState.data?.pallet?.id) {
                    setControlPrintState({
                        transferPalletIds: [
                            ...transferPalletIds.filter((pId) => pId !== transferPackageState.data?.pallet?.id),
                            transferPackageState.data.pallet.id,
                        ],
                    });
                }

                form.resetFields();
                setStep('multiple');
            }

            setBarcodeToStore(undefined);
            setRefToStore(undefined);
        }
    }, [
        previous?.transferPackageState.loading,
        transferPackageState.loading,
        transferPackageState.error,
        barcodeToStore,
        refToStore,
        form,
        transferPackageState.data,
        setControlTransferState,
        setControlPrintState,
        transferPalletIds,
        packagesBarcodes,
        packagesRefs,
    ]);

    useEffect(() => {
        if (previous?.transferPackagesState.loading && !transferPackagesState.loading) {
            if (transferPackagesState.error) {
                message.error('Une erreur est survenue pendant le transfert');
            } else {
                // must add to already transferred count if we do successive transfers
                setControlCreatePalletState({
                    transferredCount: alreadyTransferredCount + packagesBarcodes.length + packagesRefs.length,
                });
                setStep('success');
            }
        }
    }, [
        previous?.transferPackagesState.loading,
        transferPackagesState.loading,
        transferPackagesState.error,
        transferPackagesState.data,
        setStep,
        setControlCreatePalletState,
        setControlPrintState,
        alreadyTransferredCount,
        packagesBarcodes,
        packagesRefs,
    ]);

    useEffect(() => {
        if (!visible) {
            setStep('default');
            setBarcodeToStore(undefined);
            setRefToStore(undefined);
            resetControlTransferState();
        }
    }, [visible, setBarcodeToStore, setRefToStore, resetControlTransferState]);

    useEffect(() => {
        let timeout: number;

        if (step === 'success') {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onSuccess();
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [step, isMounted, onSuccess]);

    return (
        <CustomModal
            footer={
                step !== 'success' && (
                    <>
                        {step === 'default' && (
                            <>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="enter"
                                        type="primary"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        onClick={onSubmitSinglePackage}
                                        loading={transferPackageState.loading}
                                    >
                                        Transférer ce colis
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="f1"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        type="primary"
                                        onClick={setStep.bind(null, 'multiple')}
                                        ghost
                                    >
                                        Transférer plusieurs colis
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="esc"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        type="primary"
                                        onClick={onClickCancel}
                                        ghost
                                    >
                                        Annuler
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </>
                        )}
                        {step === 'multiple' && (
                            <>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="enter"
                                        type="primary"
                                        shortcutScope={shortcutScope}
                                        onClick={onSubmitMultiplePackages}
                                        loading={transferPackageState.loading}
                                    >
                                        Terminer le transfert
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="f1"
                                        type="primary"
                                        shortcutScope={shortcutScope}
                                        onClick={setStep.bind(null, 'manual')}
                                        ghost
                                    >
                                        Saisir manuellement
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="esc"
                                        shortcutScope={shortcutScope}
                                        type="primary"
                                        onClick={setStep.bind(null, 'default')}
                                        ghost
                                    >
                                        Annuler
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </>
                        )}
                        {step === 'manual' && (
                            <>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="enter"
                                        type="primary"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        onClick={() => form.submit()}
                                        loading={transferPackageState.loading}
                                    >
                                        Valider
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="esc"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        type="primary"
                                        onClick={setStep.bind(null, 'multiple')}
                                        ghost
                                    >
                                        Annuler
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </>
                        )}
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={
                step !== 'success' && (
                    <>
                        {step === 'default' && (
                            <Typography.Text>
                                <Typography.Text type="warning">
                                    <IconWarning style={{ fontSize: '2.25rem', marginBottom: '1rem' }} />
                                </Typography.Text>
                                <br />
                                Ce colis est déjà présent sur une palette, que souhaitez-vous faire ?
                            </Typography.Text>
                        )}
                        {step === 'multiple' && 'Veuillez scanner tous les colis que vous souhaitez tranférer :'}
                        {step === 'manual' && 'Saisir manuellement'}
                    </>
                )
            }
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            altTitle={step === 'manual'}
        >
            {step === 'default' ? null : (
                <>
                    {step === 'manual' && (
                        <Form form={form} onFinish={onSubmitManual}>
                            <Form.Item
                                name="reference"
                                rules={[{ required: true, message: 'Veuillez saisir une référence' }]}
                            >
                                <ArrowNavItem scope={shortcutScope}>
                                    <Input placeholder="Saisir référence colis" autoFocus />
                                </ArrowNavItem>
                            </Form.Item>
                            <Form.Item
                                name="cont"
                                rules={[{ required: true, message: 'Veuillez saisir le numéro CONT' }]}
                                style={{ marginBottom: 0 }}
                            >
                                <ArrowNavItem scope={shortcutScope}>
                                    <Input placeholder="Saisir CONT" />
                                </ArrowNavItem>
                            </Form.Item>
                        </Form>
                    )}
                    {step === 'multiple' && (
                        <QuantityCard
                            value={formatNumber(packagesRefs.length + packagesBarcodes.length)}
                            label="colis à transférer"
                            outline
                        />
                    )}
                    {step === 'success' && <SuccessMessage message="Les colis ont été transférés" />}
                </>
            )}
        </CustomModal>
    );
};

export default ControlTransferModal;
