import jwt_decode from 'jwt-decode';
import { PackageRef } from '../store/actions/preparationControl';
import { Place, PlaceZoneType, User } from '../store/api/apiTypes';

interface LoggerType {
    isAllowed: boolean;
    log: (messages?: any, ...optionalParams: any[]) => void;
    warn: (messages?: any, ...optionalParams: any[]) => void;
}

class Logger implements LoggerType {
    public isAllowed: boolean;

    constructor() {
        this.isAllowed = process.env.NODE_ENV !== 'production';
    }

    public log(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: dodgerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public info(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: cornflowerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public warn(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: darkorange; font-weight: bold', messages, ...optionalParams);
        }
    }

    public error(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: tomato; font-weight: bold', messages, ...optionalParams);
        }
    }

    public group(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.group('%c[Logger]', 'color: dodgerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public groupEnd() {
        if (this.isAllowed) {
            console.groupEnd();
        }
    }
}

export const debug = new Logger();

export const getFullName = (firstname?: string, lastname?: string) => {
    if (!firstname && !lastname) {
        return '';
    }

    return `${firstname ?? ''}${lastname ? ` ${lastname}` : ''}`.trim();
};

export const getOperatorName = (operator?: User) => {
    if (!operator || (!operator.firstName && !operator.lastName)) {
        return '—';
    }

    return `${operator.firstName ?? ''}${operator.lastName ? ` ${operator.lastName.substr(0, 1)}.` : ''}`.trim();
};

export const capitalize = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    const lowerCased = str.toLowerCase();

    return `${lowerCased.charAt(0).toUpperCase()}${lowerCased.slice(1)}`;
};

export const capitalizeWords = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    return str.split(' ').map(capitalize).join(' ');
};

export const addYear = (date: Date) => {
    date.setFullYear(date.getFullYear() + 1);

    return date;
};

export function classNames(...args: Array<string | undefined | boolean>) {
    return [...args].filter(Boolean).join(' ');
}

export const checkIfTokenExpired = (token: string) => {
    try {
        const decoded: { exp: number } = jwt_decode(token);
        return decoded.exp * 1000 - Date.now() < 0;
    } catch (error) {
        return true;
    }
};

export const stripUndefinedKeysFromObject = (object: { [key: string]: any }) =>
    Object.keys(object).forEach((key) => {
        if (object[key] === undefined) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete object[key];
        }
    });

export const urlSearchParamsToObject = (urlSearchParams: URLSearchParams) =>
    Array.from(urlSearchParams.entries()).reduce<Record<string, string | string[]>>((acc, [key, value]) => {
        const currentValueInAccumulator = acc[key];

        if (currentValueInAccumulator) {
            if (typeof currentValueInAccumulator === 'string') {
                acc[key] = [currentValueInAccumulator, value];
            } else {
                acc[key] = [...acc[key], value];
            }
        } else {
            acc[key] = value;
        }

        return acc;
    }, {});

export const formatPlace = (place?: Place) => {
    if (place?.zoneType === PlaceZoneType.preparation) {
        return place?.locker ?? '—';
    } else if (place?.zoneType === PlaceZoneType.picking) {
        const parts = [place?.sector, place?.locker].filter(Boolean);

        return parts.length ? parts.join(' ') : '—';
    } else {
        const parts = [place?.sector, place?.locker, place?.spot].filter(Boolean);

        return parts.length ? parts.join(' ') : '—';
    }
};

export const getLocker = (place?: Place) => {
    if (!place) {
        return '—';
    }

    if (place.locker) {
        return place.locker;
    }

    return !place.lane && !place.alveole && !place.level
        ? '—'
        : `${place.lane ?? ''}${place.alveole ?? ''}${place.level ?? ''}`;
};

export const requiredRule = { required: true, message: 'Champ requis' };

export const isControlPackageAlreadyStoredLocally = (packageRefs: PackageRef[], values: PackageRef) =>
    packageRefs.some((packageRef) => {
        if (packageRef.cont) {
            return packageRef.cont === values.cont;
        } else {
            // if we don't have a cont, allow to store the same ref multiple times
            return false;
        }
    });
