import React, { useEffect, useState } from 'react';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import CustomModal from '../../components/CustomModal';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import { Divider, message, Typography } from 'antd';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ButtonRed from '../../components/ButtonRed';
import { useHistory, useParams } from 'react-router-dom';
import { del as deleteLoadingOrderAction, getLoadingOrderDelState } from '../../store/actions/loadingOrders';
import { useSelector } from 'react-redux';
import SuccessMessage from '../../components/SuccessMessage';
import { getRoute, RoutePathName } from '../../routes';

const shortcutScope = 'LoadingOrderConfirmRemoveModal';

const LoadingOrderConfirmRemoveModal = NiceModal.create(() => {
    const modal = useModal();
    const { loadingOrderId } = useParams<{ loadingOrderId: string }>();
    const { hide, visible } = modal;
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [success, setSuccess] = useState<boolean>(false);
    const deleteLoadingOrder = useActions(deleteLoadingOrderAction.trigger);
    const deleteLoadingOrderState = useSelector(getLoadingOrderDelState);
    const previous = usePrevious({ deleteLoadingOrderState });
    const history = useHistory();

    const onDelete = () => {
        deleteLoadingOrder({ loadingOrderId: parseInt(loadingOrderId, 10) });
    };

    useEffect(() => {
        if (previous?.deleteLoadingOrderState.loading && !deleteLoadingOrderState.loading) {
            if (deleteLoadingOrderState.error) {
                message.error(`Une erreur est survenue durant la suppression de l'ordre de chargement`);
            } else {
                setSuccess(true);
            }
        }
    }, [deleteLoadingOrderState.error, deleteLoadingOrderState.loading, previous?.deleteLoadingOrderState.loading]);

    useEffect(() => {
        let timeout: number;

        if (visible && success) {
            timeout = window.setTimeout(() => {
                hide();
                history.push(getRoute(RoutePathName.preparation));
            }, 1000);
        }

        return () => window.clearTimeout(timeout);
    }, [hide, success, visible]);
    return (
        <CustomModal
            {...antdModal(modal)}
            width={!success ? 368 : 232}
            title={
                !success && (
                    <>
                        Êtes-vous sûr de vouloir <br />
                        supprimer l&lsquo;ordre de chargement ? <br />
                        <br />
                        <Typography.Text type={'danger'}>Cette action est irréversible</Typography.Text>
                    </>
                )
            }
            footer={
                !success && (
                    <>
                        <Divider />
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                as={ButtonRed}
                                onClick={() => onDelete()}
                            >
                                Oui, supprimer l&lsquo;ordre
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={hide}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
        >
            {success && (
                <SuccessMessage
                    message={
                        <>
                            Ordre de chargement
                            <br /> supprimé avec succès
                        </>
                    }
                />
            )}
        </CustomModal>
    );
});

export default LoadingOrderConfirmRemoveModal;
