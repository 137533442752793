import { PlatformReturnsState, platformReturnsReducer } from './../actions/platformReturn';
import { movementsBackToWorkshopsReducer, MovementBackToWorkshopsState } from '../actions/MovementsBackToWorkshops';
import { repairsReducer, RepairsState } from './../actions/repairs';
import { MovementBrokensState, movementBrokensReducer } from './../actions/movementBrokens';
import { combineReducers } from 'redux';

import { ApiState, apiReducer } from '../actions/api';
import { AuthState, authReducer } from '../actions/auth';
import { ControlLoadingOrdersState, controlLoadingOrdersReducer } from '../actions/controlLoadingOrders';
import { ControlOrderPalletsState, controlOrderPalletsReducer } from '../actions/controlOrderPallets';
import { ControlRegularizationState, controlRegularizationReducer } from '../actions/controlRegularization';
import { CustomerOrdersState, customerOrdersReducer } from '../actions/customerOrders';
import {
    CustomerOrderPlatformReasonCodesState,
    customerOrderPlatformReasonCodesReducer,
} from '../actions/customerOrderPlatformReasonCodes';
import { CustomersState, customersReducer } from '../actions/customers';
import { ExpeditionsState, expeditionsReducer } from '../actions/expeditions';
import { InventoriesState, inventoriesReducer } from '../actions/inventories';
import { LoadingOrdersState, loadingOrdersReducer } from '../actions/loadingOrders';
import { LogsState, logsReducer } from '../actions/logs';
import { MovementHistoriesState, movementHistoriesReducer } from '../actions/movementHistories';
import { OrderFurnituresState, orderFurnituresReducer } from '../actions/orderFurnitures';
import { OrderParcelsState, orderParcelsReducer } from '../actions/orderParcels';
import { OrdersState, ordersReducer } from '../actions/orders';
import { OrganizationsState, organizationsReducer } from '../actions/organizations';
import { PalletPlacesState, palletPlacesReducer } from '../actions/palletPlaces';
import { PalletsState, palletsReducer } from '../actions/pallets';
import { ParcelsState, parcelsReducer } from '../actions/parcels';
import { PlaceAlveolesState, placeAlveolesReducer } from '../actions/placeAlveoles';
import { PlaceLanesState, placeLanesReducer } from '../actions/placeLanes';
import { PlaceSectorsState, placeSectorsReducer } from '../actions/placeSectors';
import { PlacesState, placesReducer } from '../actions/places';
import { PlaceStoresState, placeStoresReducer } from '../actions/placeStores';
import { PlatformAnomalyCodesState, platformAnomalyCodesReducer } from '../actions/platformAnomalyCodes';
import { PlatformLoadingsState, platformLoadingsReducer } from '../actions/platformLoadings';
import { PlatformReasonCodesState, platformReasonCodesReducer } from '../actions/platformReasonCodes';
import { PlatformReceptionsState, platformReceptionsReducer } from '../actions/platformReceptions';
import { PreparationControlState, preparationControlReducer } from '../actions/preparationControl';
import { PreparationsState, preparationsReducer } from '../actions/preparations';
import { PrintersState, printersReducer } from '../actions/printers';
import { ProductionsState, productionsReducer } from '../actions/productions';
import { PurchaseOrdersState, purchaseOrdersReducer } from '../actions/purchaseOrders';
import { RelatedPalletsState, relatedPalletsReducer } from '../actions/relatedPallets';
import { ResupplyOrdersState, resupplyOrdersReducer } from '../actions/resupplyOrders';
import { SitesState, sitesReducer } from '../actions/sites';
import { StockDifferencesState, stockDifferencesReducer } from '../actions/stockDifferences';
import { StockResearchState, stockResearchReducer } from '../actions/stockResearch';
import { UsersState, usersReducer } from '../actions/users';
import { transferOrdersReducer, TransferOrdersState } from './../actions/transferOrders';
import { customerReturnReducer, CustomerReturnState } from '../actions/customerReturn';
import { receptionsReducer, ReceptionsState } from '../actions/receptions';
import { machinesReducer, MachinesState } from '../actions/machines';
import { incidentsReducer, IncidentsState } from '../actions/incidents';
import { machinesIncidentsReducer, MachinesIncidentsState } from '../actions/machinesIncidents';

import { RequestError } from '../api';

export interface MainReducerState {
    readonly api: ApiState;
    readonly auth: AuthState;
    readonly controlLoadingOrders: ControlLoadingOrdersState;
    readonly controlOrderPallets: ControlOrderPalletsState;
    readonly controlRegularization: ControlRegularizationState;
    readonly customerOrders: CustomerOrdersState;
    readonly customerOrderPlatformReasonCodes: CustomerOrderPlatformReasonCodesState;
    readonly customers: CustomersState;
    readonly expeditions: ExpeditionsState;
    readonly inventories: InventoriesState;
    readonly loadingOrders: LoadingOrdersState;
    readonly logs: LogsState;
    readonly movementHistories: MovementHistoriesState;
    readonly orderFurnitures: OrderFurnituresState;
    readonly orderParcels: OrderParcelsState;
    readonly orders: OrdersState;
    readonly organizations: OrganizationsState;
    readonly palletPlaces: PalletPlacesState;
    readonly pallets: PalletsState;
    readonly parcels: ParcelsState;
    readonly placeAlveoles: PlaceAlveolesState;
    readonly placeLanes: PlaceLanesState;
    readonly placeSectors: PlaceSectorsState;
    readonly places: PlacesState;
    readonly placeStores: PlaceStoresState;
    readonly platformAnomalyCodes: PlatformAnomalyCodesState;
    readonly platformLoadings: PlatformLoadingsState;
    readonly platformReasonCodes: PlatformReasonCodesState;
    readonly platformReceptions: PlatformReceptionsState;
    readonly preparationControl: PreparationControlState;
    readonly preparations: PreparationsState;
    readonly printers: PrintersState;
    readonly productions: ProductionsState;
    readonly purchaseOrders: PurchaseOrdersState;
    readonly relatedPallets: RelatedPalletsState;
    readonly resupplyOrders: ResupplyOrdersState;
    readonly sites: SitesState;
    readonly stockDifferences: StockDifferencesState;
    readonly stockResearch: StockResearchState;
    readonly users: UsersState;
    readonly transferOrders: TransferOrdersState;
    readonly repairs: RepairsState;
    readonly movementBrokens: MovementBrokensState;
    readonly movementsBackToWorkShops: MovementBackToWorkshopsState;
    readonly platformReturns: PlatformReturnsState;
    readonly customerReturn: CustomerReturnState;
    readonly receptions: ReceptionsState;
    readonly machines: MachinesState;
    readonly incidents: IncidentsState;
    readonly machinesIncidents: MachinesIncidentsState;
}

const mainReducer = combineReducers<MainReducerState>({
    api: apiReducer,
    auth: authReducer,
    controlLoadingOrders: controlLoadingOrdersReducer,
    controlOrderPallets: controlOrderPalletsReducer,
    controlRegularization: controlRegularizationReducer,
    customerOrders: customerOrdersReducer,
    customerOrderPlatformReasonCodes: customerOrderPlatformReasonCodesReducer,
    customers: customersReducer,
    expeditions: expeditionsReducer,
    inventories: inventoriesReducer,
    loadingOrders: loadingOrdersReducer,
    logs: logsReducer,
    movementHistories: movementHistoriesReducer,
    orderFurnitures: orderFurnituresReducer,
    orderParcels: orderParcelsReducer,
    orders: ordersReducer,
    organizations: organizationsReducer,
    palletPlaces: palletPlacesReducer,
    pallets: palletsReducer,
    parcels: parcelsReducer,
    placeAlveoles: placeAlveolesReducer,
    placeLanes: placeLanesReducer,
    placeSectors: placeSectorsReducer,
    places: placesReducer,
    placeStores: placeStoresReducer,
    platformAnomalyCodes: platformAnomalyCodesReducer,
    platformLoadings: platformLoadingsReducer,
    platformReasonCodes: platformReasonCodesReducer,
    platformReceptions: platformReceptionsReducer,
    preparationControl: preparationControlReducer,
    preparations: preparationsReducer,
    printers: printersReducer,
    productions: productionsReducer,
    purchaseOrders: purchaseOrdersReducer,
    relatedPallets: relatedPalletsReducer,
    resupplyOrders: resupplyOrdersReducer,
    sites: sitesReducer,
    stockDifferences: stockDifferencesReducer,
    stockResearch: stockResearchReducer,
    users: usersReducer,
    transferOrders: transferOrdersReducer,
    repairs: repairsReducer,
    movementBrokens: movementBrokensReducer,
    movementsBackToWorkShops: movementsBackToWorkshopsReducer,
    platformReturns: platformReturnsReducer,
    customerReturn: customerReturnReducer,
    receptions: receptionsReducer,
    machines: machinesReducer,
    incidents: incidentsReducer,
    machinesIncidents: machinesIncidentsReducer,
});

export default mainReducer;

export interface RequestState<T = any, TErrorData = any> {
    readonly data?: T;
    readonly error?: RequestError<TErrorData>;
    readonly loading: boolean;
    readonly response?: any;
    readonly success?: boolean;
    readonly total?: number;
}
