import { PlatformAnomalyCode, PlatformLoading } from './../api/apiTypes';
import { ListResponse } from './../api/index';
import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types

import * as api from './../api/platformLoadings';
import { DataAction, EzeeAction } from '../helpers/EzeeAction';

export interface PlatformLoadingsPalletRef {
    reference: string;
    anomalyCode?: PlatformAnomalyCode['id'];
}
export interface PlatformLoadingsPalletBarcode {
    barcode: string;
    anomalyCode?: PlatformAnomalyCode['id'];
}
// States
export interface PlatformLoadingsState {
    create: RequestState<PlatformLoading>;
    list: RequestState<ListResponse<PlatformLoading>>;
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: PlatformLoading | undefined;
        };
    };
    del: RequestState;
    update: RequestState<PlatformLoading>;
    pdf: RequestState<string>;
    checkPlatformLoadingsPallet: RequestState;
    checkPlatformLoadingsPallets: RequestState;
    reception: {
        receive: {
            palletRefs: PlatformLoadingsPalletRef[];
            palletBarcodes: PlatformLoadingsPalletBarcode[];
            isWaitingForAnomaly: boolean;
        };
    };
}

const initialState: PlatformLoadingsState = {
    create: { ...requestInitialState },
    list: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
    del: { ...requestInitialState },
    update: { ...requestInitialState },
    pdf: { ...requestInitialState },
    checkPlatformLoadingsPallet: { ...requestInitialState },
    checkPlatformLoadingsPallets: { ...requestInitialState },
    reception: {
        receive: {
            palletRefs: [],
            palletBarcodes: [],
            isWaitingForAnomaly: false,
        },
    },
};

export const create = new EzeeAsyncAction<
    PlatformLoadingsState['create'],
    api.PlatformLoadingCreatePayload,
    PlatformLoading
>(
    'platformLoadings/create',
    initialState.create,
    requestReducer<PlatformLoadingsState['create'], PlatformLoading>(initialState.create)
);

export const list = new EzeeAsyncAction<
    PlatformLoadingsState['list'],
    api.PlatformLoadingsListPayload,
    ListResponse<PlatformLoading>
>('platformLoadings/list', initialState.list, {
    trigger: (state, _, meta) => ({
        ...state,
        loading: true,
        data: meta?.loadMore ? state.data : undefined,
    }),
    success: (state, payload, meta) => ({
        data: {
            ...payload,
            items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.list,
    }),
});

export const details = new EzeeAsyncAction<
    PlatformLoadingsState['details'],
    api.PlatformLoadingIdPayload,
    PlatformLoading
>('platformLoadings/details', initialState.details, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...state.data,
            [payload.id]: payload,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.details,
    }),
});

export const del = new EzeeAsyncAction<PlatformLoadingsState['del'], api.PlatformLoadingIdPayload, PlatformLoading>(
    'platformLoadings/del',
    initialState.del,
    requestReducer<PlatformLoadingsState['del'], PlatformLoading>(initialState.del)
);

export const update = new EzeeAsyncAction<
    PlatformLoadingsState['update'],
    api.PlatformLoadingUpdatePayload,
    PlatformLoading
>(
    'platformLoadings/update',
    initialState.update,
    requestReducer<PlatformLoadingsState['update'], PlatformLoading>(initialState.update)
);

export const pdf = new EzeeAsyncAction<PlatformLoadingsState['pdf'], api.PlatformLoadingIdPayload, string>(
    'platformLoadings/pdf',
    initialState.pdf,
    requestReducer<PlatformLoadingsState['pdf'], string>(initialState.pdf)
);

export const checkPlatformLoadingsPallet = new EzeeAsyncAction<
    PlatformLoadingsState['checkPlatformLoadingsPallet'],
    api.CheckPlatformLoadingsPalletPayload,
    string
>(
    'platformLoadings/checkPlatformLoadingsPallet',
    initialState.checkPlatformLoadingsPallet,
    requestReducer<PlatformLoadingsState['checkPlatformLoadingsPallet'], string>(
        initialState.checkPlatformLoadingsPallet
    )
);
export const checkPlatformLoadingsPallets = new EzeeAsyncAction<
    PlatformLoadingsState['checkPlatformLoadingsPallets'],
    api.CheckPlatformLoadingsPalletsPayload,
    string
>(
    'platformLoadings/checkPlatformLoadingsPallets',
    initialState.checkPlatformLoadingsPallets,
    requestReducer<PlatformLoadingsState['checkPlatformLoadingsPallets'], string>(
        initialState.checkPlatformLoadingsPallets
    )
);
export const reception = new EzeeAction<PlatformLoadingsState['reception']>(
    'platformLoadings/reception',
    initialState.reception,
    {
        setReceiveData: (state, payload) => {
            return {
                ...state,
                receive: {
                    ...state.receive,
                    ...payload,
                },
            };
        },
        resetReceive: (state) => ({
            ...state,
            receive: {
                ...initialState.reception.receive,
            },
        }),
    }
);

// Reducer
export const platformLoadingsReducer = combineReducers<PlatformLoadingsState>({
    create: create.reducer,
    list: list.reducer,
    details: details.reducer,
    del: del.reducer,
    update: update.reducer,
    pdf: pdf.reducer,
    checkPlatformLoadingsPallet: checkPlatformLoadingsPallet.reducer,
    checkPlatformLoadingsPallets: checkPlatformLoadingsPallets.reducer,
    reception: reception.reducer,
});

// Saga
export function* platformLoadingsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(api.list, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(api.details, details));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(api.create, create));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(api.del, del));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(api.update, update));
    yield takeLatest(pdf.type.trigger, pdfSaga);
    yield takeLatest(
        checkPlatformLoadingsPallet.type.trigger,
        simpleAsyncSaga(api.checkPlatformLoadingsPallet, checkPlatformLoadingsPallet)
    );
    yield takeLatest(
        checkPlatformLoadingsPallets.type.trigger,
        simpleAsyncSaga(api.checkPlatformLoadingsPallets, checkPlatformLoadingsPallets)
    );
}

function* pdfSaga(actionData: DataAction<api.PlatformLoadingIdPayload>) {
    try {
        const response = yield call(api.pdf, actionData.payload);
        const file = new Blob([response], {
            type: 'application/pdf',
        });
        const url = URL.createObjectURL(file);

        return yield put(pdf.success(url));
    } catch (error) {
        return yield put(pdf.failure(error));
    }
}

// Store helpers
export const getPlatformLoadingCreateState = (state: MainReducerState) => state.platformLoadings.create;
export const getPlatformLoadingsListState = (state: MainReducerState) => state.platformLoadings.list;
export const getPlatformLoadingsStateById = (id?: PlatformLoading['id']) => (state: MainReducerState) => ({
    loading: state.platformLoadings.details.loading,
    error: state.platformLoadings.details.error,
    data: id ? state.platformLoadings.details.data[id] : undefined,
});
export const getPlatformLoadingsDelState = (state: MainReducerState) => state.platformLoadings.del;
export const getPlatformLoadingUpdateState = (state: MainReducerState) => state.platformLoadings.update;
export const getPlatformLoadingPdfState = (state: MainReducerState) => state.platformLoadings.pdf;
export const getChekPlatformLoadingsPalletState = (state: MainReducerState) =>
    state.platformLoadings.checkPlatformLoadingsPallet;
export const getChekPlatformLoadingsPalletsState = (state: MainReducerState) =>
    state.platformLoadings.checkPlatformLoadingsPallets;

export const getPlatformReceptionState = (state: MainReducerState) => state.platformLoadings.reception;
