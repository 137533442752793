import api from './_client';
import { Machine } from './apiTypes';
import { SearchPaginationQuery } from './index';

export interface MachinesCreatePayload {
    label: Machine['label'];
}
export const create = async (payload: MachinesCreatePayload) => await api.post(`/machine`, payload);

export type MachinesListPayload = SearchPaginationQuery;
export const list = async (payload: MachinesListPayload = {}) => await api.get(`/machines`, { params: payload });
export interface MachinesDeletePayload {
    machineId: Machine['id'];
}
export const del = async ({ machineId }: MachinesDeletePayload) => await api.delete(`/machine/${machineId}`);
