import { Descriptions, Spin } from 'antd';
import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { OrderParcel, OrderFurniture } from '../../store/api/apiTypes';
import { details as orderDetails, getOrderStateById } from '../../store/actions/orders';

import FixedFooter from '../../components/FixedFooter';
import Header from '../../components/Header';
import List, { ListColumn } from '../../components/list/List';
import PageHeader from '../../components/PageHeader';
import Seo from '../../components/Seo';
import { useActions } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import { formatNumber, translateOrderStatus, translatePreparationStatus } from '../../helpers/i18n';
import { getOperatorName } from '../../helpers';
import TitleBlack from '../../components/TitleBlack';

const OrderDetails: FC = () => {
    const { loadingOrderId, customerOrderId, orderId } =
        useParams<{ loadingOrderId: string; customerOrderId: string; orderId: string }>();
    const [fetchOrder] = useActions([orderDetails.trigger]);
    const orderState = useSelector(getOrderStateById(parseInt(orderId, 10)));
    const orderParcelColumns = (withFurniture?: boolean) =>
        [
            {
                dataIndex: 'parcel.reference',
                title: 'Réf. colis',
                flex: '1 1 50%',
            },
            withFurniture && {
                key: 'quantityPerFurniture',
                title: 'Qté. / meuble',
                flex: '1 1 25%',
                render: (record: OrderParcel) => formatNumber(record.parcel?.quantityPerFurniture),
            },
            {
                key: 'quantity',
                title: 'Quantité',
                flex: '1 1 20%',
                render: (record: OrderParcel) => formatNumber(record.quantity),
            },
            {
                key: 'status',
                title: 'Statut',
                flex: '1 1 30%',
                render: (record: OrderParcel) => translatePreparationStatus(record.preparation?.status),
            },
            {
                key: 'operator',
                title: 'Cariste',
                flex: '1 1 30%',
                render: (record: OrderParcel) => getOperatorName(record.preparation?.operator),
            },
        ].filter(Boolean) as Array<ListColumn<OrderParcel>>;
    const multiParcelColumns: Array<ListColumn<OrderFurniture>> = [
        {
            dataIndex: 'furniture.reference',
            title: 'Référence meuble',
            flex: '1 1 25%',
        },
        {
            dataIndex: 'furniture.label',
            title: 'Désignation',
            flex: '1 1 50%',
        },
        {
            dataIndex: 'quantity',
            title: 'Quantité',
            flex: '1 1 25%',
        },
    ];

    useEffect(() => {
        if (orderId) {
            fetchOrder({ orderId });
        }
    }, [fetchOrder, orderId]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Détail de la commande" />
            <Header
                title="Détail de la commande"
                backRoute={getRoute(RoutePathName.orderList, {
                    loadingOrderId,
                    customerOrderId,
                })}
            />
            <PageHeader>
                <Spin spinning={orderState.loading}>
                    <Descriptions column={{ xs: 4 }} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="N° de commande">
                            {orderState.data?.reference ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Code client">
                            {orderState.data?.customerOrder?.customer?.reference ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Colis">
                            {orderState.data?.nbOfPackageEstimated ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Statut">
                            {translateOrderStatus(orderState.data?.status)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Client" span={4}>
                            {orderState.data?.customerOrder?.customer?.label ?? '—'}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>
            <TitleBlack>Préparations</TitleBlack>
            <List<OrderParcel>
                columns={orderParcelColumns()}
                data={orderState.data?.detailOrderParcels}
                rowKey={(record) => `${record.id}`}
                isLoading={orderState.loading}
                title="Détail / SAV"
                style={{ marginTop: '1.5rem' }}
                altTitle
            />
            <List<OrderFurniture>
                columns={multiParcelColumns}
                data={orderState.data?.orderFurnitures}
                rowKey={(record) => `${record.id}`}
                renderCellFooter={(record) => (
                    <List<OrderParcel>
                        columns={orderParcelColumns(true)}
                        data={record.orderParcels}
                        rowKey={(r) => `${r.id}`}
                        isLoading={orderState.loading}
                        focusable={false}
                        alt
                    />
                )}
                isLoading={orderState.loading}
                style={{ marginTop: '1.5rem' }}
                title="Multicolis"
                headerInCell
                altTitle
            />
            <List<OrderParcel>
                columns={orderParcelColumns()}
                data={orderState.data?.orderParcels}
                rowKey={(record) => `${record.id}`}
                isLoading={orderState.loading}
                title="Monocolis"
                style={{ marginTop: '1.5rem' }}
                altTitle
            />
        </FixedFooter.Wrapper>
    );
};

export default OrderDetails;
