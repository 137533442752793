import { combineReducers } from 'redux';
import { call, delay, put, race, take, takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { DataAction, EzeeAction } from '../helpers/EzeeAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { ResupplyOrder } from '../api/apiTypes';

// Controlers
import {
    ResupplyOrderPickPalletPayload,
    collectPallet as collectPalletApiCall,
    ResupplyOrderDetailsPayload,
    details as detailsApiCall,
    ResupplyOrderListPayload,
    list as listApiCall,
    ResupplyOrderUpdatePayload,
    update as updateApiCall,
    ResupplyOrderCreatePayload,
    create as createApiCall,
} from '../api/resupplyOrders';
import { ListResponse } from '../api';

// States
export interface ResupplyOrdersState {
    list: RequestState<ListResponse<ResupplyOrder>>;
    details: RequestState<{
        [id: number]: ResupplyOrder;
    }>;
    collectPallet: RequestState;
    update: RequestState<ResupplyOrder, { operatorAssigned?: boolean }>;
    create: RequestState<ResupplyOrder>;
}

const initialState: ResupplyOrdersState = {
    list: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
    collectPallet: { ...requestInitialState },
    update: { ...requestInitialState },
    create: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<
    ResupplyOrdersState['list'],
    ResupplyOrderListPayload,
    ListResponse<ResupplyOrder>
>('resupplyOrders/list', initialState.list, {
    trigger: (state, _, meta) => ({
        ...state,
        loading: true,
        data: meta?.loadMore ? state.data : undefined,
    }),
    success: (state, payload, meta) => ({
        data: {
            ...payload,
            items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.list,
    }),
});

export const details = new EzeeAsyncAction<ResupplyOrdersState['details'], ResupplyOrderDetailsPayload, ResupplyOrder>(
    'resupplyOrders/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

export const collectPallet = new EzeeAsyncAction<ResupplyOrdersState['collectPallet'], ResupplyOrderPickPalletPayload>(
    'resupplyOrders/collectPallet',
    initialState.collectPallet,
    requestReducer<ResupplyOrdersState['collectPallet'], ResupplyOrder>(initialState.collectPallet)
);

export const update = new EzeeAsyncAction<ResupplyOrdersState['update'], ResupplyOrderUpdatePayload, ResupplyOrder>(
    'resupplyOrders/update',
    initialState.update,
    requestReducer<ResupplyOrdersState['update'], ResupplyOrder>(initialState.update)
);

export const create = new EzeeAsyncAction<ResupplyOrdersState['create'], ResupplyOrderCreatePayload, ResupplyOrder>(
    'resupplyOrders/create',
    initialState.create,
    requestReducer<ResupplyOrdersState['create'], ResupplyOrder>(initialState.create)
);

export const detailsPolling = new EzeeAction(
    'resupplyOrders/detailsPolling',
    {},
    {
        stopPolling: () => ({}),
        startPolling: () => ({}),
    }
);

export const listPolling = new EzeeAction(
    'resupplyOrders/listPolling',
    {},
    {
        stopPolling: () => ({}),
        startPolling: () => ({}),
    }
);

// Reducer
export const resupplyOrdersReducer = combineReducers<ResupplyOrdersState>({
    list: list.reducer,
    details: details.reducer,
    collectPallet: collectPallet.reducer,
    update: update.reducer,
    create: create.reducer,
});

// Saga
export function* resupplyOrdersSaga() {
    yield takeLatest(list.type.trigger, listSaga);
    yield takeLatest(details.type.trigger, detailsSaga);
    yield takeLatest(collectPallet.type.trigger, simpleAsyncSaga(collectPalletApiCall, collectPallet));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
}

function* listSaga(actionData: DataAction<ResupplyOrderListPayload>) {
    if (actionData.meta?.poll) {
        yield put(listPolling.actions.startPolling());
        yield race([call(listPollTask, actionData), take(listPolling.actions.stopPolling().type)]);
    } else {
        try {
            const response = yield call(listApiCall, actionData.payload);

            return yield put(list.success(response, actionData.meta));
        } catch (error) {
            return yield put(list.failure(error, actionData.meta));
        }
    }
}

function* detailsSaga(actionData: DataAction<ResupplyOrderDetailsPayload>) {
    if (actionData.meta?.poll) {
        yield put(detailsPolling.actions.startPolling());
        yield race([call(detailsPollTask, actionData), take(detailsPolling.actions.stopPolling().type)]);
    } else {
        try {
            const response = yield call(detailsApiCall, actionData.payload);

            return yield put(details.success(response));
        } catch (error) {
            return yield put(details.failure(error));
        }
    }
}

/* Worker Function */
function* detailsPollTask(action: DataAction<ResupplyOrderDetailsPayload>) {
    while (true) {
        try {
            const response = yield call(detailsApiCall, action.payload);

            yield put(details.success(response));
            yield delay(5000);
        } catch (error) {
            yield put(details.failure(error));
            yield delay(5000);
        }
    }
}
function* listPollTask(action: DataAction<ResupplyOrderListPayload>) {
    while (true) {
        try {
            const response = yield call(listApiCall, action.payload);

            yield put(list.success(response, action.meta));
            yield delay(5000);
        } catch (error) {
            yield put(list.failure(error, action.meta));
            yield delay(5000);
        }
    }
}

// Store helpers
export const getResupplyOrderListState = (state: MainReducerState) => state.resupplyOrders.list;
export const getResupplyOrderPickPalletState = (state: MainReducerState) => state.resupplyOrders.collectPallet;
export const getResupplyOrderDetailsStateById = (id: ResupplyOrder['id']) => (state: MainReducerState) => ({
    loading: state.resupplyOrders.details.loading,
    error: state.resupplyOrders.details.error,
    data: state.resupplyOrders.details.data?.[id],
});
export const getResupplyOrderUpdateState = (state: MainReducerState) => state.resupplyOrders.update;
export const getResupplyOrderCreateState = (state: MainReducerState) => state.resupplyOrders.create;
