import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { InventoryType } from '../../store/api/apiTypes';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useArrowNavScope, useShortcutScope } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';

const shortcutScope = 'InventoryCreateTypeModal';

const InventoryCreateTypeModal: FC<CustomModalProps> = ({ visible, onCancel }) => {
    const history = useHistory();
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const onSubmit = (type: InventoryType) => {
        history.push(getRoute(RoutePathName.inventoryCreate, { inventoryType: type }));
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    return (
        <CustomModal
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="f1"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onSubmit.bind(null, InventoryType.place)}
                        >
                            Emplacement
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="f2"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onSubmit.bind(null, InventoryType.parcel)}
                        >
                            Référence
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClose}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            visible={visible}
            onCancel={onCancel}
            title="Type d'inventaire"
            width={368}
            keyboard={false}
            altTitle
        />
    );
};

export default InventoryCreateTypeModal;
