import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestInitialState } from '../helpers';

// Types
import { PalletPlace } from '../api/apiTypes';
import { ListResponse } from '../api/';

// Controlers
import { StockResearchSearchPayload, search as searchApiCall } from '../api/stockResearch';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';

// States
export interface StockResearchState {
    searchParcels: RequestState<ListResponse<PalletPlace>>;
    searchPlaces: RequestState<ListResponse<PalletPlace>>;
    searchQuantity: RequestState<ListResponse<PalletPlace>>;
}

const initialState: StockResearchState = {
    searchParcels: { ...requestInitialState },
    searchPlaces: { ...requestInitialState },
    searchQuantity: { ...requestInitialState },
};

export const searchParcels = new EzeeAsyncAction<
    StockResearchState['searchParcels'],
    StockResearchSearchPayload,
    ListResponse<PalletPlace>
>('stockResearch/searchParcels', initialState.searchParcels, {
    trigger: (state) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload, meta) => ({
        data: {
            ...payload,
            items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.searchParcels,
    }),
});

export const searchPlaces = new EzeeAsyncAction<
    StockResearchState['searchPlaces'],
    StockResearchSearchPayload,
    ListResponse<PalletPlace>
>('stockResearch/searchPlaces', initialState.searchPlaces, {
    trigger: (state, _, meta) => ({
        ...state,
        loading: true,
        data: meta?.loadMore ? state.data : undefined,
    }),
    success: (state, payload, meta) => ({
        data: {
            ...payload,
            items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.searchPlaces,
    }),
});

export const searchQuantity = new EzeeAsyncAction<
    StockResearchState['searchQuantity'],
    StockResearchSearchPayload,
    ListResponse<PalletPlace>
>('stockResearch/searchQuantity', initialState.searchQuantity, {
    trigger: (state, _, meta) => ({
        ...state,
        loading: true,
        data: meta?.loadMore ? state.data : undefined,
    }),
    success: (state, payload, meta) => ({
        data: {
            ...payload,
            items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.searchQuantity,
    }),
});

// Reducer
export const stockResearchReducer = combineReducers<StockResearchState>({
    searchParcels: searchParcels.reducer,
    searchPlaces: searchPlaces.reducer,
    searchQuantity: searchQuantity.reducer,
});

// Saga
export function* stockResearchSaga() {
    yield takeLatest(searchParcels.type.trigger, simpleAsyncSaga(searchApiCall, searchParcels));
    yield takeLatest(searchPlaces.type.trigger, simpleAsyncSaga(searchApiCall, searchPlaces));
    yield takeLatest(searchQuantity.type.trigger, simpleAsyncSaga(searchApiCall, searchQuantity));
}

// Store helpers
export const getParcelsSearchState = (state: MainReducerState) => state.stockResearch.searchParcels;
export const getPlacesSearchState = (state: MainReducerState) => state.stockResearch.searchPlaces;
export const getQuantitySearchState = (state: MainReducerState) => state.stockResearch.searchQuantity;
