import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useScanner, useShortcutScope } from '../../../hooks';
import { MovementHistoryType, Place, PlaceZoneType, TransferType } from '../../../store/api/apiTypes';

import { Input, InputProps, message, Spin } from 'antd';
import { isZebra } from '../../../helpers/enterprise-browser';
import { detailsByScan, getPlaceDetailsByScanState } from '../../../store/actions/places';
import { useHistory, useLocation } from 'react-router-dom';
import {
    updateAndStore,
    getTransferOrderStoreState,
    getTransferOrdersCreateState,
} from '../../../store/actions/transferOrders';
import SuccessMessage from '../../../components/SuccessMessage';
import { formatZoneType } from '../../../helpers/i18n';
import { translateTransferOrderError } from '../../../helpers/errors';

const shortcutScope = 'ReferenceModal';
interface StoreReferenceProps extends CustomModalProps {
    finishZoneType?: PlaceZoneType;
    onReturn: () => void;
    visible: boolean;
    transferType?: TransferType;
    onSuccess: () => void;
}

const FinishZoneScanModal: FC<StoreReferenceProps> = ({ visible, onReturn, finishZoneType, onSuccess }) => {
    const [reference, setReference] = useState<string>();
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [success, setSuccess] = useState<boolean>();

    const [fetchPlaceDetails, updateAndStoreAction] = useActions([detailsByScan.trigger, updateAndStore.trigger]);

    const placeScanState = useSelector(getPlaceDetailsByScanState);
    const storeTransferOrderState = useSelector(getTransferOrderStoreState);
    const transferOrdersCreationState = useSelector(getTransferOrdersCreateState);

    const isMounted = useIsMounted();
    const history = useHistory();
    const location =
        useLocation<{
            zoneType: PlaceZoneType;
            place?: Partial<Place>;
            transferType?: TransferType;
        }>();

    const handlePlaceChange: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };

    const handleSubmit = () => {
        fetchPlaceDetails({ reference });
    };

    const previous = usePrevious({
        placeScanState,
        storeTransferOrderState,
    });

    useEffect(() => {
        if (visible && previous?.placeScanState.loading && !placeScanState.loading) {
            if (placeScanState.error) {
                message.error('Emplacement non reconnue');
            } else {
                const zoneType = finishZoneType ?? location.state?.zoneType;
                if (transferOrdersCreationState.data?.startPlaceZoneType === PlaceZoneType.picking) {
                    if (zoneType === PlaceZoneType.mass) {
                        if (location.state?.place?.id !== placeScanState.data?.id) {
                            message.error("L'emplacement sélectionné est different de l'emplacement flashé");
                        } else {
                            updateAndStoreAction({
                                id: transferOrdersCreationState.data?.id,
                                type: MovementHistoryType.transfer,
                                index: location.state?.place?.index,
                                palletId: transferOrdersCreationState.data?.startZone.pallet?.id,
                                placeId: placeScanState.data?.id,
                                transferType: TransferType.pallet,
                            });
                        }
                    } else {
                        if (!placeScanState.data?.storageByAccumulation) {
                            message.error('Emplacement non valable');
                        } else {
                            updateAndStoreAction({
                                id: transferOrdersCreationState.data?.id,
                                type: MovementHistoryType.transfer,
                                palletId: transferOrdersCreationState.data?.startZone.pallet?.id,
                                placeId: placeScanState.data?.id,
                                transferType: TransferType.pallet,
                            });
                        }
                    }
                } else {
                    if (
                        location.state?.place?.id !== placeScanState.data?.id &&
                        finishZoneType !== PlaceZoneType.accumulation
                    ) {
                        message.error("L'emplacement scanné est différent de l'emplacement sélectionné");
                    } else if (
                        (zoneType === PlaceZoneType.accumulation && !placeScanState.data?.storageByAccumulation) ||
                        (zoneType !== PlaceZoneType.accumulation && zoneType !== placeScanState.data?.zoneType)
                    ) {
                        message.error(`Emplacement non reconnu en ${zoneType}`);
                    } else {
                        const updatePayload = {
                            id: transferOrdersCreationState.data?.id,
                            type: MovementHistoryType.transfer,
                            index: location.state?.place?.index,
                            palletId: transferOrdersCreationState.data?.startZone?.pallet?.id,
                            placeId: placeScanState.data?.id,
                            transferType: TransferType.pallet,
                        };
                        updateAndStoreAction(updatePayload);
                    }
                }
            }
        }
    }, [
        finishZoneType,
        location.state?.place?.id,
        location.state?.place?.index,
        location.state?.zoneType,
        placeScanState.data?.id,
        placeScanState.data?.storageByAccumulation,
        placeScanState.data?.zoneType,
        placeScanState.error,
        placeScanState.loading,
        previous?.placeScanState.loading,
        transferOrdersCreationState.data?.id,
        transferOrdersCreationState.data?.startPlaceZoneType,
        transferOrdersCreationState.data?.startZone.pallet?.id,
        updateAndStoreAction,
        visible,
    ]);

    useEffect(() => {
        if (visible && previous?.storeTransferOrderState.loading && !storeTransferOrderState.loading) {
            if (storeTransferOrderState.error) {
                message.error(translateTransferOrderError(storeTransferOrderState.error));
            } else {
                setSuccess(true);
            }
        }
    }, [
        previous?.storeTransferOrderState.loading,
        storeTransferOrderState.error,
        storeTransferOrderState.loading,
        visible,
    ]);

    useEffect(() => {
        let timeout: number;

        if (success) {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    history.replace({});
                    onSuccess();
                }
            }, 2000);
        }
        return () => window.clearTimeout(timeout);
    }, [success, isMounted, history, onSuccess]);

    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                fetchPlaceDetails({ barcode: barCode.data });
            }
        },
        {
            deps: [],
            disable: !visible,
        }
    );
    const zoneType = finishZoneType ?? location.state?.zoneType;
    return (
        <CustomModal
            visible={visible}
            title={success ?? storeTransferOrderState.loading ? '' : `rangement ${formatZoneType(zoneType)}`}
            width={368}
            keyboard={false}
            altTitle
            transitionName=""
            maskTransitionName=""
            footer={
                !storeTransferOrderState.loading &&
                !success && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={handleSubmit}
                            >
                                Valider le rangement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onReturn}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
        >
            {storeTransferOrderState.loading ? (
                <div style={{ textAlign: 'center' }}>
                    <p>Transfert en cours..</p>
                    <Spin size="large" />
                </div>
            ) : success ? (
                <SuccessMessage message="Transfert effectué avec succès" />
            ) : (
                <ArrowNavItem scope={shortcutScope}>
                    <Input
                        placeholder={isZebra ? `Flasher l'emplacement` : `Entrer la reference de l'emplacement`}
                        value={reference}
                        onChange={handlePlaceChange}
                    />
                </ArrowNavItem>
            )}
        </CustomModal>
    );
};

export default FinishZoneScanModal;
