import { Pallet, PlatformAnomalyCode } from './apiTypes';
import api from './_client';

export interface PlatformReturnCheckPalletPayload {
    reference?: Pallet['reference'];
    barcode?: Pallet['barcode'];
}

export const platformCheckReturnPallet = async (payload: PlatformReturnCheckPalletPayload) =>
    await api.get(`/platformReturnsPallet`, { params: payload });

export interface PlatformReturnPalletsPayload {
    references?: Array<{
        reference: string;
        anomalyCode?: PlatformAnomalyCode['id'];
    }>;
    barcodes?: Array<{
        barcode: string;
        anomalyCode?: PlatformAnomalyCode['id'];
    }>;
}

export const platformReturnPallets = async (payload: PlatformReturnPalletsPayload) =>
    await api.post(`/platformReturnsPallets`, payload);
