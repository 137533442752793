import { message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { CustomerOrdersState } from '../store/actions/customerOrders';
import { PlatformLoadingsState } from '../store/actions/platformLoadings';
import { PlatformReceptionsState } from '../store/actions/platformReceptions';
import { PlatformReturnsState } from '../store/actions/platformReturn';
import { PreparationControlState } from '../store/actions/preparationControl';
import { ResupplyOrdersState } from '../store/actions/resupplyOrders';
import {
    ControlCustomerOrderStatus,
    ControlLoadingOrderStatus,
    ControlOrderPalletStatus,
    ControlPreparationStatus,
    CustomerOrderStatus,
    DayOfWeek,
    InventoryStatus,
    InventoryType,
    LoadingOrderStatus,
    LoadingOrderType,
    MovementBrokenStatus,
    MovementHistoryType,
    OrderStatus,
    PalletSize,
    PalletStatus,
    ParcelType,
    PickingStatus,
    PlaceStoreType,
    PlaceZoneType,
    PlatformLoadingStatus,
    PlatformReceptionStatus,
    PreparationStatus,
    ReceptionStatus,
    RepairStatus,
    ResupplyOrderStatus,
    ResupplyOrderType,
    StickerType,
    StockControlStatus,
    StockControlType,
    TransferOrderStatus,
    TransferType,
} from '../store/api/apiTypes';

export const formatNumber = (value?: number, options?: Intl.NumberFormatOptions) => {
    if (value === undefined) {
        return 0;
    }

    return Intl.NumberFormat('fr-FR', options).format(value);
};

export const formatDate = (value?: string | Date | null, options?: Intl.DateTimeFormatOptions) => {
    if (value === undefined || value === null) {
        return '—';
    }

    const date = value instanceof Date ? value : new Date(value);

    return Intl.DateTimeFormat(
        'fr-FR',
        options ?? {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        }
    ).format(date);
};

export const convertSecondsToDayjs = (seconds?: number) => {
    if (seconds === undefined || seconds === null) {
        return undefined;
    }

    return dayjs().startOf('day').add(seconds, 'second');
};

export const convertDayjsToSeconds = (date?: Dayjs) => {
    if (date === undefined || date === null || !(date instanceof dayjs)) {
        return 0;
    }

    const hours = date.hour();
    const minutes = date.minute();

    return hours * 3600 + minutes * 60;
};

export const formatTimeFromSeconds = (seconds?: number, options?: Intl.DateTimeFormatOptions) => {
    if (seconds === undefined || seconds === null) {
        return '—';
    }

    const date = dayjs().startOf('day').add(seconds, 'second').toDate();

    return Intl.DateTimeFormat(
        'fr-FR',
        options ?? {
            hour: '2-digit',
            minute: '2-digit',
        }
    ).format(date);
};

export const translatePalletSize = (size?: PalletSize, small?: boolean) => {
    switch (size) {
        case PalletSize.small:
            return 'Petite';
        case PalletSize.medium:
            return small ? 'Moy.' : 'Moyenne';
        case PalletSize.large:
            return 'Grande';

        default:
            return size ?? '—';
    }
};

export const defaultErrorMessage =
    'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur';

export const translatePalletStatus = (status?: PalletStatus, fromPlatform?: boolean) => {
    switch (status) {
        case PalletStatus.opened:
            return 'En cours';
        case PalletStatus.closed:
            return 'Attente rangement';
        case PalletStatus.stored:
            return 'Rangée';
        case PalletStatus.toBeLoaded:
            return 'À charger';
        case PalletStatus.loaded:
            return fromPlatform ? 'À réceptionner' : 'Chargée';
        case PalletStatus.receivedInPlatform:
            return 'Reçu';
        case PalletStatus.receivedInPlatformDamaged:
            return 'Avarie';
        case PalletStatus.receivedInPlatformDerogated:
            return 'Dérogé';
        case PalletStatus.loadedSendToClient:
            return 'Chargé';
        case PalletStatus.loadedSendToClientDerogated:
            return 'Chargé (dérogé)';
        case PalletStatus.toBeLoadedSendToClient:
            return 'À charger';
        case PalletStatus.toBeLoadedSendToClientDerogated:
            return 'À charger (dérogé)';

        default:
            return status ?? '—';
    }
};
export const translateReceptionStatus = (status?: ReceptionStatus) => {
    switch (status) {
        case ReceptionStatus.pending:
            return 'En attente';
        case ReceptionStatus.inProgress:
            return 'En cours';

        default:
            return status ?? '—';
    }
};

export const translateParcelType = (type?: ParcelType) => {
    switch (type) {
        case ParcelType.negoce:
            return 'Négoce';
        case ParcelType.production:
            return 'Production';
        case ParcelType.assimilatedProduction:
            return 'SAV';

        default:
            return type ?? '—';
    }
};

export const translatePlaceZoneType = (type?: PlaceZoneType) => {
    switch (type) {
        case PlaceZoneType.mass:
            return 'Masse';
        case PlaceZoneType.picking:
            return 'Picking';
        case PlaceZoneType.preparation:
            return 'Préparation';
        case PlaceZoneType.accumulation:
            return 'Accumulation';
        default:
            return type ?? '—';
    }
};

export const translateResupplyOrderType = (type?: ResupplyOrderType, small?: boolean) => {
    switch (type) {
        case ResupplyOrderType.direct:
            return 'Direct';
        case ResupplyOrderType.resupply:
            return small ? 'Réappro.' : 'Réapprovisionnement';

        default:
            return type ?? '—';
    }
};

export const translateResupplyOrderStatus = (status?: ResupplyOrderStatus) => {
    switch (status) {
        case ResupplyOrderStatus.toBeProcessed:
            return 'À traiter';
        case ResupplyOrderStatus.inProgress:
            return 'En cours';
        case ResupplyOrderStatus.completed:
            return 'Terminé';

        default:
            return status ?? '—';
    }
};

export const translateTransferOrderStatus = (status?: TransferOrderStatus) => {
    switch (status) {
        case TransferOrderStatus.toBeProcessed:
            return 'À traiter';
        case TransferOrderStatus.inProgress:
            return 'En cours';
        case TransferOrderStatus.completed:
            return 'Terminé';

        default:
            return status ?? '—';
    }
};

export const translateInventoryStatus = (status?: InventoryStatus) => {
    switch (status) {
        case InventoryStatus.toBeProcessed:
            return 'À traiter';
        case InventoryStatus.inProgress:
            return 'En cours';
        case InventoryStatus.toValidGap:
        case InventoryStatus.completed:
            return 'Terminé';

        default:
            return status ?? '—';
    }
};

export const translateInventoryType = (type?: InventoryType) => {
    switch (type) {
        case InventoryType.parcel:
            return 'Référence';
        case InventoryType.place:
            return 'Emplacement';

        default:
            return type ?? '—';
    }
};

export const translateStockControlStatus = (status?: StockControlStatus) => {
    switch (status) {
        case StockControlStatus.toBeProcessed:
            return 'À traiter';
        case StockControlStatus.inProgress:
            return 'En cours';
        case StockControlStatus.toValidGap:
        case StockControlStatus.completed:
            return 'Terminé';

        default:
            return status ?? '—';
    }
};

export const translateStockControlType = (type?: StockControlType) => {
    switch (type) {
        case StockControlType.parcel:
            return 'Référence';
        case StockControlType.place:
            return 'Emplacement';

        default:
            return type ?? '—';
    }
};

export const translateLoadingOrderStatus = (status?: LoadingOrderStatus) => {
    switch (status) {
        case LoadingOrderStatus.toBeProcessed:
            return 'À traiter';
        case LoadingOrderStatus.inProgress:
            return 'En cours';
        case LoadingOrderStatus.completed:
            return 'Terminé';
        case LoadingOrderStatus.toBeLoaded:
            return 'À charger';
        case LoadingOrderStatus.loaded:
            return 'Chargé';

        default:
            return status ?? '—';
    }
};

export const translateLoadingOrderType = (type?: LoadingOrderType) => {
    switch (type) {
        case LoadingOrderType.directDelevery:
            return 'Direct';
        case LoadingOrderType.logisticsPlateform:
            return 'Plateforme';

        default:
            return type ?? '—';
    }
};

export const translateOrderStatus = (status?: OrderStatus) => {
    switch (status) {
        case OrderStatus.toBeProcessed:
            return 'À traiter';
        case OrderStatus.started:
            return 'Lancé';
        case OrderStatus.inProgress:
            return 'En cours';
        case OrderStatus.completed:
            return 'Terminé';

        default:
            return status ?? '—';
    }
};

export const translateCustomerOrderStatus = (status?: CustomerOrderStatus) => {
    switch (status) {
        case CustomerOrderStatus.toBeProcessed:
            return 'À traiter';
        case CustomerOrderStatus.inProgress:
            return 'En cours';
        case CustomerOrderStatus.completed:
            return 'Terminé';
        case CustomerOrderStatus.cancelled:
            return 'Annulé';
        case CustomerOrderStatus.toBeLoaded:
            return 'À charger';
        case CustomerOrderStatus.loaded:
            return 'Chargé';
        case CustomerOrderStatus.toRegularize:
            return 'À régulariser';
        case CustomerOrderStatus.toBeLoadedSendToClient:
            return 'À charger';
        case CustomerOrderStatus.loadingSendToClient:
            return 'En cours';

        case CustomerOrderStatus.loadedSendToClient:
            return 'Chargé';
        default:
            return status ?? '—';
    }
};

export const translatePlatformLoadingStatus = (status?: PlatformLoadingStatus) => {
    switch (status) {
        case PlatformLoadingStatus.toBeProcessed:
            return 'À traiter';
        case PlatformLoadingStatus.inProgress:
            return 'En cours';
        case PlatformLoadingStatus.completed:
            return 'Terminé';

        default:
            return status ?? '—';
    }
};

export const translateCustomerOrderControlStatus = (status?: ControlCustomerOrderStatus) => {
    switch (status) {
        case ControlCustomerOrderStatus.toBeProcessed:
            return 'À traiter';
        case ControlCustomerOrderStatus.inProgress:
            return 'En cours';
        case ControlCustomerOrderStatus.completed:
            return 'Terminé';

        default:
            return status ?? '—';
    }
};

export const translatePreparationStatus = (status?: PreparationStatus) => {
    switch (status) {
        case PreparationStatus.toBeProcessed:
            return 'À traiter';
        case PreparationStatus.started:
            return 'Lancé';
        case PreparationStatus.inProgress:
            return 'En cours';
        case PreparationStatus.completed:
            return 'Terminé';
        case PreparationStatus.toRegularize:
            return 'À régulariser';
        case PreparationStatus.canceled:
            return 'Annulé';
        case PreparationStatus.partial:
            return 'Partiel';

        default:
            return status ?? '—';
    }
};

export const translatePreparationControlStatus = (status?: ControlPreparationStatus) => {
    switch (status) {
        case ControlPreparationStatus.toBeProcessed:
            return 'À traiter';
        case ControlPreparationStatus.inProgress:
            return 'En cours';
        case ControlPreparationStatus.completed:
            return 'Terminé';
        case ControlPreparationStatus.toRegularize:
            return 'À régulariser';

        default:
            return status ?? '—';
    }
};

export const translatePreparationPickingStatus = (status?: PickingStatus) => {
    switch (status) {
        case PickingStatus.toProceed:
            return 'À traiter';
        case PickingStatus.inProgress:
            return 'En cours';
        case PickingStatus.waiting:
            return 'En attente';
        case PickingStatus.finished:
            return 'Terminé';

        default:
            return status ?? '—';
    }
};

export const translateControlOrderPalletStatus = (status?: ControlOrderPalletStatus) => {
    switch (status) {
        case ControlOrderPalletStatus.toBeProcessed:
            return 'À traiter';
        case ControlOrderPalletStatus.inProgress:
            return 'En cours';
        case ControlOrderPalletStatus.completed:
            return 'Terminé';

        default:
            return status ?? '—';
    }
};

export const translateControlLoadingOrderStatus = (status?: ControlLoadingOrderStatus) => {
    switch (status) {
        case ControlLoadingOrderStatus.toBeProcessed:
            return 'À traiter';
        case ControlLoadingOrderStatus.inProgress:
            return 'En cours';
        case ControlLoadingOrderStatus.completed:
            return 'Terminé';

        default:
            return status ?? '—';
    }
};

export const translateMovementHistoryType = (type?: MovementHistoryType, small?: boolean) => {
    switch (type) {
        case MovementHistoryType.reception:
            return 'Réception';
        case MovementHistoryType.picking:
            return 'Picking';
        case MovementHistoryType.resupply:
            return small ? 'Réappro.' : 'Réapprovisionnement';
        case MovementHistoryType.direct:
            return 'Direct';
        case MovementHistoryType.transfer:
            return 'Transfert';
        case MovementHistoryType.broken:
            return 'Casse';
        case MovementHistoryType.destruction:
            return 'Destruction';
        case MovementHistoryType.discountShipping:
            return 'Envoi soldeur';
        case MovementHistoryType.backToWorkshop:
            return 'Retour atelier';
        case MovementHistoryType.repair:
            return 'Réparation';
        case MovementHistoryType.customerReturn:
            return 'Retour client';

        default:
            return type ?? '—';
    }
};

export const translatePlaceStoreType = (type?: PlaceStoreType) => {
    switch (type) {
        case PlaceStoreType.site:
            return 'Site';
        case PlaceStoreType.well:
            return 'Puits';

        default:
            return type ?? '—';
    }
};

export const getResupplyOrderCreateError = (requestState: ResupplyOrdersState['create']) => {
    const status = requestState.error?.status;
    const {
        placeNotFound,
        parcelNotFound,
        parcelNotReserved,
        wrongPlaceType,
        placeNotEmpty,
        packageFound,
        noMassStock,
    } = requestState.error?.data || {};

    if (status === 400) {
        if (parcelNotReserved) {
            return "Cet emplacement n'est pas réservé pour une référence";
        } else if (wrongPlaceType) {
            return "Il n'est pas possible d'effectuer de réapprovisionnement sur cet emplacement";
        } else if (placeNotEmpty) {
            return "L'emplacement choisi n'est pas vide";
        } else if (noMassStock) {
            return "Il n'y a pas de stock pour la référence de cet emplacement";
        } else {
            return "Une erreur est survenue lors de la demande d'OR";
        }
    } else if (status === 404) {
        if (placeNotFound) {
            return 'Emplacement non trouvé';
        } else if (parcelNotFound) {
            return 'Colis non trouvé';
        } else {
            return "Une erreur est survenue pendant la création de l'ordre de réapprovisionnement (404)";
        }
    } else if (status === 409) {
        if (packageFound) {
            return "Impossible de créer l'ordre de réapprovisionnement car l'emplacement cible contient des colis";
        } else {
            return "Un ordre de réapprovisionnement existe déjà pour l'emplacement cible";
        }
    } else {
        return "Une erreur est survenue lors de la demande d'OR";
    }
};

export const translateMovementBrokenStatus = (status?: MovementBrokenStatus) => {
    switch (status) {
        case MovementBrokenStatus.completed:
            return 'Terminé';
        case MovementBrokenStatus.inProgress:
            return 'En cours';
        case MovementBrokenStatus.toBeProcessed:
        case MovementBrokenStatus.created:
            return 'À traiter';
        default:
            return '-';
    }
};
export const getControlPackageGetError = (requestState: PreparationControlState['controlPackage']) => {
    const status = requestState.error?.status ?? 0;
    const {
        alreadyChecked,
        pallet,
        contAlreadyUsed,
        parcelNotFound,
        controlNotFound,
        parcelNotInPreparation,
        parcelNotPrepared,
        invalidBarcode,
        controlCompleted,
    } = requestState.error?.data ?? {};
    const result = {
        message: '',
        alreadyControlled: false,
        messageDuration: 3,
    };

    if (status === 400) {
        if (alreadyChecked && pallet) {
            result.message = 'Ce colis est déjà présent dans une autre palette';
            result.alreadyControlled = true;
        } else if (contAlreadyUsed) {
            result.message = 'Ce numéro de CONT est déjà utilisé pour un autre colis';
        } else if (parcelNotInPreparation) {
            result.message = "Ce colis n'est pas attribué à ce client";
        } else if (parcelNotPrepared) {
            result.message = 'Colis non préparé';
        } else if (invalidBarcode) {
            result.message = 'Erreur de format de code barre';
        } else if (controlCompleted) {
            result.message =
                'Le nombre maximum de ce colis à contrôler est déjà atteint, vous pouvez transférer/supprimer des colis, mais pas en ajouter';
            result.messageDuration = 5;
        } else {
            result.message = 'Une erreur est survenue pendant le contrôle (400)';
        }
    } else if (status === 404) {
        if (parcelNotFound) {
            result.message = 'Colis non trouvé';
        } else if (controlNotFound) {
            result.message = "Ce colis n'est pas à contrôler";
        } else {
            result.message = 'Une erreur est survenue pendant le contrôle (404)';
        }
    } else {
        result.message = `Une erreur est survenue pendant le contrôle (${status})`;
    }

    return result;
};

export const getRemovePackageGetError = (requestState: PreparationControlState['removePackage']) => {
    const status = requestState.error?.status ?? 0;
    const { parcelNotFound, noRegularization, controlNotFound } = requestState.error?.data ?? {};
    const result = {
        message: '',
    };

    if (status === 404) {
        if (parcelNotFound) {
            result.message = 'Colis non trouvé';
        } else if (noRegularization || controlNotFound) {
            result.message = "Ce colis n'est pas à régulariser";
        } else {
            result.message = "Le colis n'est pas présent sur cette palette";
        }
    } else {
        result.message = `Une erreur est survenue pendant la suppression (${status})`;
    }

    return result;
};

export const getRemovePackagesGetError = (requestState: PreparationControlState['removePackages']) => {
    const status = requestState.error?.status ?? 0;
    const { wrongPallet, palletNotFound, controlNotFound, noRegularization } = requestState.error?.data ?? {};
    const result = {
        message: '',
    };

    if (status === 400) {
        if (wrongPallet) {
            result.message = "La palette en cours n'est pas pour le bon client";
        } else {
            result.message = 'Une erreur est survenue pendant la suppression (400)';
        }
    } else if (status === 404) {
        if (controlNotFound) {
            result.message = "Un des colis à supprimer n'a pas été controllé";
        } else if (palletNotFound) {
            result.message = 'Palette non trouvée';
        } else if (noRegularization) {
            result.message = "Un des colis à supprimer n'est pas à régulariser";
        } else {
            result.message = 'Une erreur est survenue pendant la suppression (404)';
        }
    } else {
        result.message = `Une erreur est survenue pendant la suppression (${status})`;
    }

    return result;
};

export const translateTransferType = (type?: TransferType) => {
    switch (type) {
        case TransferType.package:
            return 'Colis';
        case TransferType.pallet:
            return 'Palette complète';
        default:
            return type ?? '—';
    }
};

export const formatZoneType = (zoneType?: PlaceZoneType) => {
    if ([PlaceZoneType.mass, PlaceZoneType.accumulation].find((type) => type === zoneType)) {
        return `${translatePlaceZoneType(PlaceZoneType.mass)} / ${translatePlaceZoneType(PlaceZoneType.accumulation)}`
            .substring(0, 12)
            .concat('.');
    }
    return translatePlaceZoneType(zoneType);
};

export const translateRepairStatus = (status?: RepairStatus) => {
    switch (status) {
        case RepairStatus.completed:
            return 'Terminé';
        case RepairStatus.inProgress:
            return 'En cours';
        case RepairStatus.toBeProcessed:
            return 'À traiter';

        default:
            return status ?? '-';
    }
};

export const translatePlatformReceptionStatus = (status?: PlatformReceptionStatus) => {
    switch (status) {
        case PlatformReceptionStatus.toBeProcessed:
            return 'À traiter';
        case PlatformReceptionStatus.inProgress:
            return 'En cours';
        case PlatformReceptionStatus.partial:
            return 'Partiel';
        case PlatformReceptionStatus.completed:
            return 'Terminé';

        default:
            return status ?? '—';
    }
};

export const translateStickerType = (type?: StickerType) => {
    switch (type) {
        case StickerType.clientStickers:
            return 'Étiquette client';
        case StickerType.customerAddressing:
            return 'Adressage client';
        case StickerType.trackingSheet:
            return 'Fiche suiveuse';

        default:
            return type ?? '—';
    }
};

export const translateDayOfWeek = (day?: DayOfWeek, short?: boolean) => {
    switch (day) {
        case DayOfWeek.monday:
            return short ? 'Lun' : 'Lundi';
        case DayOfWeek.tuesday:
            return short ? 'Mar' : 'Mardi';
        case DayOfWeek.wednesday:
            return short ? 'Mer' : 'Mercredi';
        case DayOfWeek.thursday:
            return short ? 'Jeu' : 'Jeudi';
        case DayOfWeek.friday:
            return short ? 'Ven' : 'Vendredi';
        case DayOfWeek.saturday:
            return short ? 'Sam' : 'Samedi';
        case DayOfWeek.sunday:
            return short ? 'Dim' : 'Dimanche';

        default:
            return day ?? '—';
    }
};

export const getPlatformReceptionCheckPalletError = (requestState: PlatformReceptionsState['checkPallet']) => {
    const status = requestState.error?.status ?? 0;
    const { alreadyCompleted, cannotReceived } = requestState.error?.data ?? {};
    const result = {
        message: '',
    };
    if (status === 400) {
        if (alreadyCompleted) {
            result.message = 'La réception pour ce lot est déjà terminée';
        } else if (cannotReceived) {
            result.message = 'Ce lot a déjà été réceptionné ou déclaré en avarie';
        } else {
            result.message = 'Une erreur est survenue pendant la vérification du lot (400)';
        }
    } else {
        result.message = `Une erreur est survenue pendant la vérification du lot (${status})`;
    }

    return result;
};

export const getPlatformReceptionSendPalletsError = (
    requestState: PlatformReceptionsState['sendPallets'],
    singleRef?: boolean
) => {
    const status = requestState.error?.status ?? 0;
    const { alreadyCompleted, cannotReceived, anomalyCodeNotFound } = requestState.error?.data ?? {};
    const result = {
        message: '',
    };

    if (status === 400) {
        if (alreadyCompleted) {
            result.message = singleRef
                ? 'La réception de ce lot est déjà terminée'
                : 'La réception pour un des lots est déjà terminée';
        } else if (cannotReceived) {
            result.message = 'Un des lots ne fait pas partie de cet OC';
        } else {
            result.message = `Une erreur est survenue pendant la réception ${singleRef ? 'du lot' : 'des lots'} (400)`;
        }
    } else if (status === 404) {
        if (anomalyCodeNotFound) {
            result.message = 'Code anomalie reçu invalide';
        } else {
            result.message = `Une erreur est survenue pendant la réception ${singleRef ? 'du lot' : 'des lots'} (404)`;
        }
    } else {
        result.message = `Une erreur est survenue pendant la réception ${
            singleRef ? 'du lot' : 'des lots'
        } (${status})`;
    }

    return result;
};

export const getPlatformLoadingCheckPalletError = (
    requestState: PlatformLoadingsState['checkPlatformLoadingsPallet'],
    singleRef?: boolean
) => {
    const status = requestState.error?.status ?? 0;
    const { alreadyLoaded, cannotBeControlled, palletNotFound } = requestState.error?.data ?? {};
    const result = {
        message: '',
    };

    if (status === 400) {
        if (alreadyLoaded) {
            result.message = singleRef ? 'La palette a été déja chargée' : 'Une palette a été déja chargée';
        } else if (cannotBeControlled) {
            result.message = 'Un des lots ne fait pas partie de cet OC';
        } else {
            result.message = `Une erreur est survenue pendant le chargement ${singleRef ? 'du lot' : 'des lots'} (400)`;
        }
    } else if (status === 404) {
        if (palletNotFound) {
            result.message = `Palette non reconnue`;
        } else {
            result.message = `Une erreur est survenue pendant le chargement ${singleRef ? 'du lot' : 'des lots'} (404)`;
        }
    } else {
        result.message = `Une erreur est survenue pendant le chargement ${
            singleRef ? 'du lot' : 'des lots'
        } (${status})`;
    }

    return result;
};

export const getCustomerOrderCloseError = (
    requestState: CustomerOrdersState['close'] | CustomerOrdersState['closeDetails']
) => {
    const status = requestState.error?.status ?? 0;
    const { resuppliesInProgress, customerOrderBlocked } = requestState.error?.data ?? {};
    const result = {
        message: '',
    };
    if (status === 400 && resuppliesInProgress) {
        result.message = 'Vous devez finaliser les ordres de réappro automatique en cours pour ce client';
    } else if (status === 400 && customerOrderBlocked) {
        result.message = `La clôture de cette préparation client à été bloquée par un administrateur`;
    } else {
        result.message = `Une erreur est survenue pendant la clôture de la préparation client (${status})`;
    }

    return result;
};

export const getPlatformReturnsCheckPalletError = (requestState: PlatformReturnsState['checkPallet']) => {
    const status = requestState.error?.status ?? 0;
    const { cannotBeReturn, platformLoadingToday, palletNotFound } = requestState.error?.data ?? {};
    const result = {
        message: '',
    };

    if (status === 400) {
        if (cannotBeReturn) {
            result.message = 'La palette ne peut pas être retourné';
        } else if (platformLoadingToday) {
            result.message = 'La palette ne peut pas être retourné';
        } else {
            result.message = `Une erreur est survenue pendant le retour du lot (400)`;
        }
    } else if (status === 404) {
        if (palletNotFound) {
            result.message = `Palette non trouvé`;
        } else {
            result.message = `Une erreur est survenue pendant le retour du lot (404)`;
        }
    } else {
        result.message = `Une erreur est survenue pendant le retour du lot
        } (${status})`;
    }

    return result;
};

export const getPlatformReturnsSendPalletsError = (
    requestState: PlatformReturnsState['sendPallets'],
    singleRef?: boolean
) => {
    const status = requestState.error?.status ?? 0;
    const { platformLoadingToday, cannotBeReturn, anomalyCodeNotFound, palletNotFound } =
        requestState.error?.data ?? {};
    const result = {
        message: '',
    };

    if (status === 400) {
        if (platformLoadingToday) {
            result.message = 'Vous ne pouvez pas retourner une palette chargé le jour même';
        } else if (cannotBeReturn) {
            result.message = 'La palette ne peut pas être retourné';
        } else {
            result.message = `Une erreur est survenue pendant le retour ${singleRef ? 'du lot' : 'des lots'} (400)`;
        }
    } else if (status === 404) {
        if (anomalyCodeNotFound) {
            result.message = `Code d'anomalie non trouvé`;
        } else if (palletNotFound) {
            result.message = `Palette non trouvé`;
        } else {
            result.message = `Une erreur est survenue pendant le retour ${singleRef ? 'du lot' : 'des lots'} (404)`;
        }
    } else {
        result.message = `Une erreur est survenue pendant le retour ${singleRef ? 'du lot' : 'des lots'} (${status})`;
    }

    return result;
};

export const showErrorMessage = (error?: any) => {
    if (error.resupplyOrderExist) {
        message.error('Un ordre de réapprovisionnement en cours !');
    }
    if (error.inventoryExist) {
        message.error('Un inventaire en cours !');
    }
    if (error.stockControlExist) {
        message.error('Un contrôle de stock en cours !');
    }
};
