import React, { FC, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../../hooks';
import { Pallet, PlaceZoneType } from '../../../store/api/apiTypes';

import { Input, InputProps, message } from 'antd';

import { detailsByScan, getPalletDetailsByScanState } from '../../../store/actions/pallets';

const shortcutScope = 'ScanPalletModall';
interface ScanPalletModallProps extends CustomModalProps {
    zoneType?: PlaceZoneType;
    visible: boolean;
    onSuccess: ({ pallet }: { pallet?: Pallet }) => void;
    selectedPalletId?: Pallet['id'];
}

const ScanPalletModal: FC<ScanPalletModallProps> = ({ visible, zoneType, onCancel, onSuccess, selectedPalletId }) => {
    const [reference, setReference] = useState<string>();
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    const fetchPalletDetails = useActions(detailsByScan.trigger);

    const palletScanState = useSelector(getPalletDetailsByScanState);

    const handlePlaceChange: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };

    const handleSubmit = () => {
        fetchPalletDetails({ reference });
    };

    const previous = usePrevious({
        palletScanState,
    });

    useEffect(() => {
        if (visible && previous?.palletScanState.loading && !palletScanState.loading) {
            if (palletScanState.error) {
                message.error('Emplacement non reconnue');
            } else {
                if (zoneType !== palletScanState.data?.currentPlace?.zoneType) {
                    message.error(`Emlacement non reconnue en ${zoneType ?? ''}`);
                } else if (palletScanState.data?.id !== selectedPalletId) {
                    message.error('La palette sélectionnée est different de palette flashée');
                } else {
                    onSuccess({ pallet: palletScanState.data });
                }
            }
        }
    }, [
        onSuccess,
        palletScanState.data,
        palletScanState.error,
        palletScanState.loading,
        previous?.palletScanState.loading,
        selectedPalletId,
        visible,
        zoneType,
    ]);

    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                fetchPalletDetails({ barcode: barCode.data });
            }
        },
        {
            deps: [],
            disable: !visible,
        }
    );

    return (
        <CustomModal
            visible={visible}
            title={`rangement`}
            width={368}
            keyboard={false}
            altTitle
            transitionName=""
            maskTransitionName=""
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={handleSubmit}
                            loading={palletScanState.loading}
                        >
                            Valider le rangement
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClose}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
        >
            <ArrowNavItem scope={shortcutScope}>
                <Input placeholder={`Flasher la palette`} value={reference} onChange={handlePlaceChange} />
            </ArrowNavItem>
        </CustomModal>
    );
};

export default ScanPalletModal;
