import React, { forwardRef, useEffect, ForwardRefRenderFunction } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { useSelector } from 'react-redux';

import { PlaceLane, PlaceSector } from '../store/api/apiTypes';
import { getPlaceLanesListState, list as listPlaceLanesAction } from '../store/actions/placeLanes';

import { useActions } from '../hooks';
import ArrowNavItem from './ArrowNavItem';

interface PlaceLaneSelectProps extends SelectProps<PlaceLane['id'] | PlaceLane['lane']> {
    multi?: boolean;
    size?: SizeType;
    placeholder?: string;
    onChange?: (value: any) => void;
    withArrowNav?: boolean;
    arrowNavScope?: string;
    placeSectorId?: PlaceSector['id'];
    valueProp?: 'id' | 'lane';
}

const PlaceLaneSelect: ForwardRefRenderFunction<typeof Select, PlaceLaneSelectProps> = (
    { placeSectorId, placeholder, withArrowNav, arrowNavScope, valueProp, ...selectProps },
    ref: any
) => {
    const listPlaceLanes = useActions(listPlaceLanesAction.trigger);
    const placeLanesListState = useSelector(getPlaceLanesListState);
    const onSearch: SelectProps<PlaceLane['id']>['onSearch'] = (newValue) => {
        listPlaceLanes(
            {
                search: newValue || undefined,
                ...(placeSectorId ? { placeSectorId } : {}),
            },
            { throttling: 300 }
        );
    };

    useEffect(() => {
        listPlaceLanes(placeSectorId ? { placeSectorId } : undefined);
    }, [listPlaceLanes, placeSectorId]);

    const select = (
        <Select<PlaceLane['id'] | PlaceLane['lane']>
            placeholder={placeholder ?? 'Filtrer par allée'}
            filterOption={false}
            style={{ width: '100%' }}
            onSearch={onSearch}
            loading={placeLanesListState.loading}
            notFoundContent={placeLanesListState.loading ? <Spin /> : 'Aucun résultat'}
            allowClear
            showArrow
            ref={ref}
            showSearch
            {...selectProps}
        >
            {placeLanesListState.data?.items?.map((option) => (
                <Select.Option value={option[valueProp ?? 'id']} key={option.id}>
                    {option.lane}
                </Select.Option>
            ))}
        </Select>
    );

    return withArrowNav ? <ArrowNavItem scope={arrowNavScope}>{select}</ArrowNavItem> : select;
};

export default forwardRef(PlaceLaneSelect);
