import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Form, Input, InputNumber, message, Radio, Select, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { FormProps, useForm } from 'antd/lib/form/Form';

import {
    getPlaceSectorsListState,
    list as placeSectorsList,
    PlaceSectorsState,
} from '../../store/actions/placeSectors';
import { getPlaceLanesListState } from '../../store/actions/placeLanes';
import { PlaceSector, PlaceStore, PlaceStoreType, PlaceZoneType } from '../../store/api/apiTypes';
import { create, getPlaceCreateState } from '../../store/actions/places';

import { getRoute, RoutePathName } from '../../routes';
import { useActions, useIsMobile, usePrevious } from '../../hooks';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Seo from '../../components/Seo';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import FixedFooter from '../../components/FixedFooter';
import ButtonGrey from '../../components/ButtonGrey';
import YesNoSwitchFormItem from '../../components/YesNoSwitchFormItem';
import PlaceStoreSelect from '../../components/PlaceStoreSelect';
import { requiredRule } from '../../helpers';
import PlaceLaneSelect from '../../components/PlaceLaneSelect';
import PlaceAlveoleSelect from '../../components/PlaceAlveoleSelect';

const getPlaceSectorBySector = (sector: string | undefined, placeSectorsState: PlaceSectorsState['list']) =>
    placeSectorsState.data?.items.find((s) => s.sector === sector);

const PlaceManagementCreate: FC = () => {
    const [form] = useForm();
    const isMobile = useIsMobile();
    const history = useHistory();
    const [createPlace, listPlaceSectors] = useActions([create.trigger, placeSectorsList.trigger]);
    const placeCreateState = useSelector(getPlaceCreateState);
    const placeSectorsState = useSelector(getPlaceSectorsListState);
    const placeLanesState = useSelector(getPlaceLanesListState);
    const previous = usePrevious({
        placeCreateState,
    });
    const initialValues = {
        zoneType: PlaceZoneType.mass,
    };
    const onFormValuesChange: FormProps['onValuesChange'] = (
        changedValues: Record<string, string | undefined>,
        values
    ) => {
        const changedKey = Object.keys(changedValues)[0];
        const changedValue = changedValues[changedKey];

        if (changedKey === 'zoneType') {
            form.resetFields([
                'sector',
                'lane',
                'alveole',
                'locker',
                'level',
                'space',
                'isStorageAuthorization',
                'isWithdrawalAuthorization',
                'isResupplyAuthorization',
                'isMixingParcel',
            ]);
            if ([PlaceZoneType.mass, PlaceZoneType.picking].includes(values.zoneType)) {
                listPlaceSectors({
                    zoneType: values.zoneType,
                    placeStoreId: values.placeStore?.id,
                });
            }
        }

        if (['placeStore', 'sector', 'lane'].includes(changedKey)) {
            switch (changedKey) {
                case 'placeStore':
                    form.resetFields(['sector', 'lane', 'alveole']);
                    listPlaceSectors({
                        zoneType: values.zoneType,
                        placeStoreId: (changedValue as PlaceStore | undefined)?.id,
                    });
                    break;

                case 'sector':
                    form.resetFields(['lane', 'alveole']);
                    break;

                case 'lane':
                    form.resetFields(['alveole']);
                    break;
            }
        }
    };
    const onSubmit: FormProps['onFinish'] = ({ placeStore, ...values }) => {
        createPlace({
            ...values,
            storeId: placeStore?.id,
        });
    };

    useEffect(() => {
        if (previous?.placeCreateState.loading && !placeCreateState.loading) {
            if (placeCreateState.error) {
                if (placeCreateState.error?.status === 404 && placeCreateState.error?.data?.parcelNotFound) {
                    message.error('Référence inconnue');
                } else if (placeCreateState.error?.status === 409) {
                    message.error('Cet emplacement existe déjà');
                } else {
                    message.error("Une erreur est survenue pendant la création de l'emplacement");
                }
            } else {
                message.success('Emplacement créé avec succès');
                history.push(getRoute(RoutePathName.placeManagementMenu));
            }
        }
    }, [previous?.placeCreateState.loading, placeCreateState.loading, placeCreateState.error, history]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Créer un emplacement" />
            <Header title="Créer un emplacement" backRoute={getRoute(RoutePathName.placeManagementMenu)} />
            <PageHeader className="page-header-small">
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onSubmit}
                    onValuesChange={onFormValuesChange}
                    initialValues={initialValues}
                    requiredMark={false}
                >
                    <Form.Item label="Site" name="placeStore" rules={[requiredRule]}>
                        <PlaceStoreSelect placeStoreType={PlaceStoreType.site} allowClear={false} withArrowNav />
                    </Form.Item>
                    <Form.Item label="Type d'emplacement" name="zoneType">
                        <ArrowNavItem>
                            <Radio.Group buttonStyle="solid" className="stretch small-text">
                                <Radio.Button value={PlaceZoneType.mass}>Masse</Radio.Button>
                                <Radio.Button value={PlaceZoneType.picking}>Picking</Radio.Button>
                                <Radio.Button value={PlaceZoneType.preparation}>Préparation</Radio.Button>
                            </Radio.Group>
                        </ArrowNavItem>
                    </Form.Item>
                    <Form.Item shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                            const isMass = getFieldValue(['zoneType']) === PlaceZoneType.mass;
                            const isPicking = getFieldValue(['zoneType']) === PlaceZoneType.picking;

                            return (
                                <>
                                    {(isMass || isPicking) && (
                                        <Form.Item label="Secteur" name="sector" rules={[requiredRule]}>
                                            <ArrowNavItem>
                                                <Select<PlaceSector['sector']>
                                                    placeholder="Sélectionner un secteur"
                                                    filterOption={false}
                                                    disabled={!getFieldValue('placeStore')}
                                                    loading={placeSectorsState.loading}
                                                    allowClear
                                                    showArrow
                                                >
                                                    {placeSectorsState.data?.items?.map((option) => (
                                                        <Select.Option value={option.sector} key={option.id}>
                                                            {option.sector}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </ArrowNavItem>
                                        </Form.Item>
                                    )}
                                    {((isMass &&
                                        !getPlaceSectorBySector(getFieldValue(['sector']), placeSectorsState)
                                            ?.storageByAccumulation) ||
                                        isPicking) && (
                                        <>
                                            <Form.Item label="Allée" name="lane" rules={[requiredRule]}>
                                                <PlaceLaneSelect
                                                    placeholder="Sélectionner une allée"
                                                    placeSectorId={
                                                        placeSectorsState.data?.items?.find(
                                                            (placeSector) =>
                                                                placeSector.sector === getFieldValue('sector')
                                                        )?.id
                                                    }
                                                    disabled={!getFieldValue('sector')}
                                                    valueProp="lane"
                                                    withArrowNav
                                                />
                                            </Form.Item>
                                            <Form.Item label="Alvéole" name="alveole" rules={[requiredRule]}>
                                                <PlaceAlveoleSelect
                                                    placeholder="Sélectionner une alvéole"
                                                    placeLaneId={
                                                        placeLanesState.data?.items?.find(
                                                            (placeLane) => placeLane.lane === getFieldValue('lane')
                                                        )?.id
                                                    }
                                                    disabled={!getFieldValue('lane')}
                                                    valueProp="alveole"
                                                    withArrowNav
                                                />
                                            </Form.Item>
                                            <Form.Item label="Niveau" name="level" rules={[requiredRule]}>
                                                <ArrowNavItem>
                                                    <Input placeholder="Saisir un niveau" />
                                                </ArrowNavItem>
                                            </Form.Item>
                                        </>
                                    )}

                                    {isMass &&
                                        (getPlaceSectorBySector(getFieldValue(['sector']), placeSectorsState)
                                            ?.storageByAccumulation ? (
                                            <Form.Item
                                                label="Casier"
                                                name="locker"
                                                rules={[requiredRule]}
                                                normalize={(value?: string) => `${value ?? ''}`.toUpperCase()}
                                            >
                                                <ArrowNavItem>
                                                    <Input placeholder="Saisir un casier" />
                                                </ArrowNavItem>
                                            </Form.Item>
                                        ) : (
                                            <Form.Item label="Nombre d'unité(s)" name="space" rules={[requiredRule]}>
                                                <ArrowNavItem>
                                                    <InputNumber
                                                        placeholder="Saisir un nombre"
                                                        min={0}
                                                        style={{ width: '100%' }}
                                                    />
                                                </ArrowNavItem>
                                            </Form.Item>
                                        ))}
                                    {isPicking && (
                                        <>
                                            <Form.Item
                                                label="Référence"
                                                name="parcelReference"
                                                normalize={(value?: string) => `${value ?? ''}`.toUpperCase()}
                                            >
                                                <ArrowNavItem>
                                                    <Input placeholder="Saisir une référence" />
                                                </ArrowNavItem>
                                            </Form.Item>
                                            <Form.Item label="Rang" name="rank">
                                                <ArrowNavItem>
                                                    <InputNumber placeholder="Saisir un rang" min={0} />
                                                </ArrowNavItem>
                                            </Form.Item>
                                        </>
                                    )}
                                    {getFieldValue(['zoneType']) === PlaceZoneType.preparation && (
                                        <Form.Item
                                            label="Nom de la zone de préparation"
                                            name="locker"
                                            style={{ marginBottom: 0 }}
                                            rules={[requiredRule]}
                                            normalize={(value?: string) => `${value ?? ''}`.toUpperCase()}
                                        >
                                            <ArrowNavItem>
                                                <Input placeholder="Saisir un nom" />
                                            </ArrowNavItem>
                                        </Form.Item>
                                    )}
                                    {(isMass || isPicking) && (
                                        <>
                                            <Divider />
                                            <p>
                                                <Typography.Text className="text-primary" strong>
                                                    Autorisations
                                                </Typography.Text>
                                            </p>
                                            <YesNoSwitchFormItem label="Rangement" name="isStorageAuthorization" />
                                            <YesNoSwitchFormItem label="Prélèvement" name="isWithdrawalAuthorization" />
                                        </>
                                    )}
                                    {isPicking && (
                                        <YesNoSwitchFormItem
                                            label="Réapprovisionnement"
                                            name="isResupplyAuthorization"
                                        />
                                    )}
                                    {isMass && (
                                        <YesNoSwitchFormItem label="Mélange des articles" name="isMixingParcel" />
                                    )}
                                </>
                            );
                        }}
                    </Form.Item>
                    <button type="submit" style={{ display: 'none' }} />
                </Form>
            </PageHeader>
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        shortcut="enter"
                        onClick={form.submit}
                        as={ButtonGrey}
                        size={isMobile ? 'middle' : 'large'}
                        loading={placeCreateState.loading}
                        block
                    >
                        Créer l&rsquo;emplacement
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </FixedFooter.Wrapper>
    );
};

export default PlaceManagementCreate;
