import { Button, Divider, message } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    Pallet,
    PalletPlace,
    Place,
    PlaceZoneType,
    RepairStatus,
    TransferType,
    WMSMode,
} from '../../../store/api/apiTypes';

import { getPalletPlaceListState, list as listPalletPlacesAction } from '../../../store/actions/palletPlaces';

import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonGrey from '../../../components/ButtonGrey';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import FixedFooter from '../../../components/FixedFooter';
import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';

import Seo from '../../../components/Seo';

import { useActions, useIsMobile, usePrevious } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';

import QuantityCard from '../../../components/QuantityCard';
import PrintModal from '../../../components/PrintModal';

import { formatZoneType } from '../../../helpers/i18n';
import ConfirmModal from '../../../components/ConfirmModal';
import { isZebra } from '../../../helpers/enterprise-browser';

import {
    detailsRepair,
    getRepairsDetails,
    getRepairStorePackagesState,
    getRepairTransferPackagesState,
    transferRepairPackages,
} from '../../../store/actions/repairs';
import ScanPlaceModal from './ScanPlaceModal';
import ScanPalletModal from './ScanPalletModal';
import { TransferRepairPackagesPayload } from '../../../store/api/repairs';
import SuccessModal from '../../../components/SuccessModal';
import QuantitySelectModal from '../../../components/QuantitySelectModal';
import { formatPlace } from '../../../helpers';
import { translateStoreRepairPackagesError } from '../../../helpers/errors';

const PalletPlacesList: FC = () => {
    const isMobile = useIsMobile();
    const location = useLocation<{ zoneType: PlaceZoneType }>();
    const history = useHistory();
    const params = useParams<{ repairId: string }>();

    const [selectedPalletPlace, setSelectedPalletPlace] = useState<PalletPlace | undefined>();
    const [isQuantityModalVisible, setIsQuantityModalVisible] = useState<boolean>(false);
    const [isScanModalVisible, setIsScanModalVisible] = useState<boolean>(false);
    const [isPrintModalVisible, setIsPrintModalVisible] = useState<boolean>(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);

    const [transferQuantity, setTransferQuantity] = useState<number>();

    const [selectedPlace, setSelectedPlace] = useState<Place>();
    const [selectedPallet, setSelectedPallet] = useState<Pallet>();

    const [listPalletPlaces, fetchRepair, transferPackages] = useActions([
        listPalletPlacesAction.trigger,
        detailsRepair.trigger,
        transferRepairPackages.trigger,
    ]);

    const palletPlacesListState = useSelector(getPalletPlaceListState);
    const transferPackagesState = useSelector(getRepairTransferPackagesState);
    const storePackagesState = useSelector(getRepairStorePackagesState);
    const repairDetailsState = useSelector(getRepairsDetails);

    const previous = usePrevious({
        palletPlacesListState,
        transferPackagesState,
        repairDetailsState,
        storePackagesState,
    });
    const onListRowEnter = (record: PalletPlace) => {
        setSelectedPalletPlace(record);
    };

    const columns: Array<ListColumn<PalletPlace>> = [
        {
            key: 'reference',
            title: 'Réference de palette',
            flex: '1 1 39%',
            render: (record) => record.pallet?.reference ?? '-',
        },
        {
            title: 'Emplacement',
            flex: '1 1 35%',
            render: ({ place }) => formatPlace(place),
        },
        {
            title: 'Qté en stock',
            flex: '1 1 26%',
            render: (record) => record.pallet?.quantity ?? '-',
        },
    ];

    useEffect(() => {
        const parcelId = repairDetailsState.data?.parcel.id;
        if (parcelId) {
            listPalletPlaces({
                parcelId,
                sort: 'quantity',
                sortOrder: 'asc',
                pageSize: 10,
                zoneType: location.state?.zoneType,
            });
        }
    }, [listPalletPlaces, location.state?.zoneType, repairDetailsState.data?.parcel.id]);

    useEffect(() => {
        if (
            !!(previous?.storePackagesState.loading && !storePackagesState.loading) ||
            (previous?.transferPackagesState.loading && !transferPackagesState.loading)
        ) {
            fetchRepair({ id: params.repairId });
        }
    }, [
        fetchRepair,
        params.repairId,
        previous?.storePackagesState.loading,
        previous?.transferPackagesState.loading,
        storePackagesState.loading,
        transferPackagesState.loading,
    ]);

    const availablePackages =
        (repairDetailsState.data?.quantity ?? 0) - (repairDetailsState.data?.transferedPackages ?? 0);

    useEffect(() => {
        if (previous?.transferPackagesState.loading && !transferPackagesState.loading) {
            if (transferPackagesState.error) {
                message.error(translateStoreRepairPackagesError(transferPackagesState.error));
            } else {
                setIsSuccessModalVisible(true);
            }
        }
    }, [previous?.transferPackagesState.loading, transferPackagesState.error, transferPackagesState.loading]);

    useEffect(() => {
        if (previous?.repairDetailsState.loading && !repairDetailsState.loading) {
            if (repairDetailsState.data?.status === RepairStatus.completed) {
                history.push(getRoute(RoutePathName.movementsRepairs));
            } else {
                setTransferQuantity(availablePackages);
            }
        }
    }, [
        availablePackages,
        history,
        previous?.repairDetailsState.loading,
        repairDetailsState.data?.status,
        repairDetailsState.loading,
    ]);
    // return to home page when there is no OT in store (reload page, direct access to page by url...)
    useEffect(() => {
        if (!repairDetailsState.loading && !repairDetailsState.data) {
            history.push(getRoute(RoutePathName.movementsRepairs), {});
        }
    }, [history, repairDetailsState.data, repairDetailsState.loading]);

    useEffect(() => {
        if (previous?.palletPlacesListState.loading && !palletPlacesListState.loading) {
            if (!palletPlacesListState.data?.totalCount && location.state.zoneType === PlaceZoneType.picking) {
                setIsConfirmModalVisible(true);
            }
        }
    }, [
        location.state.zoneType,
        palletPlacesListState.data?.totalCount,
        palletPlacesListState.loading,
        previous?.palletPlacesListState.loading,
    ]);

    const back = () => {
        const { repairId } = params;
        history.push(getRoute(RoutePathName.movementsRepairDetails, { repairId }), {});
    };

    const handleQuntitySelect = (qt: number) => {
        setIsQuantityModalVisible(false);

        if (location.state.zoneType === PlaceZoneType.picking) {
            const payload: TransferRepairPackagesPayload = {
                id: Number(params.repairId),
                placeId: selectedPlace?.id,
                transferedPackages: qt,
                finishPlaceZoneType: location.state.zoneType,
                status: RepairStatus.inProgress,
            };

            transferPackages(payload);
        } else {
            setTransferQuantity(qt);
            setIsScanModalVisible(true);
        }
    };

    const handlePrintSucess = () => {
        setIsPrintModalVisible(false);
        const payload: TransferRepairPackagesPayload = {
            id: Number(params.repairId),
            palletId: selectedPallet?.id,
            transferedPackages: transferQuantity ?? 0,
            finishPlaceZoneType: PlaceZoneType.mass,
            status: RepairStatus.inProgress,
        };
        transferPackages(payload);
    };

    const handlePackages = () => {
        if (location.state.zoneType === PlaceZoneType.picking) {
            setIsScanModalVisible(true);
        } else {
            setIsQuantityModalVisible(true);
        }
    };

    const onLoadMore = () => {
        listPalletPlaces(
            {
                parcelId: repairDetailsState.data?.parcel?.id,
                sort: 'quantity',
                sortOrder: 'asc',
                page: (palletPlacesListState.data?.page ?? 0) + 1,
                pageSize: 10,
                zoneType: location.state.zoneType,
            },
            {
                loadMore: true,
            }
        );
    };

    const findPlace = () => {
        history.push(getRoute(RoutePathName.movementRepairPackagesPlaces, { repairId: params.repairId }), {
            ...location.state,
            transferType: TransferType.package,
        });
    };

    const handleScanSuccess = ({ pallet, place }: { place?: Place; pallet?: Pallet }) => {
        if (location.state.zoneType === PlaceZoneType.picking) {
            if (pallet) {
                setSelectedPallet(pallet);
            } else {
                setSelectedPlace(place);
            }
            setIsScanModalVisible(false);
            setIsQuantityModalVisible(true);
        } else {
            setSelectedPallet(pallet);
            setIsScanModalVisible(false);
            setIsPrintModalVisible(true);
        }
    };

    const handlePrintReturn = () => {
        setIsPrintModalVisible(false);
        setIsScanModalVisible(true);
    };
    return isZebra ? (
        <FixedFooter.Wrapper>
            <Seo title={`arrivée ${formatZoneType(location.state.zoneType)}`} />
            <Header
                title={`arrivée ${formatZoneType(location.state.zoneType)}`}
                backRoute={{
                    pathname: getRoute(RoutePathName.movementsRepairDetails, { repairId: params.repairId }),
                }}
            />

            <QuantityCard
                style={{ marginTop: '25px' }}
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                value={`${repairDetailsState.data?.transferedPackages?.toString()?.concat(' / ') ?? ''}${
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    repairDetailsState.data?.quantity ?? ''
                }`}
                label={repairDetailsState.data?.transferedPackages ? 'colis rangés' : 'colis à ranger'}
            />

            <Divider />
            <List<PalletPlace>
                columns={columns}
                data={palletPlacesListState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowFocus={setSelectedPalletPlace}
                onRowClick={setSelectedPalletPlace}
                onRowEnterPress={onListRowEnter}
                isLoading={palletPlacesListState.loading && palletPlacesListState.data?.page === undefined}
                title="Palettes avec cette réference"
                hideWhenEmpty
                style={{ marginBottom: 60 }}
            />

            {palletPlacesListState.data && palletPlacesListState.data.page < palletPlacesListState.data.pageCount - 1 && (
                <Button
                    onClick={onLoadMore}
                    loading={palletPlacesListState.loading}
                    size="small"
                    style={{ marginBottom: 60, fontSize: 14 }}
                    block
                >
                    Plus de résultats
                </Button>
            )}

            <ConfirmModal
                visible={isConfirmModalVisible}
                message={`Il n'y a aucune palette picking disponible`}
                onCancel={back}
            />

            <SuccessModal
                visible={isSuccessModalVisible}
                autoClose={true}
                onCancel={setIsSuccessModalVisible.bind(null, false)}
                message={
                    repairDetailsState.data?.status === RepairStatus.completed
                        ? 'Colis rangés avec succès'
                        : 'Réparation traitée avec succès'
                }
                noFooter={true}
            />

            {isQuantityModalVisible && (
                <QuantitySelectModal
                    visible={isQuantityModalVisible}
                    onReturn={setIsQuantityModalVisible.bind(null, false)}
                    altTitle={true}
                    onSubmit={handleQuntitySelect}
                    title="quantité colis"
                    text={`sur ${availablePackages} à ranger`}
                    max={availablePackages}
                />
            )}
            {isPrintModalVisible && (
                <PrintModal
                    visible={isPrintModalVisible}
                    onCancel={handlePrintReturn}
                    onSuccess={handlePrintSucess}
                    palletId={selectedPalletPlace?.pallet?.id}
                    cancelText="Retour"
                    mode={WMSMode.movement}
                />
            )}
            {isScanModalVisible ? (
                location.state.zoneType === PlaceZoneType.picking ? (
                    <ScanPlaceModal
                        onCancel={setIsScanModalVisible.bind(null, false)}
                        visible={isScanModalVisible}
                        zoneType={PlaceZoneType.picking}
                        onSuccess={handleScanSuccess}
                        selectPlaceId={selectedPalletPlace?.place.id}
                    />
                ) : (
                    <ScanPalletModal
                        onCancel={setIsScanModalVisible.bind(null, false)}
                        visible={isScanModalVisible}
                        onSuccess={handleScanSuccess}
                        zoneType={PlaceZoneType.mass}
                        selectedPalletId={selectedPalletPlace?.pallet?.id}
                    />
                )
            ) : null}
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        onClick={handlePackages}
                        shortcut="enter"
                        size={isMobile ? 'middle' : 'large'}
                        as={ButtonGrey}
                        block
                        disabled={!selectedPalletPlace?.id}
                    >
                        Ranger les colis
                    </ButtonWithShortcut>
                </ArrowNavItem>
                {location.state.zoneType !== PlaceZoneType.picking && (
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            onClick={findPlace}
                            shortcut="f1"
                            type="primary"
                            ghost
                            size={isMobile ? 'middle' : 'large'}
                            block
                        >
                            Autre emplacement
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                )}
            </FixedFooter>
        </FixedFooter.Wrapper>
    ) : null;
};

export default PalletPlacesList;
