import React, { FC, ReactNode, useState } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout, Dropdown, Avatar, Menu, Typography } from 'antd';

import '../assets/styles/Header.less';

import { getUser } from '../store/actions/auth';
import { getApiInfosState } from '../store/actions/api';
import { RoleName } from '../store/api/apiTypes';

import { hasRole } from '../helpers/security';
import { classNames } from '../helpers';
import constants from '../config/constants';
import DesktopBreakpoint from './DesktopBreakpoint';
import MobileBreakpoint from './MobileBreakpoint';
import { IconArrowLeft, IconHome, IconLogout } from './icons';
import ButtonWithShortcut from './ButtonWithShortcut';
import ButtonLink from './ButtonLink';
import LogoutModal from './LogoutModal';
import { getRoute, RoutePathName } from '../routes';

interface HeaderProps {
    title?: ReactNode;
    backRoute?: LinkProps['to'];
    onClickBack?: () => void;
    disableShortcut?: boolean;
    backButtonLoading?: boolean;
    enableHomeButton?: boolean;
}

const Header: FC<HeaderProps> = ({
    backRoute,
    disableShortcut,
    onClickBack,
    backButtonLoading,
    title,
    enableHomeButton,
}) => {
    const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
    const apiInfosState = useSelector(getApiInfosState);
    const isPreprod = apiInfosState.data?.env === 'preprod';
    const user = useSelector(getUser);
    const menu = (
        <Menu>
            <Menu.Item onClick={setIsLogoutModalVisible.bind(null, true)}>Se déconnecter</Menu.Item>
        </Menu>
    );

    if (!title) {
        if (hasRole(user, [RoleName.pfUser])) {
            title = (
                <>
                    Parisot plateforme
                    <br />
                    <small>{user?.organization?.slug}</small>
                </>
            );
        } else if (hasRole(user, [RoleName.pfAdmin])) {
            title = (
                <>
                    Parisot plateforme
                    <br />
                    <small>Administration</small>
                </>
            );
        }
    }

    return (
        <Layout.Header
            id="main-header"
            className={classNames(!backRoute && !onClickBack && 'two-cols', isPreprod && 'is-preprod')}
        >
            <div>
                {!onClickBack && backRoute && (
                    <ButtonWithShortcut
                        shortcut="esc"
                        shortcutOptions={{ enableOnTags: ['INPUT'], enabled: !disableShortcut }}
                        id="back-button"
                        type="link"
                        as={ButtonLink}
                        to={backRoute}
                        hideShortcut
                    >
                        <IconArrowLeft /> <span id="back-button-text">Retour (ESC)</span>
                    </ButtonWithShortcut>
                )}
                {onClickBack && (
                    <ButtonWithShortcut
                        shortcut="esc"
                        shortcutOptions={{ enableOnTags: ['INPUT'], enabled: !disableShortcut }}
                        id="back-button"
                        type="link"
                        onClick={onClickBack}
                        loading={backButtonLoading}
                        hideShortcut
                    >
                        <IconArrowLeft /> <span id="back-button-text">Retour (ESC)</span>
                    </ButtonWithShortcut>
                )}
                {!backRoute && !onClickBack && (
                    <Typography.Title level={1} className={classNames(!title && 'default-title')}>
                        {title ?? constants.SEO.DEFAULT_TITLE}
                    </Typography.Title>
                )}
            </div>
            {(!!backRoute || onClickBack) && (
                <div>
                    <Typography.Title level={1} className={classNames(!title && 'default-title')}>
                        {title ?? constants.SEO.DEFAULT_TITLE}
                    </Typography.Title>
                </div>
            )}
            <div>
                <DesktopBreakpoint>
                    {!!enableHomeButton && (
                        <ButtonLink id="home-button" type="link" to={getRoute(RoutePathName.home)}>
                            <IconHome />
                        </ButtonLink>
                    )}
                    {user?.firstName && (
                        <Dropdown overlayClassName="user-card-dropdown" trigger={['click']} overlay={menu}>
                            <Link to="" className="user-card">
                                <Avatar size={32}>{user?.firstName.substr(0, 1)}</Avatar>
                                {user?.firstName}
                            </Link>
                        </Dropdown>
                    )}
                </DesktopBreakpoint>
                <MobileBreakpoint>
                    {!!enableHomeButton && (
                        <ButtonLink id="home-button" type="link" to={getRoute(RoutePathName.home)}>
                            <IconHome />
                        </ButtonLink>
                    )}
                    {!backRoute && !onClickBack && (
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'], enabled: !disableShortcut }}
                            type="link"
                            onClick={setIsLogoutModalVisible.bind(null, true)}
                            hideShortcut
                        >
                            Quitter (ESC) <IconLogout />
                        </ButtonWithShortcut>
                    )}
                </MobileBreakpoint>
            </div>
            <LogoutModal visible={isLogoutModalVisible} onCancel={setIsLogoutModalVisible.bind(null, false)} />
        </Layout.Header>
    );
};

export default Header;
