import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, message, Select, SelectProps } from 'antd';

import {
    getInventoryOperatorListState,
    operatorList,
    update,
    getInventoryUpdateState,
} from '../../store/actions/inventories';
import { User } from '../../store/api/apiTypes';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import { getOperatorName } from '../../helpers';

const shortcutScope = 'InventoryOperatorModal';

interface InventoryOperatorModalProps extends CustomModalProps {
    onSuccess: () => void;
}

const InventoryOperatorModal: FC<InventoryOperatorModalProps> = ({ visible, onCancel, onSuccess }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const { inventoryId } = useParams<{ inventoryId: string }>();
    const [selectedUser, setSelectedUser] = useState<User['id']>();
    const [fetchOperatorList, updateInventory] = useActions([operatorList.trigger, update.trigger]);
    const operatorListState = useSelector(getInventoryOperatorListState);
    const inventoryUpdateState = useSelector(getInventoryUpdateState);
    const previous = usePrevious({ inventoryUpdateState });
    const onValidate = () => {
        updateInventory({
            id: inventoryId,
            operatorId: selectedUser,
        });
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const onOperatorSearch: SelectProps<string>['onSearch'] = (value) => {
        fetchOperatorList(
            {
                // inventoryId,
                ...(value ? { search: value } : {}),
            },
            { throttling: 300 }
        );
    };

    useEffect(() => {
        if (visible) {
            fetchOperatorList();
        }
    }, [visible, fetchOperatorList]);

    useEffect(() => {
        if (previous?.inventoryUpdateState.loading && !inventoryUpdateState.loading) {
            if (inventoryUpdateState.error) {
                message.error("Une erreur est survenue lors de la mise à jour de l'inventaire");
            } else {
                onSuccess();
                onClose();
            }
        }
    }, [
        onClose,
        onSuccess,
        inventoryId,
        inventoryUpdateState.error,
        inventoryUpdateState.loading,
        previous?.inventoryUpdateState.loading,
    ]);

    return (
        <CustomModal
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            shortcutScope={shortcutScope}
                            onClick={onValidate}
                            loading={inventoryUpdateState.loading}
                            disabled={!selectedUser}
                        >
                            Valider
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClose}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            visible={visible}
            onCancel={onCancel}
            title="Modifier l'attribution"
            width={368}
            keyboard={false}
            altTitle
        >
            <Form.Item label="Cariste" style={{ margin: 0 }} labelCol={{ span: 24 }}>
                <ArrowNavItem scope={shortcutScope}>
                    <Select
                        style={{ width: '100%' }}
                        value={selectedUser}
                        onChange={setSelectedUser}
                        filterOption={false}
                        onSearch={onOperatorSearch}
                        placeholder="Choisir un cariste"
                        loading={operatorListState.loading}
                        showSearch
                    >
                        {operatorListState.data?.items.map((u) => (
                            <Select.Option value={u.id} key={u.id}>
                                {getOperatorName(u)}
                            </Select.Option>
                        ))}
                    </Select>
                </ArrowNavItem>
            </Form.Item>
        </CustomModal>
    );
};

export default InventoryOperatorModal;
