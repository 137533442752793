import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import Seo from '../../../components/Seo';
import { useActions, useIsMobile, usePrevious } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import { formatNumber, translateMovementBrokenStatus } from '../../../helpers/i18n';
import FixedFooter from '../../../components/FixedFooter';
import ArrowNavItem from '../../../components/ArrowNavItem';

import { MovementBroken, MovementBrokenStatus } from '../../../store/api/apiTypes';

import {
    getMovementBrokenCreateState,
    getMovementBrokensListState,
    list,
} from '../../../store/actions/movementBrokens';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CreateBrokenModal from './CreateBrokenModal';
import { Button } from 'antd';
import { isZebra } from '../../../helpers/enterprise-browser';
import { PaginationQuery } from '../../../store/api';
import { getOperatorName } from '../../../helpers';

const BrokenList: FC = () => {
    const isMobile = useIsMobile();
    const history = useHistory();

    const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);
    const fetchMovementBrokens = useActions(list.trigger);
    const movementBrokensListState = useSelector(getMovementBrokensListState);
    const movementBrokenCreateState = useSelector(getMovementBrokenCreateState);

    const previous = usePrevious({ movementBrokenCreateState, movementBrokensListState });

    const onClickItem = (record: MovementBroken) => {
        history.push(getRoute(RoutePathName.movementsBrokenDetails, { brokenId: record.id }));
    };

    const columnWith = isZebra ? '50%' : `${100 / 3}%`;
    const columns = (
        [
            {
                title: 'Réference colis',
                flex: `1 1 ${columnWith}`,
                render: (record: MovementBroken) => record.parcel?.reference,
            },
            {
                title: 'Statut',
                flex: `1 1 ${columnWith}`,
                render: (record: MovementBroken) => translateMovementBrokenStatus(record.status),
            },
            !isZebra
                ? {
                      title: 'Cariste',
                      flex: `1 1 ${columnWith}`,
                      render: (record: MovementBroken) => getOperatorName(record.operator),
                  }
                : null,
        ] as Array<ListColumn<MovementBroken>>
    ).filter(Boolean);

    const listBrokens = useCallback(
        (params?: PaginationQuery, loadMore?: boolean) => {
            return fetchMovementBrokens(
                {
                    order: 'asc',
                    status: [MovementBrokenStatus.inProgress, MovementBrokenStatus.toBeProcessed],
                    ...params,
                },
                { loadMore }
            );
        },
        [fetchMovementBrokens]
    );

    useEffect(() => {
        listBrokens();
    }, [listBrokens]);

    useEffect(() => {
        if (
            previous?.movementBrokenCreateState.loading &&
            !movementBrokenCreateState.loading &&
            !movementBrokenCreateState.error
        ) {
            listBrokens();
        }
    }, [
        listBrokens,
        movementBrokenCreateState.error,
        movementBrokenCreateState.loading,
        previous?.movementBrokenCreateState.loading,
    ]);
    const onLoadMore = () => {
        listBrokens(
            {
                page: (movementBrokensListState.data?.page ?? 0) + 1,
            },
            true
        );
    };
    return (
        <FixedFooter.Wrapper>
            <Seo title="Casse" />
            <Header title="Casse" backRoute={getRoute(RoutePathName.movementsVariousOutputsMenu)} enableHomeButton />
            <List<MovementBroken>
                columns={columns}
                data={movementBrokensListState.data?.items}
                rowKey={(record) => String(record.id)}
                onRowEnterPress={onClickItem}
                onRowClick={onClickItem}
                isLoading={movementBrokensListState.loading}
                title={
                    movementBrokensListState.data
                        ? `${formatNumber(movementBrokensListState.data?.totalCount)} Casse${
                              movementBrokensListState.data?.totalCount > 1 ? 's' : ''
                          } à traiter`
                        : 'Casses à traiter'
                }
                style={isMobile ? { marginTop: 24 } : undefined}
                columnWidthGrow={false}
            />
            {movementBrokensListState.data &&
                movementBrokensListState.data.page < movementBrokensListState.data.pageCount - 1 && (
                    <Button
                        onClick={onLoadMore}
                        loading={movementBrokensListState.loading}
                        size="small"
                        style={{ margin: '1rem 0', fontSize: 14 }}
                        block
                    >
                        Plus de résultats
                    </Button>
                )}
            {isCreateModalVisible && (
                <CreateBrokenModal
                    visible={isCreateModalVisible}
                    onCancel={setIsCreateModalVisible.bind(null, false)}
                />
            )}
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut shortcut="f1" onClick={setIsCreateModalVisible.bind(null, true)} block>
                        Déclarer casse
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </FixedFooter.Wrapper>
    );
};

export default BrokenList;
