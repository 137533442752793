import React, { FC, useEffect, useState } from 'react';
import Seo from '../../../components/Seo';
import Header from '../../../components/Header';
import QuantityCard from '../../../components/QuantityCard';
import PageHeader from '../../../components/PageHeader';
import { useSelector } from 'react-redux';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import ButtonGrey from '../../../components/ButtonGrey';
import FixedFooter from '../../../components/FixedFooter';
import List, { ListColumn } from '../../../components/list/List';
import ReturnCancelModal from './ReturnCancelModal';
import { useModal } from '@ebay/nice-modal-react';
import ReturnScanModal from './ReturnScanModal';
import { useHistory, useLocation } from 'react-router-dom';
import { PalletPlace, Parcel, ReturnZoneType } from '../../../store/api/apiTypes';
import { formatNumber } from '../../../helpers/i18n';
import { getPalletPlaceListState, list as listPalletsAction } from '../../../store/actions/palletPlaces';
import { useActions, useIsMobile, usePrevious } from '../../../hooks';
import { formatPlace } from '../../../helpers';
import { Button } from 'antd';
import WarningModal from '../../../components/WarningModal';
import { getRoute, RoutePathName } from '../../../routes';

const ReturnPalletList: FC = () => {
    const isMobile = useIsMobile();
    const cancelCustomerReturnModal = useModal(ReturnCancelModal);
    const customerReturnScanModal = useModal(ReturnScanModal);
    const palletListState = useSelector(getPalletPlaceListState);
    const previous = usePrevious({ palletListState });
    const listPallets = useActions(listPalletsAction.trigger);
    const [isNoDataModalVisible, setIsNoDataModalVisible] = useState<boolean>(false);
    const [selectedPalletPlace, setSelectedPalletPlace] = useState<PalletPlace>();
    const location =
        useLocation<{ returnZoneType: ReturnZoneType; quantity: number; parcelId: Parcel['id'] | undefined }>();

    const { returnZoneType, quantity, parcelId } = location.state;
    const history = useHistory();
    const onClickBack = () => {
        cancelCustomerReturnModal.show();
    };

    const columns: Array<ListColumn<PalletPlace>> = [
        {
            key: 'reference',
            title: 'Réference de palette',
            flex: '1 1 60%',
            render: (record) => record.pallet?.reference ?? '-',
        },
        {
            title: 'Emplacement',
            flex: '1 1 60%',
            render: ({ place }) => formatPlace(place),
        },
        {
            title: 'Qté en stock',
            flex: '1 1 40%',
            render: (palletPlace) => palletPlace.pallet?.quantity ?? 0,
        },
    ];

    useEffect(() => {
        if (parcelId) {
            listPallets({
                parcelId,
                sort: 'quantity',
                sortOrder: 'asc',
                pageSize: 10,
                zoneType: returnZoneType,
            });
        }
    }, [listPallets, parcelId, returnZoneType]);

    const findPlace = () => {
        history.push(getRoute(RoutePathName.movementCustomerReturnPackagePlaces), {
            ...location.state,
            step: 'confirmation',
        });
    };

    const onLoadMore = () => {
        listPallets(
            {
                parcelId: parcelId,
                sort: 'quantity',
                sortOrder: 'asc',
                page: (palletListState.data?.page ?? 0) + 1,
                pageSize: 10,
                zoneType: returnZoneType,
            },
            {
                loadMore: true,
            }
        );
    };

    useEffect(() => {
        if (previous?.palletListState.loading && !palletListState.loading) {
            if (!palletListState.data?.totalCount && returnZoneType === ReturnZoneType.picking) {
                setIsNoDataModalVisible(true);
            }
        }
    }, [returnZoneType, palletListState.data?.totalCount, palletListState.loading, previous?.palletListState.loading]);

    const handleSubmit = async () =>
        parcelId && selectedPalletPlace
            ? await customerReturnScanModal.show({
                  parcelId,
                  quantity,
                  returnZoneType,
                  selectedPalletPlace,
                  setSelectedPalletPlace,
              })
            : null;

    const handlePalletPlaceSelect = (palletPlace?: PalletPlace) => {
        setSelectedPalletPlace(palletPlace);
    };

    return (
        <FixedFooter.Wrapper>
            <Seo title={`rangement - ${returnZoneType === ReturnZoneType.picking ? 'picking' : 'masse/accu.'}`} />
            <Header
                title={`rangement - ${returnZoneType === ReturnZoneType.picking ? 'picking' : 'masse/accu.'}`}
                onClickBack={onClickBack}
            />
            <PageHeader>
                <QuantityCard loading={false} value={formatNumber(quantity)} label={'colis à ranger'} />
            </PageHeader>
            <List<PalletPlace>
                columns={columns}
                data={palletListState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowClick={handlePalletPlaceSelect}
                onRowEnterPress={handlePalletPlaceSelect}
                onRowFocus={handlePalletPlaceSelect}
                onRowBlur={handlePalletPlaceSelect}
                isLoading={palletListState.loading && palletListState.data?.page === undefined}
                title="Palettes avec cette réference"
                hideWhenEmpty
                style={{ marginBottom: 60 }}
            />
            {palletListState.data && palletListState.data.page < palletListState.data.pageCount - 1 && (
                <Button
                    onClick={onLoadMore}
                    loading={palletListState.loading}
                    size="small"
                    style={{ marginBottom: 60, fontSize: 14 }}
                    block
                >
                    Plus de résultats
                </Button>
            )}
            {isNoDataModalVisible && (
                <WarningModal visible={isNoDataModalVisible} message={`Il n'y a aucune palette picking disponible`} />
            )}

            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        onClick={handleSubmit}
                        shortcut="enter"
                        size={isMobile ? 'middle' : 'large'}
                        as={ButtonGrey}
                        block
                        disabled={!selectedPalletPlace}
                    >
                        Ranger les colis
                    </ButtonWithShortcut>
                </ArrowNavItem>
                {returnZoneType !== ReturnZoneType.picking && (
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            onClick={findPlace}
                            shortcut="f1"
                            type="primary"
                            ghost
                            size={isMobile ? 'middle' : 'large'}
                            block
                        >
                            Autre emplacement
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                )}
            </FixedFooter>
        </FixedFooter.Wrapper>
    );
};

export default ReturnPalletList;
