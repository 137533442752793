import React, { FC } from 'react';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import { useArrowNavScope, useShortcutScope } from '../../../hooks';
import { TransferType } from '../../../store/api/apiTypes';
import { translateTransferType } from '../../../helpers/i18n';

const shortcutScope = 'TransferTypeModal';
interface TransferTypeModalProps extends CustomModalProps {
    onSelect: (type: TransferType | undefined) => void;
    onReturn: () => void;
}

const TransferTypeModal: FC<TransferTypeModalProps> = ({ visible, onReturn, onSelect }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    return (
        <CustomModal
            visible={visible}
            title="type de transfert"
            width={368}
            keyboard={false}
            altTitle
            transitionName=""
            maskTransitionName=""
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="1"
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onSelect.bind(null, TransferType.pallet)}
                        >
                            {translateTransferType(TransferType.pallet)}
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="2"
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onSelect.bind(null, TransferType.package)}
                        >
                            {translateTransferType(TransferType.package)}
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onReturn}
                            ghost
                        >
                            Retour
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
        />
    );
};

export default TransferTypeModal;
