import { Form, FormProps, Input, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    control,
    removePackage,
    removePackages,
    getControlOrderControlState,
    getControlOrderRemovePackageState,
    getControlOrderRemovePackagesState,
    PackageRef,
} from '../../store/actions/preparationControl';
import { details as customerOrderDetails, getCustomerOrderStateById } from '../../store/actions/customerOrders';

import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { formatNumber, getRemovePackageGetError, getRemovePackagesGetError } from '../../helpers/i18n';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import QuantityCard from '../../components/QuantityCard';
import ButtonRed from '../../components/ButtonRed';
import { isControlPackageAlreadyStoredLocally } from '../../helpers';
import { getPreparationQuantityToRemoveByCustomerOrderIdAndParcelIdState } from '../../store/actions/preparations';
import { errorSound } from '../../helpers/sound';

const shortcutScope = 'ControlPalletRemovePackageModal';

interface ControlPalletRemovePackageModalProps extends CustomModalProps {
    onFinish: () => void;
}

const ControlPalletRemovePackageModal: FC<ControlPalletRemovePackageModalProps> = ({ visible, onCancel, onFinish }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const { customerOrderId: customerOrderIdParam, palletId } =
        useParams<{ customerOrderId: string; palletId: string }>();
    const customerOrderId = parseInt(customerOrderIdParam ?? '-1', 10);
    const [form] = Form.useForm();
    const [isManual, setIsManual] = useState(false);
    const [refToStore, setRefToStore] = useState<PackageRef>(); // temp data when waiting for API response
    const [barcodeToStore, setBarcodeToStore] = useState<string>(); // temp data when waiting for API response
    const [
        fetchCustomerOrder,
        fetchRemovePackage,
        sendRemovePackages,
        setControlRemovePackageState,
        resetControlRemovePackageState,
    ] = useActions([
        customerOrderDetails.trigger,
        removePackage.trigger,
        removePackages.trigger,
        control.actions.setRemovePackageData,
        control.actions.resetRemovePackage,
    ]);
    const removePackageState = useSelector(getControlOrderRemovePackageState);
    const removePackagesState = useSelector(getControlOrderRemovePackagesState);
    const customerOrderState = useSelector(getCustomerOrderStateById(customerOrderId));
    const { packagesRefs, packagesBarcodes, localParcelToRemoveCountByParcelId } =
        useSelector(getControlOrderControlState).removePackage;
    const quantityToRemoveForCurrentParcel =
        useSelector(
            getPreparationQuantityToRemoveByCustomerOrderIdAndParcelIdState(
                customerOrderId,
                removePackageState.data?.id
            )
        ) - (localParcelToRemoveCountByParcelId[removePackageState.data?.id ?? -1] ?? 0);
    const totalQuantityToRemove =
        (customerOrderState.data?.controlledQuantity ?? 0) - packagesRefs.length - packagesBarcodes.length;
    const previous = usePrevious({ visible, removePackageState, removePackagesState });
    const onClose = useCallback(() => {
        onCancel?.({} as React.MouseEvent<HTMLElement, MouseEvent>);
    }, [onCancel]);
    const onSubmitManual: FormProps['onFinish'] = (values) => {
        if (totalQuantityToRemove === 0) {
            message.error("Il n'y a plus de colis à supprimer");
        } else if (isControlPackageAlreadyStoredLocally(packagesRefs, values)) {
            message.error('Ce colis est déjà dans la liste à supprimer');
        } else {
            setRefToStore({
                reference: values.reference,
                ...(values.cont ? { cont: values.cont } : {}),
            });
            fetchRemovePackage({
                customerOrderId,
                reference: values.reference,
                ...(values.cont ? { cont: values.cont } : {}),
            });
        }
    };
    const onSubmit = () => {
        sendRemovePackages({
            customerOrderId,
            barcodes: packagesBarcodes.length ? packagesBarcodes : undefined,
            references: packagesRefs.length ? packagesRefs : undefined,
            palletId,
        });
    };
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (totalQuantityToRemove === 0) {
                    message.error("Il n'y a plus de colis à supprimer");
                    errorSound();
                } else if (packagesBarcodes.includes(barCode.data)) {
                    message.error('Ce colis est déjà dans la liste à supprimer');
                    errorSound();
                } else {
                    setBarcodeToStore(barCode.data);
                    fetchRemovePackage({
                        customerOrderId,
                        barcode: barCode.data,
                    });
                }
            }
        },
        {
            deps: [fetchRemovePackage, packagesBarcodes],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    useEffect(() => {
        if (previous?.removePackageState.loading && !removePackageState.loading) {
            if (removePackageState.error) {
                const requestError = getRemovePackageGetError(removePackageState);

                if (requestError.message) {
                    message.error(requestError.message);
                }
                errorSound();
            } else if (removePackageState.data) {
                if (totalQuantityToRemove === 0) {
                    message.error(
                        'Vous avez ajouté la totalité des colis à supprimer, veuillez valider pour effectuer la suppression, ou annuler',
                        5
                    );
                    errorSound();
                } else if (quantityToRemoveForCurrentParcel === 0) {
                    message.error('Vous avez ajouté la totalité des colis à supprimer de cette référence', 5);
                    errorSound();
                } else {
                    setControlRemovePackageState({
                        packagesBarcodes: barcodeToStore ? [...packagesBarcodes, barcodeToStore] : packagesBarcodes,
                        packagesRefs: refToStore ? [...packagesRefs, refToStore] : packagesRefs,
                        localParcelToRemoveCountByParcelId: {
                            ...localParcelToRemoveCountByParcelId,
                            [removePackageState.data.id]:
                                (localParcelToRemoveCountByParcelId[removePackageState.data.id] ?? 0) + 1,
                        },
                    });
                }

                form.resetFields();
                setIsManual(false);
            }
            setBarcodeToStore(undefined);
            setRefToStore(undefined);
        }
    }, [
        previous?.removePackageState.loading,
        removePackageState,
        barcodeToStore,
        refToStore,
        form,
        packagesBarcodes,
        packagesRefs,
        setControlRemovePackageState,
        totalQuantityToRemove,
        quantityToRemoveForCurrentParcel,
        localParcelToRemoveCountByParcelId,
    ]);

    useEffect(() => {
        if (previous?.removePackagesState.loading && !removePackagesState.loading) {
            if (removePackagesState.error) {
                const requestError = getRemovePackagesGetError(removePackagesState);

                if (requestError.message) {
                    message.error(requestError.message);
                }
            } else {
                if (removePackagesState.data) {
                    onFinish();
                } else {
                    message.error('Une erreur est survenue pendant la suppression de colis');
                }
            }
        }
    }, [
        previous?.removePackagesState.loading,
        removePackagesState.loading,
        removePackagesState.error,
        removePackagesState.data,
        onFinish,
        removePackagesState,
    ]);

    useEffect(() => {
        if (!visible) {
            setBarcodeToStore(undefined);
            setRefToStore(undefined);
            resetControlRemovePackageState([]);
        } else {
            fetchCustomerOrder({ customerOrderId });
        }
    }, [
        visible,
        setBarcodeToStore,
        setRefToStore,
        resetControlRemovePackageState,
        fetchCustomerOrder,
        customerOrderId,
    ]);

    return (
        <CustomModal
            footer={
                isManual ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={() => form.submit()}
                                loading={removePackageState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={setIsManual.bind(null, false)}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                as={ButtonRed}
                                shortcutScope={shortcutScope}
                                onClick={onSubmit}
                                loading={removePackagesState.loading}
                            >
                                Supprimer maintenant
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="f1"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={setIsManual.bind(null, true)}
                                ghost
                            >
                                Saisir manuellement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={
                isManual
                    ? 'Saisir manuellement'
                    : 'Veuillez scanner individuellement les colis à supprimer de la palette :'
            }
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            altTitle={isManual}
        >
            {isManual ? (
                <Form form={form} onFinish={onSubmitManual}>
                    <Form.Item name="reference" rules={[{ required: true, message: 'Veuillez saisir une référence' }]}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir référence colis" autoFocus />
                        </ArrowNavItem>
                    </Form.Item>
                    <Form.Item
                        name="cont"
                        rules={[{ required: true, message: 'Veuillez saisir un CONT' }]}
                        style={{ marginBottom: 0 }}
                    >
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir CONT" />
                        </ArrowNavItem>
                    </Form.Item>
                    <button type="submit" style={{ display: 'none' }} />
                </Form>
            ) : (
                <QuantityCard
                    value={formatNumber(packagesRefs.length + packagesBarcodes.length)}
                    label="colis à supprimer"
                    outline
                />
            )}
        </CustomModal>
    );
};

export default ControlPalletRemovePackageModal;
