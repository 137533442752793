import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import React, { useEffect, useState } from 'react';
import CustomModal from '../../components/CustomModal';
import SuccessMessage from '../../components/SuccessMessage';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import { useHistory, useParams } from 'react-router-dom';
import { Divider, message } from 'antd';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ButtonRed from '../../components/ButtonRed';
import { del as deleteInventoryAction, getInventoryDelState } from '../../store/actions/inventories';
import { useSelector } from 'react-redux';
import { getRoute, RoutePathName } from '../../routes';

const shortcutScope = 'InventoryRemoveConfirmModal';

const InventoryRemoveConfirmModal = NiceModal.create(() => {
    const modal = useModal();
    const { inventoryId } = useParams<{ inventoryId: string }>();
    const { hide, visible } = modal;
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [success, setSuccess] = useState<boolean>(false);
    const deleteInventory = useActions(deleteInventoryAction.trigger);
    const deleteInventoryState = useSelector(getInventoryDelState);
    const previous = usePrevious({ deleteInventoryState });
    const history = useHistory();
    const onDelete = () => {
        deleteInventory({ inventoryId: parseInt(inventoryId, 10) });
    };
    useEffect(() => {
        if (previous?.deleteInventoryState.loading && !deleteInventoryState.loading) {
            if (deleteInventoryState.error) {
                message.error(`Une erreur est survenue durant la suppression de l'inventaire`);
            } else if (deleteInventoryState.success) {
                setSuccess(true);
            }
        }
    }, [
        deleteInventoryState.error,
        deleteInventoryState.loading,
        deleteInventoryState.success,
        previous?.deleteInventoryState.loading,
    ]);

    useEffect(() => {
        let timeout: number;

        if (visible && success) {
            timeout = window.setTimeout(() => {
                hide();
                history.push(getRoute(RoutePathName.inventoryCurrent));
            }, 1000);
        }

        return () => window.clearTimeout(timeout);
    }, [hide, success, visible]);

    return (
        <CustomModal
            {...antdModal(modal)}
            width={!success ? 368 : 232}
            title={
                !success && (
                    <>
                        Êtes-vous sûr de vouloir <br />
                        supprimer l&lsquo;inventaire ?
                        <Divider />
                    </>
                )
            }
            footer={
                !success && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                as={ButtonRed}
                                onClick={() => onDelete()}
                            >
                                Supprimer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={hide}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
        >
            {success && (
                <SuccessMessage
                    message={
                        <>
                            L&lsquo;inventaire à été
                            <br /> supprimé avec succès
                        </>
                    }
                />
            )}
        </CustomModal>
    );
});

export default InventoryRemoveConfirmModal;
