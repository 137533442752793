import { Form, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getPalletDetailsStateById, getPalletUpdateState, update } from '../../store/actions/pallets';
import { Pallet } from '../../store/api/apiTypes';

import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import QuantityInput from '../../components/QuantityInput';

const shortcutScope = 'SearchPalletEditQuantityModal';

interface SearchPalletEditQuantityModalProps extends CustomModalProps {
    onSuccess: () => void;
    palletId: Pallet['id'];
}

const SearchPalletEditQuantityModal: FC<SearchPalletEditQuantityModalProps> = ({
    visible,
    onCancel,
    palletId,
    onSuccess,
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const palletState = useSelector(getPalletDetailsStateById(palletId));
    const [quantity, setQuantity] = useState(palletState.data?.quantity);
    const [updatePallet] = useActions([update.trigger]);
    const palletUpdateState = useSelector(getPalletUpdateState);
    const previous = usePrevious({ palletUpdateState });
    const onClose = useCallback(() => {
        onCancel?.({} as React.MouseEvent<HTMLElement, MouseEvent>);
    }, [onCancel]);
    const onSubmit = () => {
        updatePallet({
            id: palletId,
            quantity,
        });
    };

    // update pallet response, close modal & fetch pallet to refresh
    useEffect(() => {
        if (visible && previous?.palletUpdateState.loading && !palletUpdateState.loading) {
            if (palletUpdateState.error) {
                message.error('Une erreur est survenue pendant la mise à jour de la palette');
            } else {
                onSuccess();
                onClose();
            }
        }
    }, [
        palletUpdateState.error,
        palletUpdateState.loading,
        previous?.palletUpdateState.loading,
        onClose,
        visible,
        onSuccess,
    ]);

    useEffect(() => {
        if (!visible) {
            setQuantity(palletState.data?.quantity);
        }
    }, [visible, setQuantity, palletState.data?.quantity]);

    return (
        <CustomModal
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            type="primary"
                            shortcut="enter"
                            shortcutScope={shortcutScope}
                            onClick={onSubmit}
                            loading={palletUpdateState.loading}
                        >
                            Valider
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            type="primary"
                            shortcut="esc"
                            shortcutScope={shortcutScope}
                            onClick={onClose}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            visible={visible}
            onCancel={onCancel}
            title="Modifier quantité"
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            altTitle
        >
            <Form.Item label="Nouvelle quantité">
                <QuantityInput onChange={setQuantity} value={quantity} min={0} hasArrowNav={visible} />
            </Form.Item>
        </CustomModal>
    );
};

export default SearchPalletEditQuantityModal;
