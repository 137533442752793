import { combineReducers } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { Package, Pallet } from '../api/apiTypes';
import { ListResponse } from '../api/';

// Controlers
import {
    PalletDetailsByScanPayload,
    PalletListByParcelPayload,
    listByParcel as listByParcelApiCall,
    PalletDetailsPayload,
    details as detailsApiCall,
    PalletDetailsByBarcodePayload,
    detailsByBarcode as detailsByBarcodeApiCall,
    PalletDetailsByReferencePayload,
    detailsByReference as detailsByReferenceApiCall,
    PalletCreatePayload,
    create as createApiCall,
    PalletUpdatePayload,
    update as updateApiCall,
    print as printApiCall,
    PalletPrintPayload,
    place as placeApiCall,
    PalletPlacePayload,
    store as storeApiCall,
    PalletStorePayload,
    del as delApiCall,
    PalletDelPayload,
    packageList as packageListApiCall,
    PalletPackageListPayload,
    detailsPalletByScan,
    changeResupplyId as changeResupplyIdApiCall,
    PalletChangeResupplyIdPayload,
} from '../api/pallets';
import { DataAction, EzeeAction } from '../helpers/EzeeAction';

type PalletPlace = Pallet & Required<Pick<Pallet, 'reservedPlace'>>;

// States
export interface PalletsState {
    listByParcel: RequestState<ListResponse<Pallet>>;
    details: RequestState<{
        [id: number]: Pallet;
    }>;
    detailsByBarcode: RequestState<{
        [barcode: string]: Pallet;
    }>;
    detailsByReference: RequestState<{
        [reference: string]: Pallet;
    }>;
    create: RequestState<Pallet>;
    update: RequestState<Pallet>;
    print: RequestState;
    place: RequestState<PalletPlace>;
    store: RequestState;
    del: RequestState;
    packageList: RequestState<ListResponse<Package>>;
    detailsByScan: RequestState<Pallet>;
    defaultSize: {
        size: Pallet['size'] | undefined;
    };
    changeResupplyId: RequestState<Pallet>;
}

const initialState: PalletsState = {
    listByParcel: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
    detailsByBarcode: { ...requestInitialState, data: {} },
    detailsByReference: { ...requestInitialState, data: {} },
    create: { ...requestInitialState },
    update: { ...requestInitialState },
    print: { ...requestInitialState },
    place: { ...requestInitialState },
    store: { ...requestInitialState },
    del: { ...requestInitialState },
    packageList: { ...requestInitialState },
    detailsByScan: { ...requestInitialState },
    defaultSize: {
        size: undefined,
    },
    changeResupplyId: { ...requestInitialState },
};

export const listByParcel = new EzeeAsyncAction<
    PalletsState['listByParcel'],
    PalletListByParcelPayload,
    ListResponse<Pallet>
>('pallets/listByParcel', initialState.listByParcel, {
    trigger: (state, _, meta) => ({
        ...state,
        loading: true,
        data: meta?.loadMore ? state.data : undefined,
    }),
    success: (state, payload, meta) => ({
        data: {
            ...payload,
            items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.listByParcel,
    }),
});

export const details = new EzeeAsyncAction<PalletsState['details'], PalletDetailsPayload, Pallet>(
    'pallets/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

export const detailsByBarcode = new EzeeAsyncAction<
    PalletsState['detailsByBarcode'],
    PalletDetailsByBarcodePayload,
    Pallet & { barcodeKey: string }
>('pallets/detailsByBarcode', initialState.detailsByBarcode, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...state.data,
            [payload.barcodeKey]: payload, // store pallet by scanned barcode instead of response barcode
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.detailsByBarcode,
    }),
});

export const detailsByReference = new EzeeAsyncAction<
    PalletsState['detailsByReference'],
    PalletDetailsByReferencePayload,
    Pallet
>('pallets/detailsByReference', initialState.detailsByReference, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...state.data,
            [payload.reference]: payload,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.detailsByReference,
    }),
});

export const create = new EzeeAsyncAction<PalletsState['create'], PalletCreatePayload, Pallet>(
    'pallets/create',
    initialState.create,
    requestReducer<PalletsState['create'], Pallet>(initialState.create)
);

export const update = new EzeeAsyncAction<PalletsState['update'], PalletUpdatePayload, Pallet>(
    'pallets/update',
    initialState.update,
    requestReducer<PalletsState['update'], Pallet>(initialState.update)
);

export const print = new EzeeAsyncAction<PalletsState['print'], PalletPrintPayload>(
    'pallets/print',
    initialState.print,
    requestReducer<PalletsState['print']>(initialState.print)
);

export const place = new EzeeAsyncAction<PalletsState['place'], PalletPlacePayload, PalletPlace>(
    'pallets/place',
    initialState.place,
    requestReducer<PalletsState['place'], Pallet>(initialState.place)
);

export const store = new EzeeAsyncAction<PalletsState['store'], PalletStorePayload>(
    'pallets/store',
    initialState.store,
    requestReducer<PalletsState['store']>(initialState.store)
);

export const del = new EzeeAsyncAction<PalletsState['del'], PalletDelPayload>(
    'pallets/del',
    initialState.del,
    requestReducer<PalletsState['del']>(initialState.del)
);

export const packageList = new EzeeAsyncAction<
    PalletsState['packageList'],
    PalletPackageListPayload,
    ListResponse<Package>
>(
    'pallets/packageList',
    initialState.packageList,
    requestReducer<PalletsState['packageList'], ListResponse<Package>>(initialState.packageList)
);

export const detailsByScan = new EzeeAsyncAction<PalletsState['detailsByScan'], PalletDetailsByScanPayload, Pallet>(
    'pallets/detailsByScan',
    initialState.detailsByScan,
    requestReducer<PalletsState['detailsByScan'], Pallet>(initialState.detailsByScan)
);
export const defaultSize = new EzeeAction<PalletsState['defaultSize']>(
    'pallets/defaultSize',
    initialState.defaultSize,
    {
        setDefaultSize: (state, payload) => {
            return {
                size: payload,
            };
        },
    }
);
export const changeResupplyId = new EzeeAsyncAction<
    PalletsState['changeResupplyId'],
    PalletChangeResupplyIdPayload,
    Pallet
>(
    'pallets/changeResupplyId',
    initialState.changeResupplyId,
    requestReducer<PalletsState['changeResupplyId'], Pallet>(initialState.changeResupplyId)
);
// Reducer
export const palletsReducer = combineReducers<PalletsState>({
    listByParcel: listByParcel.reducer,
    details: details.reducer,
    detailsByBarcode: detailsByBarcode.reducer,
    detailsByReference: detailsByReference.reducer,
    create: create.reducer,
    update: update.reducer,
    print: print.reducer,
    place: place.reducer,
    store: store.reducer,
    del: del.reducer,
    packageList: packageList.reducer,
    detailsByScan: detailsByScan.reducer,
    defaultSize: defaultSize.reducer,
    changeResupplyId: changeResupplyId.reducer,
});

// Saga
export function* palletsSaga() {
    yield takeLatest(listByParcel.type.trigger, simpleAsyncSaga(listByParcelApiCall, listByParcel));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(detailsByBarcode.type.trigger, detailsByBarcodeSaga);
    yield takeLatest(detailsByReference.type.trigger, simpleAsyncSaga(detailsByReferenceApiCall, detailsByReference));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(print.type.trigger, printSaga);
    yield takeLatest(place.type.trigger, simpleAsyncSaga(placeApiCall, place));
    yield takeLatest(store.type.trigger, simpleAsyncSaga(storeApiCall, store));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
    yield takeLatest(packageList.type.trigger, simpleAsyncSaga(packageListApiCall, packageList));
    yield takeLatest(detailsByScan.type.trigger, simpleAsyncSaga(detailsPalletByScan, detailsByScan));
    yield takeLatest(changeResupplyId.type.trigger, simpleAsyncSaga(changeResupplyIdApiCall, changeResupplyId));
}

function* printSaga(actionData: DataAction<PalletPrintPayload>) {
    try {
        const { palletId, palletIds, ...payload } = actionData.payload;

        yield all([
            ...(palletId ? [call(printApiCall, { palletId, ...payload })] : []),
            ...(palletIds ?? []).map((id) => call(printApiCall, { palletId: id, ...payload })),
        ]);

        return yield put(print.success());
    } catch (error) {
        return yield put(print.failure(error, actionData.meta));
    }
}

function* detailsByBarcodeSaga(actionData: DataAction<PalletDetailsByBarcodePayload>) {
    try {
        const response = yield call(detailsByBarcodeApiCall, actionData.payload);
        return yield put(
            detailsByBarcode.success(
                {
                    ...response,
                    barcodeKey: actionData.payload.barcode, // store barcode
                },
                actionData.meta
            )
        );
    } catch (error) {
        return yield put(detailsByBarcode.failure(error, actionData.meta));
    }
}

// Store helpers
export const getParcelPalletsListState = (state: MainReducerState) => state.pallets.listByParcel;
export const getPalletDetailsStateById = (id: Pallet['id']) => (state: MainReducerState) => ({
    loading: state.pallets.details.loading,
    error: state.pallets.details.error,
    data: state.pallets.details.data?.[id],
});
export const getPalletDetailsStateByBarcode = (barcode?: Pallet['barcode']) => (state: MainReducerState) => ({
    loading: state.pallets.detailsByBarcode.loading,
    error: state.pallets.detailsByBarcode.error,
    data: barcode ? state.pallets.detailsByBarcode.data?.[barcode] : undefined,
});
export const getPalletDetailsStateByReference = (reference?: Pallet['reference']) => (state: MainReducerState) => ({
    loading: state.pallets.detailsByReference.loading,
    error: state.pallets.detailsByReference.error,
    data: reference ? state.pallets.detailsByReference.data?.[reference] : undefined,
});
export const getPalletCreateState = (state: MainReducerState) => state.pallets.create;
export const getPalletUpdateState = (state: MainReducerState) => state.pallets.update;
export const getPalletPrintState = (state: MainReducerState) => state.pallets.print;
export const getPalletPlaceState = (state: MainReducerState) => state.pallets.place;
export const getPalletStoreState = (state: MainReducerState) => state.pallets.store;
export const getPalletDelState = (state: MainReducerState) => state.pallets.del;
export const getPalletPackageListState = (state: MainReducerState) => state.pallets.packageList;
export const getPalletDetailsByScanState = (state: MainReducerState) => state.pallets.detailsByScan;
export const getPalletDefaultSizeState = (state: MainReducerState) => state.pallets.defaultSize;
export const getPalletChangeResupplyIdState = (state: MainReducerState) => state.pallets.changeResupplyId;
