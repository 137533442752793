import { ListResponse } from './../api/index';
import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { MovementBroken } from '../api/apiTypes';

import * as api from './../api/movementBrokens';

// States
export interface MovementBrokensState {
    create: RequestState<MovementBroken>;
    list: RequestState<ListResponse<MovementBroken>>;
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: MovementBroken | undefined;
        };
    };
    del: RequestState;
    update: RequestState<MovementBroken>;
    createRepair: RequestState<MovementBroken>;
    createDestruction: RequestState<MovementBroken>;
    createDiscountShipping: RequestState<MovementBroken>;
}

const initialState: MovementBrokensState = {
    create: { ...requestInitialState },
    list: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
    del: { ...requestInitialState },
    update: { ...requestInitialState },
    createRepair: { ...requestInitialState },
    createDestruction: { ...requestInitialState },
    createDiscountShipping: { ...requestInitialState },
};

export const create = new EzeeAsyncAction<
    MovementBrokensState['create'],
    api.MovementBrokenCreatePayload,
    MovementBroken
>(
    'movementBrokens/create',
    initialState.create,
    requestReducer<MovementBrokensState['create'], MovementBroken>(initialState.create)
);

export const list = new EzeeAsyncAction<
    MovementBrokensState['list'],
    api.MovementBrokensListPayload,
    ListResponse<MovementBroken>
>('movementBrokens/list', initialState.list, {
    trigger: (state, _, meta) => ({
        ...state,
        loading: true,
        data: meta?.loadMore ? state.data : undefined,
    }),
    success: (state, payload, meta) => ({
        data: {
            ...payload,
            items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.list,
    }),
});

export const details = new EzeeAsyncAction<
    MovementBrokensState['details'],
    api.MovementBrokenIdPayload,
    MovementBroken
>('movementBrokens/details', initialState.details, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...state.data,
            [payload.id]: payload,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.details,
    }),
});

export const del = new EzeeAsyncAction<MovementBrokensState['del'], api.MovementBrokenIdPayload, MovementBroken>(
    'movementBrokens/del',
    initialState.del,
    requestReducer<MovementBrokensState['del'], MovementBroken>(initialState.del)
);

export const update = new EzeeAsyncAction<
    MovementBrokensState['update'],
    api.MovementBrokenUpdatePayload,
    MovementBroken
>(
    'movementBrokens/update',
    initialState.update,
    requestReducer<MovementBrokensState['update'], MovementBroken>(initialState.update)
);

export const createBrokenRepair = new EzeeAsyncAction<
    MovementBrokensState['createRepair'],
    api.CreateRepairPayload,
    MovementBroken
>(
    'movementBrokens/createRepair',
    initialState.createRepair,
    requestReducer<MovementBrokensState['createRepair'], MovementBroken>(initialState.createRepair)
);
export const createBrokenDestruction = new EzeeAsyncAction<
    MovementBrokensState['createDestruction'],
    api.CreateDestructionPayload,
    MovementBroken
>(
    'movementBrokens/createDestruction',
    initialState.createDestruction,
    requestReducer<MovementBrokensState['createDestruction'], MovementBroken>(initialState.createDestruction)
);
export const createDiscountShipping = new EzeeAsyncAction<
    MovementBrokensState['createDiscountShipping'],
    api.createDiscountShippingPayload,
    MovementBroken
>(
    'movementBrokens/createDiscountShipping',
    initialState.createDiscountShipping,
    requestReducer<MovementBrokensState['createDiscountShipping'], MovementBroken>(initialState.createDiscountShipping)
);

// Reducer
export const movementBrokensReducer = combineReducers<MovementBrokensState>({
    create: create.reducer,
    list: list.reducer,
    details: details.reducer,
    del: del.reducer,
    createRepair: createBrokenRepair.reducer,
    createDestruction: createBrokenDestruction.reducer,
    createDiscountShipping: createDiscountShipping.reducer,
    update: update.reducer,
});

// Saga
export function* movementBrokensSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(api.list, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(api.details, details));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(api.create, create));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(api.del, del));
    yield takeLatest(createBrokenRepair.type.trigger, simpleAsyncSaga(api.createRepair, createBrokenRepair));
    yield takeLatest(
        createBrokenDestruction.type.trigger,
        simpleAsyncSaga(api.createDestruction, createBrokenDestruction)
    );
    yield takeLatest(
        createDiscountShipping.type.trigger,
        simpleAsyncSaga(api.createDiscountShipping, createDiscountShipping)
    );
    yield takeLatest(update.type.trigger, simpleAsyncSaga(api.update, update));
}

// Store helpers
export const getMovementBrokenCreateState = (state: MainReducerState) => state.movementBrokens.create;
export const getMovementBrokensListState = (state: MainReducerState) => state.movementBrokens.list;
export const getMovementBrokensStateById = (id?: MovementBroken['id']) => (state: MainReducerState) => ({
    loading: state.movementBrokens.details.loading,
    error: state.movementBrokens.details.error,
    data: id ? state.movementBrokens.details.data[id] : undefined,
});
export const getMovementBrokensDelState = (state: MainReducerState) => state.movementBrokens.del;
export const getCreateBrokenRepairState = (state: MainReducerState) => state.movementBrokens.createRepair;
export const getCreateBrokenDestructionState = (state: MainReducerState) => state.movementBrokens.createDestruction;
export const getCreateBrokenDiscountShippingState = (state: MainReducerState) =>
    state.movementBrokens.createDiscountShipping;
export const getMovementBrokenUpdateState = (state: MainReducerState) => state.movementBrokens.update;
