import { SearchPaginationQuery } from '.';
import { PlatformAnomalyCode } from './apiTypes';
import api from './_client';

export type PlatformAnomalyCodeListPayload = SearchPaginationQuery;
export const list = async (payload: PlatformAnomalyCodeListPayload = {}) =>
    await api.get('/platformAnomalyCodes', { params: payload });

export type PlatformAnomalyCodeCreatePayload = Omit<PlatformAnomalyCode, 'id'>;
export const create = async (payload: PlatformAnomalyCodeCreatePayload) =>
    await api.post('/platformAnomalyCodes', payload);

export type PlatformAnomalyCodeUpdatePayload = PlatformAnomalyCode & {
    platformAnomalyCodeId: PlatformAnomalyCode['id'];
};
export const update = async ({ platformAnomalyCodeId, ...payload }: PlatformAnomalyCodeUpdatePayload) =>
    await api.put(`/platformAnomalyCodes/${platformAnomalyCodeId}`, payload);

export interface PlatformAnomalyCodeDeletePayload {
    platformAnomalyCodeId: PlatformAnomalyCode['id'];
}
export const del = async ({ platformAnomalyCodeId }: PlatformAnomalyCodeDeletePayload) =>
    await api.delete(`/platformAnomalyCodes/${platformAnomalyCodeId}`);
