import React, { FC } from 'react';

import BasicList from '../../components/BasicList';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ButtonLink from '../../components/ButtonLink';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import { getRoute, RoutePathName } from '../../routes';

const PlaceManagementMenu: FC = () => {
    return (
        <>
            <Seo title="Gestion des emplacements" />
            <Header title="Gestion des emplacements" backRoute={getRoute(RoutePathName.home)} />
            <nav className="menu">
                <BasicList>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="1"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.placeManagementSearch)}
                                block
                            >
                                Rechercher un emplacement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="2"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.placeManagementCreate)}
                                block
                            >
                                Créer un emplacement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="3"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.placeManagementSectionsMenu)}
                                block
                            >
                                Gestion des sections
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                </BasicList>
            </nav>
        </>
    );
};

export default PlaceManagementMenu;
