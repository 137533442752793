import React, { useEffect, useState } from 'react';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import CustomModal from '../../components/CustomModal';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import {
    create as createIncidentAction,
    getIncidentsCreateState,
    list as listIncidentsAction,
} from '../../store/actions/incidents';
import {
    create as createMachineAction,
    getMachinesCreateState,
    list as listMachinesAction,
} from '../../store/actions/machines';
import { Incident, Machine } from '../../store/api/apiTypes';
import { useSelector } from 'react-redux';
import { Input, InputProps, message } from 'antd';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import SuccessMessage from '../../components/SuccessMessage';
import { useRouteMatch } from 'react-router-dom';
import { getRawRoute, RoutePathName } from '../../routes';

const shortcutScope = 'AddMachineIncidentModal';

const AddMachineIncidentModal = NiceModal.create(() => {
    const modal = useModal();
    const isMachinesListRoute = !!useRouteMatch(getRawRoute(RoutePathName.machinesList));
    const { visible, hide } = modal;
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [incidentLabel, setIncidentLabel] = useState<Incident['label']>();
    const [machineLabel, setMachineLabel] = useState<Machine['label']>();
    const [success, setSuccess] = useState<boolean>(false);
    const [createIncident, fetchIncidents, createMachine, fetchMachines] = useActions([
        createIncidentAction.trigger,
        listIncidentsAction.trigger,
        createMachineAction.trigger,
        listMachinesAction.trigger,
    ]);
    const incidentCreateState = useSelector(getIncidentsCreateState);
    const machineCreateState = useSelector(getMachinesCreateState);
    const previous = usePrevious({ incidentCreateState, machineCreateState });

    const onChangeField: InputProps['onChange'] = (e) => {
        if (isMachinesListRoute) {
            setMachineLabel(e.target.value);
        } else {
            setIncidentLabel(e.target.value);
        }
    };
    const onValid = () => {
        if (isMachinesListRoute) {
            createMachine({ label: machineLabel });
        } else {
            createIncident({ label: incidentLabel });
        }
    };

    useEffect(() => {
        if (previous?.incidentCreateState.loading && !incidentCreateState.loading) {
            if (incidentCreateState.error) {
                message.error(`Erreur lors de la création de l'incident`);
            } else if (incidentCreateState.data) {
                fetchIncidents({
                    exclude: ['RAS'],
                });
                setSuccess(true);
            }
        }
    }, [
        fetchIncidents,
        incidentCreateState.data,
        incidentCreateState.error,
        incidentCreateState.loading,
        previous?.incidentCreateState.loading,
    ]);

    useEffect(() => {
        if (previous?.machineCreateState.loading && !machineCreateState.loading) {
            if (machineCreateState.error) {
                message.error(`Erreur lors de la création de l'engin`);
            } else if (machineCreateState.data) {
                fetchMachines();
                setSuccess(true);
            }
        }
    }, [
        fetchMachines,
        machineCreateState.data,
        machineCreateState.error,
        machineCreateState.loading,
        previous?.machineCreateState.loading,
    ]);

    useEffect(() => {
        let timeout: number;
        if (success) {
            timeout = window.setTimeout(() => {
                hide();
            }, 2000);
        }
        return () => {
            window.clearTimeout(timeout);
        };
    }, [hide, success]);

    return (
        <CustomModal
            {...antdModal(modal)}
            title={!success && `Ajouter un ${isMachinesListRoute ? 'engin' : 'incident'}`}
            footer={
                !success && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                type="primary"
                                shortcut="enter"
                                shortcutScope={shortcutScope}
                                onClick={onValid}
                                disabled={isMachinesListRoute ? !machineLabel : !incidentLabel}
                                loading={isMachinesListRoute ? machineCreateState.loading : incidentCreateState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                type="primary"
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                onClick={hide}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            width={!success ? 368 : 232}
        >
            {!success ? (
                <ArrowNavItem scope={shortcutScope}>
                    <Input placeholder={`Saisir un nom`} onChange={onChangeField} autoFocus />
                </ArrowNavItem>
            ) : (
                <SuccessMessage message={`${isMachinesListRoute ? 'Engin' : 'Incident'} ajouté avec succès`} />
            )}
        </CustomModal>
    );
});

export default AddMachineIncidentModal;
