import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16">
        <path
            fill="currentColor"
            fillRule="nonzero"
            d="M7.983 1.125a.437.437 0 00-.33.145l-.056.078L.185 14.176a.446.446 0 00.303.66l.083.008h14.824c.314 0 .52-.311.42-.592l-.035-.076L8.368 1.348a.438.438 0 00-.385-.223zm.108 1.949l6.054 10.48-.108.187H1.928l-.108-.187 6.054-10.48h.217zm-.108 7.886a.73.73 0 100 1.46.73.73 0 000-1.46zm.427-4.704h-.855l-.012.006-.006.012v3.278l.006.013.012.005h.855l.013-.005.005-.013V6.274l-.005-.012-.013-.006z"
        />
    </svg>
);

const IconWarning: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-warning${props.className ? ` ${props.className}` : ''}`} />
);

export default IconWarning;
