import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Input, message, Spin, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import {
    details as preparationDetails,
    picking,
    getPreparationPickingState,
    update,
    getPreparationUpdateState,
    getPreparationStateById,
} from '../../store/actions/preparations';
import {
    Preparation,
    Pallet,
    PickingStatus,
    PlaceZoneType,
    ResupplyOrderType,
    PalletQuality,
    PalletPlace,
} from '../../store/api/apiTypes';
import { create, getResupplyOrderCreateState } from '../../store/actions/resupplyOrders';
import { getStockDifferencesState, send } from '../../store/actions/stockDifferences';
import { getPalletPlaceListState, list as palletPlacesList } from '../../store/actions/palletPlaces';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import QuantityInput from '../../components/QuantityInput';
import TitleBlack from '../../components/TitleBlack';
import SuccessMessage from '../../components/SuccessMessage';
import { IconWarning } from '../../components/icons';
import { getResupplyOrderCreateError } from '../../helpers/i18n';
import { getRawRoute, RoutePathName } from '../../routes';
import { isZebra } from '../../helpers/enterprise-browser';
import constants from '../../config/constants';

const shortcutScope = 'PickingModal';

type Steps =
    | 'detailScan'
    | 'quantity'
    | 'resupply'
    | 'delta'
    | 'anomalyComment'
    | 'anomalySuccess'
    | 'pause'
    | 'success';

interface PickingModalProps extends CustomModalProps {
    preparationId?: Preparation['id'];
    pallet?: Pallet;
    setSelectedPalletPlace: React.Dispatch<React.SetStateAction<PalletPlace | undefined>>;
}

const PickingModal: FC<PickingModalProps> = ({ visible, onCancel, preparationId, pallet, setSelectedPalletPlace }) => {
    const isDetailType = !!useRouteMatch(getRawRoute(RoutePathName.preparationDetailType));
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const isMounted = useIsMounted();
    const initialStep = isDetailType ? 'detailScan' : 'quantity';
    const [step, setStep] = useState<Steps>(initialStep);
    const [palletReference, setPalletReference] = useState<string>();
    const [stockDelta, setStockDelta] = useState(0);
    const [anomalyDelta, setAnomalyDelta] = useState(0);
    const [anomalyComment, setAnomalyComment] = useState<string>();
    const [isIgnoringDelta, setIsIgnoringDelta] = useState(false);
    const [isResupplyCreateTimeUp, setIsResupplyCreateTimeUp] = useState(false);
    const availableQuantity = useMemo(() => pallet?.quantity ?? 0, [pallet?.quantity]);
    const preparation = useSelector(getPreparationStateById(preparationId)).data;
    const remainingQuantityToPick = useMemo(
        () => (preparation?.pickingQtyToPrep ?? 0) - (preparation?.pickingPreparedQty ?? 0),
        [preparation?.pickingQtyToPrep, preparation?.pickingPreparedQty]
    );
    const [quantity, setQuantity] = useState(
        remainingQuantityToPick > availableQuantity ? availableQuantity : remainingQuantityToPick
    );
    const hasStockDiff = useMemo(
        () => quantity < availableQuantity && quantity < remainingQuantityToPick,
        [quantity, availableQuantity, remainingQuantityToPick]
    );
    const [
        sendPicking,
        fetchPreparation,
        updatePreparation,
        createResupplyOrder,
        sendStockDifference,
        fetchPalletPlaces,
    ] = useActions([
        picking.trigger,
        preparationDetails.trigger,
        update.trigger,
        create.trigger,
        send.trigger,
        palletPlacesList.trigger,
    ]);
    const stockDifferenceState = useSelector(getStockDifferencesState);
    const resupplyOrderCreateState = useSelector(getResupplyOrderCreateState);
    const preparationUpdateState = useSelector(getPreparationUpdateState);
    const pickingState = useSelector(getPreparationPickingState);
    const palletPlaceListState = useSelector(getPalletPlaceListState);
    const previous = usePrevious({
        pickingState,
        preparationUpdateState,
        resupplyOrderCreateState,
        stockDifferenceState,
        isIgnoringDelta,
        palletPlaceListState,
    });
    const refreshPalletPlaceList = useCallback(() => {
        fetchPalletPlaces({
            parcelId: preparation?.parcel?.id,
            zoneType: isDetailType ? PlaceZoneType.mass : PlaceZoneType.picking,
            quality: isDetailType ? PalletQuality.conform : undefined,
            pageSize: 300,
        });
    }, [fetchPalletPlaces, preparation?.parcel?.id, isDetailType]);
    const sendPickingRequest = useCallback(() => {
        sendPicking({
            preparationId: preparation?.id,
            placeId: pallet?.currentPlace?.id,
            index: pallet?.currentPlace?.index,
            quantity,
        });
    }, [sendPicking, preparation?.id, pallet?.currentPlace?.id, pallet?.currentPlace?.index, quantity]);
    const onSubmitDetailScan = () => {
        if (palletReference === pallet?.reference) {
            setStep('quantity');
        } else {
            message.error('La référence ne correspond pas à celle de la palette choisie pour le picking');
        }
    };
    const onSubmitQuantity = () => {
        if (quantity < availableQuantity && quantity < remainingQuantityToPick) {
            setStep('delta');
        } else {
            sendPickingRequest();
        }
    };
    const onIgnoreDelta = () => {
        setIsIgnoringDelta(true);
    };
    const onSubmitDelta = () => {
        if (anomalyDelta > 0) {
            setStep('anomalyComment');
        } else {
            sendPickingRequest();
        }
    };
    const onSubmitDeltaComment = () => {
        sendPickingRequest();
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const getModalTitle = () => {
        switch (step) {
            case 'detailScan':
                return 'Picking';

            case 'quantity':
                return 'Quantité prélevée';

            case 'delta':
                return `Il y a un écart de ${
                    availableQuantity - quantity
                } colis. Veuillez indiquer les quantités pour chaque motif :`;

            case 'anomalyComment':
                return 'Merci de décrire les anomalies colis :';

            case 'pause':
                return (
                    <Typography.Text>
                        <Typography.Text type="warning">
                            <IconWarning style={{ fontSize: '2.25rem', marginBottom: '1rem' }} />
                        </Typography.Text>
                        <br />
                        Picking mis en pause en attendant le réapprovisionnement de la référence
                    </Typography.Text>
                );

            case 'anomalySuccess':
                return (
                    <SuccessMessage
                        message={
                            <>
                                Les colis ont été déclarés en casse.
                                <br />
                                Veuillez les déplacer dans le puits correspondant.
                            </>
                        }
                    />
                );

            default:
                return undefined;
        }
    };
    const getQuantityInputText = () => {
        if (remainingQuantityToPick > availableQuantity || quantity < availableQuantity) {
            return (
                <>
                    {`sur ${availableQuantity} colis à cet emplacement`}
                    {!!(remainingQuantityToPick - quantity) && (
                        <>
                            <br />({`${remainingQuantityToPick - quantity}`} restant
                            {remainingQuantityToPick - quantity > 1 ? 's' : ''} à prélever)
                        </>
                    )}
                </>
            );
        } else {
            return `sur ${remainingQuantityToPick} colis`;
        }
    };
    const afterModalClose = () => {
        setStep(initialStep);
        setStockDelta(0);
        setAnomalyDelta(0);
        setAnomalyComment(undefined);
        setPalletReference(undefined);
    };

    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (barCode.data?.match(constants.PALLET_BARCODE_REGEX)?.[1] === pallet?.barcode) {
                    setStep('quantity');
                } else {
                    message.error(
                        'Le code-barre scanné ne correspond pas à celui de la palette choisie pour le picking'
                    );
                }
            }
        },
        {
            deps: [step, setStep, pallet?.barcode],
            disable: !visible || step !== 'detailScan',
        }
    );

    // set initial quantity
    useEffect(() => {
        setQuantity(remainingQuantityToPick > availableQuantity ? availableQuantity : remainingQuantityToPick);
    }, [availableQuantity, remainingQuantityToPick]);

    // after picking response, create resupply order if we emptied the place or when there are anomalies,
    // or update preparation status if we picked the total quantity
    useEffect(() => {
        if (visible && previous?.pickingState.loading && !pickingState.loading) {
            if (pickingState.error) {
                message.error('Une erreur est survenue lors du picking');
            } else {
                if (hasStockDiff && !isIgnoringDelta) {
                    sendStockDifference({
                        placeId: pallet?.currentPlace?.id,
                        parcelId: preparation?.parcel?.id,
                        palletId: pallet?.id,
                        stockDifferenceQuantity: stockDelta,
                        packageAnomaliesQuantity: anomalyDelta,
                        message: anomalyComment,
                    });
                } else {
                    if (isIgnoringDelta) {
                        setIsIgnoringDelta(false);
                        refreshPalletPlaceList();
                    }

                    if (!isDetailType && quantity === availableQuantity) {
                        setStep('resupply');
                    } else {
                        setStep('success');
                    }
                }
            }
        }
    }, [
        previous?.pickingState,
        pickingState.loading,
        pickingState.error,
        setStep,
        pallet?.currentPlace?.id,
        pallet?.id,
        preparation?.parcel?.id,
        availableQuantity,
        quantity,
        hasStockDiff,
        sendStockDifference,
        stockDelta,
        anomalyDelta,
        anomalyComment,
        isIgnoringDelta,
        refreshPalletPlaceList,
        isDetailType,
        visible,
    ]);

    useEffect(() => {
        if (visible && !previous?.isIgnoringDelta && isIgnoringDelta) {
            sendPickingRequest();
        }
    }, [previous?.isIgnoringDelta, isIgnoringDelta, sendPickingRequest, visible]);

    useEffect(() => {
        if (visible && previous?.preparationUpdateState.loading && !preparationUpdateState.loading) {
            if (preparationUpdateState.error) {
                message.error('Une erreur est survenue lors de la mise à jour');
                setStep(initialStep);
            } else {
                fetchPreparation({ preparationId });
                if (!isDetailType && preparationUpdateState.data?.pickingPrepStatus === PickingStatus.waiting) {
                    setStep('pause');
                }
            }
        }
    }, [
        visible,
        previous?.preparationUpdateState,
        preparationUpdateState.loading,
        preparationUpdateState.error,
        fetchPreparation,
        preparationId,
        preparationUpdateState.data?.pickingPrepStatus,
        isDetailType,
        initialStep,
    ]);

    // resupply step
    useEffect(() => {
        let timeout: number;

        if (visible && step === 'resupply') {
            createResupplyOrder({
                placeId: pallet?.currentPlace?.id,
                type: ResupplyOrderType.resupply,
                quantityToBeTransferred: 100,
            });
            timeout = window.setTimeout(() => {
                if (isMounted.current && step === 'resupply') {
                    setIsResupplyCreateTimeUp(true);
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [step, pallet?.currentPlace?.id, createResupplyOrder, isMounted, visible]);

    // resupply request response step
    useEffect(() => {
        if (visible && step === 'resupply' && isResupplyCreateTimeUp && !resupplyOrderCreateState.loading) {
            setIsResupplyCreateTimeUp(false);

            if (resupplyOrderCreateState.error) {
                if (resupplyOrderCreateState.error.data.noMassStock && resupplyOrderCreateState.error?.status === 400) {
                    setStep('success');
                } else {
                    message.error(getResupplyOrderCreateError(resupplyOrderCreateState));
                    setStep('quantity');
                }
            } else {
                if (pickingState.data?.pickingPrepStatus === PickingStatus.finished) {
                    setStep('success');
                } else {
                    refreshPalletPlaceList();
                    fetchPreparation({ preparationId });
                }
            }
        }
    }, [
        fetchPreparation,
        isResupplyCreateTimeUp,
        pickingState.data?.pickingPrepStatus,
        preparationId,
        refreshPalletPlaceList,
        resupplyOrderCreateState,
        step,
        visible,
    ]);

    useEffect(() => {
        if (visible && step === 'resupply' && previous?.palletPlaceListState.loading && !palletPlaceListState.loading) {
            if (palletPlaceListState.error) {
                message.error('Une erreur est survenue lors de la récupération des emplacements');
            } else {
                if (
                    preparationUpdateState.data?.pickingPrepStatus !== PickingStatus.waiting &&
                    palletPlaceListState.data?.items.every(
                        (palletPlace) => !palletPlace.pallet?.quantity || palletPlace.place.resupply
                    )
                ) {
                    updatePreparation({ id: preparation?.id, pickingPrepStatus: PickingStatus.waiting });
                } else {
                    setStep('success');
                }
            }
        }
    }, [
        previous?.palletPlaceListState.loading,
        palletPlaceListState.loading,
        palletPlaceListState.error,
        palletPlaceListState.data?.items,
        updatePreparation,
        preparation?.id,
        visible,
        preparationUpdateState.data?.pickingPrepStatus,
        step,
    ]);

    // stockDifference request response step
    useEffect(() => {
        if (visible && previous?.stockDifferenceState.loading && !stockDifferenceState.loading) {
            if (stockDifferenceState.error) {
                message.error("Une erreur est survenue lors de l'envoi de l'écart de stock");
            } else {
                if (anomalyDelta > 0) {
                    setStep('anomalySuccess');
                } else if (isDetailType) {
                    setStep('success');
                } else {
                    setStep('resupply');
                }
            }
        }
    }, [
        previous?.stockDifferenceState.loading,
        stockDifferenceState.loading,
        stockDifferenceState.error,
        quantity,
        visible,
        anomalyDelta,
        isDetailType,
    ]);

    // refresh preparation after success
    useEffect(() => {
        let timeout: number;

        if (visible && step === 'success') {
            fetchPreparation({ preparationId });

            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onClose();
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [step, onClose, isMounted, fetchPreparation, preparationId, visible]);

    // set the correct initialStep based on isDetailType dependency
    useEffect(() => {
        setStep(initialStep);
    }, [initialStep, isDetailType]);

    // reset selectedPalletPlace to undefined on success to force select to refresh picking value
    useEffect(() => {
        if (step === 'success') {
            setSelectedPalletPlace(undefined);
        }
    }, [setSelectedPalletPlace, step]);

    return (
        <CustomModal
            footer={
                step !== 'success' && step !== 'resupply' ? (
                    <>
                        {step === 'detailScan' && (
                            <>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="enter"
                                        type="primary"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        onClick={onSubmitDetailScan}
                                    >
                                        Valider
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="esc"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        type="primary"
                                        onClick={onClose}
                                        ghost
                                    >
                                        Annuler
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </>
                        )}
                        {step === 'quantity' && (
                            <>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="enter"
                                        type="primary"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        onClick={onSubmitQuantity}
                                        loading={pickingState.loading}
                                    >
                                        Valider
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="esc"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        type="primary"
                                        onClick={onClose}
                                        disabled={pickingState.loading}
                                        ghost
                                    >
                                        Annuler
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </>
                        )}
                        {step === 'delta' && (
                            <>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="enter"
                                        type="primary"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        onClick={onSubmitDelta}
                                        disabled={anomalyDelta + stockDelta < availableQuantity - quantity}
                                        loading={pickingState.loading}
                                    >
                                        Valider
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="f1"
                                        type="primary"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        onClick={onIgnoreDelta}
                                        loading={pickingState.loading}
                                        ghost
                                    >
                                        Ignorer l&rsquo;écart et valider
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="esc"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        type="primary"
                                        onClick={() => setStep('quantity')}
                                        ghost
                                    >
                                        Modifier le picking
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </>
                        )}
                        {step === 'anomalyComment' && (
                            <>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="enter"
                                        type="primary"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        onClick={onSubmitDeltaComment}
                                        loading={pickingState.loading}
                                    >
                                        Valider
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                                <ArrowNavItem scope={shortcutScope}>
                                    <ButtonWithShortcut
                                        shortcut="esc"
                                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                        shortcutScope={shortcutScope}
                                        type="primary"
                                        onClick={() => setStep('delta')}
                                        disabled={pickingState.loading}
                                        ghost
                                    >
                                        Retour
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </>
                        )}
                        {step === 'pause' && (
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="enter"
                                    type="primary"
                                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                    shortcutScope={shortcutScope}
                                    onClick={onClose}
                                >
                                    Fermer
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        )}
                        {step === 'anomalySuccess' && (
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="enter"
                                    type="primary"
                                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                    shortcutScope={shortcutScope}
                                    onClick={() => setStep('resupply')}
                                >
                                    Continuer
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        )}
                    </>
                ) : null
            }
            afterClose={afterModalClose}
            visible={visible}
            onCancel={onCancel}
            title={getModalTitle()}
            width={step === 'success' ? 266 : 368}
            keyboard={false}
            maskClosable={step === 'success'}
            altTitle={['detailScan', 'quantity'].includes(step)}
        >
            {step === 'detailScan' && (
                <ArrowNavItem scope={shortcutScope}>
                    <Input
                        placeholder={isZebra ? 'Flasher palette ou entrer réf.' : 'Entrer référence palette'}
                        onChange={(e) => setPalletReference(e.target.value)}
                        autoFocus
                    />
                </ArrowNavItem>
            )}
            {step === 'quantity' && (
                <>
                    <TitleBlack>Emplacement du picking</TitleBlack>
                    <div className="centered-description mb-24">
                        <div>
                            <p>Secteur</p>
                            <p>{pallet?.currentPlace?.sector ?? '—'}</p>
                        </div>
                        <div>
                            <p>Casier</p>
                            <p>{pallet?.currentPlace?.locker ?? '—'}</p>
                        </div>
                        {isDetailType && (
                            <div>
                                <p>Emplacement</p>
                                <p>{pallet?.currentPlace?.spot ?? '—'}</p>
                            </div>
                        )}
                    </div>
                    <QuantityInput
                        onChange={setQuantity}
                        value={quantity}
                        max={remainingQuantityToPick > availableQuantity ? availableQuantity : remainingQuantityToPick}
                        text={getQuantityInputText()}
                        hasArrowNav={visible}
                        smallText
                    />
                </>
            )}
            {step === 'delta' && (
                <>
                    <div className="flex flex-between" style={{ marginBottom: '0.75rem' }}>
                        <p
                            style={{
                                margin: 0,
                                flex: '1 0 95px',
                                fontSize: '0.9375rem',
                                fontWeight: 500,
                                lineHeight: '1rem',
                            }}
                        >
                            Ecart de stock
                        </p>
                        <QuantityInput
                            onChange={setStockDelta}
                            value={stockDelta}
                            max={availableQuantity - quantity - anomalyDelta || 0}
                            hasArrowNav={visible}
                            small
                        />
                    </div>
                    <div className="flex flex-between">
                        <p
                            style={{
                                margin: 0,
                                flex: '1 0 95px',
                                fontSize: '0.9375rem',
                                fontWeight: 500,
                                lineHeight: '1rem',
                            }}
                        >
                            Anomalies colis
                        </p>
                        <QuantityInput
                            onChange={setAnomalyDelta}
                            value={anomalyDelta}
                            max={availableQuantity - quantity - stockDelta || 0}
                            hasArrowNav={visible}
                            small
                        />
                    </div>
                </>
            )}
            {step === 'anomalyComment' && (
                <Input.TextArea
                    onChange={(e) => setAnomalyComment(e.target.value)}
                    value={anomalyComment}
                    placeholder="Saisir une description"
                />
            )}
            {step === 'resupply' && (
                <div style={{ textAlign: 'center' }}>
                    <p>Envoi d&apos;une demande de réapprovisionnement...</p>
                    <Spin size="large" />
                </div>
            )}
            {step === 'success' && (
                <SuccessMessage
                    message={
                        preparation?.pickingPrepStatus === PickingStatus.finished
                            ? 'Tous les colis on été prélevés'
                            : 'Les colis ont été prélevés'
                    }
                />
            )}
        </CustomModal>
    );
};

export default PickingModal;
