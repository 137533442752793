import React from 'react';
import { IconEnter } from '../components/icons';
import { isZebra } from './enterprise-browser';

export const DEFAULT_SHORCUT_SCOPE = 'all';
// Used in ButtonWithShortcut to scope events
class Shortcut {
    public _scope = DEFAULT_SHORCUT_SCOPE;

    public set scope(scope: string) {
        this._scope = scope;
    }

    public get scope() {
        return this._scope;
    }

    public resetScope() {
        this._scope = DEFAULT_SHORCUT_SCOPE;
    }
}

export const shortcut = new Shortcut();

export const formatShortcut = (shortcut: string) => {
    switch (shortcut) {
        case 'return':
        case 'enter':
            return <IconEnter />;

        case '`':
            return 'ESC';

        default:
            return shortcut.toUpperCase();
    }
};

// Use the same key name on every device
// (on zebra we remap the ESC key to backtick key), see ./enterprise-browser/index.ts
export const getKey = (key: string) => {
    if (isZebra) {
        switch (key) {
            case 'escape':
            case 'esc':
                return '`';

            default:
                return key;
        }
    } else {
        return key;
    }
};
