import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { message } from 'antd';

import { ControlCustomerOrderStatus, CustomerOrderStatus, Pallet } from '../../store/api/apiTypes';
import { getPalletCreateState } from '../../store/actions/pallets';
import { getControlOrderControlPackagesState } from '../../store/actions/preparationControl';
import {
    close as customerOrderClose,
    closeDetails as customerOrderCloseDetails,
    getCustomerOrderCloseDetailsState,
    getCustomerOrderCloseState,
    getCustomerOrderStateById,
} from '../../store/actions/customerOrders';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useShortcutScope } from '../../hooks';
import ControlScanModal from './ControlScanModal';
import ControlPrintModal from './ControlPrintModal';
import SuccessModal from '../../components/SuccessModal';
import { shortcut } from '../../helpers/shortcut';
import { getRoute, RoutePathName } from '../../routes';
import ControlTransferModal from './ControlTransferModal';
import ControlVracModal from './ControlVracModal';
import ControlCloseModal from './ControlCloseModal';
import ControlBrokenPackageModal from './ControlBrokenPackageModal';
import { getCustomerOrderCloseError } from '../../helpers/i18n';

const shortcutScope = 'ControlActionsModal';

interface ControlActionsModalProps extends CustomModalProps {
    refreshCustomerOrder: () => void;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
}

const ControlActionsModal: FC<ControlActionsModalProps> = ({
    visible,
    onCancel,
    refreshCustomerOrder,
    setIsVisible,
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const isMounted = useIsMounted();
    const history = useHistory();
    const { loadingOrderId, customerOrderId: customerOrderIdParam } =
        useParams<{ loadingOrderId: string; customerOrderId: string }>();
    const customerOrderId = parseInt(customerOrderIdParam, 10);
    const [palletToControl, setPalletToControl] = useState<Pallet>();
    const [isScanModalVisible, setIsScanModalVisible] = useState(false);
    const [isVracModalVisible, setIsVracModalVisible] = useState(false);
    const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
    const [isCloseModalVisible, setIsCloseModalVisible] = useState(false);
    const [isBrokenPackageModalVisible, setIsBrokenPackageModalVisible] = useState(false);
    const [isWaitingForPrintingAfterScanFinish, setIsWaitingForPrintingAfterScanFinish] = useState(false);
    const [printOrigin, setPrintOrigin] = useState<'scan' | 'transfer' | 'vrac' | undefined>();
    const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string>();
    const [closeDetailsCustomerOrder, closeCustomerOrder] = useActions([
        customerOrderCloseDetails.trigger,
        customerOrderClose.trigger,
    ]);
    const customerOrderState = useSelector(getCustomerOrderStateById(customerOrderId));
    const createPalletState = useSelector(getPalletCreateState);
    const controlPackagesState = useSelector(getControlOrderControlPackagesState);
    const customerOrderCloseDetailsState = useSelector(getCustomerOrderCloseDetailsState);
    const customerOrderCloseState = useSelector(getCustomerOrderCloseState);
    const previous = usePrevious({
        isScanModalVisible,
        createPalletState,
        controlPackagesState,
        customerOrderCloseDetailsState,
        customerOrderCloseState,
    });
    const isToRegularize = customerOrderState.data?.status === CustomerOrderStatus.toRegularize;
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const onScanFinish = useCallback(() => {
        setPrintOrigin('scan');
        setIsScanModalVisible(false);
        setIsPrintModalVisible(true);
        setIsWaitingForPrintingAfterScanFinish(true);
    }, []);
    const onCloseScanModal = useCallback(() => {
        setIsScanModalVisible(false);
        refreshCustomerOrder();
    }, [refreshCustomerOrder]);
    const onClosePrintModal = useCallback(() => {
        setIsPrintModalVisible(false);
    }, []);
    const onCloseVracModal = useCallback(() => {
        setIsVracModalVisible(false);
    }, []);
    const onCloseCloseModal = useCallback(() => {
        setIsCloseModalVisible(false);
    }, []);
    const onVracScanFinish = useCallback(() => {
        setPrintOrigin('vrac');
        setIsVracModalVisible(false);
        setIsPrintModalVisible(true);
        setSuccessMessage('Colis vrac créés avec succès');
    }, []);
    const onVracPrint = useCallback(() => {
        setPrintOrigin('vrac');
        setIsVracModalVisible(false);
        setIsPrintModalVisible(true);
        setSuccessMessage(undefined);
    }, []);
    const onScanTransfer = useCallback(() => {
        setIsScanModalVisible(false);
        setIsTransferModalVisible(true);
        setSuccessMessage('Colis transférés avec succès');
    }, []);
    const onClickCreatePallet = () => {
        onClose();
        setIsScanModalVisible(true);
    };
    const onTransferSuccess = useCallback(() => {
        setPrintOrigin('transfer');
        setIsTransferModalVisible(false);
        setIsPrintModalVisible(true);
    }, []);
    const onPrintSuccessOrPrintLater = useCallback(() => {
        setIsPrintModalVisible(false);

        if (isWaitingForPrintingAfterScanFinish) {
            setSuccessMessage(
                customerOrderState.data?.controlStatus === ControlCustomerOrderStatus.completed
                    ? 'Tous les colis de ce client ont été contrôlés'
                    : customerOrderState.data?.controlledQuantity === customerOrderState.data?.preparedQuantity
                    ? "Il n'y a actuellement aucun colis restant à contrôler"
                    : 'Palette créée avec succès'
            );
            setIsWaitingForPrintingAfterScanFinish(false);
        }

        setIsSuccessModalVisible(true);

        if (printOrigin === 'transfer') {
            setIsScanModalVisible(true);
        }

        window.setTimeout(() => {
            if (isMounted) {
                setIsSuccessModalVisible(false);
            }
        }, 2000);
    }, [
        customerOrderState.data?.controlStatus,
        customerOrderState.data?.controlledQuantity,
        customerOrderState.data?.preparedQuantity,
        isMounted,
        isWaitingForPrintingAfterScanFinish,
        printOrigin,
    ]);
    const onCloseSuccessModal = useCallback(() => {
        setIsSuccessModalVisible(false);

        if (customerOrderState.data?.controlStatus === ControlCustomerOrderStatus.completed) {
            history.push(getRoute(RoutePathName.customerOrderDetails, { loadingOrderId, customerOrderId }));
        }
    }, [customerOrderState.data?.controlStatus, customerOrderId, history, loadingOrderId]);
    const onCloseTransferModal = useCallback(() => {
        setIsTransferModalVisible(false);
        setIsScanModalVisible(true);
    }, []);

    const onControlCloseModalSuccess = useCallback(() => {
        setIsVisible(false);
        refreshCustomerOrder();
    }, [setIsVisible, refreshCustomerOrder]);
    const onClickSeeCurrentPallet = () => {
        onClose();
        history.push(getRoute(RoutePathName.preparationControlPalletList, { loadingOrderId, customerOrderId }));
    };
    const onClickVracParcels = () => {
        onClose();
        setIsVracModalVisible(true);
    };
    const onClickDeclareBroken = () => {
        onClose();
        setIsBrokenPackageModalVisible(true);
    };
    const onClickRegularize = () => {
        history.push(getRoute(RoutePathName.preparationControlRegularize, { loadingOrderId, customerOrderId }));
    };
    const onClickClosePreparation = () => {
        closeDetailsCustomerOrder({ customerOrderId });
    };
    const onCloseBrokenModal = () => {
        setIsBrokenPackageModalVisible(false);
    };

    const onBrokenModalFinish = () => {
        setIsBrokenPackageModalVisible(false);
        setSuccessMessage('Les colis ont été déclarés en casse. Veuillez les déplacer dans le puits correspondant.');
        setIsSuccessModalVisible(true);
        window.setTimeout(() => {
            if (isMounted) {
                setIsSuccessModalVisible(false);
            }
        }, 2000);
    };
    const canAddOrClose =
        customerOrderState.data?.status &&
        ![CustomerOrderStatus.toBeLoaded, CustomerOrderStatus.loaded, CustomerOrderStatus.toRegularize].includes(
            customerOrderState.data?.status
        );
    const canClose = canAddOrClose && !customerOrderState.data?.isBlocked;
    const actions = [
        canAddOrClose && {
            onClick: onClickCreatePallet,
            label: 'Créer une palette',
        },
        {
            onClick: onClickSeeCurrentPallet,
            label: 'Voir palettes existantes',
        },
        {
            onClick: onClickVracParcels,
            label: 'Colis vrac',
        },
        canAddOrClose && {
            onClick: onClickDeclareBroken,
            label: 'Déclarer casse',
        },
        isToRegularize && {
            onClick: onClickRegularize,
            label: 'Régulariser',
        },
        canClose && {
            onClick: onClickClosePreparation,
            label: 'Clore la préparation client',
            isLoading: customerOrderCloseDetailsState.loading,
        },
    ].filter(Boolean) as Array<{ onClick: () => void; label: string; isLoading?: boolean }>;

    // update palletToControl when controlPackages request succeeds (needed to have the latest state
    // of the pallet in order to delete it if the user cancels and there are no parcels in it ^^')
    useEffect(() => {
        if (previous?.controlPackagesState.loading && !controlPackagesState.loading) {
            if (controlPackagesState.data) {
                setPalletToControl(controlPackagesState.data);
                refreshCustomerOrder();
            }
        }
    }, [
        controlPackagesState.data,
        controlPackagesState.loading,
        previous?.controlPackagesState.loading,
        refreshCustomerOrder,
    ]);

    // When scan modal closes, delete pallet if it is empty

    useEffect(() => {
        if (previous?.customerOrderCloseDetailsState.loading && !customerOrderCloseDetailsState.loading) {
            if (customerOrderCloseDetailsState.error) {
                const requestError = getCustomerOrderCloseError(customerOrderCloseDetailsState);

                if (requestError.message) {
                    message.error(requestError.message);
                }
            } else {
                if (
                    customerOrderCloseDetailsState.data?.orderDifference !== 0 ||
                    customerOrderCloseDetailsState.data?.orderDifferenceSAV
                ) {
                    setIsCloseModalVisible(true);
                } else {
                    closeCustomerOrder({ customerOrderId });
                }
            }
        }
    }, [
        previous?.customerOrderCloseDetailsState.loading,
        customerOrderCloseDetailsState.loading,
        customerOrderCloseDetailsState,
        customerOrderCloseDetailsState.data?.orderDifference,
        closeCustomerOrder,
        customerOrderId,
    ]);

    useEffect(() => {
        if (!isCloseModalVisible && previous?.customerOrderCloseState.loading && !customerOrderCloseState.loading) {
            if (customerOrderCloseState.error) {
                const requestError = getCustomerOrderCloseError(customerOrderCloseState);

                if (requestError.message) {
                    message.error(requestError.message);
                }
            } else {
                message.success('Préparation client clôturée avec succès');
                refreshCustomerOrder();
                history.push(getRoute(RoutePathName.customerOrderDetails, { loadingOrderId, customerOrderId }));
            }
        }
    }, [
        previous?.customerOrderCloseState.loading,
        customerOrderCloseState.loading,
        customerOrderCloseState,
        refreshCustomerOrder,
        isCloseModalVisible,
        history,
        loadingOrderId,
        customerOrderId,
    ]);

    useEffect(() => {
        if (isSuccessModalVisible) {
            shortcut.scope = 'SuccessModal';
        } else {
            shortcut.resetScope();
        }
    }, [isSuccessModalVisible]);

    return (
        <>
            <CustomModal
                footer={
                    <>
                        {actions.map(({ onClick, label, isLoading }, index) => (
                            <ArrowNavItem scope={shortcutScope} key={index}>
                                <ButtonWithShortcut
                                    shortcut={`f${index + 1}`}
                                    type="primary"
                                    shortcutScope={shortcutScope}
                                    onClick={onClick}
                                    loading={isLoading}
                                >
                                    {label}
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        ))}
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Fermer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                }
                visible={visible}
                onCancel={onCancel}
                title="Actions"
                width={368}
                keyboard={false}
                altTitle
            />
            <ControlScanModal
                visible={isScanModalVisible}
                onCancel={onCloseScanModal}
                onFinish={onScanFinish}
                onTransfer={onScanTransfer}
            />
            <ControlPrintModal
                visible={isPrintModalVisible}
                onCancel={onClosePrintModal}
                onPrintSuccessOrPrintLater={onPrintSuccessOrPrintLater}
                origin={printOrigin}
                customerOrderFileNameOfClientMask={customerOrderState.data?.fileNameOfClientMask}
            />
            <ControlTransferModal
                visible={isTransferModalVisible}
                onCancel={onCloseTransferModal}
                onSuccess={onTransferSuccess}
                palletId={palletToControl?.id}
            />
            <ControlVracModal
                visible={isVracModalVisible}
                onCancel={onCloseVracModal}
                onFinish={onVracScanFinish}
                onPrint={onVracPrint}
            />
            <ControlCloseModal
                visible={isCloseModalVisible}
                onCancel={onCloseCloseModal}
                onSuccess={onControlCloseModalSuccess}
            />
            <ControlBrokenPackageModal
                visible={isBrokenPackageModalVisible}
                onCancel={onCloseBrokenModal}
                onFinish={onBrokenModalFinish}
            />
            {successMessage && (
                <SuccessModal
                    visible={isSuccessModalVisible}
                    onCancel={onCloseSuccessModal}
                    footer={
                        customerOrderState.data?.controlStatus === ControlCustomerOrderStatus.completed && (
                            <ArrowNavItem scope="SuccessModal">
                                <ButtonWithShortcut
                                    shortcut="enter"
                                    shortcutScope="SuccessModal"
                                    type="primary"
                                    onClick={onCloseSuccessModal}
                                    ghost
                                >
                                    Fermer
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        )
                    }
                    message={successMessage}
                    width={customerOrderState.data?.controlStatus === ControlCustomerOrderStatus.completed ? 368 : 232}
                />
            )}
        </>
    );
};

export default ControlActionsModal;
