import React, { FC, useCallback } from 'react';
import ButtonWithShortcut from './ButtonWithShortcut';
import CustomModal, { CustomModalProps } from './CustomModal';
import ArrowNavItem from './ArrowNavItem';
import { useArrowNavScope, useShortcutScope } from '../hooks';
import { Col, Row, Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import { WarningOutlined } from '@ant-design/icons';

const shortcutScope = 'WarningModal';

interface WarningModalProps extends CustomModalProps {
    message: string;
}

const WarningModal: FC<WarningModalProps> = ({ visible, onCancel, message }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    return (
        <CustomModal
            visible={visible}
            width={368}
            keyboard={false}
            altTitle
            footer={
                <ArrowNavItem scope={shortcutScope}>
                    <ButtonWithShortcut
                        shortcut="enter"
                        shortcutOptions={{ enableOnTags: ['INPUT'] }}
                        shortcutScope={shortcutScope}
                        type="primary"
                        onClick={onClose}
                    >
                        Fermer
                    </ButtonWithShortcut>
                </ArrowNavItem>
            }
        >
            <Row justify="center">
                <Col xs={16} className="text-center">
                    <Typography.Title level={4} style={{ fontSize: '35px' }} type="warning">
                        <WarningOutlined />
                    </Typography.Title>
                    <Text>{message}</Text>
                </Col>
            </Row>
        </CustomModal>
    );
};

export default WarningModal;
