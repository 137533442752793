import { Form, FormProps, Input, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    brokenPackages,
    control,
    getControlOrderBrokenPackagesState,
    getControlOrderControlState,
    getControlOrderRemovePackageState,
    PackageRef,
    removePackage,
} from '../../store/actions/preparationControl';

import { details as customerOrderDetails } from '../../store/actions/customerOrders';

import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import QuantityCard from '../../components/QuantityCard';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { getPalletPackageListState } from '../../store/actions/pallets';
import { formatNumber } from '../../helpers/i18n';
import { listControlByCustomerOrderId as preparationControlList } from '../../store/actions/preparations';
import { ControlPreparationStatus } from '../../store/api/apiTypes';
import { errorSound } from '../../helpers/sound';
const shortcutScope = 'ControlBrokenPackageModal';

interface ControlBrokenPackageModalProps extends CustomModalProps {
    onFinish: () => void;
}

const ControlBrokenPackageModal: FC<ControlBrokenPackageModalProps> = ({ visible, onCancel, onFinish }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const { customerOrderId: customerOrderIdParam, palletId } =
        useParams<{ customerOrderId: string; palletId: string }>();
    const customerOrderId = parseInt(customerOrderIdParam ?? '-1', 10);
    const [form] = Form.useForm();
    const [isManual, setIsManual] = useState(false);
    const [refToStore, setRefToStore] = useState<PackageRef>(); // temp data when waiting for API response
    const [barcodeToStore, setBarcodeToStore] = useState<string>(); // temp data when waiting for API response
    const [
        fetchCustomerOrder,
        fetchControlPackage,
        sendBrokenPackages,
        listPreparationControlsByCustomerId,
        setControlBrokenPackageState,
        resetControlBrokenPackageState,
    ] = useActions([
        customerOrderDetails.trigger,
        removePackage.trigger,
        brokenPackages.trigger,
        preparationControlList.trigger,
        control.actions.setBrokenPackageData,
        control.actions.resetBrokenPackages,
    ]);
    const controlBrokenPackageState = useSelector(getControlOrderBrokenPackagesState);
    const controlPackageScanState = useSelector(getControlOrderRemovePackageState);
    const palletPackageListState = useSelector(getPalletPackageListState);

    const { packagesRefs, packagesBarcodes } = useSelector(getControlOrderControlState).brokenPackages;

    const previous = usePrevious({ visible, controlBrokenPackageState, controlPackageScanState });
    const onClose = useCallback(() => {
        onCancel?.({} as React.MouseEvent<HTMLElement, MouseEvent>);
    }, [onCancel]);

    const onSubmitManual: FormProps['onFinish'] = (values) => {
        if (packagesRefs.some((p) => p.cont === values.cont)) {
            message.error('Ce colis est déjà présent dans la palette');
        } else {
            setRefToStore({
                reference: values.reference,
                ...(values.cont ? { cont: values.cont } : {}),
            });
            fetchControlPackage({
                customerOrderId,
                reference: values.reference,
                ...(values.cont ? { cont: values.cont } : {}),
            });
        }
    };

    const onSubmit = () => {
        sendBrokenPackages({
            customerOrderId,
            barcodes: packagesBarcodes.length ? packagesBarcodes : undefined,
            references: packagesRefs.length ? packagesRefs : undefined,
            palletId,
        });
    };
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (
                    packagesBarcodes.includes(barCode.data) ||
                    palletPackageListState.data?.items.some((p) => p.parcel.barcode === barCode.data)
                ) {
                    message.error('Ce colis est déjà présent dans la palette');
                    errorSound();
                } else {
                    setBarcodeToStore(barCode.data);
                    fetchControlPackage({
                        customerOrderId,
                        barcode: barCode.data,
                    });
                }
            }
        },
        {
            deps: [fetchControlPackage, packagesBarcodes],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    // GET parcel response, if request fails because parcel is already on another pallet display error message;
    useEffect(() => {
        if (previous?.controlPackageScanState.loading && !controlPackageScanState.loading) {
            if (controlPackageScanState.error) {
                message.error('Une erreur est survenue pendant la décalartion en casse');
                errorSound();
            } else if (controlPackageScanState.data) {
                setControlBrokenPackageState({
                    packagesBarcodes: barcodeToStore ? [...packagesBarcodes, barcodeToStore] : packagesBarcodes,
                    packagesRefs: refToStore ? [...packagesRefs, refToStore] : packagesRefs,
                });
                form.resetFields();
                setIsManual(false);
            }
            setBarcodeToStore(undefined);
            setRefToStore(undefined);
        }
    }, [
        barcodeToStore,
        controlPackageScanState.data,
        controlPackageScanState.error,
        controlPackageScanState.loading,
        form,
        packagesBarcodes,
        packagesRefs,
        previous?.controlPackageScanState.loading,
        refToStore,
        setControlBrokenPackageState,
    ]);

    useEffect(() => {
        if (previous?.controlBrokenPackageState.loading && !controlBrokenPackageState.loading) {
            if (controlBrokenPackageState.error) {
                message.error("Une erreur est survenue pendant l'ajout de colis");
            } else {
                if (controlBrokenPackageState.data) {
                    fetchCustomerOrder({ customerOrderId });
                    listPreparationControlsByCustomerId({
                        customerOrderId,
                        controlStatus: [
                            ControlPreparationStatus.toBeProcessed,
                            ControlPreparationStatus.inProgress,
                            ControlPreparationStatus.completed,
                            ControlPreparationStatus.toRegularize,
                        ],
                    });
                    onFinish();
                } else {
                    message.error("Une erreur est survenue pendant l'ajout de colis");
                }
            }
        }
    }, [
        controlBrokenPackageState.data,
        controlBrokenPackageState.error,
        controlBrokenPackageState.loading,
        customerOrderId,
        fetchCustomerOrder,
        listPreparationControlsByCustomerId,
        onFinish,
        previous?.controlBrokenPackageState.loading,
    ]);

    useEffect(() => {
        if (!visible) {
            setBarcodeToStore(undefined);
            setRefToStore(undefined);
            resetControlBrokenPackageState();
        } else {
            fetchCustomerOrder({ customerOrderId });
        }
    }, [customerOrderId, fetchCustomerOrder, resetControlBrokenPackageState, visible]);

    return (
        <CustomModal
            footer={
                isManual ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={() => form.submit()}
                                loading={controlPackageScanState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={setIsManual.bind(null, false)}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={onSubmit}
                                loading={controlPackageScanState.loading || controlBrokenPackageState.loading}
                            >
                                Terminer l&rsquo;ajout
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="f1"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={setIsManual.bind(null, true)}
                                ghost
                            >
                                Ajouter manuellement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={
                isManual ? 'Saisir manuellement' : 'Veuillez scanner individuellement les colis à déclarer en casse :'
            }
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            altTitle={isManual}
        >
            {isManual ? (
                <Form form={form} onFinish={onSubmitManual}>
                    <Form.Item name="reference" rules={[{ required: true, message: 'Veuillez saisir une référence' }]}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir référence colis" autoFocus />
                        </ArrowNavItem>
                    </Form.Item>
                    <Form.Item name="cont" style={{ marginBottom: 0 }}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir CONT" />
                        </ArrowNavItem>
                    </Form.Item>
                </Form>
            ) : (
                <QuantityCard
                    value={formatNumber(packagesRefs.length + packagesBarcodes.length)}
                    label="colis cassés"
                    outline
                />
            )}
        </CustomModal>
    );
};

export default ControlBrokenPackageModal;
