import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import React, { useEffect, useRef, useState } from 'react';
import CustomModal from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import { Input, InputProps, message } from 'antd';
import { isZebra } from '../../helpers/enterprise-browser';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { getReceptionStorePalletState, storePallet as storePalletAction } from '../../store/actions/receptions';
import { useSelector } from 'react-redux';
import { Pallet, Parcel, Reception } from '../../store/api/apiTypes';
import { useHistory } from 'react-router-dom';
import SuccessMessage from '../../components/SuccessMessage';

const shortcutScope = 'StorePalletModal';

type storePalletSteps = 'scan' | 'success';

interface StorePalletModalProps {
    pallet: Pallet;
    receptionId: Reception['id'];
    parcelId: Parcel['id'];
    successRoute: string;
}

const StorePalletModal = NiceModal.create<StorePalletModalProps>(({ pallet, receptionId, parcelId, successRoute }) => {
    const modal = useModal();
    const { visible, hide } = modal;
    const storePallet = useActions(storePalletAction.trigger);
    const [reference, setReference] = useState<string | undefined>();
    const [step, setStep] = useState<storePalletSteps>('scan');
    const storeState = useSelector(getReceptionStorePalletState);
    const previous = usePrevious({ storeState });
    const inputRef = useRef<Input | null>(null);
    const history = useHistory();
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const onSubmit = () => {
        if (pallet) {
            storePallet({ id: receptionId, palletId: pallet.id, reference });
        }
    };

    const onChangeField: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };

    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                storePallet({
                    id: receptionId,
                    palletId: pallet.id,
                    barcode: barCode.data,
                });
            }
        },
        {
            deps: [storePallet],
            disable: !visible || storeState.loading,
        }
    );

    useEffect(() => {
        if (previous?.storeState.loading && !storeState.loading) {
            if (storeState.error) {
                if (storeState.error.status === 404) {
                    message.error("Veuillez scanner l'emplacement reservé pour cette palette");
                } else if (storeState.error.status === 400 && storeState.error.data.placeNotFitWithPallet) {
                    message.error("L'emplacement n'est pas adapté à la taille de la palette");
                } else {
                    message.error('Une erreur est survenue lors du rangement de la palette');
                }
            } else {
                setStep('success');
                history.push(successRoute);
            }
        }
    }, [
        hide,
        history,
        parcelId,
        previous?.storeState.loading,
        receptionId,
        storeState.error,
        storeState.loading,
        successRoute,
    ]);

    useEffect(() => {
        let timeout: number;
        if (step === 'success') {
            window.setTimeout(() => {
                hide();
            }, 1000);
        }
        return () => window.clearTimeout(timeout);
    }, [hide, step]);

    return (
        <CustomModal
            {...antdModal(modal)}
            footer={
                step === 'scan' ? (
                    <>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={onSubmit}
                                loading={storeState.loading}
                                disabled={!reference}
                            >
                                Valider le rangement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={hide}
                                disabled={storeState.loading}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : null
            }
            title={step === 'scan' ? 'Rangement des palettes' : null}
            width={step === 'scan' ? 368 : 232}
            altTitle
        >
            {step === 'scan' ? (
                <ArrowNavItem>
                    <Input
                        placeholder={isZebra ? "Flasher l'emplacement" : "Entrer le code barre de l'emplacement"}
                        value={reference}
                        onChange={onChangeField}
                        ref={inputRef}
                        autoFocus
                    />
                </ArrowNavItem>
            ) : (
                <SuccessMessage message="Palette rangée avec succès" />
            )}
        </CustomModal>
    );
});

export default StorePalletModal;
