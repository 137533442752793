import { Empty, EmptyProps, Pagination, Skeleton, TableProps, Typography } from 'antd';
import React, { CSSProperties, HTMLAttributes, ReactNode, useCallback } from 'react';

import '../../assets/styles/List.less';

import { classNames } from '../../helpers';
import BasicList from '../BasicList';
import TitleBlack from '../TitleBlack';
import ListRow from './ListRow';

export interface ListColumn<RecordType> {
    dataIndex?: string;
    key?: string;
    render?: (record: RecordType) => ReactNode;
    hidden?: (record: RecordType) => boolean;
    title?: ReactNode;
    style?: CSSProperties;
    cellStyle?: CSSProperties;
    flex: string;
}

export interface ListProps<RecordType> extends HTMLAttributes<HTMLDivElement> {
    data?: RecordType[];
    columns: Array<ListColumn<RecordType>>;
    rowKey: (record: RecordType, index: number) => string;
    className?: string;
    onRowEnterPress?: (record: RecordType) => void;
    onRowClick?: (record: RecordType) => void;
    onRowFocus?: (record: RecordType) => void;
    onRowBlur?: (record: RecordType) => void;
    renderCellFooter?: (record: RecordType) => ReactNode;
    renderCellAddonRight?: (record: RecordType) => ReactNode;
    headerInCell?: boolean;
    disableArrowNav?: boolean;
    arrowNavScope?: string;
    isRowHiglighted?: (record: RecordType) => boolean;
    isRowWarning?: (record: RecordType) => boolean;
    isRowError?: (record: RecordType) => boolean;
    isRowSuccess?: (record: RecordType) => boolean;
    isRowSelected?: (record: RecordType) => boolean;
    isRowLocked?: (record: RecordType) => boolean;
    isSelectionDisabled?: (record: RecordType) => boolean;
    rowSelectType?: 'checkbox' | 'radio';
    isLoading?: boolean;
    loadingRowsCount?: number;
    hideWhenEmpty?: boolean;
    titleSkeletonWidth?: number;
    title?: string | undefined;
    emptyDescription?: EmptyProps['description'];
    emptyImage?: EmptyProps['image'];
    columnWidthGrow?: boolean;
    altTitle?: boolean;
    alt?: boolean; // alternative style with smaller grey rows
    noWarningIcon?: (record: RecordType) => boolean;
    pagination?: TableProps<RecordType>['pagination'];
    focusable?: boolean; // useful when rendering a List within a List cell footer
    size?: 'small' | 'middle' | 'large';
}

function List<RecordType extends Record<string, any>>({
    data,
    className,
    columns,
    rowKey,
    onRowEnterPress,
    onRowClick,
    onRowFocus,
    onRowBlur,
    renderCellFooter,
    renderCellAddonRight,
    headerInCell,
    disableArrowNav,
    arrowNavScope,
    isRowHiglighted,
    isRowWarning,
    isRowError,
    isRowSuccess,
    isRowSelected,
    isRowLocked,
    isSelectionDisabled,
    rowSelectType = 'radio',
    isLoading,
    loadingRowsCount = 4,
    hideWhenEmpty,
    titleSkeletonWidth,
    title,
    emptyDescription,
    emptyImage,
    columnWidthGrow = true,
    altTitle,
    alt,
    noWarningIcon,
    pagination,
    focusable = true,
    size = 'middle',
    ...props
}: ListProps<RecordType>) {
    const hasSelectLogic = typeof isRowSelected === 'function';
    const hasCheckboxSelectLogic = hasSelectLogic && rowSelectType === 'checkbox';
    const hasRadioSelectLogic = hasSelectLogic && rowSelectType === 'radio';
    const hasEnterLogic = typeof onRowEnterPress === 'function';
    const getColumnKey = useCallback((column: ListColumn<RecordType>, columnIndex: number) => {
        return `${(column.dataIndex as string | number) ?? column.key ?? columnIndex}`;
    }, []);
    const titleRender = title ? (
        <>
            {altTitle ? (
                <Typography.Title level={3} className="green-title">
                    {title}
                </Typography.Title>
            ) : (
                <TitleBlack>{title}</TitleBlack>
            )}
        </>
    ) : null;
    const wrapperClassNames = classNames(
        'list-wrapper',
        !columnWidthGrow && 'no-column-grow',
        !focusable && 'no-focus'
    );
    const basicListClassNames = classNames('list', headerInCell && 'list-header-in-cell', alt && 'list-alt', className);

    return (
        <div className={wrapperClassNames} {...props}>
            {title &&
                (hideWhenEmpty ? (
                    <>
                        {isLoading && <Skeleton paragraph={false} title={{ width: titleSkeletonWidth ?? 150 }} />}
                        {!isLoading && !!data?.length && titleRender}
                    </>
                ) : (
                    titleRender
                ))}
            {isLoading && (
                <BasicList className={basicListClassNames}>
                    {!headerInCell && (
                        <li
                            className={classNames(
                                'list-row',
                                'list-row-column',
                                hasEnterLogic && !hasCheckboxSelectLogic && 'list-row-column-enter',
                                hasRadioSelectLogic && 'list-row-column-select-radio',
                                hasCheckboxSelectLogic && 'list-row-column-select-checkbox'
                            )}
                            style={{ marginBottom: '1rem' }}
                        >
                            {columns.map((column, columnIndex) => (
                                <div key={columnIndex} style={{ ...column.style, flex: column.flex }}>
                                    <Skeleton.Button />
                                </div>
                            ))}
                        </li>
                    )}
                    {Array.from({ length: loadingRowsCount }, (_, index) => (
                        <li key={index}>
                            <Skeleton paragraph={false} title active />
                            {typeof renderCellFooter === 'function' && <Skeleton paragraph={false} title active />}
                        </li>
                    ))}
                </BasicList>
            )}
            {!isLoading && !data?.length && !hideWhenEmpty && (
                <Empty image={emptyImage ?? Empty.PRESENTED_IMAGE_SIMPLE} description={emptyDescription} />
            )}
            {!isLoading && !!data?.length && (
                <BasicList className={basicListClassNames}>
                    {!headerInCell && (
                        <li
                            className={classNames(
                                'list-row',
                                'list-row-column',
                                hasEnterLogic && !hasCheckboxSelectLogic && 'list-row-column-enter',
                                hasRadioSelectLogic && 'list-row-column-select-radio',
                                hasCheckboxSelectLogic && 'list-row-column-select-checkbox'
                            )}
                        >
                            {columns.map((column, columnIndex) => (
                                <div
                                    className="list-column"
                                    key={getColumnKey(column, columnIndex)}
                                    style={{
                                        ...column.style,
                                        flex: column.flex,
                                    }}
                                >
                                    {column.title}
                                </div>
                            ))}
                        </li>
                    )}
                    {data.map((record, recordIndex) => (
                        <ListRow<RecordType>
                            record={record}
                            columns={columns}
                            index={recordIndex}
                            isHiglighted={typeof isRowHiglighted === 'function' && isRowHiglighted(record)}
                            isWarning={typeof isRowWarning === 'function' && isRowWarning(record)}
                            isError={typeof isRowError === 'function' && isRowError(record)}
                            isSuccess={typeof isRowSuccess === 'function' && isRowSuccess(record)}
                            isSelected={isRowSelected}
                            selectType={rowSelectType}
                            isSelectionDisabled={
                                typeof isSelectionDisabled === 'function' && isSelectionDisabled(record)
                            }
                            isLocked={typeof isRowLocked === 'function' && isRowLocked(record)}
                            key={rowKey(record, recordIndex)}
                            renderCellFooter={renderCellFooter}
                            renderCellAddonRight={renderCellAddonRight}
                            onRowEnterPress={onRowEnterPress}
                            onRowClick={onRowClick}
                            onRowFocus={onRowFocus}
                            onRowBlur={onRowBlur}
                            headerInCell={headerInCell}
                            disableArrowNav={disableArrowNav}
                            arrowNavScope={arrowNavScope}
                            noWarningIcon={noWarningIcon}
                            focusable={focusable}
                            size={size}
                        />
                    ))}
                </BasicList>
            )}
            {!!pagination && !!data?.length && <Pagination showSizeChanger={false} hideOnSinglePage {...pagination} />}
        </div>
    );
}

export default List;
