import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 52 48">
        <path
            fill="currentColor"
            fillRule="nonzero"
            d="M39.014 0c-.915 0-1.663.68-1.736 1.543l-.005.138v2.442H14.727V1.69l-.004-.129C14.66.686 13.903 0 12.986 0c-.915 0-1.663.68-1.736 1.543l-.006.138v2.442H6.136l-.219.004C2.699 4.235.117 6.749.004 9.846L0 10.058V42.06l.004.212c.111 3.112 2.707 5.614 5.909 5.723l.22.004h39.73l.22-.004c3.218-.108 5.8-2.622 5.913-5.719l.004-.212V10.062l-.004-.212c-.111-3.112-2.707-5.614-5.909-5.723l-.22-.004h-5.111V1.69l-.005-.129C40.688.686 39.931 0 39.014 0Zm9.503 21.85v20.223l-.006.161c-.087 1.294-1.158 2.319-2.477 2.399l-.166.005H6.125l-.166-.006c-1.33-.084-2.388-1.121-2.47-2.405l-.006-.162V21.851h45.034Zm-13.383 4.606a1.787 1.787 0 0 0-2.347-.155l-.12.102-8.744 8.1-4.214-4.082-.112-.1a1.788 1.788 0 0 0-2.362.094c-.64.62-.675 1.6-.106 2.26l.106.113 5.42 5.25.124.11c.315.256.71.392 1.117.392.39 0 .769-.126 1.078-.361l.127-.108 9.972-9.236.106-.106c.562-.612.58-1.524.06-2.158l-.105-.115ZM6.132 7.486h5.112v4.907l.005.128c.063.876.82 1.561 1.737 1.561.915 0 1.663-.68 1.736-1.543l.005-.138V7.485h22.545v4.908l.005.128c.064.876.82 1.561 1.737 1.561.915 0 1.663-.68 1.736-1.543l.006-.138V7.485h5.12l.165.006c1.33.084 2.388 1.122 2.47 2.405l.006.162v8.43H3.483V10.05l.006-.161C3.576 8.595 4.647 7.57 5.966 7.49l.166-.005Z"
        />
    </svg>
);

const IconCalendar: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-calendar${props.className ? ` ${props.className}` : ''}`} />
);

export default IconCalendar;
