import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Descriptions, Divider, Spin } from 'antd';

import {
    clientRegularizationDetails,
    getClientRegularizationStateById,
    getParcelRegularizationListState,
    parcelRegularizationList,
} from '../../store/actions/controlRegularization';
import { ClientRegularizationStatus, ParcelRegularisationStatus, ParcelRegularization } from '../../store/api/apiTypes';

import { getRoute, RoutePathName } from '../../routes';
import { useActions, useIsMobile } from '../../hooks';
import { formatNumber } from '../../helpers/i18n';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import FixedFooter from '../../components/FixedFooter';
import List, { ListColumn } from '../../components/list/List';
import QuantityCard from '../../components/QuantityCard';
import ButtonGrey from '../../components/ButtonGrey';
import ControlRegularizeScanModal from './ControlRegularizeScanModal';

const ControlRegularizePallet: FC = () => {
    const isMobile = useIsMobile();
    const { loadingOrderId, customerOrderId, clientRegularizationId } =
        useParams<{ loadingOrderId: string; customerOrderId: string; clientRegularizationId: string }>();
    const [isActionModalVisible, setIsScanModalVisible] = useState(false);
    const [fetchClientRegularization, fetchParcelRegularizations] = useActions([
        clientRegularizationDetails.trigger,
        parcelRegularizationList.trigger,
    ]);
    const clientRegularizationState = useSelector(
        getClientRegularizationStateById(parseInt(clientRegularizationId, 10))
    );
    const parcelRegularizationListState = useSelector(getParcelRegularizationListState);
    const columns: Array<ListColumn<ParcelRegularization>> = [
        {
            dataIndex: 'parcel.reference',
            title: 'Référence colis',
            flex: '1 1 35%',
        },
        {
            key: 'quantity',
            title: 'Quantité retirée',
            flex: '1 1 35%',
            render: (record) => `${record.quantityRemoved ?? 0}/${record.quantity ?? 0}`,
        },
    ];
    const quantityRemoved = clientRegularizationState.data?.quantityRemoved ?? 0;
    const quantityToRemove = clientRegularizationState.data?.quantity ?? 0;
    const onCloseScanModal = useCallback(() => {
        setIsScanModalVisible(false);
    }, []);
    const onRefresh = useCallback(() => {
        fetchClientRegularization({ clientRegularizationId });
        fetchParcelRegularizations({ clientRegularizationId });
    }, [clientRegularizationId, fetchParcelRegularizations, fetchClientRegularization]);

    useEffect(() => {
        if (clientRegularizationId) {
            fetchClientRegularization({ clientRegularizationId });
            fetchParcelRegularizations({ clientRegularizationId });
        }
    }, [fetchClientRegularization, fetchParcelRegularizations, clientRegularizationId]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Détail palette" />
            <Header
                title="Détail palette"
                backRoute={getRoute(RoutePathName.preparationControlRegularize, {
                    loadingOrderId,
                    customerOrderId,
                })}
                enableHomeButton
            />
            <PageHeader>
                <Spin spinning={clientRegularizationState.loading}>
                    <Descriptions column={5} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="Référence palette" span={2}>
                            {clientRegularizationState.data?.pallet?.reference}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>
            <QuantityCard
                value={
                    clientRegularizationState.data?.status !== ClientRegularizationStatus.toBeProcessed
                        ? `${formatNumber(quantityRemoved)}/${formatNumber(quantityToRemove)}`
                        : formatNumber(quantityToRemove)
                }
                isComplete={
                    clientRegularizationState.data?.status === ClientRegularizationStatus.completed
                        ? quantityRemoved === quantityToRemove
                        : false
                }
                label={
                    clientRegularizationState.data?.status !== ClientRegularizationStatus.toBeProcessed
                        ? `Colis retiré${quantityRemoved !== 1 ? 's' : ''}`
                        : 'Colis à retirer'
                }
            />
            <Divider />
            <List<ParcelRegularization>
                columns={columns}
                data={parcelRegularizationListState.data?.items}
                rowKey={(record) => `${record.id}`}
                isLoading={parcelRegularizationListState.loading}
                isRowHiglighted={(record) => record.status === ParcelRegularisationStatus.completed}
                loadingRowsCount={6}
                title="Colis à retirer"
            />
            <ControlRegularizeScanModal
                visible={isActionModalVisible}
                onCancel={onCloseScanModal}
                clientRegularizationId={parseInt(clientRegularizationId, 10)}
                onFinish={onRefresh}
            />
            {clientRegularizationState.data?.status !== ClientRegularizationStatus.completed && (
                <FixedFooter>
                    <ArrowNavItem>
                        <ButtonWithShortcut
                            shortcut="enter"
                            onClick={setIsScanModalVisible.bind(null, true)}
                            size={isMobile ? 'middle' : 'large'}
                            as={ButtonGrey}
                            block
                        >
                            Scanner les colis
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </FixedFooter>
            )}
        </FixedFooter.Wrapper>
    );
};

export default ControlRegularizePallet;
