import { Form, FormItemProps } from 'antd';
import React, { FC } from 'react';

import YesNoSwitch from './YesNoSwitch';

const YesNoSwitchFormItem: FC<FormItemProps> = ({ ...props }) => (
    <Form.Item className="form-item-inline" style={{ marginBottom: '1rem' }} {...props} valuePropName="checked">
        <YesNoSwitch />
    </Form.Item>
);

export default YesNoSwitchFormItem;
