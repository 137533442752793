import CustomModal from '../../../components/CustomModal';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MovementBrokenStatus, Parcel, ReturnZoneType } from '../../../store/api/apiTypes';
import { Input, InputProps, message } from 'antd';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../../hooks';
import { detailsByScan, getParcelScanState } from '../../../store/actions/parcels';
import { useSelector } from 'react-redux';
import { ReturnPackageContent, ReturnPackageFooter } from './modalContent/ReturnPackageContent';
import { ReturnQuantityContent, ReturnQuantityFooter } from './modalContent/ReturnQuantityContent';
import ReturnPlaceZoneTypeContent from './modalContent/ReturnPlaceZoneTypeContent';
import { getRoute, RoutePathName } from '../../../routes';
import ReturnSuccessModal from './ReturnSuccessModal';
import {
    createBrokenDestruction,
    createDiscountShipping as createDiscountShippingAction,
    getCreateBrokenDestructionState,
    getCreateBrokenDiscountShippingState,
} from '../../../store/actions/movementBrokens';

type createCustomerReturnStep = 'scanPackage' | 'quantity' | 'storage';

const shortcutScope = 'ReturnCreateModal';

const ReturnCreateModal = NiceModal.create(() => {
    const modal = useModal();
    const { hide } = modal;
    const [step, setStep] = useState<createCustomerReturnStep>('scanPackage');
    const [quantity, setQuantity] = useState<number>();
    const [reference, setReference] = useState<Parcel['reference']>();
    const inputRef = useRef<Input>(null);
    const parcelScanState = useSelector(getParcelScanState);
    const createBrokenDiscountShippingState = useSelector(getCreateBrokenDiscountShippingState);
    const createBrokenDestructionState = useSelector(getCreateBrokenDestructionState);
    const [loadParcelScanDetails, createDestruction, createDiscountShipping] = useActions([
        detailsByScan.trigger,
        createBrokenDestruction.trigger,
        createDiscountShippingAction.trigger,
    ]);
    const previous = usePrevious({ parcelScanState, createBrokenDiscountShippingState, createBrokenDestructionState });
    const history = useHistory();
    const successModal = useModal(ReturnSuccessModal);
    useShortcutScope(shortcutScope);
    useArrowNavScope(shortcutScope);

    useEffect(() => {
        if (inputRef && reference) {
            inputRef.current?.setValue(reference);
        }
    }, [inputRef, reference]);

    useEffect(() => {
        if (previous?.parcelScanState.loading && !parcelScanState.loading) {
            if (parcelScanState.error) {
                message.error('Code barre / réference non reconnu');
            } else {
                setStep('quantity');
            }
        }
    }, [parcelScanState.error, parcelScanState.loading, previous?.parcelScanState.loading]);

    useEffect(() => {
        if (previous?.createBrokenDiscountShippingState.loading && !createBrokenDiscountShippingState.loading) {
            if (createBrokenDiscountShippingState.error) {
                message.error(`Erreur lors de la mise en envoi soldeur du colis`);
            } else {
                hide().then(async () => await successModal.show({ returnZoneType: ReturnZoneType.soldeur }));
            }
        }
    }, [
        previous?.createBrokenDiscountShippingState.loading,
        createBrokenDiscountShippingState.loading,
        createBrokenDiscountShippingState.error,
        hide,
        successModal,
    ]);

    useEffect(() => {
        if (previous?.createBrokenDestructionState.loading && !createBrokenDestructionState.loading) {
            if (createBrokenDestructionState.error) {
                message.error(`Erreur lors de la mise en destruction du colis`);
            } else {
                hide().then(async () => await successModal.show({ returnZoneType: ReturnZoneType.destruction }));
            }
        }
    }, [
        previous?.createBrokenDestructionState.loading,
        createBrokenDestructionState.loading,
        createBrokenDestructionState.error,
        hide,
        successModal,
    ]);

    useEffect(() => {
        if (step === 'scanPackage') {
            setReference(undefined);
        }
    }, [step]);

    const onAdd = useCallback(() => {
        if (step === 'scanPackage') {
            loadParcelScanDetails({ reference });
        } else if (step === 'quantity') {
            if (!quantity || quantity === 0) {
                message.error('Veuillez saisir une quantité de retour');
            } else {
                setStep('storage');
            }
        }
    }, [step, loadParcelScanDetails, reference, quantity]);

    const onClose = useCallback(() => {
        if (step === 'scanPackage') {
            inputRef.current?.setValue('');
            hide();
        } else if (step === 'quantity') {
            setStep('scanPackage');
        } else if (step === 'storage') {
            setStep('quantity');
        }
    }, [step, hide]);

    const title = useMemo(() => {
        if (step === 'scanPackage') {
            return 'Colis en retour';
        } else if (step === 'quantity') {
            return 'Quantité en retour';
        } else if (step === 'storage') {
            return 'Rangement';
        } else {
            return undefined;
        }
    }, [step]);
    const onChangeField: InputProps['onChange'] = (e) => {
        setReference(e.target.value.toUpperCase());
    };
    const handleReturnZoneTypeClick = (returnZoneType: ReturnZoneType) => {
        if (quantity && parcelScanState.data?.id) {
            if (returnZoneType === ReturnZoneType.picking || returnZoneType === ReturnZoneType.mass) {
                hide().then(() =>
                    history.push({
                        pathname: getRoute(RoutePathName.movementCustomerReturnPalletList),
                        state: {
                            returnZoneType: returnZoneType,
                            quantity,
                            parcelId: parcelScanState.data?.id,
                        },
                    })
                );
            } else if (returnZoneType === ReturnZoneType.destruction) {
                createDestruction({
                    quantity,
                    parcelId: parcelScanState.data?.id.toString(),
                    status: MovementBrokenStatus.completed,
                });
            } else if (returnZoneType === ReturnZoneType.soldeur) {
                createDiscountShipping({
                    quantity,
                    parcelId: parcelScanState.data.id.toString(),
                    status: MovementBrokenStatus.toBeProcessed,
                });
            }
        }
    };

    const footerMap = {
        scanPackage: (
            <ReturnPackageFooter
                isValidateDisabled={!reference}
                loading={parcelScanState.loading}
                onValid={onAdd}
                onClose={onClose}
                shortcutScope={shortcutScope}
            />
        ),
        quantity: (
            <ReturnQuantityFooter
                loading={parcelScanState.loading}
                onValid={onAdd}
                onClose={onClose}
                shortcutScope={shortcutScope}
            />
        ),
        storage: (
            <ReturnPlaceZoneTypeContent
                onClose={onClose}
                handleReturnZoneTypeClick={handleReturnZoneTypeClick}
                shortcutScope={shortcutScope}
            />
        ),
    };

    const footer = footerMap[step];

    const contentMap = {
        scanPackage: (
            <ReturnPackageContent
                inputRef={inputRef}
                shortcutScope={shortcutScope}
                onChangeField={onChangeField}
                isScanDisabled={!modal.visible}
                onScanSuccess={loadParcelScanDetails}
            />
        ),
        quantity: (
            <ReturnQuantityContent
                label={parcelScanState.data?.label}
                quantity={quantity}
                reference={parcelScanState.data?.reference}
                setQuantity={setQuantity}
                hasNarrowNav={modal.visible}
            />
        ),
        storage: undefined,
    };

    const content = contentMap[step];

    return (
        <CustomModal {...antdModal(modal)} footer={footer} title={title} width={368} altTitle>
            {content}
        </CustomModal>
    );
});

export default ReturnCreateModal;
