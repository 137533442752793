import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { Pallet, PlaceZoneType, PalletQuality, RoleName, WMSMode, ResupplyOrderType } from '../../store/api/apiTypes';
import { details, getPalletDetailsStateById } from '../../store/actions/pallets';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, useShortcutScope } from '../../hooks';
import SuccessModal from '../../components/SuccessModal';
import PrintModal from '../../components/PrintModal';
import SearchPalletEditParcelModal from './SearchPalletEditParcelModal';
import SearchPalletEditQuantityModal from './SearchPalletEditQuantityModal';
import SearchPalletBlockUnblockModal from './SearchPalletBlockUnblockModal';
import { getUser } from '../../store/actions/auth';
import { hasRole } from '../../helpers/security';

const shortcutScope = 'SearchPalletActionsModal';

interface SearchPalletActionsModalProps extends CustomModalProps {
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    palletId: Pallet['id'];
}

const SearchPalletActionsModal: FC<SearchPalletActionsModalProps> = ({ visible, onCancel, setIsVisible, palletId }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const isMounted = useIsMounted();
    const palletState = useSelector(getPalletDetailsStateById(palletId));
    const fetchPallet = useActions(details.trigger);
    const [successMessage, setSuccessMessage] = useState<string>();
    const [isAfterEditSuccess, setIsAfterEditSuccess] = useState(false);
    const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
    const [isEditParcelModalVisible, setIsEditParcelModalVisible] = useState(false);
    const [isEditQuantityModalVisible, setIsEditQuantityModalVisible] = useState(false);
    const [isBlockUnblockModalVisible, setIsBlockUnblockModalVisible] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const user = useSelector(getUser);
    const isGestionnaire = hasRole(user, [RoleName.gsriruRole, RoleName.admin]);
    const isMass = palletState.data?.currentPlace?.zoneType === PlaceZoneType.mass;
    const isReservedForDirect = palletState.data?.resupply?.type === ResupplyOrderType.direct;
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const onCloseSuccessModal = () => {
        setIsSuccessModalVisible(false);
        setIsAfterEditSuccess(false);
    };
    const onClickEditParcel = () => {
        setIsVisible(false);
        setIsEditParcelModalVisible(true);
    };
    const onClickEditQuantity = () => {
        setIsVisible(false);
        setIsEditQuantityModalVisible(true);
    };
    const onClickBlockUnblock = () => {
        setIsVisible(false);
        setIsBlockUnblockModalVisible(true);
    };
    const onClickPrint = () => {
        setIsVisible(false);
        setIsPrintModalVisible(true);
    };
    const onClosePrintModal = () => {
        setIsPrintModalVisible(false);
        fetchPallet({ palletId });
        if (isAfterEditSuccess) {
            setIsSuccessModalVisible(true);
            window.setTimeout(() => {
                if (isMounted) {
                    setIsSuccessModalVisible(false);
                }
            }, 2000);
        }
    };
    const onEditParcelSuccess = () => {
        fetchPallet({ palletId });
        setIsAfterEditSuccess(true);
        setSuccessMessage('Référence modifiée avec succès');
        setIsPrintModalVisible(true);
    };
    const onCloseEditParcelModal = () => {
        setIsEditParcelModalVisible(false);
    };
    const onEditQuantitySuccess = () => {
        fetchPallet({ palletId });
        setSuccessMessage('Quantité modifiée avec succès');
        setIsSuccessModalVisible(true);
        window.setTimeout(() => {
            if (isMounted) {
                setIsSuccessModalVisible(false);
            }
        }, 2000);
    };
    const onBlockUnblockSuccess = () => {
        setSuccessMessage(
            palletState.data?.quality !== PalletQuality.noConform
                ? 'La palette a été bloquée en défaut qualité'
                : 'La palette a été débloquée'
        );
        fetchPallet({ palletId });
        setIsSuccessModalVisible(true);
        window.setTimeout(() => {
            if (isMounted) {
                setIsSuccessModalVisible(false);
            }
        }, 2000);
    };
    const onCloseEditQuantityModal = () => {
        setIsEditQuantityModalVisible(false);
    };
    const onCloseBlockUnblockModal = () => {
        setIsBlockUnblockModalVisible(false);
    };
    const actions = [
        {
            onClick: onClickPrint,
            label: 'Impression étiquette',
        },
        isMass &&
            !isReservedForDirect && {
                onClick: onClickEditParcel,
                label: 'Modifier la référence',
            },
        isMass &&
            !isReservedForDirect && {
                onClick: onClickEditQuantity,
                label: 'Modifier la quantité',
            },
        isGestionnaire &&
            !isReservedForDirect && {
                onClick: onClickBlockUnblock,
                label:
                    palletState.data?.quality === PalletQuality.noConform
                        ? 'Déblocage défaut qualité'
                        : 'Blocage défaut qualité',
            },
    ].filter(Boolean) as Array<{ onClick: () => void; label: string }>;

    return (
        <>
            <CustomModal
                footer={
                    <>
                        {actions.map(({ onClick, label }, index) => (
                            <ArrowNavItem scope={shortcutScope} key={index}>
                                <ButtonWithShortcut
                                    shortcut={`${index + 1}`}
                                    type="primary"
                                    shortcutScope={shortcutScope}
                                    onClick={onClick}
                                >
                                    {label}
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        ))}
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Fermer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                }
                visible={visible}
                onCancel={onCancel}
                title="Actions"
                width={368}
                keyboard={false}
                altTitle
            />
            <PrintModal
                visible={isPrintModalVisible}
                onCancel={onClosePrintModal}
                palletId={palletId}
                cancelText="Fermer"
                mode={WMSMode.stockResearch}
            />
            <SearchPalletEditParcelModal
                visible={isEditParcelModalVisible}
                onCancel={onCloseEditParcelModal}
                onSuccess={onEditParcelSuccess}
                palletId={palletId}
            />
            <SearchPalletEditQuantityModal
                visible={isEditQuantityModalVisible}
                onCancel={onCloseEditQuantityModal}
                onSuccess={onEditQuantitySuccess}
                palletId={palletId}
            />
            <SearchPalletBlockUnblockModal
                visible={isBlockUnblockModalVisible}
                onCancel={onCloseBlockUnblockModal}
                onSuccess={onBlockUnblockSuccess}
                palletId={palletId}
            />
            <SuccessModal
                visible={isSuccessModalVisible}
                onCancel={onCloseSuccessModal}
                footer={null}
                message={successMessage}
                width={256}
            />
        </>
    );
};

export default SearchPalletActionsModal;
