import { Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getParcelScanState } from '../../store/actions/parcels';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonGrey from '../../components/ButtonGrey';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import FixedFooter from '../../components/FixedFooter';
import QuantityInput from '../../components/QuantityInput';
import { useIsDesktop, useIsMobile } from '../../hooks';
import BasicList from '../../components/BasicList';
import { show } from '@ebay/nice-modal-react';
import ClosePalletModal from './ClosePalletModal';
import { useLocation } from 'react-router-dom';
import { Pallet } from '../../store/api/apiTypes';

const ReceptionPalletCreate: FC = () => {
    const isMobile = useIsMobile();
    const isDesktop = useIsDesktop();
    const parcelScanState = useSelector(getParcelScanState);
    const { pallet } = useLocation<{ pallet?: Pallet }>().state || {};
    const [quantity, setQuantity] = useState(pallet?.quantity ?? 0);

    const onChangeQuantity = (value: number) => {
        setQuantity(value);
    };

    useEffect(() => {
        if (pallet) {
            show('closePalletModal');
        }
    }, [pallet]);

    return (
        <>
            <Spin spinning={parcelScanState.loading}>
                <QuantityInput onChange={onChangeQuantity} value={quantity} disableEnterShortCut />
            </Spin>
            {quantity > 0 ? (
                <FixedFooter>
                    <BasicList inline={isDesktop} inlineStretch={isDesktop}>
                        <li>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    shortcut="enter"
                                    onClick={async () => await show('closePalletModal')}
                                    size={isMobile ? 'middle' : 'large'}
                                    as={ButtonGrey}
                                    block
                                >
                                    Clôturer la palette
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </li>
                    </BasicList>
                </FixedFooter>
            ) : undefined}
            <ClosePalletModal quantity={quantity} id={'closePalletModal'} />
        </>
    );
};

export default ReceptionPalletCreate;
