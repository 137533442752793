import { MovementBroken, MovementBrokenStatus, Place, Pallet, Parcel } from './apiTypes';
import api from './_client';

export interface MovementBrokenCreatePayload {
    placeId?: Place['id'];
    palletId?: Pallet['id'];
    status: MovementBrokenStatus;
    quantity: number;
}

export const create = async (payload: MovementBrokenCreatePayload) => await api.post(`/movementBrokens`, payload);

export interface MovementBrokensListPayload {
    status: MovementBrokenStatus;
}

export const list = async (payload: MovementBrokensListPayload) =>
    await api.get(`/movementBrokens`, { params: payload });

export interface MovementBrokenIdPayload {
    id: MovementBroken['id'];
}
export const details = async ({ id }: MovementBrokenIdPayload) => await api.get(`/movementBrokens/${id}`);

export const del = async (payload: MovementBrokenIdPayload) => await api.delete(`/movementBrokens/${payload.id}`);

export interface MovementBrokenUpdatePayload {
    id: MovementBroken['id'];
    status?: MovementBrokenStatus;
    quantity?: number;
    operatorId?: number | null;
}

export const update = async ({ id, ...payload }: MovementBrokenUpdatePayload) =>
    await api.put(`/movementBrokens/${id}`, payload);

export interface CreateRepairPayload {
    movementBrokenId: MovementBroken['id'];
    quantity: number;
    status: MovementBrokenStatus.toBeProcessed;
}

export const createRepair = async (payload: CreateRepairPayload) => await api.post(`/movementRepairs`, payload);

export interface CreateDestructionPayload {
    movementBrokenId?: MovementBroken['id'];
    parcelId?: Parcel['id'];
    quantity: number;
    status: MovementBrokenStatus.completed;
}

export const createDestruction = async (payload: CreateDestructionPayload) =>
    await api.post(`/movementDestructions`, payload);

export interface createDiscountShippingPayload {
    movementBrokenId?: MovementBroken['id'];
    parcelId?: Parcel['id'];
    quantity: number;
    status: MovementBrokenStatus.toBeProcessed;
}

export const createDiscountShipping = async (payload: createDiscountShippingPayload) =>
    await api.post(`/movementDiscountShippings`, payload);
