import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Divider, Form, Input, message, Radio, Select, SelectProps, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { FormProps, useForm } from 'antd/lib/form/Form';

import {
    Place,
    PlaceAlveole,
    PlaceLane,
    PlaceSector,
    PlaceStore,
    PlaceStoreType,
    PlaceZoneType,
} from '../../store/api/apiTypes';
import {
    getPlacesListState,
    getPlacesListDesktopState,
    list as placesList,
    listDesktop as searchList,
} from '../../store/actions/places';
import { getPlaceSectorsListState, list as placeSectorsList } from '../../store/actions/placeSectors';
import { getPlaceLanesListState, list as placeLanesList } from '../../store/actions/placeLanes';
import { getPlaceAlveolesListState, list as placeAlveolesList } from '../../store/actions/placeAlveoles';

import { getRoute, RoutePathName } from '../../routes';
import { useActions, useIsMobile, usePrevious } from '../../hooks';
import { stripUndefinedKeysFromObject } from '../../helpers';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Seo from '../../components/Seo';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import FixedFooter from '../../components/FixedFooter';
import ButtonGrey from '../../components/ButtonGrey';
import PlaceStoreSelect from '../../components/PlaceStoreSelect';
import PlaceFormModal from './PlaceFormModal';

const PlaceManagementSearch: FC = () => {
    const [form] = useForm();
    const isMobile = useIsMobile();
    const history = useHistory();
    const [isFormModalVisible, setIsFormModalVisible] = useState(false);
    const [placeToEdit, setPlaceToEdit] = useState<Place>();
    const [fetchPlaces, searchPlaces, listPlaceSectors, listPlaceLanes, listPlaceAlveoles] = useActions([
        placesList.trigger,
        searchList.trigger,
        placeSectorsList.trigger,
        placeLanesList.trigger,
        placeAlveolesList.trigger,
    ]);
    const placeSectorsState = useSelector(getPlaceSectorsListState);
    const placeLanesState = useSelector(getPlaceLanesListState);
    const placeAlveolesState = useSelector(getPlaceAlveolesListState);
    const placesListState = useSelector(getPlacesListState);
    const placesSearchState = useSelector(getPlacesListDesktopState);
    const initialValues = {
        zoneType: PlaceZoneType.mass,
    };
    const previous = usePrevious({ placesSearchState });
    const onPlaceSearch: SelectProps<Place['id']>['onSearch'] = (value) => {
        fetchPlaces(
            {
                zoneType: PlaceZoneType.preparation,
                ...(value ? { search: value } : {}),
            },
            { throttling: 300 }
        );
    };
    const onFormValuesChange: FormProps['onValuesChange'] = (
        changedValues: Record<string, string | undefined>,
        values
    ) => {
        const changedKey = Object.keys(changedValues)[0];
        const changedValue = changedValues[changedKey];

        if (changedKey === 'zoneType') {
            form.resetFields(['sector', 'lane', 'alveole', 'locker', 'level']);
            if (values.zoneType === PlaceZoneType.mass) {
                listPlaceSectors({
                    zoneType: [PlaceZoneType.mass, PlaceZoneType.picking],
                    placeStoreId: values.placeStore?.id,
                });
            } else if (values.zoneType === PlaceZoneType.preparation) {
                fetchPlaces({ zoneType: PlaceZoneType.preparation });
            }
        }

        if (['placeStore', 'sector', 'lane'].includes(changedKey)) {
            switch (changedKey) {
                case 'placeStore':
                    form.resetFields(['sector', 'lane', 'alveole']);
                    listPlaceSectors({
                        zoneType: [PlaceZoneType.mass, PlaceZoneType.picking],
                        placeStoreId: (changedValue as PlaceStore | undefined)?.id,
                    });
                    break;

                case 'sector':
                    form.resetFields(['lane', 'alveole']);
                    listPlaceLanes({
                        placeSectorId: placeSectorsState.data?.items?.find(
                            (placeSector) => placeSector.sector === changedValue
                        )?.id,
                    });
                    break;

                case 'lane':
                    form.resetFields(['alveole']);
                    listPlaceAlveoles({
                        placeLaneId: placeLanesState.data?.items?.find((placeLane) => placeLane.lane === changedValue)
                            ?.id,
                    });
                    break;
            }
        }

        if (['lane', 'alveole', 'level'].includes(changedKey)) {
            form.resetFields(['locker']);
        }

        if (changedKey === 'locker') {
            form.resetFields(['lane', 'alveole', 'level']);
        }
    };
    const onSubmit: FormProps['onFinish'] = (values) => {
        if (values.zoneType === PlaceZoneType.preparation && values.placeId) {
            setPlaceToEdit(placesListState.data?.items?.find((p) => p.id === values.placeId));
            setIsFormModalVisible(true);
        } else {
            const params: Record<string, string | number | PlaceZoneType[]> = {
                store: values.placeStore?.id,
                zoneType:
                    values.zoneType === PlaceZoneType.mass
                        ? [PlaceZoneType.mass, PlaceZoneType.picking]
                        : PlaceZoneType.preparation,
            };

            if (values.locker) {
                params.locker = values.locker;
            } else {
                params.sector = values.sector;
                params.lane = values.lane;
                params.alveole = values.alveole;
                params.level = values.level ? values.level : undefined;
            }

            stripUndefinedKeysFromObject(params);

            searchPlaces(params);
        }
    };
    const onEditDeleteSuccess = () => {
        if (form.getFieldValue('zoneType') === PlaceZoneType.preparation) {
            fetchPlaces({ zoneType: PlaceZoneType.preparation });
            form.resetFields(['placeId']);
        }
    };

    useEffect(() => {
        if (previous?.placesSearchState.loading && !placesSearchState.loading) {
            if (placesSearchState.error) {
                message.error("Une erreur est survenue pendant la recherche d'emplacement");
            } else {
                if (placesSearchState.data?.totalCount === 1) {
                    setPlaceToEdit(placesSearchState.data?.items?.[0]);
                    setIsFormModalVisible(true);
                } else if ((placesSearchState.data?.totalCount ?? 0) > 1) {
                    const formValues = form.getFieldsValue();

                    formValues.locker = formValues.locker ? formValues.locker : undefined;
                    formValues.level = formValues.level ? formValues.level : undefined;

                    stripUndefinedKeysFromObject(formValues);

                    formValues.placeStoreId = formValues.placeStore?.id;
                    formValues.placeStoreLabel = formValues.placeStore?.name;
                    delete formValues.placeStore;

                    const params = new URLSearchParams(formValues);

                    if (formValues.zoneType === PlaceZoneType.mass) {
                        params.append('zoneType', PlaceZoneType.picking);
                    }

                    history.push({
                        pathname: getRoute(RoutePathName.placeManagementSearchResults),
                        search: params.toString(),
                    });
                } else {
                    message.error('Aucun emplacement trouvé');
                }
            }
        }
    }, [
        previous?.placesSearchState.loading,
        placesSearchState.loading,
        placesSearchState.error,
        history,
        placesSearchState.data?.totalCount,
        form,
        placesSearchState.data?.items,
    ]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Rechercher un emplacement" />
            <Header title="Rechercher un emplacement" backRoute={getRoute(RoutePathName.placeManagementMenu)} />
            <PageHeader className="page-header-small">
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onSubmit}
                    onValuesChange={onFormValuesChange}
                    initialValues={initialValues}
                >
                    <Form.Item label="Site" name="placeStore">
                        <PlaceStoreSelect placeStoreType={PlaceStoreType.site} allowClear={false} withArrowNav />
                    </Form.Item>
                    <Form.Item label="Type d'emplacement" name="zoneType">
                        <ArrowNavItem>
                            <Radio.Group buttonStyle="solid" className="stretch small-text">
                                <Radio.Button value={PlaceZoneType.mass}>Masse / Picking</Radio.Button>
                                <Radio.Button value={PlaceZoneType.preparation}>Préparation</Radio.Button>
                            </Radio.Group>
                        </ArrowNavItem>
                    </Form.Item>
                    <Form.Item shouldUpdate noStyle>
                        {({ getFieldValue }) => (
                            <>
                                {getFieldValue(['zoneType']) === PlaceZoneType.mass && (
                                    <>
                                        <Form.Item label="Secteur" name="sector">
                                            <ArrowNavItem>
                                                <Select<PlaceSector['sector']>
                                                    placeholder="Sélectionner un secteur"
                                                    filterOption={false}
                                                    disabled={!getFieldValue('placeStore')}
                                                    loading={placeSectorsState.loading}
                                                    allowClear
                                                    showArrow
                                                >
                                                    {placeSectorsState.data?.items?.map((option) => (
                                                        <Select.Option value={option.sector} key={option.id}>
                                                            {option.sector}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </ArrowNavItem>
                                        </Form.Item>
                                        <Card className="grey-card">
                                            <Form.Item label="Allée" name="lane">
                                                <ArrowNavItem>
                                                    <Select<PlaceLane['lane']>
                                                        placeholder="Sélectionner une allée"
                                                        filterOption={false}
                                                        disabled={!getFieldValue('sector')}
                                                        loading={placeLanesState.loading}
                                                        allowClear
                                                        showArrow
                                                    >
                                                        {placeLanesState.data?.items?.map((option) => (
                                                            <Select.Option value={option.lane} key={option.id}>
                                                                {option.lane}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </ArrowNavItem>
                                            </Form.Item>
                                            <Form.Item label="Alvéole" name="alveole">
                                                <ArrowNavItem>
                                                    <Select<PlaceAlveole['alveole']>
                                                        placeholder="Sélectionner une alvéole"
                                                        filterOption={false}
                                                        disabled={!getFieldValue('lane')}
                                                        loading={placeAlveolesState.loading}
                                                        allowClear
                                                        showArrow
                                                    >
                                                        {placeAlveolesState.data?.items?.map((option) => (
                                                            <Select.Option value={option.alveole} key={option.id}>
                                                                {option.alveole}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </ArrowNavItem>
                                            </Form.Item>
                                            <Form.Item label="Niveau" name="level">
                                                <ArrowNavItem>
                                                    <Input placeholder="Saisir un niveau" />
                                                </ArrowNavItem>
                                            </Form.Item>
                                            <Divider>
                                                <Typography.Text
                                                    className="text-primary"
                                                    style={{ fontSize: '0.9375rem' }}
                                                    strong
                                                >
                                                    OU
                                                </Typography.Text>
                                            </Divider>
                                            <Form.Item label="Casier" name="locker">
                                                <ArrowNavItem>
                                                    <Input placeholder="Saisir un casier" />
                                                </ArrowNavItem>
                                            </Form.Item>
                                        </Card>
                                    </>
                                )}
                                {getFieldValue(['zoneType']) === PlaceZoneType.preparation && (
                                    <Form.Item label="Emplacement de préparation" name="placeId">
                                        <ArrowNavItem>
                                            <Select
                                                style={{ width: '100%' }}
                                                filterOption={false}
                                                onSearch={onPlaceSearch}
                                                placeholder="Choisir ou chercher un emplacement"
                                                loading={placesListState.loading}
                                                allowClear
                                                showSearch
                                            >
                                                {placesListState.data?.items.map((place) => (
                                                    <Select.Option value={place.id} key={place.id}>
                                                        {place.locker}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </ArrowNavItem>
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </Form.Item>
                    <button type="submit" style={{ display: 'none' }} />
                </Form>
            </PageHeader>
            <PlaceFormModal
                visible={isFormModalVisible}
                onCancel={setIsFormModalVisible.bind(null, false)}
                onSuccess={onEditDeleteSuccess}
                place={placeToEdit}
            />
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        shortcut="enter"
                        onClick={form.submit}
                        as={ButtonGrey}
                        size={isMobile ? 'middle' : 'large'}
                        loading={placesSearchState.loading}
                        block
                    >
                        Rechercher
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </FixedFooter.Wrapper>
    );
};

export default PlaceManagementSearch;
