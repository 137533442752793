import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 21 16">
        <path
            fill="currentColor"
            fillRule="nonzero"
            d="M9.044.27l.144.118.144.154c.422.53.429 1.246.036 1.765l-.126.144L5.06 6.545l14.44.001.17.009c.767.086 1.33.704 1.33 1.439 0 .726-.55 1.337-1.28 1.434l-.175.012h-14.5l4.163 4.073.124.135c.472.592.42 1.412-.108 1.928a1.504 1.504 0 01-1.926.14l-.147-.122L.45 9.04l-.124-.135a1.429 1.429 0 01-.219-.366l-.065-.202-.031-.164L.002 8.1 0 7.968l.009-.132.014-.1c.05-.261.164-.49.336-.687L7.115.44l.138-.12a1.514 1.514 0 011.79-.05z"
        />
    </svg>
);

const IconArrowLeft: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-arrow-left${props.className ? ` ${props.className}` : ''}`} />
);

export default IconArrowLeft;
