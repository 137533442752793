import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { list, getPlaceStoresListState } from '../../store/actions/placeStores';
import { PlaceStore } from '../../store/api/apiTypes';

import { useActions, useIsMobile } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import Header from '../../components/Header';
import List, { ListColumn } from '../../components/list/List';
import Seo from '../../components/Seo';
import FixedFooter from '../../components/FixedFooter';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import PlaceStoreFormModal from './PlaceStoreFormModal';
import { translatePlaceStoreType } from '../../helpers/i18n';

const PlaceManagementPlaceStores: FC = () => {
    const isMobile = useIsMobile();
    const [isFormModalVisible, setIsFormModalVisible] = useState(false);
    const [placeStoreToEdit, setPlaceStoreToEdit] = useState<PlaceStore>();
    const [listPlaceStores] = useActions([list.trigger]);
    const listState = useSelector(getPlaceStoresListState);
    const onClickPlaceStore = (record: PlaceStore) => {
        setPlaceStoreToEdit(record);
        setIsFormModalVisible(true);
    };
    const onClickAdd = () => {
        setPlaceStoreToEdit(undefined);
        setIsFormModalVisible(true);
    };
    const onCloseFormModal = () => {
        setIsFormModalVisible(false);
        listPlaceStores({ sort: 'updatedAt', sortOrder: 'desc' });
    };
    const columns: Array<ListColumn<PlaceStore>> = [
        {
            key: 'reference',
            title: 'Code',
            flex: '1 1 15%',
            render: (record) => record.number ?? '—',
        },
        {
            key: 'type',
            title: 'Type',
            flex: '1 1 15%',
            render: (record) => translatePlaceStoreType(record.type),
        },
        {
            key: 'label',
            title: 'Nom',
            flex: '1 1 70%',
            render: (record) => record.name ?? '—',
        },
    ];

    useEffect(() => {
        listPlaceStores({ sort: 'updatedAt', sortOrder: 'desc' });
    }, [listPlaceStores]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Sites / puits" />
            <Header title="Sites / puits" backRoute={getRoute(RoutePathName.placeManagementSectionsMenu)} />
            <List<PlaceStore>
                columns={columns}
                data={listState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowEnterPress={onClickPlaceStore}
                onRowClick={onClickPlaceStore}
                isLoading={listState.loading}
                loadingRowsCount={11}
                style={isMobile ? { marginTop: 24 } : undefined}
            />
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        shortcut="f1"
                        onClick={onClickAdd}
                        size={isMobile ? 'middle' : 'large'}
                        type="primary"
                        ghost
                        block
                    >
                        Ajouter un site / puits
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
            <PlaceStoreFormModal
                visible={isFormModalVisible}
                onCancel={onCloseFormModal}
                placeStore={placeStoreToEdit}
            />
        </FixedFooter.Wrapper>
    );
};

export default PlaceManagementPlaceStores;
