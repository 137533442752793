import { Form, FormProps, Input, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    control,
    controlPackage,
    controlPackages,
    getControlOrderControlPackagesState,
    getControlOrderControlPackageState,
    getControlOrderControlState,
    PackageRef,
} from '../../store/actions/preparationControl';

import { details as customerOrderDetails, getCustomerOrderStateById } from '../../store/actions/customerOrders';

import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import QuantityCard from '../../components/QuantityCard';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { getPalletPackageListState } from '../../store/actions/pallets';
import { formatNumber, getControlPackageGetError } from '../../helpers/i18n';
import { isControlPackageAlreadyStoredLocally } from '../../helpers';
import { getPreparationRemainingQuantityToControlByCustomerOrderIdAndParcelIdState } from '../../store/actions/preparations';
import { errorSound } from '../../helpers/sound';

const shortcutScope = 'ControlPalletAddPackageModal';

interface ControlPalletAddPackageModalProps extends CustomModalProps {
    onFinish: () => void;
}

const ControlPalletAddPackageModal: FC<ControlPalletAddPackageModalProps> = ({ visible, onCancel, onFinish }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const { customerOrderId: customerOrderIdParam, palletId } =
        useParams<{ customerOrderId: string; palletId: string }>();
    const customerOrderId = parseInt(customerOrderIdParam ?? '-1', 10);
    const [form] = Form.useForm();
    const [isManual, setIsManual] = useState(false);
    const [refToStore, setRefToStore] = useState<PackageRef>(); // temp data when waiting for API response
    const [barcodeToStore, setBarcodeToStore] = useState<string>(); // temp data when waiting for API response
    const [
        fetchCustomerOrder,
        fetchControlPackage,
        sendControlPackages,
        setControlAddPackageState,
        setControlLocalParcelCountByParcelId,
        resetControlAddPackageState,
    ] = useActions([
        customerOrderDetails.trigger,
        controlPackage.trigger,
        controlPackages.trigger,
        control.actions.setAddPackageData,
        control.actions.setLocalParcelCountByParcelId,
        control.actions.resetAddPackage,
    ]);
    const controlPackageState = useSelector(getControlOrderControlPackageState);
    const controlPackagesState = useSelector(getControlOrderControlPackagesState);
    const palletPackageListState = useSelector(getPalletPackageListState);
    const customerOrderState = useSelector(getCustomerOrderStateById(customerOrderId));
    const { packagesRefs, packagesBarcodes } = useSelector(getControlOrderControlState).addPackage;
    const localParcelCountByParcelId = useSelector(getControlOrderControlState).localParcelCountByParcelId;
    const remainingQuantityToControlForCurrentParcel =
        useSelector(
            getPreparationRemainingQuantityToControlByCustomerOrderIdAndParcelIdState(
                customerOrderId,
                controlPackageState.data?.id
            )
        ) - (localParcelCountByParcelId[controlPackageState.data?.id ?? -1] ?? 0);
    const totalRemainingQuantityToControl =
        (customerOrderState.data?.preparedQuantity ?? 0) -
        (customerOrderState.data?.controlledQuantity ?? 0) -
        packagesRefs.length -
        packagesBarcodes.length;
    const previous = usePrevious({ visible, controlPackageState, controlPackagesState });
    const onClose = useCallback(() => {
        onCancel?.({} as React.MouseEvent<HTMLElement, MouseEvent>);
    }, [onCancel]);
    const onSubmitManual: FormProps['onFinish'] = (values) => {
        if (
            isControlPackageAlreadyStoredLocally(packagesRefs, values) ||
            palletPackageListState.data?.items.some((p) => p.cont === values.cont)
        ) {
            message.error('Ce colis est déjà présent dans la palette');
        } else {
            setRefToStore({
                reference: values.reference,
                ...(values.cont ? { cont: values.cont } : {}),
            });
            fetchControlPackage({
                customerOrderId,
                reference: values.reference,
                ...(values.cont ? { cont: values.cont } : {}),
            });
        }
    };
    const onSubmit = () => {
        sendControlPackages({
            customerOrderId,
            barcodes: packagesBarcodes.length ? packagesBarcodes : undefined,
            references: packagesRefs.length ? packagesRefs : undefined,
            palletId,
        });
    };
    useScanner(
        shortcutScope,
        (barCode) => {
            if (totalRemainingQuantityToControl === 0) {
                message.error('Vous ne pouvez pas ajouter plus de colis pour ce contrôle');
                errorSound();
            } else {
                if (barCode.data) {
                    if (
                        packagesBarcodes.includes(barCode.data) ||
                        palletPackageListState.data?.items.some((p) => p.parcel.barcode === barCode.data)
                    ) {
                        message.error('Ce colis est déjà présent dans la palette');
                        errorSound();
                    } else {
                        setBarcodeToStore(barCode.data);
                        fetchControlPackage({
                            customerOrderId,
                            barcode: barCode.data,
                        });
                    }
                } else {
                    errorSound();
                }
            }
        },
        {
            deps: [fetchControlPackage, packagesBarcodes],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    // GET parcel response, if request fails because parcel is already on another pallet display error message;
    useEffect(() => {
        if (previous?.controlPackageState.loading && !controlPackageState.loading) {
            if (controlPackageState.error) {
                const requestError = getControlPackageGetError(controlPackageState);

                if (requestError.message) {
                    message.error(requestError.message);
                }
                errorSound();
            } else if (controlPackageState.data) {
                if (totalRemainingQuantityToControl === 0) {
                    message.error(
                        'Vous avez contrôlé tous les colis préparés, vous pouvez transférer/supprimer des colis, mais pas en ajouter',
                        5
                    );
                    errorSound();
                } else if (remainingQuantityToControlForCurrentParcel === 0) {
                    message.error(
                        'Vous avez déjà contrôlé tous les colis préparés de cette référence, veuillez contrôler une autre référence',
                        5
                    );
                    errorSound();
                } else {
                    setControlAddPackageState({
                        packagesBarcodes: barcodeToStore ? [...packagesBarcodes, barcodeToStore] : packagesBarcodes,
                        packagesRefs: refToStore ? [...packagesRefs, refToStore] : packagesRefs,
                    });
                    setControlLocalParcelCountByParcelId({
                        ...localParcelCountByParcelId,
                        [controlPackageState.data.id]:
                            (localParcelCountByParcelId[controlPackageState.data.id] ?? 0) + 1,
                    });
                }

                form.resetFields();
                setIsManual(false);
            }
            setBarcodeToStore(undefined);
            setRefToStore(undefined);
        }
    }, [
        previous?.controlPackageState.loading,
        controlPackageState,
        barcodeToStore,
        refToStore,
        form,
        packagesBarcodes,
        packagesRefs,
        setControlAddPackageState,
        setControlLocalParcelCountByParcelId,
        totalRemainingQuantityToControl,
        remainingQuantityToControlForCurrentParcel,
        localParcelCountByParcelId,
    ]);

    useEffect(() => {
        if (previous?.controlPackagesState.loading && !controlPackagesState.loading) {
            if (controlPackagesState.error) {
                message.error("Une erreur est survenue pendant l'ajout de colis");
            } else {
                if (controlPackagesState.data) {
                    onFinish();
                } else {
                    message.error("Une erreur est survenue pendant l'ajout de colis");
                }
            }
        }
    }, [
        previous?.controlPackagesState.loading,
        controlPackagesState.loading,
        controlPackagesState.error,
        controlPackagesState.data,
        onFinish,
    ]);

    useEffect(() => {
        if (!visible) {
            setBarcodeToStore(undefined);
            setRefToStore(undefined);
            resetControlAddPackageState([]);
        } else {
            fetchCustomerOrder({ customerOrderId });
        }
    }, [visible, setBarcodeToStore, setRefToStore, resetControlAddPackageState, fetchCustomerOrder, customerOrderId]);

    return (
        <CustomModal
            footer={
                isManual ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={() => form.submit()}
                                loading={controlPackageState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={setIsManual.bind(null, false)}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={onSubmit}
                                loading={controlPackagesState.loading}
                            >
                                Terminer l&rsquo;ajout
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="f1"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={setIsManual.bind(null, true)}
                                disabled={totalRemainingQuantityToControl === 0}
                                ghost
                            >
                                Ajouter manuellement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={
                isManual ? 'Saisir manuellement' : 'Veuillez scanner individuellement les colis pour les contrôler :'
            }
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            altTitle={isManual}
        >
            {isManual ? (
                <Form form={form} onFinish={onSubmitManual}>
                    <Form.Item name="reference" rules={[{ required: true, message: 'Veuillez saisir une référence' }]}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir référence colis" autoFocus />
                        </ArrowNavItem>
                    </Form.Item>
                    <Form.Item name="cont" style={{ marginBottom: 0 }}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir CONT (optionnel)" />
                        </ArrowNavItem>
                    </Form.Item>
                    <button type="submit" style={{ display: 'none' }} />
                </Form>
            ) : (
                <QuantityCard
                    value={formatNumber(packagesRefs.length + packagesBarcodes.length)}
                    label="colis contrôlés"
                    outline
                />
            )}
        </CustomModal>
    );
};

export default ControlPalletAddPackageModal;
