import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    Button,
    Descriptions,
    Divider,
    message,
    PaginationProps,
    Radio,
    Skeleton,
    Spin,
    Typography,
    RadioChangeEvent,
    Card,
    Select,
} from 'antd';
import { RangePickerDateProps } from 'antd/es/date-picker/generatePicker';
import dayjs, { Dayjs } from 'dayjs';

import { details, getParcelStateById } from '../../store/actions/parcels';
import {
    MovementHistory,
    PalletPlace,
    PalletQuality,
    Parcel,
    PlaceZoneType,
    SearchType,
} from '../../store/api/apiTypes';
import { getParcelsSearchState, searchParcels } from '../../store/actions/stockResearch';
import { getMovementHistoriesListState, list as movementHistoriesList } from '../../store/actions/movementHistories';

import { useActions, useIsDesktop, usePrevious } from '../../hooks';
import PageHeader from '../../components/PageHeader';
import QuantityCard from '../../components/QuantityCard';
import List, { ListColumn } from '../../components/list/List';
import useQueryParams from '../../hooks/queryParams';
import { getRawRoute, getRoute, RoutePathName } from '../../routes';
import { formatDate, formatNumber, translateMovementHistoryType } from '../../helpers/i18n';
import { getOperatorName } from '../../helpers';
import FixedFooter from '../../components/FixedFooter';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import TitleBlack from '../../components/TitleBlack';
import DatePicker from '../../components/DatePicker';
import { isZebra } from '../../helpers/enterprise-browser';
import { IconWarning } from '../../components/icons';

interface SearchDetailsProps {
    id: Parcel['id'];
}

const SearchDetails: FC<SearchDetailsProps> = ({ id }) => {
    const history = useHistory();
    const isHistory = !!useRouteMatch(getRawRoute(RoutePathName.searchDetailsHistory));
    const [fetchParcel, fetchPalletPlaces, fetchMovementHistory] = useActions([
        details.trigger,
        searchParcels.trigger,
        movementHistoriesList.trigger,
    ]);
    const parcelState = useSelector(getParcelStateById(id));
    const [queryParams, setQueryParams] = useQueryParams('searchDetailsParcel');
    const placeStoreLabel = queryParams.get('placeStoreLabel') ?? '';
    const placeStoreId = queryParams.get('placeStoreId') ?? '';
    const fromDate = queryParams.get('fromDate') ?? '';
    const toDate = queryParams.get('toDate') ?? '';
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const zoneType = queryParams.get('zoneType') ?? '';
    const palletPlaceState = useSelector(getParcelsSearchState);
    const movementHistoryListState = useSelector(getMovementHistoriesListState);
    const previous = usePrevious({ parcelState });
    const onClickItem = (record: PalletPlace) => {
        history.push({
            pathname: getRoute(RoutePathName.searchDetails, { type: SearchType.places, id: record?.id ?? '' }),
            search: new URLSearchParams({ placeStoreLabel }).toString(),
        });
    };
    const onClickHistory = () => {
        history.push(getRoute(RoutePathName.searchDetailsHistory, { type: SearchType.parcels, id }));
    };
    const onPaginationChange: PaginationProps['onChange'] = (p) => {
        setQueryParams({
            page: p ? p - 1 : 0,
        });
    };
    const onDatesChange: RangePickerDateProps<Dayjs>['onChange'] = (values) => {
        setQueryParams({
            fromDate: values?.[0]?.startOf('day')?.toISOString(),
            toDate: values?.[1]?.endOf('day')?.toISOString(),
        });
    };
    const onLoadMore = () => {
        fetchPalletPlaces(
            {
                type: SearchType.parcels,
                placeStoreId: placeStoreId,
                reference: parcelState.data?.reference,
                zoneType: zoneType || undefined,
                page: (palletPlaceState.data?.page ?? 0) + 1,
                pageSize: 10,
            },
            { loadMore: true }
        );
    };
    const onLoadMoreHistory = () => {
        fetchMovementHistory(
            {
                parcelId: id,
                pageSize: 10,
                sort: 'date',
                sortOrder: 'desc',
                page: (movementHistoryListState.data?.page ?? 0) + 1,
                ...(fromDate ? { fromDate } : {}),
                ...(toDate ? { toDate } : {}),
            },
            { loadMore: true }
        );
    };
    const onRadioChangeZoneType = (e: RadioChangeEvent) => onChangeZoneType(e.target.value);

    const onChangeZoneType = (value: string) => {
        const zoneType = value !== 'all' ? value : undefined;
        setQueryParams({
            zoneType,
            page: 0,
        });
    };

    const isDesktop = useIsDesktop();
    const palletPlaceColumns: Array<ListColumn<PalletPlace>> = (
        [
            {
                key: 'currentPlace',
                title: 'Emplacement',
                flex: '1 1 37.5%',
                render: ({ place }) => `${place?.sector ?? '—'} ${place?.locker ?? '—'} ${place?.spot ?? '—'}`,
            },
            isDesktop && {
                dataIndex: 'pallet.reference',
                title: 'Réf. palette',
                flex: '1 1 37.5%',
            },
            isDesktop && {
                key: 'last',
                title: 'Date dernier mvt',
                flex: '1 1 37.5%',
                render: ({ fullfilledAt }) =>
                    formatDate(fullfilledAt, {
                        day: 'numeric',
                        month: 'numeric',
                        year: '2-digit',
                    }),
            },
            {
                key: 'pallet.quantity',
                title: 'Quantité dispo.',
                flex: '1 1 25%',
                render: (record) => formatNumber(record.pallet?.quantity),
            },
        ] as Array<ListColumn<PalletPlace>>
    ).filter(Boolean);
    const movementHistoryColumns: Array<ListColumn<MovementHistory>> = [
        {
            key: 'type',
            title: 'Type',
            flex: '1 1 37.5%',
            render: ({ type }) => translateMovementHistoryType(type, true),
        },
        {
            key: 'source',
            title: 'Source',
            flex: '1 1 37.5%',
            render: (item) => [item?.sourceSector, item?.sourceLocker].filter(Boolean).join(' ') || '—',
        },
        {
            key: 'destination',
            title: 'Destination',
            flex: '1 1 37.5%',
            render: (item) => [item?.destinationSector, item?.destinationLocker].filter(Boolean).join(' ') || '—',
        },
        {
            key: 'quantity',
            title: 'Quantité',
            flex: '1 1 37.5%',
            render: ({ quantity }) => formatNumber(quantity),
        },
        {
            key: 'operator',
            title: 'Opérateur',
            flex: '1 1 37.5%',
            render: ({ operator }) => getOperatorName(operator),
        },
        {
            key: 'date',
            title: 'Date / Heure',
            flex: '1 1 37.5%',
            render: ({ date }) =>
                formatDate(date, {
                    day: 'numeric',
                    month: 'numeric',
                    year: '2-digit',
                    hour: 'numeric',
                    minute: 'numeric',
                }),
        },
    ];

    useEffect(() => {
        fetchParcel({ parcelId: id });
    }, [fetchParcel, id]);

    useEffect(() => {
        if (parcelState.data?.reference && !isHistory) {
            fetchPalletPlaces({
                type: SearchType.parcels,
                placeStoreId: placeStoreId,
                reference: parcelState.data?.reference,
                zoneType: zoneType || undefined,
                pageSize: 10,
                page,
            });
        }
    }, [isHistory, page, fetchPalletPlaces, placeStoreId, parcelState.data?.reference, zoneType]);

    useEffect(() => {
        if (isHistory) {
            fetchMovementHistory({
                parcelId: id,
                pageSize: 10,
                sort: 'date',
                sortOrder: 'desc',
                ...(fromDate ? { fromDate } : {}),
                ...(toDate ? { toDate } : {}),
                page,
            });
        }
    }, [isHistory, fetchMovementHistory, id, fromDate, toDate, page]);

    useEffect(() => {
        if (previous?.parcelState.loading && !parcelState.loading) {
            if (parcelState.error) {
                message.error('Une erreur est survenue lors du chargement du colis');
            }
        }
    }, [previous?.parcelState.loading, parcelState.loading, parcelState.error]);

    return (
        <FixedFooter.Wrapper>
            <PageHeader>
                <Spin spinning={parcelState.loading}>
                    <Descriptions column={{ xs: 3 }} size="small" colon={false} layout="vertical">
                        {placeStoreLabel && <Descriptions.Item label="Site">{placeStoreLabel}</Descriptions.Item>}
                        <Descriptions.Item label="Référence">{parcelState.data?.reference}</Descriptions.Item>
                        <Descriptions.Item label="Désignation">{parcelState.data?.label}</Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>
            {isHistory ? (
                <>
                    <Divider />
                    <div className="flex flex-between flex-center">
                        <Skeleton
                            className="skeleton-title"
                            loading={movementHistoryListState.loading}
                            paragraph={{ rows: 0 }}
                        >
                            <TitleBlack style={{ margin: 0 }}>{`${formatNumber(
                                movementHistoryListState.data?.totalCount
                            )} Mouvement${(movementHistoryListState.data?.totalCount ?? 0) > 1 ? 's' : ''} ${
                                fromDate && toDate
                                    ? `trouvé${
                                          (movementHistoryListState.data?.totalCount ?? 0) > 1 ? 's' : ''
                                      } pour cette période`
                                    : `enregistré${(movementHistoryListState.data?.totalCount ?? 0) > 1 ? 's' : ''}`
                            }`}</TitleBlack>
                        </Skeleton>
                        <div className="flex" style={{ minWidth: '29.1875rem' }}>
                            <Typography.Text
                                className="text-primary"
                                style={{ fontSize: '0.9375rem', marginRight: '1rem' }}
                                strong
                            >
                                Filtrer par période
                            </Typography.Text>
                            <DatePicker.RangePicker
                                onChange={onDatesChange}
                                format="DD/MM/YYYY"
                                defaultValue={fromDate && toDate ? [dayjs(fromDate), dayjs(toDate)] : undefined}
                            />
                        </div>
                    </div>
                    <Divider />
                    <List<MovementHistory>
                        columns={movementHistoryColumns}
                        data={movementHistoryListState.data?.items}
                        rowKey={(record) => `${record.id}`}
                        isLoading={
                            isZebra
                                ? movementHistoryListState.loading && movementHistoryListState.data?.page === undefined
                                : movementHistoryListState.loading
                        }
                        loadingRowsCount={10}
                        pagination={
                            isZebra
                                ? false
                                : {
                                      total: movementHistoryListState.data?.totalCount,
                                      pageSize: movementHistoryListState.data?.pageSize,
                                      hideOnSinglePage: true,
                                      current: page + 1,
                                      onChange: onPaginationChange,
                                  }
                        }
                    />
                    {isZebra &&
                        movementHistoryListState.data &&
                        movementHistoryListState.data.page < movementHistoryListState.data.pageCount - 1 && (
                            <Button
                                onClick={onLoadMoreHistory}
                                loading={movementHistoryListState.loading}
                                size="small"
                                style={{ margin: '16px 0', fontSize: 14 }}
                                block
                            >
                                Plus de résultats
                            </Button>
                        )}
                </>
            ) : (
                <>
                    <QuantityCard value={formatNumber(parcelState.data?.quantity)} label="Colis en stock" />
                    <Divider />
                    <TitleBlack>Détail des emplacements</TitleBlack>
                    {!isZebra && !isHistory && (
                        <ArrowNavItem>
                            <Radio.Group
                                buttonStyle="solid"
                                className="stretch small-text"
                                onChange={onRadioChangeZoneType}
                                style={{ marginBottom: '1.5rem' }}
                                value={zoneType || undefined}
                            >
                                <Radio.Button value={undefined} style={{ flex: 1 }}>
                                    Toutes les zones
                                </Radio.Button>
                                <Radio.Button value={PlaceZoneType.mass} style={{ flex: 1 }}>
                                    Masse/Accumulation
                                </Radio.Button>
                                <Radio.Button value={PlaceZoneType.picking} style={{ flex: 1 }}>
                                    Picking
                                </Radio.Button>
                                <Radio.Button value={PlaceZoneType.preparation} style={{ flex: 1 }}>
                                    Box
                                </Radio.Button>
                            </Radio.Group>
                        </ArrowNavItem>
                    )}
                    {isZebra && !isHistory && (
                        <ArrowNavItem>
                            <Select
                                style={{ marginBottom: '1.5rem' }}
                                defaultValue={zoneType || 'all'}
                                onChange={onChangeZoneType}
                            >
                                <Select.Option value={'all'}>Toutes les zones</Select.Option>
                                <Select.Option value={PlaceZoneType.mass}>Masse/Accumulation</Select.Option>
                                <Select.Option value={PlaceZoneType.picking}>Picking</Select.Option>
                                <Select.Option value={PlaceZoneType.preparation}>Box</Select.Option>
                            </Select>
                        </ArrowNavItem>
                    )}
                    <List<PalletPlace>
                        columns={palletPlaceColumns}
                        data={palletPlaceState.data?.items}
                        rowKey={(record) => `${record.id}`}
                        onRowEnterPress={onClickItem}
                        onRowClick={onClickItem}
                        isRowError={(record) => !!record.pallet && record.pallet.quality === PalletQuality.noConform}
                        isLoading={
                            isZebra
                                ? palletPlaceState.loading && palletPlaceState.data?.page === undefined
                                : palletPlaceState.loading
                        }
                        loadingRowsCount={11}
                        pagination={
                            isZebra
                                ? false
                                : {
                                      total: palletPlaceState.data?.totalCount,
                                      pageSize: palletPlaceState.data?.pageSize,
                                      hideOnSinglePage: true,
                                      current: page + 1,
                                      onChange: onPaginationChange,
                                  }
                        }
                        hideWhenEmpty
                    />
                    {isZebra &&
                        palletPlaceState.data &&
                        palletPlaceState.data.page < palletPlaceState.data.pageCount - 1 && (
                            <Button
                                onClick={onLoadMore}
                                loading={palletPlaceState.loading}
                                size="small"
                                style={{ margin: '16px 0', fontSize: 14 }}
                                block
                            >
                                Plus de résultats
                            </Button>
                        )}
                    {palletPlaceState.data?.totalCount === 0 && (
                        <Card>
                            <p style={{ margin: 0, textAlign: 'center', color: '#EA9741' }}>
                                <IconWarning style={{ fontSize: '1.5rem' }} />
                                <br />
                                Il n&rsquo;y a pas de résultats pour cette recherche
                            </p>
                        </Card>
                    )}
                    {!isZebra && (
                        <FixedFooter>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    shortcut="f1"
                                    onClick={onClickHistory}
                                    size="large"
                                    type="primary"
                                    ghost
                                    block
                                >
                                    Historique des mouvements
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </FixedFooter>
                    )}
                </>
            )}
        </FixedFooter.Wrapper>
    );
};

export default SearchDetails;
