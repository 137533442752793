import { SearchPaginationQuery } from '.';
import { CustomerOrder } from './apiTypes';
import api from './_client';

export type CustomerOrderListPayload = SearchPaginationQuery;
export const list = async (payload: CustomerOrderListPayload = {}) =>
    await api.get('/customerOrders', { params: payload });

export type CustomerOrderUpdatePayload = CustomerOrder;
export const update = async (payload: CustomerOrderUpdatePayload) =>
    await api.put(`/customerOrders/${payload.id}`, payload);

export interface CustomerOrderDetailsPayload {
    customerOrderId: CustomerOrder['id'];
}
export const details = async ({ customerOrderId, ...payload }: CustomerOrderDetailsPayload) =>
    await api.get(`/customerOrders/${customerOrderId}`, { params: payload });

export interface CustomerOrderCloseDetailsPayload {
    customerOrderId: CustomerOrder['id'];
}
export const closeDetails = async ({ customerOrderId }: CustomerOrderCloseDetailsPayload) =>
    await api.get(`/customerOrders/${customerOrderId}/close`);

export interface CustomerOrderClosePayload {
    customerOrderId: CustomerOrder['id'];
}
export const close = async ({ customerOrderId }: CustomerOrderClosePayload) =>
    await api.post(`/customerOrders/${customerOrderId}/close`);
