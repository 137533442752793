import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 12">
        <path
            fill="currentColor"
            fillRule="nonzero"
            d="M6 0c1.612 0 3.125.64 4.243 1.757A5.982 5.982 0 0112 6c0 1.612-.64 3.125-1.757 4.243A5.982 5.982 0 016 12a5.982 5.982 0 01-4.243-1.757A5.982 5.982 0 010 6c0-1.612.64-3.125 1.757-4.243A5.982 5.982 0 016 0zm0 1.09c-1.32 0-2.556.524-3.471 1.439A4.891 4.891 0 001.09 6c0 1.32.523 2.556 1.438 3.471A4.891 4.891 0 006 10.91c1.32 0 2.556-.523 3.471-1.438A4.891 4.891 0 0010.91 6c0-1.32-.523-2.556-1.438-3.471A4.891 4.891 0 006 1.09zm3.113 2.888c.19.19.21.483.063.696l-.063.075L5.84 8.022a.546.546 0 01-.696.063l-.075-.063-1.637-1.636a.545.545 0 01.696-.835l.076.063 1.25 1.251 2.888-2.887a.545.545 0 01.771 0z"
        />
    </svg>
);

const IconCheckCircle: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-check-circle${props.className ? ` ${props.className}` : ''}`} />
);

export default IconCheckCircle;
