import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestInitialState } from '../helpers';

// Types
import { Pallet } from '../api/apiTypes';

// Controlers
import {
    RelatedPalletsDetailsByBarcodePayload,
    detailsByBarcode as detailsByBarcodeApiCall,
    RelatedPalletsDetailsByReferencePayload,
    detailsByReference as detailsByReferenceApiCall,
} from '../api/relatedPallets';

// States
export interface RelatedPalletsState {
    detailsByBarcode: RequestState<Pallet>;
    detailsByReference: RequestState<Pallet>;
}

const initialState: RelatedPalletsState = {
    detailsByBarcode: { ...requestInitialState },
    detailsByReference: { ...requestInitialState },
};

export const detailsByBarcode = new EzeeAsyncAction<
    RelatedPalletsState['detailsByBarcode'],
    RelatedPalletsDetailsByBarcodePayload,
    Pallet
>('relatedPallets/detailsByBarcode', initialState.detailsByBarcode, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.detailsByBarcode,
    }),
});

export const detailsByReference = new EzeeAsyncAction<
    RelatedPalletsState['detailsByReference'],
    RelatedPalletsDetailsByReferencePayload,
    Pallet
>('relatedPallets/detailsByReference', initialState.detailsByReference, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.detailsByReference,
    }),
});

// Reducer
export const relatedPalletsReducer = combineReducers<RelatedPalletsState>({
    detailsByBarcode: detailsByBarcode.reducer,
    detailsByReference: detailsByReference.reducer,
});

// Saga
export function* relatedPalletsSaga() {
    yield takeLatest(detailsByBarcode.type.trigger, simpleAsyncSaga(detailsByBarcodeApiCall, detailsByBarcode));
    yield takeLatest(detailsByReference.type.trigger, simpleAsyncSaga(detailsByReferenceApiCall, detailsByReference));
}

// Store helpers
export const getPalletDetailsStateByBarcode = (state: MainReducerState) => state.relatedPallets.detailsByBarcode;
export const getPalletDetailsStateByReference = (state: MainReducerState) => state.relatedPallets.detailsByReference;
