import React, { FC, useCallback, useEffect, useState } from 'react';
import { message, Form, FormProps, Button, InputNumber, Typography, Input } from 'antd';
import { useSelector } from 'react-redux';

import {
    getPlaceUpdateState,
    getPlaceDeleteState,
    update as placeUpdate,
    del as placeDelete,
} from '../../store/actions/places';
import { Place, PlaceZoneType } from '../../store/api/apiTypes';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useShortcutScope } from '../../hooks';
import SuccessMessage from '../../components/SuccessMessage';
import ButtonRed from '../../components/ButtonRed';
import { translatePlaceZoneType } from '../../helpers/i18n';
import YesNoSwitchFormItem from '../../components/YesNoSwitchFormItem';

const shortcutScope = 'PlaceFormModal';

interface PlaceFormModalProps extends CustomModalProps {
    place?: Place;
    onSuccess?: () => void;
}

const PlaceFormModal: FC<PlaceFormModalProps> = ({ visible, onCancel, place, onSuccess }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const isMounted = useIsMounted();
    const [form] = Form.useForm();
    const [success, setSuccess] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [updatePlace, deletePlace] = useActions([placeUpdate.trigger, placeDelete.trigger]);
    const placeUpdateState = useSelector(getPlaceUpdateState);
    const placeDeleteState = useSelector(getPlaceDeleteState);
    const previous = usePrevious({ placeUpdateState, placeDeleteState });
    const onSubmit: FormProps['onFinish'] = ({ placeStore, picking, mass, ...values }) => {
        const zoneType = picking ? PlaceZoneType.picking : mass ? PlaceZoneType.mass : undefined;

        updatePlace({
            placeId: place?.id,
            placeStoreId: placeStore?.id,
            ...values,
            parcelReference: values.parcelReference || null,
            zoneType,
        });
    };
    const onDelete = () => {
        deletePlace({ placeId: place?.id });
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    const onFormValuesChange: FormProps['onValuesChange'] = (changedValues) => {
        const changedField = Object.keys(changedValues)?.[0];

        if (changedField === 'picking' && changedValues.picking) {
            form.setFieldsValue({
                mass: false,
                storageByAccumulation: false,
            });
        } else if (
            (changedField === 'mass' && changedValues.mass) ||
            (changedField === 'storageByAccumulation' && changedValues.storageByAccumulation)
        ) {
            form.setFieldsValue({
                picking: false,
            });
        }
    };

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            setSuccess(false);
        }
    }, [visible, form, setSuccess]);

    useEffect(() => {
        if (previous?.placeUpdateState.loading && !placeUpdateState.loading) {
            if (placeUpdateState.error) {
                if (placeUpdateState.error?.status === 400 && placeUpdateState.error?.data?.placeNotEmpty) {
                    message.error("Cet emplacement n'est pas vide, vous ne pouvez pas le modifier");
                } else if (placeUpdateState.error?.status === 404 && placeUpdateState.error?.data?.parcelNotFound) {
                    message.error('Référence inconnue');
                } else {
                    message.error("Une erreur est survenue pendant la mise à jour de l'emplacement");
                }
            } else {
                setSuccess(true);
                onSuccess?.();
            }
        }
    }, [placeUpdateState.error, placeUpdateState.loading, previous?.placeUpdateState.loading, onSuccess]);

    useEffect(() => {
        if (previous?.placeDeleteState.loading && !placeDeleteState.loading) {
            if (placeDeleteState.error) {
                if (placeDeleteState.error?.status === 400 && placeUpdateState.error?.data?.placeNotEmpty) {
                    message.error("Vous ne pouvez pas supprimer cet emplacement car il n'est pas vide");
                } else {
                    message.error("Une erreur est survenue pendant la suppression de l'emplacement");
                }
            } else {
                setSuccess(true);
                onSuccess?.();
            }
        }
    }, [
        placeDeleteState.error,
        placeDeleteState.loading,
        previous?.placeDeleteState.loading,
        onSuccess,
        placeUpdateState.error?.data?.placeNotEmpty,
    ]);

    useEffect(() => {
        let timeout: number;

        if (success) {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onClose();
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [isMounted, onClose, success]);

    useEffect(() => {
        if (place) {
            form.setFieldsValue({
                ...place,
                picking: place?.zoneType === PlaceZoneType.picking,
                mass: place?.zoneType === PlaceZoneType.mass,
                parcelReference: place?.parcel?.reference,
            });
        } else {
            form.resetFields();
        }
    }, [place, form]);

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            setIsDeleting(false);
        }
    }, [visible, form]);

    return (
        <CustomModal
            footer={
                success ? null : isDeleting ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                as={ButtonRed}
                                onClick={onDelete}
                                loading={placeDeleteState.loading}
                            >
                                Oui, supprimer
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                disabled={placeDeleteState.loading}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={form.submit}
                                loading={placeUpdateState.loading}
                                disabled={placeDeleteState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <Button onClick={setIsDeleting.bind(null, true)} danger>
                                Supprimer
                            </Button>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                disabled={placeDeleteState.loading}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={!success && (isDeleting ? `Voulez-vous vraiment supprimer cet emplacement maintenant ?` : 'Éditer')}
            width={368}
            keyboard={false}
            altTitle={!isDeleting}
        >
            {success ? (
                <SuccessMessage message={`L'emplacement a été ${isDeleting ? 'supprimé' : 'édité'} avec succès`} />
            ) : !isDeleting ? (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                    onValuesChange={onFormValuesChange}
                    initialValues={{
                        ...place,
                        picking: place?.zoneType === PlaceZoneType.picking,
                        mass: place?.zoneType === PlaceZoneType.mass,
                        parcelReference: place?.parcel?.reference,
                    }}
                    requiredMark={false}
                >
                    <Form.Item label="Type de zone" className="form-item-inline">
                        {translatePlaceZoneType(place?.zoneType)}
                    </Form.Item>
                    {place?.zoneType === PlaceZoneType.mass && (
                        <Form.Item label="Nombre d'unité(s)" name="space">
                            <ArrowNavItem>
                                <InputNumber placeholder="Saisir un nombre" min={0} style={{ width: '100%' }} />
                            </ArrowNavItem>
                        </Form.Item>
                    )}
                    {place?.zoneType === PlaceZoneType.picking && (
                        <>
                            <Form.Item label="Référence" name="parcelReference">
                                <ArrowNavItem>
                                    <Input placeholder="Saisir une référence" />
                                </ArrowNavItem>
                            </Form.Item>
                            <Form.Item label="Rang" name="rank">
                                <ArrowNavItem>
                                    <InputNumber placeholder="Saisir un rang" min={0} />
                                </ArrowNavItem>
                            </Form.Item>
                        </>
                    )}
                    {(place?.zoneType === PlaceZoneType.mass || place?.zoneType === PlaceZoneType.picking) && (
                        <>
                            <p>
                                <Typography.Text className="text-primary" strong>
                                    Autorisations
                                </Typography.Text>
                            </p>
                            <YesNoSwitchFormItem label="Rangement" name="isStorageAuthorization" />
                            <YesNoSwitchFormItem label="Prélèvement" name="isWithdrawalAuthorization" />
                        </>
                    )}
                    {place?.zoneType === PlaceZoneType.picking && (
                        <YesNoSwitchFormItem label="Réapprovisionnement" name="isResupplyAuthorization" />
                    )}
                    {place?.zoneType === PlaceZoneType.mass && (
                        <YesNoSwitchFormItem label="Mélange des articles" name="isMixingParcel" />
                    )}
                    {place?.zoneType === PlaceZoneType.preparation && (
                        <Form.Item label="Nom de la zone de préparation" name="locker" style={{ marginBottom: 0 }}>
                            <ArrowNavItem>
                                <Input placeholder="Saisir un nom" />
                            </ArrowNavItem>
                        </Form.Item>
                    )}
                </Form>
            ) : null}
        </CustomModal>
    );
};

export default PlaceFormModal;
