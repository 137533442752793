import {
    platformCheckReturnPallet,
    PlatformReturnCheckPalletPayload,
    platformReturnPallets,
    PlatformReturnPalletsPayload,
} from './../api/platformReturn';
import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { Pallet, PlatformAnomalyCode, PlatformReception } from '../api/apiTypes';

import { EzeeAction } from '../helpers/EzeeAction';

export interface PlatformReturnPalletRef {
    reference: string;
    anomalyCode?: PlatformAnomalyCode['id'];
}
export interface PlatformReturnPalletBarcode {
    barcode: string;
    anomalyCode?: PlatformAnomalyCode['id'];
}

// States
export interface PlatformReturnsState {
    checkPallet: RequestState<Pallet>;
    sendPallets: RequestState<PlatformReception>;
    reception: {
        receive: {
            palletRefs: PlatformReturnPalletRef[];
            palletBarcodes: PlatformReturnPalletBarcode[];
            isWaitingForAnomaly: boolean;
        };
    };
}

const initialState: PlatformReturnsState = {
    checkPallet: { ...requestInitialState },
    sendPallets: { ...requestInitialState },
    reception: {
        receive: {
            palletRefs: [],
            palletBarcodes: [],
            isWaitingForAnomaly: false,
        },
    },
};

export const checkReturnsPallet = new EzeeAsyncAction<
    PlatformReturnsState['checkPallet'],
    PlatformReturnCheckPalletPayload,
    Pallet
>(
    'platformReturns/checkPallet',
    initialState.checkPallet,
    requestReducer<PlatformReturnsState['checkPallet'], Pallet>(initialState.checkPallet)
);

export const sendReturnsPallets = new EzeeAsyncAction<
    PlatformReturnsState['sendPallets'],
    PlatformReturnPalletsPayload,
    Pallet
>(
    'platformReturns/sendPallets',
    initialState.sendPallets,
    requestReducer<PlatformReturnsState['sendPallets'], Pallet>(initialState.sendPallets)
);

export const returnsReception = new EzeeAction<PlatformReturnsState['reception']>(
    'platformReturns/reception',
    initialState.reception,
    {
        setReceiveData: (state, payload) => ({
            ...state,
            receive: {
                ...state.receive,
                ...payload,
            },
        }),
        resetReceive: (state) => ({
            ...state,
            receive: {
                ...initialState.reception.receive,
            },
        }),
    }
);

// Reducer
export const platformReturnsReducer = combineReducers<PlatformReturnsState>({
    checkPallet: checkReturnsPallet.reducer,
    sendPallets: sendReturnsPallets.reducer,
    reception: returnsReception.reducer,
});

// Saga
export function* platformReturnsSaga() {
    yield takeLatest(checkReturnsPallet.type.trigger, simpleAsyncSaga(platformCheckReturnPallet, checkReturnsPallet));
    yield takeLatest(sendReturnsPallets.type.trigger, simpleAsyncSaga(platformReturnPallets, sendReturnsPallets));
}

// Store helpers

export const getPlatformReturnsCheckPalletState = (state: MainReducerState) => state.platformReturns.checkPallet;
export const getPlatformReturnsSendPalletsState = (state: MainReducerState) => state.platformReturns.sendPallets;
export const getPlatformReturnsReceptionState = (state: MainReducerState) => state.platformReturns.reception;
