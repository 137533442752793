export const errorSound = () => {
    const context = new window.AudioContext();
    const osc = context.createOscillator();
    osc.type = 'triangle';
    osc.frequency.value = 440; // Hz
    osc.connect(context.destination);
    osc.start();
    osc.frequency.setValueAtTime(0, context.currentTime + 0.2);
    osc.frequency.setValueAtTime(440, context.currentTime + 0.25);
    osc.stop(context.currentTime + 0.45);
    window.navigator.vibrate([200, 100, 200]);
};

export const warningSound = () => {
    const context = new window.AudioContext();
    const osc = context.createOscillator();
    osc.type = 'triangle';
    osc.frequency.value = 1000; // Hz
    osc.connect(context.destination);
    osc.start();
    osc.frequency.setValueAtTime(0, context.currentTime + 0.2);
    osc.frequency.setValueAtTime(800, context.currentTime + 0.25);
    osc.stop(context.currentTime + 0.4);
    window.navigator.vibrate([200, 100, 200]);
};
