import { Typography } from 'antd';
import { IconWarning } from '../../../components/icons';
import React, { useCallback } from 'react';
import CustomModal from '../../../components/CustomModal';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import { useArrowNavScope, useShortcutScope } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import { useHistory } from 'react-router-dom';
import { Parcel, ReturnZoneType } from '../../../store/api/apiTypes';

const shortcutScope = 'ReturnCancelModal';

interface ReturnCancelModalProps {
    isNewPallet?: boolean;
    returnZoneType?: ReturnZoneType;
    quantity?: number;
    parcelId?: Parcel['id'];
}

const ReturnCancelModal = NiceModal.create<ReturnCancelModalProps>(
    ({ isNewPallet, returnZoneType, quantity, parcelId }) => {
        const modal = useModal();
        const { hide } = modal;
        const history = useHistory();
        useShortcutScope(shortcutScope);
        useArrowNavScope(shortcutScope);

        const onValid = useCallback(() => {
            if (!isNewPallet) {
                hide().then(() => history.push(getRoute(RoutePathName.movementsStockMenu)));
            } else {
                hide().then(() =>
                    history.push(getRoute(RoutePathName.movementCustomerReturnPalletList), {
                        returnZoneType,
                        quantity,
                        parcelId,
                    })
                );
            }
        }, [hide, history, isNewPallet, parcelId, quantity, returnZoneType]);

        return (
            <CustomModal
                {...antdModal(modal)}
                footer={
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={onValid}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={hide}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                }
                title={
                    <Typography.Text>
                        <Typography.Text type="warning">
                            <IconWarning style={{ fontSize: '2.25rem', marginBottom: '1rem' }} />
                        </Typography.Text>
                        {!isNewPallet ? (
                            <>
                                <br />
                                En cliquant sur valider <br />
                                vous annulerez la saisie de ce retour
                            </>
                        ) : (
                            <>
                                <br />
                                En cliquant sur valider <br />
                                vous annulerez le rangement dans
                                <br />
                                un autre emplacement
                            </>
                        )}
                    </Typography.Text>
                }
                width={368}
            />
        );
    }
);

export default ReturnCancelModal;
