import React, { FC, useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { useSelector } from 'react-redux';

import { InventoryPlace } from '../../store/api/apiTypes';
import { getInventoryPlaceValidateGapState, placeValidateGap } from '../../store/actions/inventories';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useShortcutScope } from '../../hooks';
import SuccessMessage from '../../components/SuccessMessage';
import QuantityInput from '../../components/QuantityInput';

const shortcutScope = 'StockGapModal';

interface StockGapModalProps extends CustomModalProps {
    inventoryPlace?: InventoryPlace;
    hasInput?: boolean;
}

const StockGapModal: FC<StockGapModalProps> = ({ visible, onCancel, inventoryPlace, hasInput }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const inventoryPlaceValidateGap = useActions(placeValidateGap.trigger);
    const [success, setSuccess] = useState(false);
    const delta = (inventoryPlace?.quantity ?? 0) - (inventoryPlace?.theoreticalQuantity ?? 0);
    const [newDelta, setNewDelta] = useState(delta);
    const inventoryPlaceValidateGapState = useSelector(getInventoryPlaceValidateGapState);
    const previous = usePrevious({ inventoryPlaceValidateGapState });
    const isMounted = useIsMounted();
    const onSubmit = () => {
        inventoryPlaceValidateGap({
            inventoryPlaceId: inventoryPlace?.id,
            quantity: (inventoryPlace?.theoreticalQuantity ?? 0) + newDelta,
        });
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    useEffect(() => {
        let timeout: number;

        if (previous?.inventoryPlaceValidateGapState.loading && !inventoryPlaceValidateGapState.loading) {
            if (inventoryPlaceValidateGapState.error) {
                message.error("Une erreur est survenue lors de la validation d'écart");
            } else {
                setSuccess(true);
            }
        }

        return () => window.clearTimeout(timeout);
    }, [
        previous?.inventoryPlaceValidateGapState,
        inventoryPlaceValidateGapState.loading,
        inventoryPlaceValidateGapState.error,
        setSuccess,
        isMounted,
        onClose,
    ]);

    useEffect(() => {
        let timeout: number;

        if (success) {
            timeout = window.setTimeout(() => {
                if (isMounted.current) {
                    onClose();
                }
            }, 2000);
        }

        return () => window.clearTimeout(timeout);
    }, [success, isMounted, onClose]);

    useEffect(() => {
        if (!visible) {
            setSuccess(false);
        }
    }, [visible]);

    useEffect(() => {
        setNewDelta((inventoryPlace?.quantity ?? 0) - (inventoryPlace?.theoreticalQuantity ?? 0));
    }, [inventoryPlace]);

    return (
        <CustomModal
            footer={
                !success && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={onSubmit}
                                loading={inventoryPlaceValidateGapState.loading}
                            >
                                {hasInput ? 'Valider' : 'Oui'}
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                {hasInput ? 'Annuler' : 'Non'}
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={!success && (hasInput ? 'Saisir variation' : `Souhaitez-vous valider l'écart de ${delta} colis ?`)}
            width={368}
            keyboard={false}
            altTitle={hasInput}
        >
            {!success && hasInput && (
                <QuantityInput
                    onChange={setNewDelta}
                    value={newDelta}
                    min={-(inventoryPlace?.theoreticalQuantity ?? -0)}
                    hasArrowNav={visible}
                />
            )}
            {success && <SuccessMessage message="Écart validé avec succès" />}
        </CustomModal>
    );
};

export default StockGapModal;
