import React, { forwardRef, ForwardRefRenderFunction, useEffect, useState } from 'react';
import { Select } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { useSelector } from 'react-redux';

import { Printer, PrinterStatus } from '../store/api/apiTypes';
import { getDefaultPrinterState, getPrintersListState, list as listPrintersAction } from '../store/actions/printers';

import { IconWarning } from './icons';
import { useActions, usePrevious } from '../hooks';
import ArrowNavItem from './ArrowNavItem';
import { getRawRoute, RoutePathName } from '../routes';
import { useRouteMatch } from 'react-router-dom';

interface PrintersSelectProps {
    multi?: boolean;
    size?: SizeType;
    placeholder?: string;
    onChange?: (value: any) => void;
    withArrowNav?: boolean;
    arrowNavScope?: string;
}

const PrintersSelect: ForwardRefRenderFunction<typeof Select, PrintersSelectProps> = (props, ref: any) => {
    const listPrinters = useActions(listPrintersAction.trigger);
    const printersListState = useSelector(getPrintersListState);
    const defaultPrinterState = useSelector(getDefaultPrinterState);
    const previous = usePrevious({ printersListState });
    const { onChange, multi, size, placeholder, withArrowNav, arrowNavScope } = props;
    const [value, setValue] = useState<Printer['id'] | undefined>();
    const [disableSelect, setDisableSelect] = useState(false);
    const isSettingsRoute = !!useRouteMatch(getRawRoute(RoutePathName.settings));

    useEffect(() => {
        setValue(defaultPrinterState.printer?.id);
        listPrinters();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (defaultPrinterState.printer && !isSettingsRoute) {
            setDisableSelect(true);
        }
    }, [defaultPrinterState.printer, isSettingsRoute]);

    useEffect(() => {
        if (previous?.printersListState.loading && !printersListState.loading) {
            if (!value) {
                setValue(printersListState.data?.items[0]?.id);

                if (onChange) {
                    onChange(printersListState.data?.items[0]);
                }
            }
        }
    }, [
        onChange,
        previous?.printersListState.loading,
        printersListState.data?.items,
        printersListState.loading,
        value,
    ]);

    const onChangeSelect = (newValue: any) => {
        if (newValue) {
            setValue(newValue);
        } else {
            setValue(undefined);
        }

        if (onChange) {
            onChange(printersListState.data?.items.find((p) => p.id === parseInt(newValue, 10)));
        }
    };

    const select = (
        <Select
            mode={multi ? 'multiple' : undefined}
            size={size}
            ref={ref}
            value={value}
            placeholder={placeholder ?? 'Choisir une imprimante'}
            loading={printersListState.loading}
            filterOption={false}
            onChange={onChangeSelect}
            style={{ width: '100%' }}
            disabled={disableSelect}
            allowClear
            showArrow
        >
            {printersListState.data?.items?.map((option) => (
                <Select.Option value={option.id} key={option.id}>
                    {option.name}{' '}
                    {option.status === PrinterStatus.broken && (
                        <span style={{ color: 'red' }}>
                            <IconWarning /> HS
                        </span>
                    )}
                </Select.Option>
            ))}
        </Select>
    );

    return withArrowNav ? <ArrowNavItem scope={arrowNavScope}>{select}</ArrowNavItem> : select;
};

export default forwardRef(PrintersSelect);
