import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { message, PaginationProps } from 'antd';

import { getInventoryPlacesListState, placeList, getInventoryPlacesUpdateState } from '../../store/actions/inventories';
import { InventoryPlace, InventoryStatus } from '../../store/api/apiTypes';

import List, { ListColumn } from '../../components/list/List';
import Header from '../../components/Header';
import Seo from '../../components/Seo';
import { useActions, usePrevious } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import { getLocker } from '../../helpers';
import FixedFooter from '../../components/FixedFooter';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import StockGapModal from './StockGapModal';
import ButtonGrey from '../../components/ButtonGrey';
import BasicList from '../../components/BasicList';
import { formatNumber } from '../../helpers/i18n';
import useQueryParams from '../../hooks/queryParams';

const InventoryStockGapList: FC = () => {
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isDeltaEditable, setIsDeltaEditable] = useState(false);
    const [selectedInventoryPlace, setSelectedInventoryPlace] = useState<InventoryPlace>();
    const [queryParams, setQueryParams] = useQueryParams('inventoryStockGapList');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const [fetchPlaces] = useActions([placeList.trigger]);
    const inventoryPlacesState = useSelector(getInventoryPlacesListState);
    const inventoryPlaceUpdateState = useSelector(getInventoryPlacesUpdateState);
    const previous = usePrevious({ inventoryPlaceUpdateState });
    const onPaginationChange: PaginationProps['onChange'] = (p) => {
        setQueryParams({
            page: p ? p - 1 : 0,
        });
    };
    const onClickValidate = () => {
        if (selectedInventoryPlace) {
            setIsConfirmModalVisible(true);
        } else {
            message.error('Veuillez choisir une ligne');
        }
    };
    const onClickEditDelta = () => {
        if (selectedInventoryPlace) {
            setIsDeltaEditable(true);
            setIsConfirmModalVisible(true);
        } else {
            message.error('Veuillez choisir une ligne');
        }
    };
    const onCloseStockGapModal = () => {
        setIsConfirmModalVisible(false);
        setIsDeltaEditable(false);
        fetchPlaces({ status: InventoryStatus.toValidGap });
    };
    const columns: Array<ListColumn<InventoryPlace>> = [
        {
            dataIndex: 'place.store.number',
            title: 'Site',
            flex: '1 1 25%',
        },
        {
            dataIndex: 'place.sector',
            title: 'Secteur',
            flex: '1 1 25%',
        },
        {
            key: 'locker',
            title: 'Casier',
            flex: '1 1 25%',
            render: (record) => getLocker(record.place),
        },
        {
            dataIndex: 'place.spot',
            title: 'Emplacement',
            flex: '1 1 25%',
        },
        {
            dataIndex: 'parcel.reference',
            title: 'Référence',
            flex: '1 1 25%',
        },
        {
            key: 'quantity',
            title: 'Qté théorique',
            flex: '1 1 25%',
            render: (record) => formatNumber(record.theoreticalQuantity),
        },
        {
            key: 'trueQuantity',
            title: 'Qté réelle',
            flex: '1 1 25%',
            render: (record) => formatNumber(record.quantity),
        },
    ];

    useEffect(() => {
        fetchPlaces({ status: InventoryStatus.toValidGap, page });
    }, [fetchPlaces, page]);

    useEffect(() => {
        if (
            previous?.inventoryPlaceUpdateState.loading &&
            !inventoryPlaceUpdateState.loading &&
            !inventoryPlaceUpdateState.error
        ) {
            fetchPlaces({ status: InventoryStatus.toValidGap });
        }
    }, [
        previous?.inventoryPlaceUpdateState.loading,
        inventoryPlaceUpdateState.loading,
        inventoryPlaceUpdateState.error,
        fetchPlaces,
    ]);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Régularisation de stock" />
            <Header title="Régularisation de stock" backRoute={getRoute(RoutePathName.inventoryMenu)} />
            <List<InventoryPlace>
                columns={columns}
                data={inventoryPlacesState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowFocus={setSelectedInventoryPlace}
                onRowClick={setSelectedInventoryPlace}
                isRowSelected={(record) => !!selectedInventoryPlace && record.id === selectedInventoryPlace?.id}
                isLoading={inventoryPlacesState.loading}
                title={
                    inventoryPlacesState.data
                        ? `${inventoryPlacesState.data?.totalCount} écart${
                              inventoryPlacesState.data?.totalCount > 1 ? 's' : ''
                          } à valider`
                        : 'Écarts à valider'
                }
                pagination={{
                    total: inventoryPlacesState.data?.totalCount,
                    pageSize: inventoryPlacesState.data?.pageSize,
                    hideOnSinglePage: true,
                    current: page + 1,
                    onChange: onPaginationChange,
                }}
            />
            {!!inventoryPlacesState.data?.items?.length && (
                <FixedFooter>
                    <BasicList inline inlineStretch>
                        <li>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    shortcut="1"
                                    as={ButtonGrey}
                                    onClick={onClickValidate}
                                    size="large"
                                    block
                                >
                                    Valider l&rsquo;écart
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </li>
                        <li>
                            <ArrowNavItem>
                                <ButtonWithShortcut shortcut="2" onClick={onClickEditDelta} size="large" block>
                                    Saisir variation
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </li>
                    </BasicList>
                </FixedFooter>
            )}
            <StockGapModal
                visible={isConfirmModalVisible}
                onCancel={onCloseStockGapModal}
                inventoryPlace={selectedInventoryPlace}
                hasInput={isDeltaEditable}
            />
        </FixedFooter.Wrapper>
    );
};

export default InventoryStockGapList;
