import { takeLatest } from 'redux-saga/effects';
import { combineReducers } from 'redux';
// Helpers
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { requestInitialState, requestReducer } from '../helpers';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { MainReducerState, RequestState } from '../reducers';

// Types
import { Machine } from '../api/apiTypes';
import { ListResponse } from '../api';
// Controllers
import {
    MachinesCreatePayload,
    MachinesDeletePayload,
    MachinesListPayload,
    list as listApiCall,
    create as createApiCall,
    del as deleteApiCall,
} from '../api/machines';

// State
export interface MachinesState {
    create: RequestState<Machine>;
    list: RequestState<ListResponse<Machine>>;
    del: RequestState;
}
const initialState: MachinesState = {
    create: { ...requestInitialState },
    list: { ...requestInitialState },
    del: { ...requestInitialState },
};
export const create = new EzeeAsyncAction<MachinesState['create'], MachinesCreatePayload, Machine>(
    'machines/create',
    initialState.create,
    requestReducer<MachinesState['create'], Machine>(initialState.create)
);

export const list = new EzeeAsyncAction<MachinesState['list'], MachinesListPayload, ListResponse<Machine>>(
    'machines/list',
    initialState.list,
    requestReducer<MachinesState['list'], ListResponse<Machine>>(initialState.list)
);
export const del = new EzeeAsyncAction<MachinesState['del'], MachinesDeletePayload>(
    'machines/del',
    initialState.del,
    requestReducer<MachinesState['del']>(initialState.del)
);

export const machinesReducer = combineReducers<MachinesState>({
    create: create.reducer,
    list: list.reducer,
    del: del.reducer,
});

export function* machinesSaga() {
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(deleteApiCall, del));
}

export const getMachinesCreateState = (state: MainReducerState) => state.machines.create;
export const getMachinesListState = (state: MainReducerState) => state.machines.list;
export const getMachinesDelState = (state: MainReducerState) => state.machines.del;
