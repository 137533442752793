import React, { useCallback, useEffect, useState } from 'react';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import CustomModal from '../../components/CustomModal';
import { Incident, Machine } from '../../store/api/apiTypes';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import { Col, Form, message, Row, Select, Typography } from 'antd';
import { getMachinesListState, list as machinesListAction } from '../../store/actions/machines';
import { getIncidentsListState, list as incidentsListAction } from '../../store/actions/incidents';
import {
    create as machineIncidentCreateAction,
    getMachineIncidentsIncidentsCreateState,
} from '../../store/actions/machinesIncidents';
import { useSelector } from 'react-redux';
import { WarningOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import SuccessMessage from '../../components/SuccessMessage';

const shortcutScope = 'ReportIncidentModal';

type reportIncidentSteps = 'select' | 'confirm' | 'success';

interface ReportIncidentModalProps {
    isOpenedFromLogin?: boolean;
}

const ReportIncidentModal = NiceModal.create<ReportIncidentModalProps>(({ isOpenedFromLogin }) => {
    const modal = useModal();
    const { hide, visible } = modal;
    const [machine, setMachine] = useState<Machine>();
    const [incident, setIncident] = useState<Incident>();
    const [step, setStep] = useState<reportIncidentSteps>('select');
    const [fetchMachines, fetchIncidents, createMachineIncident] = useActions([
        machinesListAction.trigger,
        incidentsListAction.trigger,
        machineIncidentCreateAction.trigger,
    ]);
    const machinesState = useSelector(getMachinesListState);
    const incidentsState = useSelector(getIncidentsListState);
    const machineIncidentCreateState = useSelector(getMachineIncidentsIncidentsCreateState);
    const previous = usePrevious({ machineIncidentCreateState, incidentsState });
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const canConfirm = !!machine && !!incident;

    const onChangeMachine = (machineId: string) => {
        if (machinesState.data) {
            const selectedMachine = machinesState.data.items.find((machine) => machine.id === parseInt(machineId, 10));
            if (selectedMachine) {
                setMachine(selectedMachine);
            }
        }
    };
    const onChangeIncident = (incidentId: string) => {
        if (incidentsState.data) {
            const selectedIncident = incidentsState.data.items.find(
                (incident) => incident.id === parseInt(incidentId, 10)
            );
            if (selectedIncident) {
                setIncident(selectedIncident);
            }
        }
    };

    const onValid = useCallback(() => {
        if (step === 'select' && machine && incident) {
            if (incident.label === 'RAS') {
                createMachineIncident({
                    machineId: machine.id,
                    incidentId: incident.id,
                });
            } else {
                setStep('confirm');
            }
        } else if (step === 'confirm') {
            if (machine && incident) {
                createMachineIncident({
                    machineId: machine.id,
                    incidentId: incident.id,
                });
            }
        }
    }, [createMachineIncident, incident, machine, step]);

    const onCancel = useCallback(() => {
        if (step === 'select') {
            hide();
        } else if (step === 'confirm') {
            setStep('select');
        }
    }, [hide, step]);

    useEffect(() => {
        fetchMachines();
    }, [fetchMachines]);

    useEffect(() => {
        fetchIncidents();
    }, [fetchIncidents]);

    useEffect(() => {
        if (previous?.incidentsState.loading && !incidentsState.loading) {
            if (incidentsState.error) {
                message.error(`Erreur lors de la récupération des incidents`);
            } else if (incidentsState.data?.items.length) {
                setIncident(incidentsState.data.items.find((incident) => incident.label === 'RAS'));
            }
        }
    }, [incidentsState.data?.items, incidentsState.error, incidentsState.loading, previous?.incidentsState.loading]);

    useEffect(() => {
        if (previous?.machineIncidentCreateState.loading && !machineIncidentCreateState.loading) {
            if (machineIncidentCreateState.error) {
                message.error(`Erreur lors de la création de l'incident`);
            } else if (machineIncidentCreateState.data) {
                setStep('success');
            }
        }
    }, [
        machineIncidentCreateState.data,
        machineIncidentCreateState.error,
        machineIncidentCreateState.loading,
        previous?.machineIncidentCreateState.loading,
    ]);

    useEffect(() => {
        let timeout: number;
        if (step === 'success') {
            timeout = window.setTimeout(() => {
                hide();
            }, 2000);
        }
        return () => {
            window.clearTimeout(timeout);
        };
    }, [hide, step]);

    return (
        <CustomModal
            {...antdModal(modal)}
            title={step === 'select' ? (isOpenedFromLogin ? 'Prise de poste' : 'Déclarer un incident') : undefined}
            altTitle
            width={step !== 'success' ? 368 : 232}
            footer={
                step !== 'success' && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                type="primary"
                                shortcut="enter"
                                shortcutScope={shortcutScope}
                                onClick={onValid}
                                disabled={!canConfirm}
                            >
                                {step === 'select' ? 'Valider' : 'Confirmer'}
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                type="primary"
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                onClick={onCancel}
                                ghost
                            >
                                {isOpenedFromLogin && step === 'select' ? `Je n'ai pas d'engin` : 'Annuler'}
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
        >
            {step === 'select' && (
                <>
                    <Form.Item label="Engin" style={{ margin: 0 }} labelCol={{ span: 24 }}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Select
                                placeholder={'Sélectionner un engin'}
                                loading={machinesState.loading}
                                filterOption={false}
                                onChange={onChangeMachine}
                                style={{ width: '100%' }}
                                allowClear
                                showArrow
                                defaultValue={machine ? `${machine.label}` : undefined}
                                onClear={() => setMachine(undefined)}
                            >
                                {machinesState.data?.items?.map((machine) => (
                                    <Select.Option value={machine.id} key={machine.id}>
                                        {machine.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </ArrowNavItem>
                    </Form.Item>
                    <Form.Item label="Type d'incident" style={{ marginTop: 20 }} labelCol={{ span: 24 }}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Select
                                placeholder={'Sélectionner un incident'}
                                loading={incidentsState.loading}
                                filterOption={false}
                                onChange={onChangeIncident}
                                style={{ width: '100%' }}
                                allowClear
                                showArrow
                                defaultValue={incident ? `${incident.label}` : `RAS`}
                                onClear={() => setIncident(undefined)}
                            >
                                {incidentsState.data?.items?.map((incident) => (
                                    <Select.Option value={incident.id} key={incident.id}>
                                        {incident.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </ArrowNavItem>
                    </Form.Item>
                </>
            )}
            {step === 'confirm' && (
                <Row justify="center">
                    <Col xs={30} className="text-center">
                        <Typography.Title level={4} style={{ fontSize: '35px' }} type="warning">
                            <WarningOutlined />
                        </Typography.Title>
                        <Text strong>Vous êtes sur le point de déclarer l&lsquo;incident suivant:</Text>
                        <br />
                        <br />
                        <Text strong className={'text-highlighted'}>
                            {machine?.label} - {incident?.label}
                        </Text>
                        <br />
                        <br />
                        <Text strong>Souhaitez-vous confirmer ?</Text>
                    </Col>
                </Row>
            )}
            {step === 'success' && <SuccessMessage message={`Incident déclaré avec succès`} />}
        </CustomModal>
    );
});

export default ReportIncidentModal;
