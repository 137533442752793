import { Col, Descriptions, Divider, message, Row, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    CustomerOrderStatus,
    Permission,
    Preparation,
    PreparationOrderType,
    PreparationStatus,
} from '../../store/api/apiTypes';
import {
    details as customerOrderDetails,
    getCustomerOrderStateById,
    update as customerOrderUpdate,
} from '../../store/actions/customerOrders';
import {
    getPreparationListByTypeAndCustomerOrderIdState,
    getPreparationStartState,
    getPreparationUpdateState,
    listByTypeAndCustomerOrderId as preparationsListByType,
    start as preparationStart,
    update as preparationUpdate,
} from '../../store/actions/preparations';
import { getUser } from '../../store/actions/auth';

import FixedFooter from '../../components/FixedFooter';
import Header from '../../components/Header';
import List, { ListColumn } from '../../components/list/List';
import PageHeader from '../../components/PageHeader';
import Seo from '../../components/Seo';
import { useActions, usePrevious } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import { formatDate, translateCustomerOrderStatus, translatePreparationStatus } from '../../helpers/i18n';
import TitleBlack from '../../components/TitleBlack';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import PreparationTypeModal from './PreparationTypeModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonGrey from '../../components/ButtonGrey';
import { getOperatorName } from '../../helpers';
import { isZebra } from '../../helpers/enterprise-browser';
import { hasPermission } from '../../helpers/security';
import ButtonLink from '../../components/ButtonLink';
import { useModal } from '@ebay/nice-modal-react';
import ConfirmBlockCustomerModal from './ConfirmBlockCustomerModal';
import ValidSAVModal from './ValidSAVModal';

const getPreparationDetailsRoute = (record?: Preparation) => {
    let route = RoutePathName.preparationDetails;

    if (record?.type === PreparationOrderType.detail) {
        route = RoutePathName.preparationDetailType;
    } else if (record?.type === PreparationOrderType.none && record?.picking && !record?.direct) {
        route = RoutePathName.preparationPicking;
    }

    return route;
};

const CustomerOrderDetails: FC = () => {
    const history = useHistory();
    const [isHeaderExpanded, setIsHeaderExpanded] = useState(false);
    const [isPreparationTypeModalOpen, setIsPreparationTypeModalOpen] = useState(false);
    const [selectedPreparationId, setSelectedPreparationId] = useState<Preparation['id']>();
    const [preparationIdToUpdate, setPreparationIdToUpdate] = useState<Preparation['id']>();
    const { loadingOrderId, customerOrderId: customerOrderIdParam } =
        useParams<{ loadingOrderId: string; customerOrderId: string }>();
    const customerOrderId = parseInt(customerOrderIdParam, 10);
    const user = useSelector(getUser);
    const canReadDesktop = !isZebra && hasPermission(user, Permission.preparation);
    const [fetchCustomerOrder, listPreparationsByType, updateCustomerOrder, updatePreparation, startPreparation] =
        useActions([
            customerOrderDetails.trigger,
            preparationsListByType.trigger,
            customerOrderUpdate.trigger,
            preparationUpdate.trigger,
            preparationStart.trigger,
        ]);
    const preparationListByTypeState = useSelector(getPreparationListByTypeAndCustomerOrderIdState(customerOrderId));
    const customerOrderState = useSelector(getCustomerOrderStateById(customerOrderId));
    const customerOrderUpdateState = useSelector(getPreparationUpdateState);
    const preparationUpdateState = useSelector(getPreparationUpdateState);
    const preparationStartState = useSelector(getPreparationStartState);
    const previous = usePrevious({
        customerOrderState,
        customerOrderUpdateState,
        preparationUpdateState,
        preparationStartState,
        preparationIdToUpdate,
    });
    const isPreparationDisabled =
        customerOrderState.data?.status &&
        [CustomerOrderStatus.toBeLoaded, CustomerOrderStatus.loaded].includes(customerOrderState.data?.status);
    const [customerOrderIsBlocked, setCustomerOrderIsBlocked] = useState(customerOrderState.data?.isBlocked);
    const confirmBlockCustomerModal = useModal(ConfirmBlockCustomerModal);
    const validSAVModal = useModal(ValidSAVModal);
    const onListRowEnter = (record: Preparation) => {
        // can't be here if !canReadDesktop && toBeProcessed || inProgress && operator && operator is different
        if (record.status !== PreparationStatus.toBeProcessed) {
            if (isZebra) {
                if (user?.id === record.operator?.id) {
                    const route = getPreparationDetailsRoute(record);
                    history.push(
                        getRoute(route, {
                            preparationId: record.id,
                            loadingOrderId,
                            customerOrderId,
                        })
                    );
                } else if (record.status === PreparationStatus.inProgress && !record.operator) {
                    // this happens when the preparation is in progress and has been unblocked by an admin
                    // so we have to reassign it to the current user that clicked on it
                    setPreparationIdToUpdate(record.id);
                }
            } else if (canReadDesktop && record.type !== PreparationOrderType.sav) {
                history.push(
                    getRoute(RoutePathName.preparationDetails, {
                        preparationId: record.id,
                        loadingOrderId,
                        customerOrderId,
                    })
                );
            }
        } else {
            if (canReadDesktop && record.operator && record.type !== PreparationOrderType.sav) {
                history.push(
                    getRoute(RoutePathName.preparationDetails, {
                        preparationId: record.id,
                        loadingOrderId,
                        customerOrderId,
                    })
                );
            } else {
                setSelectedPreparationId(record.id);

                if (record.type === PreparationOrderType.detail) {
                    startPreparation({ preparationId: record.id, type: PreparationOrderType.detail });
                } else if (record.type === PreparationOrderType.sav) {
                    validSAVModal.show({ preparationId: record.id, customerOrderId });
                } else {
                    setIsPreparationTypeModalOpen(true);
                }
            }
        }
    };
    const onClickControl = () => {
        history.push(
            getRoute(RoutePathName.preparationControl, {
                loadingOrderId: loadingOrderId ?? '',
                customerOrderId: customerOrderId ?? '',
            })
        );
    };

    const onClickBlockClient = async () => {
        fetchCustomerOrder({ customerOrderId });
        await confirmBlockCustomerModal.show({
            customerOrderId,
            customerOrderIsBlocked,
            setCustomerOrderIsBlocked,
        });
    };
    const columns: Array<ListColumn<Preparation>> = (
        [
            {
                dataIndex: 'parcel.reference',
                title: 'Référence colis',
                flex: '1 1 50%',
            },
            {
                dataIndex: 'quantity',
                title: 'Quantité',
                flex: '1 1 20%',
            },
            {
                key: 'status',
                title: 'Statut',
                flex: '1 1 35%',
                render: (record) => translatePreparationStatus(record.status),
            },
            canReadDesktop && {
                key: 'operator',
                title: 'Cariste',
                flex: '1 1 30%',
                render: (record) => getOperatorName(record.operator) || '—',
            },
        ] as Array<ListColumn<Preparation>>
    ).filter(Boolean);

    useEffect(() => {
        if (customerOrderId) {
            fetchCustomerOrder({ customerOrderId });
        }
    }, [fetchCustomerOrder, customerOrderId]);

    useEffect(() => {
        if (previous?.customerOrderState.loading && !customerOrderState.loading) {
            if (!customerOrderState.error) {
                listPreparationsByType({
                    customerOrderId,
                    status: [
                        PreparationStatus.toBeProcessed,
                        PreparationStatus.inProgress,
                        PreparationStatus.completed,
                        PreparationStatus.toRegularize,
                        PreparationStatus.partial,
                    ],
                    pageSize: 300,
                });
                if (customerOrderState.data?.isBlocked && !customerOrderIsBlocked) {
                    setCustomerOrderIsBlocked(customerOrderState.data?.isBlocked);
                }
            }
        }
    }, [
        previous?.customerOrderState,
        customerOrderState,
        listPreparationsByType,
        customerOrderId,
        customerOrderIsBlocked,
    ]);

    useEffect(() => {
        // this happens when the preparation is in progress and has been unblocked by an admin
        // so we have to reassign it to the current user that clicked on it
        if (previous?.preparationIdToUpdate === undefined && preparationIdToUpdate !== undefined) {
            updatePreparation({
                id: preparationIdToUpdate,
                operatorId: user?.id,
            });
        }
    }, [preparationIdToUpdate, previous?.preparationIdToUpdate, updatePreparation, user?.id]);

    // after assigning a new operator
    useEffect(() => {
        if (
            preparationIdToUpdate !== undefined &&
            previous?.preparationUpdateState.loading &&
            !preparationUpdateState.loading
        ) {
            if (preparationUpdateState.error) {
                message.error('Une erreur est survenue lors de la mise à jour');
            } else {
                const route = getPreparationDetailsRoute(preparationUpdateState.data);
                history.push(
                    getRoute(route, {
                        preparationId: preparationUpdateState.data?.id ?? '',
                        loadingOrderId,
                        customerOrderId,
                    })
                );

                setPreparationIdToUpdate(undefined);
            }
        }
    }, [
        previous?.preparationUpdateState,
        preparationUpdateState,
        updateCustomerOrder,
        listPreparationsByType,
        customerOrderId,
        history,
        loadingOrderId,
        preparationIdToUpdate,
    ]);

    // when choosing a preparation of type detail
    useEffect(() => {
        if (!isPreparationTypeModalOpen && previous?.preparationStartState.loading && !preparationStartState.loading) {
            if (!preparationStartState.error) {
                history.push(
                    getRoute(RoutePathName.preparationDetailType, {
                        preparationId: selectedPreparationId ?? '',
                        loadingOrderId: loadingOrderId ?? '',
                        customerOrderId: customerOrderId ?? '',
                    })
                );
            } else if (preparationStartState.error?.status === 400) {
                message.error("Il n'y a pas de stock disponible pour l'instant");
            } else {
                message.error('Une erreur est survenue lors de la récupération de la préparation de type détail');
            }
        }
    }, [
        isPreparationTypeModalOpen,
        history,
        loadingOrderId,
        customerOrderId,
        selectedPreparationId,
        preparationStartState.error,
        preparationStartState.loading,
        previous?.preparationStartState.loading,
    ]);

    let nbOfPackageToPrepare: string | undefined;
    let nbOfPackageToControl: string | undefined;
    if (customerOrderState.data?.quantity) {
        nbOfPackageToPrepare = `${customerOrderState.data.preparedQuantity ?? 0}/${customerOrderState.data?.quantity}`;

        nbOfPackageToControl = `${customerOrderState.data.controlledQuantity ?? 0}/${
            customerOrderState.data?.quantity
        }`;
    }

    return (
        <FixedFooter.Wrapper>
            <Seo title="Détail client" />
            <Header
                title="Détail client"
                backRoute={getRoute(RoutePathName.loadingOrderDetails, { loadingOrderId })}
                enableHomeButton
            />
            <PageHeader>
                <Spin spinning={customerOrderState.loading}>
                    <Descriptions column={{ xs: 5, md: 4 }} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="Code client">
                            {customerOrderState.data?.customer?.reference ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Rang">{customerOrderState.data?.rank ?? '—'}</Descriptions.Item>
                        <Descriptions.Item label="Colis">{customerOrderState.data?.quantity ?? '—'}</Descriptions.Item>
                        <Descriptions.Item label="Statut" span={2}>
                            {translateCustomerOrderStatus(customerOrderState.data?.status)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Client" span={5}>
                            {customerOrderState.data?.customer?.label ?? '—'}
                        </Descriptions.Item>
                        {isHeaderExpanded && (
                            <>
                                <Descriptions.Item label="N° de tournée" span={2}>
                                    {customerOrderState.data?.loadingOrder?.tourNumber ?? '—'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Date de chargement" span={3}>
                                    {formatDate(customerOrderState.data?.loadingOrder?.date)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Code plateforme" span={2}>
                                    {customerOrderState.data?.loadingOrder?.plateformCode ?? '—'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Transporteur" span={3}>
                                    {customerOrderState.data?.loadingOrder?.carrier?.label ?? '—'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Poids">
                                    {customerOrderState.data?.weightEstimated
                                        ? new Intl.NumberFormat('fr-FR', {
                                              style: 'unit',
                                              unit: 'kilogram',
                                          }).format(customerOrderState.data?.weightEstimated ?? 0)
                                        : '—'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Volume">
                                    {customerOrderState.data?.volumeEstimated ? (
                                        <span>
                                            {customerOrderState.data.volumeEstimated} m<sup>3</sup>
                                        </span>
                                    ) : (
                                        '—'
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label="Palette" span={3}>
                                    {customerOrderState.data?.nbOfPalletEstimated ?? '—'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Instructions livraison" span={5}>
                                    {customerOrderState.data?.deliveryInstruction ?? '—'}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={`Préparé${customerOrderState.data?.preparedQuantity !== 1 ? 's' : ''}`}
                                    span={2}
                                >
                                    {nbOfPackageToPrepare ?? '-'}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={`Controlé${customerOrderState.data?.controlledQuantity !== 1 ? 's' : ''}`}
                                >
                                    {nbOfPackageToControl ?? '-'}
                                </Descriptions.Item>
                            </>
                        )}
                    </Descriptions>
                    <Row gutter={24}>
                        <Col flex="auto">
                            <ButtonWithShortcut
                                onClick={setIsHeaderExpanded.bind(null, !isHeaderExpanded)}
                                shortcut="1"
                                size="small"
                                block
                            >
                                {isHeaderExpanded ? 'Moins' : 'Plus'} de détail
                            </ButtonWithShortcut>
                        </Col>
                        {canReadDesktop && (
                            <>
                                <Col flex="auto">
                                    <ButtonWithShortcut
                                        as={ButtonLink}
                                        to={getRoute(RoutePathName.orderList, {
                                            loadingOrderId,
                                            customerOrderId,
                                        })}
                                        shortcut="2"
                                        size="small"
                                        block
                                    >
                                        Liste des commandes
                                    </ButtonWithShortcut>
                                </Col>
                                <Col flex="auto">
                                    <ButtonWithShortcut onClick={onClickBlockClient} shortcut="3" size="small" block>
                                        {!customerOrderIsBlocked
                                            ? `Bloquer la clôture client`
                                            : `Débloquer la clôture client`}
                                    </ButtonWithShortcut>
                                </Col>
                            </>
                        )}
                    </Row>
                </Spin>
            </PageHeader>
            <TitleBlack>Préparations</TitleBlack>
            <Divider />
            <Spin spinning={!isPreparationTypeModalOpen && preparationStartState.loading}>
                <List<Preparation>
                    columns={columns}
                    data={preparationListByTypeState.data?.sav?.items}
                    rowKey={(record) => `${record.id}`}
                    onRowClick={isPreparationDisabled ? undefined : onListRowEnter}
                    onRowEnterPress={isPreparationDisabled ? undefined : onListRowEnter}
                    isRowLocked={(record) =>
                        !canReadDesktop &&
                        (record.status === PreparationStatus.inProgress ||
                            record.status === PreparationStatus.toBeProcessed) &&
                        !!record.operator &&
                        user?.id !== record.operator?.id
                    }
                    isRowHiglighted={(record) => record.status === PreparationStatus.completed}
                    isRowWarning={(record) =>
                        [PreparationStatus.toRegularize, PreparationStatus.partial].includes(record.status)
                    }
                    noWarningIcon={(record) => record.status === PreparationStatus.partial}
                    isLoading={preparationListByTypeState.loading}
                    title="sav"
                    altTitle
                />
            </Spin>
            <List<Preparation>
                columns={columns}
                data={preparationListByTypeState.data?.detail?.items}
                rowKey={(record) => `${record.id}`}
                onRowClick={isPreparationDisabled ? undefined : onListRowEnter}
                onRowEnterPress={isPreparationDisabled ? undefined : onListRowEnter}
                isRowLocked={(record) =>
                    !canReadDesktop &&
                    (record.status === PreparationStatus.inProgress ||
                        record.status === PreparationStatus.toBeProcessed) &&
                    !!record.operator &&
                    user?.id !== record.operator?.id
                }
                isRowHiglighted={(record) => record.status === PreparationStatus.completed}
                isRowWarning={(record) =>
                    [PreparationStatus.toRegularize, PreparationStatus.partial].includes(record.status)
                }
                style={{ marginTop: '1.5rem' }}
                noWarningIcon={(record) => record.status === PreparationStatus.partial}
                isLoading={preparationListByTypeState.loading}
                title="Détail"
                altTitle
            />
            <List<Preparation>
                columns={columns}
                data={preparationListByTypeState.data?.none?.items}
                rowKey={(record) => `${record.id}`}
                onRowClick={isPreparationDisabled ? undefined : onListRowEnter}
                onRowEnterPress={isPreparationDisabled ? undefined : onListRowEnter}
                isRowLocked={(record) =>
                    !canReadDesktop &&
                    (record.status === PreparationStatus.inProgress ||
                        record.status === PreparationStatus.toBeProcessed) &&
                    !!record.operator &&
                    user?.id !== record.operator?.id
                }
                isRowHiglighted={(record) => record.status === PreparationStatus.completed}
                isRowWarning={(record) =>
                    [PreparationStatus.toRegularize, PreparationStatus.partial].includes(record.status)
                }
                isLoading={preparationListByTypeState.loading}
                title="Picking / Direct"
                style={{ marginTop: '1.5rem' }}
                noWarningIcon={(record) => record.status === PreparationStatus.partial}
                altTitle
            />
            <PreparationTypeModal
                visible={isPreparationTypeModalOpen}
                onCancel={setIsPreparationTypeModalOpen.bind(null, false)}
                preparationId={selectedPreparationId}
            />
            {isZebra && customerOrderState.data?.controlStatus !== null && (
                <FixedFooter>
                    <ArrowNavItem>
                        <ButtonWithShortcut shortcut="f1" as={ButtonGrey} onClick={onClickControl} block>
                            Contrôle des colis
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </FixedFooter>
            )}
        </FixedFooter.Wrapper>
    );
};

export default CustomerOrderDetails;
