import { PaginationQuery, SearchQuery } from './index';

import { MovementHistoryType, PlaceZoneType, RepairStatus, Repair } from './apiTypes';
import api from './_client';

export interface RepairCreatePayload {
    movementBrokenId: number;
    quantity: number;
    status: RepairStatus;
}

export const create = async (payload: RepairCreatePayload) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return await api.post(`/movementRepairs`, payload);
};

export type RepairsListPayload = SearchQuery &
    PaginationQuery & {
        status: RepairStatus | RepairStatus[];
    };

export const list = async (payload: RepairsListPayload) => {
    return await api.get(`/movementRepairs`, { params: payload });
};

export interface RepairIdPayload {
    id: Repair['id'];
}

export const details = async ({ id, ...payload }: RepairIdPayload) => {
    return await api.get(`/movementRepairs/${id}`, { params: payload });
};

export type RepairUpdatePayload = Partial<Repair>;

export const update = async ({ id, ...payload }: RepairUpdatePayload) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return await api.put(`/movementRepairs/${id}`, payload);
};

export const del = async ({ id, ...payload }: RepairIdPayload) => {
    return await api.delete(`/movementRepairs/${id}`, { params: payload });
};

export interface TransferRepairPackagesPayload {
    id: Repair['id'];
    placeId?: number;
    palletId?: number;
    transferedPackages: number;
    finishPlaceZoneType: PlaceZoneType;
    status?: RepairStatus;
}

export const TransferRepairPackages = async ({ id, ...payload }: TransferRepairPackagesPayload) =>
    await api.post(`/movementRepairs/${id}/packages`, payload);

export interface StoreRepairPackagesPayload {
    id: Repair['id'];
    placeId?: number;
    palletId?: number;
    transferedPackages: number;
    type: MovementHistoryType;
    index?: number;
}

export const StoreRepairPackages = async ({ id, ...payload }: StoreRepairPackagesPayload) =>
    await api.post(`/movementRepairs/${id}/storePackages`, payload);
