import React, { useState, forwardRef, useEffect, ForwardRefRenderFunction } from 'react';
import { Select, SelectProps } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { useSelector } from 'react-redux';

import { User } from '../store/api/apiTypes';
import { getUsersListState, list as listUsersAction } from '../store/actions/users';

import { useActions } from '../hooks';
import ArrowNavItem from './ArrowNavItem';
import { getOperatorName } from '../helpers';

interface UserSelectProps {
    size?: SizeType;
    placeholder?: string;
    onChange?: (value: any) => void;
    withArrowNav?: boolean;
    arrowNavScope?: string;
}

const UserSelect: ForwardRefRenderFunction<typeof Select, UserSelectProps> = (props, ref: any) => {
    const listUsers = useActions(listUsersAction.trigger);
    const usersListState = useSelector(getUsersListState);
    const { onChange, size, placeholder, withArrowNav, arrowNavScope } = props;
    const [value, setValue] = useState<User['id'] | undefined>();
    const onSearch: SelectProps<string>['onSearch'] = (value) => {
        listUsers(
            {
                ...(value ? { search: value } : {}),
            },
            { throttling: 300 }
        );
    };

    useEffect(() => {
        setValue(undefined);
        listUsers();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeSelect = (newValue: any) => {
        if (newValue) {
            setValue(newValue);
        } else {
            setValue(undefined);
        }

        if (onChange) {
            onChange(usersListState.data?.items.find((p) => p.id === parseInt(newValue, 10)));
        }
    };

    const select = (
        <Select
            size={size}
            ref={ref}
            value={value}
            placeholder={placeholder ?? 'Choisir un utilisateur'}
            loading={usersListState.loading}
            filterOption={false}
            onChange={onChangeSelect}
            onSearch={onSearch}
            style={{ width: '100%' }}
            allowClear
            showSearch
            showArrow
        >
            {usersListState.data?.items?.map((user) => (
                <Select.Option value={user.id} key={user.id}>
                    {getOperatorName(user)}
                </Select.Option>
            ))}
        </Select>
    );

    return withArrowNav ? <ArrowNavItem scope={arrowNavScope}>{select}</ArrowNavItem> : select;
};

export default forwardRef(UserSelect);
