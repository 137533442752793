import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, Descriptions, Divider, Space, Spin, Typography } from 'antd';

import { PalletPlace, PalletQuality, SearchType } from '../../store/api/apiTypes';
import { getPalletPlaceDetailsState, details } from '../../store/actions/palletPlaces';

import { useActions } from '../../hooks';
import PageHeader from '../../components/PageHeader';
import QuantityCard from '../../components/QuantityCard';
import VerticalDescriptions from '../../components/VerticalDescriptions';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import TitleBlack from '../../components/TitleBlack';
import FixedFooter from '../../components/FixedFooter';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonLink from '../../components/ButtonLink';
import useQueryParams from '../../hooks/queryParams';
import { getRoute, RoutePathName } from '../../routes';
import BasicList from '../../components/BasicList';
import { formatNumber } from '../../helpers/i18n';
import { formatPlace } from '../../helpers';

interface SearchDetailsPlaceProps {
    id: PalletPlace['id'];
}

const SearchDetailsPlace: FC<SearchDetailsPlaceProps> = ({ id }) => {
    const [urlSearchParams] = useQueryParams('searchDetailsPlace');
    const placeStoreLabel = urlSearchParams.get('placeStoreLabel');
    const fetchPalletPlace = useActions(details.trigger);
    const palletPlaceState = useSelector(getPalletPlaceDetailsState(id));
    const parcel = palletPlaceState.data?.pallet?.parcel ?? palletPlaceState.data?.place?.parcel;

    useEffect(() => {
        fetchPalletPlace({ palletPlaceId: id });
    }, [fetchPalletPlace, id]);

    return (
        <FixedFooter.Wrapper>
            <PageHeader>
                <Spin spinning={palletPlaceState.loading}>
                    <Descriptions column={{ xs: 2 }} size="small" colon={false} layout="vertical">
                        {placeStoreLabel && <Descriptions.Item label="Site">{placeStoreLabel}</Descriptions.Item>}
                        <Descriptions.Item label="Emplacement">
                            {formatPlace(palletPlaceState.data?.place)}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>
            {parcel ? (
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <Card>
                        <TitleBlack>Information colis</TitleBlack>
                        <Divider />
                        <VerticalDescriptions
                            items={[
                                { label: 'Référence', value: parcel.reference ?? '—' },
                                {
                                    label: 'Désignation',
                                    value: parcel.label ?? '—',
                                },
                            ]}
                            inCard
                        />
                    </Card>
                    <QuantityCard
                        value={formatNumber(palletPlaceState.data?.pallet?.quantity)}
                        label="Colis en stock"
                    />
                    {palletPlaceState.data?.pallet && (
                        <Card>
                            <VerticalDescriptions
                                items={[
                                    {
                                        label: 'Référence palette',
                                        value: (
                                            <Typography.Text
                                                type={
                                                    palletPlaceState.data?.pallet?.quality === PalletQuality.noConform
                                                        ? 'warning'
                                                        : undefined
                                                }
                                            >
                                                {palletPlaceState.data?.pallet?.reference ?? '—'}
                                            </Typography.Text>
                                        ),
                                    },
                                ]}
                                inCard
                            />
                        </Card>
                    )}
                </Space>
            ) : (
                <Card>
                    <p style={{ margin: 0 }}>
                        <i>L&rsquo;emplacement est vide</i>
                    </p>
                </Card>
            )}
            {(!!palletPlaceState.data?.pallet?.parcel?.id || !!palletPlaceState.data?.pallet?.id) && (
                <FixedFooter>
                    <BasicList inline inlineStretch>
                        {palletPlaceState.data?.pallet?.parcel?.id && (
                            <li>
                                <ArrowNavItem>
                                    <ButtonWithShortcut
                                        shortcut="f1"
                                        type="primary"
                                        as={ButtonLink}
                                        to={{
                                            pathname: getRoute(RoutePathName.searchDetails, {
                                                type: SearchType.parcels,
                                                id: palletPlaceState.data?.pallet?.parcel?.id,
                                            }),
                                            search: urlSearchParams.toString(),
                                        }}
                                        ghost
                                        block
                                    >
                                        Détail référence
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </li>
                        )}
                        {palletPlaceState.data?.pallet?.id && (
                            <li>
                                <ArrowNavItem>
                                    <ButtonWithShortcut
                                        shortcut="f2"
                                        type="primary"
                                        as={ButtonLink}
                                        to={{
                                            pathname: getRoute(RoutePathName.searchDetails, {
                                                type: SearchType.pallets,
                                                id: palletPlaceState.data?.pallet?.id,
                                            }),
                                            search: urlSearchParams.toString(),
                                        }}
                                        ghost
                                        block
                                    >
                                        Détail palette
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </li>
                        )}
                    </BasicList>
                </FixedFooter>
            )}
        </FixedFooter.Wrapper>
    );
};

export default SearchDetailsPlace;
