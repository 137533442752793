import { SearchPaginationQuery } from '.';
import { Parcel } from './apiTypes';
import api from './_client';

export type ParcelListPayload = SearchPaginationQuery;
export const list = async (payload: ParcelListPayload = {}) => await api.get('/reception/parcels', { params: payload });

export interface ParcelDetailsPayload {
    parcelId: Parcel['id'];
}
export const details = async ({ parcelId, ...payload }: ParcelDetailsPayload) =>
    await api.get(`/parcels/${parcelId}`, { params: payload });
export interface ParcelDetailsByScanPayload {
    barcode?: Parcel['barcode'];
    reference?: Parcel['reference'];
}
export const detailsByScan = async ({ barcode, reference, ...payload }: ParcelDetailsByScanPayload) =>
    await api.get(`/parcels/${reference ? 'reference' : 'barcode'}/${reference ?? barcode ?? ''}`, { params: payload });
