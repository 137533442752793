import React, { useEffect, useRef, useState } from 'react';
import { Input, InputProps, message } from 'antd';
import { useSelector } from 'react-redux';

import { detailsByScan, getParcelScanState } from '../../store/actions/parcels';
import { create as receptionCreate, getReceptionCreateState } from '../../store/actions/receptions';
import { Parcel } from '../../store/api/apiTypes';

import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';

const shortcutScope = 'AddPackageModal';

const AddPackageModal = NiceModal.create(() => {
    const modal = useModal();
    const { visible, hide } = modal;
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const [reference, setReference] = useState<Parcel['reference'] | undefined>();
    const [loadParcelScanDetails, createReception] = useActions([detailsByScan.trigger, receptionCreate.trigger]);
    const parcelScanState = useSelector(getParcelScanState);
    const receptionCreateState = useSelector(getReceptionCreateState);
    const inputRef = useRef<Input | null>(null);
    const previous = usePrevious({ parcelScanState, receptionCreateState });
    const history = useHistory();
    const onAdd = () => {
        loadParcelScanDetails({ reference });
    };
    const onChangeField: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };

    // receive parcel details by barcode (due to scan), create new reception.
    useEffect(() => {
        if (previous?.parcelScanState.loading && !parcelScanState.loading) {
            if (parcelScanState.error) {
                if (parcelScanState.error?.status === 404) {
                    message.error('Colis non reconnu');
                } else {
                    message.error('Erreur lors de la demande de code-barre');
                }
            } else if (parcelScanState.data) {
                const isParcelReceptionExist = !!parcelScanState.data.reception;
                if (isParcelReceptionExist) {
                    message.error('Le colis est en cours de réception ou a déjà été réceptionné');
                } else {
                    createReception({
                        parcelId: parcelScanState.data.id.toString(),
                    });
                }
            }
        }
    }, [
        parcelScanState.data,
        parcelScanState.loading,
        parcelScanState.error,
        previous?.parcelScanState.loading,
        createReception,
    ]);

    // navigate to create a new created pallet of the new reception
    useEffect(() => {
        if (previous?.receptionCreateState.loading && !receptionCreateState.loading) {
            if (receptionCreateState.error) {
                message.error('Erreur lors de la création de la reception');
            } else if (receptionCreateState.data?.parcel?.id) {
                hide();
                history.push(
                    getRoute(RoutePathName.receptionParcelPallet, {
                        receptionId: receptionCreateState.data.id,
                        parcelId: receptionCreateState.data.parcel.id,
                    })
                );
            }
        }
    }, [
        previous?.receptionCreateState.loading,
        receptionCreateState.loading,
        receptionCreateState.data,
        receptionCreateState.data?.parcel?.id,
        history,
        receptionCreateState.error,
        hide,
    ]);

    useScanner(
        'Reception',
        (barCode) => {
            if (barCode.data) {
                loadParcelScanDetails({ barcode: barCode.data });
            }
        },
        {
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    return (
        <CustomModal
            {...antdModal(modal)}
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onAdd}
                            loading={parcelScanState.loading}
                            disabled={!reference}
                        >
                            Ajouter
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={hide}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            title="Ajouter un colis"
            width={368}
            keyboard={false}
            altTitle
        >
            <ArrowNavItem scope={shortcutScope}>
                <Input placeholder="Entrer la référence colis" onChange={onChangeField} ref={inputRef} autoFocus />
            </ArrowNavItem>
        </CustomModal>
    );
});

export default AddPackageModal;
