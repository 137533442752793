import { SearchPaginationQuery } from '.';
import { OrganizationType } from './apiTypes';
import api from './_client';

export type OrganizationListPayload = SearchPaginationQuery & { type?: OrganizationType; isActive?: boolean };
export const list = async (payload: OrganizationListPayload) =>
    await api.get('/organizations', {
        params: {
            ...payload,
            isActive: true,
        },
    });
