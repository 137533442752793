import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { Spin } from 'antd';

import { checkLoginStatus as checkLoginStatusAction, getAuthState } from '../store/actions/auth';
import { getRawRoute, RoutePathName } from '../routes';
import { useActions } from '../hooks';

const ProtectedRoute: FC = ({ children }) => {
    const [checkLoginStatus, logout] = useActions([checkLoginStatusAction.trigger, checkLoginStatusAction.failure]);
    const authState = useSelector(getAuthState);
    const location = useLocation();

    useEffect(() => {
        // dispatched from axios interceptor in api/_client.ts
        window.addEventListener('unauthorized.error', () => logout());

        if (!authState.hasCheckedLoginStatus) {
            checkLoginStatus();
        }

        return () => window.removeEventListener('unauthorized.error', () => logout());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (authState.hasCheckedLoginStatus && !authState.isConnected) {
        return (
            <Redirect
                to={{
                    pathname: getRawRoute(RoutePathName.login),
                    state: { from: location },
                }}
            />
        );
    } else if (authState.hasCheckedLoginStatus && authState.isConnected) {
        return <>{children}</>;
    } else {
        return (
            <Route>
                <div id="initial-loader">
                    <Spin />
                </div>
            </Route>
        );
    }
};

export default ProtectedRoute;
