import React, { FC, ReactNode, useEffect, useCallback } from 'react';
import { useIsMounted } from '../hooks';

import CustomModal, { CustomModalProps } from './CustomModal';
import SuccessMessage from './SuccessMessage';

interface SuccessModalProps extends CustomModalProps {
    message: ReactNode;
    noFooter?: boolean;
    autoClose?: boolean;
}

const SuccessModal: FC<SuccessModalProps> = ({ visible, noFooter, autoClose, message, onCancel, ...props }) => {
    const isMounted = useIsMounted();
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    useEffect(() => {
        let timeout: number;
        if (autoClose && visible) {
            timeout = window.setTimeout(() => {
                if (isMounted.current && visible) {
                    onClose();
                }
            }, 2000);
        }
        return () => window.clearTimeout(timeout);
    }, [visible, autoClose, isMounted, onClose]);

    return (
        <CustomModal
            {...props}
            footer={noFooter ? null : props.footer}
            onCancel={onCancel}
            visible={visible}
            width={props.width ?? 232}
        >
            <SuccessMessage message={message} />
        </CustomModal>
    );
};

export default SuccessModal;
