import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Pallet, PalletSize, PalletStatus, Printer, WMSMode } from '../../store/api/apiTypes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import CustomModal from '../../components/CustomModal';
import { PalletSizeModalContent, PalletSizeModalFooter } from './closePalletModalContents/PalletSizeModalContent';
import {
    create as createPalletAction,
    defaultSize,
    del as delPalletAction,
    getPalletCreateState,
    getPalletDefaultSizeState,
    getPalletDelState,
    getPalletPrintState,
    getPalletUpdateState,
    print as printPalletAction,
    update as updatePalletAction,
} from '../../store/actions/pallets';
import { details as receptionDetails } from '../../store/actions/receptions';
import { useSelector } from 'react-redux';
import { message, Spin } from 'antd';
import { PalletPrintModalContent, PalletPrintModalFooter } from './closePalletModalContents/PalletPrintModalContent';
import { PalletDeleteFooter, PalletDeleteModalTitle } from './closePalletModalContents/PalletDeleteModalContent';
import { PalletPlaceModalContent, PalletPlaceModalFooter } from './closePalletModalContents/PalletPlaceModalContent';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import SuccessMessage from '../../components/SuccessMessage';
import { getPlaceDefaultSectorState } from '../../store/actions/places';
import { getDefaultPrinterState } from '../../store/actions/printers';

type createReceptionPalletStep = 'size' | 'print' | 'printing' | 'delete' | 'place' | 'success';

interface ClosePalletModalProps {
    quantity: number;
}

const shortcutScope = 'ClosePalletModal';

const ClosePalletModal = NiceModal.create<ClosePalletModalProps>(({ quantity }) => {
    const { pallet } = useLocation<{ pallet?: Pallet }>().state || {};
    const { receptionId, parcelId } = useParams<{ receptionId: string; parcelId: string }>();

    const modal = useModal();
    const { hide, visible } = modal;
    const [createPallet, print, deletePallet, fetchReception, updatePallet, setDefaultSize] = useActions([
        createPalletAction.trigger,
        printPalletAction.trigger,
        delPalletAction.trigger,
        receptionDetails.trigger,
        updatePalletAction.trigger,
        defaultSize.actions.setDefaultSize,
    ]);
    const createPalletState = useSelector(getPalletCreateState);
    const printState = useSelector(getPalletPrintState);
    const delPalletState = useSelector(getPalletDelState);
    const defaultSectorState = useSelector(getPlaceDefaultSectorState);
    const defaultSizeState = useSelector(getPalletDefaultSizeState);
    const defaultPrinterState = useSelector(getDefaultPrinterState);
    const updatePalletState = useSelector(getPalletUpdateState);
    const [step, setStep] = useState<createReceptionPalletStep>(pallet ? 'place' : 'size');
    const [size, setSize] = useState<PalletSize | undefined>(defaultSizeState.size);
    const [closePallet, setClosePallet] = useState<Pallet | undefined>(pallet);
    const [previousStep, setPreviousStep] = useState<createReceptionPalletStep | undefined>();
    const [printer, setPrinter] = useState<Printer | undefined>(defaultPrinterState.printer);
    const previous = usePrevious({ createPalletState, printState, delPalletState, updatePalletState });
    const history = useHistory();
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const { sector } = defaultSectorState;

    const onConfirm = useCallback(() => {
        if (step === 'size') {
            if (size) {
                setDefaultSize(size);
                createPallet({
                    receptionId,
                    parcelId,
                    quantity,
                    size,
                    sector,
                });
            }
        } else if (step === 'print') {
            if (printer && closePallet) {
                setPreviousStep('print');
                print({ palletId: closePallet.id, printerId: printer.id, mode: WMSMode.reception });
            }
        } else if (step === 'delete') {
            if (closePallet) {
                deletePallet({
                    palletId: closePallet.id,
                });
            }
        } else if (step === 'place') {
            if (closePallet) {
                setPreviousStep('place');
                updatePallet({
                    id: closePallet.id,
                    status: PalletStatus.closed,
                });
            }
        }
    }, [
        step,
        size,
        setDefaultSize,
        createPallet,
        receptionId,
        parcelId,
        quantity,
        sector,
        printer,
        closePallet,
        print,
        deletePallet,
        updatePallet,
    ]);

    const onClose = useCallback(() => {
        if (step === 'print') {
            setPreviousStep('print');
            setStep('delete');
        } else if (step === 'place') {
            setPreviousStep('place');
            setStep('delete');
        }
    }, [step]);

    useEffect(() => {
        let timeout: number;
        if (step === 'printing') {
            timeout = window.setTimeout(() => {
                setStep('place');
            }, 1000);
        } else if (step === 'success') {
            timeout = window.setTimeout(() => {
                history.push(
                    getRoute(RoutePathName.receptionPalletList, {
                        receptionId,
                        parcelId,
                    })
                );
                hide();
            }, 1000);
        }
        return () => {
            if (timeout) {
                window.clearTimeout(timeout);
            }
        };
    }, [step, setSize, closePallet?.parcel?.id, hide, history, receptionId, parcelId]);

    useEffect(() => {
        if (previous?.createPalletState.loading && !createPalletState.loading) {
            if (createPalletState.error) {
                message.error('Erreur lors de la création de la palette');
            } else if (createPalletState.success) {
                setClosePallet(createPalletState.data);
                setStep('print');
            }
        }
    }, [
        createPalletState.data,
        createPalletState.error,
        createPalletState.loading,
        createPalletState.success,
        previous?.createPalletState.loading,
    ]);

    useEffect(() => {
        if (previous?.updatePalletState.loading && !updatePalletState.loading) {
            if (updatePalletState.error) {
                message.error('Erreur lors de la cloture de la palette');
            } else if (updatePalletState.success) {
                setStep('success');
            }
        }
    }, [
        previous?.updatePalletState.loading,
        updatePalletState.error,
        updatePalletState.loading,
        updatePalletState.success,
    ]);

    useEffect(() => {
        if (previous?.printState.loading && !printState.loading) {
            if (printState.error) {
                message.error(`Erreur lors de l'envoi à l'impression`);
            } else if (printState.success) {
                setStep('printing');
            }
        }
    }, [previous?.printState.loading, printState.error, printState.loading, printState.success]);

    useEffect(() => {
        if (previous?.delPalletState.loading && !delPalletState.loading) {
            if (delPalletState.error) {
                message.error(`Erreur lors de la suppression de la palette`);
            } else if (delPalletState.success) {
                fetchReception({ receptionId });
                hide();
                history.push(
                    getRoute(RoutePathName.receptionParcelPallet, {
                        receptionId,
                        parcelId,
                    }),
                    { pallet: undefined }
                );
            }
        }
    }, [
        delPalletState.error,
        delPalletState.loading,
        delPalletState.success,
        fetchReception,
        hide,
        history,
        parcelId,
        previous?.delPalletState.loading,
        receptionId,
    ]);

    const title = useMemo(() => {
        if (step === 'size') {
            return 'taille de la palette';
        } else if (step === 'print') {
            return `impression de l'étiquette`;
        } else if (step === 'delete') {
            return <PalletDeleteModalTitle />;
        } else if (step === 'place') {
            return `rangement de la palette`;
        }
        return undefined;
    }, [step]);

    const onClickEditPlace = () => {
        if (createPalletState.data?.id) {
            hide();
            history.push(
                getRoute(RoutePathName.receptionPalletPlaceEdit, {
                    receptionId,
                    palletId: createPalletState.data?.id,
                    parcelId,
                }),
                {
                    from: getRoute(RoutePathName.receptionParcelPallet, {
                        receptionId,
                        parcelId,
                    }),
                }
            );
        }
    };

    const contentMap = {
        size: <PalletSizeModalContent shortcutScope={shortcutScope} size={size} setSize={setSize} />,
        print: <PalletPrintModalContent shortcutScope={shortcutScope} setPrinter={setPrinter} />,
        printing: null,
        delete: null,
        place: closePallet ? <PalletPlaceModalContent pallet={closePallet} /> : null,
        success: <SuccessMessage message="Palette clôturée avec succès" />,
    };
    const content = contentMap[step];

    const footerMap = {
        size: (
            <PalletSizeModalFooter
                shortcutScope={shortcutScope}
                size={size}
                onClose={hide}
                onConfirm={onConfirm}
                loading={createPalletState.loading}
            />
        ),
        print: (
            <PalletPrintModalFooter
                shortcutScope={shortcutScope}
                printer={printer}
                onValidate={onConfirm}
                onClose={onClose}
                loading={printState.loading}
            />
        ),
        printing: (
            <div style={{ textAlign: 'center' }}>
                <p>Impression en cours...</p>
                <Spin size="large" />
            </div>
        ),
        delete: (
            <PalletDeleteFooter
                onClose={() => (previousStep ? setStep(previousStep) : undefined)}
                onValid={onConfirm}
                shortcutScope={shortcutScope}
                loading={delPalletState.loading}
            />
        ),
        place: (
            <PalletPlaceModalFooter
                shortcutScope={shortcutScope}
                onValidate={onConfirm}
                onClose={onClose}
                loading={updatePalletState.loading}
                onClickEditPlace={onClickEditPlace}
            />
        ),
        success: null,
    };
    const footer = footerMap[step];

    return (
        <CustomModal
            {...antdModal(modal)}
            footer={footer}
            title={title}
            width={step === 'success' ? 236 : 368}
            altTitle={step !== 'delete'}
        >
            {content}
        </CustomModal>
    );
});

export default ClosePalletModal;
