import { ActionCreator } from 'redux';
import {
    DataAction,
    EzeeAction,
    EzeeCustomActionType as EzeeActionType,
    EzeeCustomActionReducer as EzeeActionReducer,
    ActionMeta,
} from './EzeeAction';

export class EzeeSimpleActionType extends EzeeActionType {
    get trigger() {
        return this.name;
    }

    constructor(name: string, actionTypes: string[]) {
        super(name, actionTypes);
        this.actionTypes = actionTypes.reduce(
            (res, actionType) => ({
                ...res,
                [actionType]: actionType === 'trigger' ? this.name : `${this.name}_${actionType.toUpperCase()}`,
            }),
            {}
        );
    }
}

export interface EzeeSimpleActionReducer<State, Payload = any> extends EzeeActionReducer<State> {
    trigger: (state: State, payload: Payload, meta?: ActionMeta) => State;
}

export class EzeeSimpleAction<State, Payload = any> extends EzeeAction<State, Payload, EzeeSimpleActionType> {
    public trigger: ActionCreator<DataAction<Payload>>;

    constructor(name: string, initialState: State, actionReducer: EzeeSimpleActionReducer<State, Payload>) {
        super(name, initialState, actionReducer);
        const actionTypes = Object.keys(actionReducer);
        this.type = new EzeeSimpleActionType(this.name, actionTypes);
        this.initialState = initialState;
        this.actionReducer = actionReducer;

        this.trigger = (payload: Payload, meta?: ActionMeta) => ({
            type: this.type.trigger,
            payload,
            meta,
        });
    }
}
