import { Input, InputProps, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { list, getPlatformReceptionsListState } from '../../../store/actions/platformReceptions';

import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import { isZebra } from '../../../helpers/enterprise-browser';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useScanner, useShortcutScope } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';

const shortcutScope = 'SearchPlatformReceptionModal';

const SearchPlatformReceptionModal: FC<CustomModalProps> = ({ visible, onCancel }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const history = useHistory();
    const listPlatformReceptions = useActions(list.trigger);
    const [tourNumber, setTourNumber] = useState<string>();
    const platformReceptionListState = useSelector(getPlatformReceptionsListState);
    const previous = usePrevious({ platformReceptionListState });
    const isMounted = useIsMounted();
    const onSubmit = () => {
        listPlatformReceptions({ tourNumber });
    };

    const onChangeField: InputProps['onChange'] = (e) => {
        setTourNumber(e.target.value);
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                listPlatformReceptions({ tourNumber: barCode.data });
            }
        },
        {
            deps: [setTourNumber],
            disable: !visible,
        }
    );

    useEffect(() => {
        if (visible && previous?.platformReceptionListState.loading && !platformReceptionListState.loading) {
            if (platformReceptionListState.error) {
                if (platformReceptionListState.error?.status === 400) {
                    if (platformReceptionListState.error?.data?.cannotBeReceived) {
                        message.error("Cet OC n'est pas encore prêt à être réceptionné");
                    } else {
                        message.error(`Une erreur est survenue lors de la recherche d'OC (400)`);
                    }
                } else if (platformReceptionListState.error?.status === 404) {
                    message.error('OC non trouvé');
                } else {
                    message.error(
                        `Une erreur est survenue lors de la recherche d'OC (${platformReceptionListState.error?.status})`
                    );
                }
            } else {
                if (platformReceptionListState.data?.items.length) {
                    onClose();
                    history.push(
                        getRoute(RoutePathName.platformReception, {
                            platformReceptionId: platformReceptionListState.data?.items[0]?.id ?? '',
                        })
                    );
                } else {
                    message.error('Aucune réception pour cet OC');
                }
            }
        }
    }, [
        previous?.platformReceptionListState,
        platformReceptionListState.loading,
        platformReceptionListState.error,
        isMounted,
        onClose,
        history,
        visible,
        platformReceptionListState.data?.items,
    ]);

    return (
        <CustomModal
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onSubmit}
                            loading={platformReceptionListState.loading}
                            disabled={!tourNumber}
                        >
                            Rechercher le chargement
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClose}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            visible={visible}
            onCancel={onCancel}
            title="Feuille de route de traction"
            width={368}
            keyboard={false}
            altTitle
        >
            <ArrowNavItem scope={shortcutScope}>
                <Input
                    placeholder={isZebra ? 'Flasher/entrer le code-barre' : 'Entrer le code-barre'}
                    value={tourNumber}
                    onChange={onChangeField}
                />
            </ArrowNavItem>
        </CustomModal>
    );
};

export default SearchPlatformReceptionModal;
