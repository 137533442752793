import { Input, InputProps, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { list, getPlatformLoadingsListState } from '../../../store/actions/platformLoadings';

import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import { isZebra } from '../../../helpers/enterprise-browser';
import { useActions, useArrowNavScope, useIsMounted, usePrevious, useScanner, useShortcutScope } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';

const shortcutScope = 'PlatformLoadingScanModal';

const PlatformLoadingScanModal: FC<CustomModalProps> = ({ visible, onCancel }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const history = useHistory();
    const findPlatformLoading = useActions(list.trigger);
    const [reference, setReference] = useState<string>();
    const platformLoadingsListState = useSelector(getPlatformLoadingsListState);
    const previous = usePrevious({ platformLoadingsListState });
    const isMounted = useIsMounted();
    const onSubmit = () => {
        findPlatformLoading({ reference });
    };
    const onChangeField: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                findPlatformLoading({ reference: barCode.data });
            }
        },
        {
            deps: [setReference],
            disable: !visible,
        }
    );

    useEffect(() => {
        if (visible && previous?.platformLoadingsListState.loading && !platformLoadingsListState.loading) {
            if (platformLoadingsListState.error && !true) {
                if (platformLoadingsListState.error?.status === 400) {
                    if (platformLoadingsListState.error?.data?.laodingOrderNotCompleted) {
                        message.error("La préparation/contrôle de l'OC n'est pas encore terminée");
                    } else if (platformLoadingsListState.error?.data?.operatorAlreadyAssigned) {
                        message.error('Cet OC est déjà assigné à un autre chargeur');
                    } else {
                        message.error("Une erreur est survenue lors de la recherche d'OC (400)");
                    }
                } else if (platformLoadingsListState.error?.status === 404) {
                    message.error('OC non trouvé');
                } else {
                    message.error(
                        `Une erreur est survenue lors de la recherche d'OC (${
                            platformLoadingsListState.error?.status ?? '0'
                        })`
                    );
                }
            } else {
                onClose();
                const loadingOrder = platformLoadingsListState.data?.items[0] ?? null;
                if (!loadingOrder) {
                    message.error('Aucune charge trouvée avec ce numéro de tournée');
                } else {
                    const redirectTo =
                        !loadingOrder.customerOrders || loadingOrder.customerOrders?.length > 1
                            ? getRoute(RoutePathName.platformLoadingDetails, { loadingId: loadingOrder.id })
                            : getRoute(RoutePathName.platformLoadingCustomerOrderDetails, {
                                  customerOrderId: loadingOrder.customerOrders[0]?.id,
                                  loadingId: loadingOrder?.id,
                              });

                    history.push(redirectTo);
                }
            }
        }
    }, [
        previous?.platformLoadingsListState,
        platformLoadingsListState.loading,
        platformLoadingsListState.error,
        isMounted,
        onClose,
        history,
        visible,
        platformLoadingsListState.data?.items,
    ]);

    return (
        <CustomModal
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            type="primary"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={onSubmit}
                            loading={platformLoadingsListState.loading}
                            disabled={!reference}
                        >
                            Rechercher le chargement
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onClose}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            visible={visible}
            onCancel={onCancel}
            title="Feuille de route de livraison"
            width={368}
            keyboard={false}
            altTitle
        >
            <ArrowNavItem scope={shortcutScope}>
                <Input
                    placeholder={isZebra ? 'Flasher / Saisir le code-barres' : 'Saisir le code-barres'}
                    value={reference}
                    onChange={onChangeField}
                />
            </ArrowNavItem>
        </CustomModal>
    );
};

export default PlatformLoadingScanModal;
