import { Card, Descriptions, Form, FormProps, InputNumber, message, Spin } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import '../../../assets/styles/Rdv.less';

import {
    details,
    getCustomerOrderStateById,
    detailsPolling as customerOrderDetailsPolling,
} from '../../../store/actions/customerOrders';
import { CustomerOrderUpdatePayload } from '../../../store/api/customerOrders';

import FixedFooter from '../../../components/FixedFooter';
import Header from '../../../components/Header';
import PageHeader from '../../../components/PageHeader';
import Seo from '../../../components/Seo';
import { useActions, usePrevious } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import TitleBlack from '../../../components/TitleBlack';
import ArrowNavItem from '../../../components/ArrowNavItem';
import DatePicker from '../../../components/DatePicker';
import TimePicker from '../../../components/TimePicker';
import BasicList from '../../../components/BasicList';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import ButtonGrey from '../../../components/ButtonGrey';
import RdvCustomerOrderEditModal from './RdvCustomerOrderEditModal';
import { requiredRule } from '../../../helpers';
import RdvCustomerOrderHistory from './RdvCustomerOrderHistory';
import { convertDayjsToSeconds, convertSecondsToDayjs } from '../../../helpers/i18n';

const RdvCustomerOrder: FC = () => {
    const history = useHistory();
    const location = useLocation();
    const { customerOrderId: customerOrderIdParam } = useParams<{ customerOrderId: string }>();
    const customerOrderId = parseInt(customerOrderIdParam, 10);
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [customerOrderUpdatePayload, setCustomerOrderUpdatePayload] = useState<CustomerOrderUpdatePayload>();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [getCustomerOrder, stopCustomerOrderPolling] = useActions([
        details.trigger,
        customerOrderDetailsPolling.actions.stopPolling,
    ]);
    const customerOrderState = useSelector(getCustomerOrderStateById(customerOrderId));
    const previous = usePrevious({ customerOrderState });
    const backRoute = {
        pathname: getRoute(RoutePathName.platformRdv),
        search: location.search,
    };
    const onFormValuesChange: FormProps['onValuesChange'] = (changedValues) => {
        setHasChanges(
            Object.keys(changedValues).some((name) => {
                if (name === 'deliveryDateToClient') {
                    return changedValues[name]?.toISOString() !== customerOrderState.data?.deliveryDateToClient;
                } else if (name === 'time') {
                    return convertDayjsToSeconds(changedValues[name]) !== customerOrderState.data?.time;
                } else if (name === 'tour') {
                    return changedValues[name] !== customerOrderState.data?.tour;
                } else {
                    return false;
                }
            })
        );
    };
    const onSubmit: FormProps['onFinish'] = (values) => {
        const payload = { ...values };

        if (payload.deliveryDateToClient) {
            payload.deliveryDateToClient = payload.deliveryDateToClient.toISOString();
        }

        if (payload.time) {
            payload.time = convertDayjsToSeconds(payload.time);
        }

        setIsEditModalVisible(true);

        setCustomerOrderUpdatePayload({
            id: customerOrderId,
            ...payload,
        });
    };
    const onClickCancel = () => {
        history.push(backRoute);
    };
    const onCloseEditModal = useCallback(() => {
        setIsEditModalVisible(false);
    }, []);
    const refreshCustomerOrder = useCallback(() => {
        getCustomerOrder({ customerOrderId });
    }, [getCustomerOrder, customerOrderId]);

    useEffect(() => {
        getCustomerOrder({ customerOrderId }, { poll: true });
    }, [getCustomerOrder, customerOrderId]);

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    useEffect(() => {
        if (previous?.customerOrderState.loading && !customerOrderState.loading) {
            if (customerOrderState.error) {
                message.error('Une erreur est survenue pendant le chargement de la livraison');
            } else {
                form.setFieldsValue({
                    ...customerOrderState.data,
                    time: convertSecondsToDayjs(customerOrderState.data?.time),
                    deliveryDateToClient: dayjs(customerOrderState.data?.deliveryDateToClient),
                });
                setHasChanges(false);
                forceUpdate(true);
            }
        }
    }, [
        previous?.customerOrderState.loading,
        customerOrderState.loading,
        customerOrderState.error,
        customerOrderState.data,
        form,
    ]);

    useEffect(
        () => () => {
            stopCustomerOrderPolling();
        },
        [stopCustomerOrderPolling]
    );

    return (
        <FixedFooter.Wrapper>
            <Seo title="Détail de la tournée" />
            <Header title="Détail de la tournée" backRoute={backRoute} />
            <PageHeader>
                <Spin spinning={customerOrderState.loading} style={{ width: '100%' }}>
                    <Descriptions column={11} size="small" colon={false} layout="vertical">
                        <Descriptions.Item label="Plateforme" span={2}>
                            {customerOrderState.data?.loadingOrder?.plateformCode ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="N° tournée" span={2}>
                            {customerOrderState.data?.loadingOrder?.tourNumber ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Usine">
                            {customerOrderState.data?.loadingOrder?.organization?.name ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Code client" span={2}>
                            {customerOrderState.data?.customer?.reference ?? '—'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Client" span={4}>
                            {customerOrderState.data?.customer?.label ?? '—'}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </PageHeader>
            <Card loading={customerOrderState.loading}>
                <TitleBlack>Détails du rendez-vous</TitleBlack>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    onValuesChange={onFormValuesChange}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ width: 470, maxWidth: 470, margin: '0 auto' }}
                    requiredMark={false}
                    colon={false}
                    className="horizontal-form"
                >
                    <Form.Item name="deliveryDateToClient" label="Date de livraison" rules={[requiredRule]}>
                        <ArrowNavItem>
                            <DatePicker placeholder="Date de livraison" format="DD/MM/YYYY" />
                        </ArrowNavItem>
                    </Form.Item>
                    <Form.Item name="tour" label="Tour" rules={[requiredRule]}>
                        <ArrowNavItem>
                            <InputNumber min={0} placeholder="Tour" />
                        </ArrowNavItem>
                    </Form.Item>
                    <Form.Item name="time" label="Heure" rules={[requiredRule]}>
                        <ArrowNavItem>
                            <TimePicker placeholder="Heure" format="HH[h]mm" showNow={false} />
                        </ArrowNavItem>
                    </Form.Item>
                </Form>
            </Card>
            <RdvCustomerOrderHistory onSendMailSuccess={refreshCustomerOrder} />
            <RdvCustomerOrderEditModal
                visible={isEditModalVisible}
                onCancel={onCloseEditModal}
                payload={customerOrderUpdatePayload}
                onSuccess={refreshCustomerOrder}
            />
            {hasChanges && (
                <FixedFooter>
                    <BasicList inline inlineStretch>
                        <li>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    shortcut="1"
                                    as={ButtonGrey}
                                    onClick={() => form.submit()}
                                    size="large"
                                    block
                                >
                                    Enregistrer les modifications
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </li>
                        <li>
                            <ArrowNavItem>
                                <ButtonWithShortcut
                                    type="primary"
                                    shortcut="2"
                                    onClick={onClickCancel}
                                    size="large"
                                    ghost
                                    block
                                >
                                    Annuler
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </li>
                    </BasicList>
                </FixedFooter>
            )}
        </FixedFooter.Wrapper>
    );
};

export default RdvCustomerOrder;
