import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CustomModal from '../../../components/CustomModal';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { useArrowNavScope, useShortcutScope } from '../../../hooks';
import { ConfirmNewPalletModalContent, ConfirmNewPalletModalFooter } from './modalContent/ConfirmNewPalletModalContent';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../../routes';
import { PalletSize, Parcel, ReturnZoneType } from '../../../store/api/apiTypes';
import { PalletSizeModalContent, PalletSizeModalFooter } from './modalContent/PalletSizeModalContent';

type createReturnPalletStep = 'confirmation' | 'size';

interface ReturnNewPalletModalProps {
    returnZoneType: ReturnZoneType;
    quantity: number;
    parcelId?: Parcel['id'];
    callBackSetSize: React.Dispatch<React.SetStateAction<PalletSize>>;
}

const shortcutScope = 'ReturnNewPalletModal';

const ReturnNewPalletModal = NiceModal.create<ReturnNewPalletModalProps>(
    ({ returnZoneType, quantity, parcelId, callBackSetSize }) => {
        const modal = useModal();
        const { hide, visible } = modal;
        const [step, setStep] = useState<createReturnPalletStep>('confirmation');
        const [size, setSize] = useState<PalletSize>();
        const history = useHistory();
        useShortcutScope(shortcutScope, !visible);
        useArrowNavScope(shortcutScope, !visible);

        const onConfirm = useCallback(() => {
            if (step === 'confirmation') {
                setStep('size');
            }
            if (step === 'size') {
                if (size && parcelId) {
                    callBackSetSize(size);
                    hide();
                }
            }
        }, [step, size, parcelId, callBackSetSize, hide]);

        useEffect(() => {
            if (visible)
                history.push(getRoute(RoutePathName.movementCustomerReturnPackagePlaces), {
                    returnZoneType,
                    quantity,
                    parcelId,
                    step: 'selectPlace',
                });
        }, [history, parcelId, quantity, returnZoneType, visible]);

        useEffect(() => {
            if (step === 'size') setSize(undefined);
        }, [step]);

        const title = useMemo(() => {
            if (step === 'size') return 'taille de la palette';
            return undefined;
        }, [step]);
        const handleCancel = () => {
            hide().then(() =>
                history.push(getRoute(RoutePathName.movementCustomerReturnPalletList), {
                    returnZoneType,
                    quantity,
                    parcelId,
                })
            );
        };

        const contentMap = {
            confirmation: (
                <ConfirmNewPalletModalContent
                    message={'En cliquant sur continuer vous allez créer une nouvelle palette'}
                    hasIcon={true}
                />
            ),
            size: <PalletSizeModalContent shortcutScope={shortcutScope} size={size} setSize={setSize} />,
        };
        const content = contentMap[step];

        const footerMap = {
            confirmation: (
                <ConfirmNewPalletModalFooter
                    onConfirm={onConfirm}
                    shortcutScope={shortcutScope}
                    onClose={handleCancel}
                />
            ),
            size: (
                <PalletSizeModalFooter
                    shortcutScope={shortcutScope}
                    size={size}
                    onClose={handleCancel}
                    onConfirm={onConfirm}
                />
            ),
        };
        const footer = footerMap[step];

        return (
            <CustomModal
                {...antdModal(modal)}
                afterClose={() => setStep('confirmation')}
                footer={footer}
                title={title}
                width={368}
                altTitle
            >
                {content}
            </CustomModal>
        );
    }
);

export default ReturnNewPalletModal;
