import { SearchPaginationQuery } from '.';
import { CustomerOrder, Preparation, PreparationOrderType, Pallet, Place, PreparationType } from './apiTypes';
import api from './_client';

export interface PreparationListPayload extends SearchPaginationQuery {
    customerOrderId: CustomerOrder['id'];
    type?: PreparationOrderType;
}
export const list = async (payload: PreparationListPayload) => await api.get('/preparations', { params: payload });

export interface PreparationDetailsPayload {
    preparationId: Preparation['id'];
}
export const details = async ({ preparationId, ...payload }: PreparationDetailsPayload) =>
    await api.get(`/preparations/${preparationId}`, { params: payload });

export type PreparationUpdatePayload = Preparation;
export const update = async (payload: PreparationUpdatePayload) =>
    await api.put(`/preparations/${payload.id}`, payload);

export interface PreparationStartPayload {
    preparationId: Preparation['id'];
    preparationType: PreparationType;
}
export const start = async ({ preparationId, ...payload }: PreparationStartPayload) =>
    await api.post(`/preparations/${preparationId}/start`, payload);

export interface PreparationPickingPayload {
    preparationId: Preparation['id'];
    placeId: Place['id'];
    index: Place['index'];
    quantity: Pallet['quantity'];
}
export const picking = async ({ preparationId, ...payload }: PreparationPickingPayload) =>
    await api.post(`/preparations/${preparationId}/picking`, payload);

export interface PreparationSAVPayload {
    preparationId: Preparation['id'];
}

export const sav = async ({ preparationId }: PreparationSAVPayload) =>
    await api.post(`/preparations/${preparationId}/sav`);
