import { ListResponse } from '../api/index';
import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { MovementBackToWorkshop } from '../api/apiTypes';
import * as api from '../api/movementsBackToWorkshops';

// States
export interface MovementBackToWorkshopsState {
    create: RequestState<MovementBackToWorkshop>;
    list: RequestState<ListResponse<MovementBackToWorkshop>>;
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: MovementBackToWorkshop | undefined;
        };
    };
    del: RequestState;
    update: RequestState<MovementBackToWorkshop>;
}

const initialState: MovementBackToWorkshopsState = {
    create: { ...requestInitialState },
    list: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
    del: { ...requestInitialState },
    update: { ...requestInitialState },
};

export const create = new EzeeAsyncAction<
    MovementBackToWorkshopsState['create'],
    api.MovementsBackToWorkshopCreatePayload,
    MovementBackToWorkshop
>(
    'movementBackToWorkshops/create',
    initialState.create,
    requestReducer<MovementBackToWorkshopsState['create'], MovementBackToWorkshop>(initialState.create)
);

export const list = new EzeeAsyncAction<
    MovementBackToWorkshopsState['list'],
    api.MovementsBackToWorkshopListPayload,
    ListResponse<MovementBackToWorkshop>
>('movementBackToWorkshops/list', initialState.list, {
    trigger: (state, _, meta) => ({
        ...state,
        loading: true,
        data: meta?.loadMore ? state.data : undefined,
    }),
    success: (state, payload, meta) => ({
        data: {
            ...payload,
            items: meta?.loadMore ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.list,
    }),
});

export const details = new EzeeAsyncAction<
    MovementBackToWorkshopsState['details'],
    api.MovementsBackToWorkshopIdPayload,
    MovementBackToWorkshop
>('movementBackToWorkshops/details', initialState.details, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...state.data,
            [payload.id]: payload,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.details,
    }),
});

export const del = new EzeeAsyncAction<
    MovementBackToWorkshopsState['del'],
    api.MovementsBackToWorkshopIdPayload,
    MovementBackToWorkshop
>(
    'movementBackToWorkshops/del',
    initialState.del,
    requestReducer<MovementBackToWorkshopsState['del'], MovementBackToWorkshop>(initialState.del)
);

export const update = new EzeeAsyncAction<
    MovementBackToWorkshopsState['update'],
    api.MovementsBackToWorkshopUpdatePayload,
    MovementBackToWorkshop
>(
    'movementBackToWorkshops/update',
    initialState.update,
    requestReducer<MovementBackToWorkshopsState['update'], MovementBackToWorkshop>(initialState.update)
);

// Reducer
export const movementsBackToWorkshopsReducer = combineReducers<MovementBackToWorkshopsState>({
    create: create.reducer,
    list: list.reducer,
    details: details.reducer,
    del: del.reducer,
    update: update.reducer,
});

// Saga
export function* movementsBackToWorkShopsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(api.list, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(api.details, details));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(api.create, create));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(api.del, del));

    yield takeLatest(update.type.trigger, simpleAsyncSaga(api.update, update));
}

// Store helpers
export const getMovementsBackToWorkShopCrateState = (state: MainReducerState) => state.movementsBackToWorkShops.create;
export const getMovementsBackToWorkShopsListState = (state: MainReducerState) => state.movementsBackToWorkShops.list;
export const getMovementsBackToWorkShopStateById =
    (id?: MovementBackToWorkshop['id']) => (state: MainReducerState) => ({
        loading: state.movementsBackToWorkShops.details.loading,
        error: state.movementsBackToWorkShops.details.error,
        data: id ? state.movementsBackToWorkShops.details.data[id] : undefined,
    });
export const getMovementsBackToWorkShopDelState = (state: MainReducerState) => state.movementsBackToWorkShops.del;

export const getMovementsBackToWorkShopsUpdateState = (state: MainReducerState) =>
    state.movementsBackToWorkShops.update;
