import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { PlatformAnomalyCode } from '../api/apiTypes';
import { ListResponse } from '../api';

// Controlers
import {
    PlatformAnomalyCodeListPayload,
    list as listApiCall,
    PlatformAnomalyCodeCreatePayload,
    create as createApiCall,
    PlatformAnomalyCodeUpdatePayload,
    update as updateApiCall,
    PlatformAnomalyCodeDeletePayload,
    del as delApiCall,
} from '../api/platformAnomalyCodes';

// States
export interface PlatformAnomalyCodesState {
    list: RequestState<ListResponse<PlatformAnomalyCode>>;
    create: RequestState<PlatformAnomalyCode>;
    update: RequestState<PlatformAnomalyCode>;
    del: RequestState;
}

const initialState: PlatformAnomalyCodesState = {
    list: { ...requestInitialState },
    create: { ...requestInitialState },
    update: { ...requestInitialState },
    del: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<
    PlatformAnomalyCodesState['list'],
    PlatformAnomalyCodeListPayload,
    ListResponse<PlatformAnomalyCode>
>(
    'platformAnomalyCodes/list',
    initialState.list,
    requestReducer<PlatformAnomalyCodesState['list'], ListResponse<PlatformAnomalyCode>>(initialState.list)
);

export const create = new EzeeAsyncAction<
    PlatformAnomalyCodesState['create'],
    PlatformAnomalyCodeCreatePayload,
    PlatformAnomalyCode
>(
    'platformAnomalyCodes/create',
    initialState.create,
    requestReducer<PlatformAnomalyCodesState['create'], PlatformAnomalyCode>(initialState.create)
);

export const update = new EzeeAsyncAction<
    PlatformAnomalyCodesState['update'],
    PlatformAnomalyCodeUpdatePayload,
    PlatformAnomalyCode
>(
    'platformAnomalyCodes/update',
    initialState.update,
    requestReducer<PlatformAnomalyCodesState['update'], PlatformAnomalyCode>(initialState.update)
);

export const del = new EzeeAsyncAction<PlatformAnomalyCodesState['del'], PlatformAnomalyCodeDeletePayload>(
    'platformAnomalyCodes/del',
    initialState.del,
    requestReducer<PlatformAnomalyCodesState['del'], PlatformAnomalyCode>(initialState.del)
);

// Reducer
export const platformAnomalyCodesReducer = combineReducers<PlatformAnomalyCodesState>({
    list: list.reducer,
    create: create.reducer,
    update: update.reducer,
    del: del.reducer,
});

// Saga
export function* platformAnomalyCodesSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
}

// Store helpers
export const getPlatformAnomalyCodesListState = (state: MainReducerState) => state.platformAnomalyCodes.list;
export const getPlatformAnomalyCodeCreateState = (state: MainReducerState) => state.platformAnomalyCodes.create;
export const getPlatformAnomalyCodeUpdateState = (state: MainReducerState) => state.platformAnomalyCodes.update;
export const getPlatformAnomalyCodeDeleteState = (state: MainReducerState) => state.platformAnomalyCodes.del;
