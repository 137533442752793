import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import { Col, message, Row, Typography } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import CustomModal from '../../components/CustomModal';
import React, { useEffect, useState } from 'react';
import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import SuccessMessage from '../../components/SuccessMessage';
import { getCustomerOrderUpdateState, update } from '../../store/actions/customerOrders';
import { CustomerOrder } from '../../store/api/apiTypes';
import { useSelector } from 'react-redux';

const shortcutScope = 'ConfirmBlockCustomerModal';

type BlockCustomerCloseStep = 'confirm' | 'success';

interface ConfirmBlockCustomerModalProps {
    customerOrderId: CustomerOrder['id'];
    customerOrderIsBlocked: boolean | undefined;
    setCustomerOrderIsBlocked: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const ConfirmBlockCustomerModal = NiceModal.create<ConfirmBlockCustomerModalProps>(
    ({ customerOrderId, customerOrderIsBlocked, setCustomerOrderIsBlocked }) => {
        const modal = useModal();
        const { visible, hide } = modal;
        const [step, setStep] = useState<BlockCustomerCloseStep>('confirm');
        const updateCustomerOrder = useActions(update.trigger);
        const updateCustomerOrderState = useSelector(getCustomerOrderUpdateState);
        const previous = usePrevious({ updateCustomerOrderState });
        useShortcutScope(shortcutScope, !visible);
        useArrowNavScope(shortcutScope, !visible);

        useEffect(() => {
            if (previous?.updateCustomerOrderState.loading && !updateCustomerOrderState.loading) {
                if (updateCustomerOrderState.error) {
                    message.error(`Une erreur est survenue durant la mise à jour de l'ordre client`);
                } else if (updateCustomerOrderState.data) {
                    setStep('success');
                    setCustomerOrderIsBlocked(!customerOrderIsBlocked);
                }
            }
        }, [
            customerOrderId,
            customerOrderIsBlocked,
            previous?.updateCustomerOrderState.loading,
            setCustomerOrderIsBlocked,
            updateCustomerOrderState.data,
            updateCustomerOrderState.error,
            updateCustomerOrderState.loading,
            updateCustomerOrderState.success,
        ]);

        const onConfirm = () => {
            updateCustomerOrder({
                id: customerOrderId,
                isBlocked: !customerOrderIsBlocked,
            });
        };

        const contentMap = {
            confirm: (
                <Row justify="center">
                    <Col xs={16} className="text-center">
                        <Typography.Title level={4} style={{ fontSize: '35px' }} type="warning">
                            <WarningOutlined />
                        </Typography.Title>
                        <Text>
                            Vous êtes sur le point de {customerOrderIsBlocked ? 'débloquer' : 'bloquer'} la clôture du
                            client. Souhaitez-vous confirmer ?
                        </Text>
                    </Col>
                </Row>
            ),
            success: (
                <SuccessMessage
                    message={`Clôture du client ${customerOrderIsBlocked ? 'débloquée' : 'bloquée'} avec succès`}
                />
            ),
        };
        const content = contentMap[step];

        const footerMap = {
            confirm: (
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="enter"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={onConfirm}
                        >
                            Confirmer
                        </ButtonWithShortcut>
                    </ArrowNavItem>

                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            onClick={hide}
                        >
                            Fermer
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            ),
            success: null,
        };
        const footer = footerMap[step];

        useEffect(() => {
            let timeout: number;
            if (step === 'success') {
                timeout = window.setTimeout(() => {
                    hide();
                }, 1000);
            }
            return () => {
                if (timeout) {
                    window.clearTimeout(timeout);
                }
            };
        }, [hide, step]);

        return (
            <CustomModal {...antdModal(modal)} width={368} keyboard={false} footer={footer}>
                {content}
            </CustomModal>
        );
    }
);

export default ConfirmBlockCustomerModal;
