import { Card, Divider } from 'antd';
import React, { FC, ReactNode } from 'react';

import '../assets/styles/VerticalDescriptions.less';
import { classNames } from '../helpers';

import BasicList from './BasicList';

interface VerticalDescriptionsProps {
    items: Array<{
        label: ReactNode;
        value: ReactNode;
    }>;
    inCard?: boolean;
    bigValue?: boolean;
}

const VerticalDescriptions: FC<VerticalDescriptionsProps> = ({ items, inCard, bigValue }) => (
    <Card className={classNames('vertical-descriptions', inCard && 'vertical-descriptions-in-card')} bordered={false}>
        <BasicList>
            {items.map((item, index) => (
                <li key={index}>
                    <div className="vertical-descriptions-content">
                        <p className="vertical-descriptions-label">{item.label}</p>
                        <p
                            className={classNames(
                                'vertical-descriptions-value',
                                bigValue && 'vertical-descriptions-value-big'
                            )}
                        >
                            {item.value}
                        </p>
                    </div>
                    {index !== items.length - 1 && <Divider />}
                </li>
            ))}
        </BasicList>
    </Card>
);

export default VerticalDescriptions;
