import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import CustomModal from '../../components/CustomModal';
import React, { useState } from 'react';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import { Permission, Printer } from '../../store/api/apiTypes';
import { Form } from 'antd';
import PrintersSelect from '../../components/PrintersSelect';
import { useActions, useArrowNavScope, useShortcutScope } from '../../hooks';
import { defaultPrinter, getDefaultPrinterState } from '../../store/actions/printers';
import { useSelector } from 'react-redux';
import ReportIncidentModal from '../machinesIncidents/ReportIncidentModal';
import { isZebra } from '../../helpers/enterprise-browser';
import { hasPermission } from '../../helpers/security';
import { getUser } from '../../store/actions/auth';

interface SelectPrinterModalProps {
    isConnexionPrinter?: boolean;
}

const shortcutScope = 'SelectPrinterModal';

const SelectPrinterModal = NiceModal.create<SelectPrinterModalProps>(({ isConnexionPrinter }) => {
    const modal = useModal();
    const { hide, visible } = modal;
    const setDefaultPrinter = useActions(defaultPrinter.actions.setDefaultPrinter);
    const defaultPrinterState = useSelector(getDefaultPrinterState);
    const [printer, setPrinter] = useState<Printer | undefined>(defaultPrinterState.printer);
    const reportIncidentModal = useModal(ReportIncidentModal);
    const user = useSelector(getUser);
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);

    const onClickConfirm = () => {
        setDefaultPrinter(printer);
        hide().then(() => {
            if (isConnexionPrinter && isZebra && hasPermission(user, Permission.incidents)) {
                reportIncidentModal.show({ isOpenedFromLogin: true });
            }
        });
    };

    const onCancel = () => {
        if (!isConnexionPrinter) {
            hide();
        }
    };

    return (
        <CustomModal
            {...antdModal(modal)}
            onCancel={onCancel}
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            type="primary"
                            shortcut="enter"
                            shortcutScope={shortcutScope}
                            shortcutOptions={{ enabled: !!printer }}
                            onClick={onClickConfirm}
                        >
                            Valider
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                    {!isConnexionPrinter ? (
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={hide}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    ) : null}
                </>
            }
            title={`imprimante par défaut`}
            width={368}
            altTitle
        >
            <>
                <Form.Item label="Imprimante" style={{ margin: 0 }} labelCol={{ span: 24 }}>
                    <PrintersSelect onChange={setPrinter} withArrowNav arrowNavScope={shortcutScope} />
                </Form.Item>
            </>
        </CustomModal>
    );
});

export default SelectPrinterModal;
