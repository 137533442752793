import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestInitialState } from '../helpers';

// Types
import { Expedition } from '../api/apiTypes';

// Controlers
import {
    list as listApiCall,
    ExpeditionListPayload,
    details as detailsApiCall,
    ExpeditionDetailsPayload,
} from '../api/expeditions';
import { ListResponse } from '../api';

// States
export interface ExpeditionsState {
    list: RequestState<ListResponse<Expedition>>;
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: Expedition | undefined;
        };
    };
}

const initialState: ExpeditionsState = {
    list: { ...requestInitialState },
    details: { ...requestInitialState, data: {} },
};

export const list = new EzeeAsyncAction<ExpeditionsState['list'], ExpeditionListPayload, ListResponse<Expedition>>(
    'expeditions/list',
    initialState.list,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
            data: undefined, // reset list data since it's used in multiple components (lotVisualization, Rdv, etc...)
        }),
        success: (state, payload) => ({
            data: payload,
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.list,
        }),
    }
);

export const details = new EzeeAsyncAction<ExpeditionsState['details'], ExpeditionDetailsPayload, Expedition>(
    'expeditions/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

// Reducer
export const expeditionsReducer = combineReducers<ExpeditionsState>({
    list: list.reducer,
    details: details.reducer,
});

// Saga
export function* expeditionsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getExpeditionListState = (state: MainReducerState) => state.expeditions.list;
export const getExpeditionStateById = (id: Expedition['id']) => (state: MainReducerState) => ({
    loading: state.expeditions.details.loading,
    error: state.expeditions.details.error,
    data: state.expeditions.details.data[id],
});
