import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { PlatformReasonCode } from '../api/apiTypes';
import { ListResponse } from '../api';

// Controlers
import {
    PlatformReasonCodeListPayload,
    list as listApiCall,
    PlatformReasonCodeCreatePayload,
    create as createApiCall,
    PlatformReasonCodeUpdatePayload,
    update as updateApiCall,
    PlatformReasonCodeDeletePayload,
    del as delApiCall,
} from '../api/platformReasonCodes';

// States
export interface PlatformReasonCodesState {
    list: RequestState<ListResponse<PlatformReasonCode>>;
    create: RequestState<PlatformReasonCode>;
    update: RequestState<PlatformReasonCode>;
    del: RequestState;
}

const initialState: PlatformReasonCodesState = {
    list: { ...requestInitialState },
    create: { ...requestInitialState },
    update: { ...requestInitialState },
    del: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<
    PlatformReasonCodesState['list'],
    PlatformReasonCodeListPayload,
    ListResponse<PlatformReasonCode>
>(
    'platformReasonCodes/list',
    initialState.list,
    requestReducer<PlatformReasonCodesState['list'], ListResponse<PlatformReasonCode>>(initialState.list)
);

export const create = new EzeeAsyncAction<
    PlatformReasonCodesState['create'],
    PlatformReasonCodeCreatePayload,
    PlatformReasonCode
>(
    'platformReasonCodes/create',
    initialState.create,
    requestReducer<PlatformReasonCodesState['create'], PlatformReasonCode>(initialState.create)
);

export const update = new EzeeAsyncAction<
    PlatformReasonCodesState['update'],
    PlatformReasonCodeUpdatePayload,
    PlatformReasonCode
>(
    'platformReasonCodes/update',
    initialState.update,
    requestReducer<PlatformReasonCodesState['update'], PlatformReasonCode>(initialState.update)
);

export const del = new EzeeAsyncAction<PlatformReasonCodesState['del'], PlatformReasonCodeDeletePayload>(
    'platformReasonCodes/del',
    initialState.del,
    requestReducer<PlatformReasonCodesState['del'], PlatformReasonCode>(initialState.del)
);

// Reducer
export const platformReasonCodesReducer = combineReducers<PlatformReasonCodesState>({
    list: list.reducer,
    create: create.reducer,
    update: update.reducer,
    del: del.reducer,
});

// Saga
export function* platformReasonCodesSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(del.type.trigger, simpleAsyncSaga(delApiCall, del));
}

// Store helpers
export const getPlatformReasonCodesListState = (state: MainReducerState) => state.platformReasonCodes.list;
export const getPlatformReasonCodeCreateState = (state: MainReducerState) => state.platformReasonCodes.create;
export const getPlatformReasonCodeUpdateState = (state: MainReducerState) => state.platformReasonCodes.update;
export const getPlatformReasonCodeDeleteState = (state: MainReducerState) => state.platformReasonCodes.del;
