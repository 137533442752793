import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    MovementHistoryType,
    PalletSize,
    Place,
    PlaceZoneType,
    RepairStatus,
    WMSMode,
} from '../../../store/api/apiTypes';

import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import Seo from '../../../components/Seo';
import { useActions, usePrevious } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import FixedFooter from '../../../components/FixedFooter';
import ArrowNavItem from '../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import PalletSizeSelectModal from '../../../components/PalletSizeSelectModal';
import { getPlacesListState, list } from '../../../store/actions/places';
import {
    create as createPalletAction,
    del as deletePalletAction,
    getPalletCreateState,
    getPalletDelState,
} from '../../../store/actions/pallets';
import useQueryParams from '../../../hooks/queryParams';
import { urlSearchParamsToObject } from '../../../helpers';

import { Button, Divider, message, Modal, PageHeader } from 'antd';
import QuantityCard from '../../../components/QuantityCard';
import PrintModal from '../../../components/PrintModal';
import { PalletCreatePayload } from '../../../store/api/pallets';
import { isZebra } from '../../../helpers/enterprise-browser';
import QuantitySelectModal from '../../../components/QuantitySelectModal';
import ScanPlaceModal from './ScanPlaceModal';
import {
    detailsRepair,
    getRepairsDetails,
    getRepairStorePackagesState,
    storePackages,
} from '../../../store/actions/repairs';
import { StoreRepairPackagesPayload } from '../../../store/api/repairs';
import SuccessModal from '../../../components/SuccessModal';
import ConfirmModal from '../../../components/ConfirmModal';
import { translateStoreRepairPackagesError } from '../../../helpers/errors';

type createPalletStep = 'confirmation' | 'print' | 'quantity' | 'scan' | 'size' | 'success' | undefined;

const PackagePlaces: FC = () => {
    const history = useHistory();
    const params = useParams<{ repairId: string }>();

    const [selectedPlace, setSelectedPlace] = useState<Place>();
    const [palletSize, setPalletSize] = useState<PalletSize>();
    const [isWaitingForBackButton, setIsWaitingForBackButton] = useState(false);
    const location = useLocation<{ step?: createPalletStep; zoneType: PlaceZoneType; quantity?: number }>();
    const [step, setStep] = useState<createPalletStep>(location.state?.step);

    const [fetchPlaces, createPallet, deletePallet, storeRepairPackages, fetchRepair, resetPalletCreateState] =
        useActions([
            list.trigger,
            createPalletAction.trigger,
            deletePalletAction.trigger,
            storePackages.trigger,
            detailsRepair.trigger,
            createPalletAction.reset,
        ]);

    const placesListState = useSelector(getPlacesListState);
    const palletCreateState = useSelector(getPalletCreateState);
    const palletDeleteState = useSelector(getPalletDelState);
    const storeRepairPackagesState = useSelector(getRepairStorePackagesState);
    const repairState = useSelector(getRepairsDetails);

    const availablePackagesQuantity = (repairState.data?.quantity ?? 0) - (repairState.data?.transferedPackages ?? 0);
    const [quantity, setQuantity] = useState<number>(location.state.quantity ?? availablePackagesQuantity);

    const previous = usePrevious({
        placesListState,
        palletCreateState,
        palletDeleteState,
        repairState,
        storeRepairPackagesState,
    });

    const columns = (
        [
            {
                key: 'sector',
                title: 'Secteur',
                flex: '1 1 25%',
                render: (record) => record.sector,
            },
            {
                key: 'locker',
                title: 'Casier',
                flex: '1 1 25%',
                render: (record) => record.locker,
            },
            {
                key: 'spot',
                title: 'Emplacement',
                flex: '1 1 25%',
                render: (record) => record.spot,
            },
        ] as Array<ListColumn<Place>>
    ).filter(Boolean);

    const [queryParams] = useQueryParams('PlacesFilter');
    const backRoute = getRoute(RoutePathName.movementRepairPalletPlaces, { repairId: params.repairId });

    const onClickBackButton = () => {
        // should be always true
        if (palletCreateState.data?.id) {
            Modal.confirm({
                title: 'Êtes-vous sûr de vouloir retourner à la liste des réparations ? La palette en cours sera supprimée.',
                onOk() {
                    setIsWaitingForBackButton(true);
                    deletePallet({ palletId: palletCreateState.data?.id });
                },
            });
        } else {
            getBack();
        }
    };

    const fetchPlacesWithParams = useCallback(() => {
        fetchPlaces({
            ...urlSearchParamsToObject(queryParams),
            zoneType: PlaceZoneType.mass,
            palletId: palletCreateState.data?.id,
            pageSize: 10,
        });
    }, [fetchPlaces, palletCreateState.data?.id, queryParams]);

    const handlePlaceSelect = (place?: Place) => {
        setSelectedPlace(place);
    };

    const selectPackagePlace = () => {
        setStep('scan');
    };

    const handleFilterClick = () => {
        history.push(getRoute(RoutePathName.movementsPlacesFilter), {
            ...location.state,
            quantity,
            backRoute: getRoute(RoutePathName.movementRepairPackagesPlaces, { repairId: params.repairId }),
        });
    };
    const handleScanSuccess = () => {
        const palletId = palletCreateState.data?.id;
        const placeId = selectedPlace?.id;
        const index = selectedPlace?.index;

        const payload: StoreRepairPackagesPayload = {
            id: Number(params.repairId),
            palletId,
            transferedPackages: Number(quantity),
            type: MovementHistoryType.transfer,
            placeId,
            index,
        };
        storeRepairPackages(payload);
    };
    const handleStoreSuccess = () => {
        setStep(undefined);
        fetchRepair({ id: params.repairId });
        resetPalletCreateState();

        if (repairState.data?.status === RepairStatus.completed) {
            history.push(getRoute(RoutePathName.movementsRepairs));
        } else {
            history.push({
                pathname: getRoute(RoutePathName.movementRepairPalletPlaces, { repairId: params.repairId }),
                state: location.state,
            });
        }
    };

    const onLoadMore = () => {
        fetchPlaces(
            {
                ...urlSearchParamsToObject(queryParams),
                zoneType: PlaceZoneType.mass,
                palletId: palletCreateState.data?.id,
                page: (placesListState.data?.page ?? 0) + 1,
                pageSize: 10,
            },
            { loadMore: true }
        );
    };

    const selectPackagesQuantity = (quantity: number) => {
        setQuantity(quantity);
        const parcelId = repairState.data?.parcel.id;
        if (palletSize && parcelId) {
            const createPalletPayload: Partial<PalletCreatePayload> = {
                quantity,
                size: palletSize,
                parcelId,
            };
            createPallet(createPalletPayload);
        }
    };

    const selectPalletSize = (size?: PalletSize) => {
        setPalletSize(size);
        setStep('quantity');
    };

    const getBack = useCallback(() => {
        history.push(backRoute, { ...location.state });
    }, [backRoute, history, location.state]);

    // return to home page when there is no OT in store (reload page, direct access to page by url...)
    useEffect(() => {
        if (!repairState.data && !repairState.loading) {
            history.push(getRoute(RoutePathName.movementsRepairs));
        }
    }, [history, repairState.data, repairState.loading]);

    useEffect(() => {
        fetchPlacesWithParams();
    }, [fetchPlacesWithParams]);

    useEffect(() => {
        if (!palletCreateState.data?.id) {
            setStep('confirmation');
        }
    }, [palletCreateState.data?.id]);

    useEffect(() => {
        if (previous?.palletCreateState.loading && !palletCreateState.loading) {
            if (palletCreateState.error) {
                message.error('Un erreur est survenue pendant la création de la palette');
            } else {
                setStep('print');
                fetchRepair({ id: Number(repairState.data?.id) });
                fetchPlacesWithParams();
            }
        }
    }, [
        fetchPlacesWithParams,
        fetchRepair,
        palletCreateState.error,
        palletCreateState.loading,
        previous?.palletCreateState.loading,
        queryParams,
        repairState.data?.id,
    ]);

    useEffect(() => {
        if (previous?.repairState.loading && !repairState.loading) {
            if (repairState.success) {
                setQuantity(availablePackagesQuantity);
            }
        }
    }, [availablePackagesQuantity, previous?.repairState.loading, repairState.loading, repairState.success]);

    useEffect(() => {
        if (previous?.palletDeleteState.loading && !palletDeleteState.loading) {
            setIsWaitingForBackButton(false);

            if (palletDeleteState.error) {
                message.error('Un erreur est survenue pendant la suppression de la palette');
            } else {
                message.info('Palette supprimée');
                resetPalletCreateState();
                getBack();
            }
        }
    }, [
        availablePackagesQuantity,
        previous?.palletDeleteState.loading,
        palletDeleteState.loading,
        palletDeleteState.success,
        palletDeleteState.error,
        resetPalletCreateState,
        getBack,
    ]);

    useEffect(() => {
        if (previous?.storeRepairPackagesState.loading && !storeRepairPackagesState.loading) {
            if (storeRepairPackagesState.error) {
                message.error(translateStoreRepairPackagesError(storeRepairPackagesState.error));
            } else {
                setStep('success');
                fetchPlacesWithParams();
            }
        }
    }, [
        fetchPlacesWithParams,
        previous?.storeRepairPackagesState.loading,
        storeRepairPackagesState.error,
        storeRepairPackagesState.loading,
    ]);

    return isZebra ? (
        <FixedFooter.Wrapper>
            <Seo title="autre emplacement" />
            <Header
                title="autre emplacement"
                backRoute={{
                    pathname: backRoute,
                    state: { ...location.state },
                }}
                onClickBack={onClickBackButton}
                backButtonLoading={isWaitingForBackButton}
            />
            <PageHeader>
                <QuantityCard value={quantity} label={'colis à ranger'} />
            </PageHeader>
            <Divider />
            <List<Place>
                columns={columns}
                data={placesListState.data?.items}
                rowKey={(record) => `${record.id}`}
                onRowEnterPress={selectPackagePlace}
                onRowClick={handlePlaceSelect}
                onRowFocus={handlePlaceSelect}
                onRowBlur={handlePlaceSelect}
                isLoading={placesListState.loading && placesListState.data?.page === undefined}
                title="Emplacements disponibles"
                style={{ marginTop: 24 }}
                columnWidthGrow={true}
            />
            {placesListState.data && placesListState.data.page < placesListState.data.pageCount - 1 && (
                <Button
                    onClick={onLoadMore}
                    loading={placesListState.loading}
                    size="small"
                    style={{ marginBottom: 60, marginTop: 16, fontSize: 14 }}
                    block
                >
                    Plus de résultats
                </Button>
            )}
            {step === 'success' && (
                <SuccessModal
                    message={
                        repairState.data?.status === RepairStatus.completed
                            ? 'Colis rangés avec succès'
                            : 'Réparation traitée avec succès'
                    }
                    visible={step === 'success'}
                    noFooter
                    autoClose={true}
                    onCancel={handleStoreSuccess}
                />
            )}
            {step === 'confirmation' && (
                <ConfirmModal
                    visible={step === 'confirmation'}
                    onCancel={getBack}
                    onOk={setStep.bind(null, 'size')}
                    hasIcon={true}
                    message="En cliquant sur continuer vous allez créer une nouvelle palette"
                    okText="Continuer"
                    cancelText="Annuler"
                />
            )}
            {step === 'size' && (
                <PalletSizeSelectModal visible={step === 'size'} onSubmit={selectPalletSize} onCancel={getBack} />
            )}
            {step === 'quantity' && (
                <QuantitySelectModal
                    onSubmit={selectPackagesQuantity}
                    onReturn={setStep.bind(null, 'size')}
                    max={availablePackagesQuantity}
                    visible={step === 'quantity'}
                    title="Quantité colis"
                    text={`sur ${availablePackagesQuantity} à transférer`}
                    cancelText="Retour"
                />
            )}
            {step === 'print' && (
                <PrintModal
                    visible={step === 'print'}
                    onCancel={setStep.bind(null, undefined)}
                    onSuccess={setStep.bind(null, undefined)}
                    cancelText="Imprimer plus tard"
                    palletId={palletCreateState.data?.id}
                    mode={WMSMode.movement}
                />
            )}
            {step === 'scan' && (
                <ScanPlaceModal
                    onCancel={setStep.bind(null, undefined)}
                    onSuccess={handleScanSuccess}
                    selectPlaceId={selectedPlace?.id}
                    visible={step === 'scan'}
                    zoneType={PlaceZoneType.mass}
                />
            )}
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        disabled={!selectedPlace?.id}
                        onClick={selectPackagePlace}
                        shortcut="enter"
                        block
                        className="btn-grey"
                    >
                        Ranger les colis
                    </ButtonWithShortcut>
                </ArrowNavItem>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        onClick={handleFilterClick}
                        shortcut="f1"
                        style={{ marginBottom: 10, fontSize: 14 }}
                        type="ghost"
                        block
                    >
                        Filtrer
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </FixedFooter.Wrapper>
    ) : null;
};

export default PackagePlaces;
