import './ebapi.js';
import './eb.keycapture.js';
import './eb.barcode.js';
import { debug } from '../';

export const isZebra = navigator.userAgent.toLowerCase().includes('mc93 build');

// remap ESC key to backtick (`) key to avoid android back button behaviour when pressing ESC
if (isZebra) {
    window.EB.KeyCapture.remapKey('111', '68');
}

export interface BarCodeScannerResponse {
    data: string; // The data decoded by the scanner or imaging device.
    source: string; // The source device and human readable decoder type of the decoded barcode or symbol.
    type: string; // Hex value representing the decoder type.
    time: string; // The time at which the decode occurred (hh:mm:ss)
    length: string; // The length of the decoded barcode or symbol.
    direction: string; // The direction the barcode was scanned, either forward, reverse or unavailable.
    isMULTIBARCODEData: boolean; // Whether the Scanned Data is MULTIBARCODE decoded data or not.
    isUDIData: boolean; // Whether the Scanned Data is UDI decoded data or not.
    label?: string; // Get the decoder type of the decoded barcode or symbol. Note: This is specially applicable for UDI decoding.
    UDITokenizedData?: Array<{
        // Array of tokenized data received after processing the UDI barcode Data. The data read from a UDI Barcode labels contain embedded key/value pairs delimited by characters defined by their UDI type. Note: This is specially applicable for UDI decoding.
        key: string; // Get key of the token data.
        data: string; // Get data of the token data.
        type: string; // Get data type of the token data.
        format: string; // Get format of the token data
        rawData: string; // Get raw data of the token data
    }>;
    MULTIBARCODETokenizedData?: Array<{
        // Array of scanned data received after processing the Multi barcode Data.
        data: string; // Get data of the scanned data.
        source: string; // The source device and human readable decoder type of the decoded barcode or symbol.
        time: string; // Get time of the scanned data
        rawData: string; // Get raw data of the scanned data
    }>;
}

// https://techdocs.zebra.com/enterprise-browser/3-0/api/barcode/#api-barcode?Properties
export interface ScannerParams {
    adaptiveScanning?: boolean;
    aimMode?: string;
    aimType?: string;
    aimingPattern?: string;
    allDecoders?: boolean;
    ausPostal?: boolean;
    autoCharacterSetFailureOption?: string;
    autoEnter?: boolean;
    autoTab?: boolean;
    aztec?: boolean;
    barcodeCount?: number;
    barcodeDataFormat?: string;
    beamWidth?: string;
    bidirectionalRedundancy?: boolean;
    canPostal?: boolean;
    characterSetSelection?: string;
    chinese2of5?: boolean;
    codabar?: boolean;
    codabarClsiEditing?: boolean;
    codabarMaxLength?: number;
    codabarMinLength?: number;
    codabarNotisEditing?: boolean;
    codabarRedundancy?: boolean;
    code11?: boolean;
    code11checkDigitCount?: string;
    code11maxLength?: number;
    code11minLength?: number;
    code11redundancy?: boolean;
    code11reportCheckDigit?: boolean;
    code128?: boolean;
    code128checkIsBtTable?: boolean;
    code128ean128?: boolean;
    code128isbt128?: boolean;
    code128isbt128ConcatMode?: string;
    code128maxLength?: number;
    code128minLength?: number;
    code128other128?: boolean;
    code128redundancy?: boolean;
    code128securityLevel?: number;
    code39?: boolean;
    code39code32Prefix?: boolean;
    code39convertToCode32?: boolean;
    code39fullAscii?: boolean;
    code39maxLength?: number;
    code39minLength?: number;
    code39redundancy?: boolean;
    code39reportCheckDigit?: boolean;
    code39securityLevel?: number;
    code39verifyCheckDigit?: boolean;
    code93?: boolean;
    code93maxLength?: number;
    code93minLength?: number;
    code93redundancy?: boolean;
    codeIdType?: string;
    compositeAb?: boolean;
    compositeAbUccLinkMode?: string;
    compositeAbUseUpcPreambleCheckDigitRules?: boolean;
    compositeC?: boolean;
    connectionIdleTime?: number;
    connectionIdleTimeout?: number;
    d2of5?: boolean;
    d2of5maxLength?: number;
    d2of5minLength?: number;
    d2of5redundancy?: boolean;
    dataBufferSize?: number;
    datamatrix?: boolean;
    dbpMode?: string;
    decodeDuration?: number;
    decodeFrequency?: number;
    decodeLEDFeedback?: boolean;
    decodeLEDFeedbackMode?: string;
    decodeLEDTime?: number;
    decodeScreenNotification?: boolean;
    decodeScreenNotificationTime?: number;
    decodeScreenTranslucencyLevel?: number;
    decodeSound?: string;
    decodeVolume?: number;
    differentSymbolTimeout?: number;
    digimarcDecoding?: boolean;
    disableScannerDuringNavigate?: boolean;
    disconnectBtOnDisable?: boolean;
    disconnectOnExit?: boolean;
    displayBtAddressBarcodeOnEnable?: boolean;
    dotCode?: boolean;
    dotCodeInverse?: string;
    dotCodeMirror?: string;
    dpmMode?: boolean;
    dutchPostal?: boolean;
    ean13?: boolean;
    ean8?: boolean;
    ean8convertToEan13?: boolean;
    enableGS1?: boolean;
    enableHIBCC?: boolean;
    enableICCBBA?: boolean;
    enableTimeout?: number;
    focusMode?: string;
    friendlyName?: string;
    gridMatrix?: boolean;
    gridMatrixInverse?: string;
    gridMatrixMirror?: string;
    gs1Datamatrix?: boolean;
    gs1LimitedSecurityLevel?: string;
    gs1QrCode?: boolean;
    gs1dataBar?: boolean;
    gs1dataBarExpanded?: boolean;
    gs1dataBarLimited?: boolean;
    hanXin?: boolean;
    hanXinInverse?: string;
    hapticFeedback?: boolean;
    i2of5?: boolean;
    i2of5convertToEan13?: boolean;
    i2of5febraban?: boolean;
    i2of5maxLength?: number;
    i2of5minLength?: number;
    i2of5reducedQuietZone?: boolean;
    i2of5redundancy?: boolean;
    i2of5reportCheckDigit?: boolean;
    i2of5verifyCheckDigit?: string;
    illuminationBrightness?: string;
    illuminationMode?: string;
    instantReporting?: boolean;
    invalidDecodeFrequency?: number;
    invalidDecodeSound?: string;
    inverse1dMode?: string;
    japPostal?: boolean;
    klasseEins?: boolean;
    korean3of5?: boolean;
    korean3of5maxLength?: number;
    korean3of5minLength?: number;
    korean3of5redundancy?: boolean;
    lcdMode?: boolean;
    linearSecurityLevel?: string;
    lowBatteryScan?: boolean;
    macroMicroPdf?: boolean;
    macroMicroPdfBufferLabels?: boolean;
    macroMicroPdfConvertToMicroPdf?: boolean;
    macroMicroPdfExclusive?: boolean;
    macroMicroPdfReportAppendInfo?: boolean;
    macroPdf?: boolean;
    macroPdfBufferLabels?: boolean;
    macroPdfConvertToPdf417?: boolean;
    macroPdfExclusive?: boolean;
    mailMark?: boolean;
    matrix2of5?: boolean;
    matrix2of5maxLength?: number;
    matrix2of5minLength?: number;
    matrix2of5reportCheckDigit?: boolean;
    matrix2of5verifyCheckDigit?: boolean;
    maxiCode?: boolean;
    microPdf?: boolean;
    microQr?: boolean;
    msi?: boolean;
    msiCheckDigitScheme?: string;
    msiCheckDigits?: string;
    msiMaxLength?: number;
    msiMinLength?: number;
    msiRedundancy?: boolean;
    msiReportCheckDigit?: boolean;
    oneDQuietZoneLevel?: string;
    pairAfterScannerReboot?: string;
    pdf417?: boolean;
    picklistEx?: string;
    picklistMode?: string;
    poorQuality1dMode?: boolean;
    poorQualityDecodeEffortLevel?: string;
    qrCode?: boolean;
    rasterHeight?: number;
    rasterMode?: string;
    rsmBatteryCapacity?: string;
    rsmBatteryId?: string;
    rsmBatteryStatus?: string;
    rsmBluetoothAddress?: string;
    rsmBluetoothAuthentication?: boolean;
    rsmBluetoothAutoReconnect?: string;
    rsmBluetoothBeepOnReconnectAttempt?: boolean;
    rsmBluetoothEncryption?: boolean;
    rsmBluetoothFriendlyName?: string;
    rsmBluetoothHidAutoReconnect?: string;
    rsmBluetoothInquiryMode?: string;
    rsmBluetoothPinCode?: string;
    rsmBluetoothPinCodeType?: string;
    rsmBluetoothReconnectionAttempts?: number;
    rsmDateOfManufacture?: string;
    rsmDateOfService?: string;
    rsmDecodeFeedback?: boolean;
    rsmDeviceClass?: string;
    rsmFirmwareVersion?: string;
    rsmForceSavePairingBarcode?: boolean;
    rsmGoodScansDelay?: number;
    rsmIgnoreCode128Usps?: boolean;
    rsmLowBatteryIndication?: boolean;
    rsmLowBatteryIndicationCycle?: number;
    rsmMems?: boolean;
    rsmModelNumber?: string;
    rsmPagingBeepSequence?: number;
    rsmPagingEnable?: boolean;
    rsmProximityContinuous?: boolean;
    rsmProximityDistance?: string;
    rsmProximityEnable?: boolean;
    rsmScanLineWidth?: string;
    rsmScanTriggerWakeup?: boolean;
    rsmSerialNumber?: string;
    sameSymbolTimeout?: number;
    scanMode?: string;
    scanTimeout?: number;
    scannerType?: string;
    sceneDetectionQualifier?: string;
    signature?: boolean;
    signatureImageHeight?: number;
    signatureImageQuality?: number;
    signatureImageWidth?: number;
    signatureImageBitsPerPixel?: string;
    signatureImageFormat?: string;
    timedAimDuration?: number;
    tlc39?: boolean;
    triggerConnected?: boolean;
    triggerType?: string;
    trioptic39?: boolean;
    trioptic39Redundancy?: boolean;
    ukPostal?: boolean;
    ukPostalReportCheckDigit?: boolean;
    upcEanBookland?: boolean;
    upcEanBooklandFormat?: string;
    upcEanConvertGs1dataBarToUpcEan?: boolean;
    upcEanCoupon?: boolean;
    upcEanCouponReport?: string;
    upcEanLinearDecode?: boolean;
    upcEanRandomWeightCheckDigit?: boolean;
    upcEanRetryCount?: number;
    upcEanSecurityLevel?: number;
    upcEanSupplemental2?: boolean;
    upcEanSupplemental5?: boolean;
    upcEanSupplementalMode?: string;
    upca?: boolean;
    upcaPreamble?: string;
    upcaReportCheckDigit?: boolean;
    upce0?: boolean;
    upce0convertToUpca?: boolean;
    upce0preamble?: string;
    upce0reportCheckDigit?: boolean;
    upce1?: boolean;
    upce1convertToUpca?: boolean;
    upce1preamble?: string;
    upce1reportCheckDigit?: boolean;
    us4state?: boolean;
    us4stateFics?: boolean;
    usPlanet?: boolean;
    usPlanetReportCheckDigit?: boolean;
    usPostNet?: boolean;
    usPostNetReportCheckDigit?: boolean;
    viewfinderFeedback?: string;
    viewfinderFeedbackTime?: number;
    viewfinderHeight?: number;
    viewfinderMode?: string;
    viewfinderWidth?: number;
    viewfinderX?: number;
    viewfinderY?: number;
    webcode?: boolean;
    webcodeDecodeGtSubtype?: boolean;
    zoom?: number;
}

export const enableScanner = (callback: (response: BarCodeScannerResponse) => void, params?: ScannerParams) => {
    if (isZebra) {
        debug.info('[Scanner] Enabled', params);
        window.EB.Barcode.enable({ adaptiveScanning: true, ...params }, callback);
    }
};

export const disableScanner = () => {
    if (isZebra) {
        debug.info('[Scanner] Disabled');
        window.EB.Barcode.disable();
    }
};
