import React, { FC } from 'react';
import ArrowNavItem from '../../../../components/ArrowNavItem';
import ButtonWithShortcut from '../../../../components/ButtonWithShortcut';
import { ReturnZoneType } from '../../../../store/api/apiTypes';

interface ReturnPlaceZoneTypeContentProps {
    shortcutScope: string;
    onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    handleReturnZoneTypeClick: (returnZoneType: ReturnZoneType) => void;
}

const ReturnPlaceZoneTypeContent: FC<ReturnPlaceZoneTypeContentProps> = ({
    shortcutScope,
    onClose,
    handleReturnZoneTypeClick,
}) => {
    const returnZoneTypesArray: ReturnZoneType[] = Object.values(ReturnZoneType)
        .filter((value) => typeof value === 'string')
        .map((value) => value);

    return (
        <>
            {returnZoneTypesArray.map((zoneType, index) => {
                let text = '';
                switch (zoneType) {
                    case ReturnZoneType.picking:
                        text = 'Picking';
                        break;
                    case ReturnZoneType.mass:
                        text = 'Masse / Accumulation';
                        break;
                    case ReturnZoneType.destruction:
                        text = 'Destruction';
                        break;
                    case ReturnZoneType.soldeur:
                        text = 'Envoir soldeur';
                        break;
                }
                return (
                    <ArrowNavItem scope={shortcutScope} key={index}>
                        <ButtonWithShortcut
                            shortcut={`${index + 1}`}
                            type="primary"
                            shortcutScope={shortcutScope}
                            onClick={() => handleReturnZoneTypeClick(zoneType)}
                        >
                            {text}
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                );
            })}
            <ArrowNavItem scope={shortcutScope}>
                <ButtonWithShortcut
                    shortcut="esc"
                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                    shortcutScope={shortcutScope}
                    type="primary"
                    onClick={onClose}
                    ghost
                >
                    Retour
                </ButtonWithShortcut>
            </ArrowNavItem>
        </>
    );
};

export default ReturnPlaceZoneTypeContent;
