import { SearchPaginationQuery } from '.';
import { ControlOrderPallet, Pallet } from './apiTypes';
import api from './_client';

export interface ControlOrderPalletDetailsPayload {
    controlOrderPalletId: ControlOrderPallet['id'];
}
export const details = async ({ controlOrderPalletId, ...payload }: ControlOrderPalletDetailsPayload) =>
    await api.get(`/controlLoadingOrderPallets/${controlOrderPalletId}`, { params: payload });

export interface ControlOrderPalletPalletListPayload extends SearchPaginationQuery {
    controlOrderPalletId: ControlOrderPallet['id'];
}
export const palletList = async ({ controlOrderPalletId, ...payload }: ControlOrderPalletPalletListPayload) =>
    await api.get(`/controlLoadingOrderPallets/${controlOrderPalletId}/pallets`, { params: payload });

export interface ControlOrderPalletControlPalletListPayload {
    controlOrderPalletId: ControlOrderPallet['id'];
    barcode: Pallet['barcode'];
}
export const controlPallet = async (payload: ControlOrderPalletControlPalletListPayload) =>
    await api.get('/checkControlLoadingOrderPallet', { params: payload });

export interface ControlOrderPalletControlPalletsListPayload {
    controlOrderPalletId: ControlOrderPallet['id'];
    barcodes: Array<Pallet['barcode']>;
}
export const controlPallets = async (payload: ControlOrderPalletControlPalletsListPayload) =>
    await api.post('/checkControlLoadingOrderPallets', payload);
