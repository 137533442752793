import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 23 14">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M22 12a1 1 0 010 2H1a1 1 0 010-2h21zm0-6a1 1 0 010 2H1a1 1 0 110-2h21zm0-6a1 1 0 010 2H1a1 1 0 110-2h21z"
        />
    </svg>
);

const IconHandle: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-handle${props.className ? ` ${props.className}` : ''}`} />
);

export default IconHandle;
