import React, { FC, useEffect } from 'react';
import { Button, Descriptions, Result } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PalletPlace, PalletQuality, SearchType } from '../../store/api/apiTypes';
import {
    searchPlaces,
    searchQuantity,
    getPlacesSearchState,
    getQuantitySearchState,
} from '../../store/actions/stockResearch';

import { getRoute, RoutePathName } from '../../routes';
import { useActions, useIsMobile } from '../../hooks';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Seo from '../../components/Seo';
import List, { ListColumn } from '../../components/list/List';
import useQueryParams from '../../hooks/queryParams';
import { formatPlace, stripUndefinedKeysFromObject, urlSearchParamsToObject } from '../../helpers';
import { defaultErrorMessage, formatNumber } from '../../helpers/i18n';

const SearchResults: FC = () => {
    const history = useHistory();
    const isMobile = useIsMobile();
    const { type } = useParams<{ type: SearchType }>();
    const [urlSearchParams] = useQueryParams('searchResults');
    const searchParams = urlSearchParamsToObject(urlSearchParams) as {
        placeStoreId: string;
        placeStoreLabel: string;
        sector: string;
        lane: string;
        alveole: string;
        level: string;
        quantity: string;
        operatorSymbole: string;
        locker: string;
    };
    const { placeStoreId, placeStoreLabel, sector, lane, alveole, level, quantity, operatorSymbole, locker } =
        searchParams;
    stripUndefinedKeysFromObject(searchParams);
    const placesSearchState = useSelector(getPlacesSearchState);
    const quantitySearchState = useSelector(getQuantitySearchState);
    const [fetchPlaces, fetchQuantity] = useActions([searchPlaces.trigger, searchQuantity.trigger]);
    const onLoadMore = (type: SearchType) => {
        switch (type) {
            case SearchType.places:
                fetchPlaces(
                    {
                        type: SearchType.places,
                        pageSize: 10,
                        page: (placesSearchState.data?.page ?? 0) + 1,
                        placeStoreId,
                        sector,
                        lane,
                        alveole,
                        level,
                        locker,
                    },
                    { loadMore: true }
                );
                break;
            case SearchType.quantity:
                fetchQuantity(
                    {
                        type: SearchType.quantity,
                        pageSize: 10,
                        page: (quantitySearchState.data?.page ?? 0) + 1,
                        placeStoreId,
                        quantity,
                        operatorSymbole,
                    },
                    { loadMore: true }
                );
                break;
        }
    };
    const onClickItem = (type: SearchType, record: PalletPlace) => {
        history.push({
            pathname: getRoute(RoutePathName.searchDetails, { type, id: record?.id ?? '' }),
            search: new URLSearchParams({ placeStoreId, placeStoreLabel }).toString(),
        });
    };
    const columns: Array<ListColumn<PalletPlace>> = [
        locker
            ? {
                  key: 'pallet.reference',
                  title: 'Référence palette',
                  flex: '1 1 30%',
                  render: (record) =>
                      locker.toUpperCase() === 'BOX9' ? (
                          record?.pallet?.reference
                      ) : (
                          <span style={{ whiteSpace: 'nowrap' }}>{record?.pallet?.reference}</span>
                      ),
              }
            : {
                  key: 'place',
                  title: 'Emplacement',
                  flex: '1 1 30%',
                  render: ({ place }) => <span style={{ whiteSpace: 'nowrap' }}>{formatPlace(place)}</span>,
              },
        {
            key: 'ref',
            title: 'Référence colis',
            flex: '1 1 50%',
            render: (record) => record?.pallet?.parcel?.reference ?? record?.place?.parcel?.reference ?? '—',
        },
        {
            key: 'quantity',
            title: 'Quantité',
            flex: '1 1 20%',
            render: (record) => formatNumber(record?.pallet?.quantity),
        },
    ];

    useEffect(() => {
        if (type === SearchType.quantity) {
            fetchQuantity({
                type: SearchType.quantity,
                pageSize: 10,
                placeStoreId,
                quantity,
                operatorSymbole,
            });
        }
    }, [fetchQuantity, type, placeStoreId, quantity, operatorSymbole]);

    useEffect(() => {
        if (type === SearchType.places) {
            fetchPlaces({
                type: SearchType.places,
                pageSize: 10,
                placeStoreId,
                sector,
                lane,
                alveole,
                level,
                locker,
            });
        }
    }, [fetchPlaces, type, placeStoreId, sector, lane, alveole, level, locker]);

    return (
        <>
            <Seo title="Recherche stock" />
            <Header
                title="Recherche stock"
                backRoute={{
                    pathname: getRoute(RoutePathName.searchType, { type }),
                    search: urlSearchParams.toString(),
                }}
                enableHomeButton
            />
            <PageHeader>
                <Descriptions size="small" column={type === SearchType.places ? 5 : 2} colon={false} layout="vertical">
                    {placeStoreLabel && <Descriptions.Item label="Site">{placeStoreLabel}</Descriptions.Item>}
                    {sector && <Descriptions.Item label="Secteur">{sector}</Descriptions.Item>}
                    {lane && <Descriptions.Item label="Allée">{lane}</Descriptions.Item>}
                    {alveole && <Descriptions.Item label="Alveole">{alveole}</Descriptions.Item>}
                    {level && <Descriptions.Item label="Niveau">{level}</Descriptions.Item>}
                    {locker && <Descriptions.Item label="Casier">{locker}</Descriptions.Item>}
                    {quantity && operatorSymbole && (
                        <Descriptions.Item label="Quantité">
                            {operatorSymbole} {quantity}
                        </Descriptions.Item>
                    )}
                </Descriptions>
            </PageHeader>
            {type === SearchType.places && (
                <>
                    {!placesSearchState.loading && placesSearchState.error ? (
                        <Result title={defaultErrorMessage} />
                    ) : (
                        <>
                            <List<PalletPlace>
                                columns={columns}
                                data={placesSearchState.data?.items}
                                rowKey={(record) => `${record.id}`}
                                onRowEnterPress={onClickItem.bind(null, SearchType.places)}
                                onRowClick={onClickItem.bind(null, SearchType.places)}
                                isLoading={placesSearchState.loading && placesSearchState.data?.page === undefined}
                                isRowError={(record) =>
                                    !!record.pallet && record.pallet.quality === PalletQuality.noConform
                                }
                                loadingRowsCount={11}
                                title={
                                    !placesSearchState.loading
                                        ? `${formatNumber(placesSearchState.data?.totalCount)} resultat${
                                              placesSearchState.data?.totalCount !== 1 ? 's' : ''
                                          } trouvé${placesSearchState.data?.totalCount !== 1 ? 's' : ''}`
                                        : 'Recherche en cours'
                                }
                                emptyDescription="Aucuns résultats"
                                style={isMobile ? { marginTop: 24 } : undefined}
                                noWarningIcon={() => true}
                            />
                            {placesSearchState.data &&
                                placesSearchState.data.page < placesSearchState.data.pageCount - 1 && (
                                    <Button
                                        onClick={onLoadMore.bind(null, SearchType.places)}
                                        loading={placesSearchState.loading}
                                        size="small"
                                        style={{ marginTop: 16, fontSize: 14 }}
                                        block
                                    >
                                        Plus de résultats
                                    </Button>
                                )}
                        </>
                    )}
                </>
            )}
            {type === SearchType.quantity && (
                <>
                    <List<PalletPlace>
                        columns={columns}
                        data={quantitySearchState.data?.items}
                        rowKey={(record) => `${record.id}`}
                        onRowEnterPress={!locker ? onClickItem.bind(null, SearchType.quantity) : undefined}
                        onRowClick={!locker ? onClickItem.bind(null, SearchType.quantity) : undefined}
                        isRowError={(record) => !!record.pallet && record.pallet.quality === PalletQuality.noConform}
                        isLoading={quantitySearchState.loading && quantitySearchState.data?.page === undefined}
                        loadingRowsCount={11}
                        title={
                            !quantitySearchState.loading
                                ? `${formatNumber(quantitySearchState.data?.totalCount)} resultat${
                                      quantitySearchState.data?.totalCount !== 1 ? 's' : ''
                                  } trouvé${quantitySearchState.data?.totalCount !== 1 ? 's' : ''}`
                                : 'Recherche en cours'
                        }
                        emptyDescription="Aucuns résultats"
                        style={isMobile ? { marginTop: 24 } : undefined}
                    />
                    {quantitySearchState.data &&
                        quantitySearchState.data.page < quantitySearchState.data.pageCount - 1 && (
                            <Button
                                onClick={onLoadMore.bind(null, SearchType.quantity)}
                                loading={quantitySearchState.loading}
                                size="small"
                                style={{ marginTop: 16, fontSize: 14 }}
                                block
                            >
                                Plus de résultats
                            </Button>
                        )}
                </>
            )}
        </>
    );
};

export default SearchResults;
