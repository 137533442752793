import React, { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Repair, RepairStatus } from '../../../store/api/apiTypes';

import Header from '../../../components/Header';
import List, { ListColumn } from '../../../components/list/List';
import Seo from '../../../components/Seo';
import { useActions, useIsMobile } from '../../../hooks';
import { getRoute, RoutePathName } from '../../../routes';
import { formatDate, formatNumber, translateRepairStatus } from '../../../helpers/i18n';

import FixedFooter from '../../../components/FixedFooter';
import { getRepairsListState, listRepairs } from '../../../store/actions/repairs';
import { isZebra } from '../../../helpers/enterprise-browser';
import { getUser } from '../../../store/actions/auth';
import { Button } from 'antd';
import { PaginationQuery } from '../../../store/api';
import { getOperatorName } from '../../../helpers';

const RepairsList: FC = () => {
    const isMobile = useIsMobile();
    const history = useHistory();

    const fetchRepairs = useActions(listRepairs.trigger);
    const repairsList = useSelector(getRepairsListState);
    const user = useSelector(getUser);

    const getRepairs = useCallback(
        (params?: PaginationQuery, loadMore?: boolean) => {
            return fetchRepairs(
                {
                    order: 'asc',
                    status: [RepairStatus.toBeProcessed, RepairStatus.inProgress],
                    ...params,
                },
                { loadMore }
            );
        },
        [fetchRepairs]
    );

    useEffect(() => {
        getRepairs();
    }, [getRepairs]);

    const onClickItem = (record: Repair) => {
        history.push(getRoute(RoutePathName.movementsRepairDetails, { repairId: record.id }));
    };

    const isRowLocked = (repair: Repair) => {
        return isZebra && !!repair.operator && repair.operator.id !== user?.id;
    };
    const onLoadMore = () => {
        const currentPage: number = repairsList.data?.page ?? 0;
        getRepairs(
            {
                page: currentPage + 1,
            },
            true
        );
    };

    const columns = (
        (isZebra
            ? [
                  {
                      title: 'Référence colis',
                      flex: '1 1 50%',
                      render: (record) => record.parcel?.reference ?? '-',
                  },
                  {
                      title: 'Statut',
                      flex: '1 1 50%',
                      render: (record) => translateRepairStatus(record.status),
                  },
              ]
            : [
                  {
                      title: 'Date',
                      flex: '1 1 25%',
                      render: (record) => formatDate(record.createdAt),
                  },
                  {
                      key: 'time',
                      title: 'Heure',
                      flex: '1 1 25%',
                      render: (record) => formatDate(record.createdAt, { minute: '2-digit', hour: '2-digit' }),
                  },
                  {
                      title: 'Statut',
                      flex: '1 1 50%',
                      render: (record) => translateRepairStatus(record.status),
                  },
                  {
                      title: 'Cariste',
                      flex: '1 1 25%',
                      render: (record) => getOperatorName(record.operator),
                  },
              ]) as Array<ListColumn<Repair>>
    ).filter(Boolean);

    return (
        <FixedFooter.Wrapper>
            <Seo title="Réparations" />
            <Header title="Réparations" backRoute={getRoute(RoutePathName.movementsMenu)} enableHomeButton />
            <List<Repair>
                columns={columns}
                data={repairsList.data?.items}
                rowKey={(record) => String(record.id)}
                onRowEnterPress={onClickItem}
                onRowClick={onClickItem}
                isLoading={repairsList.loading}
                isRowLocked={isRowLocked}
                title={
                    repairsList.data
                        ? `${formatNumber(repairsList.data?.totalCount)} Réparation${
                              repairsList.data?.totalCount > 1 ? 's' : ''
                          } à ranger`
                        : 'Réparation à ranger'
                }
                style={isMobile ? { marginTop: 24 } : undefined}
                columnWidthGrow={false}
            />
            {repairsList.data && repairsList.data.page < repairsList.data.pageCount - 1 && (
                <Button
                    onClick={onLoadMore}
                    loading={repairsList.loading}
                    size="small"
                    style={{ margin: '1rem 0', fontSize: 14 }}
                    block
                >
                    Plus de résultats
                </Button>
            )}
        </FixedFooter.Wrapper>
    );
};

export default RepairsList;
