import React, { FC } from 'react';

import { SearchType } from '../../store/api/apiTypes';

import { getRoute, RoutePathName } from '../../routes';
import Header from '../../components/Header';
import Seo from '../../components/Seo';
import BasicList from '../../components/BasicList';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import ButtonLink from '../../components/ButtonLink';

const Search: FC = () => {
    return (
        <>
            <Seo title="Recherche stock" />
            <Header title="Recherche stock" backRoute={getRoute(RoutePathName.home)} />
            <nav id="search-menu" className="menu">
                <BasicList>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="1"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.searchType, { type: SearchType.parcels })}
                                block
                            >
                                Référence
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="2"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.searchType, { type: SearchType.places })}
                                block
                            >
                                Emplacement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="3"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.searchType, { type: SearchType.pallets })}
                                block
                            >
                                Palette
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut
                                shortcut="4"
                                as={ButtonLink}
                                to={getRoute(RoutePathName.searchType, { type: SearchType.quantity })}
                                block
                            >
                                Quantité
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                </BasicList>
            </nav>
        </>
    );
};

export default Search;
