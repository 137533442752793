import { CustomerOrder, Expedition, PlatformLoading, PlatformLoadingStatus } from './apiTypes';
import api from './_client';

export interface PlatformLoadingCreatePayload {
    expeditionId: Expedition['id'];
    customerOrderIds: Array<CustomerOrder['id']>;
}
export const create = async (payload: PlatformLoadingCreatePayload) => await api.post(`/platformLoadings`, payload);

export interface PlatformLoadingsListPayload {
    status: PlatformLoadingStatus;
    reference?: string;
}
export const list = async (payload: PlatformLoadingsListPayload) =>
    await api.get(`/platformLoadings`, { params: payload });

export interface PlatformLoadingIdPayload {
    id: PlatformLoading['id'];
}
export const details = async ({ id }: PlatformLoadingIdPayload) => await api.get(`/platformLoadings/${id}`);

export const del = async (payload: PlatformLoadingIdPayload) => await api.delete(`/platformLoadings/${payload.id}`);

export interface PlatformLoadingUpdatePayload {
    id: PlatformLoading['id'];
    status?: PlatformLoadingStatus;
    quantity?: number;
    operatorId?: number | null;
}
export const update = async ({ id, ...payload }: PlatformLoadingUpdatePayload) =>
    await api.put(`/platformLoadings/${id}`, payload);

export const pdf = async (payload: PlatformLoadingIdPayload) =>
    await api.get(`/platformLoadings/${payload.id}/generateFrlPdf`, { responseType: 'blob' });

export interface CheckPlatformLoadingsPalletPayload {
    customerOrderId: CustomerOrder['id'];
    barcode?: string;
    reference?: string;
}

export const checkPlatformLoadingsPallet = async (payload: CheckPlatformLoadingsPalletPayload) =>
    await api.get(`/checkPlatformLoadingsPallet`, { params: payload });
export interface CheckPlatformLoadingsPalletsPayload {
    customerOrderId: CustomerOrder['id'];
    barcode?: string[];
    references?: string[];
}

export const checkPlatformLoadingsPallets = async (payload: CheckPlatformLoadingsPalletsPayload) =>
    await api.post(`/checkPlatformLoadingsPallets`, payload);
