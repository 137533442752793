import { Form, FormProps, Input, message, Typography } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    control,
    controlPackage,
    controlPackages,
    getControlOrderControlPackagesState,
    getControlOrderControlPackageState,
    getControlOrderControlState,
    PackageRef,
} from '../../store/actions/preparationControl';
import { getPreparationRemainingQuantityToControlByCustomerOrderIdAndParcelIdState } from '../../store/actions/preparations';
import { getCustomerOrderStateById } from '../../store/actions/customerOrders';
import { Pallet } from '../../store/api/apiTypes';

import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import QuantityCard from '../../components/QuantityCard';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { formatNumber, getControlPackageGetError } from '../../helpers/i18n';
import { isControlPackageAlreadyStoredLocally } from '../../helpers';
import { IconWarning } from '../../components/icons';

const shortcutScope = 'ControlVracScanModal';

interface ControlVracScanModalProps extends CustomModalProps {
    onFinish: () => void;
    palletId?: Pallet['id'];
}

const ControlVracScanModal: FC<ControlVracScanModalProps> = ({ visible, onCancel, onFinish, palletId }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const { customerOrderId: customerOrderIdParam } = useParams<{ customerOrderId: string }>();
    const customerOrderId = parseInt(customerOrderIdParam ?? '-1', 10);
    const [form] = Form.useForm();
    const [step, setStep] = useState<'scan' | 'manual' | 'alreadyControlled'>('scan');
    const [refToStore, setRefToStore] = useState<PackageRef>(); // temp data when waiting for API response
    const [barcodeToStore, setBarcodeToStore] = useState<string>(); // temp data when waiting for API response
    const [
        fetchControlPackage,
        sendControlPackages,
        setControlVracState,
        setControlLocalParcelCountByParcelId,
        resetControlState,
        setControlPrintState,
    ] = useActions([
        controlPackage.trigger,
        controlPackages.trigger,
        control.actions.setVracData,
        control.actions.setLocalParcelCountByParcelId,
        control.actions.resetVrac,
        control.actions.setPrintData,
    ]);
    const controlPackageState = useSelector(getControlOrderControlPackageState);
    const controlPackagesState = useSelector(getControlOrderControlPackagesState);
    const customerOrderState = useSelector(getCustomerOrderStateById(customerOrderId));
    const { packagesRefs, packagesBarcodes } = useSelector(getControlOrderControlState).vrac;
    const localParcelCountByParcelId = useSelector(getControlOrderControlState).localParcelCountByParcelId;
    const remainingQuantityToControlForCurrentParcel =
        useSelector(
            getPreparationRemainingQuantityToControlByCustomerOrderIdAndParcelIdState(
                customerOrderId,
                controlPackageState.data?.id
            )
        ) - (localParcelCountByParcelId[controlPackageState.data?.id ?? -1] ?? 0);
    const totalRemainingQuantityToControl =
        (customerOrderState.data?.preparedQuantity ?? 0) -
        (customerOrderState.data?.controlledQuantity ?? 0) -
        packagesRefs.length -
        packagesBarcodes.length;
    const previous = usePrevious({ visible, controlPackageState, controlPackagesState });
    const onClose = useCallback(() => {
        onCancel?.({} as React.MouseEvent<HTMLElement, MouseEvent>);
    }, [onCancel]);
    const onSubmitManual: FormProps['onFinish'] = (values) => {
        if (isControlPackageAlreadyStoredLocally(packagesRefs, values)) {
            message.error('Ce colis est déjà présent dans la palette');
        } else {
            setRefToStore({
                reference: values.reference,
                ...(values.cont ? { cont: values.cont } : {}),
            });
            fetchControlPackage({
                customerOrderId,
                reference: values.reference,
                ...(values.cont ? { cont: values.cont } : {}),
            });
        }
    };
    const onSubmit = () => {
        sendControlPackages({
            customerOrderId,
            barcodes: packagesBarcodes.length ? packagesBarcodes : undefined,
            references: packagesRefs.length ? packagesRefs : undefined,
            palletId,
        });
    };
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                if (packagesBarcodes.includes(barCode.data)) {
                    message.error('Ce colis est déjà présent dans la palette');
                } else {
                    setBarcodeToStore(barCode.data);
                    fetchControlPackage({
                        customerOrderId,
                        barcode: barCode.data,
                    });
                }
            }
        },
        {
            deps: [fetchControlPackage, packagesBarcodes],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    // get on parcel, if request fails because parcel is already on another pallet, send to transfer modal;
    // otherwise check if we have remaining quantity to add for this parcel
    useEffect(() => {
        if (visible && previous?.controlPackageState.loading && !controlPackageState.loading) {
            if (controlPackageState.error) {
                const requestError = getControlPackageGetError(controlPackageState);

                if (requestError.alreadyControlled) {
                    setStep('alreadyControlled');
                } else if (requestError.message) {
                    message.error(requestError.message);
                }
            } else if (controlPackageState.data) {
                if (totalRemainingQuantityToControl === 0) {
                    message.error(
                        'Vous avez contrôlé tous les colis préparés, vous pouvez transférer/supprimer des colis, mais pas en ajouter',
                        5
                    );
                } else if (remainingQuantityToControlForCurrentParcel === 0) {
                    message.error(
                        'Vous avez déjà contrôlé tous les colis préparés de cette référence, veuillez contrôler une autre référence',
                        5
                    );
                } else {
                    setControlVracState({
                        packagesBarcodes: barcodeToStore ? [...packagesBarcodes, barcodeToStore] : packagesBarcodes,
                        packagesRefs: refToStore ? [...packagesRefs, refToStore] : packagesRefs,
                    });
                    setControlLocalParcelCountByParcelId({
                        ...localParcelCountByParcelId,
                        [controlPackageState.data.id]:
                            (localParcelCountByParcelId[controlPackageState.data.id] ?? 0) + 1,
                    });
                }

                form.resetFields();
                setStep('scan');
            }
            setBarcodeToStore(undefined);
            setRefToStore(undefined);
        }
    }, [
        previous?.controlPackageState.loading,
        controlPackageState,
        barcodeToStore,
        refToStore,
        form,
        packagesBarcodes,
        packagesRefs,
        setControlVracState,
        setControlLocalParcelCountByParcelId,
        visible,
        totalRemainingQuantityToControl,
        remainingQuantityToControlForCurrentParcel,
        localParcelCountByParcelId,
    ]);

    useEffect(() => {
        if (visible && previous?.controlPackagesState.loading && !controlPackagesState.loading) {
            if (controlPackagesState.error) {
                message.error('Une erreur est survenue pendant le contrôle des colis vrac');
            } else {
                if (controlPackagesState.data) {
                    setControlPrintState({
                        vracPalletId: controlPackagesState.data.id,
                    });
                    onFinish();
                    onClose();
                } else {
                    message.error('Une erreur est survenue pendant le contrôle des colis vrac');
                }
            }
        }
    }, [
        previous?.controlPackagesState.loading,
        controlPackagesState.loading,
        controlPackagesState.error,
        controlPackagesState.data,
        setControlPrintState,
        onFinish,
        onClose,
        visible,
    ]);

    useEffect(() => {
        if (!visible) {
            setBarcodeToStore(undefined);
            setRefToStore(undefined);
            resetControlState([]);
            setStep('scan');
            form.resetFields();
        }
    }, [visible, setBarcodeToStore, setRefToStore, resetControlState, setStep, form]);

    return (
        <CustomModal
            footer={
                step === 'manual' ? (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={() => form.submit()}
                                loading={controlPackageState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={setStep.bind(null, 'scan')}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                ) : step === 'alreadyControlled' ? (
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={setStep.bind(null, 'scan')}
                            ghost
                        >
                            Retour
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                ) : (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={onSubmit}
                                loading={controlPackagesState.loading}
                                disabled={packagesRefs.length + packagesBarcodes.length === 0}
                            >
                                Terminer le vrac
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="f1"
                                type="primary"
                                shortcutScope={shortcutScope}
                                onClick={setStep.bind(null, 'manual')}
                                ghost
                            >
                                Saisir manuellement
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={
                step !== 'alreadyControlled' &&
                (step === 'manual'
                    ? 'Saisir manuellement'
                    : 'Veuillez scanner individuellement les colis pour les contrôler :')
            }
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            altTitle={step === 'manual'}
        >
            {step === 'manual' ? (
                <Form form={form} onFinish={onSubmitManual}>
                    <Form.Item name="reference" rules={[{ required: true, message: 'Veuillez saisir une référence' }]}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir référence colis" autoFocus />
                        </ArrowNavItem>
                    </Form.Item>
                    <Form.Item name="cont" style={{ marginBottom: 0 }}>
                        <ArrowNavItem scope={shortcutScope}>
                            <Input placeholder="Saisir CONT (optionnel)" />
                        </ArrowNavItem>
                    </Form.Item>
                </Form>
            ) : step === 'alreadyControlled' ? (
                <Typography.Text>
                    <Typography.Text type="warning">
                        <IconWarning style={{ fontSize: '2.25rem', marginBottom: '1rem' }} />
                    </Typography.Text>
                    <br />
                    Ce colis a déjà été contrôlé.
                </Typography.Text>
            ) : (
                <QuantityCard
                    value={formatNumber(packagesRefs.length + packagesBarcodes.length)}
                    label="colis contrôlés"
                    outline
                />
            )}
        </CustomModal>
    );
};

export default ControlVracScanModal;
