import { CustomerOrderPlatformReasonCode } from './apiTypes';
import api from './_client';

export type CustomerOrderPlatformReasonCodeUpdatePayload = CustomerOrderPlatformReasonCode;
export const update = async ({ id, ...payload }: CustomerOrderPlatformReasonCodeUpdatePayload) =>
    await api.put(`/customerOrderPlatformReasonCodes/${id}`, payload);

export type CustomerOrderPlatformReasonCodeSendMailPayload = Pick<CustomerOrderPlatformReasonCode, 'id'>;
export const sendMail = async ({ id }: CustomerOrderPlatformReasonCodeSendMailPayload) =>
    await api.post(`/customerOrderPlatformReasonCodes/${id}/sendMail`);
