import React, { FC } from 'react';
import Seo from '../../components/Seo';
import Header from '../../components/Header';
import { useHistory } from 'react-router-dom';
import BasicList from '../../components/BasicList';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import { useModal } from '@ebay/nice-modal-react';
import SelectSectorModal from './SelectSectorModal';
import { getRoute, RoutePathName } from '../../routes';
import ButtonLink from '../../components/ButtonLink';
import { isZebra } from '../../helpers/enterprise-browser';
import SelectPrinterModal from './SelectPrinterModal';

const Settings: FC = () => {
    const history = useHistory();
    const selectSectorModal = useModal(SelectSectorModal);
    const selectPrinterModal = useModal(SelectPrinterModal);

    const onClickDefaultSector = () => {
        selectSectorModal.show();
    };

    const onClickDefaultPrinter = () => {
        selectPrinterModal.show();
    };

    return (
        <>
            <Seo title={'Paramétrage'} />
            <Header
                title={'Paramétrage'}
                onClickBack={() => {
                    history.goBack();
                }}
            />
            <nav className="menu">
                <BasicList>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut shortcut="1" onClick={onClickDefaultSector} block>
                                Secteur par défaut
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    <li>
                        <ArrowNavItem>
                            <ButtonWithShortcut shortcut="2" onClick={onClickDefaultPrinter} block>
                                Imprimante par défaut
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </li>
                    {!isZebra && (
                        <>
                            <li>
                                <ArrowNavItem>
                                    <ButtonWithShortcut
                                        as={ButtonLink}
                                        shortcut="3"
                                        to={getRoute(RoutePathName.incidentsList)}
                                        block
                                    >
                                        Incidents
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </li>
                            <li>
                                <ArrowNavItem>
                                    <ButtonWithShortcut
                                        as={ButtonLink}
                                        shortcut="4"
                                        to={getRoute(RoutePathName.machinesList)}
                                        block
                                    >
                                        Engins
                                    </ButtonWithShortcut>
                                </ArrowNavItem>
                            </li>
                        </>
                    )}
                </BasicList>
            </nav>
        </>
    );
};

export default Settings;
