import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Form, Input, message, Select, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { FormProps, useForm } from 'antd/lib/form/Form';
import { NamePath } from 'antd/lib/form/interface';

import { InventoryType, Place, PlaceFilter } from '../../store/api/apiTypes';
import { getPlacesListFiltersState, listFilters } from '../../store/actions/places';
import { create as inventoryCreate, getInventoryCreateState } from '../../store/actions/inventories';
import { InventoryCreatePayload } from '../../store/api/inventories';

import { getRoute, RoutePathName } from '../../routes';
import { useActions, useIsMobile, usePrevious } from '../../hooks';
import { stripUndefinedKeysFromObject } from '../../helpers';
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Seo from '../../components/Seo';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import FixedFooter from '../../components/FixedFooter';
import ButtonGrey from '../../components/ButtonGrey';
import UserSelect from '../../components/UserSelect';
import PlaceStoreSelect from '../../components/PlaceStoreSelect';

type Filters = Pick<Place, 'sector' | 'lane' | 'alveole' | 'level'>;
const filterNames: Array<keyof Filters> = ['sector', 'lane', 'alveole', 'level'];
const filterTranslations = ['Secteur', 'Allée', 'Alvéole', 'Niveau'];

const isDisabled = (filter: keyof Filters, getFieldValue: (name: NamePath) => any) => {
    switch (filter) {
        case 'sector':
            return !!getFieldValue('locker');
        case 'lane':
            return !getFieldValue('sector') || !!getFieldValue('locker');
        case 'alveole':
            return !getFieldValue('lane') || !!getFieldValue('locker');
        case 'level':
            return !getFieldValue('alveole') || !!getFieldValue('locker');
    }
};

const InventoryCreate: FC = () => {
    const [form] = useForm();
    const isMobile = useIsMobile();
    const history = useHistory();
    const { inventoryType } = useParams<{ inventoryType: InventoryType }>();
    const [fetchFilters, createInventory] = useActions([listFilters.trigger, inventoryCreate.trigger]);
    const placesFiltersState = useSelector(getPlacesListFiltersState);
    const inventoryCreateState = useSelector(getInventoryCreateState);
    const previous = usePrevious({ inventoryCreateState });
    const onSubmit: FormProps['onFinish'] = (values) => {
        const params: InventoryCreatePayload = {
            ...values,
            placeStoreId: values.placeStore?.id,
            operatorId: values.operator?.id,
        };

        delete params.placeStore;
        delete params.operator;

        stripUndefinedKeysFromObject(params);
        createInventory(params);
    };

    const onFormValuesChange: FormProps['onFieldsChange'] = (changedFields, allFields) => {
        if (
            changedFields.some((field) =>
                filterNames.includes((Array.isArray(field.name) ? field.name[0] : field.name) as keyof Filters)
            )
        ) {
            fetchFilters(
                allFields
                    .filter((field) =>
                        filterNames.includes((Array.isArray(field.name) ? field.name[0] : field.name) as keyof Filters)
                    )
                    .reduce(
                        (acc, field) => {
                            let fieldName = Array.isArray(field.name) ? field.name[0] : field.name;
                            const fieldValue = fieldName === 'store' ? field.value?.id : field.value;

                            fieldName = fieldName === 'store' ? 'storeId' : fieldName;

                            return {
                                ...acc,
                                [fieldName]: fieldValue,
                            };
                        },
                        {
                            storeId: form.getFieldValue('store')?.id,
                        }
                    )
            );

            changedFields.forEach((field) => {
                if (field.value === undefined) {
                    switch (Array.isArray(field.name) ? field.name[0] : field.name) {
                        case 'store':
                            form.setFieldsValue({
                                sector: undefined,
                                lane: undefined,
                                alveole: undefined,
                                level: undefined,
                            });
                            break;

                        case 'locker':
                            form.setFieldsValue({
                                sector: undefined,
                                lane: undefined,
                                alveole: undefined,
                                level: undefined,
                            });
                            break;

                        case 'sector':
                            form.setFieldsValue({
                                lane: undefined,
                                alveole: undefined,
                                level: undefined,
                                locker: undefined,
                            });
                            break;

                        case 'lane':
                            form.setFieldsValue({ alveole: undefined, level: undefined });
                            break;

                        case 'alveole':
                            form.setFieldsValue({ level: undefined });
                            break;
                    }
                } else {
                    switch (Array.isArray(field.name) ? field.name[0] : field.name) {
                        case 'locker':
                            form.setFieldsValue({
                                sector: undefined,
                                lane: undefined,
                                alveole: undefined,
                                level: undefined,
                            });
                            break;
                    }
                }
            });
        }
    };

    useEffect(() => {
        if (previous?.inventoryCreateState.loading && !inventoryCreateState.loading) {
            if (inventoryCreateState.error) {
                if (inventoryCreateState.error.status === 404 && inventoryType === InventoryType.parcel) {
                    message.error('Référence non trouvée');
                } else {
                    message.error("Une erreur est survenue lors de la création de l'inventaire");
                }
            } else {
                history.push({
                    pathname: getRoute(RoutePathName.inventoryMenu),
                    state: { createSuccess: true },
                });
            }
        }
    }, [
        previous?.inventoryCreateState.loading,
        inventoryCreateState.loading,
        inventoryCreateState.error,
        history,
        inventoryType,
    ]);

    useEffect(() => {
        fetchFilters();
    }, [fetchFilters]);

    return (
        <FixedFooter.Wrapper>
            <Seo title={`Créer inventaire - ${inventoryType === InventoryType.place ? 'Emplacement' : 'Référence'}`} />
            <Header
                title={`Créer inventaire - ${inventoryType === InventoryType.place ? 'Emplacement' : 'Référence'}`}
                backRoute={getRoute(RoutePathName.inventoryMenu)}
            />
            <PageHeader className="page-header-small">
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onSubmit}
                    onFieldsChange={onFormValuesChange}
                    requiredMark={false}
                >
                    <Form.Item
                        label="Site"
                        name="placeStore"
                        rules={[{ required: true, message: 'Veuillez renseigner un site' }]}
                    >
                        <PlaceStoreSelect allowClear={false} withArrowNav />
                    </Form.Item>
                    {inventoryType === InventoryType.place && (
                        <>
                            <Form.Item label="Casier" name="locker">
                                <ArrowNavItem>
                                    <Input placeholder="Saisir un casier" />
                                </ArrowNavItem>
                            </Form.Item>
                            <Divider>
                                <Typography.Text className="text-primary" style={{ fontSize: '0.9375rem' }} strong>
                                    OU
                                </Typography.Text>
                            </Divider>
                            <Form.Item shouldUpdate noStyle>
                                {({ getFieldValue }) => (
                                    <>
                                        {filterNames.map((filter, index) => (
                                            <Form.Item
                                                label={filterTranslations[index]}
                                                name={filter}
                                                key={filter}
                                                dependencies={filter === 'lane' ? ['sector'] : [filterNames[index - 1]]}
                                            >
                                                <ArrowNavItem>
                                                    <Select
                                                        placeholder={`Sélectionnez un${
                                                            filter === 'lane' || filter === 'alveole' ? 'e' : ''
                                                        } ${filterTranslations[index].toLowerCase()}`}
                                                        filterOption={false}
                                                        style={{ width: '100%' }}
                                                        disabled={isDisabled(filter, getFieldValue)}
                                                        allowClear
                                                        showArrow
                                                    >
                                                        {(
                                                            placesFiltersState.data?.[
                                                                `${filter}s` as keyof PlaceFilter
                                                            ] as Array<string | number>
                                                        )?.map((option) => (
                                                            <Select.Option value={option} key={option}>
                                                                {option}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </ArrowNavItem>
                                            </Form.Item>
                                        ))}
                                    </>
                                )}
                            </Form.Item>
                        </>
                    )}

                    {inventoryType === InventoryType.parcel && (
                        <Form.Item
                            label="Référence"
                            name="reference"
                            rules={[{ required: true, message: 'Veuillez renseigner une référence' }]}
                        >
                            <ArrowNavItem>
                                <Input placeholder="Saisir une référence" />
                            </ArrowNavItem>
                        </Form.Item>
                    )}

                    <Divider>
                        <Typography.Text className="text-primary" style={{ fontSize: '0.9375rem' }} strong>
                            ET
                        </Typography.Text>
                    </Divider>

                    <Form.Item
                        label="Cariste"
                        style={{ margin: 0 }}
                        name="operator"
                        rules={[{ required: true, message: 'Veuillez choisir un cariste' }]}
                    >
                        <UserSelect placeholder="Choisir un cariste" withArrowNav />
                    </Form.Item>

                    <button type="submit" style={{ display: 'none' }} />
                </Form>
            </PageHeader>
            <FixedFooter>
                <ArrowNavItem>
                    <ButtonWithShortcut
                        shortcut="enter"
                        onClick={form.submit}
                        as={ButtonGrey}
                        size={isMobile ? 'middle' : 'large'}
                        loading={inventoryCreateState.loading}
                        block
                    >
                        Créer l&rsquo;inventaire
                    </ButtonWithShortcut>
                </ArrowNavItem>
            </FixedFooter>
        </FixedFooter.Wrapper>
    );
};

export default InventoryCreate;
