import React, { FC } from 'react';
import { Redirect, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { SearchType } from '../../store/api/apiTypes';

import { getRawRoute, getRoute, RoutePathName } from '../../routes';
import Header from '../../components/Header';
import Seo from '../../components/Seo';
import { SearchDetailsPallet, SearchDetailsParcel, SearchDetailsPlace } from '.';

const SearchDetails: FC = () => {
    const history = useHistory();
    const { type, id } = useParams<{ type: SearchType; id: string }>();
    const isHistory = useRouteMatch(getRawRoute(RoutePathName.searchDetailsHistory));
    let title;

    switch (type) {
        case SearchType.parcels:
            title = isHistory ? 'Historique des mouvements' : 'Détail référence';
            break;
        case SearchType.places:
            title = 'Détail emplacement';
            break;
        case SearchType.pallets:
            title = 'Détail palette';
            break;

        default:
            title = 'Détail';
            break;
    }

    if (!id) {
        return <Redirect to={{ pathname: getRoute(RoutePathName.searchType, { type }) }} />;
    }

    return (
        <>
            <Seo title={title} />
            <Header
                title={title}
                onClickBack={() => {
                    if (type === SearchType.parcels) {
                        history.push(getRoute(RoutePathName.searchType, { type }));
                    } else {
                        history.goBack();
                    }
                }}
                enableHomeButton
            />
            {type === SearchType.parcels && <SearchDetailsParcel id={Number(id)} />}
            {(type === SearchType.places || type === SearchType.quantity) && <SearchDetailsPlace id={Number(id)} />}
            {type === SearchType.pallets && <SearchDetailsPallet id={Number(id)} />}
        </>
    );
};

export default SearchDetails;
