import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { message, Typography } from 'antd';

import {
    close,
    getCustomerOrderCloseDetailsState,
    getCustomerOrderCloseState,
} from '../../store/actions/customerOrders';
import { CustomerOrderStatus } from '../../store/api/apiTypes';

import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import { IconWarning } from '../../components/icons';
import SuccessMessage from '../../components/SuccessMessage';
import { getCustomerOrderCloseError } from '../../helpers/i18n';

let timeout: number;
const shortcutScope = 'ControlCloseModal';

interface ControlCloseModalProps extends CustomModalProps {
    onSuccess: () => void;
}

const ControlCloseModal: FC<ControlCloseModalProps> = ({ visible, onCancel, onSuccess }) => {
    const history = useHistory();
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const { loadingOrderId, customerOrderId } = useParams<{ loadingOrderId: string; customerOrderId: string }>();
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [customerOrderClose] = useActions([close.trigger]);
    const customerOrderCloseDetailsState = useSelector(getCustomerOrderCloseDetailsState);
    const customerOrderCloseState = useSelector(getCustomerOrderCloseState);
    const previous = usePrevious({ customerOrderCloseState, isSuccess });
    const onClickClosePreparation = () => {
        customerOrderClose({
            customerOrderId,
        });
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    useEffect(() => {
        if (visible && previous?.customerOrderCloseState.loading && !customerOrderCloseState.loading) {
            if (customerOrderCloseState.error) {
                const requestError = getCustomerOrderCloseError(customerOrderCloseState);

                if (requestError.message) {
                    message.error(requestError.message);
                }
            } else {
                if (customerOrderCloseState.data?.status === CustomerOrderStatus.toRegularize) {
                    onClose();
                    history.push(
                        getRoute(RoutePathName.preparationControlRegularize, { loadingOrderId, customerOrderId })
                    );
                } else if (customerOrderCloseState.data?.status === CustomerOrderStatus.partial) {
                    setSuccessMessage('Préparation client clôturée partiellement avec succès');
                    setIsSuccess(true);
                    onSuccess();
                    timeout = window.setTimeout(() => {
                        history.push(getRoute(RoutePathName.customerOrderDetails, { loadingOrderId, customerOrderId }));
                    }, 2000);
                } else if (
                    customerOrderCloseState.data?.status &&
                    [CustomerOrderStatus.completed, CustomerOrderStatus.toBeLoaded].includes(
                        customerOrderCloseState.data?.status
                    )
                ) {
                    setSuccessMessage('Préparation client clôturée avec succès');
                    setIsSuccess(true);
                    onSuccess();
                    timeout = window.setTimeout(() => {
                        if (customerOrderCloseState.data?.status === CustomerOrderStatus.completed) {
                            history.push(
                                getRoute(RoutePathName.customerOrderDetails, { loadingOrderId, customerOrderId })
                            );
                        } else {
                            onClose();
                        }
                    }, 2000);
                } else if (customerOrderCloseState.data?.status === CustomerOrderStatus.cancelled) {
                    setSuccessMessage('Préparation client clôturée avec succès (annulée)');
                    setIsSuccess(true);
                    onSuccess();
                    timeout = window.setTimeout(() => {
                        onClose();
                    }, 2000);
                } else {
                    onClose();
                }
            }
        }
    }, [
        previous?.customerOrderCloseState.loading,
        customerOrderCloseState.loading,
        customerOrderCloseState.data,
        customerOrderCloseState,
        onClose,
        onSuccess,
        visible,
        history,
        loadingOrderId,
        customerOrderId,
    ]);

    useEffect(() => {
        return () => window.clearTimeout(timeout);
    }, []);

    const { orderDifference, orderDifferenceSAV } = customerOrderCloseDetailsState.data ?? {};

    return (
        <CustomModal
            footer={
                !isSuccess && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClickClosePreparation}
                                loading={customerOrderCloseState.loading}
                            >
                                Clore la préparation client
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Retourner au contrôle
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            visible={visible}
            onCancel={onCancel}
            title={
                !isSuccess && (
                    <Typography.Text>
                        <Typography.Text type="warning">
                            <IconWarning style={{ fontSize: '2.25rem', marginBottom: '1rem' }} />
                        </Typography.Text>
                        <br />
                        {orderDifference && orderDifferenceSAV
                            ? `Il y a un écart de ${orderDifference} colis, dont${' '}
                        ${orderDifferenceSAV} colis SAV.`
                            : orderDifference
                            ? `Il y a un écart de ${orderDifference} colis.`
                            : orderDifferenceSAV
                            ? `Il y a un écart de ${orderDifferenceSAV} colis SAV.`
                            : ''}
                        <br />
                        Que voulez-vous faire ?
                    </Typography.Text>
                )
            }
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            closable={!isSuccess}
            maskClosable={!isSuccess}
        >
            {isSuccess && <SuccessMessage message={successMessage} />}
        </CustomModal>
    );
};

export default ControlCloseModal;
