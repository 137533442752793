import { Divider, Form, Input, InputProps, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { detailsByScan, getParcelScanState } from '../../store/actions/parcels';
import { getPalletDetailsStateById, getPalletUpdateState, update } from '../../store/actions/pallets';
import { Pallet } from '../../store/api/apiTypes';

import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../components/CustomModal';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { isZebra } from '../../helpers/enterprise-browser';
import QuantityInput from '../../components/QuantityInput';

const shortcutScope = 'SearchPalletEditParcelModal';

interface SearchPalletEditParcelModalProps extends CustomModalProps {
    onSuccess: () => void;
    palletId: Pallet['id'];
}

const SearchPalletEditParcelModal: FC<SearchPalletEditParcelModalProps> = ({
    visible,
    onCancel,
    palletId,
    onSuccess,
}) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const palletState = useSelector(getPalletDetailsStateById(palletId));
    const [reference, setReference] = useState<string>();
    const [quantity, setQuantity] = useState(palletState.data?.quantity);
    const [step, setStep] = useState<'scan' | 'quantity'>('scan');
    const [loadParcelDetails, updatePallet] = useActions([detailsByScan.trigger, update.trigger]);
    const palletUpdateState = useSelector(getPalletUpdateState);
    const parcelScanState = useSelector(getParcelScanState);
    const previous = usePrevious({ parcelScanState, palletUpdateState });
    const onClose = useCallback(() => {
        onCancel?.({} as React.MouseEvent<HTMLElement, MouseEvent>);
    }, [onCancel]);
    const onSubmit = () => {
        loadParcelDetails({ reference });
    };
    const onSubmitQuantity = () => {
        updatePallet({
            id: palletId,
            parcelId: parcelScanState.data?.id,
            quantity,
        });
    };
    const onChangeField: InputProps['onChange'] = (e) => {
        setReference(e.target.value);
    };

    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                loadParcelDetails({ barcode: barCode.data });
            }
        },
        {
            deps: [loadParcelDetails],
            disable: !visible,
            scannerOptions: { ean13: false },
        }
    );

    // receive parcel details by barcode/reference (due to scan/submit), send to size step
    useEffect(() => {
        if (visible && previous?.parcelScanState.loading && !parcelScanState.loading) {
            if (parcelScanState.error) {
                if (parcelScanState.error?.status === 404) {
                    message.error('Colis non reconnu');
                } else {
                    message.error('Erreur lors de la demande de référence/code-barre');
                }
            } else if (parcelScanState.data) {
                setStep('quantity');
            }
        }
    }, [
        parcelScanState.data,
        parcelScanState.error,
        parcelScanState.loading,
        previous?.parcelScanState.loading,
        visible,
    ]);

    // update pallet response, close modal & fetch pallet to refresh
    useEffect(() => {
        if (visible && previous?.palletUpdateState.loading && !palletUpdateState.loading) {
            if (palletUpdateState.error) {
                message.error('Une erreur est survenue pendant la mise à jour de la palette');
            } else {
                onSuccess();
                onClose();
            }
        }
    }, [
        palletUpdateState.error,
        palletUpdateState.loading,
        previous?.palletUpdateState.loading,
        onClose,
        visible,
        onSuccess,
    ]);

    useEffect(() => {
        if (!visible) {
            setStep('scan');
            setReference(undefined);
            setQuantity(palletState.data?.quantity);
        }
    }, [visible, setReference, setStep, setQuantity, palletState.data?.quantity]);

    return (
        <CustomModal
            footer={
                <>
                    {step === 'scan' && (
                        <>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="enter"
                                    type="primary"
                                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                    shortcutScope={shortcutScope}
                                    onClick={onSubmit}
                                    loading={parcelScanState.loading}
                                    disabled={!reference}
                                >
                                    Valider
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    shortcut="esc"
                                    shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                    shortcutScope={shortcutScope}
                                    type="primary"
                                    onClick={onClose}
                                    ghost
                                >
                                    Annuler
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </>
                    )}
                    {step === 'quantity' && (
                        <>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    type="primary"
                                    shortcut="enter"
                                    shortcutScope={shortcutScope}
                                    onClick={onSubmitQuantity}
                                    loading={palletUpdateState.loading}
                                >
                                    Valider
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                            <ArrowNavItem scope={shortcutScope}>
                                <ButtonWithShortcut
                                    type="primary"
                                    shortcut="esc"
                                    shortcutScope={shortcutScope}
                                    onClick={setStep.bind(null, 'scan')}
                                    ghost
                                >
                                    Annuler
                                </ButtonWithShortcut>
                            </ArrowNavItem>
                        </>
                    )}
                </>
            }
            visible={visible}
            onCancel={onCancel}
            title={step === 'scan' ? 'Modifier référence' : 'Nouvelle référence'}
            width={368}
            keyboard={false}
            transitionName=""
            maskTransitionName=""
            altTitle
        >
            {step === 'scan' && (
                <ArrowNavItem scope={shortcutScope}>
                    <Input
                        placeholder={isZebra ? 'Flasher le colis' : 'Entrer la référence du colis'}
                        value={reference}
                        onChange={onChangeField}
                        autoFocus
                    />
                </ArrowNavItem>
            )}
            {step === 'quantity' && (
                <>
                    <div className="centered-description">
                        <div>
                            <p>Référence</p>
                            <p>{parcelScanState.data?.reference ?? '—'}</p>
                        </div>
                        <div>
                            <p>Désignation</p>
                            <p>{parcelScanState.data?.label ?? '—'}</p>
                        </div>
                    </div>
                    <Divider />
                    <Form.Item label="Quantité de colis">
                        <QuantityInput onChange={setQuantity} value={quantity} min={0} hasArrowNav={visible} />
                    </Form.Item>
                </>
            )}
        </CustomModal>
    );
};

export default SearchPalletEditParcelModal;
