import { SearchPaginationQuery } from '.';
import { PlatformAnomalyCode, PlatformReception } from './apiTypes';
import api from './_client';

export type PlatformReceptionListPayload = SearchPaginationQuery & { tourNumber?: string };
export const list = async (payload: PlatformReceptionListPayload = {}) =>
    await api.get('/platformReceptions', { params: payload });

export interface PlatformReceptionDetailsPayload {
    platformReceptionId: PlatformReception['id'];
}
export const details = async ({ platformReceptionId, ...payload }: PlatformReceptionDetailsPayload) =>
    await api.get(`/platformReceptions/${platformReceptionId}`, payload);

export interface PlatformReceptionPalletListPayload {
    platformReceptionId: PlatformReception['id'];
}
export const palletList = async ({ platformReceptionId, ...payload }: PlatformReceptionPalletListPayload) =>
    await api.get(`/platformReceptions/${platformReceptionId}/pallets`, { params: payload });

export interface PlatformReceptionCheckPalletPayload {
    platformReceptionId: PlatformReception['id'];
    reference?: string;
    barcode?: string;
}
export const checkPallet = async ({ platformReceptionId, ...payload }: PlatformReceptionCheckPalletPayload) =>
    await api.get(`/platformReceptions/${platformReceptionId}/checkReceivedPallet`, { params: payload });

export interface PlatformReceptionSendPalletsPayload {
    platformReceptionId: PlatformReception['id'];
    references?: Array<{
        reference: string;
        anomalyCode?: PlatformAnomalyCode['id'];
    }>;
    barcodes?: Array<{
        barcode: string;
        anomalyCode?: PlatformAnomalyCode['id'];
    }>;
}
export const sendPallets = async ({ platformReceptionId, ...payload }: PlatformReceptionSendPalletsPayload) =>
    await api.post(`/platformReceptions/${platformReceptionId}/receivedPallets`, payload);
