import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import CustomModal from '../../components/CustomModal';
import ArrowNavItem from '../../components/ArrowNavItem';
import ButtonWithShortcut from '../../components/ButtonWithShortcut';
import React, { useEffect } from 'react';
import { Input, message } from 'antd';
import { useActions, useArrowNavScope, usePrevious, useScanner, useShortcutScope } from '../../hooks';
import { detailsByScan, getParcelScanState } from '../../store/actions/parcels';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';

const shortcutScope = 'ParcelCheckingModal';

const ParcelCheckingModal = NiceModal.create(() => {
    const modal = useModal();
    const { hide, visible } = modal;
    const loadParcelScanDetails = useActions(detailsByScan.trigger);
    const parcelScanState = useSelector(getParcelScanState);
    const previous = usePrevious({ parcelScanState });
    const history = useHistory();
    useShortcutScope(shortcutScope);
    useArrowNavScope(shortcutScope);
    useScanner(
        shortcutScope,
        (barCode) => {
            if (barCode.data) {
                loadParcelScanDetails({ barcode: barCode.data });
            }
        },
        {
            disable: !visible,
        }
    );

    useEffect(() => {
        if (previous?.parcelScanState.loading && !parcelScanState.loading) {
            if (parcelScanState.error) {
                message.error('Code barre incorrect');
            } else {
                hide().then(() => history.push(getRoute(RoutePathName.parcelCheckDetails)));
            }
        }
    }, [hide, history, parcelScanState.error, parcelScanState.loading, previous?.parcelScanState.loading]);
    return (
        <CustomModal
            {...antdModal(modal)}
            title={`Vérification colis`}
            footer={
                <>
                    <ArrowNavItem scope={shortcutScope}>
                        <ButtonWithShortcut
                            shortcut="esc"
                            shortcutOptions={{ enableOnTags: ['INPUT'] }}
                            shortcutScope={shortcutScope}
                            type="primary"
                            onClick={hide}
                            ghost
                        >
                            Annuler
                        </ButtonWithShortcut>
                    </ArrowNavItem>
                </>
            }
            width={368}
            altTitle
        >
            <ArrowNavItem scope={shortcutScope}>
                <Input placeholder="Scanner le colis" disabled />
            </ArrowNavItem>
        </CustomModal>
    );
});

export default ParcelCheckingModal;
