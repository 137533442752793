import { combineReducers } from 'redux';
import { all, call, delay, put, race, take, takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { CustomerOrder, Parcel, Preparation, PreparationOrderType } from '../api/apiTypes';
import { ListResponse } from '../api';

// Controlers
import {
    PreparationListPayload,
    list as listApiCall,
    PreparationDetailsPayload,
    details as detailsApiCall,
    PreparationUpdatePayload,
    update as updateApiCall,
    PreparationStartPayload,
    start as preparationApiCall,
    PreparationPickingPayload,
    picking as pickingApiCall,
    sav as savApiCall,
    PreparationSAVPayload,
} from '../api/preparations';
import { DataAction, EzeeAction } from '../helpers/EzeeAction';
import { control } from './preparationControl';

type PreparationsListByTypeOnly = {
    [key in PreparationOrderType]: ListResponse<Preparation>;
};

export interface PreparationsListByType extends PreparationsListByTypeOnly {
    customerOrderId: CustomerOrder['id'];
}

export interface PreparationControlListByCustomerOrderId extends ListResponse<Preparation> {
    customerOrderId: CustomerOrder['id'];
}

// States
export interface PreparationsState {
    list: RequestState<ListResponse<Preparation>>;
    listByTypeAndCustomerOrderId: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: PreparationsListByType | undefined;
        };
    };
    listControlByCustomerOrderId: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: ListResponse<Preparation>;
        };
    };
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: Preparation | undefined;
        };
    };
    update: RequestState<Preparation>;
    start: RequestState<Preparation>;
    picking: RequestState<Preparation>;
    sav: RequestState<Preparation>;
}

const initialState: PreparationsState = {
    list: { ...requestInitialState },
    listByTypeAndCustomerOrderId: { ...requestInitialState, data: {} },
    listControlByCustomerOrderId: { ...requestInitialState, data: {} },
    details: { ...requestInitialState, data: {} },
    update: { ...requestInitialState },
    start: { ...requestInitialState },
    picking: { ...requestInitialState },
    sav: { ...requestInitialState },
};

export const list = new EzeeAsyncAction<PreparationsState['list'], PreparationListPayload, ListResponse<Preparation>>(
    'preparations/list',
    initialState.list,
    requestReducer<PreparationsState['list'], ListResponse<Preparation>>(initialState.list)
);

export const listByTypeAndCustomerOrderId = new EzeeAsyncAction<
    PreparationsState['listByTypeAndCustomerOrderId'],
    PreparationListPayload,
    PreparationsListByType
>('preparations/listByTypeAndCustomerOrderId', initialState.listByTypeAndCustomerOrderId, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...state.data,
            [payload.customerOrderId]: payload,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.listByTypeAndCustomerOrderId,
    }),
});

export const listControlByCustomerOrderId = new EzeeAsyncAction<
    PreparationsState['listControlByCustomerOrderId'],
    PreparationListPayload,
    PreparationControlListByCustomerOrderId
>('preparations/listControlByCustomerOrderId', initialState.listControlByCustomerOrderId, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, { customerOrderId, ...listResponse }) => ({
        data: {
            ...state.data,
            [customerOrderId]: listResponse,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.listControlByCustomerOrderId,
    }),
});

export const details = new EzeeAsyncAction<PreparationsState['details'], PreparationDetailsPayload, Preparation>(
    'preparations/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

export const update = new EzeeAsyncAction<PreparationsState['update'], PreparationUpdatePayload, Preparation>(
    'preparations/update',
    initialState.update,
    requestReducer<PreparationsState['update'], Preparation>(initialState.update)
);

export const start = new EzeeAsyncAction<PreparationsState['start'], PreparationStartPayload, Preparation>(
    'preparations/start',
    initialState.start,
    requestReducer<PreparationsState['start'], Preparation>(initialState.start)
);

export const picking = new EzeeAsyncAction<PreparationsState['picking'], PreparationPickingPayload, Preparation>(
    'preparations/picking',
    initialState.picking,
    requestReducer<PreparationsState['picking'], Preparation>(initialState.picking)
);

export const sav = new EzeeAsyncAction<PreparationsState['sav'], PreparationSAVPayload, Preparation>(
    'preparations/sav',
    initialState.sav,
    requestReducer<PreparationsState['sav'], Preparation>(initialState.sav)
);

export const detailsPolling = new EzeeAction(
    'preparations/detailsPolling',
    {},
    {
        stopPolling: () => ({}),
        startPolling: () => ({}),
    }
);

// Reducer
export const preparationsReducer = combineReducers<PreparationsState>({
    list: list.reducer,
    listByTypeAndCustomerOrderId: listByTypeAndCustomerOrderId.reducer,
    listControlByCustomerOrderId: listControlByCustomerOrderId.reducer,
    details: details.reducer,
    update: update.reducer,
    start: start.reducer,
    picking: picking.reducer,
    sav: sav.reducer,
});

// Saga
export function* preparationsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(listByTypeAndCustomerOrderId.type.trigger, listByTypeAndCustomerOrderIdSaga);
    yield takeLatest(listControlByCustomerOrderId.type.trigger, listControlByCustomerOrderIdSaga);
    yield takeLatest(details.type.trigger, detailsSaga);
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(start.type.trigger, simpleAsyncSaga(preparationApiCall, start));
    yield takeLatest(picking.type.trigger, simpleAsyncSaga(pickingApiCall, picking));
    yield takeLatest(sav.type.trigger, simpleAsyncSaga(savApiCall, sav));
}

function* listByTypeAndCustomerOrderIdSaga(actionData: DataAction<PreparationListPayload>) {
    try {
        const [none, detail, sav]: Array<ListResponse<Preparation>> = yield all([
            call(listApiCall, { ...actionData.payload, type: PreparationOrderType.none }),
            call(listApiCall, { ...actionData.payload, type: PreparationOrderType.detail }),
            call(listApiCall, { ...actionData.payload, type: PreparationOrderType.sav }),
        ]);

        return yield put(
            listByTypeAndCustomerOrderId.success({
                customerOrderId: actionData.payload.customerOrderId,
                none,
                detail,
                sav,
            })
        );
    } catch (error) {
        return yield put(listByTypeAndCustomerOrderId.failure(error));
    }
}

function* listControlByCustomerOrderIdSaga(actionData: DataAction<PreparationListPayload>) {
    try {
        const response: ListResponse<Preparation> = yield call(listApiCall, actionData.payload);

        yield put(
            control.actions.setLocalParcelCountByParcelId(
                response.items
                    .sort((a, b) => {
                        if (a.type === PreparationOrderType.sav) {
                            return -1;
                        }
                        if (b.type === PreparationOrderType.sav) {
                            return 1;
                        }
                        return a.type.localeCompare(b.type);
                    })
                    .filter((item) => !!item.parcel)
                    .reduce((acc, item) => ({ ...acc, [item.parcel!.id]: item.controlledQuantity ?? 0 }), {})
            )
        );

        return yield put(
            listControlByCustomerOrderId.success({ customerOrderId: actionData.payload.customerOrderId, ...response })
        );
    } catch (error) {
        return yield put(listControlByCustomerOrderId.failure(error));
    }
}

function* detailsSaga(actionData: DataAction<PreparationDetailsPayload>) {
    if (actionData.meta?.poll) {
        yield put(detailsPolling.actions.startPolling());
        yield race([call(detailsPollTask, actionData), take(detailsPolling.actions.stopPolling().type)]);
    } else {
        try {
            const response = yield call(detailsApiCall, actionData.payload);

            return yield put(details.success(response));
        } catch (error) {
            return yield put(details.failure(error));
        }
    }
}

/* Worker Function */
function* detailsPollTask(action: DataAction<PreparationDetailsPayload>) {
    while (true) {
        try {
            const response = yield call(detailsApiCall, action.payload);

            yield put(details.success(response));
            yield delay(5000);
        } catch (error) {
            yield put(details.failure(error));
            yield delay(5000);
        }
    }
}

// Store helpers
export const getPreparationListState = (state: MainReducerState) => state.preparations.list;
export const getPreparationListByTypeAndCustomerOrderIdState =
    (id?: CustomerOrder['id']) => (state: MainReducerState) => ({
        loading: state.preparations.listByTypeAndCustomerOrderId.loading,
        error: state.preparations.listByTypeAndCustomerOrderId.error,
        data: id ? state.preparations.listByTypeAndCustomerOrderId.data[id] : undefined,
    });
export const getPreparationControlListByCustomerOrderIdState =
    (id?: CustomerOrder['id']) => (state: MainReducerState) => ({
        loading: state.preparations.listControlByCustomerOrderId.loading,
        error: state.preparations.listControlByCustomerOrderId.error,
        data: id ? state.preparations.listControlByCustomerOrderId.data[id] : undefined,
    });
export const getPreparationRemainingQuantityToControlByCustomerOrderIdAndParcelIdState =
    (customerOrderId: CustomerOrder['id'] | undefined, parcelId: Parcel['id'] | undefined) =>
    (state: MainReducerState) => {
        const preparation = state.preparations.listControlByCustomerOrderId.data?.[customerOrderId ?? -1]?.items?.find(
            (preparation) => preparation.parcel?.id === parcelId
        );

        if (!preparation) {
            return 0;
        } else {
            return (preparation.preparedQuantity ?? 0) > (preparation.quantity ?? 0)
                ? preparation.quantity ?? 0
                : preparation.preparedQuantity ?? 0;
        }
    };
export const getPreparationQuantityToRemoveByCustomerOrderIdAndParcelIdState =
    (customerOrderId: CustomerOrder['id'] | undefined, parcelId: Parcel['id'] | undefined) =>
    (state: MainReducerState) => {
        const preparation = state.preparations.listControlByCustomerOrderId.data?.[customerOrderId ?? -1]?.items?.find(
            (preparation) => preparation.parcel?.id === parcelId
        );

        return preparation?.controlledQuantity ?? 0;
    };
export const getPreparationStateById = (id?: Preparation['id']) => (state: MainReducerState) => ({
    loading: state.preparations.details.loading,
    error: state.preparations.details.error,
    data: id ? state.preparations.details.data[id] : undefined,
});
export const getPreparationUpdateState = (state: MainReducerState) => state.preparations.update;
export const getPreparationStartState = (state: MainReducerState) => state.preparations.start;
export const getPreparationPickingState = (state: MainReducerState) => state.preparations.picking;
export const getPreparationSAVState = (state: MainReducerState) => state.preparations.sav;
