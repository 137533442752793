import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer, requestInitialState } from '../helpers';

// Types
import { ControlOrderPallet, Pallet } from '../api/apiTypes';

// Controlers
import {
    details as detailsApiCall,
    ControlOrderPalletDetailsPayload,
    palletList as palletListApiCall,
    ControlOrderPalletPalletListPayload,
    ControlOrderPalletControlPalletsListPayload,
    controlPallet as controlPalletApiCall,
    ControlOrderPalletControlPalletListPayload,
    controlPallets as controlPalletsApiCall,
} from '../api/controlOrderPallets';
import { ListResponse } from '../api';

// States
export interface ControlOrderPalletsState {
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: ControlOrderPallet | undefined;
        };
    };
    palletList: RequestState<ListResponse<Pallet>>;
    controlPallet: RequestState<Pallet>;
    controlPallets: RequestState<Pallet>;
}

const initialState: ControlOrderPalletsState = {
    details: { ...requestInitialState, data: {} },
    palletList: { ...requestInitialState },
    controlPallet: { ...requestInitialState },
    controlPallets: { ...requestInitialState },
};

export const details = new EzeeAsyncAction<
    ControlOrderPalletsState['details'],
    ControlOrderPalletDetailsPayload,
    ControlOrderPallet
>('controlOrderPallets/details', initialState.details, {
    trigger: (state) => ({
        ...state,
        error: undefined,
        loading: true,
    }),
    success: (state, payload) => ({
        data: {
            ...state.data,
            [payload.id]: payload,
        },
        loading: false,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState.details,
    }),
});

export const palletList = new EzeeAsyncAction<
    ControlOrderPalletsState['palletList'],
    ControlOrderPalletPalletListPayload,
    ListResponse<ControlOrderPallet>
>(
    'controlOrderPallets/palletList',
    initialState.palletList,
    requestReducer<ControlOrderPalletsState['palletList'], ListResponse<ControlOrderPallet>>(initialState.palletList)
);

export const controlPallet = new EzeeAsyncAction<
    ControlOrderPalletsState['controlPallet'],
    ControlOrderPalletControlPalletListPayload,
    Pallet
>(
    'controlOrderPallets/controlPallet',
    initialState.controlPallet,
    requestReducer<ControlOrderPalletsState['controlPallet'], Pallet>(initialState.controlPallet)
);

export const controlPallets = new EzeeAsyncAction<
    ControlOrderPalletsState['controlPallets'],
    ControlOrderPalletControlPalletsListPayload,
    Pallet
>(
    'controlOrderPallets/controlPallets',
    initialState.controlPallets,
    requestReducer<ControlOrderPalletsState['controlPallets'], Pallet>(initialState.controlPallets)
);

// Reducer
export const controlOrderPalletsReducer = combineReducers<ControlOrderPalletsState>({
    details: details.reducer,
    palletList: palletList.reducer,
    controlPallet: controlPallet.reducer,
    controlPallets: controlPallets.reducer,
});

// Saga
export function* controlOrderPalletsSaga() {
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(palletList.type.trigger, simpleAsyncSaga(palletListApiCall, palletList));
    yield takeLatest(controlPallet.type.trigger, simpleAsyncSaga(controlPalletApiCall, controlPallet));
    yield takeLatest(controlPallets.type.trigger, simpleAsyncSaga(controlPalletsApiCall, controlPallets));
}

// Store helpers
export const getControlOrderPalletStateById = (id?: ControlOrderPallet['id']) => (state: MainReducerState) => ({
    loading: state.controlOrderPallets.details.loading,
    error: state.controlOrderPallets.details.error,
    data: id ? state.controlOrderPallets.details.data[id] : undefined,
});
export const getControlOrderPalletPalletListState = (state: MainReducerState) => state.controlOrderPallets.palletList;
export const getControlOrderPalletControlPalletState = (state: MainReducerState) =>
    state.controlOrderPallets.controlPallet;
export const getControlOrderPalletControlPalletsState = (state: MainReducerState) =>
    state.controlOrderPallets.controlPallets;
