import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 83 44">
        <path
            fill="currentColor"
            fillRule="nonzero"
            d="M55.558 0c3.197 0 5.75 2.606 5.75 5.801v1.766h6.255a5.721 5.721 0 015.147 3.125l3.633 7.062 4.277 1.85a3.57 3.57 0 012.146 3.307v9.963a3.536 3.536 0 01-3.534 3.531h-1.81v.35c-.014 4.008-3.281 7.245-7.292 7.231a7.263 7.263 0 01-7.237-7.287v-.28H38.728c.014.14.014.182.014.28v.07c-.014 4.008-3.281 7.245-7.292 7.231a7.263 7.263 0 01-7.237-7.287v-.28h-.8a3.525 3.525 0 01-3.478-3.573V5.815c0-3.195 2.539-5.8 5.722-5.815zm-24.08 32.286a4.451 4.451 0 00-4.46 4.455 4.451 4.451 0 004.46 4.457c2.454-.015 4.46-2.004 4.46-4.457a4.45 4.45 0 00-4.46-4.455zm38.68-.015a4.451 4.451 0 00-4.46 4.457 4.451 4.451 0 004.46 4.455c2.468 0 4.46-1.99 4.46-4.455a4.46 4.46 0 00-4.46-4.457zm-11.655-6.614H22.74v7.217c0 .434.295.77.73.77h1.43a7.191 7.191 0 016.578-4.19 7.207 7.207 0 016.577 4.19h20.448v-7.987zm16.55-5.478H61.307V33.63h2.272a7.22 7.22 0 013.506-3.504 7.262 7.262 0 019.65 3.504h2.496c.434 0 .729-.337.729-.771v-9.963a.824.824 0 00-.449-.757l-4.46-1.961zM55.557 2.817h-29.9c-1.641 0-2.918 1.345-2.918 2.998v17.04h35.763V5.8a2.97 2.97 0 00-2.945-2.984zM10.6 7.207l.12.104 5.686 5.532c.211.183.36.434.408.718 0 .01.002.019.004.028.005.03.008.06.01.091l.003.05v.097l-.002.017-.007.077-.01.067-.01.049a.798.798 0 01-.02.081l-.012.03a1.187 1.187 0 01-.206.385l-.106.117-5.738 5.585a1.3 1.3 0 01-1.803 0 1.217 1.217 0 01-.106-1.638l.106-.117 3.565-3.472H1.275c-.704 0-1.275-.555-1.275-1.24 0-.637.492-1.161 1.126-1.233l.15-.008h11.196L8.917 9.065a1.217 1.217 0 01-.106-1.638l.106-.117a1.301 1.301 0 011.683-.103zm56.963 3.176h-6.255v7.007h11.739l-2.82-5.423a2.961 2.961 0 00-2.664-1.584z"
        />
    </svg>
);

const IconLoading: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-loading${props.className ? ` ${props.className}` : ''}`} />
);

export default IconLoading;
