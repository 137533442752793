import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { message } from 'antd';

import { getCustomerOrderUpdateState, update } from '../../../store/actions/customerOrders';
import { PlatformReasonCode } from '../../../store/api/apiTypes';
import { CustomerOrderUpdatePayload } from '../../../store/api/customerOrders';

import { useActions, useArrowNavScope, usePrevious, useShortcutScope } from '../../../hooks';
import ButtonWithShortcut from '../../../components/ButtonWithShortcut';
import CustomModal, { CustomModalProps } from '../../../components/CustomModal';
import ArrowNavItem from '../../../components/ArrowNavItem';
import SuccessMessage from '../../../components/SuccessMessage';
import ReasonCodeSelect from '../../../components/ReasonCodeSelect';

const shortcutScope = 'RdvEditModal';

interface RdvEditModalProps extends CustomModalProps {
    payload?: CustomerOrderUpdatePayload;
    onSuccess: () => void;
}

const RdvCustomerOrderEditModal: FC<RdvEditModalProps> = ({ visible, onCancel, onSuccess, payload }) => {
    useShortcutScope(shortcutScope, !visible);
    useArrowNavScope(shortcutScope, !visible);
    const { customerOrderId: customerOrderIdParam } = useParams<{ customerOrderId: string }>();
    const customerOrderId = parseInt(customerOrderIdParam, 10);
    const [isSuccess, setIsSuccess] = useState(false);
    const [reasonCode, setReasonCode] = useState<PlatformReasonCode>();
    const updateCustomerOrder = useActions(update.trigger);
    const customerOrderUpdateState = useSelector(getCustomerOrderUpdateState);
    const previous = usePrevious({ customerOrderUpdateState });
    const onSubmit = () => {
        updateCustomerOrder({
            id: customerOrderId,
            ...payload,
            platformReasonCodeId: reasonCode?.id,
        });
    };
    const onClose = useCallback(() => {
        if (typeof onCancel === 'function') {
            onCancel({} as React.MouseEvent<HTMLElement, MouseEvent>);
        }
    }, [onCancel]);

    useEffect(() => {
        if (previous?.customerOrderUpdateState.loading && !customerOrderUpdateState.loading) {
            if (customerOrderUpdateState.error) {
                message.error(
                    `Une erreur est survenue pendant la mise à jour du statut (${customerOrderUpdateState.error.status})`
                );
            } else {
                setIsSuccess(true);
            }
        }
    }, [previous?.customerOrderUpdateState.loading, customerOrderUpdateState.loading, customerOrderUpdateState.error]);

    useEffect(() => {
        let timeout: number;

        if (!visible) {
            setIsSuccess(false);
            setReasonCode(undefined);
        } else if (isSuccess) {
            onSuccess();

            timeout = window.setTimeout(() => {
                if (timeout) {
                    onClose();
                }
            }, 2000);
        }

        return () => {
            window.clearTimeout(timeout);
        };
    }, [visible, onClose, onSuccess, isSuccess]);

    return (
        <CustomModal
            visible={visible}
            width={368}
            keyboard={false}
            title={!isSuccess && 'Modification du rendez-vous'}
            footer={
                !isSuccess && (
                    <>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="enter"
                                type="primary"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                onClick={onSubmit}
                                disabled={!reasonCode}
                                loading={customerOrderUpdateState.loading}
                            >
                                Valider
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                        <ArrowNavItem scope={shortcutScope}>
                            <ButtonWithShortcut
                                shortcut="esc"
                                shortcutOptions={{ enableOnTags: ['INPUT'] }}
                                shortcutScope={shortcutScope}
                                type="primary"
                                onClick={onClose}
                                ghost
                            >
                                Annuler
                            </ButtonWithShortcut>
                        </ArrowNavItem>
                    </>
                )
            }
            altTitle
        >
            {isSuccess && <SuccessMessage message="Rendez-vous modifié avec succès" />}
            {!isSuccess && <ReasonCodeSelect withArrowNav onChange={setReasonCode} />}
        </CustomModal>
    );
};

export default RdvCustomerOrderEditModal;
